import React from 'react';
import styled from 'styled-components';
import Stetho from './../assets/stethoscope.png';

const SeparatorContainer = styled.div`
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin-top: 1em;
    margin-bottom: 1em;
`;

const SeparatorLine = styled.hr`
    ${props => props.theme.darkMode ? `
    background-color: rgba(255, 71, 87, 0.8);
    ` : `
    background-color: rgb(142, 0, 17);
    `}
    height: 0.08em;
    border: none;
    border-radius: 0.05em;
    width: 40%;
`;

const SeparatorIcon = styled.img`
  width: 2.1em;
`;

export default function Separator(props) {
  return (
    <SeparatorContainer>
      <SeparatorLine />
      <SeparatorIcon src={Stetho} alt="Separator Icon" />
      <SeparatorLine />
    </SeparatorContainer>
  );
}