import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraIMC() {
  const [peso, setPeso] = useState('');
  const [altura, setAltura] = useState('');
  const [resultado, setResultado] = useState('');
  const [mensagem, setMensagem] = useState('Por favor, preencha os campos.');

  const calcularIMC = () => {
    if (altura > 0 && peso > 0) {
      const imc = peso / (altura * altura);
      const classificacao = determinarClassificacao(imc);
      setResultado(`Seu IMC é ${imc.toFixed(1)} (${classificacao})`);
      setMensagem(''); // Limpa a mensagem quando há um resultado válido
    } else {
      setResultado(''); // Limpa o resultado anterior
      setMensagem('Por favor, preencha os campos.'); // Exibe esta mensagem quando os inputs são inválidos
    }
  };

  const determinarClassificacao = (imc) => {
    if (imc < 18.5) return 'Abaixo do peso.';
    if (imc >= 18.5 && imc <= 24.9) return 'Peso ideal.';
    if (imc >= 25 && imc <= 29.9) return 'Levemente acima do peso.';
    if (imc >= 30 && imc <= 34.9) return 'Obesidade grau I.';
    if (imc >= 35 && imc <= 39.9) return 'Obesidade grau II (severa).';
    if (imc >= 40) return 'Obesidade III (mórbida).';
    return 'Classificação fora dos limites.';
  };

  return (
    <div>
                                    <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
      <h1 className='calc-titulo'>Calculadora de IMC</h1>
      <div>
        <label>Peso (kg) </label>
        <input
          type="number"
          value={peso}
          onChange={(e) => setPeso(e.target.value)}
        />
      </div>
      <div>
        <label>Altura (m) </label>
        <input
          type="number"
          step="0.01"
          value={altura}
          onChange={(e) => setAltura(e.target.value)}
        />
      </div>
      <div>
        <button onClick={calcularIMC}>Calcular IMC</button>
      </div>
      <div className="result-container">
        {mensagem && <div>{mensagem}</div>}
        {resultado && <div>{resultado}</div>}
      </div>
    </div>
  );
}

export default CalculadoraIMC;
