// Outubro, 2024


export const TEST_TYPES = {
    RESIDENCIA: 'Residencia',
    FLASHCARDS: 'Flashcards'
}


export const STUDY_MODES = {
    TEST_MODE: 'test-mode',
    CONSULT_MODE: 'consult-mode',
    PLAYGROUND_MODE: 'playground-mode',
}

export const SORT_MODES = {
    SORT: 'sort',
    SHUFFLE: 'shuffle',
}


export class SessionConfig {
    static SCHEMA = {
        removeNewTests: 'boolean',
        removePendingReviews: 'boolean',
        removeFutureReviews: 'boolean',
        removeBuried: 'boolean',
        ordering: [SORT_MODES.SORT, SORT_MODES.SHUFFLE],
        removeAnuladas: 'boolean',
        removeSolved: 'boolean',
        onlyExtensivo: 'boolean',
        years: 'array',
        institutions: 'array',
        detachCousins: 'boolean',
    }


    static DEFAULT = {
        removeNewTests: false, 
        removePendingReviews: false, 
        removeFutureReviews: true,
        removeBuried: true,
        ordering: SORT_MODES.SORT
    }


    static RESIDENCIA_DEFAULT = {
        ...SessionConfig.DEFAULT,
        removeAnuladas: true, 
        removeSolved: true,
        onlyExtensivo: false,
        years: [],
        institutions: []
    }


    static FLASHCARDS_DEFAULT = {
        ...SessionConfig.DEFAULT,
        detachCousins: true
    }


    static createTestMode(testType, customConfig = {}) {
        return this.createConfig(testType, customConfig)
    }


    static createPlaygroundOrConsultMode(testType, customConfig = {}) {
        return this.createConfig(testType, {
            removeFutureReviews: false,
            removeBuried: false,
            removeSolved: false,
            ...customConfig
        })
    }


    static createOnlyNewTests(testType, removeAnuladas) {
        return this.createConfig(testType, {
            removeNewTests: false,
            removePendingReviews: true,
            removeFutureReviews: true,
            removeBuried: true,
            removeSolved: true,
            removeAnuladas: removeAnuladas
        })
    }


    static createOnlyPendingReviews(testType, removeAnuladas) {
        return this.createConfig(testType, {
            removeNewTests: true,
            removePendingReviews: false,
            removeFutureReviews: true,
            removeBuried: true,
            removeSolved: true,
            removeAnuladas: removeAnuladas
        })
    }


    static createOnlyFutureReviews(testType, removeAnuladas) {
        return this.createConfig(testType, {
            removeNewTests: true,
            removePendingReviews: true,
            removeFutureReviews: false,
            removeBuried: true,
            removeSolved: true,
            removeAnuladas: removeAnuladas
        })
    }


    static createOnlySolved(testType, removeAnuladas) {
        return this.createConfig(testType, {
            removeNewTests: true,
            removePendingReviews: true,
            removeFutureReviews: true,
            removeBuried: true,
            removeSolved: false,
            removeAnuladas: removeAnuladas
        })
    }


    static createConfig(testType, customConfig = {}) {
        try {
            this.validateConfig(customConfig)
            
            switch(testType) {
                case TEST_TYPES.RESIDENCIA:
                    return { ...this.RESIDENCIA_DEFAULT, ...customConfig }
                case TEST_TYPES.FLASHCARDS:
                    return { ...this.FLASHCARDS_DEFAULT, ...customConfig }
                default:
                    return undefined
            }
        } catch (error) {
            console.log(` * ERRO AO CRIAR A CONFIG!`)
            console.log(testType)
            console.log(customConfig)
            return undefined
        }
    }


    static validateConfig(config) {
        for (const [key, value] of Object.entries(config)) {
            if (!this.SCHEMA[key] || 
                (this.SCHEMA[key] === 'boolean' && typeof value !== 'boolean') ||
                (this.SCHEMA[key] === 'array' && !Array.isArray(value)) ||
                (Array.isArray(this.SCHEMA[key]) && !this.SCHEMA[key].includes(value))
            ) {
                throw new Error(`\t\t ** ERRO - Configuração inválida em: ${key}`)
            }
        }
    }


    equals(config2) {
        if (!config2) return false
        return JSON.stringify(this) === JSON.stringify(config2)
    }
}



export class PredefinedSessionConfig {
    static SCHEMA = {
        ordering: [SORT_MODES.SORT, SORT_MODES.SHUFFLE],
        detachCousins: 'boolean'
    }

    static DEFAULT = {
        ordering: 'sort',
        detachCousins: false
    }

    static create(customConfig = {}) {
        try {
            this.validateConfig(customConfig)
            return { ...this.DEFAULT, ...customConfig }
        } catch (error) {
            return undefined
        }
    }

    static validateConfig(config) {
        for (const [key, value] of Object.entries(config)) {
            if (!this.SCHEMA[key] || 
                (this.SCHEMA[key] === 'boolean' && typeof value !== 'boolean') ||
                (Array.isArray(this.SCHEMA[key]) && !this.SCHEMA[key].includes(value))
            ) {
                throw new Error(`\t\t ** ERRO - Configuração inválida em: ${key}`)
            }
        }
    }
}