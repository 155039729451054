import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraProteinaCreatinina() {
    const [proteinaUrinaria, setProteinaUrinaria] = useState('');
    const [creatininaUrinaria, setCreatininaUrinaria] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularRelacao = () => {
        const proteinaUrinariaNum = parseFloat(proteinaUrinaria);
        const creatininaUrinariaNum = parseFloat(creatininaUrinaria);

        if (proteinaUrinariaNum > 0 && creatininaUrinariaNum > 0) {
            const relacao = 1000 * proteinaUrinariaNum / creatininaUrinariaNum;
            let interpretacao;

            if (relacao <= 0.2) {
                interpretacao = 'Normal';
            } else if (relacao > 0.2 && relacao <= 3.5) {
                interpretacao = 'Investigar';
            } else {
                interpretacao = 'Proteinúria nefrótica';
            }

            setResultado(`Relação Proteína/Creatinina Urinária: ${relacao.toFixed(2)} mg/g - ${interpretacao}`);
        } else {
            setResultado('Preencha todos os campos com valores válidos.');
        }
    };

    useEffect(() => {
        if (proteinaUrinaria && creatininaUrinaria) {
            calcularRelacao();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [proteinaUrinaria, creatininaUrinaria]);

    return (
        <div>
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Relação Proteína/Creatinina Urinária (Amostra Isolada)</h1>
            <div>
                <label>Proteína Urinária (mg/dL):</label>
                <input type="number" value={proteinaUrinaria} onChange={e => setProteinaUrinaria(e.target.value)} />
            </div>
            <div>
                <label>Creatinina Urinária (mg/dL):</label>
                <input type="number" value={creatininaUrinaria} onChange={e => setCreatininaUrinaria(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraProteinaCreatinina;
