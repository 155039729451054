import React, { useState } from 'react'
import './FeedbackTypeButton.css'

import styled from 'styled-components';

const FeedbackTypeButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2em;
    border-radius: 1em;
    flex-wrap: wrap;
    width: 40%;
    margin: 1em;

    ${props => props.theme.darkMode ? `
        background-color: rgb(80, 80, 80);
        color: rgb(220, 220, 220);
        box-shadow: none;
    ` : `
        background-color: white;
        color: black;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    `}

    &:hover {
        cursor: pointer;
        background-color: ${props => props.theme.darkMode ? 'rgba(100, 100, 100, 0.8)' : 'rgba(243, 242, 242, 0.8)'};
    }

    &:active {
        box-shadow: none;
    }

    @media (max-width: 500px) {
        font-size: 0.8em;
        background-color: ${props => props.theme.darkMode ? 'rgba(70, 70, 70, 0.519)' : 'rgba(241, 241, 241, 0.519)'};
    }
`;

const FeedbackTypeButtonText = styled.div`
    margin: 0;
    padding: 0;
`;

const FeedbackTypeButtonIcon = styled.span`
    font-size: 1.4em;
    margin: 0;
    padding: 0;
`;

const FeedbackTypeButtonLabel = styled.span`
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0 0 0 0.2em;
    padding: 0;
`;

const FeedbackTypeButtonDica = styled.p`
    margin: 0;
    padding: 0;
`;



export default function FeedbackTypeButton(props) {
    return (
        <FeedbackTypeButtonContainer onClick={props.action} >
            <FeedbackTypeButtonText>
                <FeedbackTypeButtonIcon>{props.icon}</FeedbackTypeButtonIcon>
                <FeedbackTypeButtonLabel>{props.text}.</FeedbackTypeButtonLabel>
            </FeedbackTypeButtonText>
            <FeedbackTypeButtonDica>{props.subtitle}</FeedbackTypeButtonDica>
        </FeedbackTypeButtonContainer>
    );
}