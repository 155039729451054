// Outubro, 2024
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import ExpandIcon from './../assets/tree/right-chevron2.png'
import CollapseIcon from './../assets/tree/down-arrow2.png'


const IconContainer = styled.div`
    cursor: pointer;

    margin-right: 0.5em;
    filter: ${props => props.theme.darkMode ? 'invert(0.5) brightness(1.5)' : 'none'};


    transition: transform 0.2s ease-in-out;
    &:hover {
        transform: scale(1.1);
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        margin-right: 0.4em;
        width: 0.85em;
        height: 0.85em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.1em;
    }

    @media (max-width: 768px) {
        margin-right: 0.45em;
        width: 0.8em;
        height: 0.8em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.15em; // Ajuste fino para centralizar com o texto
    }
`

const Icon = styled.img`
    width: 0.9em;
    
    opacity: ${props => props.theme.darkMode ? 0.8 : 1};
    transition: filter 0.3s ease, opacity 0.3s ease;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 0.8em;
        height: 0.8em;
        display: block;
    }

    @media (max-width: 768px) {
        width: 0.7em;
        height: 0.7em;
        display: block;
    }
`

const Empty = styled.div`
    margin-right: 0.5em;
    width: 0.9em;
    opacity: ${props => props.theme.darkMode ? 0.5 : 1};

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 0.85em;
        height: 0.85em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.1em;
    }

    @media (max-width: 768px) {
        width: 0.8em;
        height: 0.8em;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.15em; // Mesmo ajuste do IconContainer
    }
`

export default function ExpandCollapse({ expanded, action, empty = false}) {


    const iconSrc = expanded ? CollapseIcon : ExpandIcon

    if (empty) {
        return <Empty/>
    }

    return (
        <IconContainer onClick={(e) => action(e)}>
            <Icon src={iconSrc} alt={expanded ? 'Collapse' : 'Expand'} />
        </IconContainer>
    )
}