import React from 'react'

export default function Support() {
    return (
        <>
            <p>Se você precisa de qualquer ajuda, <u>não hesite</u> e fale conosco.</p>

            <p>O suporte na Osler é rápido, com pessoas reais, e temos genuíno interesse em te ajudar.</p>

            <p><b>Quando você quer uma resposta rápida e oficial.</b> Envie um e-mail para <u>suporte@osler-ensino.com</u>. Você receberá suporte da equipe, e o tempo médio de resposta é menor que 24 horas. Excelente para dúvidas sobre pagamento, avisar sobre bugs, fazer reclamações, etc.</p>

            <p><b>Quando você quer falar com a chefia</b>. Envie uma DM no Instagram e você falará diretamente com o Vittorio ou o Gian. O lado ruim é que o tempo de resposta pode ser maior, ao redor de dias, mas não fique inibido(a), e pode mandar mais de uma mensagem.</p>

            <p><b>Não há outros canais.</b> A Osler não participa de outros grupos de alunos (e.g., Discord, Telegram), e não nos responsabilizamos por informações transmitidas nesses meios.</p>

        </>
    )
}
