import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Cronograma from './../assets/cronograma_bimestre.png'
import ExtensivoCard from './ExtensivoCard'
import OslerCard from '../components/OslerCard'



export default function ExtensivoSchedule({show}) {


    if (!show) return null

    return (
            <OslerCard>
                <h1>Cronograma</h1>

                <p>Abaixo, o cronograma do próximo bimestre.</p>

                <p><i>De nota, o cronograma é preliminar e passível de alterações, mas raramente ocorrem.</i></p>

                <img src = {Cronograma} style = {{alignSelf: 'center', maxWidth: '70%'}} />

            </OslerCard>
    );
}