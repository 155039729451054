import { db } from './../firebase/firebase-setup'
import OslerData, { KEYS } from '../controllers/OslerData'
import firebase from 'firebase/compat/app';


class ExamsController {
    constructor() {
        this.stateAbbreviationToName = {
            'AC': 'Acre',
            'AL': 'Alagoas',
            'AP': 'Amapá',
            'AM': 'Amazonas',
            'BA': 'Bahia',
            'CE': 'Ceará',
            'DF': 'Distrito Federal',
            'ES': 'Espírito Santo',
            'GO': 'Goiás',
            'MA': 'Maranhão',
            'MT': 'Mato Grosso',
            'MS': 'Mato Grosso do Sul',
            'MG': 'Minas Gerais',
            'PA': 'Pará',
            'PB': 'Paraíba',
            'PR': 'Paraná',
            'PE': 'Pernambuco',
            'PI': 'Piauí',
            'RJ': 'Rio de Janeiro',
            'RN': 'Rio Grande do Norte',
            'RS': 'Rio Grande do Sul',
            'RO': 'Rondônia',
            'RR': 'Roraima',
            'SC': 'Santa Catarina',
            'SP': 'São Paulo',
            'SE': 'Sergipe',
            'TO': 'Tocantins',
            '(sem estado)' : '(bug)'
        }


    }

    updateStateInformation() {
        for (const [institution, institutionData] of Object.entries(this.examsMetadata)) {
            if (institutionData.state) {
                institutionData.stateAbbrev = institutionData.state
                institutionData.stateFull = this.stateAbbreviationToName[institutionData.state] || institutionData.state
                delete institutionData.state
            }
        }
    }

    async start(user) {
        this.userID = user.id
        this.root = `/users/${this.userID}/Residencia`

        this.examsMetadata = OslerData.data[KEYS.RESIDENCIA][KEYS.EXAMS_METADATA]
        this.updateStateInformation()


        console.log(`${this.root}/lists/exams_lists/`)
        console.log(`${this.root}/focused_institutions`)
        console.log(`${this.root}/focused_institutions`)

        try {
            const exams = await db.collection(`${this.root}/lists/exams_lists/`).get()
            const userExamsData = this.processExams(exams.docs)
            this.updateExamsMetadata(userExamsData)
        

            const focusedInstitutions = await db.doc(`${this.root}/focused_institutions`).get()


            if (focusedInstitutions.exists && focusedInstitutions.data()) {
                this.focusedInstitutions = focusedInstitutions.data()['institutions']
            }
            else {
                this.focusedInstitutions = []
            }

            
            console.log('Updated Exams Metadata:', this.examsMetadata)
        } catch (error) {
            console.error('Erro ao baixar coleções do Firebase: ', error)
            throw error
        }
    }


    toggleFocused(institution) {
        const isCurrentlyFocused = this.focusedInstitutions.includes(institution)
        const updateMethod = isCurrentlyFocused ? 'arrayRemove' : 'arrayUnion'
    
        try {
            db.doc(`${this.root}/focused_institutions`).set({
                institutions: firebase.firestore.FieldValue[updateMethod](institution)
            }, { merge: true })
    
            this.focusedInstitutions = isCurrentlyFocused
                ? this.focusedInstitutions.filter(i => i !== institution)
                : [...this.focusedInstitutions, institution]
    
        } catch (error) {
            console.error('Error updating focused institutions:', error)
        }

        return this.focusedInstitutions
    }


    processExams(docs) {
        const examsData = this.processDocuments(docs)
        const userExamsData = {}

        for (const [examKey, examData] of Object.entries(examsData)) {
            const [institution, year] = examKey.split('_')
            if (!userExamsData[institution]) {
                userExamsData[institution] = {}
            }
            if (!userExamsData[institution][year]) {
                userExamsData[institution][year] = {
                    firstAnswerCorrect: 0,
                    lastAnswerCorrect: 0,
                    firstAnswerTotal: 0,
                    lastAnswerTotal: 0
                }
            }

            const yearData = userExamsData[institution][year]

            for (const questionData of Object.values(examData)) {
                if (questionData.first_answer) {
                    yearData.firstAnswerTotal++
                    if (questionData.first_answer.gaveRightAnswer) {
                        yearData.firstAnswerCorrect++
                    }
                }
                if (questionData.last_answer) {
                    yearData.lastAnswerTotal++
                    if (questionData.last_answer.gaveRightAnswer) {
                        yearData.lastAnswerCorrect++
                    }
                }
            }
        }

        return userExamsData
    }

    updateExamsMetadata(userExamsData) {
        for (const [institution, institutionData] of Object.entries(this.examsMetadata)) {
            for (const [examType, examTypeData] of Object.entries(institutionData)) {
                if (examType === 'R1' && examTypeData.years) {
                    for (const [year, yearData] of Object.entries(examTypeData.years)) {
                        const userYearData = userExamsData[institution]?.[year]
                        if (userYearData) {
                            yearData.firstAnswerPercentage = userYearData.firstAnswerTotal > 0
                                ? Number(((userYearData.firstAnswerCorrect / userYearData.firstAnswerTotal) * 100).toFixed(1))
                                : 0
                            yearData.lastAnswerPercentage = userYearData.lastAnswerTotal > 0
                                ? Number(((userYearData.lastAnswerCorrect / userYearData.lastAnswerTotal) * 100).toFixed(1))
                                : 0
                            yearData.testsAnswered = userYearData.firstAnswerTotal
                        } else {
                            yearData.firstAnswerPercentage = 0
                            yearData.lastAnswerPercentage = 0
                            yearData.testsAnswered = 0
                        }
                    }
                }
            }
        }
    }

    processDocuments(docs) {
        return docs.reduce((acc, doc) => {
            acc[doc.id] = doc.data()
            return acc
        }, {})
    }
}

export default new ExamsController()