// Outubro, 2024
import React, { useEffect, useRef, useState } from 'react'
import tree, { TreeFilters } from './Tree'
import styled from 'styled-components'


const Empty = styled.p`
    width: 100%;
    text-align: center;
    color: #a01d26;
    font-weight: bold;
    font-size: 1.2em;
    margin: 0;
`

const Detail = styled.p`
    width: 100%;
    margin: 0;
    padding: 0 25% 0 25%;

    text-align: center;
    color: gray;
    font-size: 0.9em;
`

export default function TreeJSX({ visible, filters, searchedString, mode, onTagpathSelected}) {
    const [treeJSX, setTreeJSX] = useState()
    const studyMode = useRef()
    const includeAnuladas = useRef()
    const treeFilter = useRef()


    useEffect(() => {
        const anuladasChanged = includeAnuladas.current != filters.includes('include_anuladas')
        const modeChanged = studyMode.current != mode

        const nodesBackup = tree.extractNodeBackup()
        
        if (anuladasChanged || modeChanged) {
            console.log('TreeJSX(): mudou opção de anulada, vamos recarregar os dados...')
            includeAnuladas.current = filters.includes('include_anuladas')
            studyMode.current = mode

            tree.loadData(!includeAnuladas.current, studyMode.current)

            // Passamos um método para ser chamado sempre que tree quiser forçar uma nova renderização,
            // e um para notificar sempre que um checkbox (tagpath) for selecionado.
            //
            // Isso é passado na criação porque entendemos que não é dinâmico, e será verdadeiro pela
            // persistência da árvore.
            tree.createTree(renderTree, onTagpathSelected)
        }


        if (Object.keys(nodesBackup).length > 0) {
            tree.loadTreeFromPreviousNodes(nodesBackup)
        }


        let converted = convertFilters(filters)
        if (converted != treeFilter.current) {
            console.log('TreeJSX(): mudou filtro, vamos alterar, mas sem refazer...')
            treeFilter.current = converted
        }

        renderTree()
    }, [filters, searchedString, mode, renderTree])



    function convertFilters(filters) {
        let converted

        if (filters.includes('only_reviews')) {
            converted = TreeFilters.PENDING_REVIEWS_ONLY
        }
        else if (filters.includes('only_news')) {
            converted = TreeFilters.NEW_TESTS_ONLY
        }
        else if (filters.includes(TreeFilters.ALL_REVIEWS_ONLY)) {
            converted = TreeFilters.ALL_REVIEWS_ONLY
        }
        else if (filters.includes(TreeFilters.SEEN_TESTS_ONLY)) {
            converted = TreeFilters.SEEN_TESTS_ONLY
        }

        return converted
    }


    function renderTree() {
        console.log("Nova renderização...")

        const JSX = tree.createTreeJSX(searchedString, mode, treeFilter.current)
        const anyNodeVisible = tree.nodes.some(node => node.visible)

        if (!anyNodeVisible && searchedString !== '') {
            setTreeJSX(<>
                <Empty>Não encontramos o termo.</Empty>
                <Detail><b>Reveja grafia e acentos.</b> Se não resolver, procure manualmente na árvore. Se for um tema importante que está faltando, solicite na DM. 🙏🏼</Detail>
            </>)
        } else {
            setTreeJSX(JSX)
        }
    }
    

    if (!visible) return null

    return (
        <>
        {treeJSX}
        </>
    )
}