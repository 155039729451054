import React from 'react';
import styled from 'styled-components';

const UserScreenCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    width: 65vw;
    background-color: white;
    border-radius: 1em;

    padding: 1.5em;
    margin-top: 2em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
        color: white;
        border: none;
    ` : `
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
        color: black;
    `}


    &:last-child {
        margin-bottom: 2em;
    }

    & + p {
        margin: 0;
    }

    & > p, & > ul {
        align-self: self-start;
        margin: 0;
    }

    @media (max-width: 1200px) {
        width: 95%;
    }

    @media (max-width: 500px) {
        width: 96%;
        &:last-child {
            margin-bottom: 4em;
        }
    }
`;

const USCHeader = styled.div`
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    justify-self: center;
    align-items: center;
    margin-bottom: 0.5em;
`;

const USCIcon = styled.img`
    width: 1.8em;
`;

const USCTitle = styled.h2`
    font-size: 1.8em;
    margin-left: 0.3em;
`;

export default function UserScreenCard(props) {
    return (
        <UserScreenCardContainer>
            <USCHeader>
                <USCIcon src={props.icon} />
                <USCTitle>{props.title}</USCTitle>
            </USCHeader>
            {props.content}
        </UserScreenCardContainer>
    );
}
