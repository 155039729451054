// Novembro 2024
//      Tanto FlashcardAnswerJSX quanto McqAnswerJSX precisam ser componentes
//      separados porque são usados no modo consulta.  

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import AnswerScreenHelperBttns from './AnswerScreenHelperBttns'
import FeedbackArea from './FeedbackArea'
import PersonalNoteJSX from './PersonalNoteJSX'
import { useHotkeys } from 'react-hotkeys-hook'
import session from '../controllers/Session'
import { TestArea } from './FlashcardsStyles';
import ButtonSRS from './ButtonSRS'
import { ColumnCSS } from '../components/BasicComponents'
import FlashcardAnswerJSX from './FlashcardAnswerJSX'
import AnswerScreenNavBttns from './AnswerScreenNavBttns'



const FeedbackBttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-top: 2em;
    width: 100%;

    @media (max-width: 500px) {
        margin-bottom: 1em;
    }
`


const colorMap = {
    fail: { backgroundColor: '#8E0011', hoverColor: 'rgba(142, 0, 17, 0.9)' },
    normal: { backgroundColor: 'rgb(35, 146, 7)', hoverColor: 'rgba(35, 146, 7, 0.9)' },
  };



const PlaygroundBttn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    color: white;
    font-weight: 600;

    height: 4em;

    border-radius: 0.5em;

    margin-right: 0.1vw;
    margin-left: 0.1vw;

    box-shadow:inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);

    cursor: pointer;
    width: 48%;

    background-color: ${props => colorMap[props.colorClass].backgroundColor};

    &:hover {
        background-color: ${props => colorMap[props.colorClass].hoverColor};
    }
`


const PlaygroundBttnLabel = styled.p`
    margin: 0;
    padding: 0;
    text-align: 0;
`

const BttsContainer = styled.div`
    width: 100%;
    ${ColumnCSS}
`



export default function FlashcardAnswerScreen({test, registerAnswer, alreadyAnswered}) {


    const [shortcutsEnabled, setShortcutsEnabled] = useState(true)
    const [showFeedbackArea, setShowFeedbackArea] = useState(false)
    const [hasPersonalNote, setHasPersonalNote] = useState(false)
    const [showingPersonalNote, setShowingPersonalNote] = useState(false)
    const [timeToNextReview, setTimeToNextReview] = useState(false)



    // Atalhos para funçẽos da session
    // Please be aware that there are some hotkeys that we cannot override, 
    // because they would interfere with a safe browsing experience for the user.
    // These depend on the browser.
    // (https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/basic-usage)    
    useHotkeys('space', () => registerAnswer(2), {enabled: shortcutsEnabled && !alreadyAnswered})
    useHotkeys('enter', () => registerAnswer(2), {enabled: shortcutsEnabled && !alreadyAnswered})
    useHotkeys('1', () => registerAnswer(0),     {enabled: shortcutsEnabled && !alreadyAnswered})
    useHotkeys('2', () => registerAnswer(1),     {enabled: shortcutsEnabled && !alreadyAnswered})
    useHotkeys('3', () => registerAnswer(2),     {enabled: shortcutsEnabled && !alreadyAnswered})
    useHotkeys('4', () => registerAnswer(3),     {enabled: shortcutsEnabled && !alreadyAnswered})


    useEffect(() => {
        if (session.mode === 'test-mode') {
            setTimeToNextReview(session.getTimeUntilNextReview() )
        }

        const note = session.getCurrentTest().personalNote

        if (note) {
            setHasPersonalNote(true)
            setShowingPersonalNote(true)
        }

    }, [])
    

    function startFeedback() {
        setShowFeedbackArea(true)
        setShortcutsEnabled(false)
    }


    function stopFeedback() {   
        setShortcutsEnabled(true)
        setShowFeedbackArea(false)
    }


    function startPersonalNote() {
        setShowingPersonalNote(true)
        setShortcutsEnabled(false)
    }


    const SRS_BUTTONS = [
        {
            text: 'Errei',
            colorClass: 'fail',
            levelOfSuccess: 0
        },
        {
            text: 'Difícil',
            colorClass: 'hard',
            levelOfSuccess: 1
        },
        {
            text: 'Normal',
            colorClass: 'normal',
            levelOfSuccess: 2
        },
        {
            text: 'Fácil',
            colorClass: 'easy',
            levelOfSuccess: 3
        }
    ]

    const PLAYGROUND_BUTTONS = [
        {
            text: 'Errei',
            colorClass: 'fail',
            levelOfSuccess: 0
        },
        {
            text: 'Acertei',
            colorClass: 'normal',
            levelOfSuccess: 3
        }
    ]


    const renderButtons = () => {
        if (alreadyAnswered) {
            return (
                <AnswerScreenNavBttns />
            )
        }

        if (showFeedbackArea) {
            return (
                <FeedbackArea
                    test={test}
                    testType={'Flashcards'}
                    close={stopFeedback}
                />
            )
        }

        return (
            <FeedbackBttnsContainer>
                {session.mode === 'test-mode' ? (
                    SRS_BUTTONS.map(button => (
                        <ButtonSRS
                            key={button.text}
                            submitAnswer={registerAnswer}
                            time={timeToNextReview[button.levelOfSuccess]}
                            {...button}
                        />
                    ))
                ) : (
                    PLAYGROUND_BUTTONS.map(button => (
                        <PlaygroundBttn 
                            key={button.text}
                            colorClass={button.colorClass} 
                            onClick={() => registerAnswer(button.levelOfSuccess)}
                        >
                            <PlaygroundBttnLabel>{button.text}</PlaygroundBttnLabel>
                        </PlaygroundBttn>
                    ))
                )}
            </FeedbackBttnsContainer>
        )
    }

    return (
        <TestArea>
            
            <FlashcardAnswerJSX test={test} />

            <BttsContainer>
                {!alreadyAnswered && !showFeedbackArea && (
                    <AnswerScreenHelperBttns
                        startFeedback={startFeedback}
                        startPersonalNote={startPersonalNote}
                        showAddNote={!showingPersonalNote}
                    />
                )}

                {(showingPersonalNote && !showFeedbackArea) && (
                    <PersonalNoteJSX
                        test={test}
                        close={() => setShowingPersonalNote(false)}
                    />
                )}

                {renderButtons()}
            </BttsContainer>
        </TestArea>        
        )
}