// Jun, 2024
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import session from './../controllers/Session'
import TestScreenButton from './TestScreenButton'
import { useNavigate } from 'react-router-dom'
import { isMobile } from '../utils/BootstrapUtils'
import ShortcutsDialog from './ShortcutsDialog'
import Transfer from './../assets/transfer.png'
import Refresh from './../assets/refresh.png'
import Question from '../assets/question.png'



const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

`


const AuxBttnsContainer = styled.div`
    width: 98%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
`



export default function QuestionScreenHelperBttns({goToPreviousQuestion, moveEndQueue, otherBttns}) {
    const [showDialog, setShowDialog] = useState(false)


    const [shortcutsHelp, setShortcutsHelp] = useState()


    useEffect(() => {
        let shortcuts = []
    
        if (session.testType === 'Residencia') {
            shortcuts.push(
                {
                    'keys': '1 a 5',
                    'description': 'Marca, respectivamente, a alternativa A/B/C/D/E.'
                },
                {
                    'keys': 'Espaço ou Enter',
                    'description': 'Após escolher a alternativa, mostra a resposta.'
                },)
        } else {
            shortcuts.push(
                {
                    'keys': "Espaço ou Enter",
                    'description' : 'Mostra a resposta.'
                }
            )
        }
    
        shortcuts = shortcuts.concat([
            {
                'keys': 'SHIFT + P',
                'description': 'Pula o teste atual.'
            },
            {
                'keys': 'CTRL + Z',
                'description': 'Refazer o teste anterior.'
            },
        ])
    
        setShortcutsHelp(shortcuts)



    }, [])
    const shortcuts = [
        {
            'keys': 'Espaço ou Enter',
            'description' : 'Te levam para a próxima questão (e também indicam que você achou a resposta \"Normal\")'
        },
        {
            'keys': '1/2/3/4',
            'description' : 'Dá feedback o Errei/Difícil/Normal/Fácil, respectivamente. No modo playground (1) é errei, e o resto é acertei.'
        },
        {
            'keys': 'SHIFT + E',
            'description' : 'Enterra o teste atual.'
        },
        {
            'keys': 'SHIFT + S',
            'description' : 'Salva o teste atual.'
        },
    ]


    return (
        <Container>

            <ShortcutsDialog 
                showDialog = {showDialog}
                handleClose = {() => setShowDialog(false)}
                shortcutsButtonProps = {shortcutsHelp} />

            <AuxBttnsContainer>

                    { otherBttns }

                    
                    {
                        session.canUndo() &&
                            <TestScreenButton
                                text = {'Refazer'}
                                action = {goToPreviousQuestion}
                                icon = {Refresh} />
                    }

                

                    { !session.isLastTest() &&
                            <TestScreenButton
                                text = {'Pular'}
                                action = {moveEndQueue}
                                icon = {Transfer} />
                    }

                    { !isMobile() && session.currentIndex <= 2 &&
                            <TestScreenButton
                                text = {'Atalhos'}
                                action = {() => {setShowDialog(true)}}
                                icon = {Question} />  
                    }

            </AuxBttnsContainer>
        </Container>    

    )
    
}