import React, { useState, useEffect } from "react";
import TestScreenButton from "../tests/TestScreenButton";

import SkullEmpty from '../assets/skull_white.png'
import SkullColor from '../assets/skull_color.png'
import LikedBuriedController from "../controllers/LikedBuriedController";
import ClozeDialog from "../tests/ClozeDialog";

import { useHotkeys } from 'react-hotkeys-hook';


export default function BuryButton(props) {

    const [isBuried, setIsBuried] = useState()
    const [showClozeDialog, setShowClozeDialog] = useState(false)




    useEffect(() => {
        setIsBuried( LikedBuriedController.isBuried(props.testType, props.ID))
    }, [])


    // https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/setting-callback-dependencies
    useHotkeys('shift+e', shortcut, [isBuried, props.shouldShowDialog])

    
    function shortcut() {
        if (props.testMode) {
            bury()
        }
    }


    function bury() {   
        if (!isBuried && props.shouldShowDialog) {
            setShowClozeDialog(true)
        }
        else {
            reallyBuryOrUnbury()
        }
    }


    function reallyBuryOrUnbury() {
        LikedBuriedController.buryOrUnbury(props.testType, props.ID)
        setIsBuried( !isBuried )   

        if (props.callOnBury) {
            props.callOnBury()
        }
    }


    function handleDialogCancel() {
        setShowClozeDialog(false)
    }



    function handleDialogOk() {
        reallyBuryOrUnbury()
        setShowClozeDialog(false)
    }


    return (
        <>
            <ClozeDialog
                showDialog    = {showClozeDialog}
                setShowDialog = {setShowClozeDialog}
                handleCancel  = {handleDialogCancel}
                handleOk      = {handleDialogOk} /> 

            <TestScreenButton
                text = { isBuried ? 'Enterrado' : 'Enterrar' }
                action = {bury}
                icon = { isBuried ? SkullColor : SkullEmpty } />
        </>
    );
}