import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation} from "react-router-dom"
import { useSelector } from 'react-redux'
import OslerLogo  from './../assets/Logo.png'
import OslerLogoDark from './../assets/LogoDark.png'
import SignatureIcon from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import Bedside from './../assets/bedside.png'
import Setup from './../assets/settings.png'
import Stetho from './../assets/stethoscope.png'
import Home from './../assets/home.png'
import Menu from './../assets/menu.png'
import Graph from './../assets/analytics.png'
import KeyIcon from './../assets/extensivo.png'
import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import styled from 'styled-components'
import SideBarButton from './MobileBarButton'
import Moon from './../assets/lua.png'
import Sun from './../assets/sol.png'
import DarkModeToggle from './DarkModeToggle'
import NoteIcon from './../assets/personal_note.png'
import Trilha from './../assets/trilha.png'

const Container = styled.div`
    width: 100vw;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    ` : `
        background-color: white;
        // box-shadow: 0 1px 1px rgba(0, 0, 0, 0.7);
    `}

    @media (max-width: 768px) {
        padding: 0.6em 0.8em;
    }
`

const MenuContainer = styled.div`
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 1em 0.2em 2em 0.2em;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: contain;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.2)' : 
            'rgba(0, 0, 0, 0.1)'
        };
        border-radius: 3px;
    }

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        color: rgb(220, 220, 220);
    ` : `
        background-color: white;
    `}

    @media (max-width: 768px) {
        width: 65vw;
        padding: 0.6em 0.6em 2em 0.6em;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    }
`

const Overlay = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    z-index: 900;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    overscroll-behavior: none;
`

const Logo = styled.img`
    width: 2em;

    @media (max-width: 768px) {
        width: 1.8em;
    }

    @media (max-width: 480px) {
        width: 1.6em;
    }
`

const Signature = styled.img`
    height: 1.8em;
    margin-left: 1em;

    @media (max-width: 768px) {
        height: 1.6em;
        margin-left: 0.8em;
    }

    @media (max-width: 480px) {
        height: 1.4em;
        margin-left: 0.6em;
    }
`


const MenuLogo = styled.img`
    width: 3em;

    @media (max-width: 768px) {
        width: 3.5em;
        margin: 0.5em 0;
    }
`
const MenuTxt = styled.p`
    text-align: center;
    font-weight: bold;
    margin: 0.7em 0;
    font-size: 0.8em;
    width: 85%;
`

const Brand = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`


const MenuButton = styled.div`
    & > img {
        width: 1.8em;
    }

    @media (max-width: 768px) {
        padding: 0.4em;
        
        & > img {
            width: 1.6em;
        }
    }
`


const DarkModeContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 85%;
    padding: 0.4em;
    margin: 0.7em 0;
`


const Icon = styled.img`
    width: 40px;

    @media (max-width: 768px) {
        width: 28px;
    }
`


export default function TopBar() {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    let navigate = useNavigate();
    const path = useLocation().pathname
    const [ menuVisible, setMenuVisibible ] = useState(false)


    const bttns = [
        { icon: Stetho, text: 'Inicial', where: '/app' },
        { icon: KeyIcon, text: 'Cronogramas', where: '/track' },
        { icon: Flashcards, text: 'Flashcards', where: '/flashcards' },
        { icon: Residencia, text: 'Residência', where: '/residencia' },
        { icon: Trilha, text: 'Correção de Erros', where: '/mistakes' },
        { icon: NoteIcon, text: 'Caderno', where: '/notebook' },
        { icon: Graph, text: 'Estatísticas', where: '/statistics' },
        { icon: Setup, text: 'Configurações', where: '/user' },
        { icon: Home, text: 'Website', where: '/home' },
        // { icon: Bedside, text: 'Bedside', where: '/bedside' },
    ]


    function showMenu() {
        setMenuVisibible (!menuVisible)
    }


    function closeMenu() {
        // console.log('Scrolling')
        // window.scrollTo(0, 0) // acho que isso não tá funcionando????
        setMenuVisibible(false)
    }


    return (
        <div>
            {menuVisible && (
            <>
                <MenuContainer height={document.documentElement.scrollHeight}>

                    <MenuLogo src = {darkMode ? OslerLogoDark : OslerLogo} />

                    <MenuTxt>Não é fácil ser foda! 🔥</MenuTxt>

                    <DarkModeContainer>
                        <Icon src = {darkMode ? Moon : Sun} />
                        <DarkModeToggle />
                    </DarkModeContainer>

                    
                    {bttns.map((bttn, index) => (
                        <SideBarButton
                            key={index}
                            icon={bttn.icon}
                            text={bttn.text}
                            selected={bttn.where === path}
                            action={() => navigate(bttn.where)}
                        />
                    ))}
                </MenuContainer>

                <Overlay onClick={closeMenu} height={document.documentElement.scrollHeight} />
                </>
            )}


            {/* Barra do topo */}
            <Container>
                <MenuButton onClick={showMenu}>
                    <img src={Menu} alt="Menu" />
                </MenuButton>

                <Brand>
                    <Logo src = {darkMode ? OslerLogoDark : OslerLogo} />
                    <Signature src = {darkMode ? SignatureDark : SignatureIcon} />
                </Brand>

                <div></div> {/* Spacer */}
            </Container>
        </div>
    )
}