import { db } from './../firebase/firebase-setup'
import { dateIntoString, tagsFromArrayToFirebase } from './../utils/Utils'
import firebase from 'firebase/compat/app';
import MistakesJournalController from './MistakesJournalController';
/*
    Essa classe possui métodos que obtém e/ou atualizam as
    estatísticas específicas DE UM ÚNICO TESTE.
*/ 

class TestStatistics {
    constructor(userID, testType) {
        this.userID = userID
        this.testType = testType
    }


    async getTestStatistics(testID) {
        // Todo teste possui um documento individual sobre suas estatísticas.
        // Primeiro, e mais importante, o necessário para a repetição espaçada:
        //      - data para qual estava agendada sua revisão (due date), legível e em nanosegundos
        //      - seu ease factor
        //      - tipo (new test, lapsed, learning)
        //      - data em que foi visto pela última vez, legível e em nanosegundos
        //
        //
        // Segundo, importante para fins de debug, um registro detalhado de todas 
        // as respostas que já foram dadas a ele, em um array de objetos:
        //      {
        //          reviewDate:
        //              reviewDateReadable:
        //          levelOfSuccess: 
        //          resposta dada (se MCQ): 
        //      }
        //
        // Se esse documento não existe, derivamos que o teste nunca havia sido respondido.
        console.log(` * Baixando estatísticas de ${testID}`)
        try {
            const coll = db.collection("users").doc(this.userID).collection(this.testType).doc("tests").collection("reviewed tests")
            const doc = await coll.doc(testID).get()
    
            if (doc.exists) {
                const data = doc.data()
                return data
            }
            else {
                if (this.testType === 'Flashcards') {
                    return {
                        'easeFactor' : 2.5,
                        'type' : 'new',
                        'log' : []
                    }
                }
                else {
                    return {
                        'log' : []
                    }
                }

            }
        }
        catch (error) {
            console.log('\t ERROR - getTestStatistics()')
            console.log(`\t ERROR - ${testID} - ${this.userID} - ${this.testType}`)
            console.log(error)            
        }
    }



    calcNextReviewDate(testStats) {
        var secondsUntilNextReview;

        switch (testStats.type) {
            case 'new':
                secondsUntilNextReview = this.getNewTestSecondsUntilNextReview()
                break;
            case 'learning':
                secondsUntilNextReview = this.getLearningTestSecondsUntilNextReview(testStats)
                break;
            case 'lapsed':
                secondsUntilNextReview = this.getLapsedTestSecondsUntilNextReview(testStats)
                break;
            default:
                console.log("calcNextReviewDate(): error")
        }

        // É diferente 10min de agora e meia noite de um dia arbitrário.
        // De nota, multiplicamos por mil, pois Date() usa milissegundos
        const nextReviewDateInMs = [
            new Date().getTime() + secondsUntilNextReview[0] * 1000,
            this.getTodayMidnight().getTime() + secondsUntilNextReview[1] * 1000,
            this.getTodayMidnight().getTime() + secondsUntilNextReview[2] * 1000,
            this.getTodayMidnight().getTime() + secondsUntilNextReview[3] * 1000,
        ]

        return nextReviewDateInMs.map(dateInMs => {
            return new Date(dateInMs)
        })
    }



    getNewTestSecondsUntilNextReview() {
        // Para hard/easy/good, respectivamente, 1/2/4 dias.
        return this.intervalOptionsSanityEnforcer([1, 2, 4])
    }



    getLearningTestSecondsUntilNextReview(testStats) {
        // Precisamos calcular o intervalo desde a última resposta, e possivelmente
        // corrigir caso a revisão atual esteja atrasada ou desejemos usar um interval
        // modifier. Isso é feito em outra função.
        //
        // CI é current interval
        const CI = this.getCurrentInterval(testStats)

        const hard = Math.max(CI[0] * 1.2, CI[0] + 1)
        const good = Math.max(CI[1] * testStats.easeFactor, CI[1] + 1)
        const easy = Math.max(CI[2] * testStats.easeFactor * 1.3, CI[2] + 1)

        return this.intervalOptionsSanityEnforcer([hard, good, easy])
    }



    getCurrentInterval(testStats) {
        const daysSinceLastReview = this.daysSinceDate( new Date(testStats.lastReview) )
        const dueDate = new Date(testStats.nextReview)
        const today = this.getTodayMidnight();

        // Caso a revisão esteja atrasada (i.e., hoje é após nextReview), precisamos
        // corrigir o currentInterval em função do levelOfSuccess.
        //
        // De nota, poderíamos multiplicar por um interval modifier, se desejássemos.
        var hard, good, easy;

        if (today > dueDate) {
            console.log("Cartão está atrasado!")
            const result = this.getOriginalPlannedInterval(testStats, today)
            const originalPlannedInterval = result[0]
            const delay = result[1]

            hard = originalPlannedInterval
            good = originalPlannedInterval + Math.round(delay / 2)
            easy = originalPlannedInterval + delay
            
        }
        else {
            hard = good = easy = daysSinceLastReview;
        }

        // Como, se o cartão está atrasado, o retorno é dependente do levelOfSuccess,
        // tratamos todos os retornos como se fossem.
        return [hard, good, easy]
    }



    getOriginalPlannedInterval(testStats, today) {
        // Ocasionalmente, o usuário responde uma revisão atrasado,
        // ou seja, era para ele ver após 5 dias (original planned interval), 
        // mas ele viu após 11 (i.e., com um delay de 6 dias).
        const lastReview = new Date(testStats.lastReview)
        const nextReview = new Date(testStats.nextReview)

        const originalInterval = Math.round( this.daysBetweenDates(lastReview, nextReview) )
        const delay = Math.round( this.daysBetweenDates(nextReview, today) )

        return [originalInterval, delay]
    }



    getLapsedTestSecondsUntilNextReview(testStats) {
        // Fazemos algo que o Anki talvez não faça. Se o cartão é lapsed,
        // mas a revisão está atrasada, e o usuário ainda assim acertar, levamos isso em consideração.
        const daysSinceLastReview = this.daysSinceDate( new Date(testStats.lastReview) )


        if (testStats.lapseStep === 'first') {
            // Se hard / good / easy, respectivamente, 1 / 2 / 3 dias.
            // Salvo que atrasado.
            const easyOption = Math.max(3, daysSinceLastReview)
            return this.intervalOptionsSanityEnforcer([1, 2, easyOption])
        }
        else if (testStats.lapseStep === 'second') {
            // É o algoritmo padrão, com a única diferença
            // que utilizamos o correctedInterval ao invés
            // do real, e que temos um mínimo de espaçamento.
            const I = testStats.lapseCorrectedInterval;
            const E = testStats.easeFactor

            // Se o usuário atrasou a revisão do lapse...
            // Vamos dizer que o cartão foi visto há 5 dias atrás.
            // Não há sentido o easy, por exemplo, ser 3 dias.
            const hard = Math.max(I * 1.2, 2)
            const good = Math.max(I * E, 3)
            const easy = Math.max(I * E * 1.3, 4, daysSinceLastReview + 1)

            return this.intervalOptionsSanityEnforcer([hard, good, easy])
        }
        else {
            console.log("ERROR: card is lapse, but no step was given; will make it the first")
            const easyOption = Math.max(3, daysSinceLastReview)


            return this.intervalOptionsSanityEnforcer([1, 2, easyOption])
        }
    }



    intervalOptionsSanityEnforcer(intervals) {
        // Recebemos um array de três elementos, que indicam o intervalo
        // para a próxima revisão, em DIAS, em função do usuário ter
        // escolhido hard / good / easy.
        //
        // Arredondamos. Garantimos que easy > good > hard. Transformamos
        // em segundos. E adicionamos o intervalo do again de 10 minutos,
        // que é constante para todos os casos.

        var hard = Math.round(intervals[0])
        var good = Math.round(intervals[1])
        var easy = Math.round(intervals[2])

        if (hard < 1) {
            // Isso não deveria ocorrer, mas ok.
            hard = 1;
        }

        if (good <= hard) {
            good = hard + 1
        }

        if (easy <= good) {
            easy = good + 1
        }

        const daysToSecs = 24 * 60 * 60;
        const minsToSecs = 60;

        return [
            10 * minsToSecs,
            hard * daysToSecs,
            good * daysToSecs,
            easy * daysToSecs
        ]
    }



    daysSinceDate(date) {
        return this.daysBetweenDates(date, this.getTodayMidnight())
    }



    daysBetweenDates(d1, d2) {
        let diff = d1.getTime() - d2.getTime()
        let diffDays = Math.abs(diff) / (24 * 60 * 60 * 1000)

        return diffDays
    }



    getTodayMidnight() {
        let date = new Date();
        let year = date.getFullYear();
        let month = date.getMonth();
        let day = date.getDate();

        let todayMidnight = new Date(year, month, day, 0, 0, 0)

        return todayMidnight;
    }



    updateFlashcardStatistics(test, levelOfSuccess) {
        console.log('whaaaaaaaatchin?')
        const testStats = test.statistics
        const testID = test.testID

        const nextReviewDate = this.calcNextReviewDate(testStats)[levelOfSuccess]
        const today = this.getTodayMidnight()


        // Não precisamos modificar para lapse antes de calcNextReviewDate(),
        // pois será 10 minutos either way.
        var newType = testStats.type
        var lapsedData = undefined;
        
        if (testStats.type === 'learning' && levelOfSuccess == 0) {
            newType = 'lapsed'

            const originalPlannedInterval = this.getOriginalPlannedInterval(testStats, today)[0]

            // Aplicamos uma penalidade de 90%.
            // Arredondamos, com um mínimo de 01 dia de intervalo. Isso não é muito
            // necessário, pois nosso lapse algorithm já tem um mínimo de 2/3/4 no
            // segundo step, mas... 
            const correctedInterval = Math.max( Math.round(originalPlannedInterval * 0.1), 1)

            lapsedData = {
                'lapseStep' : 'first',
                'lapseCorrectedInterval' : correctedInterval
            }
        }
        else if (testStats.type === 'lapsed') {
            // Se está no primeiro, atualizamos para o segundo ou mantemos no atual.
            // Se está no segundo, atualizamos para learning, ou voltamos para o anterior.
            //
            // De exceção, se por algum bug, é lapsed, mas não temos o correctedInterval
            // calculamos do zero, como fizemos acima.
            if (!testStats.lapseCorrectedInterval) {
                console.log("ERROR: card type is lapsed, but does not have a lapseCorrectedInterval")

                const originalPlannedInterval = this.getOriginalPlannedInterval(testStats, today)[0]
                const correctedInterval = Math.max( Math.round(originalPlannedInterval * 0.1), 1)

                testStats.lapseCorrectedInterval = correctedInterval
            }


            if (levelOfSuccess != 0) {
                if (testStats.lapseStep === 'first') {
                    lapsedData = {
                        'lapseStep' : 'second',
                        'lapseCorrectedInterval' : testStats.lapseCorrectedInterval
                    }
                }
                else {
                    newType = 'learning'
                    lapsedData = undefined;
                }
            }
            else {
                lapsedData = {
                    'lapseStep' : 'first',
                    'lapseCorrectedInterval' : testStats.lapseCorrectedInterval
                }
            }
        }
        else if (testStats.type === 'new') {
            if (levelOfSuccess != 0) {
                newType = 'learning'
            }
            else {
                newType = 'lapsed'

                // O usuário está fazendo pela primeira vez, e errou. Não pode continuar
                // como 'new', do contrário nunca será contabilizado como revisão. MAs
                // não pode virar learning. Logo, será 'lapsed'.
                //
                // Para lanaçrmos como lapsed, precisamos adicionar uma lapsedData, como
                // fazemos acima.
                //
                // Porém, não há um intervalo originalmente planejado até agora. Só podemos
                // lançar como 1d.
                const originalPlannedInterval = this.getOriginalPlannedInterval(testStats, today)[0]
                const correctedInterval = Math.max( Math.round(originalPlannedInterval * 0.1), 1)

                lapsedData = {
                    'lapseStep' : 'first',
                    'lapseCorrectedInterval' : 1
                }
            }
        }


        // Atualizamos o ease factor.
        // De fato, utilizamos o type anterior, pois é o que importa.
        // E atualizamos o easeFactor *depois* de utilizá-lo, que parece ser
        // a conduta do Anki (wtf? TODO?).
        var easeFactor = testStats.easeFactor
        if (testStats.type === 'learning') {
            switch (levelOfSuccess) {
            case 0:
                    easeFactor -= 0.2;
                    break;
                case 1:
                    easeFactor -= 0.15;
                    break;
                case 2:
                    // Não alteramos
                    break;
                case 3:
                    easeFactor += 0.15;
                    break;
            }
        }

        // Tem um mínimo de easeFactor. Do contrário, selecionar good seria igual ou
        // pior a selecionar hard.
        easeFactor = Math.max(1.3, easeFactor)


        // Criamos um log específico a essa revisão, que é adicionado ao fim do array.
        // Se é uma questão de residência, inserimos a alternativa escolhida.        
        var log = {
            'date' : today.getTime(),
            'dateReadable' : dateIntoString(today),
            'levelOfSuccess' : levelOfSuccess,
            'type' : testStats.type,                        // TODO Considerar excluir no futuro
            'easeFactor' : testStats.easeFactor             // TODO Considerar excluir no futuro
        }


        const logHistory = testStats.log
        logHistory.push(log)


        // Salvamos o documento.
        var data = {
            "lastReviewReadable" : dateIntoString(today),
            "lastReview" : today.getTime(),
            'type' : newType,
            'easeFactor' : easeFactor,
            "nextReview" : nextReviewDate.getTime(),
            "nextReviewReadable" : dateIntoString(nextReviewDate),
            'log' : logHistory,
        }


        // Se lapsed, adicionar mais dados...
        if (lapsedData) {
            data = Object.assign({}, data, lapsedData)
        }

        return [newType, nextReviewDate.getTime(), data]
    }



    updateResidenciaStatistics(test, metacognition, mcqAnswer, gaveRightAnswer) {
        // Desde Jun 2024, as estatísticas das questões de residência são diferentes.
        const testStats = test.statistics
        const today = this.getTodayMidnight()


        // Criamos um log específico a essa revisão, que é adicionado ao fim do array.
        var log = {
            'date' : today.getTime(),
            'dateReadable' : dateIntoString(today),
            'gaveRightAnswer' : gaveRightAnswer,
            'mcqAnswer' : parseInt(mcqAnswer),
            'metacognition' : metacognition,
        }
        
        const logHistory = testStats.log
        logHistory.push(log)


        let data = {
            'lastReviewReadable' : dateIntoString(today),
            'lastReview' : today.getTime(),
            'log' : logHistory,
        }

        if (['rightGuess', 'wrongConcept', 'wrongDistraction'].includes(metacognition)) {
            // Revisão é em +7 dias
            const nextReviewDate = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000)

            data['nextReview'] = nextReviewDate.getTime()
            data['nextReviewReadable'] = dateIntoString(nextReviewDate)

            MistakesJournalController.addAsReview(test.testID)
        } 
        else {
            data['nextReview'] = firebase.firestore.FieldValue.delete()
            data['nextReviewReadable'] = firebase.firestore.FieldValue.delete()

            MistakesJournalController.changeToReviewed(test.testID)
        }


        return [metacognition, data['nextReview'], data]
    }



    readableTimeUntilNextReview(testStats) {
        // Retorna um array de objetos Date, para levelOfSuccess de 0 a 3
        // Precisamos transformar em algo readable. De nota, o migué:
        // já sabemos que, para levelOfSuccess = 0, é 10 min.
        const nextReviewDate = this.calcNextReviewDate(testStats)
        const today = this.getTodayMidnight()

        const readable = nextReviewDate.slice(1).map(date => {
            const diff = this.daysBetweenDates(today, date)
            if (diff == 1) {
                return diff + ' dia'
            }
            else {
                return diff + ' dias'
            }
        })
        
        return [
            '10 min',
            ...readable
        ]
    }



    // getSecondsUntilNextReview(levelOfSuccess, daysSinceLastReview) {
    //     /*
    //          O usuário errou. Faremos lastReview = now e nextReviewDate = hoje + 10 minutos.
    //          Deste modo, se ele voltar a acertar o cartão ainda hoje, teremos
    //          daysSinceLastReview < 1.0
    //          Logo, a próxima revisão será amanhã -- reiniciando o cartão.

    //          Se ele acertar o cartão só amanhã, teremos:
    //          daysSinceLastReview > 1.0
    //          Logo, a próxima revisão será após ~2 dias.

    //          ---

    //          O usuário acertou.
    //          O intervalo de tempo até a próxima revisão é uma função do intervalo de tempo desde
    //          a última revisão (afinal, a repetição é espaçada). Então, começamos calculando-o.



    //             Agora, utilizamos a informação acima para calcular o número de dias até a próxima
    //         revisão. O algoritmo inicial é tosco, mas ao menos é exponencial (espaçado) e varia
    //         em função da dificuldade de acerto:
    //                     fácil:      dias desde a última revisão * 1.8
    //                     médio:      dias desde a última revisão * 1.4
    //                     difícil:    dias desde a última revisão * 1.2

    //             Há uma exceção: o primeiro intervalo é de 1d (senão, teríamos 0 * 1,4 = 0, por
    //          exemplo). Existe um script em Python na past Osler/ que mostra a progressão para
    //          diferentes coeficientes.
    //     */

    //     if (levelOfSuccess == 0) {
    //         // 10 minutos até a próxima revisão
    //         return 10 * 60;
    //     }
    //     else {
    //         return this.getDaysUntilNextReview(daysSinceLastReview, levelOfSuccess) * 24 * 60 * 60;
    //     }
    // }


    // getDaysUntilNextReview(daysSinceLastReview, levelOfSuccess) {
    //     const coef = this.getCoefficientFromLevelSuccess(levelOfSuccess);

    //     if (daysSinceLastReview == 0) {
    //         return this.getFirstIntervalFromLevelSuccess(levelOfSuccess);
    //     } else {
    //         return (Math.round(daysSinceLastReview * coef));
    //     }
    // }


    // getCoefficientFromLevelSuccess(levelSuccess) {
    //     if (levelSuccess == 1) {
    //         return 1.2;
    //     } else if (levelSuccess == 2) {
    //         return 1.4;
    //     } else {
    //         return 1.8;
    //     }
    // }


    // getFirstIntervalFromLevelSuccess(levelSuccess) {
    //     if (levelSuccess == 1) {
    //         return 1;
    //     } else if (levelSuccess == 2) {
    //         return 2;
    //     } else {
    //         return 4;
    //     }
    // }

}

export default TestStatistics
   


