import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: 'loading',
    initialState: {
        isLoading: false
    },
    reducers: {
        setIsLoading: (state, action) => {
            // action has a .payload (data passed)
            // and a .type (automatically generated,
            // i.e., user/saveUser)
            state.isLoading = action.payload
        },
    }
})

export const { setIsLoading } = loadingSlice.actions
export default loadingSlice.reducer