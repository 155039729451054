import React, { useState, useEffect } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import GeneralDialog from './GeneralDialog'


export default function ClozeDialog(props) {

    const [showDialog, setShowDialog] = useState(false)

    // Por que só esse diálogo tem essa merda?
    useEffect(() => {
        if (props.showDialog != showDialog) {
            setShowDialog(props.showDialog)
        }
    }, [props.showDialog])

    return (
        // Dentro de ClozeDialog.js
        <GeneralDialog
            open={props.showDialog}
            title="⚠️ Você tem certeza?"
            onClose={props.handleCancel}
            actions={[
                { label: 'Cancelar', onClick: props.handleCancel },
                { label: 'Sim, enterrar', onClick: props.handleOk, style: { backgroundColor: 'rgb(191, 12, 12)', color: 'white' } }
            ]}
        >
            <p>Ao <u>enterrar</u> um teste, ele não será exibido novamente. Ou seja, não aparecerá nas revisões pendentes ou com os demais testes do tema.</p>
            <p>É um modo de "esconder" flashcards ou questões que você acha pouco produtivos.</p>
            <p>Se você se arrepender, você poderá <u>desenterrar</u> na tela de configurações.</p>
        </GeneralDialog>

    )
}