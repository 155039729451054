import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraAPGAR() {
    const [frequenciaCardiaca, setFrequenciaCardiaca] = useState('');
    const [respiracao, setRespiracao] = useState('');
    const [tonusMuscular, setTonusMuscular] = useState('');
    const [cor, setCor] = useState('');
    const [irritabilidadeReflexa, setIrritabilidadeReflexa] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularAPGAR = () => {
        let pontos = 0;

        if (frequenciaCardiaca === '> 100 / minuto') {
            pontos += 2;
        } else if (frequenciaCardiaca === '< 100 / minuto') {
            pontos += 1;
        }

        if (respiracao === 'Forte / Choro') {
            pontos += 2;
        } else if (respiracao === 'Fraca, irregular') {
            pontos += 1;
        }

        if (tonusMuscular === 'Movimento ativo / Boa flexão') {
            pontos += 2;
        } else if (tonusMuscular === 'Flexão de pernas e braços') {
            pontos += 1;
        }

        if (cor === 'Rosado') {
            pontos += 2;
        } else if (cor === 'Cianose de extremidades') {
            pontos += 1;
        }

        if (irritabilidadeReflexa === 'Espirros / Choro') {
            pontos += 2;
        } else if (irritabilidadeReflexa === 'Algum movimento') {
            pontos += 1;
        }

        setResultado(`Pontuação de APGAR: ${pontos}`);

        if (pontos >= 7) {
            setInterpretacao('Valores ≥ 7 são considerados normais.');
        } else if (pontos >= 4 && pontos <= 6) {
            setInterpretacao('Valores de 4-6 são considerados baixos.');
        } else {
            setInterpretacao('Valores 0-3 são considerados críticos e necessitam de intervenção de urgência.');
        }
    };

    useEffect(() => {
        if (frequenciaCardiaca && respiracao && tonusMuscular && cor && irritabilidadeReflexa) {
            calcularAPGAR();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [frequenciaCardiaca, respiracao, tonusMuscular, cor, irritabilidadeReflexa]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escore da APGAR</h1>
            <div className='form-gad7'>
            <div>
                <label>Frequência Cardíaca</label>
                <select value={frequenciaCardiaca} onChange={e => setFrequenciaCardiaca(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Ausente">Ausente</option>
                    <option value="< 100 / minuto">&lt; 100 / minuto</option>
                    <option value="> 100 / minuto">&gt; 100 / minuto</option>
                </select>
            </div>
            <div>
                <label>Respiração</label>
                <select value={respiracao} onChange={e => setRespiracao(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Fraca, irregular">Fraca, irregular</option>
                    <option value="Forte / Choro">Forte / Choro</option>
                </select>
            </div>
            <div>
                <label>Tônus Muscular</label>
                <select value={tonusMuscular} onChange={e => setTonusMuscular(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Flácido">Flácido</option>
                    <option value="Flexão de pernas e braços">Flexão de pernas e braços</option>
                    <option value="Movimento ativo / Boa flexão">Movimento ativo / Boa flexão</option>
                </select>
            </div>
            <div>
                <label>Cor</label>
                <select value={cor} onChange={e => setCor(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Cianótico / Pálido">Cianótico / Pálido</option>
                    <option value="Cianose de extremidades">Cianose de extremidades</option>
                    <option value="Rosado">Rosado</option>
                </select>
            </div>
            <div>
                <label>Irritabilidade Reflexa</label>
                <select value={irritabilidadeReflexa} onChange={e => setIrritabilidadeReflexa(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Algum movimento">Algum movimento</option>
                    <option value="Espirros / Choro">Espirros / Choro</option>
                </select>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraAPGAR;
