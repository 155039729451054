// Novembro 2024
//      Tanto FlashcardAnswerJSX quanto McqAnswerJSX precisam ser componentes
//      separados porque são usados no modo consulta.  

import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import AnswerScreenHelperBttns from './AnswerScreenHelperBttns'
import FeedbackArea from './FeedbackArea'
import PersonalNoteJSX from './PersonalNoteJSX'
import session from './../controllers/Session'
import TestScreen from './TestScreen'
import { TestArea } from './FlashcardsStyles';
import McqFeedbackButtons from './McqFeedbackButtons'
import MCQAnswerJSX from './MCQAnswerJSX'
import StickyIcon from './../assets/sticky-note.png'
import iPadIcon from './../assets/personal_note.png'
import { useDispatch, useSelector } from 'react-redux'
import { setShowNotebook } from '../redux/notebookSlice'
import { tagSequenceToPath } from '../utils/Utils'
import NotebookController from '../controllers/NotebookController'
import { useHotkeys } from 'react-hotkeys-hook'
import { ColumnCSS } from '../components/BasicComponents'
import AnswerScreenNavBttns from './AnswerScreenNavBttns'



const ProceedBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin-top: 1em;
    padding: 2em;

    @media (max-width: 500px) {
        padding: 0 0 1em 0;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    text-transform: uppercase;

    background-color: ${props => props.bg};

    color: white;

    max-width: 30%;


    padding: 1em;
    border-radius: 0.5em;
    font-weight: bold;
    text-align: center;

    &:hover {
        background-color: ${props => props.bgHover};
        cursor: pointer;
    }

    img {
        height: 1.8em;
        margin-right: 0.5em;
    }

    p {
        font-size: 1em;
        margin: 0;
    }

    @media (max-width: 500px) {
        img {
            height: 1.3em;
            margin-right: 0.5em;
        }
        p {
            font-size: 0.8em;
        }
    }   
`

const FeedbackBttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    margin-top: 2em;
    width: 100%;

    @media (max-width: 500px) {
        margin-bottom: 1em;
    }
`

const BttsContainer = styled.div`
    width: 100%;
    ${ColumnCSS}
`

const NavigationButtons = styled(FeedbackBttnsContainer)`
    justify-content: space-between;
    max-width: 300px;
    margin: 2em auto;
`

const NavButton = styled.button`
    background-color: #4A90E2;
    color: white;
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 0.5em;
    font-weight: 600;
    cursor: pointer;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);

    &:hover {
        background-color: #357ABD;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`


function Button({ icon, text, onClick, bg = 'rgba(90, 90, 90, 0.8)', bgHover = 'rgba(90, 90, 90, 1.0)' }) {
    return (
        <ButtonContainer
            onClick={onClick}
            bg={bg}
            bgHover={bgHover} >
            <img src={icon} />
            <p>{text}</p>
        </ButtonContainer>
    )

}


export default function McqAnswerScreen({ test, registerAnswer, alreadyAnswered }) {
    const [showFeedbackArea, setShowFeedbackArea] = useState(false)
    const [userMetacognition, setUserMetacogniton] = useState(false)
    const [showPersonalNote, setShowPersonalNote] = useState(false)
    const [shortcutsEnabled, setShortcutsEnabled] = useState(true)

    const dispatch = useDispatch()
    const showNotebook = useSelector(state => state.notebook.showNotebook)


    useEffect(() => {
        const note = session.getCurrentTest().personalNote
        if (note) {
            setShowPersonalNote(true)
        }
    }, [])

    function startFeedback() {
        setShowFeedbackArea(true)
        setShortcutsEnabled(false)
    }


    function stopFeedback() {
        setShortcutsEnabled(true)
        setShowFeedbackArea(false)
    }


    function startPersonalNote() {
        setShowPersonalNote(true)
        setShortcutsEnabled(false)
    }


    function logFeedback(metacognition) {
        setUserMetacogniton(metacognition)


        registerAnswer(metacognition)
    }

    function proceed(metacognition) {
        // Não sei se justifica-se hoje em dia, afinal... o usuário pode sóv oltar.
        dispatch(setShowNotebook(false))
        registerAnswer(userMetacognition)
    }


    function proceedThroughShortcut() {
        if (!showFeedbackArea && (userMetacognition || session.mode === 'playground-mode')) {
            proceed()
        }
    }

    useHotkeys('space', proceedThroughShortcut, { enabled: shortcutsEnabled && !alreadyAnswered })
    useHotkeys('enter', proceedThroughShortcut, { enabled: shortcutsEnabled && !alreadyAnswered })



    return (
        <TestArea>

            <MCQAnswerJSX
                test={test}
                consultMode={false} />

            <BttsContainer>
                {!showFeedbackArea && (
                    <AnswerScreenHelperBttns
                        startFeedback={startFeedback}
                        startPersonalNote={startPersonalNote}
                        showAddNote={!showPersonalNote}
                    />
                )}

                {(showPersonalNote && !showFeedbackArea) && (
                    <PersonalNoteJSX
                        test={test}
                        close={() => setShowPersonalNote(false)}
                    />
                )}

                {showFeedbackArea ? (
                    <FeedbackArea
                        test={test}
                        testType={'Residencia'}
                        close={stopFeedback}
                    />
                ) : !alreadyAnswered ? (
                    <FeedbackBttnsContainer>
                        <McqFeedbackButtons
                            // Teoricamente, ambos são números, mas a comparação com ==...
                            // dá mais tranquilidade
                            gaveRightAnswer={test.answer == session.mcqChosenAnswer}
                            logFeedback={logFeedback}
                        />
                    </FeedbackBttnsContainer>
                ) : (
                    <AnswerScreenNavBttns />
                )}
            </BttsContainer>

            {/* 

                            { !showFeedbackArea && !showPersonalNote && 
                                <AnswerScreenHelperBttns
                                    startFeedback = {startFeedback}
                                    showAddNote = {false} />
                            }


                            { showFeedbackArea && 
                                <FeedbackArea
                                    test = {test.current}
                                    testType = {'Residencia'}
                                    close = {stopFeedback} />
                            }

                                    
                            { !showFeedbackArea && (!userMetacognition && session.mode !== 'playground-mode') &&
                                <McqFeedbackButtons
                                    gaveRightAnswer = {test.current.answer == session.mcqChosenAnswer }
                                    logFeedback = {logFeedback} />
                            }


                            { showPersonalNote && userMetacognition && !showFeedbackArea &&
                                <PersonalNoteJSX
                                    close = {() => setShowPersonalNote(false) }
                                    test = { test.current } />                                    
                            }

                            { !showFeedbackArea && (userMetacognition || session.mode === 'playground-mode') &&
                                <ProceedBttns>
                                    { !showNotebook &&
                                        <Button 
                                            onClick = {() => {
                                                const tagpath = tagSequenceToPath(test.current.tags)
                                                NotebookController.saveTagpathRecommendation(tagpath)
                                                dispatch(setShowNotebook(true))
                                            }}
                                            icon = {iPadIcon}
                                            text = "Caderno" />
                                                                        
                                    }

                                    { !showPersonalNote &&
                                        <Button 
                                            onClick = {() => startPersonalNote(true)}
                                            icon = {StickyIcon}
                                            text = "Anotação" />
                                    }
                                        
                                    <Button
                                        onClick = {() => proceed()}
                                        bg = 'rgba(38, 120, 196, 0.8)'
                                        bgHover = 'rgba(38, 120, 196, 1.0)'
                                        text = 'PRÓXIMA QUESTÃO' />

                                </ProceedBttns>
                            } */}

        </TestArea>



        // </Container>
    )
}