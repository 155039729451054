import { createSlice } from '@reduxjs/toolkit'

export const notebookSlice = createSlice({
    name: 'notebook',
    initialState: {
        showNotebook: false,
        showSidebar: true,
    },
    reducers: {
        setShowNotebook: (state, action) => {
            // action has a .payload (data passed)
            // and a .type (automatically generated,
            // i.e., user/saveUser)
            state.showNotebook = action.payload
        },

        setShowSidebar: (state, action) => {
            state.showSidebar = action.payload
        }
    }
})

export const { setShowNotebook, setShowSidebar } = notebookSlice.actions
export default notebookSlice.reducer