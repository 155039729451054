import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraAlvarado() {
    const [anorexia, setAnorexia] = useState(false);
    const [nauseaVomitos, setNauseaVomitos] = useState(false);
    const [dorMigratoria, setDorMigratoria] = useState(false);
    const [defesaAbdominal, setDefesaAbdominal] = useState(false);
    const [defesaDescompressao, setDefesaDescompressao] = useState(false);
    const [temperatura, setTemperatura] = useState(false);
    const [leucocitose, setLeucocitose] = useState(false);
    const [desvioEsquerda, setDesvioEsquerda] = useState(false);
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularEscoreAlvarado = () => {
        let pontos = 0;

        if (anorexia) pontos += 1;
        if (nauseaVomitos) pontos += 1;
        if (dorMigratoria) pontos += 1;
        if (defesaAbdominal) pontos += 2;
        if (defesaDescompressao) pontos += 1;
        if (temperatura) pontos += 1;
        if (leucocitose) pontos += 2;
        if (desvioEsquerda) pontos += 1;

        setResultado(`Pontuação de Escore de Alvarado: ${pontos}`);

        if (pontos <= 3) {
            setInterpretacao('Baixa probabilidade de apendicite. Pode ser observado ambulatorialmente (sensibilidade 96%).');
        } else if (pontos <= 6) {
            setInterpretacao('Necessita de avaliação de um cirurgião e pode se beneficiar da realização de exame de imagem.');
        } else {
            setInterpretacao('Alta probabilidade de apendicite. Necessidade de internação hospitalar deve ser julgada individualmente.');
        }
    };

    useEffect(() => {
        calcularEscoreAlvarado();
    }, [anorexia, nauseaVomitos, dorMigratoria, defesaAbdominal, defesaDescompressao, temperatura, leucocitose, desvioEsquerda]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escore de Alvarado (Apendicite)</h1>
            <div className='form-gad7'>
            <div>
                <label>
                    <input type="checkbox" checked={anorexia} onChange={e => setAnorexia(e.target.checked)} />
                    Anorexia (1 ponto)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={nauseaVomitos} onChange={e => setNauseaVomitos(e.target.checked)} />
                    Náusea / Vômitos (1 ponto)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={dorMigratoria} onChange={e => setDorMigratoria(e.target.checked)} />
                    Dor típica migratória (1 ponto)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={defesaAbdominal} onChange={e => setDefesaAbdominal(e.target.checked)} />
                    Defesa abdominal (QID) (2 pontos)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={defesaDescompressao} onChange={e => setDefesaDescompressao(e.target.checked)} />
                    Defesa à descompressão (1 ponto)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={temperatura} onChange={e => setTemperatura(e.target.checked)} />
                    Temperatura maior que 37.3°C (1 ponto)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={leucocitose} onChange={e => setLeucocitose(e.target.checked)} />
                    Leucocitose maior que 10.000/mm³ (2 pontos)
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={desvioEsquerda} onChange={e => setDesvioEsquerda(e.target.checked)} />
                    Desvio à esquerda (1 ponto)
                </label>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraAlvarado;
