import React, { useEffect, useMemo, useState } from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table'
import { sortMonths } from '../utils/Utils'
import AmbassadorsController from './AmbassadorsController'


export default function MonthlyConsolidatedCommissionsTable(props) {

    const monthsAbbr = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]


    function prepareMonthlyTotalComissions() {
        // Para cada mês, calculamos o total em vendas, comissões, número de vendas, e embaixadores
        // individuais com vendas.
        const monthlyData = {};

        // Esse forEach roda para cada subdicionário, que tem dados de um embaixador específico,
        // e já desmembrama em coupon e total (que não remos ver) de months (que queremos).
        AmbassadorsController.consolidatedComissions['data'].forEach(({coupon, total, ...months}) => {
            Object.entries(months).forEach(([month, values]) => {
                if (!monthlyData[month]) {
                    monthlyData[month] = { comission: 0, total_in_sales: 0, total_sales: 0, ambassadors_with_sales: 0 }
                }

                ['comission', 'total_in_sales', 'total_sales'].forEach(key => {
                    monthlyData[month][key] += values[key] ?? 0
                })

                if (values['total_sales'] >= 1) {
                    monthlyData[month]['ambassadors_with_sales'] += 1
                }
            })
        })

        for (let month of Object.keys(monthlyData)) {
            const ambassadors_with_sales = monthlyData[month]['ambassadors_with_sales']

            if (ambassadors_with_sales !== 0) {
                monthlyData[month]['avg_sales_per_ambassador'] = monthlyData[month]['total_sales'] / ambassadors_with_sales
            } else {
                monthlyData[month]['avg_sales_per_ambassador'] = 0
            }

            if (monthlyData[month]['total_sales'] !== 0) {
                monthlyData[month]['avg_ticket'] = monthlyData[month]['total_in_sales'] / monthlyData[month]['total_sales']
            } else {
                monthlyData[month]['avg_ticket'] = 0
            }
        }

        console.log('\t\t\t\t!!!!!!!!')
        for (let month of AmbassadorsController.monthlyAmbassadorCount) {
            let monthStr = month['month']

            try {
                monthlyData[monthStr]['total_ambassadors'] = month['cumulativeAmbassadors']
                monthlyData[monthStr]['percentage_ambassadors_sold'] = (monthlyData[monthStr]['ambassadors_with_sales'] / monthlyData[monthStr]['total_ambassadors']) * 100
            } catch (e) {
                console.log(e)
                console.log('Provavelmente precisa rodar wrap comission para o mês atual')
            }
        }

        return {
            'data': Object.entries(monthlyData).map(([month, data]) => {
                return {
                    'month': month,
                    ...data
                }
            })
        }
    }

    const monthlyConsolidatedComissionsColumn = useMemo(() => [
        {
            accessorKey: 'month',
            header: 'Mês',
            Cell: ({ cell }) => {
                const date = cell.getValue()
                const [year, month] = date.split('_')
                const formattedDate = `${monthsAbbr[parseInt(month, 10) - 1]}/${year.slice(-2)}`
                return formattedDate
            },
            sortingFn: (rowA, rowB, columnId) => {
                return sortMonths(rowA.original.month, rowB.original.month)
            }
        },
        {
            accessorKey: 'comission',
            header: 'Comissões (R$)',
            Cell: ({ cell }) => {
                const roundedValue = Math.round(cell.getValue())
                return `${roundedValue.toLocaleString('pt-BR')}`
            }
        },
        {
            accessorKey: 'total_in_sales',
            header: 'Vendas (R$)',
            Cell: ({ cell }) => {
                const roundedValue = Math.round(cell.getValue())
                return `${roundedValue.toLocaleString('pt-BR')}`
            }
        },
        {
            accessorKey: 'total_sales',
            header: 'Vendas (#)',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR'),
        },
        {
            accessorKey: 'ambassadors_with_sales',
            header: 'Embaixadores que venderam (#)',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR')
        },
        {
            accessorKey: 'avg_sales_per_ambassador',
            header: 'Entre vendedores, média de vendas',
            Cell: ({ cell }) => (Math.round(cell.getValue() * 10) / 10).toLocaleString('pt-BR')
        },
        {
            accessorKey: 'percentage_ambassadors_sold',
            header: '% embaixadores que vendeu',
            Cell: ({ cell }) => (Math.round(cell.getValue() * 10) / 10).toLocaleString('pt-BR')
        },
        {
            accessorKey: 'avg_ticket',
            header: 'Ticket Médio (R$)',
            Cell: ({ cell }) => Math.round(cell.getValue()).toLocaleString('pt-BR')
        },
    ], [])


    const data = useMemo(() => {
        return prepareMonthlyTotalComissions()['data']
    }, [])


    const monthlyConsolidatedCommissions = useMaterialReactTable({
        data: data,
        columns: monthlyConsolidatedComissionsColumn,
        initialState: {
            density: 'compact',
            sorting: [{ id: 'month', desc: true }],
        }
    })

    return (
        <div className='AmbassadorDashboard-Table'>
            <MaterialReactTable table = {monthlyConsolidatedCommissions} />
        </div>
    )
}