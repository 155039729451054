import React from 'react'
import styled from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'

const ExamCard = styled.div`
    // width: 280px;
    width: 45%;

    display: flex;
    flex-direction: column;

    border-radius: 12px;
    overflow: hidden;
    transition: all 0.3s ease;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
        }
    ` : `
        background-color: #ffffff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);

        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
        }
    `}


    @media (max-width: 768px) {
        width: calc(100% - 1em);
        min-width: unset;
        
        &:hover {
            transform: none;
            box-shadow: ${props => props.theme.darkMode ? 
                '0 4px 12px rgba(0, 0, 0, 0.2)' : 
                '0 4px 12px rgba(0, 0, 0, 0.05)'};
        }
    }
`

const ExamHeader = styled.div`
    padding: 12px;
    text-align: center;
    font-size: 1.3em;
    font-weight: 600;

    ${props => props.theme.darkMode ? `
        background-color: rgb(41, 41, 41);
        color: rgb(220, 220, 220);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    ` : `
        background-color: #f0f4f8;
        color: #2c3e50;
        border-bottom: 1px solid #e1e8ed;
    `}

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 10px;
    }
`

const ExamContent = styled.div`
    display: flex;
    flex-direction: column;

    padding: 16px;

    @media (max-width: 768px) {
        padding: 12px;
    }
`

const ExamStat = styled.div`
    margin-bottom: 8px;
    font-size: 0.85em;
    display: flex;
    justify-content: space-between;

    span:first-child {
        font-weight: 500;
        color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#4a5568'};
    }

    span:last-child {
        font-weight: 600;
        color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#2c3e50'};
    }

    @media (max-width: 768px) {
        font-size: 0.7em;
        margin-bottom: 6px;
    }
`

const StartButton = styled.button`
    padding: 8px 12px;
    font-size: 0.9em;
    font-weight: 500;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;

    margin-top: 2em;
    align-self: flex-end;

    ${props => props.theme.darkMode ? `
        background-color: transparent;
        color: #60a5fa;
        border: 1px solid #60a5fa;

        &:hover {
            background-color: #60a5fa;
            color: rgb(41, 41, 41);
        }

        svg {
            margin-left: 8px;
            font-size: 0.8em;
        }
    ` : `
        background-color: transparent;
        color: #3498db;
        border: 1px solid #3498db;

        &:hover {
            background-color: #3498db;
            color: #ffffff;
        }

        svg {
            margin-left: 8px;
            font-size: 0.8em;
        }
    `}

    &:hover {
        background-color: #3498db;
        color: #ffffff;
    }

    svg {
        margin-left: 8px;
        font-size: 0.8em;
    }

    @media (max-width: 768px) {
        width: 100%;
        margin-top: 1em;
        padding: 0.5em;
        font-size: 0.85em;
    }
`

export default function Exam({institution, institution_id, year, examType, examData, prepareDialog}) {
    const completionPercentage = (examData.testsAnswered / examData.nTests * 100).toFixed(1)

    return (
        <ExamCard>
            <ExamHeader>{year}</ExamHeader>
            <ExamContent>
                <ExamStat>
                    <span>Total de testes:</span>
                    <span>{examData.nTests}</span>
                </ExamStat>
                <ExamStat>
                    <span>Respondidos:</span>
                    <span>{examData.testsAnswered} ({completionPercentage}%)</span>
                </ExamStat>
                <ExamStat>
                    <span>Acerto 1ª tentativa:</span>
                    <span>{examData.firstAnswerPercentage.toFixed(1)}%</span>
                </ExamStat>
                <ExamStat>
                    <span>Acerto atual:</span>
                    <span>{examData.lastAnswerPercentage.toFixed(1)}%</span>
                </ExamStat>

                <StartButton onClick={() => prepareDialog(institution_id, year, institution)}>
                    Começar
                    <FaArrowRight />
                </StartButton>
            </ExamContent>
        </ExamCard>
    )
}