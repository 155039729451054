import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ResidenciaIcon from './../assets/residencia.png';
import FlashcardIcon from './../assets/flashcard.png';

const MSCardNewBttnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 15vh;
  border-radius: 2em;
  padding: 1em;
  cursor: pointer;
  font-weight: bold;

  ${props => props.theme.darkMode ? `
    background-color: rgb(117, 117, 117);
    box-shadow: none;
    color: rgb(214, 214, 214);

    &:hover {
      background-color: rgb(180, 180, 180);
    }
  ` : `
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    color: rgb(65, 65, 65);

    &:hover {
      box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
    }

    &:active {
      box-shadow: none;
    }
  `}
`;

const ButtonsContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  align-self: center;
  margin-top: 2em;
  margin-bottom: 2em;
  cursor: pointer;
`;

const AlreadyDone = styled(MSCardNewBttnContainer)`
  background-color: rgba(35, 146, 7, 0.3) !important;
  box-shadow: none !important;
  cursor: auto;

  &:hover {
    box-shadow: none !important;
  }
`;

const BttnTxt = styled.p`
  margin: 0;
  padding: 0;
`;

const TxtDone = styled(BttnTxt)`
  color: rgb(35, 146, 7);
  font-size: 1.5em !important;
`;

const BttnIcon = styled.img`
  width: 30%;

  @media (max-width: 900px) {
    width: 1.8em;
  }
`

export default function MSCardNewBttn(props) {
  const [JSX, setJSX] = useState(<></>);

  useEffect(() => {
    loadContent();
  }, [props]);

  function getIndividualButtons(nTests) {
    if (nTests === 0) {
      return [
        AlreadyDone,
        (<TxtDone>✓</TxtDone>)
      ];
    } else {
      return [
        MSCardNewBttnContainer,
        (<BttnTxt>{nTests}</BttnTxt>)
      ];
    }
  }

  function loadContent() {
    const [LeftButton, leftContent] = getIndividualButtons(props.residencia);
    const [RightButton, rightContent] = getIndividualButtons(props.flashcards);

    setJSX(
      <>
        <ButtonsContainer>
          <LeftButton onClick={() => props.residenciaAction()}>
            <BttnIcon src={ResidenciaIcon} />
            {leftContent}
          </LeftButton>

          <RightButton onClick={() => props.flashcardsAction()}>
            <BttnIcon src={FlashcardIcon} />
            {rightContent}
          </RightButton>
        </ButtonsContainer>
      </>
    );
  }

  return (
    <>
      {JSX}
    </>
  );
}