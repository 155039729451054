import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraDAS28VHS() {
    const [tjc, setTjc] = useState('');
    const [sjc, setSjc] = useState('');
    const [vhs, setVhs] = useState('');
    const [gh, setGh] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularDAS28VHS = () => {
        const tjcNum = parseFloat(tjc);
        const sjcNum = parseFloat(sjc);
        const vhsNum = parseFloat(vhs);
        const ghNum = parseFloat(gh);

        if (!isNaN(tjcNum) && !isNaN(sjcNum) && !isNaN(vhsNum) && !isNaN(ghNum)) {
            const das28 = (0.56 * Math.sqrt(tjcNum)) + (0.28 * Math.sqrt(sjcNum)) + (0.7 * Math.log(vhsNum)) + (0.014 * ghNum);
            setResultado(`DAS28-VHS: ${das28.toFixed(2)}`);
            
            if (das28 < 2.6) {
                setInterpretacao('Remissão');
            } else if (das28 >= 2.6 && das28 <= 3.2) {
                setInterpretacao('Baixa atividade da doença');
            } else if (das28 > 3.2 && das28 <= 5.1) {
                setInterpretacao('Atividade moderada da doença');
            } else if (das28 > 5.1) {
                setInterpretacao('Alta atividade da doença');
            }
        } else {
            setResultado('Preencha todos os campos com valores válidos.');
            setInterpretacao('');
        }
    };

    useEffect(() => {
        calcularDAS28VHS();
    }, [tjc, sjc, vhs, gh]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora DAS-28 VHS (Artrite Reumatoide)</h1>
            <div>
                <label>Articulações Dolorosas (Contagem: 0-28):</label>
                <input type="number" value={tjc} onChange={e => setTjc(e.target.value)} />
            </div>
            <div>
                <label>Articulações Edemaciadas (Contagem: 0-28):</label>
                <input type="number" value={sjc} onChange={e => setSjc(e.target.value)} />
            </div>
            <div>
                <label>VHS (mm/hora):</label>
                <input type="number" value={vhs} onChange={e => setVhs(e.target.value)} />
            </div>
            <div>
                <label>Estado Geral de Saúde (GH):</label>
                <input type="number" value={gh} onChange={e => setGh(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
            
        </div>
    );
}

export default CalculadoraDAS28VHS;
