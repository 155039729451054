import { combineReducers, configureStore, getDefaultMiddleware  } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
 
import userReducer from './userSlice'
import loadingReducer from './loadingSlice'
import pathReducer from './pathSlice'
import themeReducer from './themeSlice'
import notebookReducer from './notebookSlice'


export const rootReducer = combineReducers({
    user: userReducer,
    loading: loadingReducer,
    path: pathReducer,
    theme: themeReducer,
    notebook: notebookReducer,
})


const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,

    // Avoid error msg 
    // https://github.com/rt2zz/redux-persist/issues/988
    // middleware: getDefaultMiddleware({
    //     serializableCheck: false,
    //   }),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(),
})

export const persistor = persistStore(store)