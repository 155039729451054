import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { erf } from 'mathjs';
import '../bedside/CalcPage.css';

const lmsDataFem = [
    { age: 24, L: -0.98660853, M: 16.42339664, S: 0.085451785 },
    { age: 24.5, L: -1.024496827, M: 16.38804056, S: 0.085025838 },
    { age: 25.5, L: -1.102698353, M: 16.3189719, S: 0.084214052 },
    { age: 26.5, L: -1.18396635, M: 16.25207985, S: 0.083455124 },
    { age: 27.5, L: -1.268071036, M: 16.18734669, S: 0.082748284 },
    { age: 28.5, L: -1.354751525, M: 16.12475448, S: 0.082092737 },
    { age: 29.5, L: -1.443689692, M: 16.06428762, S: 0.081487717 },
    { age: 30.5, L: -1.53454192, M: 16.00593001, S: 0.080932448 },
    { age: 31.5, L: -1.626928093, M: 15.94966631, S: 0.080426175 },
    { age: 32.5, L: -1.720434829, M: 15.89548197, S: 0.079968176 },
    { age: 33.5, L: -1.814635262, M: 15.84336179, S: 0.079557735 },
    { age: 34.5, L: -1.909076262, M: 15.79329146, S: 0.079194187 },
    { age: 35.5, L: -2.003296102, M: 15.7452564, S: 0.078876895 },
    { age: 36.5, L: -2.096828937, M: 15.69924188, S: 0.078605255 },
    { age: 37.5, L: -2.189211877, M: 15.65523282, S: 0.078378696 },
    { age: 38.5, L: -2.279991982, M: 15.61321371, S: 0.078196674 },
    { age: 39.5, L: -2.368732949, M: 15.57316843, S: 0.078058667 },
    { age: 40.5, L: -2.455021314, M: 15.53508019, S: 0.077964169 },
    { age: 41.5, L: -2.538471972, M: 15.49893145, S: 0.077912684 },
    { age: 42.5, L: -2.618732901, M: 15.46470384, S: 0.077903716 },
    { age: 43.5, L: -2.695488973, M: 15.43237817, S: 0.077936763 },
    { age: 44.5, L: -2.768464816, M: 15.40193436, S: 0.078011309 },
    { age: 45.5, L: -2.837426693, M: 15.37335154, S: 0.078126817 },
    { age: 46.5, L: -2.902178205, M: 15.34660842, S: 0.078282739 },
    { age: 47.5, L: -2.962580386, M: 15.32168181, S: 0.078478449 },
    { age: 48.5, L: -3.018521987, M: 15.29854897, S: 0.078713325 },
    { age: 49.5, L: -3.069936555, M: 15.27718618, S: 0.078986694 },
    { age: 50.5, L: -3.116795864, M: 15.2575692, S: 0.079297841 },
    { age: 51.5, L: -3.159107331, M: 15.23967338, S: 0.079646006 },
    { age: 52.5, L: -3.196911083, M: 15.22347371, S: 0.080030389 },
    { age: 53.5, L: -3.230276759, M: 15.20894491, S: 0.080450145 },
    { age: 54.5, L: -3.259300182, M: 15.19606152, S: 0.080904391 },
    { age: 55.5, L: -3.284099963, M: 15.18479799, S: 0.081392203 },
    { age: 56.5, L: -3.30481415, M: 15.17512871, S: 0.081912623 },
    { age: 57.5, L: -3.321596954, M: 15.16702811, S: 0.082464661 },
    { age: 58.5, L: -3.334615646, M: 15.16047068, S: 0.083047295 },
    { age: 59.5, L: -3.344047622, M: 15.15543107, S: 0.083659478 },
    { age: 60.5, L: -3.35007771, M: 15.15188405, S: 0.084300139 },
    { age: 61.5, L: -3.352893805, M: 15.14980479, S: 0.0849682 },
    { age: 62.5, L: -3.352691376, M: 15.14916825, S: 0.085662539 },
    { age: 63.5, L: -3.34966438, M: 15.14994984, S: 0.086382035 },
    { age: 64.5, L: -3.343998803, M: 15.15212585, S: 0.087125591 },
    { age: 65.5, L: -3.335889574, M: 15.15567186, S: 0.087892047 },
    { age: 66.5, L: -3.325522491, M: 15.16056419, S: 0.088680264 },
    { age: 67.5, L: -3.31307846, M: 15.16677947, S: 0.089489106 },
    { age: 68.5, L: -3.298732648, M: 15.17429464, S: 0.090317434 },
    { age: 69.5, L: -3.282653831, M: 15.18308694, S: 0.091164117 },
    { age: 70.5, L: -3.265003896, M: 15.1931339, S: 0.092028028 },
    { age: 71.5, L: -3.245937506, M: 15.20441335, S: 0.092908048 },
    { age: 72.5, L: -3.225606516, M: 15.21690296, S: 0.093803033 },
    { age: 73.5, L: -3.204146115, M: 15.2305815, S: 0.094711916 },
    { age: 74.5, L: -3.181690237, M: 15.24542745, S: 0.095633595 },
    { age: 75.5, L: -3.158363475, M: 15.26141966, S: 0.096566992 },
    { age: 76.5, L: -3.134282833, M: 15.27853728, S: 0.097511046 },
    { age: 77.5, L: -3.109557879, M: 15.29675967, S: 0.09846471 },
    { age: 78.5, L: -3.084290931, M: 15.31606644, S: 0.099426955 },
    { age: 79.5, L: -3.058577292, M: 15.33643745, S: 0.100396769 },
    { age: 80.5, L: -3.032505499, M: 15.35785274, S: 0.101373159 },
    { age: 81.5, L: -3.0061576, M: 15.38029261, S: 0.10235515 },
    { age: 82.5, L: -2.979609448, M: 15.40373754, S: 0.103341788 },
    { age: 83.5, L: -2.952930993, M: 15.42816819, S: 0.104332139 },
    { age: 84.5, L: -2.926186592, M: 15.45356545, S: 0.105325289 },
    { age: 85.5, L: -2.899435307, M: 15.47991037, S: 0.106320346 },
    { age: 86.5, L: -2.872731211, M: 15.50718419, S: 0.10731644 },
    { age: 87.5, L: -2.846123683, M: 15.53536829, S: 0.108312721 },
    { age: 88.5, L: -2.819657704, M: 15.56444426, S: 0.109308364 },
    { age: 89.5, L: -2.793374145, M: 15.5943938, S: 0.110302563 },
    { age: 90.5, L: -2.767310047, M: 15.6251988, S: 0.111294537 },
    { age: 91.5, L: -2.741498897, M: 15.65684126, S: 0.112283526 },
    { age: 92.5, L: -2.715970894, M: 15.68930333, S: 0.113268793 },
    { age: 93.5, L: -2.690753197, M: 15.7225673, S: 0.114249622 },
    { age: 94.5, L: -2.665870146, M: 15.75661555, S: 0.115225321 },
    { age: 95.5, L: -2.641343436, M: 15.79143062, S: 0.116195218 },
    { age: 96.5, L: -2.617192204, M: 15.82699517, S: 0.117158667 },
    { age: 97.5, L: -2.593430614, M: 15.86329241, S: 0.118115073 },
    { age: 98.5, L: -2.570076037, M: 15.90030484, S: 0.119063807 },
    { age: 99.5, L: -2.547141473, M: 15.93801545, S: 0.12000429 },
    { age: 100.5, L: -2.524635245, M: 15.97640787, S: 0.120935994 },
    { age: 101.5, L: -2.502569666, M: 16.01546483, S: 0.121858355 },
    { age: 102.5, L: -2.48095189, M: 16.05516984, S: 0.12277087 },
    { age: 103.5, L: -2.459785573, M: 16.09550688, S: 0.123673085 },
    { age: 104.5, L: -2.439080117, M: 16.13645881, S: 0.124564484 },
    { age: 105.5, L: -2.418838304, M: 16.17800955, S: 0.125444639 },
    { age: 106.5, L: -2.399063683, M: 16.22014281, S: 0.126313121 },
    { age: 107.5, L: -2.379756861, M: 16.26284277, S: 0.127169545 },
    { age: 108.5, L: -2.360920527, M: 16.30609316, S: 0.128013515 },
    { age: 109.5, L: -2.342557728, M: 16.34987759, S: 0.128844639 },
    { age: 110.5, L: -2.324663326, M: 16.39418118, S: 0.129662637 },
    { age: 111.5, L: -2.307240716, M: 16.43898741, S: 0.130467138 },
    { age: 112.5, L: -2.290287663, M: 16.48428082, S: 0.131257852 },
    { age: 113.5, L: -2.273803847, M: 16.53004554, S: 0.132034479 },
    { age: 114.5, L: -2.257782149, M: 16.57626713, S: 0.132796819 },
    { age: 115.5, L: -2.242227723, M: 16.62292864, S: 0.133544525 },
    { age: 116.5, L: -2.227132805, M: 16.67001572, S: 0.134277436 },
    { age: 117.5, L: -2.212495585, M: 16.71751288, S: 0.134995324 },
    { age: 118.5, L: -2.19831275, M: 16.76540496, S: 0.135697996 },
    { age: 119.5, L: -2.184580762, M: 16.81367689, S: 0.136385276 },
    { age: 120.5, L: -2.171295888, M: 16.86231366, S: 0.137057004 },
    { age: 121.5, L: -2.158454232, M: 16.91130036, S: 0.137713039 },
    { age: 122.5, L: -2.146051754, M: 16.96062216, S: 0.138353254 },
    { age: 123.5, L: -2.134084303, M: 17.0102643, S: 0.138977537 },
    { age: 124.5, L: -2.122547629, M: 17.06021213, S: 0.139585795 },
    { age: 125.5, L: -2.111437411, M: 17.11045106, S: 0.140177947 },
    { age: 126.5, L: -2.100749266, M: 17.16096656, S: 0.140753927 },
    { age: 127.5, L: -2.090478774, M: 17.21174424, S: 0.141313686 },
    { age: 128.5, L: -2.080621484, M: 17.26276973, S: 0.141857186 },
    { age: 129.5, L: -2.071172932, M: 17.31402878, S: 0.142384404 },
    { age: 130.5, L: -2.062128649, M: 17.3655072, S: 0.142895332 },
    { age: 131.5, L: -2.053484173, M: 17.4171909, S: 0.143389972 },
    { age: 132.5, L: -2.045235058, M: 17.46906585, S: 0.143868341 },
    { age: 133.5, L: -2.03737688, M: 17.52111811, S: 0.144330469 },
    { age: 134.5, L: -2.029906684, M: 17.57333347, S: 0.144776372 },
    { age: 135.5, L: -2.022817914, M: 17.62569869, S: 0.145206138 },
    { age: 136.5, L: -2.016107084, M: 17.67819987, S: 0.145619819 },
    { age: 137.5, L: -2.009769905, M: 17.7308234, S: 0.146017491 },
    { age: 138.5, L: -2.003802134, M: 17.78355575, S: 0.146399239 },
    { age: 139.5, L: -1.998199572, M: 17.83638347, S: 0.146765161 },
    { age: 140.5, L: -1.992958064, M: 17.88929321, S: 0.147115364 },
    { age: 141.5, L: -1.988073505, M: 17.94227168, S: 0.147449967 },
    { age: 142.5, L: -1.983541835, M: 17.9953057, S: 0.147769097 },
    { age: 143.5, L: -1.979359041, M: 18.04838216, S: 0.148072891 },
    { age: 144.5, L: -1.975521156, M: 18.10148804, S: 0.148361495 },
    { age: 145.5, L: -1.972024258, M: 18.15461039, S: 0.148635067 },
    { age: 146.5, L: -1.968864465, M: 18.20773639, S: 0.148893769 },
    { age: 147.5, L: -1.966037938, M: 18.26085325, S: 0.149137776 },
    { age: 148.5, L: -1.963540872, M: 18.31394832, S: 0.14936727 },
    { age: 149.5, L: -1.961369499, M: 18.36700902, S: 0.149582439 },
    { age: 150.5, L: -1.959520079, M: 18.42002284, S: 0.149783482 },
    { age: 151.5, L: -1.9579889, M: 18.47297739, S: 0.149970604 },
    { age: 152.5, L: -1.956772271, M: 18.52586035, S: 0.15014402 },
    { age: 153.5, L: -1.95586652, M: 18.57865951, S: 0.15030395 },
    { age: 154.5, L: -1.955267984, M: 18.63136275, S: 0.150450621 },
    { age: 155.5, L: -1.954973011, M: 18.68395801, S: 0.15058427 },
    { age: 156.5, L: -1.954977947, M: 18.73643338, S: 0.150705138 },
    { age: 157.5, L: -1.955279136, M: 18.788777, S: 0.150813475 },
    { age: 158.5, L: -1.955872909, M: 18.84097713, S: 0.150909535 },
    { age: 159.5, L: -1.956755579, M: 18.89302212, S: 0.150993582 },
    { age: 160.5, L: -1.957923436, M: 18.94490041, S: 0.151065883 },
    { age: 161.5, L: -1.959372737, M: 18.99660055, S: 0.151126714 },
    { age: 162.5, L: -1.9610997, M: 19.04811118, S: 0.151176355 },
    { age: 163.5, L: -1.963100496, M: 19.09942105, S: 0.151215094 },
    { age: 164.5, L: -1.96537124, M: 19.15051899, S: 0.151243223 },
    { age: 165.5, L: -1.967907983, M: 19.20139397, S: 0.151261042 },
    { age: 166.5, L: -1.970706706, M: 19.25203503, S: 0.151268855 },
    { age: 167.5, L: -1.973763307, M: 19.30243131, S: 0.151266974 },
    { age: 168.5, L: -1.977073595, M: 19.35257209, S: 0.151255713 },
    { age: 169.5, L: -1.980633277, M: 19.40244671, S: 0.151235395 },
    { age: 170.5, L: -1.984437954, M: 19.45204465, S: 0.151206347 },
    { age: 171.5, L: -1.988483106, M: 19.50135548, S: 0.151168902 },
    { age: 172.5, L: -1.992764085, M: 19.55036888, S: 0.151123398 },
    { age: 173.5, L: -1.997276103, M: 19.59907464, S: 0.15107018 },
    { age: 174.5, L: -2.002014224, M: 19.64746266, S: 0.151009595 },
    { age: 175.5, L: -2.00697335, M: 19.69552294, S: 0.150942 },
    { age: 176.5, L: -2.012148213, M: 19.7432456, S: 0.150867753 },
    { age: 177.5, L: -2.017533363, M: 19.79062086, S: 0.150787221 },
    { age: 178.5, L: -2.023123159, M: 19.83763907, S: 0.150700774 },
    { age: 179.5, L: -2.028911755, M: 19.88429066, S: 0.150608788 },
    { age: 180.5, L: -2.034893091, M: 19.9305662, S: 0.150511645 },
    { age: 181.5, L: -2.041060881, M: 19.97645636, S: 0.150409731 },
    { age: 182.5, L: -2.047408604, M: 20.02195192, S: 0.15030344 },
    { age: 183.5, L: -2.05392949, M: 20.06704377, S: 0.150193169 },
    { age: 184.5, L: -2.060616513, M: 20.11172291, S: 0.150079322 },
    { age: 185.5, L: -2.067462375, M: 20.15598047, S: 0.149962308 },
    { age: 186.5, L: -2.074459502, M: 20.19980767, S: 0.14984254 },
    { age: 187.5, L: -2.081600029, M: 20.24319586, S: 0.149720441 },
    { age: 188.5, L: -2.088875793, M: 20.28613648, S: 0.149596434 },
    { age: 189.5, L: -2.096278323, M: 20.32862109, S: 0.149470953 },
    { age: 190.5, L: -2.103798828, M: 20.37064138, S: 0.149344433 },
    { age: 191.5, L: -2.111428194, M: 20.41218911, S: 0.149217319 },
    { age: 192.5, L: -2.119156972, M: 20.45325617, S: 0.14909006 },
    { age: 193.5, L: -2.126975375, M: 20.49383457, S: 0.14896311 },
    { age: 194.5, L: -2.134873266, M: 20.5339164, S: 0.148836931 },
    { age: 195.5, L: -2.142840157, M: 20.57349387, S: 0.148711989 },
    { age: 196.5, L: -2.150865204, M: 20.61255929, S: 0.148588757 },
    { age: 197.5, L: -2.158937201, M: 20.65110506, S: 0.148467715 },
    { age: 198.5, L: -2.167044578, M: 20.6891237, S: 0.148349348 },
    { age: 199.5, L: -2.175176987, M: 20.72660728, S: 0.14823412 },
    { age: 200.5, L: -2.183317362, M: 20.76355011, S: 0.148122614 },
    { age: 201.5, L: -2.191457792, M: 20.79994337, S: 0.148015249 },
    { age: 202.5, L: -2.199583649, M: 20.83578051, S: 0.147912564 },
    { age: 203.5, L: -2.207681525, M: 20.87105449, S: 0.147815078 },
    { age: 204.5, L: -2.215737645, M: 20.90575839, S: 0.147723315 },
    { age: 205.5, L: -2.223739902, M: 20.93988477, S: 0.147637768 },
    { age: 206.5, L: -2.231667995, M: 20.97342858, S: 0.147559083 },
    { age: 207.5, L: -2.239511942, M: 21.00638171, S: 0.147487716 },
    { age: 208.5, L: -2.247257081, M: 21.0387374, S: 0.14742421 },
    { age: 209.5, L: -2.254885145, M: 21.07048996, S: 0.147369174 },
    { age: 210.5, L: -2.26238209, M: 21.10163241, S: 0.147323144 },
    { age: 211.5, L: -2.269731517, M: 21.13215845, S: 0.147286698 },
    { age: 212.5, L: -2.276917229, M: 21.16206171, S: 0.147260415 },
    { age: 213.5, L: -2.283925442, M: 21.1913351, S: 0.147244828 },
    { age: 214.5, L: -2.290731442, M: 21.21997472, S: 0.147240683 },
    { age: 215.5, L: -2.29732427, M: 21.24797262, S: 0.147248467 },
    { age: 216.5, L: -2.303687802, M: 21.27532239, S: 0.14726877 },
    { age: 217.5, L: -2.309799971, M: 21.30201933, S: 0.147302299 },
    { age: 218.5, L: -2.315651874, M: 21.32805489, S: 0.147349514 },
    { age: 219.5, L: -2.32121731, M: 21.35342563, S: 0.147411215 },
    { age: 220.5, L: -2.326481911, M: 21.37812462, S: 0.147487979 },
    { age: 221.5, L: -2.331428139, M: 21.40214589, S: 0.147580453 },
    { age: 222.5, L: -2.336038473, M: 21.42548351, S: 0.147689289 },
    { age: 223.5, L: -2.34029545, M: 21.44813156, S: 0.14781515 },
    { age: 224.5, L: -2.344181703, M: 21.47008412, S: 0.147958706 },
    { age: 225.5, L: -2.34768, M: 21.49133529, S: 0.148120633 },
    { age: 226.5, L: -2.350773286, M: 21.51187918, S: 0.148301619 },
    { age: 227.5, L: -2.353444725, M: 21.53170989, S: 0.148502355 },
    { age: 228.5, L: -2.355677743, M: 21.55082155, S: 0.148723546 },
    { age: 229.5, L: -2.35745607, M: 21.56920824, S: 0.148965902 },
    { age: 230.5, L: -2.358763788, M: 21.58686406, S: 0.149230142 },
    { age: 231.5, L: -2.359585369, M: 21.60378309, S: 0.149516994 },
    { age: 232.5, L: -2.359905726, M: 21.61995939, S: 0.149827195 },
    { age: 233.5, L: -2.359710258, M: 21.635387, S: 0.150161492 },
    { age: 234.5, L: -2.358980464, M: 21.65006126, S: 0.150520734 },
    { age: 235.5, L: -2.357714508, M: 21.6639727, S: 0.150905439 },
    { age: 236.5, L: -2.355892424, M: 21.67711736, S: 0.151316531 },
    { age: 237.5, L: -2.353501353, M: 21.68948935, S: 0.151754808 },
    { age: 238.5, L: -2.350528726, M: 21.70108288, S: 0.152221086 },
    { age: 239.5, L: -2.346962247, M: 21.71189225, S: 0.152716206 },
    { age: 240, L: -2.34495843, M: 21.71699934, S: 0.152974718 },
    { age: 240.5, L: -2.342796948, M: 21.72190973, S: 0.153240872 }
];


function CalculadoraIMCFem() {
    const [idade, setIdade] = useState('');
    const [altura, setAltura] = useState('');
    const [peso, setPeso] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularBMI = () => {
        const alturaMetros = parseFloat(altura) / 100;
        const pesoNum = parseFloat(peso);
        const idadeMeses = parseFloat(idade) * 12;

        // Validações
        if (alturaMetros < 0.5) {
            setResultado('A altura deve ser no mínimo 50 cm.');
            setInterpretacao('');
            return;
        }

        if (pesoNum < 5) {
            setResultado('O peso deve ser no mínimo 5 kg.');
            setInterpretacao('');
            return;
        }

        const bmi = pesoNum / (alturaMetros * alturaMetros);

        const lms = lmsDataFem.reduce((prev, curr) => {
            return (Math.abs(curr.age - idadeMeses) < Math.abs(prev.age - idadeMeses) ? curr : prev);
        });

        if (!lms) {
            setResultado('Idade não suportada para cálculo.');
            setInterpretacao('');
            return;
        }

        const { L, M, S } = lms;
        const zScore = ((bmi / M) ** L - 1) / (L * S);
        const percentil = ZtoPercentile(zScore);
        const bmi95 = M * (((L * S * 1.645) + 1) ** (1 / L));

        let classificacao = '';

        if (percentil < 5) {
            classificacao = 'Abaixo do peso';
        } else if (percentil >= 5 && percentil < 85) {
            classificacao = 'Peso saudável';
        } else if (percentil >= 85 && percentil < 95) {
            classificacao = 'Sobrepeso';
        } else if (bmi >= 35 || (bmi >= bmi95 * 1.2 && bmi < bmi95 * 1.4)) {
            classificacao = 'Obesidade classe II';
        } else if (bmi >= 40 || bmi >= bmi95 * 1.4) {
            classificacao = 'Obesidade classe III';
        } else if (bmi >= bmi95) {
            classificacao = 'Obesidade classe I';
        }

        setResultado(`IMC: ${bmi.toFixed(2)}, Z-score: ${zScore.toFixed(2)}, Percentil: ${percentil.toFixed(2)}%`);
        setInterpretacao(classificacao);
    };

    const ZtoPercentile = (z) => {
        const percentil = 50 * (1 + erf(z / Math.sqrt(2)));
        return percentil;
    };

    useEffect(() => {
        if (idade && altura && peso) {
            calcularBMI();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [idade, altura, peso]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de percentis e Z-scores de IMC</h1>
            <div>
                <label>Idade (anos):</label>
                <input type="number" value={idade} onChange={e => setIdade(e.target.value)} />
            </div>
            <div>
                <label>Altura (cm):</label>
                <input 
                    type="number" 
                    value={altura} 
                    onChange={e => setAltura(e.target.value)} 
                    min="50"
                />
            </div>
            <div>
                <label>Peso (kg):</label>
                <input 
                    type="number" 
                    value={peso} 
                    onChange={e => setPeso(e.target.value)} 
                    min="5"
                />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraIMCFem;