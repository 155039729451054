import React from 'react';
import styled from 'styled-components';


const ShowAnswerButton = styled.div`
    height: 4em !important;
    width: 100%;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    border-radius: 0.5em;

    ${props => props.theme.darkMode ? `
        filter: grayscale(50%);
    ` : `
        filter: none;
        box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
        
        &:active {
            box-shadow: none;
        }
    `}

    background-color: rgb(39, 121, 196);
    color: white;

    &:hover {
        background-color: rgb(39, 121, 196, 0.9);
    }

    margin-top: 1em;

    @media (max-width: 500px) {
        margin-bottom: 1em;
        width: 90%;
    }
`


const ShowAnswerTxt = styled.p`
    font-size: 1.1em;
    font-weight: bold;
    margin: 0;
    padding: 0;
`

export default function MoveForwardBttn(props) {


    return (
        <ShowAnswerButton onClick={props.moveForward}>
            <ShowAnswerTxt>MOSTRE-ME A RESPOSTA</ShowAnswerTxt>
        </ShowAnswerButton>
    );
}
