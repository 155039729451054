import React, { useEffect, useState } from 'react'

import './NiceButton.css'


export default function NiceButton(props) {



    return (
        <div 
            className='NiceButtonContainer' 

            style = {{
                backgroundColor : props.color,
                ...props.style
            }}

            onClick={() => {
                props.action()
            }}>

                {props.text}

        </div>
    )
}