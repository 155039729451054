import React, { useEffect, useState } from 'react';
import OslerButton from '../components/OslerButton';
import GeneralDialog from '../tests/GeneralDialog';
import AmbassadorMonthly from './AmbassadorMonthly';
import styled from 'styled-components'
import { OslerInput } from '../tests/FlashcardsStyles';
import { checkCouponForError } from '../utils/Utils';
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'



const UploadInvoice = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    background-color: red;
`;

const HiddenFileInput = styled.input`
    display: none;
`;


export default function AmbassadorAdminUploadInvoice({uploadInvoice, selectedMonthData}) {
    const [file, setFile] = useState(null)


    return (
        <>
        { selectedMonthData['paymentReceived'] && !selectedMonthData['downloadURL'] &&
            <>
            { !file && 
            <>
                <HiddenFileInput
                    accept='application/pdf'
                    type='file'
                    id='file-input'
                    onChange={(event) => setFile(event.target.files[0])}
                />

                <OslerButton
                    style={{
                        width: '10em',
                        marginTop: '2em',
                        marginBottom: '4em',
                    }}
                    color='grey'
                    text='Adicionar nota'
                    onClick={() => document.getElementById('file-input').click()}
                />
            </>
            }

            { file &&
                <OslerButton
                    style={{
                        width: '10em',
                        marginTop: '2em',
                        marginBottom: '4em',
                    }}
                    color='green'
                    text='Confirmar envio'
                    onClick={() => uploadInvoice(file) }
                />
            }
            </>
        }
        </>
    )
}