// src/CalculadoraAsmaGINA.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraAsmaGINA() {
  const [respostas, setRespostas] = useState({
    sintomasDiurnos: false,
    despertarNoturno: false,
    usoMedicacao: false,
    limitacaoAtividades: false
  });

  const handleChange = (event) => {
    setRespostas({
      ...respostas,
      [event.target.name]: event.target.checked
    });
  };

  const calcularControleAsma = () => {
    const totalSim = Object.values(respostas).filter(Boolean).length;
    if (totalSim === 0 && !Object.values(respostas).includes(true)) {
      return 'Por favor, preencha os campos.';
    } else if (totalSim === 0) {
      return 'Asma bem controlada.';
    } else if (totalSim <= 2) {
      return 'Asma parcialmente controlada.';
    } else {
      return 'Asma não controlada.';
    }
  };

  return (
    <div>
                                          <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
      <h1 className='calc-titulo'>Calculadora de Controle da Asma - GINA 2022</h1>
      <form>
        <label>
          <input
            type="checkbox"
            name="sintomasDiurnos"
            checked={respostas.sintomasDiurnos}
            onChange={handleChange}
          />
          Apresentou sintomas diurnos mais de 2x por semana
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="despertarNoturno"
            checked={respostas.despertarNoturno}
            onChange={handleChange}
          />
          Apresentou despertar noturno devido à asma
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="usoMedicacao"
            checked={respostas.usoMedicacao}
            onChange={handleChange}
          />
          Usou beta-agonista de curta duração pelo menos 2x por semana para alívio dos sintomas
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="limitacaoAtividades"
            checked={respostas.limitacaoAtividades}
            onChange={handleChange}
          />
          Apresentou limitação das atividades diárias devido à asma
        </label>
      </form>
      <div className="result-container">
        <div>{calcularControleAsma()}</div>
      </div>
    </div>
  );
}

export default CalculadoraAsmaGINA;
