import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'
import session from './../controllers/Session'
import sessionBuilder from './../controllers/SessionBuilder'
import userReviewsInfo from './../controllers/UserReviewsInfo'
import { useNavigate } from "react-router-dom";
import { measureTime, toastMsg } from '../utils/Utils';
import './MainScreen.css'
import AppContainer from './../app-container/AppContainer';
import { guaranteeSubscription } from '../firebase/firebaseUtils';
import PendingReviewsCard from './PendingReviewsCard';
import IsLoading from './IsLoading';
import TestsDoneGraph from '../statistics/TestsDoneGraph'
import PreviousSessionCard from './PreviousSessionCard'
import SurpriseSessionCard from './SurpriseSessionCard'
import NoticeCard from './NoticeCard'
import OslerData, { KEYS } from '../controllers/OslerData'
import styled from 'styled-components';
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'
import { db } from '../firebase/firebase-setup'
import { loadAppData } from '../services/LoadAppData'
import personalNotes from '../controllers/PersonalNotes'
import MistakesJournalController from '../controllers/MistakesJournalController'
import Notebook from '../controllers/NotebookController'
import Anna from './Anna'
import { PredefinedSessionConfig, SORT_MODES } from '../controllers/SessionConfig'


const MainScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;


    ${backgroundWhiteBlack}
    width: 100%;
    min-height: 100vh;


    /* É OBRIGATÓRIO. Senão, em telas menores (tablet, celular) não é possível  ver até o fim.
    E precisa ser aqui, e não na app container, senão a side bar não acompanha. */
    padding-bottom: 8em;

    @media (max-width: 1200px) {
        width: 80%;
        // flex-wrap: wrap;
    }

    @media (max-width: 900px) {
        width: 90%;
        // flex-wrap: wrap;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`;



const MainScreenCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: wrap;
    width: 65%;

    margin-top: 3em;

    @media (max-width: 900px) {
        justify-content: space-around;
    }


    @media (max-width: 500px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 2em;
    }
`;


export default function MainScreen() {

    const user = useSelector(state => state.user.data)
    let navigate = useNavigate();
    const dispatch = useDispatch()




    // async function test() {
    //     const path  = `users/${user.id}/Flashcards/tests/reviewed tests`
    //     const now = new Date().getTime()

    //     console.log(now)
    //     console.log(path)


    //     const result = await measureTime(
    //         () => db.collection(path).where('nextReview', '<=', now).get(),

    //         '\t\t\t NÓS BAIXAMOS TUDO EM... '
    //     )

    //     console.log(result.docs.length)
    // }


    // async function hashTest() {
    //     // const doc = db.doc('metadata/Flashcards/tagpath_per_id/doc0').get()
    //     // const data = doc.data()
    //     // const decks = Object.keys(data)
    //     const allIDs_d = OslerData.data[KEYS.FLASHCARDS][KEYS.ALL_TESTS_IDS]
    //     const allIDs = Object.keys(allIDs_d)

    //     const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

    //     const divisor = 5

    //     let counts = Array(divisor).fill(0);

    //     for (let ID of allIDs) {
    //         const parts = ID.split('_')

    //         // garante que não haverá acentos e afins, tudo será uma letra ascii
    //         var unidecode = require('unidecode')
    //         const root = unidecode(parts[1])

    //         const letter = root[0].toLowerCase()

    //         let num = letters.indexOf(letter) % divisor
    
    //         // Incrementando o contador correspondente
    //         counts[num]++;
    //     }
    
    //     // Imprimindo os resultados
    //     console.log('Número de IDs em cada categoria:');
    //     counts.forEach((count, index) => {
    //         console.log(`Categoria ${index}: ${count}`);
    //     });
    // }

    // useEffect(() => {
    //     // Testes
    //     // test()
    //     hashTest()
    // }, [])



    useEffect(() => {
        console.log("MainScreen: checking subscription")
        guaranteeSubscription(user, navigate)
    }, [])
    
    const [downloadedReviewInfo, setDownloadedReviewInfo] = useState(false)
    const [downloadedTodayInfo, setDownloadTodayInfo] = useState(false)


    useEffect(() => {
        async function loadMain() {         
            dispatch( setIsLoading(true) )

            try {
                console.log('Vamos carregar')
                await loadAppData(user)

                // await OslerData.start(user)

                // não tinha await antes?! ?! ?! 
                // await measureTime(() => userReviewsInfo.start(), 'UserReviewsInfo(): started in')

                setDownloadedReviewInfo(true)    
                const tests_per_tag = userReviewsInfo.getTestsPerTag()
                sessionBuilder.prepare(user, userReviewsInfo, tests_per_tag) 
                setDownloadTodayInfo(true)    

                personalNotes.construct(user)
                MistakesJournalController.construct(user)
                Notebook.construct(user)

                dispatch( setIsLoading(false) )

            }
            catch (error) {
                navigate('/error', { state: { msg: 'loadMain(): error', data: error } });
            }
        }

        console.log('MainScreen: starting...')
        userReviewsInfo.construct(user, ['Flashcards', 'Residencia'])
        loadMain()
    }, [])


    function moveToTests() {
        navigate("/test")
        dispatch( setIsLoading(false) )
    }


    async function loadTests(testType, sessionType, shouldSaveAsLastSession, config, testIDs = false) {
        dispatch( setIsLoading(true) )

        if (!testIDs) {
            sessionBuilder.start(
                testType,
                sessionType,
                'test-mode',
                shouldSaveAsLastSession
            )
        } else {
            sessionBuilder.start(
                testType,
                'predefined',
                'test-mode',
                testIDs,
                config, 
                shouldSaveAsLastSession,
            )
        }

        console.log(testIDs)


        if (session.sessionSize > 0) {
            session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch( setIsLoading(false) )
            toastMsg("Parece que você já fez todos os cards disponíveis! 🔥")
        }
    }


    return (
        <AppContainer>
            <MainScreenContainer >

                { (!downloadedReviewInfo || !downloadedTodayInfo) &&
                    <IsLoading /> 
                }
                { downloadedReviewInfo && downloadedTodayInfo && 
                <>
                    <NoticeCard />

                    <MainScreenCardsContainer>
                        <PendingReviewsCard
                            loadTests = {loadTests}  />
                        
                        <PreviousSessionCard 
                            loadTests = {loadTests} />

                        {/* <SurpriseSessionCard
                            loadTests = {loadTests} /> */}

                    </MainScreenCardsContainer>

      
                    <TestsDoneGraph />
 
                </>
                }
            </MainScreenContainer>   
        </AppContainer>
    )
}

