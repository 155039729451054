import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Checkbox from './Checkbox'

// Importando ícones
import DumbbellIcon from './../assets/tree/dumbbell.png'
import SandboxIcon from './../assets/sandbox.png'
import BooksIcon from './../assets/books.png'
import HelpIcon from './../assets/question-new.png'
import GeneralDialog from '../tests/GeneralDialog'
import { SessionConfig, SORT_MODES, STUDY_MODES, TEST_TYPES } from '../controllers/SessionConfig'
import { isEqual } from 'remirror'
import { ColumnCSS } from '../components/BasicComponents'
import CollapsibleCard from './CollapsibleCard'
import HelpBttn from './HelpBttn'
import OslerCard from '../components/OslerCard'


const ButtonsContainer = styled.div`
    ${ColumnCSS}

    // Para ficar padrão com CollapsibleCard
    width: 95%;

    padding-top: 0.5em;
    gap: 2em;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 100%;
        padding-top: 0.4em;
        gap: 1.5em;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding-top: 1em;
        gap: 1em;
    }
`

const Sidebar = styled.div`
    ${ColumnCSS}

    // Cruzar com MainContent de CustomStudy
    width: 25% !important;
    gap: 1.2em;


    // O top é o que posiciona o div na tela
    // precisa existir, do contrário perde o sticky;
    //
    // colocar 0em é ruim, senão tudo parece colado no topo
    position: sticky;
    top: 1em;

    // height: calc(100vh - 4em);
    // overflow-y: auto;

    padding-bottom: 2em;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 29% !important;
        min-width: 180px;
        padding: 0em;
        gap: 1em;
        top: 0.8em;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        position: relative;
        top: 0;
        padding: 0.5em;
    }
`

const FilterSection = styled.div`
    margin: 1.4em 0;

    &:not(:last-child) {
        padding-bottom: 1.4em;
        border-bottom: 1px solid ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.1)' : 
            'rgba(0, 0, 0, 0.06)'};
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        margin: 1.2em 0;
        
        &:not(:last-child) {
            padding-bottom: 1.2em;
        }
    }

    @media (max-width: 768px) {
        margin: 1em 0;
        
        &:not(:last-child) {
            padding-bottom: 1em;
        }
    }
`

const FilterGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.4em;

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: 0.3em;
    }
`

const FilterLabel = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0.8em 1em;
    border-radius: 12px;
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#2c2c2c'};
    user-select: none;
    background: transparent;

    &:hover {
        background: ${props => props.theme.darkMode ? 
            'rgba(96, 165, 250, 0.1)' : 
            'rgba(22, 114, 246, 0.05)'};
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};

        @media (max-width: 768px) {
            background: transparent;
        }
    }

    span {
        margin-left: 1em;
        font-size: 0.95em;
        font-weight: 450;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0.6em 0.8em;
        border-radius: 10px;
        
        span {
            margin-left: 0.8em;
            font-size: 0.75em;
            display: flex;
            flex-direction: column;
        }
    }

    @media (max-width: 768px) {
        padding: 0.4em !important;
        border-radius: 8px;

        span {
            font-size: 0.75em;
        }
    }
`

const StudyModeContainer = styled.div`
    ${ColumnCSS}
    width: 100%;
    gap: 1em;

    @media (max-width: 768px) {
        gap: 0.5em;
    }
`

const StudyModeOption = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    
    padding: 1em 0 1em 1.2em;
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'rgba(96, 165, 250, 0.15)' : 'transparent';
        }
        return props.selected ? 'rgba(22, 114, 246, 0.08)' : 'transparent';
    }};
    
    border: 1px solid ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'rgba(96, 165, 250, 0.25)' : 'transparent';
        }
        return props.selected ? 'rgba(22, 114, 246, 0.15)' : 'transparent';
    }};

    position: relative;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1em;

    }

    @media (max-width: 768px) {
        padding: 0.5em;
        border-radius: 12px;
        height: 3em;
        align-items: center;
    }

    &:hover {
        background-color: ${props => {
            if (props.theme.darkMode) {
                return props.selected ? 'rgba(96, 165, 250, 0.2)' : 'rgba(255, 255, 255, 0.05)';
            }
            return props.selected ? 'rgba(22, 114, 246, 0.1)' : 'rgba(0, 0, 0, 0.03)';
        }};
        transform: translateY(-2px);
        box-shadow: ${props => {
            if (props.theme.darkMode) {
                return props.selected ? '0 8px 24px rgba(96, 165, 250, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.2)';
            }
            return props.selected ? '0 8px 24px rgba(22, 114, 246, 0.12)' : '0 4px 12px rgba(0, 0, 0, 0.05)';
        }};

        @media (max-width: 768px) {
            transform: none;
            box-shadow: none;
        }
    }

    &:active {
        transform: translateY(0);
    }
`

const ModeIcon = styled.img`
    width: 1.8em;
    height: 1.8em;
    margin-right: 1.2em;
    opacity: ${props => props.selected ? 1 : 0.7};
    transition: all 0.4s ease;
    filter: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? 'brightness(1.2) invert(0.8)' : 'brightness(0.9) invert(0.8)';
        }
        return props.selected ? 'none' : 'grayscale(30%)';
    }};
    transform: ${props => props.selected ? 'scale(1.25) rotate(15deg)' : 'scale(1) rotate(0)'};

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 1.4em;
        height: 1.4em;
        margin-right: 0.6em;    
    }

    @media (max-width: 768px) {
        width: 1.2em;
        height: 1.2em;
        margin-right: 0.6em;
        transform: ${props => props.selected ? 'scale(1.1) rotate(10deg)' : 'scale(1) rotate(0)'};
    }
`

const ModeLabel = styled.span`
    font-size: 1em;
    color: ${props => {
        if (props.theme.darkMode) {
            return props.selected ? '#60a5fa' : 'rgb(220, 220, 220)';
        }
        return props.selected ? '#1672f6' : '#444';
    }};
    font-weight: ${props => props.selected ? '600' : '450'};
    transition: all 0.3s ease;
    position: relative;

    &:after {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: ${props => props.selected ? '100%' : '0'};
        height: 2px;
        background: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
        transition: all 0.3s ease;
        opacity: ${props => props.selected ? '1' : '0'};
        transform: translateY(${props => props.selected ? '0' : '4px'});
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.9em;
    }

    @media (max-width: 768px) {
        font-size: 0.8em;
    }   
`


const HelpImg = styled.img`
    height: 1em;
    margin-right: 0.5em;
`

const HelpTitle = styled.p`
    font-size: 1.1em;
    font-weight: bold;
    margin: 0em 0 0.5em 0;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'inherit'};

`

const SectionTitle = styled.h4`
    font-size: 0.9em;
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#666'};
    margin-bottom: 1em;
    font-weight: 500;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.8em;
        margin-bottom: 0.8em;
    }

    @media (max-width: 768px) {
        font-size: 0.85em;
    }
`

const RecommendedBadge = styled.span`
    font-size: 0.7em;
    background-color: ${props => props.theme.darkMode ? 
        'rgba(96, 165, 250, 0.2)' : 
        'rgba(22, 114, 246, 0.1)'};
    color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
    padding: 0.2em 0.6em;
    border-radius: 4px;
    margin-left: 0.8em;
    font-weight: 500;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.60em;
        padding: 0.15em 0.5em;
        margin-left: 0em !important;
        border-radius: 3px;
        width: fit-content;
    }

    @media (max-width: 768px) {
        font-size: 0.65em;
        padding: 0.15em 0.4em;
        margin-left: 0.6em;
    }
`

const HelpIconButton = styled.button`
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease;
    opacity: 0.6;
    border: none;
    background: transparent;
    padding: 0;

    &:hover {
        opacity: 1;
        transform: scale(1.1);
    }

    img {
        width: 16px;
        height: 16px;
    }
`



export default function CustomStudySideBar({ testType, bttns, onConfigChange, onModeChange }) {
    const [studyMode, setStudyMode] = useState(STUDY_MODES.TEST_MODE)
    const [config, setConfig] = useState(testType === 'Residencia' ?
        SessionConfig.RESIDENCIA_DEFAULT : SessionConfig.FLASHCARDS_DEFAULT)

    const previousConfig = useRef()

    const [expandedCards, setExpandedCards] = useState({
        studyMode: false,
        settings: false
    })

    useEffect(() => {
        // Only trigger if config actually changed
        if (!isEqual(previousConfig.current, config)) {
            previousConfig.current = config;
            console.log('Config mudou para...', config)
            onConfigChange?.(config)
        }
    }, [config, onConfigChange])


    useEffect(() => {
        onModeChange?.(studyMode)
    }, [studyMode, onModeChange])


    const contentOptions = [
        {
            id: 'NewTests',
            label: 'Novos',
            configKey: 'removeNewTests',
            recommended: studyMode === STUDY_MODES.TEST_MODE
        },
        {
            id: 'PendingReviews',
            label: 'Revisões pendentes',
            configKey: 'removePendingReviews',
            recommended: studyMode === STUDY_MODES.TEST_MODE,
        },
        {
            id: 'FutureReviews',
            label: 'Revisões futuras',
            configKey: 'removeFutureReviews'
        },
        ...(testType === TEST_TYPES.RESIDENCIA ? [{
            id: 'Solved',
            label: 'Já resolvidas',
            configKey: 'removeSolved'
        }] : [])
    ]


    const otherOptions = [
        ...(testType === TEST_TYPES.RESIDENCIA ? [{
            id: 'Anuladas',
            label: 'Incluir anuladas',
            configKey: 'removeAnuladas'
        }] : []),
        {
            id: 'Buried',
            label: 'Incluir enterrados',
            configKey: 'removeBuried'
        },
        {
            id: 'Random',
            label: 'Aleatorizar',
            configKey: 'ordering',
            isOrdering: true
        }
    ]

    const toggleContent = (configKey) => {
        console.log('Chaamando a função')
        setConfig(prev => ({
            ...prev,
            [configKey]: !prev[configKey]
        }))
    }

    const toggleSort = () => {
        setConfig(prev => ({
            ...prev,
            ordering: prev.ordering === SORT_MODES.SORT ? SORT_MODES.SHUFFLE : SORT_MODES.SORT
        }))
    }

    const toggleStudyMode = (newMode) => {
        setStudyMode(newMode)
        const newConfig = newMode === STUDY_MODES.TEST_MODE ?
            (testType === TEST_TYPES.RESIDENCIA ? SessionConfig.RESIDENCIA_DEFAULT : SessionConfig.FLASHCARDS_DEFAULT) :
            SessionConfig.createPlaygroundOrConsultMode(testType)
        setConfig(newConfig)
    }

    const studyModeOptions = [
        { id: STUDY_MODES.TEST_MODE, label: 'Teste', icon: DumbbellIcon },
        { id: STUDY_MODES.PLAYGROUND_MODE, label: 'Playground', icon: SandboxIcon },
        { id: STUDY_MODES.CONSULT_MODE, label: 'Consulta', icon: BooksIcon }
    ]

    const [showStudyModeDialog, setShowStudyModeDialog] = useState(false)
    const [showConfigDialog, setShowConfigDialog] = useState(false)

    return (
        <Sidebar>

            <GeneralDialog
                icon = {HelpIcon}
                title='Configurações'
                open={showConfigDialog}
                onClose={() => setShowConfigDialog(false)}>

                {testType === 'Residencia' ? (
                    <>
                        <p>O padrão é carregar tanto as questões novas (i.e., que você nunca viu) quanto as revisões pendentes, mas você pode selecionar só um ou o outro.</p>

                        <p>As questões vêm ordenadas por instituição e <u>excluímos</u> as anuladas. Você pode modificar isso usando os filtros abaixo.</p>
                    </>
                ) : (
                    <>
                        <p>O padrão é carregar tanto os flashcards novos (i.e., que você nunca viu) quanto as revisões pendentes, mas você pode selecionar só um ou o outro.</p>

                        <p>Os flashcards vem agrupados por tema e, dentro de um mesmo, ordenados na sequência lógica (i.e., definição → epidemiologia → clínica → diagnóstico → tratamento). Se quiser aleatorizar, selecione o filtro abaixo.</p>
                    </>
                )}
            </GeneralDialog>

            <GeneralDialog
                icon = {HelpIcon}
                title='Modos de estudo'
                open={showStudyModeDialog}
                onClose={() => setShowStudyModeDialog(false)}>

                <HelpTitle><HelpImg src={DumbbellIcon} alt="Dumbbell Icon" />Teste</HelpTitle>
                <p>É o padrão, você responderá aos testes, afetando as suas estatísticas de acerto/erro e a programação das suas revisões. Salvo que configure o contrário, você não verá os testes que estão agendados para revisão no futuro.</p>

                <HelpTitle><HelpImg src={BooksIcon} alt="Books Icon" />Consulta</HelpTitle>
                <p>Você irá ler o conteúdo dos testes, tendo acesso tanto às perguntas quanto às respostas, mas sem conseguir respondê-los. É um estudo passivo, análogo a fazer uma leitura. Ideal para um primeiro contato com o tema ou para pesquisas.</p>

                <HelpTitle><HelpImg src={SandboxIcon} alt="Sandbox Icon" />Playground</HelpTitle>
                <p>Você terá acesso a todos os testes (sem diferenciar entre novos, revisões pendentes, revisões futuras) e irá respondê-los, mas NÃO afetará suas estatísticas de acerto ou agenda de revisões.</p>

                <p>É um estudo ativo, ideal quando você quer retomar um tema rapidamente (e.g., antes de uma prova). Ou para quando você quer mostrar para um colega (embaixadores, amamos vocês ♥️).</p>
            </GeneralDialog>

            <CollapsibleCard
                title="Modo de Estudo"
                isExpanded={expandedCards.studyMode}
                onToggle={() => setExpandedCards(prev => ({ ...prev, studyMode: !prev.studyMode }))}
                rightComponent = { 
                    <HelpBttn onClick={e => {
                            e.stopPropagation()
                            e.preventDefault()
                            setShowStudyModeDialog(true)
                        }} />
                }
            >
                <StudyModeContainer>
                    {studyModeOptions.map(mode => (
                        <StudyModeOption
                            key={mode.id}
                            selected={studyMode === mode.id}
                            onClick={() => toggleStudyMode(mode.id)}
                        >
                            <ModeIcon
                                src={mode.icon}
                                alt={mode.label}
                                selected={studyMode === mode.id}
                            />
                            <ModeLabel selected={studyMode === mode.id}>
                                {mode.label}
                            </ModeLabel>
                        </StudyModeOption>
                    ))}
                </StudyModeContainer>
            </CollapsibleCard>


            <CollapsibleCard
                title="Ajustes"
                isExpanded={expandedCards.settings}
                onToggle={() => setExpandedCards(prev => ({ ...prev, settings: !prev.settings }))}
                rightComponent={
                    <HelpBttn onClick={() => setShowConfigDialog(true)} />
                }
            >
                <FilterSection style={{ margin: '0.8em 0' }}>
                    <SectionTitle style={{ marginBottom: '0.6em' }}>
                        {testType === TEST_TYPES.RESIDENCIA ? 'Quais questões incluir?' : 'Quais flashcards incluir?'}
                    </SectionTitle>
                    <FilterGroup style={{ gap: '0.2em' }}>
                        {contentOptions.map(option => (
                            <FilterLabel
                                key={option.id}
                                onClick={() => toggleContent(option.configKey)}
                                style={{ padding: '0.6em 1em' }}
                            >
                                <Checkbox
                                    checked={!config[option.configKey]}
                                    action={(e) => {
                                        e.stopPropagation();
                                        toggleContent(option.configKey);
                                    }}
                                />
                                <span>
                                    {option.label}
                                    {option.recommended && <RecommendedBadge>recomendado</RecommendedBadge>}
                                </span>
                            </FilterLabel>
                        ))}
                    </FilterGroup>
                </FilterSection>

                <FilterSection style={{ margin: '0.8em 0', paddingBottom: '0' }}>
                    <SectionTitle style={{ marginBottom: '0.6em' }}>
                        Outras opções
                    </SectionTitle>
                    <FilterGroup style={{ gap: '0.2em' }}>
                        {otherOptions.map(option => (
                            <FilterLabel
                                key={option.id}
                                onClick={() => option.configKey === 'ordering' ?
                                    toggleSort() : toggleContent(option.configKey)}
                                style={{ padding: '0.6em 1em' }}
                            >
                                <Checkbox
                                    checked={option.configKey === 'ordering' ?
                                        config.ordering === SORT_MODES.SHUFFLE :
                                        !config[option.configKey]}
                                    action={(e) => {
                                        e.stopPropagation();
                                        option.configKey === 'ordering' ?
                                            toggleSort() : toggleContent(option.configKey);
                                    }}
                                />
                                <span>{option.label}</span>
                            </FilterLabel>
                        ))}
                    </FilterGroup>
                </FilterSection>
            </CollapsibleCard>

            {bttns && <ButtonsContainer>{bttns}</ButtonsContainer>}
        </Sidebar>
    )
}