import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraSARAFio2() {
    const [paO2, setPaO2] = useState('');
    const [fiO2, setFiO2] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularRelacaoPaO2FiO2 = () => {
        const paO2Num = parseFloat(paO2);
        const fiO2Num = parseFloat(fiO2);

        if (!isNaN(paO2Num) && !isNaN(fiO2Num) && fiO2Num > 0) {
            const relacao = (paO2Num / fiO2Num).toFixed(2);
            setResultado(`Relação PaO2/FiO2: ${relacao}`);
            
            if (relacao > 300) {
                setInterpretacao('Valor normal');
            } else if (relacao >= 201 && relacao <= 300) {
                setInterpretacao('SDRA leve');
            } else if (relacao >= 101 && relacao <= 200) {
                setInterpretacao('SDRA moderada');
            } else if (relacao <= 100) {
                setInterpretacao('SDRA grave');
            }
        } else {
            setResultado('Preencha todos os campos com valores válidos.');
            setInterpretacao('');
        }
    };

    useEffect(() => {
        calcularRelacaoPaO2FiO2();
    }, [paO2, fiO2]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora Relação PaO2/FiO2 (SARA)</h1>
            <div>
                <label>PaO2 (mmHg):</label>
                <input type="number" step="0.01" value={paO2} onChange={e => setPaO2(e.target.value)} />
            </div>
            <div>
                <label>FiO2 (Fração de O2):</label>
                <input type="number" step="0.01" value={fiO2} onChange={e => setFiO2(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
            
        </div>
    );
}

export default CalculadoraSARAFio2;
