import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraPlantao() {
  const [medicos, setMedicos] = useState([{ nome: '' }]);
  const [inicioDescanso, setInicioDescanso] = useState('');
  const [fimDescanso, setFimDescanso] = useState('');
  const [resultados, setResultados] = useState([]);

  const handleMedicoChange = (index, event) => {
    const newMedicos = medicos.map((medico, i) => {
      if (i === index) {
        return { ...medico, nome: event.target.value };
      }
      return medico;
    });
    setMedicos(newMedicos);
  };

  const handleAddMedico = () => {
    setMedicos([...medicos, { nome: '' }]);
  };

  const handleRemoveMedico = (index) => {
    const newMedicos = medicos.filter((_, i) => i !== index);
    setMedicos(newMedicos);
  };

  const calcularHorarios = () => {
    const inicio = new Date(inicioDescanso);
    const fim = new Date(fimDescanso);
    const diff = (fim - inicio) / (1000 * 60 * 60); // diferença em horas

    if (diff > 24 || diff <= 0) {
      setResultados(['Período de descanso deve ser de até 24 horas e maior que 0 horas']);
      return;
    }

    const horasPorMedico = diff / medicos.length;
    let horarioInicio = new Date(inicio);
    const novosResultados = medicos.map((medico) => {
      const horarioFim = new Date(horarioInicio.getTime() + horasPorMedico * 60 * 60 * 1000);
      const resultado = `${medico.nome}: ${horarioInicio.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - ${horarioFim.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}`;
      horarioInicio = horarioFim;
      return resultado;
    });

    setResultados(novosResultados);
  };

  return (
    <div className="calc-page">
      <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
      <h1>Divisão de Descanso no Plantão</h1>
    <div className='input-medicos'>
      <h2>1. Adicionar Médicos</h2>
      {medicos.map((medico, index) => (
        <div key={index}>
          <input
            type="text"
            placeholder={`Nome do Médico ${index + 1}`}
            value={medico.nome}
            onChange={(event) => handleMedicoChange(index, event)}
          />
          <button className='botao-plantoes-remover' onClick={() => handleRemoveMedico(index)}>Remover</button>
        </div>
      ))}
      <button className='botao-plantoes' onClick={handleAddMedico}>Adicionar Médico</button>
      </div>
      <div className='input-descanso'>
      <h2>2. Selecionar Período de Descanso</h2>
      <div>
        <label>Início do Descanso:</label>
        <input
          type="datetime-local"
          value={inicioDescanso}
          onChange={(event) => setInicioDescanso(event.target.value)}
        />
      </div>
      <div>
        <label>Fim do Descanso:</label>
        <input
          type="datetime-local"
          value={fimDescanso}
          onChange={(event) => setFimDescanso(event.target.value)}
        />
      </div>
      </div>
      <div className='calcular-plantao'>
      <h2>3. Calcular Horários de Descanso</h2>
      <button className='botao-plantoes' onClick={calcularHorarios}>Calcular</button>
      </div>
      <h2>Resultados</h2>
      <div className="result-container">
        {resultados.length > 0 && resultados.map((resultado, index) => (
          <div key={index}>{resultado}</div>
        ))}
      </div>
    </div>
  );
}

export default CalculadoraPlantao;
