import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraCAMICU() {
    const [estadoMental, setEstadoMental] = useState(false);
    const [flutuacao, setFlutuacao] = useState(false);
    const [atencao, setAtencao] = useState(false);
    const [pensamento, setPensamento] = useState(false);
    const [comandos, setComandos] = useState(false);
    const [resultado, setResultado] = useState('');

    const calcularDelirium = () => {
        const sintomas = [
            estadoMental,
            flutuacao,
            atencao,
            pensamento,
            comandos
        ];
        
        const sintomasPositivos = sintomas.filter(sintoma => sintoma).length;
        
        if (sintomasPositivos >= 2) {
            setResultado('Delirium confirmado');
        } else {
            setResultado('Delirium não confirmado');
        }
    };

    useEffect(() => {
        calcularDelirium();
    }, [estadoMental, flutuacao, atencao, pensamento, comandos]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora CAM-ICU (Delirium)</h1>
            <div className="disclaimer">
                <p>O primeiro passo é a utilização da escala de agitação e sedação de Richmond avaliação da sedação (final da página), que é quantificada pela escala de agitação e sedação de Richmond (RASS). Se o RASS for superior a -4 (-3 até +4), deve-se então seguir para o segundo passo, que é a avaliação do delirium.</p>
            </div>
            
            <h2>Avaliação do Delirium</h2>
            <div>
                <label>
                    <input type="checkbox" checked={estadoMental} onChange={e => setEstadoMental(e.target.checked)} />
                    Alteração no estado mental basal
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={flutuacao} onChange={e => setFlutuacao(e.target.checked)} />
                    Flutuação do nível de consciência nas últimas 24 horas
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={atencao} onChange={e => setAtencao(e.target.checked)} />
                    &lt; 8 pontos no teste de Atenção
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={pensamento} onChange={e => setPensamento(e.target.checked)} />
                    Pensamento desorganizado
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={comandos} onChange={e => setComandos(e.target.checked)} />
                    Incapacidade de responder a comandos
                </label>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>

            <h2>Escala RASS (Richmond Agitation Sedation Scale)</h2>
            <ul>
                <li>+4: Agressivo, violento; perigoso.</li>
                <li>+3: Muito agitado; conduta agressiva; remoção de tubos ou cateteres.</li>
                <li>+2: Agitado; movimentos sem coordenação frequentes.</li>
                <li>+1: Inquieto; ansioso, mas sem movimentos agressivos ou vigorosos.</li>
                <li>0: Alerto, calmo.</li>
                <li>-1: Sonolento; não se encontra totalmente alerta, mas tem o despertar sustentado ao som da voz (maior de 10 seg).</li>
                <li>-2: Sedação leve; acorda rapidamente e faz contato visual com o som da voz (&lt;10 seg).</li>
                <li>-3: Sedação moderada; movimento ou abertura dos olhos ao som da voz (mas sem contato visual).</li>
                <li>-4: Sedação profunda; não responde ao som da voz, mas movimenta ou abre os olhos com estimulação física.</li>
                <li>-5: Incapaz de ser despertado; não responde ao som da voz ou ao estímulo físico.</li>
            </ul>
            <h2>Teste de Atenção (Attention Screening Examination - ASE)</h2>
            <p>Diga para o paciente que irá ler uma série de 10 letras e que ele deve apertar a sua mão toda vez que ouvir a letra A. Leia as letras de C A S A B L A N C A. Erros são contados toda vez que o paciente não aperta a mão quando ouve a letra A ou quando aperta a mão quando outra letra é lida.</p>
        </div>
    );
}

export default CalculadoraCAMICU;
