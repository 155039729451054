import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraDeficitAguaLivre() {
    const [sexo, setSexo] = useState('');
    const [faixaEtaria, setFaixaEtaria] = useState('');
    const [peso, setPeso] = useState('');
    const [naSerico, setNaSerico] = useState('');
    const [naEsperado, setNaEsperado] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularDeficitAgua = () => {
        const pesoNum = parseFloat(peso);
        const naSericoNum = parseFloat(naSerico);
        const naEsperadoNum = parseFloat(naEsperado);

        if (isNaN(pesoNum) || isNaN(naSericoNum) || isNaN(naEsperadoNum) || !sexo || !faixaEtaria) {
            setResultado('Preencha os campos em branco');
            return;
        }

        let tbw = 0;
        if (faixaEtaria === 'criança') {
            tbw = 0.6;
        } else if (faixaEtaria === 'adulto') {
            tbw = sexo === 'masculino' ? 0.6 : 0.5;
        } else if (faixaEtaria === 'idoso') {
            tbw = sexo === 'masculino' ? 0.5 : 0.45;
        }

        const deficitAgua = tbw * pesoNum * ((naSericoNum / naEsperadoNum) - 1);
        setResultado(`Déficit de água livre: <strong>${deficitAgua.toFixed(2)}</strong> L`);
    };

    useEffect(() => {
        if (sexo && faixaEtaria && peso && naSerico > 140 && naEsperado) {
            calcularDeficitAgua();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [sexo, faixaEtaria, peso, naSerico, naEsperado]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Déficit de Água Livre em Hipernatremia</h1>
            <div>
                <label>Sexo:</label>
                <select value={sexo} onChange={e => setSexo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                </select>
            </div>
            <div>
                <label>Faixa etária:</label>
                <select value={faixaEtaria} onChange={e => setFaixaEtaria(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="criança">Criança</option>
                    <option value="adulto">Adulto</option>
                    <option value="idoso">Idoso</option>
                </select>
            </div>
            <div>
                <label>Peso (kg):</label>
                <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div>
                <label>Na sérico (mEq/L):</label>
                <input type="number" value={naSerico} onChange={e => setNaSerico(e.target.value)} />
            </div>
            <div>
                <label>Na esperado (mEq/L):</label>
                <input type="number" value={naEsperado} onChange={e => setNaEsperado(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraDeficitAguaLivre;
