import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraSodioCorrigido() {
    const [naMedido, setNaMedido] = useState('');
    const [glicemiaMedida, setGlicemiaMedida] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularSodioCorrigido = () => {
        const naMedidoNum = parseFloat(naMedido);
        const glicemiaMedidaNum = parseFloat(glicemiaMedida);

        if (isNaN(naMedidoNum) || isNaN(glicemiaMedidaNum)) {
            setResultado('Preencha os campos em branco');
            return;
        }

        const sodioCorrigido = naMedidoNum + (1.6 * (glicemiaMedidaNum - 100) / 100);
        setResultado(`Na corrigido: <strong>${sodioCorrigido.toFixed(1)}</strong>`);
    };

    useEffect(() => {
        if (naMedido && glicemiaMedida) {
            calcularSodioCorrigido();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [naMedido, glicemiaMedida]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Sódio Corrigido</h1>
            <div>
                <label>Na medido (mEq/L):</label>
                <input type="number" value={naMedido} onChange={e => setNaMedido(e.target.value)} />
            </div>
            <div>
                <label>Glicemia medida (mg/dL):</label>
                <input type="number" value={glicemiaMedida} onChange={e => setGlicemiaMedida(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraSodioCorrigido;
