import { db } from './../firebase/firebase-setup'


export async function getDailyUse(userID, testType, lastXDays) {
    // Baixamos o uso dos últimos X dias. Se não houver, retornamos dicionários vazios
    // para cada data.
    const today = new Date()
    const promises = []

    for (let i = 0; i < lastXDays; i++) {
        const date = new Date(today)
        date.setDate(date.getDate() - i)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()

        const dateStr = `${year}-${month}-${day}`

        const docRef = db.collection(`users/${userID}/${testType}/statistics/daily`).doc(dateStr)
        promises.push(docRef.get())
    }

    try {
        const dailyUseDocs = await Promise.all(promises)
        const dailyData = dailyUseDocs.map(doc => {
            const date_str = doc.id

            if (doc.exists) {
                return {
                    date_str,
                    data: doc.data(),
                }
            } else {
                return {
                    date_str,
                    data: {},
                }
            }
        })

        return dailyData.reverse()
    } catch (error) {
        console.error('Erro ao obter os dados diários:', error)
        return []
    }
}


export async function getDailyUseTestTypes(userID, lastXDays) {
    const promises = [
        getDailyUse(userID, 'Flashcards', lastXDays),
        getDailyUse(userID, 'Residencia', lastXDays)
    ]
    
    try {
        const results = await Promise.all(promises)
        const newDailyUse = {}

        newDailyUse['Flashcards'] = results[0]
        newDailyUse['Residencia'] = results[1]

        return newDailyUse
    } catch (error) {
        console.error('getDailyUseTestTypes(): erro ao obter os dados diários:', error)
        return undefined
    }
}


export function sumDailyData(sortedDailyUse) {
            // Recebemos os dados de cada um dos dias. Aqui, passamos por todos eles,
        // para somar o número total de dias que estudou, de teste que respondeu,
        // de teste em cada classe (e.g., newGood, newEasy, etc), e o tempo que consumiu.
        const dailyDataSum = {
            daysStudied : 0
        }

        sortedDailyUse.forEach(item => {
            const totals = item.data['Total'] || false
            
            if (totals) {
                Object.keys(totals).forEach(key => {
                    if (!dailyDataSum[key]) {
                        dailyDataSum[key] = 0
                    }
                    dailyDataSum[key] += totals[key]
                })

                if (totals['nTestsAnswered'] > 0) {
                    dailyDataSum['daysStudied'] += 1
                }
            }
        })


        return dailyDataSum
}