import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraAdrenalina() {
    const [peso, setPeso] = useState('');
    const [volumeInfusao, setVolumeInfusao] = useState('');
    const [mgSoro, setMgSoro] = useState('');
    const [mlSoro, setMlSoro] = useState(250); // Valor padrão
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularInfusao = () => {
        const pesoNum = parseFloat(peso);
        const volumeInfusaoNum = parseFloat(volumeInfusao);
        const mgSoroNum = parseFloat(mgSoro);
        const mlSoroNum = parseFloat(mlSoro);

        if (isNaN(pesoNum) || isNaN(volumeInfusaoNum) || isNaN(mgSoroNum) || isNaN(mlSoroNum)) {
            setResultado('Preencha todos os campos necessários para ver o resultado');
            return;
        }

        const dose = (mgSoroNum * 1000 * volumeInfusaoNum) / (mlSoroNum * pesoNum * 60); // Dose em mcg/kg/min
        setResultado(`Dose: <strong>${dose.toFixed(2)}</strong> mcg/kg/min`);
    };

    useEffect(() => {
        if (peso && volumeInfusao && mgSoro) {
            calcularInfusao();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [peso, volumeInfusao, mgSoro, mlSoro]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Infusão de Adrenalina</h1>
            <div>
                <label>Peso do paciente (kg):</label>
                <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div>
                <label>Volume de infusão (mL/h):</label>
                <input type="number" value={volumeInfusao} onChange={e => setVolumeInfusao(e.target.value)} />
            </div>
            <div>
                <label>Total de mg no soro:</label>
                <input type="number" value={mgSoro} onChange={e => setMgSoro(e.target.value)} />
            </div>
            <div>
                <label>mL de soro:</label>
                <select value={mlSoro} onChange={e => setMlSoro(e.target.value)}>
                    <option value={250}>250 mL</option>
                    <option value={500}>500 mL</option>
                </select>
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
            
        </div>
    );
}

export default CalculadoraAdrenalina;
