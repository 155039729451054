import { useEffect } from 'react';

export default function OnClickOutside(ref, handler) {
    useEffect(() => {
        const listener = (event) => {
            // Se o clique for dentro do ref, retorna sem fazer nada
            if (ref.current && !ref.current.contains(event.target)) {
                // Parece bobo, mas é necessário para garantir que os outros listeners
                // associado ao clique local executem antes -- assim evitando que isos aqui
                // sequestre o clique. Por ex., impedindo ação de um botão, só escondendo
                // a toolbar, e necessitando que o usuário clique uma 2a vez.
                setTimeout(() => handler(event), 0);
            }
        }

        document.addEventListener('click', listener, false)
        document.addEventListener('touchstart', listener, false)
        document.addEventListener('mousedown', listener, false)

        return () => {
            document.removeEventListener('click', listener, false)
            document.removeEventListener('touchstart', listener, false)
            document.removeEventListener('mousedown', listener, false)
        }
    }, [ref, handler])
}
