// Jun, 2024
// Card para exibição de textos, avisos, etc, na tela do Extensivo

import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import FlashcardsIcon from './../assets/flashcard.png'
import ResidenciaIcon from './../assets/residencia.png'
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'




const Container = styled.div`
    width: 100%;
    margin-top: 2em;
    padding: 2em;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    ${props => props.theme.darkMode ? `
        box-shadow: none;
        background-color: rgb(90, 90, 90);
        color: rgb(200, 200, 200);
    `: `
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
        background-color: white;
        color: black;
    `}

    @media (max-width: 500px) {
        padding: 2em 0.5em 2em 0.5em;

    }


    & p {
        font-size: 0.8em;

        @media (max-width: 500px) {
            font-size: 1em;
            margin-bottom: 1em;
        }
    }

    & h1 {
        font-size: 1.4em !important;
        margin: 0 0 0.5em 0 !important;
        color: #a10d26;
        font-weight: bold;
        font-family: 'Lora';

        ${props => props.theme.darkMode ? `
            color: rgba(255, 71, 87, 0.8);;
        `: `
            color: #a10d26;
        `}
    }

    & img { 
        max-width: 80%;
    }

`


export default function ExtensivoCard({children}) {
    return (
        <Container>
            {children}
        </Container>
    );
}