import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';
import { Link } from 'react-router-dom';

const MedicamentoDetails = () => {
  const { medicamentoId } = useParams();
  const [medicamento, setMedicamento] = useState(null);
  const navRef = useRef(null);

  useEffect(() => {
    const fetchMedicamento = async () => {
      const docRef = doc(db, 'bulario', medicamentoId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        console.log('Documento encontrado:', docSnap.data());
        setMedicamento({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log('Documento não encontrado!');
      }
    };
    fetchMedicamento();
  }, [medicamentoId]);

  useEffect(() => {
    const navElement = navRef.current;

    const checkScroll = () => {
      const isAtEnd = navElement.scrollWidth - navElement.scrollLeft <= navElement.clientWidth;
      navElement.classList.toggle('at-end', isAtEnd);
    };

    if (navElement) {
      navElement.addEventListener('scroll', checkScroll);
      checkScroll();
    }

    return () => {
      if (navElement) {
        navElement.removeEventListener('scroll', checkScroll);
      }
    };
  }, [medicamento]);

  if (!medicamento) {
    return <div>Carregando...</div>;
  }

  const camposOrdenados = [
    'CLASS', 'PRESENTATION', 'ADMINISTRATION', 'TYPE',
    'CLINICADULT', 'CLINICCHILDREN', 'GERIATRIC',
    'PREGNANCYUSE', 'LACTATIONUSE', 'OPERATORYUSE', 'RENALADJUSTMENT',
    'HEPATICADJUSTMENT', 'ISSUESPRECAUTIONS', 'MONITORING',
    'ADVERSEEFFECTS', 'MECANISM', 'FONTS'
  ];

  const formatFieldName = (fieldName) => {
    const specialNames = {
      CLASS: 'Classe',
      PRESENTATION: 'Apresentação',
      ADMINISTRATION: 'Administração',
      TYPE: 'Tipo de Receituário',
      CLINICADULT: 'Uso Clínico e Posologia no Adulto',
      CLINICCHILDREN: 'Uso Clínico e Posologia na Criança',
      GERIATRIC: 'Uso Geriátrico',
      PREGNANCYUSE: 'Uso na Gravidez',
      LACTATIONUSE: 'Uso na Lactação',
      OPERATORYUSE: 'Uso no Perioperatório',
      RENALADJUSTMENT: 'Ajustes para Disfunção Renal',
      HEPATICADJUSTMENT: 'Ajustes para Disfunção Hepática',
      ISSUESPRECAUTIONS: 'Contraindicações e Precauções',
      MONITORING: 'Monitoramento Sério',
      ADVERSEEFFECTS: 'Efeitos Adversos',
      MECANISM: 'Mecanismo de Ação',
      FONTS: 'Fontes'
    };
    return specialNames[fieldName] || fieldName;
  };

  const sanitizeHTML = (html) => {
    // Remove pre and code tags if they exist
    return html.replace(/<\/?pre>/g, '').replace(/<\/?code>/g, '');
  };

  const renderCampo = (campo) => {
    const valorCampo = medicamento[campo];

    console.log(`Renderizando campo: ${campo}`, valorCampo);

    let displayValue;
    if (valorCampo && valorCampo.html) {
      const sanitizedHTML = sanitizeHTML(valorCampo.html);
      displayValue = <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
    } else {
      displayValue = <p>Nenhuma especificidade relatada.</p>;
    }

    return (
      <div key={campo} id={campo} className="mb-3 campo-medicamento">
        <h2>{formatFieldName(campo)}</h2>
        {displayValue}
      </div>
    );
  };

  return (
    <div className="medicamento-details">
      <Link to="/medicamentos" className="button-voltar-bulario">&lt;</Link>
      <div className="name-section">
      <h1>{medicamentoId}</h1>
      </div>
      <div className="anchor-nav">
        <nav ref={navRef} className="anchor-nav">
          {camposOrdenados.map(campo => (
            <a href={`#${campo}`} key={campo}>{formatFieldName(campo)}</a>
          ))}
        </nav>
      </div>
      {camposOrdenados.map(campo => (
        renderCampo(campo)
      ))}
    </div>
  );
};

export default MedicamentoDetails;