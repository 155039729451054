import React, { useEffect, useRef, useState } from 'react'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import { dateObjIntoStr, statisticsDateToReadable } from '../utils/Utils'
import { VictoryArea, VictoryAxis, VictoryBar, VictoryChart, VictoryLegend, VictoryLine, VictoryTooltip, VictoryVoronoiContainer } from 'victory'
import styled from 'styled-components'
import StatisticsExplanationCard from './StatisticsExplanationCard'
import { ChartContainer, GRAPHS_COLORS, getColor, getDateAxisTick } from './LastDaysChart'
import { isMobile, isTablet } from '../utils/BootstrapUtils'
import CustomTooltip from './CustomTooltip'
import { useSelector } from 'react-redux' 
import { darkBackgroundPrimaryColor, darkTextPrimaryColor, darkTextSecondaryColor, darkTextTertiaryColor, textWhiteBlack } from '../tests/FlashcardsStyles';
import OslerDateChart from './OslerDateChart'

import CardsIcon from './../assets/flashcard.png'
import ResidIcon from './../assets/residencia.png'
import TimerIcon from './../assets/extensivo_icons/timer.png'
import OslerNewDateChart from './OslerNewDateChart'



const NextDaysReviewsContainer = styled.div`
    display: flex;
    flex-direction: column;

    margin-top: 2em;
    width: 100%;

    @media (max-width: 900px) {
        width: 100%;
        padding: 1em 0 0 0;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 1em 0 0 0;
    }
`


const TooltipContainer = styled.div`
    padding: 1em 2em 1em 2em;
    min-width: 200px;
    border-radius: 0.5em;



    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundPrimaryColor};
    `:`
        background-color: white;    
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    `}
`

const TooltipTitle = styled.div`
    font-weight: bold;
    text-align: center;
    margin: 0;

    ${textWhiteBlack};
`

const TooltipSubtitle = styled.div`
    font-weight: bold;
    font-size: 0.85em;
    text-align: center;
    margin: 0 0 0.5em 0;

    ${props => props.color && `
        color: ${props.color};    
    `}
`


const TooltipRow = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 5px;
`;


const Comma = styled.span`
    color: gray;
`

const IconWrapper = styled.span`
  margin-right: 5px;
`;

const Icon = styled.img`
    height: 1.25em;
    width: 1.25em;
`




// Claramente, tudo igual ou muito igual ao que temos em LastDaysCharts -- PRECISA ser generalizado

const NewTooltip = ({ active, payload, darkMode, exhibitionType}) => {
    if (!active || !payload || !payload.length) return null;

    const renderContent = (count, color, label) => 
        count > 0 ? <span style={{ color: getColor(darkMode, color) }}>{count.toLocaleString('pt-BR')} {label}</span> : null;

    const renderRow = (icon, data, newColor, reviewColor) => {
        const [reviewCount, newCount] = data;
        const hasContent = reviewCount > 0 || newCount > 0;
    
        return (
            <TooltipRow>
            <IconWrapper>{<Icon src = {icon} />}</IconWrapper>
            {hasContent ? (
                <>
                {renderContent(reviewCount, reviewColor, 'revisões')}
                </>
            ) : '✅'}
            </TooltipRow>
        );
    }

    const datum = payload[0].payload;
    
    const dateDisplay = datum.date === 'Acumuladas' ? 'Acumuladas' : statisticsDateToReadable(datum.date);

    const time = datum.line1 ? datum.line1.toLocaleString('pt-BR') : false

    return (
        <TooltipContainer>
            <TooltipTitle>{dateDisplay}</TooltipTitle>
            {time && <TooltipSubtitle color = {getColor(darkMode, 'darkGray')}>{time} min estimados</TooltipSubtitle>} 
            {!time && <div style = {{marginBottom: '0.5em'}} />}

            {/* que horror. isso não é um bom paradigma */}
            {exhibitionType === 'Flashcards' && datum.bar1 && renderRow(CardsIcon, datum.bar1, 'pink', 'darkPink')}
            {exhibitionType === 'Residencia' && datum.bar1 && renderRow(ResidIcon, datum.bar1, 'blue', 'darkBlue')}
            {exhibitionType === 'Ambos' && datum.bar1 && renderRow(CardsIcon, datum.bar1, 'pink', 'darkPink')}
            {exhibitionType === 'Ambos' && datum.bar2 && renderRow(ResidIcon, datum.bar2, 'blue', 'darkBlue')}
        </TooltipContainer>
    )
};





export default function NextDaysReviews({dailyData, exhibitionType, selector}) {
    
    const [data, setData] = useState(false)
    const [metadata, setMetadata] = useState(false)

    const maxNumReviews = useRef(0)
    const maxTimeRequired = useRef(0)

    const darkMode = useSelector(state => state.theme.darkModeOn)

    const graphData = useRef()
    
    useEffect(() => {
        function getDateMidnight(date) {
            const d = new Date(date);
            d.setHours(0, 0, 0, 0);
            return d.getTime();
        }


        function buildFutureReviews(allReviews, testType) {
            if (allReviews[testType]) {
                const todayMidnight = getDateMidnight(Date.now())
                const avgTestsSpeed = dailyData[testType].timeSpent / dailyData[testType].nTestsAnswered
                
                let accumulatedReviews = 0
                const reviewsEachDay = {}
                const nextDaysReviews = []

                Object.entries(allReviews[testType]).forEach(([test_id, date_ms]) => {
                    const midnight = getDateMidnight(date_ms);
                    if (midnight < todayMidnight) {
                        accumulatedReviews++;
                    } else {
                        if (!reviewsEachDay[midnight]) {
                            reviewsEachDay[midnight] = 0;
                        }
                        reviewsEachDay[midnight]++;
                    }
                });
                

                for (let day = 0; day <= 30; day++) {
                    const nextMidnight = todayMidnight + day * 86400000;
                    const date_str = dateObjIntoStr(new Date(nextMidnight))

                    const nReviews = reviewsEachDay[nextMidnight]

                    if (nReviews) {
                        const minutesRequired = Math.round( (nReviews * avgTestsSpeed) / 60 )

                        // Para normalização do gráfico
                        if (nReviews > maxNumReviews.current) {
                            maxNumReviews.current = nReviews
                        }
                        
                        if (minutesRequired > maxTimeRequired.current) {
                            maxTimeRequired.current = minutesRequired
                        }

                        nextDaysReviews.push({
                            date_ms: nextMidnight,
                            date: date_str,
                            nReviews: nReviews,
                            minutesRequired: minutesRequired
                        })
                    } else {
                        nextDaysReviews.push({
                            date_ms: nextMidnight,
                            date: date_str,
                            nReviews: 0,
                            minutesRequired: 0
                        })
                    }
                }

                const accumulatedMinutesRequired = Math.round((accumulatedReviews * avgTestsSpeed) / 60);

                // Atualizar max values para incluir as acumuladas
                if (accumulatedReviews > maxNumReviews.current) {
                    maxNumReviews.current = accumulatedReviews;
                }
                if (accumulatedMinutesRequired > maxTimeRequired.current) {
                    maxTimeRequired.current = accumulatedMinutesRequired;
                }

                const getCustomTick = (n) => {
                    if (n < 50) {
                      return '✅';
                    } else if (n < 200) {
                      return '🚨';
                    } else {
                      return '🫠';
                    }
                }

                nextDaysReviews.unshift({
                    date_ms: -1,
                    date: 'Acumuladas',
                    nReviews: accumulatedReviews,
                    minutesRequired: accumulatedMinutesRequired,
                    customTick : getCustomTick(accumulatedReviews)
                })
                
                return nextDaysReviews
            }
        }

        const allReviews = UserReviewsInfo.allReviews
        if (allReviews) {
            graphData.current = {}
            graphData.current['Flashcards'] = buildFutureReviews(allReviews, 'Flashcards')   
            graphData.current['Residencia'] = buildFutureReviews(allReviews, 'Residencia')   
        }
    }, [])


    useEffect(() => {
        // Acima, construímos graphData que, para cada testType, possui um array de dicionários,
        // cada um com as chaves: date_ms, date (str), nReviews, e minute required.
        // 
        // Agora, organizamos para apropriada exibição no gráfico, considerando o que o usuário
        // quer exibir e a estrutura de dados necessária.

        const dataTmp = graphData.current['Flashcards'].map((cardItem, index) => {
            const residItem = graphData.current['Residencia'][index]

            if (exhibitionType === 'Flashcards') {
                return {
                    date: cardItem.date,
                    bar1: [cardItem.nReviews],
                    line1: cardItem.minutesRequired,
                    customTick : cardItem.customTick ? cardItem.customTick : false
                }
            } else if (exhibitionType === 'Residencia') {
                return {
                    date: residItem.date,
                    bar1: [residItem.nReviews],
                    line1: residItem.minutesRequired,
                    customTick : residItem.customTick ? residItem.customTick : false
                }
            } else {
                // ambos
                return {
                    date: cardItem.date,
                    bar1: [cardItem.nReviews],
                    bar2: [residItem.nReviews],
                    line1: cardItem.minutesRequired + residItem.minutesRequired,
                    customTick : residItem.customTick ? residItem.customTick : false
                }
            }
        })

        const metadataTmp = {
        }

        if (exhibitionType === 'Flashcards') {
            metadataTmp['bar1_labels'] = ['Revisões (cards)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkPink')]
        } else if (exhibitionType === 'Residencia') {
            metadataTmp['bar1_labels'] = ['Revisões (questões)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkBlue')]
        } else {
            metadataTmp['bar1_labels'] = ['Revisões (cards)']
            metadataTmp['bar1_colors'] = [getColor(darkMode, 'darkPink')]
            metadataTmp['bar2_labels'] = ['Revisões (questões)']
            metadataTmp['bar2_colors'] = [getColor(darkMode, 'darkBlue')]
        }

        setMetadata(metadataTmp)
        setData(dataTmp)
    }, [exhibitionType, graphData])




    return (
        <>
        
        { data &&
            <NextDaysReviewsContainer>

                <StatisticsExplanationCard
                    emoji = '⌛'
                    highlight = 'Revisões nos próximos 30 dias'
                    text = 'Quantas revisões por dia e o tempo para completá-las. O tempo é baseado na sua média de segundo por flashcards e/ou questões do último mês — há proporcionalidade, e a barra representa ambos.' />

                { selector }

                <OslerNewDateChart
                    style = {{marginTop: '3em'}}
                    showLine = {false}
                    data = {data}
                    metadata = {metadata}
                    tooltip = {(props) => <NewTooltip {...props} darkMode = {darkMode} exhibitionType={exhibitionType} />} />

            </NextDaysReviewsContainer>
        }
        { !data && 
            <StatisticsExplanationCard
                emoji = '⚠️'
                highlight = 'Parece que suas revisões não estão carregadas'
                text = 'Se você for à tela inicial e voltar aqui, deve resolver.' />
        }       
        </>
    )
}