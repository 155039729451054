import { merge } from 'd3'
import OslerData, { KEYS } from '../controllers/OslerData'
import { db } from '../firebase/firebase-setup'
import firebase from 'firebase/compat/app'
import Session from './Session'



class PersonalNotes {
    construct(user) {
        this.userID = user.id
        this.testsWithPostIts = {
            'Residencia' : OslerData.data['Residencia'][KEYS.TESTS_WITH_STICKIES],
            'Flashcards' : OslerData.data['Flashcards'][KEYS.TESTS_WITH_STICKIES],
        }
    }



    async getPersonalNote(testType, testID) {
        testID = this.adjustToCloze(testType, testID)

        if (this.testsWithPostIts[testType].includes(testID)) {        
            const doc = await db.doc(`users/${this.userID}/${testType}/post_its/tests_post_its/${testID}`).get()
            
            if (doc.exists && doc.data().userNote) {
                return doc.data().userNote
            } else {
                console.log(`ERRO: Não conseguimos carregar o userNote de ${testID}`)
                return `<p>Houve algum problema e o conteúdo dessa nota foi perdido.</p><p>Você pode só deletá-la. Mas convém tirar print e falar com o suporte, para tentarmos entender porque isso ocorreu. (${testID})</p>`
            }
        }
        else {
            return undefined
        }
    }


    adjustToCloze(testType, testID) {
        // Se for flashcard e cloze, faremos um ID adjusted, retirando o _clz do final
        // para que ele seja salvo e carregado para todos os clozes.
        if (testType === 'Flashcards' && testID.includes('_clz')) {
            return testID.split('_clz')[0]
        }
        else {
            return testID
        }
    }


    async savePersonalNote(testType, testID, noteContent) {
        // a princípio, só estamos salvando notaas que não existem
    
    
        /*
            testType - collection
                notes - doc
                    test_notes - collection
                        id
                        id
                        id
                        id
                    metadata - collection
                        tests_with_notes
        */
        // personal - doc
        // tests_notes - collection
        // testes_notes
        console.log(`PersonalNotes: salvando nota para ${testID}...`)
        const path = `users/${this.userID}/${testType}/post_its`
   
        testID = this.adjustToCloze(testType, testID)

        if (testType === 'Flashcards' && testID.includes('_clz')) {
            this.adjustSession(noteContent)
        }

        this.testsWithPostIts[testType].push(testID)

        await db.doc(`${path}/tests_post_its/${testID}`).set({
            'userNote' : noteContent,
            'lastUpdated' : new Date().toLocaleString('pt-BR'),
        })
    
        await db.doc(`${path}/metadata/tests_with_post_its`).set({
            'data' : firebase.firestore.FieldValue.arrayUnion(testID)
        }, { merge : true})

    }


    async deletePersonalNote(testType, testID) {
        testID = this.adjustToCloze(testType, testID)

        const path = `users/${this.userID}/${testType}/post_its`
    
        await db.doc(`${path}/tests_post_its/${testID}`).delete()
    
        await db.doc(`${path}/metadata/tests_with_post_its`).set({
            'data' : firebase.firestore.FieldValue.arrayRemove(testID)
        }, { merge : true})

        this.testsWithPostIts[testType] = this.testsWithPostIts[testType].filter(id => id !== testID)
        console.log(this.testsWithPostIts[testType])
    }


    adjustSession(noteContent) {
        // Em meio a uma sessão de testes, ou no próprio modo consulta, o usuário
        // adiciona nota a um cloze. Ela NÃO aparecerá nos demais, pois todas as notas
        // já foram baixadas...

        // if (Session && Session.session && Session.session.length > 0) {
        //     Session.session.forEach(test => {
        //         test.personalNote = noteContent
        //     })

        //     console.log(Session.session)
        // }
    }

}


export default new PersonalNotes()