import styled from 'styled-components'
import session from '../controllers/Session'
import { RowCSS } from '../components/BasicComponents'


const NavigationButtons = styled.div`
    ${RowCSS}
    justify-content: space-around;

    margin-top: 2em;
    width: 100%;

    @media (max-width: 500px) {
        margin-bottom: 1em;
    }
`


const NavButton = styled.button`
    background-color: #4A90E2;
    color: white;
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 0.5em;
    font-weight: 600;
    cursor: pointer;
    box-shadow: inset 0 -0.6em 0 -0.35em rgba(0,0,0,0.17);

    &:hover {
        background-color: #357ABD;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`


export default function AnswerScreenNavBttns() {
    return (
        <NavigationButtons>
            {session.canMoveToPrevious() && (
                <NavButton onClick={() => session.moveToPrevious()}>
                    ← Anterior
                </NavButton>
            )}
            {session.canMovetoNext() && (
                <NavButton onClick={() => session.moveToNext()}>
                    Próximo →
                </NavButton>
            )}
        </NavigationButtons>
    )
}