import React, {useState, useEffect} from 'react'

import './LoadingUserScreen.css'
import IsLoading from './IsLoading'
import styled from 'styled-components';
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles';



const LoadingUserScreenDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: pink;
    min-height: 100vh;
    ${backgroundWhiteBlack}
`

export default function LoadingUserScreen(props) {
    return (
        <LoadingUserScreenDiv>
            <IsLoading />
        </LoadingUserScreenDiv>
    )
}