import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Checkbox from './Checkbox'
import { RowCSS } from '../components/BasicComponents'
import OslerData, { KEYS } from '../controllers/OslerData'


const Years = styled.div`
    ${RowCSS};
    justify-content: flex-start;
    align-self: center;
    flex-wrap: wrap;
    padding: 1em;

    width: 100%;

    @media (max-width: 768px) {
        padding: 0.5em;
    }
`

const Year = styled.div`
    ${RowCSS};
    width: 15%;
    margin: 0 5% 1em 5%;
    cursor: pointer;
    border-radius: 0.5em;

    @media (max-width: 768px) {
        width: 40%;
        margin: 0 5% 1em 5%;
        justify-content: center;
    }
`

const Title = styled.p`
    margin: 0;
    padding: 0 0 0 0.5em;
    font-size: 0.9em;
    font-weight: bold;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#1d1d1f'};


    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 0 0 0 0.3em;
    }
`

export default function YearsCard({ updateChosenYears, previouslyChecked }) {
    const [years, setYears] = useState(false)
    const [checkedYears, setCheckedYears] = useState(false)

    useEffect(() => {
        if (checkedYears !== previouslyChecked) {
            console.log('YearsCard: houve mudança nos props, vamos recarregar...')
            const yearsTmp = getYears()
            setYears(yearsTmp)
            setCheckedYears(previouslyChecked)
        }
    }, [previouslyChecked])


    function getYears() {
        const examsMetadata = OslerData.data[KEYS.RESIDENCIA][KEYS.EXAMS_METADATA]
        const allYears = new Set()
        Object.values(examsMetadata).forEach(data => {
            if (data.R1 && data.R1.years) {
                Object.keys(data.R1.years).forEach(year => allYears.add(year))
            }
        })
        return Array.from(allYears).sort((a, b) => a - b)
    }


    function selectYear(year) {
        const newCheckedYears = checkedYears.includes(year)
            ? checkedYears.filter(y => y !== year)
            : [...checkedYears, year]
        
        setCheckedYears(newCheckedYears)
        updateChosenYears(newCheckedYears)
    }

    if (!years) return
    return (
        <Years>
            {years.map(year => (
                <Year 
                    onClick={() => selectYear(year)} 
                    key={year}
                >
                    <Checkbox
                        checked={checkedYears.includes(year)}
                        mildlyChecked={false} 
                    />
                    <Title>{year}</Title>
                </Year>
            ))}
        </Years>
    )
}