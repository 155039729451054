import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { ColumnCSS } from '../components/BasicComponents'
import OslerPieChart from './OslerPieChart'
import { PiesContainer } from './GeneralUseStatistics'
import { textWhiteBlack } from '../tests/FlashcardsStyles'


const Container = styled.div`
    ${ColumnCSS};

    width: 50%;


    @media (max-width: 900px) {
        margin-top: 2em;
        width: 50%;
    }

    @media (max-width: 500px) {
        margin-top: 2em;
        width: 100%;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 0.5em;
`;

const Icon = styled.img`
    height: 2em;

    @media (max-width: 900px) {
        height: 2em;
    }

    @media (max-width: 500px) {
        height: 2em;
    }
`;

const Title = styled.p`
    font-size: 1.5em;
    font-weight: bold;

    ${textWhiteBlack}

    margin: 0;

    ${props => props.hasSubtitle && `
        margin-bottom: -0.3em;    
    `}

    @media (max-width: 900px) {
        font-size: 1.5em;
    }

    @media (max-width: 500px) {
        font-size: 1.5em;
    }
`;

const Subtitle = styled.p`
    margin: 0;
    font-size: 1em;

    ${textWhiteBlack}

    @media (max-width: 900px) {
        font-size: 1.0em;
    }

    @media (max-width: 500px) {
        font-size: 1.0em;
    }
`;

const NoTestContainer = styled.div`
    ${ColumnCSS};
    justify-content: center;
    height: 100%;

    p {
        font-weight: bold;
        font-size: 1.1em;
    }
`;

const PieContainer = styled.div`
    width: 100%;
    aspect-ratio: 1 / 1;
    margin-bottom: 1em;

    // background-color: cyan;
    // border: 1px solid black;
`



export default function StatisticsPie({ title, subtitle, icon, data }) {
    const darkMode = useSelector(state => state.theme.darkModeOn)

    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <Container>
            <TitleContainer>
                <Icon src={icon} />
                <InfoContainer>
                    { title && <Title hasSubtitle = {subtitle}>{title}</Title> }
                    { subtitle && <Subtitle>{subtitle}</Subtitle> }
                </InfoContainer>
            </TitleContainer>

            <OslerPieChart
                data = {data} />

        </Container>
    )
}