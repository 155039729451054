// Outubro, 2024
// Revisto e otimizado. Ainda há algumas coisas que poderiam ser melhoradas. Por exemplo,
// acredito que Session não deveria ser criada aqui -- a classe deveria limitar-se a retornar os testIDs.
// Além disso, poderíamos tornar mais robusto avaliando os parâmetros.

import session  from './Session'
import { shuffleArray, isClozeCard, getFlashcardDeckRoot } from '../utils/Utils'
import LikedBuriedController from './LikedBuriedController'
import OslerData, { KEYS } from './OslerData'
import { PredefinedSessionConfig, SORT_MODES, TEST_TYPES } from './SessionConfig'


class SessionBuilder {
    prepare(user, userInfo, testsPerTag) {
        this.userID = user.id;
        this.userReviewsInfo = userInfo.info

        // Em retrospecto, isso deveria ser feito em UserReviewsInfo, mas...
        // Ficar fazendo busca com .includes em arrays é muito grande, especialmente
        // quando fazemos para vários testes, gerando complexidade O(n^2).
        // Para resolver, criamos um mapa
        this.userReviewsInfoSet = {}

        for (let type of ['Residencia', 'Flashcards']) {
            this.userReviewsInfoSet[type] = {}
            this.userReviewsInfoSet[type].pendingReviews = new Set(this.userReviewsInfo[type].pendingReviews)
            this.userReviewsInfoSet[type].futureReviews = new Set(this.userReviewsInfo[type].futureReviews)
        }
        
        this.allInfo = userInfo
        this.testsPerTag = testsPerTag
        
        this.buriedIDs = JSON.parse(JSON.stringify(LikedBuriedController.buried))
       
        // Não são array de IDs, mas um dict da forma { ID : True }
        this.anuladasIDs = userInfo.anuladasInfo.allIDs
        this.extensivoIDs = userInfo.extensivo
    
        this.cache = {}
    }


    /*
        AS ÚNICAS SESSÕES QUE DEVEM SER CHAMADAS PUBLICAMENTE SÃO
        start() & simulate().

        Idealmente, colocar o restomo como private.
    */
    start(testType, builder, studyMode, IDsOrTagaths, config, saveAsLastSession, downloadStatistics = true) {
        // O objetivo deste método é construir uma Session para o usuário, em 
        // função dos parâmetros recebidos.
        const listIDs = this.loadSessionByType(testType, builder, IDsOrTagaths, config, true)
        session.start(this.userID, testType, listIDs, saveAsLastSession, studyMode, downloadStatistics)
    }
    
    simulate(testType, builder, IDsOrTagaths, config, shouldSort = false) {
        // A única diferença em relação a start() é que
        // não inicializa uma sessão, mas retorna uma lista de IDs.
        return this.loadSessionByType(testType, builder, IDsOrTagaths, config, shouldSort)
    }
    
    
    loadSessionByType(testType, builder, IDsOrTagaths, config, shouldSort = true) {
        switch (builder) {
            case 'custom':
                return this.buildCustomSession(testType, IDsOrTagaths, config, shouldSort)
            
            case 'predefined':
                return this.buildPredefinedSession(testType, IDsOrTagaths, config)
            
            default:
                console.error('SessionBuilder - Invalid selectedOption:', builder)
                return []
        }
    }
    

    buildCustomSession(testType, listOfTagsPaths, config, shouldSort) {
        let testIDs = listOfTagsPaths?.length > 0
            ? this.findTestsForTagPaths(testType, listOfTagsPaths)
            : Object.keys(OslerData.data[testType][KEYS.ALL_TESTS_IDS])

    
        testIDs = testIDs.filter(id => {
            if (testType === TEST_TYPES.RESIDENCIA) {
                if (config.years?.length) {
                    const year = id.split('_')[2]
                    if (!config.years.includes(year)) return false
                }

                if (config.institutions?.length) {
                    const institutionID = id.split('_')[1]
                    const data = OslerData.data[TEST_TYPES.RESIDENCIA][KEYS.INSTITUTIONS_IDS]
                    const institutionIDs = config.institutions.map(name => data[name])
                    if (!institutionIDs.includes(institutionID)) return false
                }
    
                if (config.removeAnuladas && this.anuladasIDs[id]) return false
                if (config.onlyExtensivo && !this.extensivoIDs[id]) return false
                if (config.removeSolved && this.allInfo.residenciaSolved[id]) return false
            }
    
            const { pendingReviews, futureReviews } = this.userReviewsInfoSet[testType]
            
            if (config.removePendingReviews && pendingReviews.has(id)) return false
            if (config.removeFutureReviews && futureReviews.has(id)) return false
            if (config.removeBuried && this.buriedIDs[testType].includes(id)) return false
            
            if (config.removeNewTests) {
                const isPendingReview = pendingReviews.has(id)
                const isFutureReview = futureReviews.has(id)
                const isSolved = testType === TEST_TYPES.RESIDENCIA && this.allInfo.residenciaSolved[id]
                if (!(isPendingReview || isFutureReview || isSolved)) return false
            }
    
            return true
        })
    
        if (shouldSort) {
            if (config.ordering === SORT_MODES.SORT) {
                testIDs = this.sortIDs(testType, testIDs, config.detachCousins)
            }
            else if (config.ordering === SORT_MODES.SHUFFLE) {
                testIDs = this.shuffle(testIDs)
            }
        }
        
        return testIDs
    }
    

    filterResidenciaByYears(testIDs, selectedYears) {
        if (!selectedYears?.length) return testIDs
        
        return testIDs.filter(id => {
            const year = id.split('_')[2]
            return selectedYears.includes(year)
        })
    }


    filterResidenciaByInstitutions(testIDs, selectedInstitutions) {
        if (!selectedInstitutions?.length) return testIDs
        
        const data = OslerData.data[TEST_TYPES.RESIDENCIA][KEYS.INSTITUTIONS_IDS]
        const institutionIDs = selectedInstitutions.map(name => data[name])
        
        return testIDs.filter(id => {
            const institutionID = id.split('_')[1]
            return institutionIDs.includes(institutionID)
        })
    }


    removeAnuladas(listIDs) {
        return listIDs.filter(id => !this.anuladasIDs[id])
    }


    reduceToExtensivo(listIDs) {
        return listIDs.filter(id => this.extensivoIDs[id])
    }


    removeSolved(listIDs) {
        return listIDs.filter(id => !this.allInfo.residenciaSolved[id])
    }


    removePendingReviews(testType, listIDs) {
        return listIDs.filter(id => !this.userReviewsInfoSet[testType].pendingReviews.has(id))
    }
    

    removeNewTests(testType, listIDs) {
        const {pendingReviews, futureReviews} = this.userReviewsInfoSet[testType]
        
        return listIDs.filter(id => {
            // Um teste é "new" se NÃO está em nenhuma dessas categorias
            const isPendingReview = pendingReviews.has(id)
            const isFutureReview = futureReviews.has(id)
            const isSolved = testType === TEST_TYPES.RESIDENCIA && this.allInfo.residenciaSolved[id]
            
            // Se está em alguma categoria, não é new, então mantemos
            // Se não está em nenhuma, é new, então removemos
            return isPendingReview || isFutureReview || isSolved
        })
    }


    removeBuried(testType, listIDs) {
        return listIDs.filter(id => !this.buriedIDs[testType].includes(id))
    }
    

    removeFutureReviews(testType, listIDs) {
        return listIDs.filter(id => !this.userReviewsInfoSet[testType].futureReviews.has(id))
    }

    
    findTestsForTagPaths(testType, listOfTagsPaths) {
        /*
            Existe a possibilidade que um testID esteja listado em mais de uma tagpath.
        
            Por exemplo, se recebermos as tagpaths "Clínica Cirúrgica" e 
            "Clínica Cirúrgica/Abdome Agudo/Abdome Agudo Inflamatório/Apendicite", certamente haverá overlap.

            Evidente, não queremos o mesmo teste aparecendo duas vezes na mesma sessão. Para evitar isso,
            o modo computacionalmente mais eficaz é construir um Set.
        */
        const pathsDict = this.testsPerTag[testType]
        const uniqueTests = new Set()
        
        for (const path of listOfTagsPaths) {
            const testsInPath = pathsDict[path]
            if (testsInPath) {
                for (const testID of testsInPath) {
                    uniqueTests.add(testID)
                }
            }
        }
        
        return Array.from(uniqueTests)
    }


    buildPredefinedSession(testType, listTestIDs, config = PredefinedSessionConfig.create()) {
        if (!config) return listTestIDs
    
        if (config.ordering === SORT_MODES.SORT) {
            if (testType === TEST_TYPES.RESIDENCIA) {
                listTestIDs = this.sortResidenciaIDs(listTestIDs)
            }
            else {
                listTestIDs = this.sortFlashcards(listTestIDs, config.detachCousins)
            }
        }
        else if (config.ordering === SORT_MODES.SHUFFLE) {
            listTestIDs = this.shuffle(listTestIDs)
        }
    
        return listTestIDs
    }

    
    shuffle(listIDs) {
        return shuffleArray(listIDs)
    }


    sortIDs(testType, testIDs, detachCousins) {
        if (testType === TEST_TYPES.RESIDENCIA) {
            return this.sortResidenciaIDs(testIDs)
        } else {
            return this.sortFlashcards(testIDs, detachCousins)
        }
    }

    sortResidenciaIDs(listIDs) {
        // Criamos uma cópia, por segurança.
        return [...listIDs].sort((id1, id2) => {
            // Ordenamos por instituição, por anos dentro de cada instituição, e por número do
            // teste na prova -- tomando cuidado para não colocarmos 100 antes do 11, o que acontece
            // se ordenarmos strings.
            //
            // Os IDs são sempre no modelo: residencia_INSTITUIÇÃO_ANO_QX
            const [, inst1, year1, num1] = id1.split('_')
            const [, inst2, year2, num2] = id2.split('_')
    
            if (inst1 !== inst2) {
                return inst1.localeCompare(inst2, 'pt-BR')
            }
            else if (year1 !== year2) {
                return parseInt(year2) - parseInt(year1)
            }
            else {
                return parseInt(num1) - parseInt(num2)
            }
        })
    }


    sortFlashcards(listIDs, shouldDetachCousins = false) {
        // Agrupamos por decks. Ordenamos os cards de cada deck. 
        // Se desejado, fazemos o detach cousins.
        const deckGroups = {}

        console.log(`sortFlashcards(): will detach cousins? ${shouldDetachCousins}`)
        
        listIDs.forEach(id => {
            const { deckName } = this.parseFlashcardID(id)
            deckGroups[deckName] = deckGroups[deckName] || []
            deckGroups[deckName].push(id)
        })
    

        const sortedDecks = Object.entries(deckGroups).map(([deckName, deckIDs]) => {
            let sortedIDs = this.sortFlashcardsIDs(deckIDs)    
            if (shouldDetachCousins) {
                sortedIDs = this.detachCousins(sortedIDs)
            }
            
            return {
                deckName,
                ids: sortedIDs
            }
        })
    
        // Ordena os decks alfabeticamente...
        return sortedDecks
            .sort((a, b) => a.deckName.localeCompare(b.deckName, 'pt-BR'))
            .flatMap(deck => deck.ids)
    }


    sortFlashcardsIDs(listIDs) {
        return [...listIDs].sort((id1, id2) => {
            const card1 = this.parseFlashcardID(id1)
            const card2 = this.parseFlashcardID(id2)
            
            if (card1.deckName !== card2.deckName) {
                // Teoricamente, não precisa, porque sortFlashcards já agrupa por deck antes,
                // mas... pela robustez...
                return card1.deckName.localeCompare(card2.deckName, 'pt-BR')
            }
            else if (card1.cardNumber !== card2.cardNumber) {
                // Atente-se que cardNumber pode conter uma letra, ou seja, ser da forma "12b",
                // por exemplo.
                //
                // Nós extraímos o número em si, dos dois cards. Se forem diferentes, ordenamos
                // por eles. Se forem iguais, comparamos a string completa.
                const num1 = parseInt(card1.cardNumber.match(/\d+/)[0])
                const num2 = parseInt(card2.cardNumber.match(/\d+/)[0])

                if (num1 !== num2) {
                    return num1 - num2
                }
                else {
                    return card1.cardNumber.localeCompare(card2.cardNumber, 'pt-BR')
                }
            }
            else {
                // Mesmo deck, mesmo número de card, clozes diferentes.
                return card1.clozeNumber - card2.clozeNumber
            }
        })
    }


    parseFlashcardID(id) {
        /*
            Infelizmente, devido à persistência de alguns decks antigos, e edições em meio aos decks,
            há alguma variabilidade nos IDs. Mas não é o fim do mundo.

            Todo ID é da forma: A_B_C
                - A é o nome do deck, uma string maior ou menor, que pode conter outros "_" dentro
                - B é uma string da forma AB, onde A é um número e B, se existir, é uma letra
                - C é da forma "clzX", onde X é um número, e pode não existir

            Exemplos:
                Multimídia_Abuso Sexual_01
                Multimídia_Cirurgia Geral_Partes Moles_11
                flashcard_adaptGestacao_07b
                flashcard_adaptGestacao_20_clz1
                flashcards_glomerulonefritePós-estreptocócica(gnpe)_extensivo_32_clz2
        */
        const [baseID, clozeNumber] = id.split('_clz')
        const parts = baseID.split('_')
        const cardNumber = parts.pop()
        const deckName = parts.join('_')
        
        return {
            deckName,
            cardNumber,
            clozeNumber: clozeNumber ? parseInt(clozeNumber) : null
        }
    }


    detachCousins(listIDs) {
        /*
            Responder clozes de uma mesma frase em sequência é chato (pois 
            repetitivo) e infeficaz (você se apoia na memória de curtíssima
            duração, pois acabou de ler).
            
            Clozes de uma mesma frase são ditos "cousins" de uma mesma
            "family".

            A estratégia é colocar outros flashcards entre os cousins, reordenando
            os elementos de listIDs antes de baixar os testes.

            Corremos o array sequencialmente. Quando encontramos qualquer cloze,
            se houver cousins (pode ser um cloze único!), eles estarão adjacentes.

            Nós pegamos esses cousins e distribuímos entre os flashcards restantes.

            E de modo "recursivo" seguimos para o resto do array, procurando uma
            nova family.

            Registramos as famílias que já foram processadas para quando reencontrarmos
            o cousin.
        */
        let listIDs_copy = [...listIDs]

                
        for(let i = 0; i < listIDs_copy.length; i++) {
            const currentCard = listIDs_copy[i]

            // Só há sentido reordenar se é um cloze.
            if ( isClozeCard(currentCard) ) {
                // console.log('\n\n')
                // console.log(`${currentCard} is a cloze card`)

                // Os IDs são da forma "flashcards_descritivo_03_clzX", onde
                // X é um número específico para cada cloze/cousin.
                //
                // Então, a família é indicada por "flashcards_descritivo_03".
                const family = this.getCardFamily( currentCard )
                
                let cousins = []                
                cousins.push(currentCard)

                for (let j = i + 1; j < listIDs_copy.length; j++) {
                    const adjacentCard = listIDs_copy[j]
                    const adjacentFamily = this.getCardFamily( adjacentCard )

                    if (family === adjacentFamily) {
                        cousins.push(adjacentCard)
                    }
                    else {
                        break;
                    }
                } 


                // Reunimos todos os cousins: grupo de cards, incluído o prório,
                // que são clozes de uma mesma frase.
                // 
                // Mas veja que só os reunimos se eles estava madjacentes!!
                //
                // Agora, vamos torná-los distantes entre si. O que... só tem
                // sentido se há mais de um cousin.
                if (cousins.length > 1) {

                    /*
                        Separamos em três partes.
                            1. Flashcards antes de encontrarmos o primeiro cousin. Usaremos
                            como base para adicionar os novos elementos em cima, em nova ordem.

                            2. Cousins da família.

                            3. Flashcards restantes, que serão os separadores. Podemos
                            fazer um slice porque **presumimos que todos os primos estavam
                            um do lado do outro, sem separadores**.
                    */

                    const previousCards = listIDs_copy.slice(0, i)
                    const separators = listIDs_copy.slice(i + cousins.length)

                    
                    // console.log(previousCards)
                    // console.log(cousins)
                    // console.log(separators)

                    /*
                        Temos M separadores e N cousins (excluindo o atual).
                        Seja R = M % N.

                        O intervalo mínimo entre os cards será de I separadores,
                            I = (M - R) / N
                        Mas ainda poderemos aumentar alguns intervalos, de modo a consumir
                        R.
                    */
                    const M = separators.length
                    const N = cousins.length - 1

                    let R = M % N
                    const I = (M - R) / N

                    for (let cousin of cousins) {
                        previousCards.push(cousin)

                        let intervalSize = I;
                        if (R > 0) {
                            R--;
                            intervalSize++;
                        }

                        // deveria ser > 1?
                        if (separators.length > 0) {
                            previousCards.push( ...separators.splice(0, intervalSize) )
                        }
                    }

                    // console.log(previousCards)
                    // console.log('\n\n')
                    listIDs_copy = previousCards
                }
            }
        }

        return listIDs_copy
    }


    getCardFamily(ID) {
        const familyEnd = ID.indexOf('_clz')

        if (familyEnd === -1) {
            // Só irá ocorrer se não for um cartão do tipo cloze.
            return undefined
        }
        else {
            return ID.slice(0, familyEnd)
        }
    }



    // checkIfCousins(ID1, ID2) {
    //     /* ATENÇÃO: 
    //     return this.getCardFamily(ID1) === this.getCardFamily(ID2)
    // }
// 
}


export default new SessionBuilder()