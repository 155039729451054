import React, { useState } from 'react';
import '../bedside/CalcPage.css';
import { Link } from 'react-router-dom';

function CalculadoraCargaTabagica() {
    const [maçosPorDia, setMaçosPorDia] = useState('');
    const [anosTabagismo, setAnosTabagismo] = useState('');
    const [resultado, setResultado] = useState('');
    const [mensagem, setMensagem] = useState('Por favor, preencha os campos.');

    const calcularCargaTabagica = () => {
        if (maçosPorDia > 0 && anosTabagismo > 0) {
            const cargaTabagica = maçosPorDia * anosTabagismo;
            const cigarrosPorDia = maçosPorDia * 20;
            const cigarrosPorMes = cigarrosPorDia * 30;
            const cigarrosPorAno = cigarrosPorMes * 12;
            const totalCigarros = cigarrosPorDia * anosTabagismo * 365;

            setResultado(`
                Carga Tabágica: ${cargaTabagica} maço(s)-ano,
                Média de Cigarros por Mês: ${cigarrosPorMes},
                Média de Cigarros por Ano: ${cigarrosPorAno},
                Total de Cigarros Fumados: ${totalCigarros}
            `);
            setMensagem(''); // Limpa a mensagem quando há um resultado válido
        } else {
            setResultado('Preencha todos os campos com valores válidos.');
            setMensagem('Por favor, preencha os campos.'); // Exibe esta mensagem quando os inputs são inválidos
        }
    };

    return (
        <div>
                                                      <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>

            <h1>Calculadora de Carga Tabágica</h1>
            <div>
                <label>Quantidade de maços por dia: </label>
                <input
                    type="number"
                    min="0"
                    step="0.1"
                    value={maçosPorDia}
                    onChange={e => setMaçosPorDia(e.target.value)}
                />
            </div>
            <div>
                <label>Anos de tabagismo: </label>
                <input
                    type="number"
                    min="0"
                    step="1"
                    value={anosTabagismo}
                    onChange={e => setAnosTabagismo(e.target.value)}
                />
            </div>
            <div>
                <button onClick={calcularCargaTabagica}>Calcular</button>
            </div>
            <div className="result-container">
                {resultado}
                {mensagem && <div>{mensagem}</div>}
            </div>
        </div>
    );
}

export default CalculadoraCargaTabagica;
