

import { merge } from 'd3'
import { db } from './../firebase/firebase-setup'
import firebase from 'firebase/compat/app';
import OslerData, { KEYS } from './OslerData';


class MistakesJournal {
    construct(user) {
        this.userID = user.id

        
        this.mistakesDocRef = db.doc(`/users/${this.userID}/Residencia/mistakes_journal`)

        this.mistakesJournal = OslerData.data[KEYS.RESIDENCIA][KEYS.MISTAKES_JOURNAL]


        this.loaded = true
    }


    addAsReview(testID) {
        if (!this.mistakesJournal['needsReview'].includes(testID)) {
            this.mistakesDocRef.set({
                'needsReview' : firebase.firestore.FieldValue.arrayUnion(testID)
            }, {merge : true})

            this.mistakesJournal['needsReview'].push(testID)
        }
    }


    changeToReviewed(testID) {
        console.log('o que temos aqui')

        console.log(        console.log(this.mistakesJournal))

        if (this.mistakesJournal['needsReview'].includes(testID)) {
            this.mistakesDocRef.set({
                'needsReview' : firebase.firestore.FieldValue.arrayRemove(testID),
                'reviewedTests' : firebase.firestore.FieldValue.arrayUnion(testID)
            }, {merge : true})

            this.mistakesJournal['needsReview'].splice(this.mistakesJournal['needsReview'].indexOf(testID), 1)
            this.mistakesJournal['reviewedTests'].push(testID)
        }
    }


    getClassifiedData() {
        const dict = this.mistakesJournal
        const pending = dict['needsReview'] ?? []
        const now = Date.now()
    
        const pendingReviews = []
        const futureReviews = []
    
        pending.forEach(testID => {
            const reviewDate = OslerData.data[KEYS.RESIDENCIA][KEYS.REVIEWS_INDEXED][testID];
    
            if (reviewDate) {
                if (reviewDate <= now) {
                    pendingReviews.push(testID)
                } else {
                    futureReviews.push(testID)
                }
            }
        })
    
        const solved = dict['reviewedTests'] ?? []

        const all = [].concat(solved).concat(pendingReviews).concat(futureReviews)
    
        return {
            'pendingReviews' : pendingReviews,
            'futureReviews' : futureReviews,
            'solved' : solved,
            'all' : all
        }
    }

}


export default new MistakesJournal()