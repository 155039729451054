import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraNitroglicerina() {
    const [opcao, setOpcao] = useState('');
    const [dose, setDose] = useState('');
    const [infusao, setInfusao] = useState('');
    const [diluicao, setDiluicao] = useState(250); // Diluição padrão em mL de SF ou SG 5%
    const [ampolas, setAmpolas] = useState(1); // Ampola padrão
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularNitroglicerina = () => {
        const doseNum = parseFloat(dose);
        const infusaoNum = parseFloat(infusao);
        const diluicaoNum = parseFloat(diluicao);
        const ampolasNum = parseFloat(ampolas);

        const var0 = ampolasNum * 10; // Ampolas em mL
        const var1 = ampolasNum * 50; // Ampolas em mg
        const var2 = var1 * 1000; // Volume total da solução em mcg
        const var3 = parseFloat(diluicaoNum) + parseFloat(var0); // Volume total da solução em mL

        if (isNaN(diluicaoNum) || isNaN(ampolasNum) || (!dose && !infusao)) {
            setResultado('Preencha todos os campos necessários para ver o resultado');
            return;
        }

        if (!isNaN(doseNum) && isNaN(infusaoNum)) {
            const result1 = (doseNum * 60) * (var3 / var2); // Volume de infusão ml/h
            setResultado(`Volume de infusão: <strong>${result1.toFixed(1)}</strong> mL/h`);
        } else if (isNaN(doseNum) && !isNaN(infusaoNum)) {
            const result2 = (var2 * infusaoNum) / var3 / 60; // Dose em mcg/min conhecendo volume de infusão
            setResultado(`Dose: <strong>${result2.toFixed(3)}</strong> mcg/min`);
        } else {
            setResultado('Preencha apenas um dos campos para ver o resultado');
        }
    };

    useEffect(() => {
        if (diluicao && ampolas && ((dose && !infusao) || (!dose && infusao))) {
            calcularNitroglicerina();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [dose, infusao, diluicao, ampolas]);

    const handleOpcaoChange = (e) => {
        setOpcao(e.target.value);
        setDose('');
        setInfusao('');
        setResultado('Preencha os campos para ver o resultado');
    };

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Infusão de Nitroglicerina</h1>
            <div>
                <label>Selecione uma opção:</label>
                <select value={opcao} onChange={handleOpcaoChange}>
                    <option value="">Selecione</option>
                    <option value="dose">Dose em mcg/min</option>
                    <option value="infusao">Volume de infusão em mL/h</option>
                </select>
            </div>
            <div>
                <label>Diluição em soro glicosado (mL):</label>
                <input type="number" value={diluicao} onChange={e => setDiluicao(e.target.value)} />
            </div>
            <div>
                <label>Ampolas usadas:</label>
                <input type="number" value={ampolas} onChange={e => setAmpolas(e.target.value)} />
            </div>
            {opcao === 'dose' && (
                <div>
                    <label>Dose em mcg/min:</label>
                    <input type="number" value={dose} onChange={e => setDose(e.target.value)} />
                </div>
            )}
            {opcao === 'infusao' && (
                <div>
                    <label>Volume de infusão em mL/h:</label>
                    <input type="number" value={infusao} onChange={e => setInfusao(e.target.value)} />
                </div>
            )}
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
            
        </div>
    );
}

export default CalculadoraNitroglicerina;
