import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const NextMeeting = styled.div`
    border-radius: 1em;
    padding: 2em;
    margin-top: 1em;

    ${props => props.theme.darkMode ? `
        background-color: #375278;
    `: `
        background-color: #f5f9ff;
    `}
`


const Book = styled.span`
    font-weight: bold;
    font-style: italic;
`

const PreviousBooks = styled.div`
    border-radius: 1em;
    padding: 2em;
    margin-top: 2em;
    margin-bottom: 1em;

    ${props => props.theme.darkMode ? `
        background-color: #696969;
    `: `
        background-color: #f7f7f7;
    `}
`

export default function BookClub() {
    return (
        <>
            <NextMeeting>
                <ul>
                    <li><b>Próxima reunião:</b> (outubro, data a ser decidida no grupo).</li>
                    {/* <li><b>Próxima reunião:</b> 28/05 às 19:30h.</li> */}
                    <li><b>Livro:</b> Admirável Mundo Novo. (Aldoux Huxley)</li>
                    <li><b>Grupo do WhatsApp:</b> <Link to="https://chat.whatsapp.com/EDtz4QvQugdASVMd37wRzm" target="_blank" rel="noopener noreferrer">clique aqui</Link></li>
                </ul>
            </NextMeeting>

            <PreviousBooks>
                <p>Livros lidos até agora:</p>
                <ul>
                    <li><strong>Novembro:</strong> <Book>A Morte de Ivan Ilitch</Book> (Liev Tolstói)</li>
                    <li><strong>Dezembro:</strong> <Book>O Velho e o Mar</Book> (Ernest Hemingway)</li>
                    <li><strong>Janeiro:</strong> <Book>Olhai os Lírios do Campo</Book> (Érico Veríssimo)</li>
                    <li><strong>Fevereiro:</strong> <Book>A Hora da Estrela</Book> (Clarice Lispector)</li>
                    <li><strong>Março:</strong> <Book>Em busca de sentido</Book> (Viktor Frankl)</li>
                    <li><strong>Abril:</strong> <Book>Anotações de um jovem médico e outras narrativas</Book> (Mikhail Bulgákov)</li>
                    <li><strong>Maio:</strong> <Book>Mortais</Book> (Atul Gawande)</li>
                    <li><strong>Junho:</strong> <Book>O Alienista</Book> (Machado de Assis)</li>
                    <li><strong>Julho:</strong> <Book>Memórias do Subsolo</Book> (Fiódor Dostoiévski)</li>
                    <li><strong>Agosto & Setembro:</strong> <Book>Antifrágil</Book> (Nassim Taleb)</li>
                    {/* <li><strong>Outubro:</strong> <Book>Admirável Mundo Novo.</Book> (Aldoux Huxley)</li> */}
                </ul>
            </PreviousBooks>
        </>
    )
}
