import React from 'react'
import styled from 'styled-components'
import CloseIcon from './../assets/x-icon.png'
import ForwardIcon from './../assets/tree/forward.png'

const StartSection = styled.div`
    
    width: 100%;
    background-color: white;
    border-radius: 1.4em;
    padding: 2em;
    transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        background: linear-gradient(145deg, rgb(46, 46, 46) 0%, rgb(51, 51, 51) 100%);
        border: 1px solid rgba(255, 255, 255, 0.1);
        box-shadow: 
            0 1px 2px rgba(0, 0, 0, 0.2),
            0 2px 4px rgba(0, 0, 0, 0.2),
            0 4px 8px rgba(0, 0, 0, 0.2),
            0 8px 16px rgba(0, 0, 0, 0.2);
    ` : `
        background-color: white;
        background: linear-gradient(145deg, #f8f9fa 0%, #ffffff 100%);
        border: 1px solid rgba(0, 0, 0, 0.04);
        box-shadow: 
            0 1px 2px rgba(0, 0, 0, 0.04),
            0 2px 4px rgba(0, 0, 0, 0.04),
            0 4px 8px rgba(0, 0, 0, 0.04),
            0 8px 16px rgba(0, 0, 0, 0.04);
    `}

    border: 1px solid rgba(0, 0, 0, 0.04);
    box-shadow: 
        0 1px 2px rgba(0, 0, 0, 0.04),
        0 2px 4px rgba(0, 0, 0, 0.04),
        0 4px 8px rgba(0, 0, 0, 0.04),
        0 8px 16px rgba(0, 0, 0, 0.04);

    &:hover {
        transform: translateY(-2px);
            ${props => props.theme.darkMode ? `
                box-shadow: 
                    0 2px 4px rgba(0, 0, 0, 0.3),
                    0 4px 8px rgba(0, 0, 0, 0.3),
                    0 8px 16px rgba(0, 0, 0, 0.3),
                    0 16px 32px rgba(0, 0, 0, 0.3);
            ` : `
                box-shadow: 
                    0 2px 4px rgba(0, 0, 0, 0.05),
                    0 4px 8px rgba(0, 0, 0, 0.05),
                    0 8px 16px rgba(0, 0, 0, 0.05),
                    0 16px 32px rgba(0, 0, 0, 0.05);
            `}
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1.6em;
        border-radius: 1.2em;

        &:hover {
            transform: translateY(-1px);
            ${props => props.theme.darkMode ? `
                box-shadow: 
                    0 2px 4px rgba(0, 0, 0, 0.25),
                    0 4px 8px rgba(0, 0, 0, 0.25),
                    0 8px 16px rgba(0, 0, 0, 0.25);
            ` : `
                box-shadow: 
                    0 2px 4px rgba(0, 0, 0, 0.04),
                    0 4px 8px rgba(0, 0, 0, 0.04),
                    0 8px 16px rgba(0, 0, 0, 0.04);
            `}
        }
    }

    @media (max-width: 768px) {
        padding: 1.25em;
        border-radius: 1em;
        
        &:hover {
            transform: none;
            box-shadow: ${props => props.theme.darkMode ? 
                '0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px rgba(0, 0, 0, 0.2)' :
                '0 1px 2px rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.04)'};
        }
    }
`

const StatsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    margin-bottom: ${props => props.noMargin ? '0' : '1.6em'};

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: 0.8em;
        margin-bottom: ${props => props.noMargin ? '0' : '1.4em'};
    }

    @media (max-width: 768px) {
        gap: 0.6em;
        margin-bottom: ${props => props.noMargin ? '0' : '1em'};
    }
`

const Pill = styled.div`
    display: flex;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    padding: 0.8em 1.2em;
    border-radius: 3em;
    border: 1px solid rgba(22, 114, 246, 0.15);
    box-shadow: 0 2px 4px rgba(22, 114, 246, 0.06);
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    
    ${props => props.theme.darkMode ? `
        background: rgba(61, 61, 61, 0.8);
        border: 1px solid rgba(96, 165, 250, 0.25);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        
        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
            border: 1px solid rgba(96, 165, 250, 0.35);
        }
    ` : `
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid rgba(22, 114, 246, 0.15);
        box-shadow: 0 2px 4px rgba(22, 114, 246, 0.06);
        
        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 4px 8px rgba(22, 114, 246, 0.08);
            border: 1px solid rgba(22, 114, 246, 0.25);
        }
    `}

    span {
        font-size: 0.95em;
        color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#1a1a1a'};
        font-weight: 450;
        letter-spacing: -0.01em;
    }

    strong {
        margin: 0 0.4em;
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
        font-weight: 600;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0.4em 0.7em;

        strong {
            margin: 0 0.3em;
        }
        
        span {
            font-size: 0.8em;
        }

        &:hover {
            transform: translateY(-1px);
            box-shadow: 0 3px 6px ${props => props.theme.darkMode ? 
                'rgba(0, 0, 0, 0.25)' : 
                'rgba(22, 114, 246, 0.07)'};
        }
    }

    @media (max-width: 768px) {
        padding: 0.5em 0.8em;
        
        span {
            font-size: 0.85em;
        }

        &:hover {
            transform: none;
            box-shadow: none;
        }
    }
`

const ClearButton = styled.button`
    background: none;
    border: none;
    padding: 0.2em;
    margin-left: 0.8em;
    opacity: 0.8;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 50%;
    filter: ${props => props.theme.darkMode ? 'invert(0.8) brightness(1.5)' : 'none'};

    &:hover {
        opacity: 1;
        background-color: ${props => props.theme.darkMode ? 
            'rgba(255, 255, 255, 0.1)' : 
            'rgba(0, 0, 0, 0.05)'};
    }

    img {
        width: 0.8em;
        height: 0.8em;
        display: block;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        margin-left: 0.6em;
        padding: 0.3em;

        img {
            width: 0.75em;
            height: 0.75em;
        }
    }

    @media (max-width: 768px) {
        margin-left: 0.4em;
        padding: 0.4em;
        
        img {
            width: 0.7em;
            height: 0.7em;
        }
    }
`

const StartButton = styled.button`
    width: 100%;
    background: linear-gradient(135deg, #0071e3 0%, #42a1ff 100%);
    color: white;
    border: none;
    border-radius: 1.2em;
    padding: 1.2em;
    font-size: 1em;
    font-weight: 500;
    letter-spacing: -0.01em;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.8em;
    opacity: ${props => props.disabled ? 0.5 : 1};
    
    &:hover {
        transform: ${props => props.disabled ? 'none' : 'translateY(-2px)'};
        box-shadow: ${props => props.disabled ? 'none' : '0 8px 16px rgba(0, 113, 227, 0.25)'};
    }

    &:active {
        transform: translateY(1px);
    }

    img {
        width: 1em;
        height: 1em;
        opacity: 0.9;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1.1em;
        border-radius: 1em;
        font-size: 0.95em;
        gap: 0.7em;

        &:hover {
            transform: ${props => props.disabled ? 'none' : 'translateY(-1px)'};
            box-shadow: ${props => props.disabled ? 'none' : '0 6px 12px rgba(0, 113, 227, 0.2)'};
        }

        img {
            width: 0.95em;
            height: 0.95em;
        }
    }

    @media (max-width: 768px) {
        padding: 1em;
        border-radius: 0.8em;
        font-size: 0.95em;
        gap: 0.6em;
        
        &:hover {
            transform: none;
            box-shadow: none;
        }
        
        img {
            width: 0.9em;
            height: 0.9em;
        }
    }
`

const EmptyMessage = styled.div`
    text-align: center;
    color: ${props => props.theme.darkMode ? 'rgb(160, 160, 160)' : '#64748b'};
    font-size: 0.95em;
    padding: 1.5em;
    line-height: 1.5;
    font-weight: 450;
    letter-spacing: -0.01em;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.9em;
        padding: 0em;
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
        padding: 1em;
    }
`

const QuestionsCount = styled.div`
    width: 100%;
    text-align: center;
    margin-bottom: 1.5em;
    padding: 1em;
    border-radius: 1em;

    ${props => props.theme.darkMode ? `
    background: linear-gradient(to right, 
        rgba(96, 165, 250, 0.15),
        rgba(96, 165, 250, 0.1)
        );
        ` : `
        background: linear-gradient(to right,
            rgba(22, 114, 246, 0.08),
            rgba(66, 161, 255, 0.08)
        );
    `}
    span {
        font-size: 1.8em;
        font-weight: 600;
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
        margin-right: 0.3em;
        font-feature-settings: "tnum";
        font-variant-numeric: tabular-nums;
    }

    label {
        color: ${props => props.theme.darkMode ? 'rgb(160, 160, 160)' : '#64748b'};
        font-size: 0.95em;
        font-weight: 450;
    }
    
    @media (max-width: 1200px) and (min-width: 769px) {
        margin-bottom: 1.3em;
        padding: 0.9em;
        border-radius: 0.9em;

        span {
            font-size: 1.4em;
        }

        label {
            font-size: 0.9em;
        }
    }

    @media (max-width: 768px) {
        margin-bottom: 1em;
        padding: 0.8em;
        border-radius: 0.8em;

        span {
            font-size: 1.2em;
        }

        label {
            font-size: 0.85em;
        }
    }

`

export default function StartBttnLuxury({
    institutionsCount,
    yearsCount,
    themesCount,
    questionsCount,
    onClearInstitutions,
    onClearYears,
    onClearThemes,
    onStart
}) {
    const hasSelections = institutionsCount > 0 || yearsCount > 0 || themesCount > 0
    const canStart = questionsCount > 0

    if (!hasSelections) {
        return (
            <StartSection>
                <EmptyMessage>
                    Selecione temas, instituições ou anos para começar
                </EmptyMessage>
            </StartSection>
        )
    }

    return (
        <StartSection>
            <StatsContainer noMargin={!canStart}>
                {institutionsCount > 0 && (
                    <Pill>
                        <span>
                            <strong>{institutionsCount}</strong>
                            {institutionsCount === 1 ? 'instituição' : 'instituições'}
                        </span>
                        <ClearButton onClick={onClearInstitutions}>
                            <img src={CloseIcon} alt="Limpar" />
                        </ClearButton>
                    </Pill>
                )}

                {yearsCount > 0 && (
                    <Pill>
                        <span>
                            <strong>{yearsCount}</strong>
                            {yearsCount === 1 ? 'ano' : 'anos'}
                        </span>
                        <ClearButton onClick={onClearYears}>
                            <img src={CloseIcon} alt="Limpar" />
                        </ClearButton>
                    </Pill>
                )}

                {themesCount > 0 && (
                    <Pill>
                        <span>
                            <strong>{themesCount}</strong>
                            {themesCount === 1 ? 'tema' : 'temas'}
                        </span>
                        <ClearButton onClick={onClearThemes}>
                            <img src={CloseIcon} alt="Limpar" />
                        </ClearButton>
                    </Pill>
                )}
            </StatsContainer>

            {canStart ? (
                <>
                    <QuestionsCount>
                        <span>{questionsCount}</span>
                        <label>questões disponíveis</label>
                    </QuestionsCount>
                    <StartButton onClick={onStart}>
                        Começar estudo
                        <img src={ForwardIcon} alt="Iniciar" />
                    </StartButton>
                </>
            ) : (
                <EmptyMessage>
                    Não encontramos questões com esses filtros
                </EmptyMessage>
            )}
        </StartSection>
    )
}