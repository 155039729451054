import React, { useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import session from './../controllers/Session'
import { db } from './../firebase/firebase-setup'
import firebase from 'firebase/compat/app';
import { useHotkeys } from 'react-hotkeys-hook';

import './FeedbackArea.css'
import TextBox from '../components/TextBox'
import FeedbackTypeButton from './FeedbackTypeButton'
import { tagSequenceToPath, tagSequenceToPathS, toastMsg } from '../utils/Utils'
import UserStatisticsManager from '../controllers/UserStatisticsManager';

import styled, { css } from 'styled-components';


// Eu acho que isso tá duplicado, porque temos algo muito parecido
// sob a text box, que foi definido lá, e repetido aqui.
const Button = styled.div`
    margin-left: 1em;
    border: none;
    padding: 1em 1.5em;
    border-radius: 0.5em;
    font-weight: bold;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    cursor: pointer;

    &:hover {
        box-shadow: none;
    }

    &:active {
        box-shadow: none;
    }

    ${props => props.bttnType === 'clean' && css`
        background-color: rgb(124, 124, 124);
        color: white;

        &:hover {
            background-color: rgba(124, 124, 124, 0.8);
        }
    `}

    ${props => props.theme.darkMode && css`
        background-color: ${props.bttnType === 'clean' ? 'rgb(124, 124, 124)' : 'white'};
        color: ${props.bttnType === 'clean' ? 'white' : 'black'};

        &:hover {
            background-color: ${props.bttnType === 'clean' ? 'rgba(124, 124, 124, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
        }
    `}

    ${props => props.additionalStyle && css`${props.additionalStyle}`}
`;


const AnswerScreenCustomMessagem = styled.p`
    margin-top: 3em;
    font-size: 1.5em;
    font-weight: bold;

    ${props => props.theme.darkMode ? `
        color: rgb(39, 121, 196);
    `: `
        color: black;
    `}

`



export default function FeedbackArea({test, testType, close}) {
    const user = useSelector(state => state.user.data)
    const [choosingErrorType, setChoosingErrorType] = useState(true)

    const errorType = useRef('')

    const [placeholder, setPlaceholder] = useState('')
    const [showFinishedMessage, setShowFinishedMessage] = useState(false)


    useHotkeys('escape', () => {
        if (choosingErrorType) {
            close()
        }
        else {
            setChoosingErrorType(true)
        }
    })


    function registerErrorType(type) {
        setChoosingErrorType(false)
        errorType.current = type

        switch(type) {
            case 'spelling':
                setPlaceholder('Especifique qual trecho está escrito errado, ou se há alguma imagem faltando.')
                break;

            case 'content':
                setPlaceholder('Há algum fato errado? Ou a argumentação não se sustenta? Indique qual referência está usando.')
                break;

            case 'improvement':
                setPlaceholder('Não há nenhum erro, mas poderia estar melhor. Como podemos melhorar? Seja concreto e dê exemplos.')
                break;

            case 'methodology':
                setPlaceholder('O que na pergunta não está claro ou direcionado? Há outra resposta possível?')
                break;
        }
    }

    async function saveFeedback(feedback) {
        if (errorType == '' || feedback == '') {
            toastMsg('Oops, houve um erro. Envie para o suporte.')
        }
        else {
            submitDislikedFeedback(user.id, user.email, feedback)
        }
    }


    async function submitDislikedFeedback(userID, userEmail, feedback) {
        const tag_path = tagSequenceToPath(test.tags)
        
        const currentDate = new Date();
    
    
        let db_path = `/feedback/${testType}/disliked/`
   
        const data = {
            'test_id': test.testID,
            'tag_path': tag_path,
            'date': currentDate.toLocaleString('pt-BR'),
            'date_ms': currentDate.getTime(),
            'user_id': userID,
            'user_email': userEmail,
            'solved': false,
            'type' : errorType.current,
            'user_feedback': feedback,
        }


        const docRef = await db.collection(`users/${user.id}/feedback/${testType}/disliked`).add(data)

        db.collection(db_path).add({
            ...data,
            'user_feedback_doc_id' : docRef.id}
        )

        db.doc(`/feedback/statistics/${testType}-disliked/tests`).set({
            [test.testID] : firebase.firestore.FieldValue.increment(1)
        }, {merge: true})
        

        db.doc(`/feedback/statistics/${testType}-disliked/tagpaths`).set({
            [tag_path] : firebase.firestore.FieldValue.increment(1)
            
        }, {merge: true})



        setShowFinishedMessage(true)
        close()

    }
    


    if (!test || !test.testID || !test.tags || !testType) {
        return (
            <div className = 'FeedbackArea-Container'>
                <p>Bug inesperado, falta dado do teste, envie print para o suporte.</p>
            </div>
        )
    }

    return (
        <div className = 'FeedbackArea-Container'>
            { choosingErrorType &&
                <>
                <div className = 'FeedbackArea-BttnsContainer'>
                <FeedbackTypeButton
                    icon = {'✍🏼'}
                    text = {'TYPO'}
                    subtitle = {'Erro de português ou imagem que não aparece; por lapso do autor, e não por ignorância. 😂'}
                    action = {() => registerErrorType('spelling')} />

                <FeedbackTypeButton
                    icon = {'📚'}
                    text = {'Conteúdo'}
                    subtitle = {'Fato médico incorreto ou desatualizado, ou argumentação que não se sustenta.'}
                    action = {() => registerErrorType('content')} />

                <FeedbackTypeButton
                    icon = {'🎨'}
                    text = {'Sugestão'}
                    subtitle = {'O conteúdo não tem erros, mas precisa de melhoria para ser excelente.'}
                    action = {() => registerErrorType('improvement')} />

                { testType === 'Flashcards' &&
                    <FeedbackTypeButton
                        icon = {'🧠'}
                        text = {'Metodologia'}
                        subtitle = {'Flashcards com pergunta/resposta mal elaboradas.'}
                        action = {() => registerErrorType('methodology')} />
                }
                </div>

                <Button
                    bttnType="clean"
                    onClick={close}
                    additionalStyle="margin-top: 2em;"
                >
                    Voltar
                </Button>

                </>
            }
            { !choosingErrorType && !showFinishedMessage && 
                <>
                    <TextBox
                        goBack = {() => setChoosingErrorType(true)}
                        submit = {saveFeedback}
                        placeholder = {placeholder}
                        />    
                                
                </>
            }
            { showFinishedMessage &&
                <AnswerScreenCustomMessagem>Seu feedback foi enviado! Obrigado. 🙏🏼</AnswerScreenCustomMessagem>
            }
        </div>
    )
}