import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraCentor() {
    const [idade, setIdade] = useState('');
    const [temperatura, setTemperatura] = useState('');
    const [tosse, setTosse] = useState('');
    const [linfonodomegalia, setLinfonodomegalia] = useState('');
    const [exsudato, setExsudato] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularEscoreCentor = () => {
        let pontos = 0;

        if (idade === '3 a 14 anos') {
            pontos += 1;
        } else if (idade === '45 anos ou mais') {
            pontos -= 1;
        }

        if (temperatura === 'Acima de 38ºC') {
            pontos += 1;
        }

        if (tosse === 'Ausente') {
            pontos += 1;
        }

        if (linfonodomegalia === 'Presente') {
            pontos += 1;
        }

        if (exsudato === 'Presente') {
            pontos += 1;
        }

        setResultado(`Pontuação de Escore de Centor Modificado: ${pontos}`);

        if (pontos <= 0) {
            setInterpretacao('1 a 2,5%. Sem necessidade de testagem ou antibioticoterapia');
        } else if (pontos === 1) {
            setInterpretacao('5 a 10%. Sem necessidade de testagem ou antibioticoterapia');
        } else if (pontos === 2) {
            setInterpretacao('11 a 17%. Considerar cultura de orofaringe/teste rápido estreptocócico e prescrever antibióticos apenas nos casos positivos');
        } else if (pontos === 3) {
            setInterpretacao('28 a 35%. Solicitar cultura de orofaringe/teste rápido estreptocócico e prescrever antibióticos apenas nos casos positivos');
        } else {
            setInterpretacao('51 a 53%. Tratar empiricamente com antibióticos e opcionalmente solicitar cultura de orofaringe/teste rápido estreptocócico');
        }
    };

    useEffect(() => {
        if (idade && temperatura && tosse && linfonodomegalia && exsudato) {
            calcularEscoreCentor();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [idade, temperatura, tosse, linfonodomegalia, exsudato]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escore de Centor Modificado</h1>
            <div className='form-gad7'>
            <div>
                <label>Idade (anos):</label>
                <select value={idade} onChange={e => setIdade(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="3 a 14 anos">3 a 14 anos</option>
                    <option value="15 a 44 anos">15 a 44 anos</option>
                    <option value="45 anos ou mais">45 anos ou mais</option>
                </select>
            </div>
            <div>
                <label>Temperatura (ºC):</label>
                <select value={temperatura} onChange={e => setTemperatura(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Até 38ºC">Até 38ºC</option>
                    <option value="Acima de 38ºC">Acima de 38ºC</option>
                </select>
            </div>
            <div>
                <label>Tosse:</label>
                <select value={tosse} onChange={e => setTosse(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Presente">Presente</option>
                    <option value="Ausente">Ausente</option>
                </select>
            </div>
            <div>
                <label>Linfonodomegalia cervical anterior dolorosa:</label>
                <select value={linfonodomegalia} onChange={e => setLinfonodomegalia(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Presente">Presente</option>
                    <option value="Ausente">Ausente</option>
                </select>
            </div>
            <div>
                <label>Exsudato ou edema tonsilar:</label>
                <select value={exsudato} onChange={e => setExsudato(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Presente">Presente</option>
                    <option value="Ausente">Ausente</option>
                </select>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraCentor;
