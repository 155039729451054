import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import tree from './Tree'
import { toastMsg } from '../utils/Utils';
import IsLoading from '../main/IsLoading'
import UserReviewsInfo from '../controllers/UserReviewsInfo'
import InstitutionsCard from './InstitutionsCard'
import YearsCard from './YearsCard'
import CustomFiltersCard from './CustomFiltersCard'
import SaveFilterDialog from './SaveFilterDialog'
import OslerData, { KEYS } from '../controllers/OslerData'
import styled, { css } from 'styled-components'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles.js'
import CreateSessionDialog from './CreateSessionDialog.js'
import { ColumnCSS, RowCSS } from '../components/BasicComponents.js'
import QuestionIcon from './../assets/question-new.png'
import GeneralDialog from '../tests/GeneralDialog.js'
import SearchBar from './SearchBar.js'
import OslerButton from '../components/OslerButton.js'
import CustomFiltersController from '../controllers/CustomFiltersController.js'
import CustomStudySideBar from './CustomStudySideBar.js'
import StartBttnLuxury from './StartBttnLuxury.js'
import SessionBuilder from '../controllers/SessionBuilder.js'
import HelpBttn from './HelpBttn.js'
import OslerCard from '../components/OslerCard.js'
import Session from '../controllers/Session.js'

export const TreeScreenContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${backgroundWhiteBlack}

    padding-top: 2em;

    background-color: pink;

    width: 90%;
    min-height: 100vh;


    @media (max-width: 1600px) {
        width: 80%;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 0;
        margin-bottom: 4em;
        margin-top: 20px;
    }
`


export const TreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 2em;

    width: 80%;
    padding: 1em;
    box-sizing: border-box;

    ${props => props.theme.darkMode ? `
        background-color: rgba(61, 61, 61, 1);
        box-shadow: none;
    `: `
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
    `}

    border-radius: 0.5em;

    @media (max-width: 1600px) {
        width: 95%;
        margin-top: 1em;
    }


    @media (max-width: 500px) {
        width: 100%;
        box-shadow: none;
        margin-top: 1em;
        padding: 0;
        align-items: center;
    ${backgroundWhiteBlack}   
    }
`

const ContentWrapper = styled.div`
    ${RowCSS}
    
    gap: 2em;
    width: 100%;
    min-height: 100vh; // Isso garante que temos espaço para scroll
    align-items: flex-start; // Importante! Mantém sidebar no topo

    @media (max-width: 1024px) {
        gap: 1em;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: 0.6em;
        padding: 0 0.5em;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 1em;
    }
`


const ContentArea = styled.div`
    ${ColumnCSS}
    width: 100%;
    min-height: 400px;
    opacity: 1;
    transition: opacity 0.3s ease;

    &.fade-enter {
        opacity: 0;
    }

    &.fade-enter-active {
        opacity: 1;
    }

    &.fade-exit {
        opacity: 1;
    }

    &.fade-exit-active {
        opacity: 0;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        min-height: 350px;
        gap: 0.4em;
        width: 100%;
    }

    @media (max-width: 768px) {
        min-height: 200px;
        gap: 0.3em;
    }
`

// Ordenado e revisto

const Container = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;
    min-height: 100vh;

    // Colocar em outro lugar
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;

    ${backgroundWhiteBlack}
    // background-color: yellow;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0em;
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin-bottom: 4em;
        margin-top: 20px;
    }

    @media (max-width: 500px) {
        width: 100%;
        padding: 0;
        margin-bottom: 1em;
        margin-top: 0.5em;
        min-height: fit-content;
    }
`

const TabContainer = styled.div`
    ${RowCSS}
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        '#d2d2d7'};
    margin-bottom: 2em;

    // background-color: pink;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0 1em;
        margin-bottom: 1.5em;
    }

    @media (max-width: 768px) {
        overflow-x: auto;
        padding: 0.3em;
        margin-bottom: 1em;
        -webkit-overflow-scrolling: touch;
        gap: 0.2em;
        justify-content: space-between;
    }
`

const Tab = styled.div`
    font-size: 1em;
    color: ${props => {
        if (props.theme.darkMode) {
            return props.active ? '#60a5fa' : 'rgb(220, 220, 220)';
        }
        return props.active ? '#06c' : '#1d1d1f';
    }};

    padding: 1em 1.5em 1em 0;
    cursor: pointer;

    // Animação da linha embaixo do texto
    position: relative;
    transition: color 0.3s ease;
    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: calc(100% - 1.5em);
        // width: 100%;
        height: 2px;
        background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
        transform: scaleX(${props => props.active ? 1 : 0});
        transition: transform 0.3s ease;
    }

    &:hover {
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.9em;
        padding: 0.9em 1.2em 0.9em 0;
    }

    @media (max-width: 768px) {
        width: fit-content;
        margin-top: 0.3em;
        padding: 0.5em;
        font-size: 0.75em;
        text-align: center;

        &::after {
            width: 100%;
        }
    }

    @media (max-width: 500px) {
        width: fit-content;
        font-size: 0.75em;
        box-shadow: none;
        margin-top: 0.3em;
        padding: 0;
        align-items: center;
        text-align: center;
        ${backgroundWhiteBlack}   
    }
`


const Empty = styled.div`
    ${ColumnCSS}
    align-self: center;
    padding: 3em;
    background-color: ${props => props.theme.darkMode ? 
        'rgb(51, 51, 51)' : 
        '#f5f5f7'};
    border-radius: 1em;
    min-height: 16em;

    margin-top: 2em;
    width: 75%;
    align-self: center;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 80%;
        padding: 2.5em;
        min-height: 14em;
        margin-top: 1.5em;
    }

    @media (max-width: 768px) {
        width: 90%;
        padding: 2em;
        min-height: 12em;
        margin-top: 1em;
    }

    @media (max-width: 500px) {
        width: 95%;
        padding: 1.5em;
        min-height: 10em;
    }
`

const Main = styled.div`
    font-size: 1.6em;
    font-weight: 600;
    color: ${props => props.theme.darkMode ? 
        'rgb(220, 220, 220)' : 
        '#1d1d1f'};
    margin-bottom: 0.8em;
    text-align: center;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 1.4em;
        margin-bottom: 0.7em;
    }

    @media (max-width: 768px) {
        font-size: 1.2em;
        margin-bottom: 0.6em;
    }
`

const Sub = styled.div`
    font-size: 1.1em;
    color: ${props => props.theme.darkMode ? 
        'rgb(160, 160, 160)' : 
        '#6e6e73'};
    line-height: 1.4;
    max-width: 30em;
    text-align: left;
    
    & + & {
        margin-top: 0.5em;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 1em;
        line-height: 1.35;
    }

    @media (max-width: 768px) {
        font-size: 0.9em;
        line-height: 1.3;
    }
`


export default function CustomStudy({ testType }) {
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkMode)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // Estados base que são necessários para ambos os tipos
    const [loadedInfo, setLoadedInfo] = useState(false)
    const [showCreationDialog, setShowCreationDialog] = useState(false)
    const [themeSearch, setThemeSearch] = useState('')
    const [treeJSX, setTreeJSX] = useState(false)
    const [studyMode, setStudyMode] = useState('Teste')
    const [selectedTagpaths, setSelectedTagpaths] = useState([])
    const [numAvailableTestsForSession, setNumAvailableTestsForSession] = useState(false)
    const [sessionConfig, setSessionConfig] = useState(false)
    const [showHelp, setShowHelp] = useState(false)

    // Estados específicos para Residencia
    const [selectedTab, setSelectedTab] = useState('Temas')
    const [institutionsSearch, setInstitutionsSearch] = useState('')
    const [chosenInstitutions, setChosenInstitutions] = useState([])
    const [chosenYears, setChosenYears] = useState([])
    const [appliedFilters, setAppliedFilters] = useState([])
    const [showSaveFilterDialog, setShowSaveFilterDialog] = useState(false)
    const [tabs, setTabs] = useState([])

    // Refs específicos para Residencia
    const institutionsTabData = useRef()
    const yearsTabData = useRef()
    const customFiltersData = useRef()



    useEffect(() => {
        async function load() {
            console.log('CustomStudy: mounting...')
            if (!UserReviewsInfo.isReady()) {
                console.log("CustomStudy(): exiting to /app because data not ready")
                navigate('/app')
                return
            }

            
            if (testType === 'Residencia') {
                loadResidenciaData()
                setTabs(['Temas', 'Instituições', 'Anos', 'Meus filtros'])
            }


            CustomFiltersController.start(user)
            tree.start(testType, onTagpathSelected, triggerTreeRender)
            setLoadedInfo(true)
        }


        function loadResidenciaData() {
            institutionsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.INSTITUTIONS]['institutions'].sort()
            yearsTabData.current = OslerData.data[KEYS.RESIDENCIA][KEYS.YEARS]['years'].sort()
            const filters = OslerData.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS]
            customFiltersData.current = filters && filters['filters'] ? filters['filters'] : []
        }
    
        load()
    }, [])


    function changeSelectedTab(newTab) {
        if (selectedTab != newTab) {
            setSelectedTab(newTab)
        }
    }

    
    function updateChosenInstitutions(newList) {
        setChosenInstitutions(newList)
        setSessionConfig(prev => ({
            ...prev,
            institutions: newList
        }))
    }

    function updateChosenYears(newList) {
        setChosenYears(newList)
        setSessionConfig(prev => ({
            ...prev,
            years: newList
        }))
    }


    function onConfigChange(newSidebarConfig) {
        // Atenção à nuance. Quem chama essa função é a <SideBar>, mas ela não sabe quais as instituições e anos
        // selecionados, pois isso é determinado pelas abas.
        //
        // Alémd isso, flashcards não tem esses dados.
        if (testType === 'Residencia') {
            setSessionConfig({
                ...newSidebarConfig,
                institutions: chosenInstitutions,
                years: chosenYears
            })
        } else {
            setSessionConfig(newSidebarConfig)
        }
    }


    function updateAppliedFilters(newFilter) {
        const isRemovingFilter = appliedFilters.some(f => f.filter_name === newFilter.filter_name)

        let newAppliedFilters
        let newInstitutions
        let newYears
    
        if (isRemovingFilter) {
            console.log(` * CustomStudy: desmarcando o filtro ${newFilter.filter_name}...`)
            
            newAppliedFilters = appliedFilters.filter(f => f.filter_name !== newFilter.filter_name)
            newInstitutions = chosenInstitutions.filter(inst => !newFilter.institutions.includes(inst))
            newYears = chosenYears.filter(year => !newFilter.years.includes(year))
            
        } else {
            console.log(` * CustomStudy: selecionando o filtro ${newFilter.filter_name}...`)
            
            newAppliedFilters = [...appliedFilters, newFilter]
            newInstitutions = [...new Set([...chosenInstitutions, ...newFilter.institutions])]
            newYears = [...new Set([...chosenYears, ...newFilter.years])]
        }

        console.log(newAppliedFilters)
        console.log(newInstitutions)
        console.log(newYears)
    
        setAppliedFilters(newAppliedFilters)
        updateChosenInstitutions(newInstitutions)
        updateChosenYears(newYears)
    }

    


    // function updateAppliedFilters(newFilter) {
    //     if (appliedFilters.some(f => f.filter_name === newFilter.filter_name)) {
    //         // É um filtro que está sendo desmarcado
    //         console.log(` * CustomStudy: desmarcando o filtro ${newFilter.filter_name}...`)

    //         setAppliedFilters(prev => prev.filter(f => f.filter_name !== newFilter.filter_name))


    //         const newInstitutions = chosenInstitutions.filter(inst => !newFilter.institutions.includes(inst))


    //         updateChosenInstitutions(chosenInstitutions => chosenInstitutions.filter(inst => !newFilter.institutions.includes(inst)))
            
    //         updateChosenYears(prev => prev.filter(year => !newFilter.years.includes(year)))
    //     } else {
    //         // É um filtro que está sendo selecionado
    //         console.log(` * CustomStudy: selecionando o filtro ${newFilter.filter_name}...`)
    //         setAppliedFilters(prev => [...prev, newFilter])

    //         updateChosenInstitutions(prev => [...prev, ...newFilter.institutions])
    //         updateChosenYears(prev => [...prev, ...newFilter.years])

    //     }
    // }


    function isSelectionNewFilter() {
        // Se não há instituições ou anos selecionados, não é um novo filtro
        if (chosenInstitutions.length === 0 && chosenYears.length === 0) {
            return false
        }
    
        // Verifica se algum filtro aplicado abrange completamente a seleção atual
        const filters = CustomFiltersController.getFilters()
        for (let filter of filters) {
            const institutionsMatch = chosenInstitutions.every(inst => filter.institutions.includes(inst))
            const yearsMatch = chosenYears.every(year => filter.years.includes(year))
    
            if (institutionsMatch && yearsMatch) {
                return false // A seleção está completamente abrangida por um filtro existente
            }
        }
    
        // Se chegou até aqui, a seleção representa um novo filtro
        return true
    }

    function onTagpathSelected() {
        /*
            É tentador iniciar a sessão com
                paths = tree.extractCheckedLeafNodes(),
            mas isso ignora a possibilidade -- e a realidade! -- de questões que
            não estão classificadas em uma "leaf tag", mas sim em uma tag intermediária.
            
            Por outro lado, não precisamos temer sobreposição de tags, porque SessionBuilder
            (provavelmente?) elimina testIDs repetidos.
        */
        const paths = tree.extractCheckedNodes()
        setSelectedTagpaths(paths)
    }


    useEffect(() => {
        if (sessionConfig && selectedTagpaths) {
            const tests = SessionBuilder.simulate(testType, 'custom', selectedTagpaths, sessionConfig, false)
            setNumAvailableTestsForSession(tests.length)
        }
    }, [selectedTagpaths, sessionConfig])


    async function saveFilter(filterName) {
        dispatch(setIsLoading(true))

        const newFilter = {
            'filter_name': filterName,
            'institutions': [...chosenInstitutions],
            'years': [...chosenYears]
        }

        const newFilters = await CustomFiltersController.saveFilter(newFilter)
        dispatch(setIsLoading(false))

        if (newFilters) {
            toastMsg(`Seu filtro "${newFilter.filter_name}" foi salvo.\nPara acessá-lo, clique na aba \"Meus filtros\".`)
        } else {
            toastMsg('Oops! Não conseguimos salvar seu filtro. Busque ajuda na DM. 🤔')
        }

        setShowSaveFilterDialog(false)
        setAppliedFilters(prev => [...prev, newFilter])
    }



    function clearSelectedTagpaths() {
        tree.clearSelectedNodes()
        setSelectedTagpaths([])
    }


 

    // ISSO TUDO PRECISA SER GENERALIZADO, EM NOME DE JESUS, POR FAVOR
    function startFlashcards() {
        dispatch(setIsLoading(true))
        const saveAsLastSession = (studyMode == 'test-mode')

        SessionBuilder.start(
            'Flashcards',
            'custom',
            studyMode,
            selectedTagpaths,
            sessionConfig,
            saveAsLastSession,
        )

        prepareSession(studyMode)
    }


    function prepareSession(mode, customList = false) {
        if (Session.sessionSize > 0) {
            if (customList) {
                Session.addList(customList)
            }
            Session.addTemporaryListener(mode === 'consult-mode' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }




    useEffect(() => {
        const nodesBackup = tree.extractNodeBackup()
        tree.update(studyMode, sessionConfig)

        const somethingChanged = tree.loadTreeFromPreviousNodes(nodesBackup)

        if (somethingChanged) {
            toastMsg('⚠️ Retiramos alguns temas porque não há questões para esse filtro!')
        }

        // Pode ter ocorrido alteração no checkedNodes, então precisamos
        // rever as tagpaths
        const paths = tree.extractCheckedNodes()
        setSelectedTagpaths(paths)

        
        triggerTreeRender()
    }, [testType, sessionConfig, studyMode, themeSearch])



    function triggerTreeRender() {
        console.log('Houve mudança em algum nó! -- Nova renderização!')
        const JSX = tree.createTreeJSX(themeSearch)
        const anyNodeVisible = tree.anyNodeVisible()

        if (anyNodeVisible) {
            setTreeJSX(JSX)
        } else {
            setTreeJSX(
                <Empty>
                    <Main>Não localizamos esse tema.</Main>
                    <Sub>Verifique a grafia e considere a possibilidade dele estar em outros decks e/ou com outro nome.</Sub>

                    <Sub>Se você acha que é algo específico que está faltando, peça por DM.</Sub>
                </Empty>
            )
        }

    }



    return (
        <Container>
            {!loadedInfo &&
                <IsLoading />
            }
{loadedInfo && (
                <>
                    <GeneralDialog
                        open={showHelp}
                        icon = {QuestionIcon}
                        title={'Ajuda'}
                        bigWidth={false}
                        onClose={() => setShowHelp(false)}
                    >

                        { testType === 'Residencia' ? (
                            <>
                            <p><b>Escolha os temas.</b> A árvore de tags permite acesso e seleção rápida aos temas disponíveis. Se precisar, há uma barra de busca no topo.</p>

                            <p><b>Filte por instituições e anos.</b> <u>Opcionalmente</u>, você pode buscar pela instituições do seu interesse e optar por questões de provas mais ou menos antigas.</p>
                            
                            <p><i>Perceba que isso dinamicamente altera dinamicamente os temas e a quantidade de questões disponíveis. Por exemplo, pode ser que, selecionando "USP" e "2015" realmente não haja nenhuma questão de anemias microangiopáticas.</i></p>

                            <p><b>Usa o mesmo filtro com frequência?</b> É normal que você sempre foque nas mesmas instituições e determinados anos (os mais recentes, presume-se...). Você pode salvar esse filtro, clicando em "Salvar filtro" na direita, e carregá-lo mais rapidamente depois através da aba "Meus filtros".</p>
                            
                            <p><b>Configurações adicionais.</b> Na direita, você seleciona o modo de estudo e pode fazer ajustes adicionais quanto aos tipos de teste, especialmente no que tange a <i>status</i> de revisão, questões anuladas, etc. Novamente, veja que isso altera dinamicamente a árvore.</p>

                            <p><b>Inicie a sessão.</b> Escolhendo temas & filtros & ajustes de modo que haja ao menos uma questão, aparecerá um botão para você carregar os testes e/ou salvá-los em uma lista.</p>
                            </>
                        ) : (
                            <>
                            <p><b>Crie uma sessão de flashcards.</b> Você poderá respondê-las agora ou salvá-las em uma lista, para mais tarde.</p>
                            </>
                        )}
                    </GeneralDialog>


                    <SaveFilterDialog
                        showDialog={showSaveFilterDialog}
                        setShowDialog={setShowSaveFilterDialog}
                        saveFilter={saveFilter} />

                    
                    <CreateSessionDialog
                        showDialog={showCreationDialog}
                        setShowDialog={setShowCreationDialog}
                        mode={studyMode}
                        paths={selectedTagpaths}
                        sessionConfig = {sessionConfig} />


{testType === 'Residencia' ? (
                        // Layout para Residencia
                        <>
                            {/* Save Filter Dialog específico para Residencia */}
                            <SaveFilterDialog
                                showDialog={showSaveFilterDialog}
                                setShowDialog={setShowSaveFilterDialog}
                                saveFilter={saveFilter} />

                            {/* Tabs específicas para Residencia */}
                            <TabContainer>
                                {tabs.map(tab => (
                                    <Tab
                                        key={tab}
                                        active={selectedTab === tab}
                                        onClick={() => changeSelectedTab(tab)}
                                    >
                                        {tab}
                                    </Tab>
                                ))}
                                <HelpBttn onClick={() => setShowHelp(true)} />
                            </TabContainer>

                            <ContentWrapper>
                                <OslerCard>
                                    {/* SearchBar condicional */}
                                    <SearchBar
                                        currentString={selectedTab === 'Temas' ? themeSearch : institutionsSearch}
                                        setSearch={selectedTab === 'Temas' ? setThemeSearch : setInstitutionsSearch}
                                        visible={selectedTab === 'Temas' || selectedTab === 'Instituições'}
                                        placeholder={selectedTab == 'Temas' ? 'Busque um tema...' : 'Busque uma instituição...'}
                                        style={{ marginBottom: '1em' }} />

                                    <ContentArea>
                                        {selectedTab === 'Temas' && treeJSX}
                                        {selectedTab === 'Instituições' && (
                                            <InstitutionsCard
                                                searchedString={institutionsSearch}
                                                updateChosenInstitutions={updateChosenInstitutions}
                                                previouslyChecked={chosenInstitutions} />
                                        )}
                                        {selectedTab === 'Anos' && (
                                            <YearsCard
                                                updateChosenYears={updateChosenYears}
                                                previouslyChecked={chosenYears} />
                                        )}
                                        {selectedTab === 'Meus filtros' && (
                                            <CustomFiltersCard
                                                appliedFilters={appliedFilters}
                                                updateAppliedFilters={updateAppliedFilters} />
                                        )}
                                    </ContentArea>
                                </OslerCard>

                                <CustomStudySideBar
                                    testType={testType}
                                    onConfigChange={onConfigChange}
                                    onModeChange={setStudyMode}
                                    bttns={
                                        <>
                                            {isSelectionNewFilter() && (
                                                <OslerButton
                                                    text='Salvar filtro'
                                                    color='gray'
                                                    width='100%'
                                                    onClick={() => setShowSaveFilterDialog(true)} />
                                            )}
                                            <StartBttnLuxury
                                                institutionsCount={chosenInstitutions.length}
                                                yearsCount={chosenYears.length}
                                                themesCount={selectedTagpaths.length}
                                                questionsCount={numAvailableTestsForSession}
                                                onClearInstitutions={() => updateChosenInstitutions([])}
                                                onClearYears={() => updateChosenYears([])}
                                                onClearThemes={() => clearSelectedTagpaths()}
                                                onStart={() => setShowCreationDialog(true)} />
                                        </>
                                    } />
                            </ContentWrapper>
                        </>
                    ) : (
                        // Layout simplificado para Flashcards
                        <ContentWrapper>
                            <OslerCard width = {'75%'}>
                                <SearchBar
                                    currentString={themeSearch}
                                    setSearch={setThemeSearch}
                                    visible={true}
                                    placeholder='Busque um tema...'
                                    style={{ marginBottom: '1em' }} />

                                <ContentArea>
                                    {treeJSX}
                                </ContentArea>
                            </OslerCard>

                            <CustomStudySideBar
                                testType={testType}
                                onConfigChange={onConfigChange}
                                onModeChange={setStudyMode}
                                bttns={
                                    <StartBttnLuxury
                                        themesCount={selectedTagpaths.length}
                                        questionsCount={numAvailableTestsForSession}
                                        onClearThemes={() => clearSelectedTagpaths()}
                                        onStart={() => startFlashcards()} />
                                } />
                        </ContentWrapper>
                    )}

                </>
            )}




        </Container>
    )
}
