import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, ArrowLeft } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import session from '../controllers/Session';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import Stopwatch from './Stopwatch';
import { RowCSS } from '../components/BasicComponents';

const NavContainer = styled.div`
    ${RowCSS}
    width: 100%;
    height: 48px;

    justify-content: space-between;
    padding: 0;


    z-index: 100;

    // position: sticky;
    // background: ${props => props.theme.darkMode ? '#1a1a1a' : '#ffffff'};
    // border-bottom: 1px solid ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.06)'};

    @media (max-width: 500px) {
        padding: 0 16px;
        height: 40px;
    }
`;

const IconButton = styled(motion.button)`
    background: none;
    border: none;
    padding: 8px;
    border-radius: 6px;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
    }
`;

const Navigation = styled(motion.div)`
    
    display: flex;
    align-items: center;
    gap: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
`;

const PageCounter = styled(motion.span)`
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'};
    min-width: 60px;
    text-align: center;
`;

const NavigationDot = styled(motion.button)`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.5)'};
    border: none;
    padding: 0;
    cursor: pointer;
    
    &:hover {
        background: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)'};
    }
`;

export default function TestScreenNavBar(props) {
    const navigate = useNavigate();
    const darkMode = useSelector(state => state.theme.darkMode);
    
    const [numberTests, setNumberTests] = useState(false);
    const [currentTest, setCurrentTest] = useState(false);
    const [allowGoBack, setAllowGoBack] = useState(false);
    const [showNavigation, setShowNavigation] = useState(true);
    const [canNavigateBack, setCanNavigateBack] = useState(false);
    const [canNavigateForward, setCanNavigateForward] = useState(false);

    useEffect(() => {
        if (session) {
            session.addPermanentListener(updateScreen);
        }
        updateScreen();
    }, []);

    useEffect(() => {
        setAllowGoBack(props.allowGoBack);
    }, [props.allowGoBack]);

    function updateScreen() {
        if (session) {
            setNumberTests(session.getNumberOfTests())
            setCurrentTest(session.getCurrentIndex() + 1)
            setCanNavigateBack(session.canMoveToPrevious())
            setCanNavigateForward(session.canMovetoNext())
        }
    }

    async function goBack() {
        if (props.onGoBackAction) {
            await props.onGoBackAction();
        }
    }

    async function handlePrevious() {
        if (canNavigateBack) {
            await session.moveToPrevious();
        }
    }

    async function handleNext() {
        if (canNavigateForward) {
            await session.moveToNext();
        }
    }

    const buttonVariants = {
        tap: { scale: 0.95 },
        hover: { 
            scale: 1.05,
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 17
            }
        }
    };

    const counterVariants = {
        hidden: { opacity: 0, y: -10 },
        visible: { 
            opacity: 1, 
            y: 0,
            transition: {
                type: "spring",
                stiffness: 500,
                damping: 25
            }
        },
        exit: { 
            opacity: 0,
            y: 10,
            transition: {
                duration: 0.2
            }
        }
    };

    return (
        <NavContainer>
            <IconButton
                onClick={goBack}
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
                style={{ visibility: allowGoBack ? 'visible' : 'hidden' }}
            >
                <ArrowLeft size={18} />
            </IconButton>

            <Navigation>
                <IconButton
                    onClick={handlePrevious}
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                    disabled={!canNavigateBack}
                >
                    <ChevronLeft size={18} />
                </IconButton>

                <AnimatePresence mode="wait">
                    {showNavigation ? (
                        <PageCounter
                            key="counter"
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                            variants={counterVariants}
                        >
                            {currentTest} / {numberTests}
                        </PageCounter>
                    ) : (
                        <NavigationDot
                            key="dot"
                            onClick={() => setShowNavigation(true)}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1 }}
                            exit={{ scale: 0 }}
                            whileHover={{ scale: 1.2 }}
                            whileTap={{ scale: 0.9 }}
                        />
                    )}
                </AnimatePresence>

                <IconButton
                    onClick={handleNext}
                    whileHover="hover"
                    whileTap="tap"
                    variants={buttonVariants}
                    disabled={!canNavigateForward}
                >
                    <ChevronRight size={18} />
                </IconButton>
            </Navigation>

            {/* <IconButton
                onClick={() => setShowNavigation(!showNavigation)}
                style={{ visibility: 'hidden' }}
            >
                <ChevronLeft size={18} />
            </IconButton> */}

            <Stopwatch />
        </NavContainer>
    );
}