import React from 'react'
import Header from './Header'
import Contact from './Contact'
import FAQ from './FAQ'
import './Homepage.scss'
import Landing from './Landing'
import SocialProof from './SocialProof'
import StudyMethod from './StudyMethod'
import ForWhom from './ForWhom'
import About from './About'
import Buy2 from './Buy2'
import WhyOsler from './WhyOsler'
import KnowMore from './KnowMore'
import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'

import BlackFridayOffer from './BlackFridayOffer'

const Container = styled.div`
    ${ColumnCSS}
    min-height: 100vh;
    width: 100vw !important;
    height: 100%;

`


const Black = styled.div`
    width: 100%;    
    min-height: 100vh;

    background-color: red;
`

export default function Homepage() {


    return (
        <Container>
            <Black>
                {/* <Buy2 /> */}

                <BlackFridayOffer />

            </Black>
        </Container>
    )


    return (
        <div className = "HomePageContainer">
            <Header />
            
            <Landing />

            <KnowMore />

            <SocialProof />

            <WhyOsler />

            <StudyMethod />

            {/* <ForWhom /> */}
            
            <About />
            
            <Buy2 />

            <FAQ />
            
            <Contact />

        </div>
    )
}