import React, {useState, useEffect, useRef, useMemo} from 'react'
import AppContainer from '../app-container/AppContainer'
import NotebookEditor from './NotebookEditor'
import ScreenContainer from '../components/ScreenContainer'
import styled from 'styled-components'
import NoteSelector from './NoteSelector'
import OslerButton from '../components/OslerButton'
import GeneralDialog from '../tests/GeneralDialog'
import NotebookController from '../controllers/NotebookController'
import { useNavigate } from 'react-router-dom'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import OslerCard from '../components/OslerCard'
import NoteIcon from './../assets/personal_note.png'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 90%;

    @media (max-width: 500px) {
        padding: 0.9em;
        width: 100%;
        height: 100%;
        min-height: 600px;
        margin-top: 0px;
        box-shadow: none;
        border-radius: 0px;
    }

    padding: 5em;

    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: transparent;
        box-shadow: none;
        color: white;
        border: none;
    ` : `
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
        color: black;
    `}
`



const Actions = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 2em;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;

    }
`


const BttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 500px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: flex-start;

    }
`



export default function Notebook() {
    const navigate = useNavigate()

    const [selectedNoteID, setSelectedNoteID] = useState(false)
    const [selectedNoteTitle, setSelectedNoteTitle] = useState(false)

    const notebook = useRef()
    const [showDeleteNoteDialog, setShowDeleteNoteDialog] = useState(false)

    const [nNotes, setNumberNotes] = useState(0)

    useEffect(() => {
        if (!NotebookController.loaded) {
            console.log('voltando...')
            navigate('/app')
            return
        }


        let c = 0;
        
        Object.entries(NotebookController.metadata).forEach(([theme, notesInTheme]) => {
            c += Object.keys(notesInTheme).length
        })

        setNumberNotes(c)

        // Colocamos selectedNoteID para forçar novo cálculo quando uma nota é criada
    }, [selectedNoteID])




    async function createNote() {
        notebook.current.save()
        const ID = await notebook.current.createNote()
        setSelectedNoteID(ID)
    }


    function deleteNote() {
        notebook.current.deleteNote()
        setSelectedNoteID(false)
        setSelectedNoteTitle(false)
        setShowDeleteNoteDialog(false)
    }


    if (!NotebookController.loaded) return null

    return (
        <AppContainer>
            <ScreenContainerHeader
                title = 'Caderno'
                icon = {NoteIcon} >


                <OslerCard>

                    <GeneralDialog
                        open = {showDeleteNoteDialog}
                        title = '💀 Deletar o resumo?'
                        onClose = {() => setShowDeleteNoteDialog(false)}
                        actions = {[
                            { label: 'Cancelar', onClick: () => setShowDeleteNoteDialog(false), style: 'neutral' },
                            { label: 'Deletar', onClick: deleteNote, style: 'destructive' }
                        ]} >

                        <p>Isso é — absolutamente, catastroficamente — irreversível.</p>
                        
                        <p>Prosseguir?</p>

                    </GeneralDialog>

                    { nNotes > 0 && 
                    <>
                        <Actions>
                            <NoteSelector
                                selectedNoteID = {selectedNoteID}
                                selectedNoteTitle = {selectedNoteTitle}
                                changeNote = {(newNoteID) => setSelectedNoteID(newNoteID)} />
    
                            { notebook.current && 
                                <BttnsContainer>                            
                                <>
                                    <OslerButton
                                        size = 'small'
                                        color = 'blue'
                                        text = 'Criar resumo'
                                        onClick = {createNote}
                                        style = {{marginRight: '1em'}}  />
    
                                    { selectedNoteID &&
                                        <OslerButton
                                            size = 'small'
                                            color = 'red'
                                            text = 'Deletar'
                                            onClick = {() => setShowDeleteNoteDialog(true)}
                                            style = {{marginRight: '1em'}} />
                                    }
                                
                                </>
                                </BttnsContainer>
                            }
                        </Actions>
    
                        { !selectedNoteID &&
                            <p>Selecione um resumo, ou crie um novo</p>
                        }                    
                    </>
                    }
                    { nNotes == 0 &&
                    <>
                        <p>Você ainda não tem nenhum resumo.</p>

                        <OslerButton
                            size = 'small'
                            color = 'blue'
                            text = 'Criar resumo'
                            onClick = {createNote}
                            style = {{marginRight: '1em'}}  />
                    </>
                    }


                    <NotebookEditor
                        ref = {notebook}
                        chosenNoteID = {selectedNoteID}
                        signalTitleChanged = {newTitle => setSelectedNoteTitle(newTitle)} />

                </OslerCard>
            </ScreenContainerHeader>
        </AppContainer>
    )
}