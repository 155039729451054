import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const esteroides = {
    'Betametasona (IV)': 0.75,
    'Cortisona (PO)': 25,
    'Dexametasona (IV ou PO)': 0.75,
    'Hidrocortisona (IV ou PO)': 20,
    'Metilprednisolona (IV ou PO)': 4,
    'Prednisolona (PO)': 5,
    'Prednisona (PO)': 5,
    'Triancinolona (IV)': 4
};

function CalculadoraConversaoEsteroides() {
    const [fromDrug, setFromDrug] = useState('');
    const [toDrug, setToDrug] = useState('');
    const [dosage, setDosage] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularConversao = () => {
        const fromDose = esteroides[fromDrug];
        const toDose = esteroides[toDrug];
        const dosageNum = parseFloat(dosage);

        if (!fromDrug || !toDrug || isNaN(dosageNum)) {
            setResultado('Preencha os campos em branco');
            return;
        }

        const convertedDosage = (dosageNum / fromDose) * toDose;
        setResultado(`Dose equivalente: <strong>${convertedDosage.toFixed(2)}</strong> mg`);
    };

    useEffect(() => {
        if (fromDrug && toDrug && dosage) {
            calcularConversao();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [fromDrug, toDrug, dosage]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Conversão de Esteroides</h1>
            <div>
                <label>Convertendo de:</label>
                <select value={fromDrug} onChange={e => setFromDrug(e.target.value)}>
                    <option value="">Selecione</option>
                    {Object.keys(esteroides).map((drug, index) => (
                        <option key={index} value={drug}>{drug}</option>
                    ))}
                </select>
            </div>
            <div>
                <label>Dosagem do fármaco (mg):</label>
                <input type="number" value={dosage} onChange={e => setDosage(e.target.value)} />
            </div>
            <div>
                <label>Convertendo para:</label>
                <select value={toDrug} onChange={e => setToDrug(e.target.value)}>
                    <option value="">Selecione</option>
                    {Object.keys(esteroides).map((drug, index) => (
                        <option key={index} value={drug}>{drug}</option>
                    ))}
                </select>
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraConversaoEsteroides;
