import React, { useState } from 'react';
import styled from 'styled-components';
import { darkTextPrimaryColor, darkTextSecondaryColor, textWhiteBlack} from '../tests/FlashcardsStyles';


const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    border-radius: 0.75em;

    padding: 0.8em;

    margin: 2em;
    width: 18em;

    ${props => props.theme.darkMode ? `
        background-color: rgba(81, 81, 81, 1);
    `:`
        border: #adadad 1px solid;
        background-color: #fafafa;
    `}

    @media (max-width: 900px) {
        width: 45%;
        margin: 2em 0 1.5em 0;      
    }

    @media (max-width: 500px) {
        width: 45%;
        margin: 1em 0 0 0;
    }
`


const Detail = styled.p`
    margin: 0;
    font-size: 1em;
    color: #404040
    font-weight: bold;

    ${textWhiteBlack}

    @media (max-width: 500px) {
        font-size: 0.8em;
    }
`

const Text = styled.p`
    margin: 0;
    font-size: 1.5em;
    font-weight: bold;

    
    ${props => props.theme.darkMode ? `
        color: ${darkTextPrimaryColor};
    `:`
        color: black;
    `}

    @media (max-width: 500px) {
        font-size: 1.2em;
    }
`

const Unit = styled.span`
    font-size: 0.9em;
    margin-left: 0.2em;
    font-weight: bold;

    ${props => props.theme.darkMode ? `
        color: ${darkTextSecondaryColor};
    `:`
        color: #3b3b3b;
    `}


    @media (max-width: 500px) {
        font-size: 0.7em;
    }
`



export default function StatisticsCard({ detail, text, unit }) {
    return (
        <Card>
            <Detail>{detail}</Detail>
            <Text>{text}<Unit>{unit}</Unit></Text>
        </Card>
    )
}