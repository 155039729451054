import React, { useEffect, useState } from 'react'
import OslerButton from '../components/OslerButton'
import GeneralDialog from '../tests/GeneralDialog'
import AmbassadorMonthly from './AmbassadorMonthly'
import styled from 'styled-components'
import { OslerInput } from '../tests/FlashcardsStyles'
import { checkCouponForError } from '../utils/Utils'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'
import AmbassadorAdminUploadInvoice from './AmbassadorAdminUploadInvoice'
import AmbassadorAdminRemoveInvoice from './AmbassadorAdminRemoveInvoice'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(231, 231, 231);
    padding: 3em;

    width: 100%;
    flex-grow: 1;
`


const AmbassadorAdminBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: flex-end;
    width: 100%;
    margin-top: 2em;
    margin-right: 2em;
`

const buttonStyle = {
    width: '10em',
    marginTop: '2em',
    marginBottom: '4em',
    alignSelf: 'flex-end',
};


export default function AmbassadorAdminView(props) {
    const user = useSelector(state => state.user.data)

    const dispatch = useDispatch()
    const [showTerminateDialog, setShowTerminateDialog] = useState(false)
    const [showChangeCouponDialog, setShowChangeCouponDialog] = useState(false)
    const [showRestituteDialog, setShowRestituteDialog] = useState(false)

    const [coupon, setCoupon] = useState('')
    const [couponError, setCouponError] = useState(<></>)
    
    const [ambassador, setAmbassadorData] = useState(false)


    const [selectedMonth, setSelectedMonth] = useState(false)
    const [selectedMonthData, setSelectedMonthData] = useState(false)


    const [trigger, setTrigger] = useState(1)

    useEffect(() => {
        console.log('ADMIN VIEW')
        console.log(props.ambassador)
        setAmbassadorData(props.ambassador)
    }, [props.ambassador])


    async function changeCoupon(input) {
        dispatch(setIsLoading(true))

        const coupon = input.trim().toUpperCase()

        const couponError = await checkCouponForError(coupon)

        if (couponError) {
            setCouponError(couponError)
        }
        else {
            await props.changeAmbassadorCoupon(coupon)

            setCoupon('')
            setCouponError(<></>)
            setShowChangeCouponDialog(false)    
        }

        dispatch(setIsLoading(false))
    }


    async function removeAmbassador(reason) {
        await props.terminateAmbassador(reason)
        setShowTerminateDialog(false)
    }

    async function restituteAmbassador() {
        await props.restituteAmbassador()
        setShowRestituteDialog(false)
    }


    function registerSelectedAmbassadorMonth(month, monthData) {
        console.log('Mudando o mês para...')
        console.log(month)
        console.log(monthData)
        setSelectedMonth(month)
        setSelectedMonthData({...monthData})
    }



    async function uploadInvoice(file) {
        props.uploadInvoice(file, ambassador['uid'], selectedMonth)
        setTrigger(trigger + 1)
    }


    async function removeInvoice() {
        props.removeInvoice(ambassador['uid'])
        setTrigger(trigger + 1)
    }


    return (
        <>
        { ambassador && 
            <Container>

                <GeneralDialog
                    open={showTerminateDialog}
                    title="🚨 Remover do programa? 🚨"
                    onClose={() => setShowTerminateDialog(false)}
                    actions={[
                        { label: 'Voltar', onClick: () => setShowTerminateDialog(false), style: 'neutral'},
                        { label: 'Embaixador pediu para sair ⚠️', onClick: () => removeAmbassador('ambassador_asked'), style: 'destructive' },
                        { label: 'Decisão da Osler 💀', onClick: () => removeAmbassador('osler_decision'), style: 'destructive' },
                    ]}
                >
                    
                    <p>É necessário informar a razão: se foi uma iniciativa do embaixador, ou se é uma decisão nossa (e.g., por má performance ou conduta). Se essas opções não são suficientes, entrar em contato. </p>
                    
                    <p>Isso é <u>irreversível</u>. Prosseguir?</p>
                </GeneralDialog>

                
                <GeneralDialog
                    open={showRestituteDialog}
                    title="😇 Restituir ao programa"
                    onClose={() => setShowTerminateDialog(false)}
                    actions={[
                        { label: 'Voltar', onClick: () => setShowRestituteDialog(false), style: 'neutral'},
                        { label: 'Restituir', onClick: () => restituteAmbassador(), },
                    ]}
                >
                    <p>Para restituir o embaixador, clique no botão abaixo.</p>  
                    
                </GeneralDialog>



                <GeneralDialog
                    open={showChangeCouponDialog}
                    title="🎫 Mudar o cupom"
                    onClose={() => setShowChangeCouponDialog(false)}
                    actions={[
                        { label: 'Voltar', onClick: () => {
                            setShowChangeCouponDialog(false)
                            setCoupon('')
                            setCouponError(<></>)
                        }, style: 'neutral'},
                        { label: 'Trocar o cupom', onClick: () => changeCoupon(coupon), style: 'green' },
                    ]} >
                    
                    <p>Você irá trocar o cupom do embaixador. Cuidado para digitar com calma.</p>

                    <p>Lembre-se das regras: 5 a 12 caracteres, sem símbolos; prefira o simples; e todas as letras serão transformadas em maiúsculas.</p>

                    { couponError }

                    <OslerInput
                        onChange={ (event) => { setCoupon(event.target.value)}}
                        value = {coupon}
                        style = {{marginLeft : '2em', marginTop: '1em'}}
                        placeholder={'Novo cupom'} />

                </GeneralDialog>


                <OslerButton
                    style = {buttonStyle}
                    color = 'dark-grey'
                    text = 'Fechar'
                    onClick = {() => props.unselectAmbassador()} />


                <AmbassadorMonthly
                    ambassador = {ambassador}
                    signalAdminDashboard = {registerSelectedAmbassadorMonth}
                    trigger = {trigger}
                    />


                <AmbassadorAdminBttns>

                    <AmbassadorAdminUploadInvoice
                        uploadInvoice = {uploadInvoice}
                        selectedMonthData = {selectedMonthData} />

                    
                    <AmbassadorAdminRemoveInvoice
                        removeInvoice = {removeInvoice}
                        selectedMonth = {selectedMonth}
                        selectedMonthData = {selectedMonthData} />

                    { ambassador.status == 'active' && 
                        <>
                        <OslerButton
                            style = {buttonStyle}
                            color = 'blue'
                            icon = '🏷️'
                            text = 'Trocar cupom'
                            onClick = {() => setShowChangeCouponDialog(true)} />
                                        

                        <OslerButton
                            style = {buttonStyle}
                            color = 'red'
                            icon = '💀'
                            text = 'Excluir do programa'
                            onClick = {() => setShowTerminateDialog(true)} />
                        </>
                    }

                    { ambassador.status === 'ambassador_terminated' &&
                        <OslerButton
                            style = {buttonStyle}
                            color = 'blue'
                            text = 'Restituir'
                            onClick = {() => setShowRestituteDialog(true)} />
                    }
                </AmbassadorAdminBttns>

            </Container>
        }
        </>
    )
}