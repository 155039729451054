import React, { useMemo } from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table'
import AmbassadorsController from './AmbassadorsController'

export default function MonthlyIndividualComissionsTable(props) {
    const monthsAbbr = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"]


    const monthlyIndividualComissionsColumn = useMemo(() => {
        const months = [...AmbassadorsController.monthRange].reverse()

        const columns = months.map((month) => {
            const [year, monthNumber] = month.split('_')
            const monthName = monthsAbbr[parseInt(monthNumber, 10) - 1]
            const yearLastTwoDigits = year.slice(-2)
            const header = `${monthName}/${yearLastTwoDigits}`

            return {
                accessorKey: `${month}.comission`,
                header: header,
                Cell: ({ cell }) => {
                    if (!cell.getValue()) {
                        return '∅'
                    }
                    else {
                        const roundedValue = Math.round(cell.getValue())
                        return `${roundedValue.toLocaleString('pt-BR')}`
                    }
                }
            }
        })

        columns.unshift({
            accessorKey: 'coupon',
            header: 'Cupom'
        })

        columns.push({
            accessorKey: 'total.comission',
            header: 'Total'
        })

        return columns
    }, [])

    const individualComissions = useMaterialReactTable({
        data: AmbassadorsController.activeAmbassadorsComissions,
        columns: monthlyIndividualComissionsColumn,
        initialState: { density: 'compact' },
    })

    return (
        <div className='AmbassadorDashboard-Table'>
            <p>Somente para embaixadores <u>ativos</u>.</p>
            <MaterialReactTable table={individualComissions} />
        </div>
    )
}