import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import GeneralDialog from './GeneralDialog';

export default function ShortcutsDialog(props) {
    const [showDialog, setShowDialog] = useState(false);

    useEffect(() => {
        if (props.showDialog !== showDialog) {
            setShowDialog(props.showDialog);
        }
    }, [props.showDialog]);

    function clozeModal() {
        props.handleClose();
    }

    if (!showDialog) return null;

    return (
        // Dentro de ShortcutsDialog.js
        <GeneralDialog
            open={props.showDialog}
            title="⌨️ Atalhos de teclado"
            onClose={props.handleClose}
            actions={[
                { label: 'Ok', onClick: props.handleClose }
            ]}
        >
            {props.shortcutsButtonProps.length === 0 ? (
                "Não há atalhos disponíveis para essa página ☹️"
            ) : (
                <ul>
                    {props.shortcutsButtonProps.map((element, index) => (
                        <li key={index}>
                            <strong>{element.keys}</strong> - {element.description}
                        </li>
                    ))}
                </ul>
            )}
        </GeneralDialog>

    );
}
