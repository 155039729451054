import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'

const config = {
    apiKey: "AIzaSyCrvtj7G3vUvWdi2_b3x95CG6UObTltX5I",
    authDomain: "osler-mobile-apps-database.firebaseapp.com",
    databaseURL: "https://osler-mobile-apps-database.firebaseio.com",
    projectId: "osler-mobile-apps-database",
    storageBucket: "osler-mobile-apps-database.appspot.com",
    messagingSenderId: "367141382392",
    appId: "1:367141382392:web:e483264301f6a78c",
    measurementId: "G-H24PKK0W2J"
};


// São Paulo setup
const spConfig = {
    apiKey: "AIzaSyDaqYkNPJrO6FXNh0dv_tTIRAVwjfpErUE",
    authDomain: "osler-web-app.firebaseapp.com",  
    projectId: "osler-web-app",
    storageBucket: "osler-web-app.appspot.com",
    messagingSenderId: "166693677659",
    appId: "1:166693677659:web:606f89044675392a57ad7e",
    measurementId: "G-BM7N89CFD7"
  };
  


// const app = firebase.initializeApp(config);
const app = firebase.initializeApp(spConfig);


// adicionei para consertar o offline error em conexões lentas
// https://github.com/firebase/firebase-js-sdk/issues/1674
// https://github.com/firebase/firebase-js-sdk/issues/6951 
app.firestore().settings({ experimentalForceLongPolling: true });


export const auth = app.auth();
export const db = app.firestore();


export const functions = app.functions('southamerica-east1');
// export const functions = app.functions();



export const analytics = app.analytics();
export const storage = app.storage();

export default firebase;