import styled, { keyframes } from 'styled-components'
import { RowCSS } from '../components/BasicComponents'

const theme = {
    colors: {
        // Core colors
        primary: '#4A90E2',    // Light blue
        secondary: '#F5F7FA',  // Light gray
        accent: '#8E2440',     // Wine color
        background: '#FFFFFF',
        
        // Text colors
        text: {
            primary: '#2C3E50',
            secondary: '#95A5A6',
            bright: '#FFFFFF'
        },
        
        // Functional colors
        border: '#E5E9F2',
        error: '#E74C3C',
        success: '#2ECC71'
    },
    
    fonts: {
        primary: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
        weights: {
            regular: 400,
            medium: 500,
            semibold: 600
        }
    },
    
    shadows: {
        subtle: '0 2px 8px rgba(74, 144, 226, 0.08)',
        medium: '0 4px 12px rgba(74, 144, 226, 0.12)',
        strong: '0 8px 24px rgba(74, 144, 226, 0.16)',
        highlight: '0 0 0 3px rgba(74, 144, 226, 0.25)'
    }
}

// Animations
const fadeIn = keyframes`
    from { opacity: 0; transform: translateX(-20px); }
    to { opacity: 1; transform: translateX(0); }
`

const float = keyframes`
    0% { transform: translateY(0px); }
    50% { transform: translateY(-10px); }
    100% { transform: translateY(0px); }
`

export const LoginScreenContainer = styled.div`
    display: flex;
    min-height: 100vh;
    background: ${theme.colors.background};
    font-family: ${theme.fonts.primary};
    
    @media (max-width: 1024px) {
        flex-direction: column;
    }
`

export const BrandSection = styled.div`
    flex: 1;
    background: ${theme.colors.secondary};
    padding: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;Tagline
    animation: ${fadeIn} 1s ease-out;
    
    @media (max-width: 1024px) {
        padding: 2rem;
    }

    @media (max-width: 500px) {

    }
`

export const LogoContainer = styled.div`
    ${RowCSS}
    text-align: center;
    margin-bottom: 2rem;
    animation: ${float} 6s ease-in-out infinite;

    img.logo {
        height: 8em;
        margin-right: 1em;
    }

    img.signature {
        height: 4em;
        margin-left: 1em;
    }

    @media (max-width: 500px) {
        margin-bottom: 0.5em;

        img.logo {
            height: 4em;
        }    

        img.signature {
            height: 3em;
        }
    }
`

export const Tagline = styled.h2`
    color: ${theme.colors.text.primary};
    font-size: 1.3rem;
    font-weight: ${theme.fonts.weights.semibold};
    text-align: center;
    margin: 0.75rem 0;
    line-height: 1.4;
    letter-spacing: -0.5px;

    @media (max-width: 500px) {
        font-size: 1em;
        margin: 0 0 0 0;
    }
`

export const LoginSection = styled.div`
    flex: 1;
    padding: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: ${fadeIn} 1s ease-out;
    
    @media (max-width: 1024px) {
        padding: 2rem;
    }

    @media (max-width: 500px) {
        padding: 1em;

    }
`

export const LoginBox = styled.div`
    width: 100%;
    max-width: 440px;
    padding: 2rem;

    @media (max-width: 500px) {
        padding: 1em;
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
`

export const InputGroup = styled.div`
    position: relative;
    width: 100%;
`

export const Input = styled.input`
    width: 100%;
    padding: 1rem;
    border: 2px solid ${theme.colors.border};
    border-radius: 8px;
    font-size: 1rem;
    color: ${theme.colors.text.primary};
    transition: all 0.2s ease;
    background: ${theme.colors.background};
    
    &:focus {
        outline: none;
        border-color: ${theme.colors.primary};
        box-shadow: ${theme.shadows.highlight};
    }
    
    &::placeholder {
        color: ${theme.colors.text.secondary};
    }
`

export const Button = styled.button`
    width: 100%;
    padding: 1rem;
    background: ${theme.colors.primary};
    color: ${theme.colors.text.bright};
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: ${theme.fonts.weights.medium};
    cursor: pointer;
    transition: all 0.2s ease;
    
    &:hover {
        background: ${theme.colors.primary}E6;
        transform: translateY(-2px);
    }
    
    &:active {
        transform: translateY(1px);
    }
`

export const ForgotPassword = styled.a`
    color: ${theme.colors.primary};
    font-size: 0.9rem;
    text-decoration: none;
    text-align: right;
    display: block;
    margin-top: -1rem;
    font-weight: ${theme.fonts.weights.medium};
    
    &:hover {
        color: ${theme.colors.accent};
    }
`

export const WelcomeText = styled.h1`
    font-size: 2rem;
    font-weight: ${theme.fonts.weights.semibold};
    margin-bottom: 0.75rem;
    color: ${theme.colors.text.primary};
    letter-spacing: -0.5px;
`

export const SubText = styled.p`
    color: ${theme.colors.text.secondary};
    margin-bottom: 2rem;
    font-size: 1rem;
    line-height: 1.5;
`

export const Text = styled.p`
    color: ${theme.colors.text.secondary};
    font-size: ${props => props.size || '0.9rem'};
    line-height: 1.5;
    margin: ${props => props.margin || '0'};
    text-align: ${props => props.align || 'left'};
    
    a {
        color: ${theme.colors.primary};
        text-decoration: none;
        font-weight: ${theme.fonts.weights.medium};
        
        &:hover {
            color: ${theme.colors.accent};
        }
    }
`

export const ButtonGroup = styled.div`
    display: flex;
    gap: 1rem;
    justify-content: ${props => props.justify || 'center'};
    margin-top: ${props => props.marginTop || '0'};
`