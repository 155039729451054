import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraPropofol() {
    const [peso, setPeso] = useState(''); // Peso em kg
    const [volumeInfusao, setVolumeInfusao] = useState(''); // Volume de infusão em mL/h
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularPropofol = () => {
        const pesoNum = parseFloat(peso);
        const volumeInfusaoNum = parseFloat(volumeInfusao);

        if (isNaN(pesoNum) || isNaN(volumeInfusaoNum)) {
            setResultado('Preencha todos os campos necessários para ver o resultado');
            return;
        }

        const dose = (volumeInfusaoNum * 10) / pesoNum; // Dose em mg/kg/h
        setResultado(`Dose: <strong>${dose.toFixed(2)}</strong> mg/kg/h`);
    };

    useEffect(() => {
        if (peso && volumeInfusao) {
            calcularPropofol();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [peso, volumeInfusao]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Infusão de Propofol</h1>
            <div>
                <label>Peso do paciente (kg):</label>
                <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div>
                <label>Volume de infusão (mL/h):</label>
                <input type="number" value={volumeInfusao} onChange={e => setVolumeInfusao(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
            
        </div>
    );
}

export default CalculadoraPropofol;
