import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import Exams from './Exams'
import residenciaIcon from './../assets/residencia.png'
import CustomStudy from '../custom/CustomStudy'
import ResidenciaLists from './ResidenciaLists'
import IsLoading from '../main/IsLoading'
import ExamsController from '../controllers/ExamsController'
import { useSelector } from 'react-redux'
import ResidenciaListsController from '../controllers/ResidenciaListsController'
import AppContainer from '../app-container/AppContainer'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import HelpIcon from './../assets/question-new.png'
import OslerCard from '../components/OslerCard'



// Fica aqui fora para evitar recriação da tab e (re)renderizações estranhas

const HelpContent = ({ testType }) => (
    <OslerCard style={{ alignItems: 'flex-start' }}>
        {testType === 'Residencia' ? (
            <>
                <p><b>Crie uma sessão de questões.</b> Você poderá respondê-las agora ou salvá-las em uma lista, para mais tarde. Selecione um ou mais temas e, se desejar, filtre por instituições e/ou anos específicos.</p>
                <p><b>Acesse suas listas.</b> Volte rapidamente de onde parou e acompanhe seu progresso, sem ter que criar várias vezes.</p>
                <p><b>Faça provas na íntegra.</b> Seja no modo simulado ou não, te permitimos fazer as provas na íntegra e avaliar seu desempenho nelas, incluindo sua evolução.</p>
            </>
        ) : (
            <>
                <p><b>Crie uma sessão de flashcards.</b> Você poderá respondê-las agora ou salvá-las em uma lista, para mais tarde. Selecione um ou mais temas e, se desejar, filtre por instituições e/ou anos específicos.</p>
                <p><b>Acesse suas listas.</b> Volte rapidamente de onde parou e acompanhe seu progresso, sem ter que criar várias vezes.</p>
            </>
        )}
    </OslerCard>
)



export default function ResidenciaScreen({ testType = 'Residencia' }) {
    const [dataLoaded, setDataLoaded] = useState(false)
    const user = useSelector(state => state.user.data)

    
    useEffect(() => {
        console.log('ResidenciaScreen >> mounting...')
        async function load() {
            await ExamsController.start(user)
            await ResidenciaListsController.start(user)
            setDataLoaded(true)
        }

        load()
    }, [])


    const tabs = useMemo(() => [
        {
            id: 'questions',
            label: 'Questões',
            content: <CustomStudy testType={'Residencia'} />
        },
        {
            id: 'lists',
            label: 'Listas',
            content: <ResidenciaLists />
        },
        {
            id: 'exams',
            label: 'Provas',
            content: <Exams />
        },
        {
            id: 'help',
            icon: HelpIcon,
            content: <HelpContent testType={testType} />
        }
    ], [testType]) 


    return (
        <AppContainer>
            {!dataLoaded && <IsLoading />}
            {dataLoaded && (
                <>
                    <ScreenContainerHeader
                        title = {'Residência'}
                        icon  = {residenciaIcon}
                        tabs  = {tabs} />
                </>
            )}
        </AppContainer>
    )
}