import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import MonthlyIndividualComissionsTable from './MonthlyIndividualComissionsTable'
import MonthlyConsolidatedCommissionsTable from './MonthlyConsolidatedCommissionsTable'
import TotalPostponedCommissionsTable from './TotalPostponedCommissionsTable'
import ConsolidatedSalesTable from './ConsolidatedSalesTable'

export default function AmbassadorsDashboardTables(props) {

    return (
        <>
            <MonthlyIndividualComissionsTable />
            
            <MonthlyConsolidatedCommissionsTable />
            
            <TotalPostponedCommissionsTable />
            
            <ConsolidatedSalesTable />
        </>
    )
}