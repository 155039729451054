// checkoutStyles.js
import { keyframes } from 'styled-components'

export const fadeIn = keyframes`
    from { opacity: 0; transform: translateX(-20px); }
    to { opacity: 1; transform: translateX(0); }
`

export const theme = {
    colors: {
        // Core colors
        primary: '#4A90E2',    // Light blue
        secondary: '#F5F7FA',  // Light gray
        accent: '#8E2440',     // Wine color
        background: '#FFFFFF',
        
        // Text colors
        text: {
            primary: '#2C3E50',
            secondary: '#95A5A6',
            bright: '#FFFFFF'
        },
        
        // Functional colors
        border: '#E5E9F2',
        error: '#E74C3C',
        success: '#2ECC71'
    },
    
    fonts: {
        primary: '-apple-system, BlinkMacSystemFont, "SF Pro Display", "Segoe UI", Roboto, sans-serif',
        weights: {
            regular: 400,
            medium: 500,
            semibold: 600
        }
    },
    
    shadows: {
        subtle: '0 2px 8px rgba(74, 144, 226, 0.08)',
        medium: '0 4px 12px rgba(74, 144, 226, 0.12)',
        strong: '0 8px 24px rgba(74, 144, 226, 0.16)',
        highlight: '0 0 0 3px rgba(74, 144, 226, 0.25)'
    }
}