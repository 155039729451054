import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled, { keyframes } from 'styled-components'
import ShoppingCart from './../assets/shopping-cart.png'
import ShieldIcon from './../assets/shield.png'
import Logo2 from './../assets/osler2.png'
import Offer from './Offer'


const glowPulse = keyframes`
    0% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.5) }
    50% { box-shadow: 0 0 20px rgba(255, 215, 0, 0.8) }
    100% { box-shadow: 0 0 5px rgba(255, 215, 0, 0.5) }
`

const slideUp = keyframes`
    from { opacity: 0; transform: translateY(30px) }
    to { opacity: 1; transform: translateY(0) }
`

const Container = styled.div`
    background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
    height: 100vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    color: white;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: radial-gradient(circle at 50% 0%, rgba(255, 215, 0, 0.15) 0%, transparent 70%);
        pointer-events: none;
    }

    @media (max-width: 480px) {
        padding: 0.5rem;
    }
`

const BlackFridayBanner = styled.div`
    background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%);
    padding: 0.8rem 2rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    animation: ${slideUp} 1s ease-out;
    text-align: center;

    h1 {
        color: black;
        font-size: 2.8rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 2px;
        text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    }


    @media (max-width: 480px) {
        padding: 0.6rem 1rem;
        width: 98%;
        margin-bottom: 1rem;
        
        h1 {
            font-size: 1.8rem;
        }
    }
`

const OfferCard = styled.div`
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 215, 0, 0.1);
    border-radius: 16px;
    padding: 2rem;
    width: 90%;
    max-width: 1000px;
    margin: 0 auto;
    animation: ${slideUp} 1s ease-out;

@media (max-width: 480px) {
        padding: 1rem;
        width: 98%;
        border-radius: 12px;
    }
`

const BenefitsCard = styled.div`
    background: rgba(255, 255, 255, 0.03);
    border-radius: 12px;
    padding: 1.5rem;
    margin: 1rem 0;

    h2 {
        color: #FFD700;
        text-align: center;
        margin-bottom: 1.5rem;
        font-size: 1.8rem;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: 1px;
    }

    @media (max-width: 480px) {
        padding: 1rem;
        margin: 0.5rem 0;

        h2 {
            font-size: 1.4rem;
            margin-bottom: 1rem;
        }
    }
`

const BenefitsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0;
    margin: 0;
`

const BuyButton = styled.button`
    background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%);
    border: none;
    padding: 1.2rem 4rem;
    border-radius: 50px;
    font-size: 1.8rem;
    font-weight: 900;
    color: black;
    cursor: pointer;
    margin: 2rem auto;
    display: block;
    transition: all 0.3s ease;
    animation: ${glowPulse} 2s infinite;
    text-transform: uppercase;
    letter-spacing: 1px;

    &:hover {
        transform: scale(1.05);
        background: linear-gradient(90deg, #FFA500 0%, #FFD700 100%);
    }

    @media (max-width: 480px) {
        font-size: 1.2rem;
        padding: 0.8rem 1.5rem;
        width: 95%;
        margin: 1rem auto;
    }
`

const FeaturesGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 80%;
    gap: 1.5rem;
    margin-top: 4rem;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
        max-width: 400px;
    }
`

const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 215, 0, 0.1);

    &:hover {
        transform: translateY(-3px);
        background: rgba(255, 255, 255, 0.08);
    }

    img {
        height: 2em;
        margin-bottom: 1rem;
        transition: transform 0.3s ease;
    }

    &:hover img {
        transform: scale(1.1);
    }

    h3 {
        color: #FFD700;
        margin: 0 0 0.5rem;
        font-size: 1.2rem;
        font-weight: 700;
    }

    p {
        margin: 0;
        font-size: 0.9rem;
        line-height: 1.4;
        color: #ccc;
    }

    @media (max-width: 480px) {
        padding: 1rem;

        h3 {
            font-size: 1.1rem;
        }

        p {
            font-size: 0.85rem;
        }

        img {
            height: 1.8em;
        }
    }
`

const PriceTag = styled.div`
    text-align: center;
    margin: 1rem 0 2rem;

    .original-price {
        text-decoration: line-through;
        color: #888;
        font-size: 1.3rem;
        font-weight: 500;

    }

    .current-price {
        font-size: 5rem;
        font-weight: 900;
        background: linear-gradient(90deg, #FFD700, #FFA500);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0.3rem 0;
        letter-spacing: -2px;

    
    }

    .discount-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        margin: 0.5rem 0;
    }

    .discount {
        background: #009127;
        color: white;
        padding: 0.5rem 1rem;
        border-radius: 25px;
        font-weight: bold;
        font-size: 1.2rem;
        text-transform: uppercase;
        letter-spacing: 1px;
        min-width: 120px;

    }

    .economy {
        color: #00b12e;
        font-size: 1.4rem;
        font-weight: bold;

        span {
            text-transform: lowercase;
            font-size: 1.2rem;
        }

    }

    .access-info {
        color: #FFD700;
        font-size: 1.2rem;
        margin-top: 1rem;
        font-weight: bold;
    }


    @media (max-width: 480px) {
        margin: 0.5rem 0 1rem;

        .original-price {
            font-size: 1rem;
        }

        .current-price {
            font-size: 3rem;
        }

        .discount {
            font-size: 0.9rem;
            min-width: 90px;
            padding: 0.4rem 0.8rem;
        }

        .economy {
            font-size: 1rem;
            
            span {
                font-size: 0.9rem;
            }
        }

        .access-info {
            font-size: 1rem;
        }
    }
`

const BenefitItem = styled.div`
    background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 215, 0, 0.08) 100%
    );
    border-radius: 12px;
    padding: 1.2rem 1.5rem;
    position: relative;
    transition: transform 0.2s ease;
    border-left: 3px solid #FFD700;

    &:hover {
        transform: translateY(-2px);
        background: linear-gradient(
            135deg,
            rgba(255, 255, 255, 0.1) 0%,
            rgba(255, 215, 0, 0.1) 100%
        );
    }

    span {
        font-size: 1.1rem;
        font-weight: 500;
        color: #fff;
        display: block;
        line-height: 1.4;
    }

    @media (max-width: 480px) {
        padding: 0.8rem 1rem;
        
        span {
            font-size: 0.9rem;
        }
    }
`



export default function BlackFridayOffer() {
    const navigate = useNavigate()

    function handlePurchase(planID) {
        // Recebe o plano escolhido como parâmetro, mas não
        // utiliza.
        navigate('/login')
    }

    const benefits = [
        "+47.000 flashcards baseados no UpToDate e em livros clássicos",
        "+43.000 questões, de todo o Brasil, todas comentadas",
        "qBank completo: provas na íntegra, lista de questões personalizadas, exportar em pdf, etc",
        "Simulados com questões próprias (2025)",
        "Estatísticas detalhadas e planos de revisão e recuperação",
        "Cronogramas para R1: Extensivo, Semi-Extensivo, Intensivão",
        "Material de multimídia completo: semiologia, ECG, exames de imagem, e exames complementares",
        "Crie resumos associados aos cards e questões",
        "Sistema de feedback e atualização contínua do conteúdo"
    ]

    return (
        <Container>
            <BlackFridayBanner>
                <h1>Black Friday 2024</h1>
            </BlackFridayBanner>

            <OfferCard>
                <PriceTag>
                    <div className="original-price">De R$ 3.600,00 por apenas</div>
                    <div className="current-price">R$ 2.697,00</div>
                    <div className="discount-container">
                        <div className="discount">25% OFF</div>
                        <div className="economy">R$ 903,00 <span>de desconto</span></div>
                    </div>
                    <div className="access-info">Até Jan de 2026 (12x de R$224 no cartão)</div>
                </PriceTag>

                <BuyButton onClick={handlePurchase}>
                    Garantir Minha Vaga
                </BuyButton>

                <BenefitsCard>
                    <h2>SEU ACESSO COMPLETO</h2>
                    <BenefitsGrid>
                        {benefits.map((benefit, index) => (
                            <BenefitItem key={index}>
                                <span>{benefit}</span>
                            </BenefitItem>
                        ))}
                    </BenefitsGrid>
                </BenefitsCard>

                <BuyButton onClick={handlePurchase}>
                    Garantir Minha Vaga
                </BuyButton>

                <FeaturesGrid>
                    <Feature>
                        <img src={Logo2} alt="Plataforma completa" />
                        <h3>Acesso Total</h3>
                        <p>Toda a plataforma e futuras atualizações</p>
                    </Feature>

                    <Feature>
                        <img src={ShieldIcon} alt="Garantia" />
                        <h3>7 Dias de Garantia</h3>
                        <p>Reembolso integral sem perguntas</p>
                    </Feature>

                    <Feature>
                        <img src={ShoppingCart} alt="Pagamento" />
                        <h3>12x Sem Juros</h3>
                        <p>Cartão ou boleto à vista</p>
                    </Feature>
                </FeaturesGrid>
            </OfferCard>
        </Container>
    )
}