// Novembro 2024
//      Tanto FlashcardAnswerJSX quanto McqAnswerJSX precisam ser componentes
//      separados porque são usados no modo consulta.  

import React, { useEffect, useState } from 'react'
import { htmlToReact } from '../utils/HtmlToReact'
import Separator from './Separator'
import styled from 'styled-components'
import { flashcardStyle, seenContentStyle } from './FlashcardsStyles'
import { SelectableText } from '../components/BasicComponents'
import TestContainer from './TestContainer'
import session from '../controllers/Session'



const Question = styled.div`
   ${seenContentStyle}
   text-align: center;
   ${SelectableText}
`

const Answer = styled.div`
   ${flashcardStyle}
   text-align: justify;
   ${SelectableText}
`


const Reference = styled.p`
    font-size: 0.70em;
    margin: 0;
    padding: 0;
    color: gray;
    font-weight: bold;
    width: 100%;
`

export default function FlashcardAnswerJSX({ test }) {


    const [flashcard, setFlashcard] = useState('')

    useEffect(() => {
        if (!test) {
            setFlashcard(
                `Erro grave ao obter a resposta.
                Por favor, tire um print e envie na DM!
                (${session.session[session.currentIndex].testID} / ${session.currentIndex})
            `)
        }
        else if (test.type === 'classic') {
            setFlashcard(
                <>
                    <Question>{htmlToReact(test.getQuestion())}</Question>
                    <Separator />
                    <Answer>{htmlToReact(test.getAnswer())}</Answer>
                    <Reference>{htmlToReact(test.reference)}</Reference>
                </>
            )
        } else {
            setFlashcard(
                <>
                    <Answer>{htmlToReact(test.getAnswer())}</Answer>
                    <Reference>{htmlToReact(test.reference)}</Reference>
                </>
            )
        }
    }, [])

    return (
        <TestContainer>
            {flashcard}
        </TestContainer>
    )
}