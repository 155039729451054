import React from 'react'
import '../scss/Main.scss'

import styled from 'styled-components';


const colorMap = {
    fail: { backgroundColor: '#8E0011', hoverColor: 'rgba(142, 0, 17, 0.9)' },
    hard: { backgroundColor: 'rgb(128, 128, 128)', hoverColor: 'rgba(128, 128, 128, 0.9)' },
    normal: { backgroundColor: 'rgb(35, 146, 7)', hoverColor: 'rgba(35, 146, 7, 0.9)' },
    easy: { backgroundColor: 'rgb(39, 121, 196)', hoverColor: 'rgba(39, 121, 196, 0.9)' },
  };

  

const SRSButton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: 600;
    width: 22%;
    height: 4em;
    border-radius: 0.5em;
    margin-right: 0.1vw;
    margin-left: 0.1vw;

    cursor: pointer;
    background-color: ${props => colorMap[props.colorClass].backgroundColor};

    &:hover {
        background-color: ${props => colorMap[props.colorClass].hoverColor};
    }


    @media (max-width: 500px) {
        width: 23%;
        box-shadow: none;
        margin: 0;
        border-radius: 0.5em;
        padding: 10px;
    }

    ${props => props.theme.darkMode ? `
        filter: grayscale(50%);
    ` : `
        filter: none;
        box-shadow: inset 0 -0.6em 0 -0.35em rgba(0, 0, 0, 0.17);
        &:active {
            box-shadow: none;
        }
            
    `}

    filter: ${props => props.theme.darkMode ? 'grayscale(50%)' : 'none'};
`;

const SRSText = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.2em;

  @media (max-width: 500px) {
    
  font-size: 1em;
  }
`;

const SRSSub = styled.p`
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 0.75em;
`;


export default function ButtonSRS(props) {
    const { submitAnswer, levelOfSuccess, colorClass, text, time } = props;

    function handleClick() {
      submitAnswer(levelOfSuccess);
    }
  
    return (
      <SRSButton colorClass={colorClass} onClick={handleClick}>
        <SRSText>{text}</SRSText>
        <SRSSub>{time}</SRSSub>
      </SRSButton>
    );
  }