// Outubro, 2024
// Tecnicamente, existe o OslerHorizontalBar, mas lá é VictoryChart, e isso é uma desgraça total
import React, { useState } from 'react'
import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'

const ProgressBarContainer = styled.div`
    width: 100%;
    height: 0.8em; // Aumentado para tornar a barra mais grossa
    background-color: ${props => props.theme.darkMode ? 
        'rgb(61, 61, 61)' : 
        '#ecf0f1'};
    border-radius: 0.4em; // Ajustado para metade da altura
    overflow: visible;
    display: flex;
    position: relative;

    @media (max-width: 768px) {
        height: 0.6em; // Ligeiramente menor em dispositivos móveis
    }
`

const SegmentContainer = styled.div`
    position: relative;
    height: 100%;
    width: ${props => props.percent}%;
    background-color: ${props => {
        if (props.theme.darkMode) {
            // Ajusta a opacidade para cores mais suaves no modo escuro
            return props.color.replace('0.7', '0.5');
        }
        return props.color;
    }};
    
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${props => {
            if (props.theme.darkMode) {
                // Aumenta a opacidade no hover para dar mais destaque
                return props.hoverColor.replace('0.8', '0.6');
            }
            return props.hoverColor;
        }};
    }

    ${props => props.isFirst && `
        border-top-left-radius: 0.4em;
        border-bottom-left-radius: 0.4em;
    `}

    ${props => props.isLast && `
        border-top-right-radius: 0.4em;
        border-bottom-right-radius: 0.4em;
    `}

    @media (max-width: 768px) {
        // Ajusta bordas para altura menor em mobile
        ${props => props.isFirst && `
            border-top-left-radius: 0.3em;
            border-bottom-left-radius: 0.3em;
        `}

        ${props => props.isLast && `
            border-top-right-radius: 0.3em;
            border-bottom-right-radius: 0.3em;
        `}
    }
`

const Tooltip = styled.div`
    position: absolute;
    bottom: 100%;
    left: ${props => props.left}%;
    transform: translateX(-50%);
    opacity: ${props => props.visible ? 1 : 0};
    transition: opacity 0.3s ease;
    padding: 0.75em;
    border-radius: 0.3em;
    ${ColumnCSS}
    z-index: 10;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        border: 1px solid rgba(255, 255, 255, 0.1);
        color: rgb(220, 220, 220);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    ` : `
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    `}

    @media (max-width: 768px) {
        padding: 0.5em;
        font-size: 0.9em;
        
        // Previne tooltip de sair da tela
        left: ${props => {
            const position = props.left;
            // Se estiver muito à esquerda
            if (position < 15) return '15%';
            // Se estiver muito à direita
            if (position > 85) return '85%';
            return `${position}%`;
        }};
    }

    @media (max-width: 480px) {
        // Em telas muito pequenas, posiciona tooltip acima com mais espaço
        bottom: 150%;
    }
`

const TooltipMain = styled.p`
    font-size: 0.9em;
    margin: 0;
    font-weight: bold;
    color: ${props => props.theme.darkMode ? 
        'rgb(220, 220, 220)' : 
        'inherit'};

    @media (max-width: 768px) {
        font-size: 0.8em;
    }
`

const TooltipSecondary = styled.p`
    font-size: 0.8em;
    margin: 0;
    color: ${props => props.theme.darkMode ? 
        'rgb(180, 180, 180)' : 
        'inherit'};

    @media (max-width: 768px) {
        font-size: 0.7em;
    }
`

function OslerProgressBar({ segments }) {
    const [activeSegment, setActiveSegment] = useState(null)

    const calculateTooltipPosition = (index) => {
        const segmentStart = segments.slice(0, index).reduce((sum, segment) => sum + segment.percent, 0)
        return segmentStart + (segments[index].percent / 2)
    }

    return (
        <ProgressBarContainer>
            {segments.map((segment, index) => (
                <SegmentContainer
                    key={index}
                    percent={segment.percent}
                    color={segment.color}
                    hoverColor={segment.colorHover}
                    onMouseEnter={() => setActiveSegment(index)}
                    onMouseLeave={() => setActiveSegment(null)}
                    isFirst={index === 0}
                    isLast={index === segments.length - 1}
                />
            ))}
            {activeSegment !== null && (
                <Tooltip 
                    visible={true} 
                    left={calculateTooltipPosition(activeSegment)}
                >
                    <TooltipMain>{segments[activeSegment].label}</TooltipMain>
                    <TooltipSecondary>{segments[activeSegment].secondaryLabel}</TooltipSecondary>
                </Tooltip>
            )}
        </ProgressBarContainer>
    )
}

export default OslerProgressBar