import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle} from 'react'
import styled from 'styled-components'
import OslerTextEditor from '../tests/OslerTextEditor'
import Notebook from '../controllers/NotebookController'
import { removeHTMLTags, sleep } from '../utils/Utils'
import LabelIcon from './../assets/label.png'
import { RowCSS } from '../components/BasicComponents'
import GeneralDialog from '../tests/GeneralDialog'
import OslerDropdown from '../components/OslerDropdown'
import OslerData, { KEYS } from '../controllers/OslerData'
import { useNavigate } from 'react-router-dom'
import { backgroundWhiteBlack, textWhiteBlack } from '../tests/FlashcardsStyles'


const Container = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

`

const NoteEditor = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
`

const Label = styled.div`
    ${ RowCSS }

    cursor: pointer;

    padding-left: 0.8em;
    margin-bottom: 0.5em;

    img {
        height: 0.8em;
    }

    

    p {
        font-size: 0.7em;
        font-weight: bold;
        ${textWhiteBlack}
          margin: 0 0 0 0.3em;
    }
`



function NotebookEditor({ onClose, chosenNoteID, signalTitleChanged}, ref) {
    // É patológico e infeliz, mas precisamos manter os dados tanto em JSX (para garantir
    // atualização dos componentes) quanto como referência (para garantir cópia durante
    // a função chamada no closure, que salva os dados).
    const [title, setTitle] = useState(false)
    const [content, setContent] = useState(false)
    const [theme, setTheme] = useState(false)
    const titleRef = useRef(false)
    const contentRef = useRef(false)
    const themeRef = useRef(false)

    // Tema e ID da nota carregada
    const [noteID, setNoteID] = useState(false)

    // Dados de quando a nota foi carregada
    const noteOriginalData = useRef(false)

    // Se versão atual está salva ou não
    const lastVersionSaved = useRef(true)

    // Diálogo para selecionar tema
    const [showLabelSelectorDialog,
         setShowLabelSelectorDialog] = useState(false)


    const [tmpNewTheme, setTmpNewTheme] = useState(false)
    const [availableThemes, setAvailableThemes] = useState(false)

    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
        createNote,
        save,
        exit,
        deleteNote
    }))



    useEffect(() => {
        // Carrega lista de temas
        if (!Notebook || !Notebook.metadata) {
            console.log('Notebook não está pronto ainda...')
            navigate('/app')
            return;
        }

        const tagpaths = Object.keys(OslerData.data[KEYS.RESIDENCIA][KEYS.TESTS_PER_TAGPATH])

        const x = tagpaths.map(tagpath => {
            return {
                text: tagpath,
                id: tagpath
            }
        })

        setAvailableThemes(x)
    }, [])


    useEffect(() => {
        console.log(`Recebemos como props o noteID: ${chosenNoteID}`)

        if (chosenNoteID && chosenNoteID != noteID) {
            loadNote(chosenNoteID)
        }

    }, [chosenNoteID])


    async function loadNote(ID) {        
        if (noteID) {
            console.log('Antes de carregar a nova nota, precisamos salvar a atual...')
            save()
        }

        console.log('Carregando a nova nota...')
        noteOriginalData.current = await Notebook.loadNote(ID)
        loadNoteContent(noteOriginalData.current)
    }


    async function createNote() {
        console.log('Criando uma nova nota...')
        noteOriginalData.current = await Notebook.createNote()
        loadNoteContent(noteOriginalData.current)

        return noteOriginalData.current['id']
    }


    function loadNoteContent(noteData) {
        setContent(noteData.content)
        contentRef.current = noteData.content

        setTitle(noteData.title)
        titleRef.current = noteData.title

        if (signalTitleChanged) {
            signalTitleChanged(noteData.title)
        }

        setTheme(noteData.theme)
        themeRef.current = noteData.theme
        setNoteID(noteData.id)
    }


    function deleteNote() {
        Notebook.delete(noteID, theme)
        setTitle(false)
        setContent(false)
        setTheme(false)
        titleRef.current = false
        contentRef.current = false
        themeRef.current = false
        setNoteID(false)
        noteOriginalData.current = false
        lastVersionSaved.current = true
        if (signalTitleChanged) {
            signalTitleChanged(false)
        }
    }


    function exit(shouldSave = true) {
        if (shouldSave) {
            save()
        }
        else {
            lastVersionSaved.current = true
        }   
        if (onClose) {
            onClose()
        }
    }



    function save() {
        if (!contentRef.current && !titleRef.current) {
            // A função de desmonte pode ser chamado antes da inicialização dos dados
            // Ainda, isso é chamado por default antes de criar uma nova nota, mas não necessariamente
            // há uma nota prévia a ser salva.
            console.log('Sequer cogitaremos salvar, pois nem title e nem content são válidos.')
            return
        }
        else {
            console.log('\tIremos avaliar de salvar o conteúdo...')
            const contentChanged = noteOriginalData.current['content'] !== contentRef.current
            const titleChanged = noteOriginalData.current['title'] !== titleRef.current
    
            if (contentChanged || titleChanged) {
                console.log('\tO conteúdo mudou...')
                if (lastVersionSaved.current) {
                    console.log('\tMas não faremos nada, pois já foi salvo...')
                    return
                }
                else {
                    console.log('\tSalvo.')
                    Notebook.save(contentRef.current, removeHTMLTags(titleRef.current), themeRef.current, noteOriginalData.current,  contentChanged, titleChanged)
                    lastVersionSaved.current = true
                }
            }
            else {
                console.log('\tNão salvaremos, pois não há mudanças.')
            }
        }
    }


    useEffect(() => {
        // Grante que iremos salvar ao desmontar o componente
        return () => {
            console.log('Componente está sendo desmontado!')
            save()
        }
    }, [])


    function updateTitle(param) {
        // Não pode remover as tags HTML aqui, senão trava o editor. Isso precisa ser feito
        // só ao salvar os dados.
        // const empty = param == '' || param == '<p></p>' || param == '<p><br></p>'
        // const newTitle = empty ? '(sem título)' : param

        const newTitle = param
        
        lastVersionSaved.current = false

        setTitle(newTitle)
        titleRef.current = newTitle
        if (signalTitleChanged) {
            signalTitleChanged(newTitle)
        }
    }


    function updateContent(param) {
        lastVersionSaved.current = false
        setContent(param)
        contentRef.current = param
    }


    function changeNote(nextNoteID) {
        if (noteID) {
            save()
        }
        loadNote(nextNoteID)
    }


    function changeNoteTheme(newTheme) {
        Notebook.changeTheme(noteID, themeRef.current, newTheme)
        setTheme(newTheme)
        themeRef.current = newTheme
    }

    if (!Notebook || !Notebook.metadata) return;

    return (
        <>

        <GeneralDialog
            open = {showLabelSelectorDialog}
            title = '🏷️ Adicione um tema'
            onClose = {() => setShowLabelSelectorDialog(false)}
            actions = {[
                { label: 'Cancelar', onClick: () => setShowLabelSelectorDialog(false), style: 'neutral' },
                { label: 'Associar', onClick: () => {
                    changeNoteTheme(tmpNewTheme)
                    setShowLabelSelectorDialog(false)
                }}
            ]} >

            <p>Você pode associar esse resumo a um tema, para que ele apareça mais facilmente quando você estiver fazendo questões desse assunto.</p>

            <OslerDropdown
                allowInput = {true}
                style = {{alignSelf: 'center', marginTop: '1em', marginBottom: '2em', width: '100%'}}
                placeholder = 'Escolha um tema....'
                options = {availableThemes}
                signal = {setTmpNewTheme} />
            
        </GeneralDialog>


        { chosenNoteID &&
            <Container>
                    <NoteEditor>


                        <OslerTextEditor
                            showToolbar = {false}
                            updateParent = {content => updateTitle(content)}
                            previousContent = {title} 
                            singleLine={true} />

                        <Label onClick = {() => setShowLabelSelectorDialog(true)}>

                            <img src = {LabelIcon} />
                            <p>
                                {theme === 'without_tagpath' ? ('adicione um tema') : theme}
                            </p>
                        </Label>

                        <OslerTextEditor
                            updateParent = {content => updateContent(content)}
                            previousContent = {content} 
                            showToolbar = {true} />    

                </NoteEditor>
            
            </Container>
        }
        </>
    )
}


export default forwardRef(NotebookEditor)