import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraIdadeGestacionalUSG() {
    const [dataConsulta, setDataConsulta] = useState('');
    const [dataUSG, setDataUSG] = useState('');
    const [semanasUSG, setSemanasUSG] = useState('');
    const [diasUSG, setDiasUSG] = useState('');
    const [resultado, setResultado] = useState('Preencha todos os campos para ver o resultado.');

    useEffect(() => {
        const today = new Date();
        const dia = String(today.getDate()).padStart(2, '0');
        const mes = String(today.getMonth() + 1).padStart(2, '0');
        const ano = today.getFullYear();
        setDataConsulta(`${dia}/${mes}/${ano}`);
    }, []);

    useEffect(() => {
        if (dataConsulta.length === 10 && dataUSG.length === 10 && semanasUSG && diasUSG) {
            calcularIdadeGestacional();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [dataConsulta, dataUSG, semanasUSG, diasUSG]);

    const calcularIdadeGestacional = () => {
        const dataConsultaObj = new Date(dataConsulta.split('/').reverse().join('-'));
        const dataUSGObj = new Date(dataUSG.split('/').reverse().join('-'));

        const diffTime = Math.abs(dataConsultaObj - dataUSGObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        const semanasAdicionais = Math.floor(diffDays / 7);
        const diasAdicionais = diffDays % 7;

        const totalSemanas = parseInt(semanasUSG) + semanasAdicionais;
        const totalDias = parseInt(diasUSG) + diasAdicionais;

        const semanasFinal = totalSemanas + Math.floor(totalDias / 7);
        const diasFinal = totalDias % 7;

        const dataParto = new Date(dataUSGObj);
        dataParto.setDate(dataParto.getDate() + 280 - (parseInt(semanasUSG) * 7 + parseInt(diasUSG)));

        const diaParto = String(dataParto.getDate()).padStart(2, '0');
        const mesParto = String(dataParto.getMonth() + 1).padStart(2, '0');
        const anoParto = dataParto.getFullYear();

        setResultado(`Idade Gestacional: ${semanasFinal} semanas e ${diasFinal} dias. Data Provável do Parto: ${diaParto}/${mesParto}/${anoParto}`);
    };

    const handleDateChange = (setter) => (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 2) value = value.replace(/^(\d{2})(\d)/, '$1/$2');
        if (value.length > 5) value = value.replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
        setter(value);
    };

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Idade Gestacional pela USG</h1>
            <div>
                <label>Data da consulta (DD/MM/AAAA):</label>
                <input 
                    type="text" 
                    value={dataConsulta} 
                    onChange={handleDateChange(setDataConsulta)} 
                    placeholder="DD/MM/AAAA"
                />
            </div>
            <div>
                <label>Data do exame de USG (DD/MM/AAAA):</label>
                <input 
                    type="text" 
                    value={dataUSG} 
                    onChange={handleDateChange(setDataUSG)} 
                    placeholder="DD/MM/AAAA"
                />
            </div>
            <div>
                <label>Idade Gestacional Estimada pela USG:</label>
                <div>
                    <label>Semanas:</label>
                    <input 
                        type="number" 
                        value={semanasUSG} 
                        onChange={e => setSemanasUSG(e.target.value)} 
                    />
                </div>
                <div>
                    <label>Dias:</label>
                    <input 
                        type="number" 
                        value={diasUSG} 
                        onChange={e => setDiasUSG(e.target.value)} 
                    />
                </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraIdadeGestacionalUSG;
