import React, { useEffect, useState, useRef, useMemo} from 'react'
import Notebook from '../controllers/NotebookController'
import { removeHTMLTags } from '../utils/Utils'
import OslerDropdown from '../components/OslerDropdown'



export default function NoteSelector({ selectedNoteID, selectedNoteTitle = undefined, changeNote}) {

    const availableNotes = useMemo(() => {
        if (Notebook.metadata) {
            return Object.entries(Notebook.metadata)
                .flatMap(([theme, notesInTheme]) => 
                    Object.entries(notesInTheme).map(([id, note]) => {
                        
                        let selected = false;
                        let thisTitle = note.title || '(sem título)'

                        if (id === selectedNoteID) {
                            selected = true
                            if (selectedNoteTitle) {
                                thisTitle = selectedNoteTitle
                            }
                        }

                        return ({
                            text: removeHTMLTags(thisTitle),
                            id: id,
                            theme: theme,
                            default: selected
                        })
                    
                    })
                );
        }
        return [];
    }, [selectedNoteID, Notebook.metadata, selectedNoteTitle])


    if (!availableNotes) return null;

    return (
        <OslerDropdown 
            placeholder = 'Escolha um resumo...'
            style = {{width: '100%'}}
            options = {availableNotes} 
            allowInput = {true}
            signal = {(id) => changeNote(id)} />    
    )



}