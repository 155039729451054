import React, { useEffect, useState, useRef } from 'react';

import styled from 'styled-components';
import { db } from '../firebase/firebase-setup';
import AmbassadorDialog from './AmbassadorDialog';
import GeneralDialog from '../tests/GeneralDialog';
import AmbassadosStatisticCard from './AmbassadosStatisticCard'
import controller from './AmbassadorsController'


const InstitutionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: #f5f5f5;

    padding: 5em 3em 5em 3em;
`

const InstitutionsStatistics = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    width: 80%;

`

const CollapsedState = styled.div`    
    padding: 1.2em;
    border-radius: 1em;


    ${props => props.clickable ? `
        background: white;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        cursor: pointer;    
    `:`
        cursor: default;
        background: rgba(148, 29, 21, 0.3);
        box-shadow: none;
    `}
`

const StateCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px; // Espaçamento entre os cards
    padding: 1em;

    margin-top: 2em;
`;


const ExpandedStateCard = styled.div`
    margin: 1em;

    padding: 2em;

    
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    background-color: white;
    border-radius: 2em;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    /* Altura máxima antes de começar a rolar */
    

    max-height: 500px;
    
    /* Permite rolagem vertical quando o conteúdo exceder o max-height */
    overflow-y: scroll;


    ul {
        padding: 1em 3em 1em 3em;
    }

`

const Aviso = styled.p`
    font-size: 1em;
    font-weight: bold;
    margin: 1em 0 1em 1em;
`

const CardHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    h2 {
        color: #334ec4;
        margin: 0 0 0 0.3em;
        font-size: 1.4em;
        font-weight: bold;
    }

`

const CloseButton = styled.span`
    cursor: pointer;
    padding: 4px;
    font-size: 1em;
    color: gray;
    margin: 0;
`;

const InstitutionsList = styled.div`
    padding: 1em;

`

const InstitutionData = styled.div`
    margin-bottom: 1em;

`

const InstitutionLine = styled.p`
    margin: 0 0 0em 1em;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const InstitutionTitle = styled.span`
    margin: 0 0 0 0.5em;
    font-size: 1.1em;
    font-weight: bold;
`

const InstitutionEmoji = styled.span`
    font-size: 1.3em;
`

const AmbassadorList = styled.p`
    color: grey;
    margin: -0.3em 0 0 4em;
`



export default function InstitutionsDashboard() {

    const [groupedbyState, setGrouped] = useState(false)
    const [withoutExclusivity, setWithoutExclusivity] = useState(false)
    const [expandedStates, setExpandedStates] = useState([])

    
    const [showDialog, setShowDialog] = useState(false)
    const chosenToBreak = useRef(undefined)


    const [showReestablishDialog, setShowReestablishDialog] = useState(false)
    const chosenReestablish = useRef(undefined)

    // 'all', 'institutionsWithAmbassadors', 'institutionsWithoutAmbassadors', brokenExclusivity, multipleAmbassadors
    const [activeFilter, setActiveFilter] = useState('all') 


    const estadosBrasileiros = {
        "AC": "Acre (AC)",
        "AL": "Alagoas (AL)",
        "AP": "Amapá (AP)",
        "AM": "Amazonas (AM)",
        "BA": "Bahia (BA)",
        "CE": "Ceará (CE)",
        "DF": "Distrito Federal (DF)",
        "ES": "Espírito Santo (ES)",
        "GO": "Goiás (GO)",
        "MA": "Maranhão (MA)",
        "MT": "Mato Grosso (MT)",
        "MS": "Mato Grosso do Sul (MS)",
        "MG": "Minas Gerais (MG)",
        "PA": "Pará (PA)",
        "PB": "Paraíba (PB)",
        "PR": "Paraná (PR)",
        "PE": "Pernambuco (PE)",
        "PI": "Piauí (PI)",
        "RJ": "Rio de Janeiro (RJ)",
        "RN": "Rio Grande do Norte (RN)",
        "RS": "Rio Grande do Sul (RS)",
        "RO": "Rondônia (RO)",
        "RR": "Roraima (RR)",
        "SC": "Santa Catarina (SC)",
        "SP": "São Paulo (SP)",
        "SE": "Sergipe (SE)",
        "TO": "Tocantins (TO)"
    };

    

    // Função para alternar a expansão de um estado
    const toggleStateExpansion = (state) => {
      if (expandedStates.includes(state)) {
        // Se já está expandido, removemos da lista
        setExpandedStates(expandedStates.filter(s => s !== state));
      } else {
        // Se não está expandido, adicionamos à lista
        setExpandedStates([...expandedStates, state]);
      }
    };


    useEffect(() => {
        function groupByState(universities) {
            console.log(universities)

            const grouped = {};
            Object.values(universities).forEach(university => {
                const { state, name } = university;
                if (!grouped[state]) {
                    grouped[state] = [];
                }
                grouped[state].push(name);
            });
            
            const sortedKeys = Object.keys(grouped).sort();
            const sortedGrouped = {};
            sortedKeys.forEach(key => {
                sortedGrouped[key] = grouped[key];
            });

            return sortedGrouped;
        }

        async function start() {
            const doc = await db.doc('embaixadores_dashboard/schools_without_exclusivity').get()
            const data = doc.data()?.['list']

            setWithoutExclusivity(data)
            setGrouped( groupByState(controller.institutions) )
        }

        if (controller.institutions) {
            start()
        }


    }, []);


    const filterInstitutions = (universities, filterOpt) => {
        switch (filterOpt) {
            case 'institutionsWithAmbassadors':
                return universities.filter(name => controller.institutionsWithAmbassadors.includes(name))
            
            case 'institutionsWithoutAmbassadors':
                return universities.filter(name => controller.institutionsWithoutAmbassadors.includes(name))

            case 'brokenExclusivity':
                return universities.filter(name => withoutExclusivity.includes(name))

            case 'multipleAmbassadors':
                return universities.filter(name => {
                    return controller.schoolsWithAmbassadors[name] && controller.schoolsWithAmbassadors[name].length > 1
                
                })
                
            default:
                return universities;
        }
    };


    // Será recalculado nas mudanças de estado.
    const notExpandedStates = Object.keys(groupedbyState).filter(state => !expandedStates.includes(state)).sort();
    const expandedStatesDetails = expandedStates.map(state => ({ state, universities: groupedbyState[state] }));



    function getAmbassadors(stateUniversities) {
        stateUniversities = stateUniversities.sort((a, b) => a.localeCompare(b));

        let filteredInstitutions = filterInstitutions(stateUniversities, activeFilter).sort((a, b) => a.localeCompare(b));

        // Verifica se há instituições após o filtro
        if (filteredInstitutions.length === 0) {
            if (activeFilter === 'institutionsWithAmbassadors') {
                return <Aviso>🚨 Não temos nenhuma instituição com embaixador nesse estado. </Aviso>;
            }
            else if (activeFilter === 'brokenExclusivity') {
                return <Aviso>🚨 Não temos nenhuma instituição com exclusividade quebrada nesse estado. </Aviso>;
            }
            else {
                return <Aviso>🚀 Já temos embaixadores em todas as instituições desse estado!</Aviso>
            }
        }
    
        return (
            <InstitutionsList>

                {filteredInstitutions.map(name => {
                    const hasAmbassador = controller.institutionsWithAmbassadors.includes(name);
                    const exclusivityBroken = withoutExclusivity.includes(name);

                    return (
                        <InstitutionData>
                        <InstitutionLine key={name}>
                            {/* Checa se tem embaixador */}
                            {hasAmbassador && exclusivityBroken &&
                                <InstitutionEmoji onClick = {() => confirmReestablishExclusivity(name)} style = {{cursor: 'pointer'}}>
                                    ⚠️
                                </InstitutionEmoji>
                            }
                            { hasAmbassador && !exclusivityBroken &&
                                <InstitutionEmoji onClick = {() => confirmBreakExclusivity(name)} style = {{cursor: 'pointer'}}>
                                    ✅
                                </InstitutionEmoji>
                            }
                            { !hasAmbassador && 
                                <InstitutionEmoji>
                                    🔜
                                </InstitutionEmoji>
                            }
                            
                            <InstitutionTitle>{name}</InstitutionTitle>
                        </InstitutionLine>

                            {/* Se tiver, mostra os nomes dos embaixadores */}
                            {hasAmbassador && controller.schoolsWithAmbassadors[name].length > 0 && (
                                <AmbassadorList>
                                    { controller.schoolsWithAmbassadors[name].map(uid => {
                                        return controller.registry[uid]['current_coupon']
                                    }).join(' | ')}

                                </AmbassadorList>
                            )}

                        </InstitutionData>
                    );
                })}
            </InstitutionsList>
        );
    }
    

    function confirmBreakExclusivity(institutionName) {
        // Checamos se já não está quebrada, e se de fato tem embaixador
        chosenToBreak.current = institutionName
        setShowDialog(true)
    }


    function cancelBreakExclusivity() {
        chosenToBreak.current = undefined
        setShowDialog(false)
    }


    function doBreakExclusivity() {
        if (chosenToBreak.current && chosenToBreak.current != '') {
            // Quebrar exclusividade
            db.doc('embaixadores_dashboard/schools_without_exclusivity').update({
                list: [...withoutExclusivity, chosenToBreak.current]
            }).then(() => {
                // Atualizar a lista de escolas sem exclusividade
                setWithoutExclusivity([...withoutExclusivity, chosenToBreak.current])
                setShowDialog(false)
            })
        }
        else {
            // Deveria avisar que houve um erro
            chosenToBreak.current = undefined
            setShowDialog(false)
        }
    }


    function confirmReestablishExclusivity(institutionName) {
        chosenReestablish.current = institutionName
        setShowReestablishDialog(true)
    }

    function cancelReestablishExclusivity() {
        chosenReestablish.current = undefined
        setShowReestablishDialog(false)
    }

    function doReestablishExclusivity() {
        if (chosenReestablish.current && chosenReestablish.current != '') {
            // Quebrar exclusividade
            db.doc('embaixadores_dashboard/schools_without_exclusivity').update({
                list: withoutExclusivity.filter(name => name !== chosenReestablish.current)
            }).then(() => {
                // Atualizar a lista de escolas sem exclusividade
                setWithoutExclusivity(withoutExclusivity.filter(name => name !== chosenReestablish.current))
                setShowReestablishDialog(false)
            })
        }
        else {
            // Deveria avisar que houve um erro
            chosenReestablish.current = undefined
            setShowReestablishDialog(false)
        }
    }
    

    if (!groupedbyState) {
        return (<></>)
    }

    return (
        <InstitutionsContainer>
            
            <GeneralDialog
                open={showDialog}
                title="⚠️ Quebrar a exclusividade?"
                onClose={cancelBreakExclusivity}
                actions={[
                    { label: 'Cancelar', onClick: cancelBreakExclusivity, style: 'neutral'},
                    { label: 'Quebrar', onClick: doBreakExclusivity, style: 'destructive' },
                ]}
            >
                <p>Você irá quebrar a exclusividade de <strong>{chosenToBreak.current}</strong>.</p>
            </GeneralDialog>


            <GeneralDialog
                open={showReestablishDialog}
                title="🫂 Reestabelecer a exclusividade?"
                onClose={cancelReestablishExclusivity}
                actions={[
                    { label: 'Cancelar', onClick: cancelReestablishExclusivity, style: 'neutral'},
                    { label: 'Reestabelecer', onClick: doReestablishExclusivity, style: '' },
                ]}
            >
                <p>Você irá <u>reestabelecer</u> a exclusividade de <strong>{chosenToBreak.current}</strong>.</p>
            </GeneralDialog>


        

            <InstitutionsStatistics>
                <AmbassadosStatisticCard 
                    emoji="🩺"
                    number={Object.keys(controller.institutions).length}
                    text="Faculdades de Medicina"
                    onClick={() => setActiveFilter('all')}
                    clicked={activeFilter === 'all'} 
                />

                <AmbassadosStatisticCard 
                    emoji="✅"
                    number={controller.institutionsWithAmbassadors.length}
                    text="Faculdades Convertidas"
                    onClick={() => setActiveFilter('institutionsWithAmbassadors')}
                    clicked={activeFilter === 'institutionsWithAmbassadors'} 
                />

                <AmbassadosStatisticCard 
                    emoji="🔜"
                    number={controller.institutionsWithoutAmbassadors.length}
                    text="Faculdades sem Embaixador"
                    onClick={() => setActiveFilter('institutionsWithoutAmbassadors')}
                    clicked={activeFilter === 'institutionsWithoutAmbassadors'} 
                />


                <AmbassadosStatisticCard 
                    emoji="⚠️"
                    number={withoutExclusivity.length}
                    text="Exclusividade Quebrada"
                    onClick={() => setActiveFilter('brokenExclusivity')}
                    clicked={activeFilter === 'brokenExclusivity'} 
                />

                <AmbassadosStatisticCard 
                    emoji="👥"
                    number={Object.values(controller.schoolsWithAmbassadors).reduce((acc, list) => {
                        if (list.length > 1) {
                            acc += 1;
                        }
                        return acc; // Retorna o valor acumulado para a próxima iteração
                    }, 0)}
                    text="Múltiplos Embaixadores"
                    onClick={() => setActiveFilter('multipleAmbassadors')}
                    clicked={activeFilter === 'multipleAmbassadors'} 
                />


            </InstitutionsStatistics>


        {/* Estados não expandidos mostrados horizontalmente com wrap */}
        <StateCardContainer>
            {notExpandedStates.map(state => {
                
                if (filterInstitutions(groupedbyState[state], activeFilter).length == 0) {
                    return (
                        <CollapsedState key={state} clickable = {false} >
                            {state}
                        </CollapsedState>
                    )
                }
                else {
                    return (
                        <CollapsedState key={state} clickable = {true} onClick={() => toggleStateExpansion(state)}>
                            {state}
                        </CollapsedState>
                    )
                }                
            })}
        </StateCardContainer>


        <StateCardContainer>
            {/* Colocamos os estados expandidos em ordem alfabética */}
            { expandedStatesDetails.length == 0 && 
                <Aviso style = {{padding: '5em 0 5em 0', fontSize: '1.2em'}}>Selecione um ou mais estados! 🚀</Aviso>
            }
            {expandedStatesDetails.sort((a, b) => a.state.localeCompare(b.state)).map(({ state, universities }) => (
                <ExpandedStateCard key={state} >

                    <CardHeader>
                        <CloseButton onClick={(e) => {
                            // e.stopPropagation(); // Impede que o clique propague e reabra o estado
                            setExpandedStates(expandedStates.filter(s => s !== state));
                        }}>X</CloseButton>
                        <h2>{estadosBrasileiros[state]}</h2>
                    </CardHeader>
                    
                    { getAmbassadors(universities) }

                </ExpandedStateCard>
            ))}
        </StateCardContainer>
        

        </InstitutionsContainer>
    );
}
