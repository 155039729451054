// Novembro 2024
import { db } from '../firebase/firebase-setup'
import Flashcard from '../models/Flashcard'
import MultipleChoiceQuestion from '../models/MultipleChoiceQuestion'
import { getLastSessionPath } from '../utils/SessionUtils'
import { isClozeCard, shuffleArray, sleep } from './../utils/Utils'
import LikedBuriedController from './LikedBuriedController'
import OslerData from './OslerData'
import SessionBuilder from './SessionBuilder'
import { TEST_TYPES } from './SessionConfig'
import UserStatisticsManager from './UserStatisticsManager'


export const KEYS = {
    notDownloaded : 'not-downloaded',
    downloading : 'downloading',
    downloadFailed : 'download-failed',
    downloaded: 'downloaded'
}

class Session {
    constructor() {
        this.created = false;
    }

    start(userID, testType, listIDs, shouldSaveAsLastSession, mode, downloadStatistics) { 
        console.log(`Session >> start(): starting...`)
        this.userID = userID
        this.testType = testType

        // IDs dos testes que serão baixados
        console.log(listIDs)
        
        this.listIDs = listIDs
        this.sessionSize = listIDs.length

        // Se deveríamos, ao fim da sessão, salvar como "lastSession",
        // que é exibido na tela inicial.
        this.shouldUpdateLastSession = shouldSaveAsLastSession


        // Array com os Tests(), cujo conteúdo ainda será baixado
        this.session = undefined;
        

        // Variáveis quanto à manipulação dos testes, importante fora
        // do modo consulta.
        this.currentIndex = 0
        this.endTime = 0
        this.mcqChosenAnswer = -1



        this.startTime  = 0
        this.testStartTime = -1



        // Se true, significa que o usuário deu um ctrl+z para o teste
        // sendo exposto, e não permitiremos que ele volte ainda mais.
        this.ctrlZ = false;


        // Flag para guardar se o usuário já enterrou alguma questão durante a sessão.
        this.buriedAnyQuestion = false


        // Se enterramos, mantemos o teste até o usuário responder o próximo,
        // permitindo que ele dê CTRL+Z e desfaça o bury.
        this.lastBuriedTest = false


        this.recentlyAnswered = false

        // O número de burieds é mensurado de modo a controlar, de modo robusto,
        // se devemos mostrar o diálogo de confirmação ou não.
        this.numberBuried = 0;


        // Guarda informações da sessão do usuário
        this.log = []
        this.statistics = new UserStatisticsManager(testType, userID)


        // Permite o desfazer
        this.actionStack = []


        // Lista de listeners que evocamos a cada modificação de Session.
        // Os temporários são chamados após a próxima modificação da Session e removidos. Os permanentes são
        // chamados após todas as modificações.
        //
        // Modificações que invocam listener:
        //      - Baixar mais testes
        //      - Teste foi respondido
        //      - Estatística foi registrada
        //      - Teste foi pulado
        //      - Teste foi enterrado
        //      - Usuário mudou de teste
        this.temporaryListeners = []
        this.permanentListeners = []

        
        // Não baixamos todos os testes de uma vez, mas em chunks
        // de até este tamanho, conforme o usuário avança na session.
        this.chunkSize = 10


        // Listas de testes que devem ser atualizadas após cada resposta;
        this.customLists = []


        this.lastTestAnsweredIndex = false

        // No modo consulta, se flashcards, precisamos remover
        // os cousins de uma mesma família.
        this.mode = mode
        if (testType === 'Flashcards' && (mode == 'consult-mode')) {
            this.clozeFusion()
        }

        this.createSession()
        this.created = true

        this.downloadStatistics = downloadStatistics



        // Se é u msimuldo
        this.isMockTest = false


        this.lastAnsweredIndex = -1
    }




    // REVISADAS 2024
    async createSession() {
        /*
            Para cada teste, criamos uma estrutura de dado que informa:
                - Seu ID
                - Se já foi baixado ou não
                - Os dados do teste, conforme baixados do Firebase
                - A resposta dada pelo usuário
        */
        console.log(`Session >> createSession()...`)
        this.session = []
        for (let ID of this.listIDs) {
            this.session.push({
                'testID' : ID,
                'data' : undefined,
                'status' : KEYS.notDownloaded,
                'userAnswer' : undefined
            })
        }

        // Carregamos os primeiros testes da sessão.
        await this.ensureNextTests()
    }


    async ensureNextTests(startingIndex = this.currentIndex, chunk = this.chunkSize) {
        // Garante que temos os próximos testes prontos -- de currentIndex até currentIndex + chunkSize.
        // Se houver problemas durante o download, já lida com isso.
        console.log(`Session >> ensureNextTests(): ensuring next ${chunk} from ${startingIndex} onwards...`)
        await this.downloadNextTests(startingIndex, startingIndex + chunk)
        this.callListeners()
    }


    async downloadNextTests(startingIndex = this.currentIndex, untilIndex) {
        console.log(`Session >> downloadNextTests(): downloading tests from ${startingIndex} to ${untilIndex}`)

        const maxIndex = Math.min(untilIndex, this.session.length)

        const pendingTests = []
        for (let i = startingIndex; i < maxIndex; i++) {
            if (this.session[i].status === KEYS.notDownloaded) {
                this.session[i].status = KEYS.downloading
                pendingTests.push({
                    index: i,
                    testID: this.session[i].testID
                })
            }
        }
    
        if (pendingTests.length === 0) {
            console.log(`Session >> downloadNextTests(): no tests to download!`)
            return
        }
    
        console.log(`Session >> downloadNextTests(): will download ${pendingTests.length} test(s)`)
        await Promise.all(
            pendingTests.map(async ({index, testID}) => {
                try {
                    const doc = await db.collection(this.testType).doc(testID).get()
                    
                    if (!doc.exists) {
                        console.log(`\t\t * ERROR - ${testID} downloading from Firebase failed`)
                        this.session[index].status = KEYS.downloadFailed
                        return
                    }
    
                    const test = this.testFromDoc(doc)
                    await test.loadData(this.statistics.testStatistics, this.downloadStatistics)
                    await test.loadPersonalNotes()

                    console.log(`\t * ${testID} - ok!`)
    
                    // Atualiza sessão se o teste ainda existe
                    const i = this.findTestPosition(testID)
                    if (i >= 0) {
                        this.session[i].data = test
                        this.session[i].status = KEYS.downloaded
                    }
    
                } catch (error) {
                    console.log(`\t\t * ERROR - ${testID} - loading test data or statistics or notes failed`)
                    this.session[index].status = KEYS.downloadFailed
                    return
                }
            })
        )
    }


    testFromDoc(doc) {
        const testTypes = {
            "Flashcards" : Flashcard,
            "Residencia" : MultipleChoiceQuestion
        }
        
        const TestClass = testTypes[this.testType]
        return new TestClass(doc.id, doc.data())
    }


    findTestPosition(testID) {
        return this.session.findIndex(test => test.testID === testID)
    }


    addTemporaryListener(callback) {
        this.temporaryListeners.push(callback)
    }
    

    addPermanentListener(callback) {
        this.permanentListeners.push(callback)
    }
    

    callListeners() {
        console.log(`Session >> callListeners():
            ${this.temporaryListeners.length} temporary, ${this.permanentListeners.length} permanent`)
    
        // Copiamos o array atual para permitir que callbacks registrem novos listeners
        const currentTemporary = [...this.temporaryListeners]
        this.temporaryListeners = []
        currentTemporary.forEach(callback => callback())
    
        this.permanentListeners.forEach(callback => callback())
    }


    canMoveToTest(targetIndex) {
        if (targetIndex < 0 || targetIndex >= this.session.length) {
            return false
        }

        if (this.testType === TEST_TYPES.FLASHCARDS && this.mode === 'test-mode') {
            console.log(`Session >> canMoveToTest(): checking if we can move to test index = ${targetIndex}`)
            console.log(`\tLast answered index = ${this.lastAnsweredIndex}`)

            // 
            if (targetIndex > this.lastAnsweredIndex + 1) {
                // Se é a primeira resposta (currentIndex = 0 e targetIndex = 1), teremos
                // this.lastAnsweredIndex = 0...
                return false
            }
        }

        return true
    }


    async navigateToTest(targetIndex) {
        // Tenta navegar para um teste específico. Avaliamos se é permissível. Se sim, garantimos
        // que os testes necessários estão baixados.
        console.log(`Session >> navigateToTest(): will try to move to test index = ${targetIndex}`)

        if (!this.canMoveToTest(targetIndex)) {
            console.log(`Session >> navigateToTest(): invalid request`)
            return false
        }
        else {
            console.log(`Session >> navigateToTest(): moving to test index = ${targetIndex}`)

            // Colocar antes do await() é, de certo modo, um risco, mas em teoria nem carrega o teste
            // atual sem ter o próximo chunk e a question screen deveria ser robusta
            this.currentIndex = targetIndex
            this.callListeners()


            await this.ensureNextTests(targetIndex)
            return true
        }
    }


    canMovetoNext() {
        return this.canMoveToTest(this.currentIndex + 1)
    }


    async moveToNext() {
        await this.navigateToTest(this.currentIndex + 1)
    }


    canMoveToPrevious() {
        return this.canMoveToTest(this.currentIndex - 1)
    }

    async moveToPrevious() {
        await this.navigateToTest(this.currentIndex - 1)
    }


    logUserAnswer(feedback) {
        // Registra a resposta do usuário, junto de tempo gasto e outras métricas relevantes.
        // feedback pode ser ou o levelOfSuccess do card ou o metacognition das questões
        const i = this.currentIndex
        const ID = this.session[i].testID
        console.log(`Session >> logUserAnswer(): logging answer for test ${i} (${ID})`)
        
        if (this.mode !== 'test-mode') {
            console.log('Session >> logUserAnswer(): ERROR - not in test mode')
            return
        }

        const test = this.session[this.currentIndex].data
        if (!test) {
            console.log('Session >> logUserAnswer(): ERROR - test does not exist')
            return
        }

        const endTime = Date.now()
        const timeSpent = (endTime - this.testStartTime) / 1000.0
        const roundedTimeSpent = Math.round(timeSpent)


        let data = {}
        if (this.testType === TEST_TYPES.FLASHCARDS) {
            this.lastAnsweredIndex = this.currentIndex

            data = {
                'test': test,
                'index': this.currentIndex,
                'levelOfSuccess': feedback,
                'time': roundedTimeSpent,
                'submittedToFirebase': false
            }
        }
        else {
            data = {
                'test': test,
                'index': this.currentIndex,
                'metacognition': feedback,
                'chosenAnswer': this.mcqChosenAnswer,
                'time': roundedTimeSpent,
                'submittedToFirebase': false
            }
        }

        this.log.push(data)

        this.addActionToStack({
            type: 'answered',
            data: {
                index: this.currentIndex,
                log: data
            }
        })

        
        if (!this.isMockTest) {
            this.updateStatistics()
            OslerData.signalStatusChange()
        }
        
        console.log(`Session >> logUserAnswer(): log was success for test ${i} (${ID})`) 
    }


    async updateStatistics(breakAfterFirst = true) {
        console.log('Session >> updateStatistics(): looking for last answered test')

        if (breakAfterFirst) {
            // Caso normal: enviamos apenas o teste N-2 quando adicionamos em N
            // No modo normal (não-simulado), envia as estatísticas do penúltimo teste respondido -- de modo que o usuário
            // não pode modificar.
            if (this.log.length < 2) {
                console.log('\tNão há testes prontos para enviar ainda (length < 3)')
                return
            }
    
            const testToUpdate = this.log[this.log.length - 2]
        
            if (testToUpdate && !testToUpdate.submittedToFirebase) {
                await this.updateStatisticsForTest(testToUpdate.index)
            }
        }
        else {
            // Caso mock test: enviamos todas as estatísticas pendentes
            console.log('\tEnviando todas as estatísticas pendentes...')
            const pendingUpdates = []
        
            for (let i = 0; i < this.log.length; i++) {
                const test = this.log[i]
                if (test && !test.submittedToFirebase) {
                    console.log(`\tEncontramos estatística pendente do teste ${i}`)
                    pendingUpdates.push(this.updateStatisticsForTest(test.index))
                }
            }
    
            if (pendingUpdates.length > 0) {
                console.log(`\tEnviando ${pendingUpdates.length} estatísticas em paralelo`)
                await Promise.all(pendingUpdates)
            }
        }
    }
    

    async updateAllPendingStatistics() {
        await this.updateStatistics(false)
    }
    

    async updateStatisticsForTest(questionIndex) {
        // Atualiza as estatísticas para a questão com index questionIndex
        console.log(`Session >> updateStatisticsForTest(): registering on Firebase statistics for test ${questionIndex}`)

        // Se estiver em modo simulado ou não for modo teste, não atualiza
        if (this.mode !== 'test-mode') {
            console.log('\t\tSession >> updateStatisticsForTest(): ERROR? - skipping, its a non-test mode')
            return
        }


        const testID = this.session[questionIndex].testID
        const logIndex = this.log.findIndex(entry => entry.test.testID === testID)
        
        if (logIndex === -1) {
            console.log('Session >> updateStatisticsForTest(): ERROR - no log found')
            return
        }

        try {
            // Usa o index correto do log para marcar como enviado
            this.log[logIndex].submittedToFirebase = true
            const questionToLog = this.log[logIndex]

            if (this.testType === TEST_TYPES.FLASHCARDS) {
                await this.statistics.updateStatisticsAfterAnswer(
                    questionToLog.test, 
                    questionToLog.levelOfSuccess,
                    questionToLog.time
                )
            }
            else {
                await this.statistics.updateStatisticsAfterAnswer(
                    questionToLog.test, 
                    questionToLog.metacognition,
                    questionToLog.time,
                    questionToLog.chosenAnswer,
                    this.customLists
                )
            }
            console.log(`Session >> updateStatisticsForTest(): statistics updated successfully`)
        } catch (error) {
            console.log(`Session >> updateStatisticsForTest(): ERROR - failed to update statistics`, error)
        }
    }


    async logWholeMockTest() {
        await this.updateStatistics(false)
    }



    addActionToStack(action) {
        // Atualmente, nós sempre colocamos no inicio do array, porque a ideia é que só
        // seja possível desfazer UMA ação.
        this.actionStack = [
            action
        ]
    }

    canUndo() {
        return this.actionStack.length > 0
    }


    async undoLastAction() {
        // TODO Não estamos usando lastAction.data, mas talvez fosse útil
        const lastAction = this.actionStack.pop()
        if (!lastAction) return

        switch(lastAction.type) {
            case 'buried':
                this.restoreBuried()
                break
            case 'answered':
                await this.undoAnswer()
                break
            case 'moved':
                this.restoreEndQueue()
                break
        }
    }


    async undoAnswer() {
        const lastAnsweredIndex = this.log.length - 1
        this.log.pop()
        await this.navigateToTest(lastAnsweredIndex)
    }


    async buryCurrentTest() {
        console.log('Session >> buryCurrentTest(): burying test at ', this.currentIndex)
        console.log('Before: ', this.session)
     
        // 1. Remove do array e guarda backup
        const buriedID = this.listIDs.splice(this.currentIndex, 1)[0]
        const buriedTest = this.session.splice(this.currentIndex, 1)[0]
        
        this.lastBuriedTest = { ID: buriedID, data: buriedTest }
        this.numberBuried += 1
        this.sessionSize -= 1 

        this.addActionToStack({
            type: 'buried',
            data: {
                ID: buriedID,
                test: buriedTest
            }
        })
        
        // Já chama listeners!
        this.callListeners()

        console.log('After: ', this.session)
        await this.ensureNextTests()
    }


    restoreBuried() {
        // São duas ações principais.
        // Primeiro, precisamos inserir o teste de volta nos array.
        this.listIDs.splice(this.currentIndex, 0, this.lastBuriedTest['ID'])
        this.session.splice(this.currentIndex, 0, this.lastBuriedTest['data']  )

        this.sessionSize += 1

        // Segundo, precisamos desfazer o bury a nível de Firebase e etc.
        LikedBuriedController.buryOrUnbury(this.testType, this.lastBuriedTest['ID'])

        this.numberBuried -= 1
        this.lastBuriedTest = undefined
        this.callListeners()
    }


    async moveCurrentTestToEndOfQueue() {
        if (this.isLastTest()) {
            console.log('Session >> moveCurrentTestToEndOfQueue(): already at last position')
            return
        }
    
    
        const movedTest = this.session[this.currentIndex]

        // Move o teste atual para o final
        this.session = [
            ...this.session.slice(0, this.currentIndex),
            ...this.session.slice(this.currentIndex + 1),
            movedTest
        ]
    
        this.addActionToStack({
            type: 'moved',
            data: {
                test: movedTest,
                index: this.currentIndex
            }
        })
        

        // Chamamos callListeners antes, especialmente porque a função do download pode demorar.
        this.callListeners()
        await this.ensureNextTests()
    }


    restoreEndQueue() {     
        // Pega o último e insere antes do atual
        const lastTest = this.session.pop()
        this.session.splice(this.currentIndex, 0, lastTest)
        this.callListeners()
    }


    currentTestValid() {
        const test = this.session[this.currentIndex].data
        return test ? true : false
    }


    // getQuestion() {
    //     if (this.currentTestValid()) {
    //         console.log(`Session >> getQuestion() -- returning data for test ${this.currentIndex}`)
    //         const test = this.session[this.currentIndex].data
    //         return test.getQuestion()
    //     }
    //     else {
    //         // Teoricamente isso não ocorre mais. Mas está aqui como legacy/robustez.
    //         // Se o documento não existe, nós já removemos o test. Se o documento não é baixado
    //         // (e.g., falha na conexão)... acredito que isso sequer ocorra, pois a biblioteca
    //         // do Firebase deve persistir até o download ocorrer.
    //         console.log("Session >> getQuestion(): ERRO, tentamos acessar teste que não foi existe")
    //         console.log(this.session)
    //         return `Erro grave ao obter a pergunta. Por favor, tire um print e envie na DM! (${this.session[this.currentIndex].testID} / ${this.currentIndex})`
    //     }
    // }


    // getAnswer(...chosenAnswer) {
    //     const test = this.session[this.currentIndex].data

    //     if (test) {
    //         return test.getAnswer(...chosenAnswer)
    //     }
    //     else {
    //         return `Erro grave ao obter a resposta. Por favor, tire um print e envie na DM! (${this.session[this.currentIndex].testID})`
    //     }
    // }

    
    isLastTest() {
        // Verifica se é o último teste da sessão
        return this.currentIndex === this.session.length - 1
    }


    checkTestsWereAnswered(tests) {
        if (!this.log) {
            return false
        }
    
        // Cria Set com IDs dos testes respondidos para busca O(1)
        const answeredIDs = new Set(this.log.map(entry => entry.test.testID))
        
        // Testa se todos os testes fornecidos foram respondidos ou falharam
        return tests.every(test => 
            test.status === KEYS.downloadFailed || answeredIDs.has(test.testID)
        )
    }
    
    areAllTestsAnswered() {
        if (this.log.length < this.session.length) {
            return false
        }
        
        return this.checkTestsWereAnswered(this.session)
    }
    

    isTestAnswered(targetIndex = this.currentIndex) {
        console.log(`Session >> isTestAnswered(): checking if test ${targetIndex} was answered`)

        if (targetIndex < 0 || targetIndex >= this.session.length) {
            return false
        }
        return this.checkTestsWereAnswered([this.session[targetIndex]])
    }


    getNumberOfTests() {
        return this.sessionSize
    }

    
    getCurrentIndex() {
        return this.currentIndex
    }


    getCurrentTest() {
        return this.session[this.currentIndex].data
    }


    getCurrentTestID() {
        return this.session[this.currentIndex].testID
    }


    getTimeUntilNextReview() {
        const test = this.session[this.currentIndex].data

        if (test) {
            const readable = this.statistics.testStatistics.readableTimeUntilNextReview(test.statistics)
            return readable
        }
        else {
            return [0, 0, 0, 0]
        }
    }
    

    async waitForAllStatisticsToBeUpdated() {
        // No modo test-mode, aguarda até que todas as estatísticas dos testes respondidos 
        // tenham sido enviadas ao Firebase
        console.log('Session >> waitForAllStatisticsToBeUpdated(): starting check...')
        
        if (this.mode !== 'test-mode') {
            console.log('\tSession >> waitForAllStatisticsToBeUpdated(): not in test mode, skipping')
            return
        }


        while (this.log.some(test => !test.submittedToFirebase)) {
            console.log('\tWaiting 100ms for pending tests to be submitted to Firebase')
            console.log(this.log)
            await sleep(100)
        }
    
        console.log('Session >> waitForAllStatisticsToBeUpdated(): all tests submitted to Firebase')
    }


    currentTestIsReady() {        
        return this.session[this.currentIndex].status === KEYS.downloaded
    }


    isDownloading(checkUntil = this.session.length) {
        // Verifica se algum teste até checkUntil está sendo baixado
        const targetIndex = Math.min(checkUntil, this.session.length)
        return this.session.slice(0, targetIndex).some(test => test.status === KEYS.downloading)
    }


    clozeFusion() {
        // No modo consulta, agrupa cards cloze da mesma família em um único card
        // mostrando todas as lacunas de uma vez
        console.log('Session >> clozeFusion(): starting...')
        
        if (this.mode !== 'consult-mode' || this.testType !== 'Flashcards') {
            return
        }
    
        // Agrupa cards por família
        const familyGroups = new Map()
        const sortedIDs = SessionBuilder.sortIDs(this.testType, this.listIDs)
    
        sortedIDs.forEach(id => {
            if (!isClozeCard(id)) return
            
            const family = SessionBuilder.getCardFamily(id)
            if (!familyGroups.has(family)) {
                familyGroups.set(family, [])
            }
            familyGroups.get(family).push(id)
        })
    
        // Para cada família, mantém apenas o primeiro card 
        const finalIDs = sortedIDs.filter(id => {
            if (!isClozeCard(id)) return true
            
            const family = SessionBuilder.getCardFamily(id)
            const familyCards = familyGroups.get(family)
            return id === familyCards[0]  // mantém apenas o primeiro
        })
    
        console.log(`Session >> clozeFusion(): reduced from ${sortedIDs.length} to ${finalIDs.length} cards`)
        this.listIDs = finalIDs
    
        // TODO: Quando baixarmos cada card mantido, modificar seu conteúdo
        // para incluir as lacunas dos cards removidos
    }


    addList(list) {
        this.customLists.push(list)
    }


    startMeasuringTime() {
        this.testStartTime = Date.now()
    }


    // Única que relamente precisa ser refeita
    async updateLastSession() {
        if(this.shouldUpdateLastSession) {
            
            let remainingIDs = []
            if (this.currentIndex < this.session.length) {
                remainingIDs = this.session.slice(this.currentIndex).map(x => x.testID)
            }
    
            let obj = {}
            obj[this.testType] = remainingIDs
            
            await db.doc(getLastSessionPath(this.userID)).set(obj, {merge: true})
        }
    }
}


export default new Session()