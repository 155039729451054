import React, { useEffect, useState } from 'react'

import './RadioButton.css'
import Default from './../assets/radio-button.png'
import Clicked from './../assets/radio-button-clicked.png'
import Disabled from './../assets/radio-button-disabled.png'
import ClickedDark from './../assets/radio-button-clicked-dark.png'
import { useSelector } from 'react-redux';

export default function RadioButton(props) {

    const darkMode = useSelector(state => state.theme.darkModeOn);

    const [clicked, setClicked] = useState(props.clicked)

    useEffect(() => {
        setClicked(props.clicked)
    }, [props.clicked])


    function signalClicked() {
        if (!props.disabled) {
            setClicked(!clicked)
            props.action()
        }
    }

    return (
        <div className = 'RadioBttn-Container' onClick = {signalClicked} style = {props.style}>
            { !props.disabled &&
                <img className = 'RadioBttn-Icon' src = {clicked ? (darkMode ? ClickedDark : Clicked) : Default} />
            }
            { props.disabled && 
             <img className = 'RadioBttn-Icon' src = {Disabled} />
            }
            <p className = 'RadioBttn-Title'>{props.text}</p>
        </div>
    )
}