import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../firebase/firebase-setup';
import { collection, getDocs } from 'firebase/firestore';

const UsoClinicoList = () => {
    const [usosClinicos, setUsosClinicos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [copyMessage, setCopyMessage] = useState('');
    const [dropdownOpen, setDropdownOpen] = useState(null);

    useEffect(() => {
        const fetchUsosClinicos = async () => {
            const querySnapshot = await getDocs(collection(db, "prescricoes"));
            const usos = [];
            querySnapshot.forEach((doc) => {
                usos.push({ id: doc.id, ...doc.data() });
            });
            setUsosClinicos(usos);
        };

        fetchUsosClinicos();
    }, []);

    const filteredUsosClinicos = usosClinicos.filter(uso =>
        uso.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const copyToClipboard = (content, setMessage) => {
        navigator.clipboard.writeText(content).then(() => {
            setMessage('Prescrição copiada!');
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }).catch((err) => {
            setMessage('Falha ao copiar o conteúdo');
            setTimeout(() => {
                setMessage('');
            }, 2000);
        });
    };

    const getMedText = (med) => {
        const usagesText = med.usage ? med.usage.join('\n') : '';
        return `${med.med}\n${usagesText}`;
    };

    const getDisplayMedName = (medName) => {
        const match = medName.match(/\.\s*(.*?)\s*_/);
        return match ? match[1] : medName;
    };

    const toggleDropdown = (index) => {
        setDropdownOpen(dropdownOpen === index ? null : index); // Alterna entre abrir e fechar o dropdown
    };

    return (
        <div className="usoclinico-body">
            <Link to="/bedside" className="button-voltar-bulario">&lt;</Link>
            <h1 className="usoclinico-title">Patologias</h1>
            <input
                type="text"
                placeholder="Buscar uso clínico..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="search-input"
            />
            <ul className="usoclinico-list">
                {filteredUsosClinicos.map((usoClinico, index) => (
                    <li className="usoclinico-item" key={usoClinico.id}>
                        <details>
                            <summary onClick={() => toggleDropdown(index)} className="usoclinico-dropdown-title">
                                <span>{usoClinico.title}</span>
                                <i className={`fi ${dropdownOpen === index ? 'fi-ss-angle-small-up' : 'fi-ss-angle-small-down'}`}></i>
                            </summary>
                            <div className="usoclinico-details">
                                <ul>
                                    {usoClinico.medications && usoClinico.medications.map((med, medIndex) => (
                                        <li key={medIndex} className="usoclinico-med-item">
                                            <details>
                                                <summary className="medicamento-nome"><strong>{getDisplayMedName(med.med)}</strong></summary>
                                                <ul>
                                                    <li className='medicamento-dose'>{med.med}</li>
                                                    {med.usage && med.usage.map((usage, idx) => (
                                                        <li key={idx} className="usoclinico-usage-item">{usage}</li>
                                                    ))}
                                                </ul>
                                                <button onClick={() => copyToClipboard(getMedText(med), setCopyMessage)} className="copy-button">
                                                    Copiar
                                                </button>
                                                {copyMessage && <div className="copy-message">{copyMessage}</div>}

                                            </details>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </details>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default UsoClinicoList;