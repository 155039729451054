import React, { useEffect, useState, memo } from 'react'
import { useNavigate, useLocation } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import styled, { css, keyframes } from 'styled-components'
import NoteIcon from './../assets/personal_note.png'
import Logo from './../assets/Logo.png'
import LogoDark from './../assets/LogoDark.png'
import Signature from './../assets/signature.png'
import SignatureDark from './../assets/signatureDark.png'
import Setup from './../assets/settings.png'
import Stetho from './../assets/stethoscope.png'
import Home from './../assets/home.png'
import Graph from './../assets/analytics.png'
import Flashcards from './../assets/flashcard.png'
import Residencia from './../assets/residencia.png'
import KeyIcon from './../assets/extensivo.png'
import DarkModeToggle from './DarkModeToggle'
import Moon from './../assets/lua.png'
import Sun from './../assets/sol.png'
import { setShowSidebar } from '../redux/notebookSlice'
import Bedside from './../assets/bedside.png'
import Trilha from './../assets/trilha.png'
import { AnimatePresence, motion } from 'framer-motion'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { dark } from '@mui/material/styles/createPalette'



/*
    Parece que, para termos as animações com qualidade e robustez,
    precisamos usar posições absolutas e, similarmente, dimensões também
    -- sem flexbox.

    Tentei deixar tudo condicionado em variáveis claras aqui no topo,
    para permitir alterações rápidas.

*/

// Dimensões da barra
const collapsedWidth = 70
const expandedWidth = 170


// Colapsado:
//      padding | BORDA | margin | ÍCONE | margin | padding
//
// Expandido:
//      padding | BORDA | margin | ÍCONE | margin | LABEL | padding
//  

// Quando colapsada, imagine que o ícone está dentro de um background/borda (com uma margem)
// e ao redor dessa borda ainda há um espaço separando da barra.
//
//      Fizemos alguns testes, para paddingLeft = 6 ou = 9 eram extremos
//      e margin = 10 ou = 13 também

const paddingLeft = 7.5
const margin = 11.5

// Tamanho do ícone (não varia se colapsado ou expandido)
const iconWidth = (collapsedWidth - paddingLeft * 2 - margin * 2)

// Quanto deve medir todo o label?
// E onde deve ser posicionado?
const labelWidth = (expandedWidth - paddingLeft * 2 - iconWidth - margin*2)
const labelLeft  = paddingLeft + margin + iconWidth + margin

// 
// Espaço entre o ícone e o resto ("label"), e a dimensão deste 
const marginLabel = 5

// É usado no CSS (para altura) e no FramerMotion (para largura e animação)
const innerWidthExpanded = (expandedWidth - paddingLeft * 2)
const innerWidthCollapsed = (collapsedWidth - paddingLeft * 2)

             
// Qual deveria ser a posição do toggle do DarkMode?
// Olhando o compoenente <DarkModeToggle>, a width dele é 60.
// Logo...
//      widthLabel = widthToggle + paddingToggle * 2
const paddingToggle = (labelWidth - 60) / 2

// Mas qual é a posição dele em si?
// (padding) || [BORDA] || (margin) || [ÍCONE] || (margin) || (paddingToggle)
const toggleLeft = paddingLeft + margin + iconWidth + margin + paddingToggle


// OUTROS
// Fonte do texto
const fontSize = 14

// É a separação entre os botões. Está zerado, mas visualmente não é zero
// de fato, porque o que dá a impressão de separação é o padding do Inner
// (variável "margin" acima!)
const gapBttns = 0


const Container = styled(motion.div)`
    ${ColumnCSS}

    // Sem isso daqui, o Flexbox pode, em uma grande filha da putagem,
    // restringir a width determinada programaticamente pelo framer-motion
    flex-shrink: 0;

    // CRUCIAL para que as coisas não aparecam para fora durante a animação,
    // e sejam reveladas aos poucos
    overflow: hidden;

    padding: 1em 0 0 0;

    // Referência para posicionamento dos elementos abaixo.
    position: relative;
    
    background-color: ${props => props.theme.darkMode ? 'rgba(60, 60, 60, 0.9)' : 'rgba(255, 255, 255, 0.98)'};

    box-shadow: 0 0 20px rgba(0, 0, 0, 0.06);


    // A width é determinada pelo framer-motion, não adianta colocar aqui!
`



const Item = styled.div`
    // Corresponde a uma das linhas do <Container>
    // A estrutura é:
    //
    //  <Container>
    //      <Item>
    //          <ImageDiv>
    //              <Imagem>
    //          </ImageDiv>
    //          <LabelDiv>  
    //              (variável)
    //          </LabelDiv>
    //      </Item>
    //  </Container>
    ${RowCSS}    

    // Se não tiver uma altura fixa, os itens se sobrepõe.
    // Por definição, fazemos da largura da barra colapsada,
    // para que seja um quadrado.
    height: ${collapsedWidth}px;
    
    // Provavelmente à toa, mas só para forçar...
    width: 100%;    

`


const ImageDiv = styled(motion.div)`
    // Facilita o design e o debug
    ${RowCSS}
    justify-content: center;


    height: ${collapsedWidth}px;

    left: ${paddingLeft + margin}px;
    width: ${iconWidth + margin*2}px;
`


const Image = styled(motion.img)`  
    width: ${iconWidth}px;
    left: ${paddingLeft + margin}px;    
`



const Inner = styled(motion.div)`
    // Está dentro de Item/ButtonContainer, que ocupa *toda* a extensão, e contém
    // não só os ícones e o texto, mas também a estrutura de padding que permite o botão.
    ${RowCSS}    

    // ATENÇÃO. Não adianta definir width ou background-color aqui, isso está sendo determinado
    // pelo framer-motion. Aqui, simplesmente será ignorado.
    height: ${innerWidthCollapsed}px;


    // Ele está começando em cima do <Item>, precisamos
    // desviar para termos o padding e, assim, o background
    width: 100%;
    margin: ${paddingLeft}px;    

    border-radius: 0.5em;


    ${props => props.selected && css`
        border: 1px solid rgba(10, 132, 255, 0.12);   
    `}


    // Se, e somente se, for clicável.
    ${props => props.clickable && css`
        cursor: pointer;  
    `}

    
`


const Label = styled(motion.div)`
    ${RowCSS}

    // Obviamente
    // Isso é *fundamental* para o elemento Label não deslocar a imagem
    // à esquerda durante a animação.
    position: absolute;
    width: ${labelWidth}px;
    left: ${labelLeft}px;

    padding-left: ${props => props.paddingLeft}px;


    height: ${innerWidthCollapsed}px;

    // Não é muito útil, mas facilita o design
    // background: green;
    // border: 0.5px solid red;
`


const SignatureIcon = styled(motion.img)`  
    width: 100%;
`


const Text = styled.p`
color: ${props => props.theme.darkMode
    ? props.selected
        ? '#60a5fa' // Cor azul clara para texto selecionado no modo escuro
        : 'rgb(220, 220, 220)' // Cor cinza clara para texto não selecionado no modo escuro
    : props.selected
        ? '#1672f6' 
        : 'black' 
    };
    padding-left: ${marginLabel}px;
    margin: 0;
    font-size: ${fontSize}px;


    width: 100%;
    left: ${props => labelLeft + props.paddingLeft}px;

    // Um botão com texto maior -- "Caderno de Erros" ou similar -- 
    // pode quebrar linha variavelmente conforme a width aumenta. 
    // Esse comando é crucial para não quebrar.
    //
    // (mas optei por querer que quebre)
    // white-space: nowrap;
    

    ${props => props.selected && css`
        font-weight: bold;    
    `}
`



function LabelImage({ 
        selected, isHovered, expanded,
        imageSrc,
        labelStyle, labelChildren}) {
    
    return (
<>
                <ImageDiv>
                    <Image
                        src = {imageSrc}
                        initial = {false}
                        animate={{
                            rotate: selected ? 15 : isHovered ? 10 : 0,
                            // Opcional: podemos adicionar um leve scale também
                            scale: selected || isHovered ? 1.1 : 1,
                        }}
                        transition={{
                            type: "spring",
                            stiffness: 250,
                            damping: 25,
                        }} />

                        

                </ImageDiv>

                <AnimatePresence>
                    {expanded && (
                        <Label
                            /* Vide o comentário no StyledComponent. É fundamental. */
                            style = {labelStyle}

                            /* O intial é FUDANMENTAL para que a animação não ocorra 
                            a cada clique da barra, "começando do zero", após o usuário
                            interagir com outros botões. */
                            initial={false}
                            animate={{
                                opacity: 1,
                                clipPath: "inset(0 0% 0 0)"
                            }}
                            exit={{
                                opacity: 0,
                                clipPath: "inset(0 100% 0 0)"
                            }}
                            transition={{
                                type: "tween",
                                duration: 0.4,
                                ease: "easeOut"
                            }} >

                            {labelChildren}
                        </Label>
                    )}
                </AnimatePresence>
    </>
    )
}



function SideBar() {
    let navigate = useNavigate()
    const path = useLocation().pathname
    const user = useSelector(state => state.user.data)
    const darkMode = useSelector(state => state.theme.darkModeOn)

    const dispatch = useDispatch()

    const expanded = useSelector(state => state.notebook.showSidebar)
    // const [animate, setAnimate] = useState(!expanded)

    const bttns = [
        { icon: Stetho, text: 'Inicial', where: '/app' },
        { icon: KeyIcon, text: 'Extensivo', where: '/track' },
        { icon: Flashcards, text: 'Flashcards', where: '/flashcards' },
        { icon: Residencia, text: 'Residência', where: '/residencia' },
        { icon: Trilha, text: 'Correção de Erros', where: '/mistakes' },
        { icon: NoteIcon, text: 'Caderno', where: '/notebook' },
        { icon: Graph, text: 'Estatísticas', where: '/statistics' },
        { icon: Setup, text: 'Configurações', where: '/user' },
        { icon: Home, text: 'Website', where: '/home' },
        // { icon: Bedside, text: 'Bedside', where: '/bedside' },
    ]


    /*
        ATENÇÃO. Todas as animações precisma ter um initial = {false} para que
        não recorram a cada clique.

        Se você ver um flickering estraho após cliques, o mais provável é que
        seja isso.
    */

    function Button({ icon, text, selected, action }) {
        const bgDefault = `transparent`
        const bgHovered = `rgba(0, 0, 0, 0.03)`
        const bgSelected = `rgba(10, 132, 255, 0.12)`

        const [isHovered, setIsHovered] = useState(false);
        
        function handleAction() {
            if (action) {
                action()
            }
        }

        return (
            <Item>
                <Inner 
                    onClick = {handleAction} 
                    clickable = {!selected}
                    onHoverStart={() => setIsHovered(true)}
                    onHoverEnd={() => setIsHovered(false)}
                    initial = {false}
                    animate={{
                        width: expanded ? innerWidthExpanded : innerWidthCollapsed,
                        background: selected ? bgSelected : (isHovered ? bgHovered : bgDefault),
                        boxShadow: isHovered
                            ? selected
                                ? '0 4px 12px rgba(10, 132, 255, 0.1)'  // Sombra azulada se selecionado
                                : '0 4px 12px rgba(0, 0, 0, 0.125)'      // Sombra cinza se não selecionado
                            : 'none',
                    }}
                    whileTap={{
                        boxShadow: 'none',
                        // scale: 0.98
                    }}
                    transition={{
                        type: "spring",
                        stiffness: 400,
                        damping: 30,
                    }} >

                    <LabelImage
                        clickable = {!selected}
                        onClick = {action}
                        selected={selected}
                        isHovered={isHovered}
                        expanded = {expanded}
                        imageSrc = {icon}
                        labelLeft = {0} 
                        labelChildren = {(
                            <Text selected={selected} paddingLeft = {0}>
                                {text}
                            </Text>
                        )} />
                </Inner>
            </Item>
        )
    }
    

    function NotButton({icon, labelStyle, labelChildren}) {
        return (
            <Item>
                <Inner>
                    <LabelImage
                        selected={false}
                        isHovered={false}
                        expanded={expanded}
                        imageSrc={icon}
                        labelStyle={labelStyle}
                        labelChildren={labelChildren} />
                </Inner>
            </Item>
        )
    }


    return (
        <Container
            expanded={expanded}
            onMouseEnter={() => dispatch(setShowSidebar(true))}
            onMouseLeave={() => { dispatch(setShowSidebar(false)) }}
            initial = {false}
            animate={{
                width: expanded ? expandedWidth : collapsedWidth,
            }}
            transition={{
                type: "tween",
                duration: 0.4,
                ease: "easeInOut"
            }} >


            <NotButton
                icon = {darkMode ? LogoDark : Logo} 
                labelStyle={{paddingLeft: '10px', paddingRight: '10px'}}
                labelChildren = {
                    <SignatureIcon src={darkMode ? SignatureDark : Signature} />
                } />

            <NotButton
                icon = {darkMode ? Moon : Sun}
                labelStyle = {{paddingLeft: paddingToggle}}
                labelChildren = {<DarkModeToggle />} /> 


            {bttns.map(bttn => (
                <Button
                    icon={bttn.icon}
                    text={bttn.text}
                    expanded={expanded}
                    selected={bttn.where === path}
                    action={() => navigate(bttn.where)}  />
            ))}
        </Container>
    )
}



const MemoSideBar = React.memo(SideBar)

export default MemoSideBar
