import React, { useMemo } from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table'
import AmbassadorsController from './AmbassadorsController'

export default function TotalPostponedCommissionsTable(props) {

    const totalPostponedComissions = useMemo(() => [
        {
            accessorKey: 'coupon',
            header: 'Cupom'
        },
        {
            accessorKey: 'postponed',
            header: 'Comissão Acumulada',
            Cell: ({ cell }) => {
                const roundedValue = Math.round(cell.getValue())
                return `${roundedValue.toLocaleString('pt-BR')}`
            }
        },
    ], [])


    const totalPostponedCommissions = useMaterialReactTable({
        data: AmbassadorsController.consolidatedPostponed['data'],
        columns: totalPostponedComissions,
        initialState: {
            density: 'compact',
            sorting: [{ id: 'postponed', desc: true }],
        }
    })

    return (
        <div className='AmbassadorDashboard-Table'>
            <p>Somente o <u>acumulado</u>, não lista as comissões a serem pagas deste mês.</p>
            <MaterialReactTable table={totalPostponedCommissions} />
        </div>
    )
}