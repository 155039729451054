import React, { useEffect, useMemo, useRef, useState } from 'react'
import controller from './AmbassadorsController';
import styled from 'styled-components';
import OslerButton from '../components/OslerButton';


const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 2em;
`


export default function LastMonthComissionsStatus() {
    const [selectedMonth, setSelectedMonth] = useState(false)
    const [selectedMonthData, setMonthData] = useState(false)


    useEffect(() => {
        const lastMonth = controller.monthRange[controller.monthRange.length - 2]
        changeMonth(lastMonth)
    }, [])


    function changeMonth(month) {
        setSelectedMonth(month)
        setMonthData(controller.currentComissionStatus[month])
    }

    function getCouponsFromList(list) {
        return (
            <p className='ComissionStatus-Coupons'>
                { list.map(coupon => {
                    return (
                        <>{ coupon } | </>
                    )
                }) }
            </p>
        )
    }

    return (
        <div className = 'ComissionStatus-Container'>

            <ButtonsContainer>
                { controller.monthRange.map(month => {
                    
                    return <OslerButton
                        onClick = {() => changeMonth(month)}
                        selected = { selectedMonth === month }
                        text = { month }
                        color = 'gray'
                        size = 'small' />

                })}
            </ButtonsContainer>


            { selectedMonthData && 
                <>

            <p className = 'ComissionStatus-Title'><strong>Comissões de: {selectedMonth}</strong></p>

            <p className = 'ComissionStatus-Status'>🗓️ {selectedMonthData['postponed'].length} optaram por acumular</p>
            { getCouponsFromList(selectedMonthData['postponed']) }
            
            <p className = 'ComissionStatus-Status'>🙏🏻 {selectedMonthData['waiting_payment'].length} aguardando pagamento</p>
            { getCouponsFromList(selectedMonthData['waiting_payment']) }

            <p className = 'ComissionStatus-Status'>💰 {selectedMonthData['received_payment'].length} já receberam</p>
            { getCouponsFromList(selectedMonthData['received_payment']) }

            <p className = 'ComissionStatus-Status'>⚠️ {selectedMonthData['pending_decision'].length} não tomaram decisão</p>
            { getCouponsFromList(selectedMonthData['pending_decision']) }

            <p className = 'ComissionStatus-Status'>😔 {selectedMonthData['no_comission'].length} não tinham comissão ou acumulado</p>
            { getCouponsFromList(selectedMonthData['no_comission']) }

            </>
            }
        </div>
    )
}