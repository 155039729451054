import { useEffect, useState } from "react"
import styled from "styled-components"
import { ColumnCSS, RowCSS } from "../components/BasicComponents"
import UserReviewsInfo from "../controllers/UserReviewsInfo"
import OslerPieChart from "./OslerPieChart"
import StatisticsExplanationCard from "./StatisticsExplanationCard"
import CardsIcon from './../assets/flashcard.png'
import ResidIcon from './../assets/residencia.png'
import StatisticsPie from "./StatisticsPie"


export const Container = styled.div`
    ${ColumnCSS}
    width: 100%;
`

export const PiesContainer = styled.div`
    ${RowCSS}

    margin-top: 2em;
    width: 100%;

    align-items: flex-start;
    justify-content: space-around;

    @media (max-width: 500px) {
        ${ColumnCSS}
    }
`


export default function GeneralUseStatistics({ eachTypeDocs }) {
    // const [data, setData] = useState([])
    // const [hasData, setHasData] = useState(false)

    const [cardsData, setCardsData] = useState(false)
    const [residData, setResidData] = useState(false)

    useEffect(() => {
        function getCardsData(info) {
            const pending = info['pendingReviews'].length
            const future = info['futureReviews'].length
            const remaining = info['nAvailableTests'] - pending - future

            // Essa ordem é deliberada; é como aparecerá no PieChart e, mais importante, na 
            // legenda
            return [
                {
                    label: 'Não vistos',
                    number: remaining,
                    color: '#828282',
                    tooltipDescription: 'que você nunca viu',
                    unit: ['card', 'cards'],
                    percentageOf: 'dos disponíveis'
                },
                {
                    label: 'Revisões pendentes',
                    number: pending,
                    color: '#8E0011',
                    tooltipDescription: 'são revisão pendente (vai estudar!)',
                    unit: ['card', 'cards'],
                    percentageOf: 'dos disponíveis'
                }, 
                {
                    label: 'Revisões futuras',
                    number : future,
                    color: 'rgb(39, 121, 196)',
                    tooltipDescription: 'revisões agendadas para o futuro',
                    unit: ['card', 'cards'],
                    percentageOf: 'dos disponíveis'
                },
            ]
        }


        function getResidData(info) {
            const pending = info['pendingReviews'].length
            const future = info['futureReviews'].length
            const solved = info['solved'].length

            const remaining = info['nAvailableTests'] - pending - future - solved

            return [
                {
                    label: 'Não vistos',
                    number: remaining,
                    color: '#828282',
                    tooltipDescription: 'que você nunca viu',
                    unit: ['questão', 'questões'],
                    percentageOf: 'de todo o qBank'

                },
                {
                    label: 'Revisão pendente',
                    number: pending,
                    color: '#8E0011',
                    tooltipDescription: 'podem ser feitas no caderno de erros',
                    unit: ['questão', 'questões'],
                    percentageOf: 'de todo o qBank'
                }, 
                {
                    label: 'Revisões futuras',
                    number : future,
                    color: 'rgb(39, 121, 196)',
                    tooltipDescription: 'pode ser vistas no caderno de erros',
                    unit: ['questão', 'questões'],
                    percentageOf: 'de todo o qBank'
                },
                {
                    label: 'Resolvidas',
                    number : solved,
                    color: 'rgb(35, 146, 7)',
                    tooltipDescription: 'você já resolveu com segurança, e não aparecerão mais',
                    unit: ['questão', 'questões'],
                    percentageOf: 'de todo o qBank'
                },
            ]

        }

        const cardsData_tmp = getCardsData(UserReviewsInfo.info['Flashcards'])
        const residData_tmp = getResidData(UserReviewsInfo.info['Residencia'])

        setCardsData(cardsData_tmp)
        setResidData(residData_tmp)

    }, [])


    
    return (
        <Container>
            <StatisticsExplanationCard
                emoji = '🚀'
                highlight = 'Uso geral da plataforma'
                text = 'Dentre todos os flashcards e questões disponibilizados, explicita quantos você já viu.' />

            <PiesContainer>
                <StatisticsPie
                    title = 'Flashcards'
                    icon  = {CardsIcon}
                    data = {cardsData} />

                <StatisticsPie
                    title = 'Residência'
                    icon  = {ResidIcon}
                    data = {residData} />
            </PiesContainer>
        </Container>
    )
}