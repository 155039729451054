import React, { useState, useEffect } from 'react';
import '../bedside/CalcPage.css';
import { Link } from 'react-router-dom';

function CalculadoraCreatininaCKDEPI() {
    const [sexo, setSexo] = useState('');
    const [idade, setIdade] = useState('');
    const [creatinina, setCreatinina] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularEGFR = () => {
        let eGFR;
        const idadeNum = parseFloat(idade);
        const creatNum = parseFloat(creatinina);

        let kappa, alpha, sexFactor;
        if (sexo === 'masculino') {
            kappa = 0.9;
            alpha = -0.302;
            sexFactor = 1;
        } else if (sexo === 'feminino') {
            kappa = 0.7;
            alpha = -0.241;
            sexFactor = 1.012;
        } else {
            setResultado('Selecione o sexo.');
            return;
        }

        const minPart = Math.min(creatNum / kappa, 1);
        const maxPart = Math.max(creatNum / kappa, 1);

        eGFR = 142 * Math.pow(minPart, alpha) * Math.pow(maxPart, -1.2) * Math.pow(0.9938, idadeNum) * sexFactor;

        const eGFRValue = eGFR.toFixed(2);
        const classificacao = classificarEGFR(eGFR);

        setResultado(`Estimativa de eGFR: ${eGFRValue} mL/min/1.73m², Classificação: ${classificacao}`);
    };

    const classificarEGFR = (eGFR) => {
        if (eGFR > 90) {
            return 'G1';
        } else if (eGFR >= 60 && eGFR <= 89) {
            return 'G2';
        } else if (eGFR >= 45 && eGFR <= 59) {
            return 'G3a';
        } else if (eGFR >= 30 && eGFR <= 44) {
            return 'G3b';
        } else if (eGFR >= 15 && eGFR <= 29) {
            return 'G4';
        } else {
            return 'G5';
        }
    };

    useEffect(() => {
        if (sexo && idade && creatinina) {
            calcularEGFR();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [sexo, idade, creatinina]);

    return (
        <div>
                                                                  <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>

            <h1>Calculadora de Clearance de Creatinina CKD-EPI</h1>
            <div>
                <label>Sexo:</label>
                <select value={sexo} onChange={e => setSexo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                </select>
            </div>
            <div>
                <label>Idade (em anos):</label>
                <input type="number" value={idade} onChange={e => setIdade(e.target.value)} />
            </div>
            <div>
                <label>Creatinina sérica (mg/dL):</label>
                <input type="number" step="0.01" value={creatinina} onChange={e => setCreatinina(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraCreatininaCKDEPI;