import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

import Brain from './../assets/extensivo_icons/brain.png'
import Knife from './../assets/extensivo_icons/faca.png'
import Magnifier from './../assets/extensivo_icons/lupa.png'
import Uterus from './../assets/extensivo_icons/ovario.png'
import Bottle from './../assets/extensivo_icons/pediatrics.png'
import Fire from './../assets/extensivo_icons/fogo.png'
import Rocket from './../assets/extensivo_icons/rocket.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'

const emojiMap = {
    '🧠': Brain,
    '🔪' : Knife,
    '🔎' : Magnifier,
    '🍼' : Bottle, 
    '👩' : Uterus,
    '🔥' : Fire,
    '🚀' : Rocket
}



const Name = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`


const Icons = styled.div`
    ${RowCSS}

    @media (max-width: 900px) {
        ${ColumnCSS}
        margin: 0 0.5em 0 0;
    }

    @media (max-width: 500px) {
        ${ColumnCSS}
        justify-content: center;
        margin: 0 0.3em 0 0em;
    }
`

const Icon = styled.img`
    margin: 0 0.5em 0 0;
    height: 1.8em;

    // Precisa ter margem em cima e embaixo caso duas imagens, para não encostar
    // e para ficar simétrico se só uma
    
    @media (max-width: 900px) {
        height: 1.3em;
        margin: 0.2em 0 0.2em 0;
    }

    @media (max-width: 500px) {
        height: 1.0em;
        margin: 0.2em 0 0.2em 0;
    }
`

const CustomSpan = styled.span`
${props => props.theme.darkMode ? `
        color: white;
    ` : `
       color: black;
        // background-color: yellow;
    `}    font-size: 0.7em;
`

const Text = styled.p`
    font-size: 1.2em;
    margin: 0;
    padding: 0;
    width: 100%;

    ${props => props.theme.darkMode ? `
        color: rgb(220, 220, 220);
    ` : `
       color: black;
        // background-color: yellow;
    `}

    @media (max-width: 900px) {
        font-size: 1em;
    }

    @media (max-width: 500px) {
        font-size: 0.8em;
    }

    @media (max-width: 368px) {
        font-size: 0.7em;
    }
`





export default function ModuleName({ title, emojis }) {
    const [JSX, setJSX] = useState(null)

    useEffect(() => {
        const icons = Array.from(emojis).map((char, index) => {
            if (emojiMap[char]) {
                return (
                    <Icon src={emojiMap[char]} alt={char} />
                )
            }
            return null
        })

        const titleParts = title.split('&').reduce((acc, part, index, array) => {
            if (index < array.length - 1) {
                acc.push(part, <CustomSpan key={`span-${index}`}>&</CustomSpan>)
            } else {
                acc.push(part)
            }
            return acc
        }, [])

        setJSX(
            <Name>
                <Icons>{icons}</Icons>
                <Text>{titleParts}</Text>
            </Name>
        )
    }, [title, emojis])

    return JSX
}