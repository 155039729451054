import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { emojiDictionary } from './Emojis' // Certifique-se de importar corretamente o emojiDictionary

const EmojiSuggestionsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    background: white;
    border: 1px solid #ccc;
    padding: 5px;
    max-width: 100%;
    max-height: 200px;
    overflow-y: scroll;
    z-index: 1000;
`

const EmojiSuggestionItem = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.4em;
    border-radius: 1em;

    width: 10%;

    cursor: pointer;
    background-color: ${props => props.highlighted ? '#eee' : 'white'};

    font-size: 20px;
    box-sizing: border-box; /* Inclui padding e border na largura e altura */
`

export default function EmojiSuggestions({ matches, setChosenEmoji }) {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const containerRef = useRef(null)
    const itemRefs = useRef([]) // Array de referências para os itens


    // Parece bambiarra, mas é para usar dentro do handleDown, que é passadocomo cópia
    const selectedIndexRef = useRef(selectedIndex);
    useEffect(() => {
        selectedIndexRef.current = selectedIndex;
    }, [selectedIndex]);

    


    useEffect(() => {
        if (matches && matches.length > 0) {
            setSelectedIndex(0)
            window.addEventListener('keydown', handleKeyDown)
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [matches])





    function handleKeyDown(e) {
        if (!matches || matches.length === 0) return

        let key = e.key;

        if (['ArrowRight', 'ArrowLeft', 'ArrowDown', 'ArrowUp'].includes(key)) {
            e.preventDefault()

            setSelectedIndex((prevIndex) => {
                let newIndex = prevIndex
        
                if (key === 'ArrowRight') {
                    newIndex = (prevIndex + 1) % matches.length

                } else if (key === 'ArrowLeft') {
                    newIndex = (prevIndex - 1 + matches.length) % matches.length

                } else if (key === 'ArrowDown') {
                    newIndex = prevIndex + 10
                    if (newIndex >= matches.length) newIndex = matches.length - 1

                } else {
                    newIndex = prevIndex - 10
                    if (newIndex < 0) newIndex = 0
                }
                
                itemRefs.current[newIndex]?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

                return newIndex
            })
        }
        else if (key === 'Enter') {

            sendEmoji(selectedIndexRef.current);
            e.preventDefault()
        }
    }


    function sendEmoji(index) {
        setChosenEmoji(emojiDictionary[matches[index]])
        setSelectedIndex(0)

    }


    if (!matches || matches.length === 0) {
        return null
    }

    return (
        <EmojiSuggestionsContainer ref={containerRef}>
            {matches.map((slug, index) => (
                <EmojiSuggestionItem 
                    key={slug}
                    ref={el => itemRefs.current[index] = el}
                    highlighted={index === selectedIndex}
                    onClick={() => sendEmoji(index)}
                    onMouseEnter={() => {
                        console.log('sim')
                        setSelectedIndex(index)
                    }}
                >
                    {emojiDictionary[slug]}
                </EmojiSuggestionItem>
            ))}
        </EmojiSuggestionsContainer>
    )
}
