import React, { useMemo } from 'react'
import {
    MaterialReactTable,
    useMaterialReactTable,
  } from 'material-react-table'
import AmbassadorsController from './AmbassadorsController'

export default function ConsolidatedSalesTable(props) {
    const consolidatedSalesDatColumn = useMemo(() => [
        {
            accessorKey: 'coupon',
            header: 'Cupom'
        },
        {
            accessorKey: 'months_since_signup',
            header: 'Meses no programa',
            Cell: ({ cell }) => (Math.round(cell.getValue() * 10) / 10).toLocaleString('pt-BR'),
            filterVariant: 'range',
            filterFn: 'between',
        },
        {
            accessorKey: 'total_sales',
            header: 'Vendas totais',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR')
        },
        {
            accessorKey: 'avg_sales_per_month',
            header: 'Média vendas mensais',
            Cell: ({ cell }) => (Math.round(cell.getValue() * 10) / 10).toLocaleString('pt-BR')
        },
        {
            accessorKey: 'months_since_last_sale',
            header: 'Meses desde última venda',
            Cell: ({ cell }) => (Math.round(cell.getValue() * 10) / 10).toLocaleString('pt-BR')
        },
        {
            accessorKey: 'tests_last_30',
            header: 'Testes últimos 30d',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR')
        },
        {
            accessorKey: 'tests_last_60', 
            header: 'Testes últimos 60d',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR')
        },
        {
            accessorKey: 'total_tests',
            header: 'Total de testes',
            Cell: ({ cell }) => cell.getValue().toLocaleString('pt-BR')
        }
    ])


    const consolidatedSales = useMaterialReactTable({
        data: AmbassadorsController.consolidatedSalesData,
        columns: consolidatedSalesDatColumn,
        initialState: {
            density: 'compact',
            sorting: [{ id: 'total_sales', desc: true }],
        }
    })

    return (
        <div className='AmbassadorDashboard-Table'>
            <p>Somente para embaixadores <u>ativos</u>.</p>
            <MaterialReactTable table={consolidatedSales} />
        </div>
    )
}