import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { db } from '../firebase/firebase-setup';
import { htmlToReact } from '../utils/HtmlToReact';
import CloseIcon from './../assets/x-icon.png';
import { useSelector } from 'react-redux';
import OslerButton from '../components/OslerButton';
import { RowCSS } from '../components/BasicComponents';

const NoticeContainer = styled.div`
    border-radius: 1em;
    margin-top: 5em;
    margin-left: 5em;
    width: 70%;
    padding: 2em;

    border: 1px solid lightslategrey;
    
    ${props => props.theme.darkMode ? `
        color: white;
        background-color: rgb(90, 90, 90);
    `: `
        color: black;
        background-color: white;

    ` }

    @media (max-width: 900px) {
        margin: 5em 0 0 0;
        width: 100%;
    }


    @media (max-width: 500px) {
        align-self: center;
        margin-left: 0;
        margin-top: 2em;
        width: 90%;
    }
`;

const NoticeHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column-reverse;
    }

    margin-bottom: 1em;
`;

const NoticeTitle = styled.div`
    font-weight: bold;
    font-family: Lora;
    font-size: 1.4em;

    & > p {
        margin: 0;
        padding: 0;
    }
`;

const NoticeClose = styled.div`
    width: 1em;
    cursor: pointer;
    margin: 0;
    padding: 0;
    align-self: center;

    & > img {
        width: 100%;
    }
`

const Bttns = styled.div`
    ${RowCSS}

    @media (max-width: 900px) {
        width: 100%;
        margin-top: 2em;
        justify-content: flex-end;
    }
`

export default function NoticeCard(props) {

    const user = useSelector(state => state.user.data)

    const [showNotice, setShow] = useState(false)
    const [title, setTitle] = useState("")
    const [message, setMessage] = useState("")
    const [uuid, setUuid] = useState("")


    const [isDesktop, setDesktop] = useState(window.innerWidth > 900);


    useEffect(() => {
        async function load() {
            const lastNoticeDoc = await db.collection('notice').doc('lastNotice').get()
            
            if (lastNoticeDoc.exists) {   
                const lastNotice = lastNoticeDoc.data()   

                const alreadyReadDoc = await db.doc(`users/${user.id}/personal/readLastNotice`).get()

                console.log(lastNotice)
                console.log(`users/${user.id}/personal/readLastNotice`)

                if (alreadyReadDoc.exists) {
                    const alreadyRead = alreadyReadDoc.data()

                    console.log(alreadyRead)

                    if (alreadyRead['id'] === lastNotice['id']) {
                        console.log("NoticeCard: já leu o aviso");
                        setShow(false)
                        return
                    }
                }
                setTitle(lastNotice['title'])
                setMessage(htmlToReact(lastNotice['message']))
                setShow(true)
                setUuid(lastNotice['id'])
            } else {
                console.log("NoticeCard: não temos avisos novos")
            }
        }

        console.log("NoticeCard: loading...")
        load()
    }, [])

    
    function bttns() {
        return (
            <Bttns>
                <OslerButton
                    size = 'small'
                    color = 'red'
                    onClick = {async () => {
                        setShow(false)
                        await db.doc(`users/${user.id}/personal/readLastNotice`).set({id: uuid})
                    }}
                    text = 'Não mostrar mais'
                    style = {{marginRight: isDesktop ? '2em' : '1em'}} />

                <OslerButton
                    size = 'small'
                    color = 'gray'
                    onClick = {() => setShow(false)}
                    text = 'Fechar'  />
            </Bttns>
        )
    }

    return (
        <>
            {showNotice && (
                <NoticeContainer>
                    <NoticeHeader>
                        <NoticeTitle>
                            <p>{title}</p>
                        </NoticeTitle>

                        { isDesktop && bttns() }
                    </NoticeHeader>
                    
                    {message}

                    { !isDesktop && bttns() }
                </NoticeContainer>
            )}
        </>
    );
}
