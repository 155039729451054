import React, { useState } from 'react';
import '../bedside/CalcPage.css';
import { Link } from 'react-router-dom';

function CalculadoraNYHA() {
    const [classificacao, setClassificacao] = useState('');

    const handleChange = (e) => {
        setClassificacao(e.target.value);
    };

    return (
        <div>
                                                      <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>

            <h1>Classificação Funcional NYHA</h1>
            <form className='form-gad7'>
                <div className='nyha-div'>
                    <input
                        type="radio"
                        name="nyha-class"
                        className='nyha-check'
                        value="Classe I: Sem limitação de atividade física. Atividade física habitual não causa fadiga, palpitação ou dispneia."
                        onChange={handleChange}
                        id="classe-i"
                    />
                    <label  className="label-nyha"  htmlFor="classe-i">Assintomático durante o exercício de atividades cotidianas.</label>
                </div>
                <div className='nyha-div'>
                    <input
                        type="radio"
                        name="nyha-class"
                        className='nyha-check'
                        value="Classe II: Leve limitação da atividade física. Confortável em repouso, mas a atividade normal provoca fadiga, palpitação ou dispneia."
                        onChange={handleChange}
                        id="classe-ii"
                    />
                    <label className="label-nyha" htmlFor="classe-ii">Sintomas leves durante as atividades cotidianas.</label>
                </div>
                <div className='nyha-div'>
                    <input
                        type="radio"
                        name="nyha-class"
                        className='nyha-check'
                        value="Classe III: Marcante limitação da atividade física. Confortável apenas em repouso. Qualquer atividade física causa fadiga, palpitação ou dispneia."
                        onChange={handleChange}
                        id="classe-iii"
                    />
                    <label className="label-nyha" htmlFor="classe-iii">Sintomas aos pequenos esforços ou atividades de menor vigor que as cotidianas.</label>
                </div>
                <div className='nyha-div'>
                    <input
                        type="radio"
                        name="nyha-class"
                        className='nyha-check'
                        value="Classe IV: Incapacidade de realizar qualquer atividade física sem desconforto. Sintomas de insuficiência cardíaca presentes até em repouso."
                        onChange={handleChange}
                        id="classe-iv"
                    />
                    <label className="label-nyha" htmlFor="classe-iv">Sintomas em repouso ou aos mínimos esforços.</label>
                </div>
            </form>
            {classificacao && (
                <div className="result-container">
                    <p>Sua classificação NYHA é: {classificacao}</p>
                </div>
            )}
        </div>
    );
}

export default CalculadoraNYHA;
