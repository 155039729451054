import userReviewsInfo from './../controllers/UserReviewsInfo'
import OslerData, { KEYS } from '../controllers/OslerData'
import { measureTime } from '../utils/Utils';


export async function loadAppData(user, forceDownload = false) {
    try {
        await OslerData.start(user, forceDownload);
        // não tinha await antes?! ?! ?!
        userReviewsInfo.loadVariables()

        await measureTime(() => userReviewsInfo.start(), 'UserReviewsInfo(): started in');
        return true
    } catch (error) {
        return false
    }
}
