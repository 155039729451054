import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraGASA() {
    const [albuminaSerica, setAlbuminaSerica] = useState('');
    const [albuminaAscitica, setAlbuminaAscitica] = useState('');
    const [proteinaAscitica, setProteinaAscitica] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularGASA = () => {
        const albuminaSericaNum = parseFloat(albuminaSerica);
        const albuminaAsciticaNum = parseFloat(albuminaAscitica);
        const proteinaAsciticaNum = parseFloat(proteinaAscitica);

        if (albuminaSericaNum > 0 && albuminaAsciticaNum > 0) {
            const gasa = albuminaSericaNum - albuminaAsciticaNum;
            let interpretacao;

            if (gasa >= 1.1) {
                if (proteinaAsciticaNum > 0) {
                    if (proteinaAsciticaNum <= 2.5) {
                        interpretacao = 'sugere doença hepática crônica avançada';
                    } else {
                        interpretacao = 'sugere comprometimento pós-sinusoidal (ex. Insuficiência cardíaca, síndrome de Budd-Chiari e pericardite constritiva)';
                    }
                } else {
                    interpretacao = 'provavelmente secundária à hipertensão portal';
                }
            } else {
                interpretacao = 'quadros de ascite não associados à hipertensão portal, como síndrome nefrótica, tuberculose, neoplasias, pancreatite, serosites e doença peritoneal';
            }

            setResultado(`Gradiente de Albumina Soro-Ascite (GASA): ${gasa.toFixed(2)} g/dL - ${interpretacao}`);
        } else {
            setResultado('Preencha todos os campos obrigatórios com valores válidos.');
        }
    };

    useEffect(() => {
        calcularGASA();
    }, [albuminaSerica, albuminaAscitica, proteinaAscitica]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Gradiente de Albumina Soro-Ascite (GASA)</h1>
            <div>
                <label>Albumina sérica (g/dL):</label>
                <input type="number" step="0.01" value={albuminaSerica} onChange={e => setAlbuminaSerica(e.target.value)} />
            </div>
            <div>
                <label>Albumina no líquido ascítico (g/dL):</label>
                <input type="number" step="0.01" value={albuminaAscitica} onChange={e => setAlbuminaAscitica(e.target.value)} />
            </div>
            <div>
                <label>Proteína total no líquido ascítico (g/dL) (opcional):</label>
                <input type="number" step="0.01" value={proteinaAscitica} onChange={e => setProteinaAscitica(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
           
        </div>
    );
}

export default CalculadoraGASA;
