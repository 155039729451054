import React, { useState, useRef, useEffect } from 'react'
import { db, storage } from '../firebase/firebase-setup'
import './AmbassadorScreen.css'


import AmbassadorDashboardProfile from './AmbassadorDashboardProfile'


export default function AmbassadorMonthly({ambassador, signalAdminDashboard = undefined, trigger}) {
    const [submittingData, setSubmittingData] = useState(false)

    // Por definição, é o mês que está sendo exibido no momento.
    // Deve ser do formato '2023_12'
    const [selectedMonth, setSelectedMonth] = useState( getCurrentMonth() )

    const [decisionWasMade, setDecisionWasMade] = useState(false)

    const monthly_data = useRef(undefined)
    // const personal_registry      = useRef(undefined)
    // const profile = useRef(undefined)


    const [pixInput1, setPixInput1] = useState('')
    const [pixInput2, setPixInput2] = useState('')

    const invoiceFile = useRef()
    const [invoiceUploaded, setInvoiceUploaded] = useState(false)

    const [dataLoaded, setDataLoaded] = useState(false)

    // const uid = useRef()
    // const [profile, setProfile] = useState(false)
    // const [personal_registry, setPersonalRegistry] = useState(false)


    function getNextMonth(giveMonth) {
        // Recebe algo do gênero "2023_12" e retorna "2024_1"
        const [year, month] = giveMonth.split('_').map(str => parseInt(str))
        return month === 12 ? `${year + 1}_1` : `${year}_${month + 1}`;
    }


    useEffect(() => {
        const fetchDados = async () => {
            // O ideal não era um documento com a uid de todos ser público, mas as regras seguram?

            try {
                // Cadastro do usuário no programa de embaixadores.
                // Possui o mês de entrada, que é importante para sabermos até onde buscar comissões.
                // const profileSnapshot = await db.doc(`users/${ambassador.uid}/ambassador/personal_registry`).get();

                // if (!profileSnapshot.exists) {
                //     return;
                // }

                // profile.current = profileSnapshot.data()

                // Os meses anteriores ao mês passado já possuem nota fiscal fechada.
                // Se estamos em Novembro (mês 11), todos os meses até Setembro (mês 9) certamente já tem comissão fechada.
                // Outubro (mês 10) pode ter ou não, a depender do dia de Novembro (geralmente, fechamos dia 10).
                const temp_monthly_data = {}
                const snap = await db.collection(`users/${ambassador.uid}/ambassador/comission/consolidated/`).get()

                snap.forEach(doc => {
                    // O ID do documento é no formato: 2023_7
                    temp_monthly_data[doc.id] = doc.data()
                })

                // Para os demais meses, até já baixamos a informação, mas não iremos exibir por ora.
                const querySnapshot = await db.collection(`users/${ambassador.uid}/ambassador/comission/coupon_use/`).get();


                querySnapshot.forEach((doc) => {                        
                    // SE não existe consolidado, precisamos colocar o uso do cupom
                    // Se o único consolidado é o acumulado, ainda assim precisamos colocar.
                    if (!temp_monthly_data[doc.id]) {
                        temp_monthly_data[doc.id] = {}
                    }

                    temp_monthly_data[doc.id]['coupon_use'] = doc.data()
                });


                // Se isso ocorre, é porque ele ainda não tem vendas no mês corrente.
                if (!temp_monthly_data[selectedMonth]) {
                    temp_monthly_data[selectedMonth] = {
                    }
                }

                console.log('Dado mensal')
                console.log(temp_monthly_data)

                // Salvamos para exibir na tela.
                if (temp_monthly_data) {
                    monthly_data.current = temp_monthly_data
                    setDataLoaded(true)
                }

                selectMonth(selectedMonth)
            }
            catch (e) {
                console.log(' * ERRO AO CARREGAR OS DADOS!')
                console.log(e)
                return;
            }
        }

        console.log('Iremos fazer uma nova renderização!!!!')
        console.log(ambassador)
        console.log(trigger)

        if (ambassador.uid) {
            fetchDados()
        }
    }, [ambassador, trigger])


    function renderListBuyers(price_data) {     
        return (
            <>
                <p><strong>Clientes:</strong></p>
                {price_data['buyers'].map(e => {
                    return (
                        <ul>
                            {/* <li className = 'Embaixadores-List'>{e.name} - {e.email} - {e.phone}</li> */}
                            <li className='Embaixadores-List'>{e.name} </li>
                        </ul>
                    )
                })}
            </>
        )
    }



    function givenMonthSalesJSX(data) {
        // Só funciona se data é coupon use, e nào comissão fechada, claro.
        if (!data) {
            return (
                <div><p>Você ainda não tem nenhuma venda este mês.</p></div>
            )
        }

        const sales_prices = Object.keys(data)

        if (sales_prices.length == 0) {
            return (
                <div className = 'Embaixadores-MonthData'>
                    <p><strong>{selectedMonth}</strong></p>
                    <p><strong>Você ainda não teve vendas neste mês (ou elas não foram atualizadas).</strong></p>
                </div>
            )
        }
        else {
            return (
                <div>
                    {sales_prices.map(price => {
                        // Existe uma exceção aqui, que é quando NÃO ESTÁ consolidado,
                        // mas tem um acumulado do mies anterior, qeu aparece como uma key
                        if (price == 'totalPostponedValue') {
                            return(<></>)
                        }
                        else {
                            const n_uses = data[price]['n_uses']
                        
                            return (
                                <div className = 'Embaixadores-MonthData'>
                                    <p><strong>{selectedMonth}</strong></p>
                                    <p className='PriceDetailTitle'>Plano de R${price / 100}</p>
                                    <p><strong>Número de vendas:</strong> {n_uses}</p>
                                    {renderListBuyers(data[price])}
                                </div>
        
                            )
                        }
                    })}
    
                </div>
            )
        }
    }


    function getConsolidatedJSX(data) {
        return (
            <>
                <p><strong>{dateToMonthString(selectedMonth)}</strong></p>
                <p><strong>Sua comissão:</strong> R${data.comission} </p>
                                        
                    <>
                        { data.totalPostponedValue > 0 && 
                            <>
                            <p><strong>Acumulado: </strong> R${data.totalPostponedValue}</p>
                            <p className = 'embaixadores-attention'>Total para saque: R${data.totalPostponedValue + data.comission}</p>
                            {/* <p>Valor de meses anteriores. Você é obrigado a sacar junto com a comissão, se quiser receber.</p> */}
                            </>
                        } 
                    </>
                
            </>

        )
    }


    function getMonthData() {
        if (!monthly_data.current[selectedMonth]) {
            return;
        }

        const data = monthly_data.current[selectedMonth]

        if ( Object.keys(data).length == 0 || (data.comission == 0 && data.totalPostponedValue == 0)) {
            return (
                <div className = 'Embaixadores-MonthData'>
                    <p><strong>{dateToMonthString(selectedMonth)}</strong></p>
                    <p>Você não tem comissões desse mês, pois não foi realizada nenhuma compra com seu cupom, e não possui valores acumulados pendentes de meses anteriores.</p>
                </div>
            )
        }   
        else {
            if (data.decisionMade) {
                return (
                    <div className = 'Embaixadores-MonthData'>
                        {getConsolidatedJSX(data)}
                        
                        { data.ambassadorPostponed &&
                            <p><strong>Valor foi acumulado. </strong> Você optou por não receber, e acumular para sacar depois.</p>
                        }
                        { !data. ambassadorPostponed &&
                            <>
                                <p><strong>Nota fiscal enviada? </strong> {data.invoiceUploaded ? 'Sim' : 'Não'}</p>
                            </>
                        }
                    </div>
                )
            }
            else {
                return (
                    <>
                        <div className = 'Embaixadores-MonthData'>
                            {getConsolidatedJSX(data)}
                                
                            { data['isSettled'] &&
                                getCallToAction(data) }
                        </div>

                        {givenMonthSalesJSX(data['coupon_use'])}
                    </>
                )
            }
        }
    }



    function postponePayment(data) {
        // Mudamos os dados da comissão atual
        // Atualizamos a comissão do mês que vem
        // Mudamos decisionWasMade para false
        // Recarregamos a tela e/ou mudamos os dados locais.
        const nextMonth = getNextMonth(selectedMonth)

        const currentComission = `users/${ambassador.uid}/ambassador/comission/consolidated/${selectedMonth}`
        const nextComission = `users/${ambassador.uid}/ambassador/comission/consolidated/${nextMonth}`

        db.doc(currentComission).set({
            'ambassadorPostponed' : true,
            'decisionMade' : true,
        }, {merge: true})

        db.doc(nextComission).set({
            'totalPostponedValue' : (data.totalPostponedValue + data.comission)
        }, {merge: true})

        // Mudamos as variáveis locais
        monthly_data.current[selectedMonth]['ambassadorPostponed'] = true
        monthly_data.current[selectedMonth]['decisionMade']        = true

        monthly_data.current[nextMonth]['totalPostponedValue'] = (data.totalPostponedValue + data.comission)
        setDecisionWasMade(false)
    }


    async function receivePayment() {
        // Enviamos o pdf para o storage
        // Modificamos o documento da comissão consolidade
        // Modificamos os dados atuais e o state
        setSubmittingData(true)

        const storageRef = storage.ref(`notas_fiscais/${ambassador.uid + selectedMonth}`)
        const result = await storageRef.put(invoiceFile.current)
        const downloadURL = await result.ref.getDownloadURL()
        
        const currentComission = `users/${ambassador.uid}/ambassador/comission/consolidated/${selectedMonth}`

        await db.doc(currentComission).set({
            'invoiceUploaded' : true,
            'decisionMade' : true,
            'pixGiven' : true,
            'downloadURL' : downloadURL,
            'pixKey' : pixInput1,
        }, {merge: true})

        monthly_data.current[selectedMonth]['invoiceUploaded'] = true
        monthly_data.current[selectedMonth]['decisionMade'] = true
        monthly_data.current[selectedMonth]['pixGiven'] = true

        setDecisionWasMade(false)
        setSubmittingData(false)
    }


    function getCallToAction(data) {
        // Ou irá acumular a comissão, ou irá colocar dados para receber
        // o que chamaremos de decision.
        if (decisionWasMade == 'postpone') {
            return (
                <div>
                    <div 
                        className = 'embaixadores-bttn2 dashboard-bttn button-blue'
                        onClick={() => postponePayment(data)}>
                            Confirmo que quero acumular.
                    </div>

                    <div 
                        className = 'embaixadores-bttn2 dashboard-bttn button-red'
                        onClick={() => setDecisionWasMade(false)}>
                            Voltar
                    </div>
                </div>
            )
        }
        else if (decisionWasMade == 'receivePayment') {
            if (submittingData) {
                return (
                    <p><b>Estamos enviando os dados. Aguarde, sem fechar a página...</b></p>
                )
            }
            else {
                return (
                    <div className = 'receivePayment-inputs'>
                        {/* <div 
                            className = 'embaixadores-bttn2 dashboard-bttn button-blue'
                            onClick={() => postponePayment(data)}>
                                Confirmo que quero acumular.
                        </div> */}
    
                        <div className = 'invoiceInputContainer'>
                            <p><b>Insira a chave Pix.</b></p>
                            
                            { pixInput1 == pixInput2 && pixInput1 != '' &&
                                <p className = 'Embaixadores-pixOk'>Chave Pix inserida!</p>
                            }
                            
                            { pixInput1 != pixInput2 && pixInput2 != '' && 
                                <p className = 'Embaixadores-pixAttention'>As chaves Pix são diferentes.</p>
                            }
                            
                            <input
                                className = 'Embaixadores-Input'
                                onChange = { e => setPixInput1(e.target.value)}
                                value = {pixInput1}
                                placeholder = {'Chave'} />
    
                            <input
                                className = 'Embaixadores-Input'
                                onChange = { e => setPixInput2(e.target.value)}
                                value = {pixInput2}
                                placeholder = {'Repita para confirmar'} />
    
                        </div>
    
                        <div className = 'invoiceInputContainer'>
                            { !invoiceUploaded && 
                                <p className = 'invoicePending'>Anexe a sua nota fiscal.</p>
                            }
    
                            { invoiceUploaded && 
                                <p className = 'invoiceOk'>Nota fiscal anexada.</p>
                            }
                            <input
                                accept='application/pdf'
                                type = 'file'
                                onChange = {(e) => {
                                    invoiceFile.current = e.target.files[0]
                                    setInvoiceUploaded(true)
                                }}/>
                        </div>
    
                        
                        <div className = 'receivePayment-finalMsg'>
                            <p><b>ATENÇÃO.</b> Você está confirmando que a conta corrente associada ao Pix é da mesma pessoa e cadastro físico pessoal associada à nota fiscal.</p>
    
                            <p>Por exemplo, se a MEI é do João, precisa ser um pix para a conta corrente do João.</p>
    
                            <p>Se você colocar um Pix para outra pessoa, estará cometendo <b>fraude tributária</b>.</p>
                        </div>
    
    
                        <div 
                            className = 'embaixadores-bttn2 dashboard-bttn button-green'
                            onClick={() => receivePayment()}>
                                Confirmo
                        </div>
    
                        <div 
                            className = 'embaixadores-bttn2 dashboard-bttn button-red'
                            onClick={() => setDecisionWasMade(false)}>
                                Voltar
                        </div>
                    </div>
                )
            }
        }
        else {
            return (
                <div>
                    <p><b>Você pode optar por acumular sua comissão para o próximo mês ou sacá-la.</b> Se você não tomar uma decisão até a data limite informada via WhatsApp, ela será automaticamente acumulada.</p>

                    <div 
                        className = 'embaixadores-bttn2 dashboard-bttn button-blue'
                        onClick={() => setDecisionWasMade('postpone')}>
                            Acumular 
                    </div>


                    <div 
                        className = 'embaixadores-bttn2 dashboard-bttn button-green'
                        onClick={() => setDecisionWasMade('receivePayment')}>
                            Sacar
                    </div>
                </div>
            )
        }
    }

    function getCurrentMonth() {
        const today = new Date()
        return `${today.getFullYear()}_${today.getMonth() + 1}`
    }


    function dateToMonthString(date) {
        // Vem no formato "2024_1"
        const [year, month] = date.split('_').map(str => parseInt(str))

        const months_names = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

        const str = `${months_names[month-1]}/${year}`

        return str
    }



    function selectMonth(month) {
        if (signalAdminDashboard) {
            signalAdminDashboard(month, monthly_data.current[month])  
        }

        setSelectedMonth(month)
    }


    function getEachMonthButton() {
        // Cruzamos com o perfil pessoal para ver quando entrou no programa, e não dar botões de antes.
        const signupDateStr = ambassador.signup_date
        const parts = signupDateStr.split('/').map(e => parseInt(e))

        // Nós colocamos primeiro dia do mês, porque é o que importa.
        // Se não, ao compararmos 01/08/2023 com 25/08/2023, teríamos dificuldades.
        const signupDate = new Date(parts[2], parts[1] - 1, 1)        
        const today = new Date()
        const months = []

        outerLoop:  
        for (let year = 2023; year <= today.getFullYear(); year++) {
            for (let month = 0; month <= 11; month++) {
                // Último dia do mês anterior.
                const date = new Date(year, month, 1)

                if (date.getTime() <= today.getTime()) {
                    if (date.getTime() >= signupDate.getTime()) {
                        const monthCode = `${year}_${month+1}`

                        months.push( [
                            dateToMonthString(monthCode),
                            monthCode   
                        ] )
                    }
                }
                else {
                    break outerLoop;
                }
            }
        }

        return (
            <>
            { months.map(month => (
                    <div 
                        className = {'embaixadores-bttn dashboard-bttn button-gray' + (month[1] == selectedMonth ? ' button-gray-active' : '')}
                        onClick={() => selectMonth(month[1])}>
                        {month[0]}
                    </div>
                ))
            }
            </>
        )
    }

    return (
        <>  
            { dataLoaded && monthly_data.current && 
                <>

                <AmbassadorDashboardProfile
                    ambassador = {ambassador} />

                <div className='Embaixadores-MonthContainer'>
                    {getEachMonthButton()}
                </div>

                <div className = 'Embaixadores-ComissionContainer'>
                    { monthly_data.current[selectedMonth]['date_comission_calculated'] }
                    { getMonthData() }
                </div>
            </>
            } 
            { !dataLoaded && 
                <p>Ops. Não conseguimos carregar os dados. Talvez você não seja embaixador.</p>
            }
        </>
    )

}