import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

const CAPURRO_SOMATICO_POINTS = {
    formacaoMamilo: {
        "Apenas visível, sem aréola": 0,
        "Aréola bem definida - diâmetro menor que 0,75 cm": 5,
        "Aréola bem definida, borda não elevada - diâmetro maior que 0,75 cm": 10,
        "Borda elevada - diâmetro maior que 0,75 cm": 15,
    },
    pregasPlantares: {
        "Sem pregas": 0,
        "Marcas mal definidas sobre a parte anterior da planta": 5,
        "Marcas bem definidas sobre a metade anterior e sulcos no terço anterior": 10,
        "Sulcos em mais da metade anterior da planta": 15,
        "Sulcos em toda planta": 20,
    },
    texturaPele: {
        "Muito fina, gelatinosa": 0,
        "Fina e lisa": 5,
        "Algo mais grossa, discreta descamação superficial": 10,
        "Grossa, sulcos superficiais, descamação nas mãos e pés": 15,
        "Grossa apergaminhada, com sulcos profundas": 20,
    },
    formaOrelha: {
        "Chata disforme, pavilhão não encurvado": 0,
        "Pavilhão parcialmente encurvado na borda": 8,
        "Pavilhão parcialmente encurvado em toda parte superior": 16,
        "Pavilhão totalmente encurvado": 24,
    },
    glandulaMamaria: {
        "Não palpável": 0,
        "Palpável, menos de 0,5 cm": 5,
        "Entre 0,5 e 1 cm": 10,
        "Maior de 1 cm": 15,
    },
};

function CalculadoraCapurroSomatico() {
    const [formacaoMamilo, setFormacaoMamilo] = useState('');
    const [pregasPlantares, setPregasPlantares] = useState('');
    const [texturaPele, setTexturaPele] = useState('');
    const [formaOrelha, setFormaOrelha] = useState('');
    const [glandulaMamaria, setGlandulaMamaria] = useState('');
    const [resultado, setResultado] = useState('Preencha todos os campos para ver o resultado.');

    useEffect(() => {
        if (formacaoMamilo && pregasPlantares && texturaPele && formaOrelha && glandulaMamaria) {
            const pontosFormacaoMamilo = CAPURRO_SOMATICO_POINTS.formacaoMamilo[formacaoMamilo] || 0;
            const pontosPregasPlantares = CAPURRO_SOMATICO_POINTS.pregasPlantares[pregasPlantares] || 0;
            const pontosTexturaPele = CAPURRO_SOMATICO_POINTS.texturaPele[texturaPele] || 0;
            const pontosFormaOrelha = CAPURRO_SOMATICO_POINTS.formaOrelha[formaOrelha] || 0;
            const pontosGlandulaMamaria = CAPURRO_SOMATICO_POINTS.glandulaMamaria[glandulaMamaria] || 0;

            const somaPontos = pontosFormacaoMamilo + pontosPregasPlantares + pontosTexturaPele + pontosFormaOrelha + pontosGlandulaMamaria;
            const idadeGestacional = (somaPontos + 204) / 7;

            const semanas = Math.floor(idadeGestacional);
            const dias = Math.round((idadeGestacional - semanas) * 7);

            setResultado(`Idade Gestacional: ${semanas} semanas e ${dias} dia(s)`);
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [formacaoMamilo, pregasPlantares, texturaPele, formaOrelha, glandulaMamaria]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Idade Gestacional por Capurro (Somático)</h1>
            <div className='form-gad7'>
            <div>
                <label>Formação do Mamilo:</label>
                <select value={formacaoMamilo} onChange={e => setFormacaoMamilo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Apenas visível, sem aréola">Apenas visível, sem aréola</option>
                    <option value="Aréola bem definida - diâmetro menor que 0,75 cm">Aréola bem definida - diâmetro menor que 0,75 cm</option>
                    <option value="Aréola bem definida, borda não elevada - diâmetro maior que 0,75 cm">Aréola bem definida, borda não elevada - diâmetro maior que 0,75 cm</option>
                    <option value="Borda elevada - diâmetro maior que 0,75 cm">Borda elevada - diâmetro maior que 0,75 cm</option>
                </select>
            </div>
            <div>
                <label>Pregas Plantares:</label>
                <select value={pregasPlantares} onChange={e => setPregasPlantares(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Sem pregas">Sem pregas</option>
                    <option value="Marcas mal definidas sobre a parte anterior da planta">Marcas mal definidas sobre a parte anterior da planta</option>
                    <option value="Marcas bem definidas sobre a metade anterior e sulcos no terço anterior">Marcas bem definidas sobre a metade anterior e sulcos no terço anterior</option>
                    <option value="Sulcos em mais da metade anterior da planta">Sulcos em mais da metade anterior da planta</option>
                    <option value="Sulcos em toda planta">Sulcos em toda planta</option>
                </select>
            </div>
            <div>
                <label>Textura da Pele:</label>
                <select value={texturaPele} onChange={e => setTexturaPele(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Muito fina, gelatinosa">Muito fina, gelatinosa</option>
                    <option value="Fina e lisa">Fina e lisa</option>
                    <option value="Algo mais grossa, discreta descamação superficial">Algo mais grossa, discreta descamação superficial</option>
                    <option value="Grossa, sulcos superficiais, descamação nas mãos e pés">Grossa, sulcos superficiais, descamação nas mãos e pés</option>
                    <option value="Grossa apergaminhada, com sulcos profundas">Grossa apergaminhada, com sulcos profundas</option>
                </select>
            </div>
            <div>
                <label>Forma de Orelha:</label>
                <select value={formaOrelha} onChange={e => setFormaOrelha(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Chata disforme, pavilhão não encurvado">Chata disforme, pavilhão não encurvado</option>
                    <option value="Pavilhão parcialmente encurvado na borda">Pavilhão parcialmente encurvado na borda</option>
                    <option value="Pavilhão parcialmente encurvado em toda parte superior">Pavilhão parcialmente encurvado em toda parte superior</option>
                    <option value="Pavilhão totalmente encurvado">Pavilhão totalmente encurvado</option>
                </select>
            </div>
            <div>
                <label>Glândula Mamária:</label>
                <select value={glandulaMamaria} onChange={e => setGlandulaMamaria(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Não palpável">Não palpável</option>
                    <option value="Palpável, menos de 0,5 cm">Palpável, menos de 0,5 cm</option>
                    <option value="Entre 0,5 e 1 cm">Entre 0,5 e 1 cm</option>
                    <option value="Maior de 1 cm">Maior de 1 cm</option>
                </select>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraCapurroSomatico;
