import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
    max-width: 800px;
    margin: auto;
    padding: 20px;
`;

const Title = styled.h1`
    text-align: center;
    color: #A70008;
`;

const SearchInput = styled.input`
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #A70008;
    border-radius: 4px;
    &:hover {
        border: 1px solid #A70008;
        }

        &:active {
            border: 1px solid #A70008;
            }

            &:focus {
                border: 1px solid #A70008;
                }
`;

const List = styled.div`
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    color: #A70008 !important;
`;

const Section = styled.div`
    width: 100%;
`;

const DropdownTitle = styled.h2`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    color: #A70008 !important;
    padding: 8px 10px;
    border-bottom: 2px solid #A70008;
    &:hover {
        background: #dddddd;
    }
`;

const DropdownMenu = styled.ul`
    padding: 20px;
    border: 1px solid #ddd;
    margin: 0px;
    margin-top: 5px;
    background-color: #f9f9f9;
    border-radius: 4px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #A70008 !important;
`;

const DropdownItem = styled.li`
    list-style-type: none;
    margin: 5px 0;
    width: 100%;
    color: #A70008 !important;
    &:visited {
        color: #A70008 !important;
    }
`;

const DropdownLink = styled(Link)`
    color: #A70008 !important;
    text-decoration: none;
    font-size: 16px;
    &:hover {
        text-decoration: underline;
    }
`;

const BackButton = styled(Link)`
    background: none !important;
    color: #A70008 !important;
    text-align: left !important;
    font-size: 40px;
    margin-bottom: 0px !important;
    text-decoration: none;
`;

function ListaCalcPage() {
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const toggleDropdown = (index) => {
        setDropdownOpen(dropdownOpen === index ? null : index); // Alterna entre abrir e fechar o dropdown
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const sections = [
        {
            title: 'Prática',
            links: [
                { to: '/calc/plantoes', label: 'Divisão de Descanso no Plantão' },
            ],
        },
        {
            title: 'Gerais',
            links: [
                { to: '/calc/imc', label: 'Calculadora de IMC' },
                { to: '/calc/GINA', label: 'Calculadora de Controle da Asma (GINA)' },
                { to: '/calc/camicu', label: 'CAM-ICU (Delirium)' },
                { to: '/calc/cage', label: 'Escore CAGE (Alcoolismo)' },
                { to: '/calc/gasa', label: 'Gradiente de Albumina Soro-Ascite (GASA)' },
                { to: '/calc/ist', label: 'Índice de Saturação de Transferrina (IST)' },
                { to: '/calc/conversaoesteroides', label: 'Conversão de Esteroides' },
            ],
        },
        {
            title: 'Ventilação',
            links: [
                { to: '/calc/gasometria', label: 'Interpretador de Gasometria Arterial' },
                { to: '/calc/sarafio2', label: 'Relação PaO2/FiO2 (SARA)' },
            ],
        },
        {
            title: 'Escores Clínicos',
            links: [
                { to: '/calc/CargaTabagica', label: 'Carga Tabágica' },
                { to: '/calc/NYHA', label: 'Calculadora NYHA' },
                { to: '/calc/childpugh', label: 'Escore de Child Pugh (Cirrose)' },
                { to: '/calc/das28crp', label: 'Calculadora DAS28 (CRP)' },
                { to: '/calc/das28vhs', label: 'Calculadora DAS28 (VHS)' },
                { to: '/calc/ckdepi', label: 'Clearance de Creatinina (CKD-EPI)' },
                { to: '/calc/WellsTEP', label: 'Escore de Wells para TEP' },
                { to: '/calc/framingham', label: 'Escore de Framingham' },
                { to: '/calc/centor', label: 'Escore de Centor Modificado' },
                { to: '/calc/nihss', label: 'Escore NIHSS (AVE)' },
                { to: '/calc/alvarado', label: 'Escore de Alvarado (Apendicite)' },
                { to: '/calc/homair', label: 'HOMA-IR' },
                { to: '/calc/gad7', label: 'GAD-7' },
                { to: '/calc/phq9', label: 'PHQ-9' },
                { to: '/calc/heart', label: 'HEART - Eventos Cardiovasculares' },
            ],
        },
        {
            title: 'Escalas',
            links: [
                { to: '/calc/glasgowpupilar', label: 'Escala de Glasgow com Resposta Pupilar' },
                { to: '/calc/fisher', label: 'Escala de Fisher (Hemorragia Subaracnoide)' },
            ],
        },
        {
            title: 'Função Renal',
            links: [
                { to: '/calc/ckdepi', label: 'Clearance de Creatinina (CKD-EPI)' },
                { to: '/calc/cockcroftGault', label: 'Clearance de Creatinina (Cockcroft-Gault)' },
                { to: '/calc/excrecaoSodio', label: 'Fração de Excreção de Sódio' },
                { to: '/calc/calcProtCreat', label: 'Relação Proteína/Creatinina Urinária' },
            ],
        },
        {
            title: 'Obstétricas',
            links: [
                { to: '/calc/igdum', label: 'Idade Gestacional pela DUM' },
                { to: '/calc/igusg', label: 'Idade Gestacional pelo USG' },
            ],
        },
        {
            title: 'Pediátrica',
            links: [
                { to: '/calc/apgar', label: 'Escore APGAR' },
                { to: '/calc/imcpedmasc', label: 'Calculadora de IMC Pediátrico (Masc)' },
                { to: '/calc/imcpedfem', label: 'Calculadora de IMC Pediátrico (Fem)' },
                { to: '/calc/capurro', label: 'Idade Gestacional por Capurro' },
            ],
        },
        {
            title: 'Drogas Vasoativas',
            links: [
                { to: '/calc/adrenalina', label: 'Adrenalina' },
                { to: '/calc/dobutamina', label: 'Dobutamina' },
                { to: '/calc/dopamina', label: 'Dopamina' },
                { to: '/calc/nitroglicerina', label: 'Nitroglicerina' },
                { to: '/calc/noradrenalina', label: 'Noradrenalina' },
            ],
        },
        {
            title: 'Drogas Sedativas',
            links: [
                { to: '/calc/midazolam', label: 'Midazolam' },
                { to: '/calc/fentanil', label: 'Fentanil' },
                { to: '/calc/cetamina', label: 'Cetamina' },
                { to: '/calc/propofol', label: 'Propofol' },
            ],
        },
        {
            title: 'Eletrólitos',
            links: [
                { to: '/calc/sodiocorrigido', label: 'Sódio Corrigido' },
                { to: '/calc/magnesiocorrigido', label: 'Magnésio Corrigido' },
                { to: '/calc/calciocorrigido', label: 'Cálcio Corrigido' },
                { to: '/calc/deficitagualivre', label: 'Déficit de Água Livre' },
            ],
        },
    ];

    const filteredSections = sections.map((section, index) => {
        const filteredLinks = section.links.filter(link =>
            link.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return {
            ...section,
            links: filteredLinks,
            isOpen: filteredLinks.length > 0 ? index : null
        };
    }).filter(section => section.links.length > 0);

    useEffect(() => {
        if (searchTerm) {
            const firstOpenDropdown = filteredSections.find(section => section.isOpen !== null);
            if (firstOpenDropdown) {
                setDropdownOpen(firstOpenDropdown.isOpen);
            } else {
                setDropdownOpen(null); // Feche o dropdown se não houver correspondência
            }
        }
    }, [searchTerm, filteredSections]);

    return (
        <Container>
            <BackButton to="/bedside">&lt;</BackButton>
            <Title>Calculadoras Médicas</Title>
            <SearchInput
                type="text"
                placeholder="Buscar calculadora..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            <List>
                {filteredSections.map((section, index) => (
                    <Section key={index}>
                        <DropdownTitle onClick={() => toggleDropdown(index)}>
                            <span>{section.title}</span>
                            <i className={`fi ${dropdownOpen === index ? 'fi-ss-angle-small-up' : 'fi-ss-angle-small-down'}`}></i>
                        </DropdownTitle>
                        {(dropdownOpen === index || (searchTerm && section.isOpen === index)) && (
                            <DropdownMenu>
                                {section.links.map((link, linkIndex) => (
                                    <DropdownItem key={linkIndex}>
                                        <DropdownLink to={link.to}>{link.label}</DropdownLink>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        )}
                    </Section>
                ))}
            </List>
        </Container>
    );
}

export default ListaCalcPage;
