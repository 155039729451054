import { dateSlashIntoDateUnderline, measureTime } from "../utils/Utils"
import firebase from 'firebase/compat/app'
import { db, storage } from './../firebase/firebase-setup'
import { sortMonths } from '../utils/Utils'
import { getDailyUse, sumDailyData } from "../statistics/DailyUseMethod"
import { loadNumberCards } from "../statistics/TotalCards"


class AmbassadorsController {
    constructor() {
        this.dataLoaded = false
        
        /*
            Respectivamente, guardam dados dos documentos:
                embaixadores_dashboard/coupons
                embaixadores_dashboard/registry
                embaixadores_dashboard/consolidated_comissions
                embaixadores_dashboard/consolidated_profile_data
                embaixadores_dashboard/institutions
                embaixadores_dashboard/schools_with_ambassadors
        */
        this.coupons = undefined
        this.registry = undefined
        this.consolidatedComissions = undefined
        this.consolidatedProfiles = undefined
        this.institutions = undefined
        this.schoolsWithAmbassadors = undefined


        // Mapeia uid do embaixador para o cupom atual dele.
        this.uidToCoupon = {}

        // Lista de todas as instituições, i.e., faculdades de medicina
        this.allInstitutions = []

        // Lista de instituições onde já temos embaixadores, mas não lista
        // quais ou quantos
        this.institutionsWithAmbassadors = []


        // Lista de instituições sem embaixadores
        this.institutionsWithoutAmbassadors = []


        // Lista, para os embaixadores *ativos*, as comissões mês a mês.
        this.activeAmbassadorsComissions = {}

        // Lista de embaixadores ativos, com onboarding pendente, e removidos
        this.activeAmbassadors = []
        this.pendingOnboardingAmbassadors = []
        this.terminatedAmbassadors = []

        // Relação, mês a mês, de embaixadores novos, removidos, e cumulativos. O dado está
        // formatado de modo meio confuso, mas... é para facilitar seu uso do gráfico.
        this.monthlyAmbassadorCount = []


        // Array com strings de todos os meses, do início do programa ao atual
        // 2023_7, 2023_8, ..., 2024_5, etc.
        this.monthRange = []


        // Para cada cupom, e no total, mostra a quantidade de comissão acumulada até agora.
        this.consolidatedPostponed = {}

        // Para cada embaixador lista há quantos meses está no programa, quantas vendas fez
        // até agora, e a média de vendas por mês.
        this.consolidatedSalesData = []

        // Status das comissões, mês a mês
        this.currentComissionStatus = {}


        // Uso da plataforma: flashcards até hoje e nos últimos 30/60 dias
        this.appData = {}
    }


    async init() {
        await this.loadData()
        this.prepareUidToCoupon()
        this.prepareInstitutions()
        this.prepareMonthRange()
        this.prepareConsolidatedSalesData()
        this.prepareActiveIndividualComission()

        // Requer monthRange
        this.prepareMonthlyAmbassadorCount()
        this.prepareConsolidatedPostponedValues()
        await this.prepareComissionsStatus()

        // Outros

        // Requer prepareConsolidatedSalesData
        await this.prepareAppUseData()
    }


    async loadData() {
        let promises = [
            db.doc('embaixadores_dashboard/coupons').get(),
            db.doc('embaixadores_dashboard/registry').get(),
            db.doc('embaixadores_dashboard/consolidated_comissions').get(),
            db.doc('embaixadores_dashboard/consolidated_profile_data').get(),
            db.doc('embaixadores_dashboard/institutions').get(),
            db.doc('embaixadores_dashboard/schools_with_ambassadors').get()
        ]

        try {
            const docs = await measureTime(
                () => Promise.all(promises), 
                'AmbassadorsController: loaded all data'
            )

            this.coupons = docs[0].data() ?? {}
            this.registry = docs[1].data() ?? {}
            this.consolidatedComissions = docs[2].data() ?? {}
            this.consolidatedProfiles = docs[3].data()['data'] ?? {}
            this.institutions = docs[4].data() ?? {}
            this.schoolsWithAmbassadors = docs[5].data() ?? {}
    
            this.dataLoaded = true

        } catch (error) {
            console.error('AmbassadorController: error downloading data', error)
            this.dataLoaded = false
            throw error
        }
    }


    prepareUidToCoupon() {
        // Prepara objeto que mapeia uid para o cupom ativo.
        this.uidToCoupon = {}
        this.couponToUid = {}

        for (let coupon in this.coupons) {
            if (this.coupons[coupon]['isActive']) {
                this.uidToCoupon[ this.coupons[coupon]['embaixador_uid'] ] = coupon
                this.couponToUid[coupon] = this.coupons[coupon]['embaixador_uid']
            }
        }
    }


    prepareInstitutions() {
        this.allInstitutions = Object.keys(this.institutions)

        this.institutionsWithAmbassadors = Object.keys(this.schoolsWithAmbassadors)

        this.institutionsWithoutAmbassadors = this.allInstitutions.filter(
            inst => !this.institutionsWithAmbassadors.includes(inst)
        )
    }


    prepareActiveIndividualComission() {
        // Filtramos para os embaixadores ativos
        this.activeAmbassadorsComissions = this.consolidatedComissions['data'].filter(ambassadorData => {
            const uid = this.coupons[ambassadorData.coupon]['embaixador_uid']

            if (this.registry[uid]['status'] === 'active' && this.registry[uid]['onboarding_complete']) {
                return true
            }
        })
    }


    prepareMonthlyAmbassadorCount() {
        // conta quantos embaixadores temos, mês a mês.
        const counts = {}

        this.activeAmbassadors = []
        this.pendingOnboardingAmbassadors = []
        this.terminatedAmbassadors = []

        this.monthRange.forEach(month_str => 
            counts[month_str] = { 
                newAmbassadors: 0, 
                terminatedAmbassadors: 0
            }
        )
    
        Object.entries(this.registry).forEach(([uid, 
            { signup_date, status, onboarding_complete, current_coupon, termination_date}]) => {
            
            const signup_month = dateSlashIntoDateUnderline(signup_date)
            counts[signup_month].newAmbassadors += 1

            // Se é ativo, ou tem onboarding complete ou não. Se não é ativo, foi excluído.
            // Ou pelo menos essa é a lógica...
            if (status === 'active') {
                if (onboarding_complete) {
                    this.activeAmbassadors.push(current_coupon)
                }
                else {
                    this.pendingOnboardingAmbassadors.push(current_coupon)
                }
            }
            else {
                this.terminatedAmbassadors.push(current_coupon)
                const termination_month = dateSlashIntoDateUnderline(termination_date)
                counts[termination_month].terminatedAmbassadors += 1
            }

        })
        
        // Convertendo para o array final e calculando o cumulativo
        this.monthlyAmbassadorCount = []
        let cumulative = 0

        Object.keys(counts).sort(sortMonths).forEach(month => {
            const added = counts[month].newAmbassadors
            const removed = counts[month].terminatedAmbassadors
            cumulative += (added - removed)

            this.monthlyAmbassadorCount.push({
                month,
                newAmbassadors: added,
                terminatedAmbassadors: removed,
                cumulativeAmbassadors: cumulative
            })
        })
    }


    prepareMonthRange() {
        // Geramos strings de todos os meses desde o início do programa até o mês atual.
        // Os meses em JavaScript vã0 de 0 a 11, razão pela qual subtraímos 1 aqui, e adicionamos
        // 1 na construção da string.
        const startDate = new Date(2023, 7 - 1)
        const currentDate = new Date()
        this.monthRange = []
        
        while (startDate <= currentDate) {
            this.monthRange.push(`${startDate.getFullYear()}_${startDate.getMonth() + 1}`) 
            startDate.setMonth(startDate.getMonth() + 1)
        }
    }


    prepareConsolidatedPostponedValues() {
        /*
            Entretenha que o mês atual é maio, e o anterior é abril.

            Se o acumulado atual é != de 0, significa que ele acumulou no mês passado.
            O  que há a ser pago é a comissão atual, mas o acumulado.

            Se o acumulado atual é = 0, significa que...
                1. Ele tem comissão +- acumulado a receber, mas ainda não tomou decisão
                2. Ele tinha comissão +- acumulado a recebe, e *já sacou*
                3. Ele não tinha nem comissão nem acumulado.
                
            O caso (1) é infeliz, porque nós não mapeamos que estamos devendo esse dinheiro.

            Mas o intuito aqui é calcular o acumulado, e não a comissão total a receber.
        */

        
        this.consolidatedPostponed = {
            'data' : [
                {
                    coupon : 'Total',
                    postponed : 0,
                }
            ]
        }

        const length = this.monthRange.length
        const currentMonth = this.monthRange[length - 1]
        const previousMonth = this.monthRange[length - 2]

        this.consolidatedComissions['data'].forEach(({coupon, total, ...months}) => {

            let currentPostponed = months[currentMonth]?.['totalPostponedValue'] ?? 0
            let lastPostponed = months[previousMonth]?.['totalPostponedValue'] ?? 0            

            
            let postponedValue = (currentPostponed != 0) ? (currentPostponed) : (lastPostponed)

            this.consolidatedPostponed['data'].push({
                coupon : coupon,
                postponed : postponedValue
            })

            this.consolidatedPostponed['data'][0]['postponed'] += postponedValue
        })
    }


    prepareConsolidatedSalesData() {
        this.consolidatedSalesData = []

        Object.entries(this.registry).forEach(([uid, {status, signup_date, current_coupon, onboarding_complete}]) => {
            if (status !== 'active' || !onboarding_complete) {
                return
            }

            // Converter signup_date para objeto Date
            const signupDateObj = new Date(signup_date.split("/").reverse().join("-"))
            
            // Calcular a diferença de tempo
            const hoje = new Date()
            const diferencaTempo = hoje.getTime() - signupDateObj.getTime()
            const diferencaMeses = diferencaTempo / (1000 * 3600 * 24 * 30)

            let totalSales = 0
            let monthsSinceLastSale = 0
            let lastSaleFound = false


        // Iterar de trás para frente, do mês atual até o mês da data de inscrição
        for (let i = 0; i < diferencaMeses; i++) {
            const currentMonth = new Date(hoje.getFullYear(), hoje.getMonth() - i, 1)
            const monthKey = `${currentMonth.getFullYear()}_${currentMonth.getMonth() + 1}`

            const item = this.consolidatedComissions['data'].find(item => item.coupon === current_coupon)

            if (item && item[monthKey] && item[monthKey].total_sales > 0) {
                totalSales += item[monthKey].total_sales
                if (!lastSaleFound) {
                    monthsSinceLastSale = i
                    lastSaleFound = true
                }
            }
        }

        if (!lastSaleFound) {
            monthsSinceLastSale = diferencaMeses
        }

            // const totalSales = this.consolidatedComissions['data'].reduce((acc, item) => {
            //     if(item.coupon === current_coupon) {
            //         console.log(item)
            //         acc += item.total.total_sales
            //     }
            //     return acc
            // }, 0)

            const avg = totalSales / diferencaMeses

            this.consolidatedSalesData.push({
                'uid' : uid,
                'coupon' : current_coupon,
                'months_since_signup' : diferencaMeses,
                'total_sales' : totalSales,
                'avg_sales_per_month' : avg,
                'months_since_last_sale' : monthsSinceLastSale,
            })
        })
    }


    async prepareComissionsStatus() {
        this.currentComissionStatus = {}

        for (const month of this.monthRange) {
            this.currentComissionStatus[month] = {
                'postponed': [],
                'waiting_payment': [],
                'received_payment': [],
                'pending_decision': [],
                'no_comission': [],
            }
    
            const promises = this.consolidatedComissions['data'].map(async ({ coupon, total, ...months }) => {
                const uid = this.couponToUid[coupon]
                const lastMonth = month // Definir lastMonth para o mês atual do loop
    
                if (months[lastMonth] && (months[lastMonth]['comission'] !== 0 || months[lastMonth]['totalPostponedValue'] !== 0)) {
                    const doc = await db.doc(`users/${uid}/ambassador/comission/consolidated/${lastMonth}`).get()
    
                    if (doc.exists) {
                        const comission = doc.data()

                        if (comission.decisionMade) {
                            if (comission.ambassadorPostponed) {
                                return { status: 'postponed', coupon: coupon }
                            }
                            else if (comission.paymentReceived) {
                                return { status: 'received_payment', coupon: coupon }
                            } else {
                                return { status: 'waiting_payment', coupon: coupon }
                            }
                        } else {
                            return { status: 'pending_decision', coupon: coupon }
                        }
                    } else {
                        if (this.registry[uid] && this.registry[uid]['status'] === 'active') {
                            return { status: 'no_comission', coupon: coupon }
                        }
                    }
                } else {
                    if (this.registry[uid] && this.registry[uid]['status'] === 'active') {
                        return { status: 'no_comission', coupon: coupon }
                    }
                }
    
                return null
            })
    
            const results = await Promise.all(promises)
    
            results.filter(result => result !== null).forEach(({ status, coupon }) => {
                this.currentComissionStatus[month][status].push(coupon)
            })
    
        }
    }


    async prepareAppUseData() {
        // Para cada embaixador, obtemos quantos cards fez até hoje e nos últimos 30 e 60 dias.

        console.log('\t\t\t OLÁ!')
        console.log(this.registry)

        const uids = Object.keys(this.registry)
        // const uids = ['d5of3WDO4Na69IFadFJ4jEGkV982', '1Ez93U6WEbcrr4NE3qvh70TN05O2', '5TkhVQXzmaXBGaGC3sA1mT0upsq1']

        const userPromises = uids.map(async uid => {
            try {
                const [last30, last60, total] = await Promise.all([
                    getDailyUse(uid, 'Flashcards', 30),
                    getDailyUse(uid, 'Flashcards', 60),
                    loadNumberCards(uid)
                ])

                const summedLast30 = sumDailyData(last30)
                const summedLast60 = sumDailyData(last60)
                
                return {
                    uid,
                    data: { summedLast30, summedLast60, total }
                }
            } catch (error) {
                console.error(`Erro processando usuário ${uid}:`, error)
                return {
                    uid,
                    error: true
                }
            }
        })

        // Processa todas as promessas em paralelo
        const results = await Promise.all(userPromises)

        // Atualiza this.appData com os resultados
        results.forEach(result => {
            if (!result.error) {
                this.appData[result.uid] = result.data
            }
        })


        // Colocamos na tabelaConsolidada
        console.log(this.appData)
        console.log(this.consolidatedSalesData)

        this.consolidatedSalesData = this.consolidatedSalesData.map(salesData => {
            const userData = this.appData[salesData.uid]

            console.log(salesData.uid)

            console.log(userData)
            
            if (userData) {
                return {
                    ...salesData,
                    tests_last_30: userData.summedLast30.nTestsAnswered || 0,
                    tests_last_60: userData.summedLast60.nTestsAnswered || 0,
                    total_tests: userData.total || 0
                }
            }
            
            // Se não encontrar dados do usuário, retorna dados originais com -1
            return {
                ...salesData,
                tests_last_30: -1,
                tests_last_60: -1,
                total_tests: -1
            }
        })
    }
    

    async changeAmbassadorCoupon(ambassador, nextCoupon) {
        const { uid, current_coupon: previousCoupon } = ambassador

        // Em teoria, o cupom já foi validado por outra função. Nós não
        // verificamos isso aqui (!)

        // 1. Mudamos o documento coupons
        await db.doc(`embaixadores_dashboard/coupons`).update({
            [`${previousCoupon}.isActive`]: false,
            [`${previousCoupon}.status`]: 'coupon_switched',
            [`${nextCoupon}.isActive`]: true,
            [`${nextCoupon}.status`]: 'currently_used',
            [`${nextCoupon}.embaixador_uid`]: uid,
        }, { merge: true })


        // 2. Mudamos o ativo e os antigos em registry
        await db.doc(`embaixadores_dashboard/registry`).update({
            [`${uid}.current_coupon`]: nextCoupon,
            [`${uid}.previous_coupons`]: firebase.firestore.FieldValue.arrayUnion(previousCoupon)
        }, { merge: true })


        // 3. Identico em personal registry
        await db.doc(`/users/${uid}/ambassador/personal_registry`).update({
            'current_coupon': nextCoupon,
            'previous_coupons': firebase.firestore.FieldValue.arrayUnion(previousCoupon)
        }, { merge: true })


        // 4. Mudar em consolidated_profile_data
        const index = this.consolidatedProfiles.findIndex(profile => profile.uid === uid)
        if (index !== -1) {
            this.consolidatedProfiles[index].current_coupon = nextCoupon
        }

        await db.doc(`embaixadores_dashboard/consolidated_profile_data`).update({
            'data' : this.consolidatedProfiles
        })
    }


    async changeSchoolTerm(ambassador, updatedData) {
        const { uid } = ambassador


        // Atualizamos em consolidated_profile_data
        const index = this.consolidatedProfiles.findIndex(profile => profile.uid === uid)
        if (index !== -1) {
            this.consolidatedProfiles[index].school_stage = updatedData
        
            await db.doc(`embaixadores_dashboard/consolidated_profile_data`).update({
                'data' : this.consolidatedProfiles
            })
        } else {
            console.log(`Erro ao atualizar o termo da escola para ${uid}`)
            console.log(ambassador)
            console.log(updatedData)
        }
    }


    async uploadInvoice(file, uid, selectedMonth) {
        if (!file || !uid || !selectedMonth) {
            console.log('Erro ao enviar invoice, cancelando...')
             return
        }

        const storageRef = storage.ref(`notas_fiscais/${uid + selectedMonth}`)
        const result = await storageRef.put(file)
        const downloadURL = await result.ref.getDownloadURL()
        
        const currentComission = `users/${uid}/ambassador/comission/consolidated/${selectedMonth}`

        await db.doc(currentComission).set({
            'invoiceUploaded' : true,
            'downloadURL' : downloadURL,
        }, {merge: true})
    }


    async removeInvoice(uid) {
        const currentMonth = this.monthRange[this.monthRange.length - 2]
        const currentComission = `users/${uid}/ambassador/comission/consolidated/${currentMonth}`

        await db.doc(currentComission).set({
            'invoiceUploaded' : false,
            'decisionMade' : false,
            'ambassadorPostponed' : false,
            'pixGiven' : false,
            'pixKey' : firebase.firestore.FieldValue.delete(),
            'downloadURL' : firebase.firestore.FieldValue.delete(),
        }, {merge: true})
    }


    async approveAmbassador(ambassador) {
        const { uid, current_coupon: coupon } = ambassador


        // Modificamos o personal_registry no Firebase e a cópia local, 
        // para marcar que o onboarding foi completado
        await db.doc(`users/${uid}/ambassador/personal_registry`).set({
            'onboarding_complete' : true
        }, {merge : true})
        

        // Modificamos o coupons, para sinalizar que o cupom está disponível, e pode ser usado
        await db.doc(`/embaixadores_dashboard/coupons`).set({
            [coupon]: {
                'isActive': true,
                'status' : 'currently_used'
            }
        }, { merge: true })


        // Modificamos o registry
        await db.doc(`/embaixadores_dashboard/registry`).set({
            [uid] : {
                'onboarding_complete' : true
            }
        }, {merge : true})
    }


    async cancelOnboarding(ambassador) {
        const { uid, current_coupon: coupon, school } = ambassador



        // Consertamos consolidated_profile_data
        const indexToRemove = this.consolidatedProfiles.findIndex(profile => profile.uid === uid)
        if (indexToRemove !== -1) {
            this.consolidatedProfiles.splice(indexToRemove, 1)
        }


        await db.doc(`embaixadores_dashboard/consolidated_profile_data`).set({
            'data' : this.consolidatedProfiles
        })


        // Consertamos coupons
        await db.doc(`embaixadores_dashboard/coupons`).update({
            [coupon] : firebase.firestore.FieldValue.delete()
        })


        // Consertamos registry
        await db.doc(`embaixadores_dashboard/registry`).update({
            [uid] : firebase.firestore.FieldValue.delete()
        })


        // Consertamos schools_with_ambassadors
        if (this.schoolsWithAmbassadors[school].length === 1) {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : firebase.firestore.FieldValue.delete()
            })
        }
        else {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : firebase.firestore.FieldValue.arrayRemove(uid)
            })
        }


        // Deletamos o documento do embaixador
        await db.doc(`/users/${uid}/ambassador/personal_registry`).delete()
    }


    async terminateAmbassador(ambassador, termination_reason) {
        const { uid, current_coupon: coupon, school } = ambassador

        const hoje = new Date()
        const dataFormatada = hoje.toLocaleDateString('pt-BR')

        // 1. Não alteramos consolidated_profile_data. O isActive de lá diz respeito à 
        // assinatura.

        // 2. No documento dos cupons, colocamos o atual como inativo.
        await db.doc(`embaixadores_dashboard/coupons`).update({
            [`${coupon}.isActive`]: false,
            [`${coupon}.status`]: 'ambassador_terminated'
        }, { merge: true })
        

        // 3. Consertamos registry, explicitando que o embaixador está inativo.
        // Essa formatação é fundamental para ser um deep merge.
        await db.doc(`embaixadores_dashboard/registry`).update({
            [`${uid}.status`]: 'ambassador_terminated',
            [`${uid}.termination_date`]: dataFormatada,
            [`${uid}.termination_reason`]: termination_reason
        }, { merge: true })


        // 4. Consertamos schools_with_ambassadors. Nós removemos o embaixador, afinal a ideia
        // aqui é "schools with ACTIVE ambassadors"
        if (this.schoolsWithAmbassadors[school].length === 1) {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : firebase.firestore.FieldValue.delete()
            })
        }
        else {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : firebase.firestore.FieldValue.arrayRemove(uid)
            })
        }

        // 5. No documento do embaixador, modificamos o seu status.
        // Aqui não precisa da nomenclatura de deep merge, pois não são dicionários aninhados
        await db.doc(`/users/${uid}/ambassador/personal_registry`).update({
            'status' : 'ambassador_terminated',
            'termination_date' : dataFormatada, 
            'termination_reason' : termination_reason
        }, {merge: true})
    }


    async restituteAmbassador(ambassador) {
        const { uid, current_coupon: coupon, school } = ambassador

        // Lógica inversa de terminateAmbassador
        const hoje = new Date()
        const dataFormatada = hoje.toLocaleDateString('pt-BR')


        await db.doc(`embaixadores_dashboard/coupons`).update({
            [`${coupon}.isActive`]: true,
            [`${coupon}.status`]: 'currently_used'
        }, { merge: true })
        

        await db.doc(`embaixadores_dashboard/registry`).update({
            [`${uid}.status`]: 'active',
            [`${uid}.restitution_date`]: dataFormatada,

            [`${uid}.termination_date`]: firebase.firestore.FieldValue.delete(),
            [`${uid}.termination_reason`]: firebase.firestore.FieldValue.delete()
        }, { merge: true })

        if (!this.schoolsWithAmbassadors[school] || this.schoolsWithAmbassadors[school].length === 0) {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : [ uid ]
            })
        }
        else {
            await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).update({
                [school] : firebase.firestore.FieldValue.arrayUnion(uid)
            })
        }

        await db.doc(`/users/${uid}/ambassador/personal_registry`).update({
            'status' : 'active',
            [`restitution_date`]: dataFormatada,

            [`termination_date`]: firebase.firestore.FieldValue.delete(),
            [`termination_reason`]: firebase.firestore.FieldValue.delete()
        }, {merge: true})
    }
}


export default new AmbassadorsController()