import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraGasometriaArterial() {
    const [pH, setPH] = useState('');
    const [pCO2, setPCO2] = useState('');
    const [hco3, setHCO3] = useState('');
    const [na, setNa] = useState('');
    const [cl, setCl] = useState('');
    const [albumina, setAlbumina] = useState('');
    const [pCO2Esperado, setPCO2Esperado] = useState('');
    const [anionGap, setAnionGap] = useState('');
    const [anionGapCorrigido, setAnionGapCorrigido] = useState('');

    const calcularPCO2Esperado = () => {
        const pHNum = parseFloat(pH);
        const pCO2Num = parseFloat(pCO2);
        const hco3Num = parseFloat(hco3);

        if (!isNaN(pHNum) && !isNaN(pCO2Num) && !isNaN(hco3Num)) {
            const pCO2EsperadoCalc = (1.5 * hco3Num + 8).toFixed(2);
            setPCO2Esperado(pCO2EsperadoCalc);
        } else {
            setPCO2Esperado('');
        }
    };

    const calcularAnionGap = () => {
        const naNum = parseFloat(na);
        const clNum = parseFloat(cl);
        const hco3Num = parseFloat(hco3);
        const albuminaNum = parseFloat(albumina);

        if (!isNaN(naNum) && !isNaN(clNum) && !isNaN(hco3Num) && !isNaN(albuminaNum)) {
            const ag = naNum - (clNum + hco3Num);
            setAnionGap(ag.toFixed(2));

            const agCorrigido = ag + 2.5 * (4 - albuminaNum);
            setAnionGapCorrigido(agCorrigido.toFixed(2));
        } else {
            setAnionGap('');
            setAnionGapCorrigido('');
        }
    };

    useEffect(() => {
        calcularPCO2Esperado();
    }, [pH, pCO2, hco3]);

    useEffect(() => {
        if (pCO2Esperado) {
            calcularAnionGap();
        }
    }, [na, cl, albumina, pCO2Esperado]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Gasometria Arterial</h1>
            <div>
                <label>pH:</label>
                <input type="number" step="0.01" value={pH} onChange={e => setPH(e.target.value)} />
            </div>
            <div>
                <label>PCO2 (mmHg):</label>
                <input type="number" step="0.01" value={pCO2} onChange={e => setPCO2(e.target.value)} />
            </div>
            <div>
                <label>HCO3- (mEq/L):</label>
                <input type="number" step="0.01" value={hco3} onChange={e => setHCO3(e.target.value)} />
            </div>
            {pCO2Esperado && (
                <>
                    <h2>Valores Calculados</h2>
                    <div>
                        <label>PCO2 esperado (mmHg):</label>
                        <input type="text" value={pCO2Esperado} readOnly />
                    </div>
                    <div>
                        <label>Na+ (mEq/L):</label>
                        <input type="number" step="0.01" value={na} onChange={e => setNa(e.target.value)} />
                    </div>
                    <div>
                        <label>Cl- (mEq/L):</label>
                        <input type="number" step="0.01" value={cl} onChange={e => setCl(e.target.value)} />
                    </div>
                    <div>
                        <label>Albumina (g/dL):</label>
                        <input type="number" step="0.01" value={albumina} onChange={e => setAlbumina(e.target.value)} />
                    </div>
                    {anionGap && (
                        <>
                            <div>
                                <label>Ânion Gap (mEq/L):</label>
                                <input type="text" value={anionGap} readOnly />
                            </div>
                            <div>
                                <label>Ânion Gap corrigido (mEq/L):</label>
                                <input type="text" value={anionGapCorrigido} readOnly />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

export default CalculadoraGasometriaArterial;
