// Novembro 2024
//      Tanto FlashcardAnswerJSX quanto McqAnswerJSX precisam ser componentes
//      separados porque são usados no modo consulta.  
import session from '../controllers/Session'
import { htmlToReact } from '../utils/HtmlToReact'
import Separator from './Separator'
import { McqMetadata, contentStyle, seenContentStyle } from './FlashcardsStyles'
import styled, { css } from 'styled-components'
import { ColumnCSS, SelectableText } from '../components/BasicComponents'
import TestContainer from './TestContainer'


// Eu ia deixar igual aos botões de SRS, mas não ficou tão bom
// Isso aqui é para essas cores osbre fundos cinzas, e pode ser reutilizado em 
// outros contextos TODO TO DO
const redColorMCQ = css`
    color: ${props => props.theme.darkMode ? `#FF4D4D;` : `#8E0011;`}
`

const greenColorMCQ = css`
    color: ${props => props.theme.darkMode ? `rgb(90, 200, 40, 1);` : `rgb(20, 99, 0);`}
`

const blueColorMCQ = css`
    color: ${props => props.theme.darkMode ? `rgb(60, 140, 220);` : `rgb(39, 121, 196);`}
`


const Alternatives = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;
`

const Alternative = styled.p`
    margin: 0 0 1em 0.5em;

    ${seenContentStyle}


    ${props => props.isCorrect && css`
        ${greenColorMCQ}
        font-weight: bold;
    `}

    
    ${props => props.isChosen && css`
        font-weight: bold;
    `}
`


const AnswerArrow = styled.span`
    font-size: 1.2em;
    font-weight: bold;
    
    ${blueColorMCQ}
`


const MCQQuestionTextSeen = styled.div`
    ${seenContentStyle}
    text-align: justify;
`


const McqAnswerFeedback = styled.div`
    padding-top: 2em;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${SelectableText}
    ${contentStyle}
`;

const McqExplanations = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;

`;



const ExplMain = styled.div`
    margin-bottom: 2em;

    p {
        text-align: justify;
    }

`

// Cada um dos boxes das explicações das alternativas
const ExplAlternativeContainer = styled.div`
    // Precisamos alinhar como row porque colocamos a letra da alternativa.
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5em;
    margin-bottom: 1em;
    border-radius: 0.5em;

    align-self: flex-start;

    ${props =>
        props.theme.darkMode
          ? props.isRight
            ? css`
                background-color: rgba(40, 150, 40, 0.3);
              `
            : css`
                background-color: rgba(200, 60, 60, 0.15);
              `
          : props.isRight
          ? css`
                background-color: rgba(20, 99, 0, 0.3);
            `
          : css`
                background-color: rgba(142, 0, 17, 0.125);
            `}
`

const ExplAlternativeContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 0.5em;

    p:last-of-type {
        margin: 0;
    }
`;


const ExplAlternativeLetter = styled.span`
    font-weight: bold;
    font-size: 1.5em;
    margin-right: 0.3em;
    align-self: center;

    ${props => props.isCorrect ? greenColorMCQ : redColorMCQ }
`


const AnswerFeedbackText = styled.p`
    font-size: 1.5em;
    font-weight: bold;
    margin: 0;
    text-align: center;
    ${props => props.isCorrect ? greenColorMCQ : redColorMCQ }
`




export default function MCQAnswerJSX({test, consultMode }) {
    const letters = [' A) ', ' B) ', ' C) ', ' D) ', ' E) ', 'F) ', 'G) ', 'H) '];


    // {/* TODO TO DO - Isso aqui permite que eu coloque imagens. Por outro lado, nesse
    // caso estarei colocando um <div> (da imagem) dentro de um <p> (veja acima),
    // o que é um problema. */}
    function buildAlternatives() {
        const alternatives = test.alternatives.map((alternative, index) => {

            // Teoricamente, test.answer e mcqChosenAnswer são números..
            const isCorrect = index === (test.answer - 1)
            const isChosen  = index === (session.mcqChosenAnswer - 1)

            return (
                <Alternative key={index} isCorrect={isCorrect} isChosen={isChosen}>
                    {isChosen && <AnswerArrow>→</AnswerArrow>}
                    {letters[index]}
                    {htmlToReact(alternative)}
                </Alternative>
            );
        });

        return alternatives;
    }


    function getFeedbackMessage() {
        if (consultMode) {
          return null
        }
        const isCorrect = session.mcqChosenAnswer == test.answer
        const msg = isCorrect ? 'Você acertou! ✓' : 'Você errou! ✗'
        return <AnswerFeedbackText isCorrect={isCorrect}>{msg}</AnswerFeedbackText>
      }



    function getExplanations() {
        let JSX = [];

        for (let i = 1; i < test.explanations.length; i++) {
            const expl = test.explanations[i];
            const isRight = i == test.answer;

            if (expl !== '' && expl !== 'null') {
                JSX.push(
                    <ExplAlternativeContainer key={i} isRight={isRight}>
                        <ExplAlternativeLetter isRight={isRight}>
                            {letters[i - 1]}
                        </ExplAlternativeLetter>
                        <ExplAlternativeContent>{htmlToReact(expl)}</ExplAlternativeContent>
                    </ExplAlternativeContainer>
                );
            }
        }

        return (
            <McqAnswerFeedback>
                <McqExplanations>
                    <ExplMain>
                        {htmlToReact(test.explanations[0])}
                    </ExplMain>
                    
                    {JSX.map(e => e)}
                </McqExplanations>
            </McqAnswerFeedback>
        );
    }


    function giveFeedback() {
        return (
            <>
                {getFeedbackMessage()}

                {test.observations.includes('ANULADA') &&
                    <p className='mcq-answer-feedback-anulada'>(Essa questão foi anulada)</p>
                }
                {
                    getExplanations()
                }
            </>
        )
    }


    return (
        <TestContainer>
            <McqMetadata>
                <strong>{test.institution} ({test.year})</strong>
            </McqMetadata>

            <MCQQuestionTextSeen>
                {htmlToReact(test.getQuestion())}
            </MCQQuestionTextSeen>

            <Separator />

            <Alternatives>
                {buildAlternatives()}
            </Alternatives>

            {giveFeedback()}
        </TestContainer>    
    )

}