import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'

import UserScreenCard from "./UserScreenCard";

import SubscriptionIcon from './../assets/credit_card_icon.png' 

import './PaymentRefused.css'
import NiceButton from '../components/NiceButton';
import { sleep } from '../utils/Utils';
import { setIsLoading } from '../redux/loadingSlice';
import { db } from '../firebase/firebase-setup';
import { eraseSubscription } from '../firebase/firebaseUtils';
import OslerButton from '../components/OslerButton';


export default function PaymentRefused(props) {

    const user = useSelector(state => state.user.data)
    const dispatch = useDispatch();

    const [reason, setReason] = useState("")


    useEffect(() => {
        if (user && user.subscription && user.subscription.reason) {
            setReason( user.subscription.reason.toLowerCase() )
        }
        else {
            setReason( '(não informou razão)')
        }
    }, [])


    async function eraseCurrentTransaction() {
        dispatch(setIsLoading(true))
        
        // Resetar a subscription do usuário
        await eraseSubscription(user)
        
        // await sleep(2000)
        
        props.verify()

        dispatch(setIsLoading(false))
    }


    function getContent() {
        if (user && user.subscription && user.subscription.paymentMethod === 'credit_card') {
            return (
                <div className = 'payment-processing-txt'>
                    <p><strong>Seu pagamento foi <u>recusado</u>.</strong></p>

                    <p>A razão que recebemos do banco foi: "{ reason }".</p>

                    <p><b>Verifique se usou o CPF do dono do cartão; se o código de segurança está correto.</b> Além disso, às vezes a mensagem fala em "não tente novamente", mas pode só insistir.</p>

                    <p>Enfim, não se preocupe, acontece. Basta tentar uma nova transação.</p>

                    <OslerButton
                        color = {'blue'}
                        text={'Tentar nova compra'}
                        onClick={eraseCurrentTransaction}
                        style={{marginTop: '1em'}} />

                    {/* <NiceButton
                        color = '#2779c4'

                        style = {{width: '12em', alignSelf: 'center', marginTop: '1em', marginBottom: '1.5em'}}
                        text = {"Tentar nova compra"}

                        action = {eraseCurrentTransaction} /> */}
                </div>
            )
        }
        else {
            return (
                <>
                <p>Você não devia estar lendo isso. Manda um print para nosso suporte, imediatamente?</p>

                <p>Via DM (@oslermedicina) ou pelo email suporte@osler-ensino.com.</p>
                </>
            )
        }
    }

    return (
        <>
        { getContent() }
        </>
    )
}