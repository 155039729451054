import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { primaryColor } from '../tests/FlashcardsStyles';
import { useSelector } from 'react-redux';
import QuestionIcon from './../assets/question.png';
import QuestionIconDark from './../assets/question dark.png';
import MSCardNewBttn from './MSCard-NewBttn';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em;
  width: 24%;
  margin: 0.2em;
  border-radius: 0.3em;

  ${props => props.theme.darkMode ? `
    background-color: rgba(61, 61, 61, 1);
    color: white;
    border: none;
  ` : `
    background-color: white;
    color: black;
    border: 1px solid lightgray;
  `}

  @media (max-width: 1200px) {
    width: 40%;
  }
  
  @media (max-width: 900px) {
    width: 45%;
  }
  
  @media (max-width: 500px) {
    width: 88%;
    margin-top: 1em;
    margin-bottom: 1em;
    height: auto;
  }
`;

const Title = styled.p`
  margin: 0 0 0 0.5em;
  padding: 0;
  font-weight: bold;
  font-size: 1.4em;
  ${primaryColor}

  @media (max-width: 900px) {
    font-size: 1.2em;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  align-items: center;
  align-self: center;
  margin-top: 0.5em;
`;

const HeaderLeft = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  font-size: 0.8em;
`;

const HeaderRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
`;

const Icon = styled.img`
  width: 2em;
  align-self: center;
`;

const HelpIcon = styled.img`
  width: 1.3em;
`;

const Text = styled.div`
  margin-top: 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  font-size: 0.9em;
  height: 30%;

  & > p {
    margin: 0 0 1em 0;
  }
`;

export default function MSCard(props) {
  const darkMode = useSelector(state => state.theme.darkModeOn);
  const [showHelp, setShowHelp] = useState(false);
  const [nFlashcards, setFlashcards] = useState(false);
  const [nResidencia, setResidencia] = useState(false);

  useEffect(() => {
    setFlashcards(props.flashcards);
    setResidencia(props.residencia);
  }, [props]);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <Icon src={props.icon} />
          <Title>{props.title}</Title>
        </HeaderLeft>

        <HeaderRight onClick={() => setShowHelp(!showHelp)}>
          <HelpIcon src={darkMode ? QuestionIconDark : QuestionIcon} />
        </HeaderRight>
      </Header>

      {showHelp && (
        <Text>
          {props.text}
        </Text>
      )}

      <MSCardNewBttn
        residencia={nResidencia}
        residenciaAction={props.residenciaAction}
        flashcards={nFlashcards}
        flashcardsAction={props.flashcardsAction}
      />
    </Container>
  );
}