import React from 'react';
import CardsIcon from './../assets/flashcard.png'
import ResidIcon from './../assets/residencia.png'
import TimerIcon from './../assets/extensivo.png'
import styled from 'styled-components'
import { RowCSS } from '../components/BasicComponents'
import OslerButton from '../components/OslerButton'


const Bttns = styled.div`
    ${RowCSS}

    width: 90%;
    align-self: center;
    justify-content: flex-end;
    margin-top: 0.75em;
`

export default function ExhibitionTypeSelector({setType, exhibitionType}) {
    return (
        <Bttns>
            <OslerButton
                img  = {CardsIcon}
                text = 'Flashcards'
                size = 'small'
                selected = {exhibitionType === 'Flashcards'}
                onClick = {() => setType('Flashcards')}
                color = 'gray'
                style = {{marginRight: '1.5em'}} />

            <OslerButton
                img  = {ResidIcon}
                text = 'Residência'
                size = 'small'
                selected = {exhibitionType === 'Residencia'}
                onClick = {() => setType('Residencia')}
                color = 'gray'
                style = {{marginRight: '1.5em'}} />

            <OslerButton    
                img  = {TimerIcon}
                text = 'Ambos'
                size = 'small'
                selected = {exhibitionType === 'Ambos'}
                onClick = {() => setType('Ambos')}
                color = 'gray' />
        </Bttns>
    )
}