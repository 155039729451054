import React, { useState } from 'react'
import { toastMsg } from '../utils/Utils'

import styled from 'styled-components';

const TextBoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;

const TextBoxInput = styled.textarea`
    width: 100%;
    height: 10em;


    caret-color: ${props => props.theme.darkMode ? 'white' : 'black'};
    background-color: ${props => props.theme.darkMode ? '#333' : 'white'};
    color: ${props => props.theme.darkMode ? 'white' : 'black'};
    border: ${props => props.theme.darkMode ? '1px solid #555' : '1px solid #ddd'};
    padding: 0.5em;
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: ${props => props.theme.darkMode ? '#777' : '#007bff'};
    }
`;

const TextBoxBttnsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 3em;
`;

const TextBoxBttn = styled.button`
    margin-left: 1em;
    border: none;
    background-color: white;
    padding: 1em 1.5em;
    border-radius: 0.5em;
    font-weight: bold;
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    cursor: pointer;

    &:active {
        box-shadow: none;
    }

    ${props => props.theme.darkMode ? `
        color: white;
        background-color: ${props.bttnType === 'close' ? '#bf0c0c' : props.bttnType === 'clean' ? '#7c7c7c' : '#2678c4'};
    ` : `
        color: white;
        background-color: ${props.bttnType === 'close' ? '#bf0c0c' : props.bttnType === 'clean' ? '#7c7c7c' : '#2678c4'};
    `}

    &:hover {
        background-color: ${props => props.theme.darkMode ? (props.bttnType === 'close' ? 'rgba(191, 12, 12, 0.8)' : props.bttnType === 'clean' ? 'rgba(124, 124, 124, 0.8)' : 'rgba(38, 120, 196, 0.8)') : (props.bttnType === 'close' ? 'rgba(191, 12, 12, 0.8)' : props.bttnType === 'clean' ? 'rgba(124, 124, 124, 0.8)' : 'rgba(38, 120, 196, 0.8)')};
    }
`;



export default function TextBox(props) {
    const [text, setText] = useState(props.text ? props.text : '');

    
    function submitFeedback() {
        if (text === '') {
            toastMsg("Você não pode submeter o texto vazio!");
        } else {
            props.submit(text);
        }
    }

    console.log('placeholder e text')
    console.log(props.placeholder)
    console.log(props.text)

    return (
            <TextBoxContainer style = {props.style}>
                <TextBoxInput
                    id="feedbackTextInput"
                    placeholder={props.placeholder ? props.placeholder : ''}
                    value={text}
                    onChange={e => setText(e.target.value)}
                    required
                />
                <TextBoxBttnsContainer>
                    <TextBoxBttn bttnType="clean" onClick={props.goBack}>Voltar</TextBoxBttn>
                    <TextBoxBttn bttnType="submit" onClick={submitFeedback}>Salvar</TextBoxBttn>
                </TextBoxBttnsContainer>
            </TextBoxContainer>
    );
}