import styled from 'styled-components';

// Definindo o componente estilizado para o botão
const StyledButton = styled.button`
    padding: 0.375rem 0.75rem; // Padding padrão do Bootstrap para botões
    border: 1px solid transparent; // Bordas padrão para botões no Bootstrap
    border-radius: 0.25rem; // Bordas arredondadas padrão do Bootstrap
    font-size: 1rem; // Tamanho de fonte padrão
    line-height: 1.5; // Altura da linha padrão
    cursor: pointer; // Muda o cursor para indicar que é clicável
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out; // Transição suave ao mudar cores


    ${props => props.theme.darkMode ? `
        background-color: #a1a1a1;
        color: white;
        
        &:hover {
            background-color: #c2c3c4;
        }
    `: `
        background-color: #343a40;
        color: white;
        
        &:hover {
            background-color: #23272b;
        }
    `}

    // Permitindo estilos adicionais via props
    ${props => props.additionalStyles}
`;

export default function GoToTopButton({ className, content = "Voltar ao topo", ...props }) {
    return (
        <StyledButton
            className={className}
            onClick={() => window.scrollTo(0, 0)}
            {...props} // Passando quaisquer props adicionais para o componente estilizado
        >
            {content}
        </StyledButton>
    );
}
