import React, { useEffect, useState, useRef } from 'react'

import Select from 'react-select'
import firebase from 'firebase/compat/app';


import { db, functions} from '../firebase/firebase-setup'
import { useDispatch, useSelector } from 'react-redux'

import AmbassadorIcon from '../assets/piramide.png' 

import { setIsLoading } from '../redux/loadingSlice'


import './UserAmbassador.css'
import AppContainer from '../app-container/AppContainer'
import OslerButton from '../components/OslerButton'
import MaskInput from 'react-maskinput'
import { Link } from 'react-router-dom'

import styled from 'styled-components';
import { Header, Title, Icon, Text } from '../academic-track/AcademicTrackMain';
import { OslerInput, backgroundWhiteBlack } from '../tests/FlashcardsStyles';
import { checkCouponForError } from '../utils/Utils';
import { loadNumberCards } from '../statistics/TotalCards';

const UserAmbassadorContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    // background-color: rgb(245, 245, 245);

    ${backgroundWhiteBlack}

    padding-top: 2em;

    width: 90%;
    min-height: 100vh;
`


const UserAmbassadorCard = styled.div`
    padding: 2em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;


    background-color: white;

    ${props => props.theme.darkMode ? `
        background-color: rgb(90, 90, 90);
        color: rgb(230, 230, 230);
    `:`
        background-color: white;
        color: black;
    `}

    border-radius: 1em;

    width: 80%;

    margin-top: 3em;
    margin-bottom: 2em;

    box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
 
    &:hover {
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.8);
    }
`

const UserAmbassadorSubtitle = styled.div`
    font-size: 1.5em;
    font-weight: bold;

    ${props => props.sad ? `
        color: ${props.theme.darkMode ? `rgba(255, 71, 87, 0.8);` : `rgb(199, 10, 10);`}
    `:`
        color: ${props.theme.darkMode ? `#25db25;` : `#199419;`}
    `}
`

const UserAmbassadorDetail = styled.div`
    font-size: 0.9em;
    font-weight: bold;
    color: ${props => props.theme.darkMode ? `#bdbdbd` : `rgb(76, 76, 76);`} 
`


export default function UserAmbassador(props) {

    const user = useSelector(state => state.user.data)
    const [isAmbassador, setIsAmbassador] = useState(false)

    const [tempSchool, setTempSchool] = useState('')
    const [tempPeriod, setTempPeriod] = useState('')

    const [phone, setPhone] = useState('')

    const dispatch = useDispatch()

    const [schoolData, setSchoolData] = useState(false)
    const [periodData, setPeriodData] = useState(false)
    const [loadedData, setLoadedData] = useState(false)
    const uniqueFlashcards = useRef()

    const [signupError, setSignupError] = useState(false)

    const [coupon, setCoupon] = useState('')

    const [acceptedTerms, setAcceptedTerms] = useState(false);
    const [acceptedTerms2, setAcceptedTerms2] = useState(false);
    const [acceptedTerms3, setAcceptedTerms3] = useState(false);

    const institutions_with_ambassadors = useRef()
    const institutions_broken_exclusivity = useRef()
    const institutions = useRef()

    const [institutionAvailable, setInstitutionAvailable] = useState(false)
    const [notGraduated, setNotGraduated] = useState(false);
    const [enoughUse, setEnoughUse] = useState(false);


    useEffect(() => {
        async function loadData() {
            console.log('oooparte2oi')

            dispatch( setIsLoading(true) )

            const doc = await db.doc(`users/${user.id}/ambassador/personal_registry`).get()

            if (doc.exists) {
                console.log('já é embaixador')
                setIsAmbassador(true)
            }
            else {
                decideAvaibility(user)
            }

            dispatch( setIsLoading(false) )
        }

        loadData()
    }, [])


    async function decideAvaibility(data) {
        console.log('não é embaixador, vamos ver se pode aplicar')
        await loadAmbassadorsProgramData()
        // await loadNumberCards()

        uniqueFlashcards.current = await loadNumberCards(user.id)


        // data ou é o user, quando a página acabou de ser carregada, 
        // ou os dados de cadastros que acabaram de ser inseridos
        if (data['school'] && data['phone'] && data['school_stage']) {
            setSchoolData(data['school'])
            setPhone(data['phone'])
            setPeriodData(data['school_stage'])

            const schoolHasAmbassador = institutions_with_ambassadors.current.includes(data['school'])
            const schoolWithoutExclusivity = institutions_broken_exclusivity.current.includes(data['school'])
            
            console.log(`School has ambassador: ${schoolHasAmbassador}`)
            console.log(`School without exclusivity: ${schoolWithoutExclusivity}`)


            setInstitutionAvailable( !schoolHasAmbassador || schoolWithoutExclusivity )
            setNotGraduated(data['school_stage']['term'] != 'Já sou formado')

            setEnoughUse(uniqueFlashcards.current >= 3000)
            setIsAmbassador(false)
        }
        else {
            console.log(`\t\t UserAmbassador: ERROR`)
            setEnoughUse(false)
            setSchoolData(false)
            setPhone(false)
            setPeriodData(false)
        }

        setLoadedData(true)
    }


    async function loadAmbassadorsProgramData() {
        const institutions_with_ambassadors_doc = await db.doc(`embaixadores_dashboard/schools_with_ambassadors`).get()
        institutions_with_ambassadors.current = Object.keys(institutions_with_ambassadors_doc.data())


        const institutions_broken_exclusivity_doc = await db.doc(`embaixadores_dashboard/schools_without_exclusivity`).get()
        institutions_broken_exclusivity.current = institutions_broken_exclusivity_doc.data()['list']


        const institutions_doc = await db.doc(`embaixadores_dashboard/institutions`).get()
        institutions.current = Object.keys(institutions_doc.data())
    }


    // async function loadNumberCards() {
    //     const overallStatistics = await db.doc(`users/${user.id}/Flashcards/statistics/assorted/nEachTypeTest`).get()

    //     if (overallStatistics.exists) {
    //         const data = overallStatistics.data()
    //         const lapsed = data.Total && data.Total.lapsed ? data.Total.lapsed : 0;
    //         const learning = data.Total && data.Total.learning ? data.Total.learning : 0;    

    //         console.log('\tTotal de cards:')
    //         console.log(lapsed + learning)

    //         uniqueFlashcards.current = (lapsed + learning)
    //     }
    //     else {
    //         uniqueFlashcards.current = 0
    //     }
    // }


    async function finishSignUp() {
        dispatch( setIsLoading(true) )
    
        let errorJSX = false;

        if (!acceptedTerms || !acceptedTerms2 || !acceptedTerms3) {
            errorJSX = <p className = 'UserAmbassador-SignUpError'><strong>Você precisa aceitar os termos!</strong> Chamar de seita é uma piada interna, mas precisamos que você realmente tenha lido e esteja de acordo.</p>
        }                                    
        else if (!schoolData || schoolData == '') {
            errorJSX = <p className = 'UserAmbassador-SignUpError'><strong>Erro no cadastro da sua faculdade.</strong> Por favor, envie um e-mail para suporte@osler-ensino.com, com um print dessa tela.</p>
        }
        else {
            errorJSX = await checkCouponForError(coupon)
        }
        

        if (errorJSX) {
            setSignupError(errorJSX)
            dispatch( setIsLoading(false) )
        }
        else {
            const create = functions.httpsCallable('createAmbassador')

            const result2 = await create({
                coupon: coupon,
                uid : user.id,
                school: schoolData
            })

            if (result2.data.success) {
                props.update()
                dispatch( setIsLoading(false) )
            }
            else {
                setSignupError(<p className = 'UserAmbassador-SignUpError'><strong>Houve um erro!</strong> Por favor, entre em contato com embaixadores@osler-ensino.com</p>)
                dispatch( setIsLoading(false) )
            }
        }
    }
    

    async function savePersonalData() {
        dispatch(setIsLoading(true))

        if (tempSchool == '' || tempPeriod == '') {
            setSignupError(<p className = 'UserAmbassador-SignUpError'><strong>Você precisa preencher sua faculade e semestre.</strong> Nós limitamos o número de embaixadores por faculdade.</p>)
        }
        else if (phone == '') {
            setSignupError(<p className = 'UserAmbassador-SignUpError'><strong>Você precisa preencher o <u>celular</u>!</strong> Termos um meio de contato contigo e sua presença no nosso grupo de WhatsApp é fundamental para a participação no programa</p>)

        }
        else {
            setSignupError(false)
            const currentDate = new Date();

            const data = {
                'phone' : phone,
                'school' : tempSchool,
                'school_stage' : {
                    'term' : tempPeriod,
                    'date_updated' : currentDate.toLocaleString('pt-BR'),
                },
            }
                                
            await db.doc(`users/${user.id}`).set(data, {merge: true})
    
            decideAvaibility(data)
        }

        dispatch(setIsLoading(false))
    }

    return (
        <AppContainer>
            <UserAmbassadorContainer>

                <Header>
                <Title>
                    <Icon src={AmbassadorIcon} />
                    <Text>EMBAIXADORES</Text>
                </Title>

                </Header>

                        
                { !isAmbassador && 
                    <UserAmbassadorCard>

                    { (!schoolData || !phone) && loadedData && 
                        <>
                        <p><strong>Você precisa informar sua faculdade e seu celular.</strong> Informe sua instituição de ensino e o período. Se não encontrar sua faculdade na lista, envie um e-mail para embaixadores@osler-ensino.com, e cadastraremos em menos de 24 horas.</p>

                            <Select 
                                placeholder = 'Escolha sua instituição (dá para pesquisar)'
                                onChange={e => { setTempSchool(e.value)}} 

                                styles = {{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        width: '600px',
                                      }),
                                }}

                                options = { institutions.current.map(i => {
                                    return {
                                        value : i,
                                        label: i,
                                    }
                                }) } />

                            <Select
                                placeholder = 'Em qual período/semestre você está?'
                                onChange={e => { setTempPeriod(e.value)}} 
                                styles = {{
                                    control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        marginTop: '1em',
                                        width: '600px'
                                      }),
                                }}
                                options = { ['Já sou formado', 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(i => {
                                    return {
                                        value : i,
                                        label: i,
                                    }
                                }) } />

                            <p className = 'UserAmbassador-P'><strong>Precisamos do seu celular pessoal. </strong>A participação no programa pressupões estar no nosos grupo de WhatsApp, e te daremos suporte individualizado nos primeiros dias.</p>

                            <MaskInput
                                className = 'UserAmbassador-InputInfo'
                                onChange={ (event) => { setPhone(event.target.value)}}
                                mask={'(00) 9 0000 0000'}
                                maskString={'(00) 9 0000 0000'}
                                value={phone}
                                placeholder={'(DD) 9 0000 0000'}
                                size={50}
                                type = {'tel'}/>
                        
                        {signupError}


                        <OslerButton
                            onClick = {() => savePersonalData()}
                            color = 'blue'
                            style = {{marginTop: '2em', }}
                            text = 'Cadastrar' />

                        </>
                    }


                    { schoolData && phone && loadedData && 
                    <>

                        { !institutionAvailable && 
                            <>
                            <UserAmbassadorSubtitle sad = {true}>Sua faculdade já tem embaixador 😥</UserAmbassadorSubtitle>
                            <p><strong>Já temos embaixador na sua faculdade.</strong> Por ora, estamos restringindo a um embaixador por faculdade. Mas pode ser que isso mude no futuro e, caso ocorra, entraremos em contato.</p>

                            <p>Se isso é um erro ou você tem alguma dúvida, envie um e-mail para embaixadores@osler-ensino.com, por favor!</p>
                            </>
                        }
                        { institutionAvailable && !notGraduated && 
                            <>
                            <UserAmbassadorSubtitle sad = {true}>Puts, você já está formado 😥</UserAmbassadorSubtitle>
                            <p><b>O programa de embaixadores é restrito a graduandos.</b> Cogitamos expandir para médicos já formados no futuro, mas por ora ele é restrito a alunos.</p>

                            <p>Se isso é um erro ou você tem alguma dúvida, envie um e-mail para embaixadores@osler-ensino.com, por favor!</p>
                            </>
                        }
                        { institutionAvailable && notGraduated && !enoughUse && 
                            <>
                            <UserAmbassadorSubtitle sad = {true}>Puts, você precisa usar mais a plataforma 😥</UserAmbassadorSubtitle>
                            <p><b>Você ainda não tem uso suficiente da plataforma. </b> O programa de embaixadores é restrito a alunos com uso significativo da plataforma. Prezamos por <u>pele no jogo</u>: só é ético vender aquilo que você usa. E, quanto mais você conhece e gosta da plataforma, mais fácil de vender depois.</p>

                            <p><b>Use a plataforam consistentemente por algumas semanas ou ao menos um mês.</b> Nós consideramos várias métricas, mas tenha em mente que usar um mês com regularidade e por &gt; 30 min por dia tende a ser suficiente para a aprovação.</p>

                            <p><b>Essa tela atualiza automaticamente.</b> Pode voltar aqui quando quiser. Se tiver dúvidas, estamos em embaixadores@osler-ensino.com!</p>
                            </>
                        }
                        { institutionAvailable && notGraduated && enoughUse && 
                            <>

                            <UserAmbassadorSubtitle sad = {false}>Você pode se tornar embaixador! 🥳 🚀</UserAmbassadorSubtitle>

                            <p className = 'UserAmbassador-P2'>Não possuímos nenhum representante na sua faculdade, e você já possui uso suficiente da plataforma. Termine seu cadastro agora para garantir sua vaga — tomará menos de 10 minutos, e você já poderá começar a vender e a ganhar comissão.</p>

                            <p className = 'UserAmbassador-P'><strong>Termos de Adesão.</strong> Você precisa ler as regras do programa do começo ao fim. Para isso, <Link to="https://osler-ensino.notion.site/Introdu-o-ao-Programa-249595d067a64a3a91af384dedca1e05" target="_blank" rel="noopener noreferrer">clique aqui</Link>. Após terminar, volte para essa aba e confirme seu aceite.</p>

                            <div className = 'UserAmbassador-CheckboxContainer'>
                            <input 
                                type="checkbox" 
                                id="confirm-ambassador"
                                className = 'UserAmbassador-Checkbox'
                                checked = {acceptedTerms}
                                onChange = {() => setAcceptedTerms(!acceptedTerms)}
                                />
                                
                                <p>Eu li os termos do começo ao fim, e estava sóbrio</p>
                                
                            </div>

                            <div className = 'UserAmbassador-CheckboxContainer'>
                            <input 
                                type="checkbox" 
                                id="confirm-ambassador"
                                className = 'UserAmbassador-Checkbox'
                                checked = {acceptedTerms2}
                                onChange = {() => setAcceptedTerms2(!acceptedTerms2)}
                                />
                                
                                <p>Eu estou de acordo com os termos da seita</p>
                                
                            </div>


                            <div className = 'UserAmbassador-CheckboxContainer'>
                            <input 
                                type="checkbox" 
                                id="confirm-ambassador"
                                className = 'UserAmbassador-Checkbox'
                                checked = {acceptedTerms3}
                                onChange = {() => setAcceptedTerms3(!acceptedTerms3)}
                                />
                                
                                <p>Quero ganhar R$ e ser um aluno melhor</p>
                                
                            </div>

                            <p className = 'UserAmbassador-P'><strong>Escolha seu cupom.</strong> Ele concederá um desconto para as pessoas que comprarem através de você, e será usado para detectar essas vendas e calcular sua comissão.</p>

                            <UserAmbassadorDetail>Regras: entre 5 e 12 caracteres, sem símbolos, e todas as letras serão feitas maiúsculas. Para facilitar a vida de seus clientes, escolha algo simples e legível.</UserAmbassadorDetail>

                            
                                <OslerInput
                                    onChange={ (event) => { setCoupon(event.target.value.trim().toUpperCase())}}
                                    value = {coupon}
                                    style = {{marginLeft : '2em', marginTop: '1em'}}
                                    placeholder={'Insira aqui'}
                                />  

                            { signupError &&
                                signupError
                            }


                            <OslerButton
                                text = 'Entrar na seita'
                                color = 'blue'
                                onClick = {finishSignUp}
                                style = {{marginTop: '2em', alignSelf: 'center'}} />                            
                            </>
                        }
                    </>
                    }
                    
                    </UserAmbassadorCard>
                }

            </UserAmbassadorContainer>
        </AppContainer>
    )
}