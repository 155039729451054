import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraDAS28CRP() {
    const [tjc, setTjc] = useState('');
    const [sjc, setSjc] = useState('');
    const [crp, setCrp] = useState('');
    const [gh, setGh] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularDAS28CRP = () => {
        if (tjc && sjc && crp && gh) {
            const TJC = Math.sqrt(parseFloat(tjc));
            const SJC = Math.sqrt(parseFloat(sjc));
            const CRP = Math.log(parseFloat(crp) + 1);
            const GH = parseFloat(gh);

            const das28crp = 0.96 + (0.56 * TJC) + (0.28 * SJC) + (0.36 * CRP) + (0.014 * GH);
            const das28crpValue = das28crp.toFixed(2);

            let interpretacao = '';
            if (das28crp < 2.6) {
                interpretacao = 'Remissão';
            } else if (das28crp >= 2.6 && das28crp <= 3.2) {
                interpretacao = 'Baixa atividade da doença';
            } else if (das28crp > 3.2 && das28crp <= 5.1) {
                interpretacao = 'Atividade moderada da doença';
            } else {
                interpretacao = 'Alta atividade da doença';
            }

            setResultado(`DAS28-CRP: ${das28crpValue}, Interpretação: ${interpretacao}`);
        } else {
            setResultado('');
        }
    };

    useEffect(() => {
        calcularDAS28CRP(); // Recalcula o resultado automaticamente sempre que os inputs mudam
    }, [tjc, sjc, crp, gh]);

    return (
        <div>
          <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>

            <h1>Calculadora DAS28-CRP</h1>
            <div>
                <label>Número de articulações com dor (TJC):</label>
                <input
                    type="number"
                    min="0"
                    value={tjc}
                    onChange={e => setTjc(e.target.value)}
                />
            </div>
            <div>
                <label>Número de articulações com edema (SJC):</label>
                <input
                    type="number"
                    min="0"
                    value={sjc}
                    onChange={e => setSjc(e.target.value)}
                />
            </div>
            <div>
                <label>Valor de PCR (CRP):</label>
                <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={crp}
                    onChange={e => setCrp(e.target.value)}
                />
            </div>
            <div>
                <label>Valor de Global Health (GH):</label>
                <input
                    type="number"
                    min="0"
                    max="100"
                    step="1"
                    value={gh}
                    onChange={e => setGh(e.target.value)}
                />
            </div>
            <div className="result-container">
                {resultado ? (
                    <p>{resultado}</p>
                ) : (
                    <p>Preencha todos os campos para ver o resultado.</p>
                )}
            </div>
        </div>
    );
}

export default CalculadoraDAS28CRP;