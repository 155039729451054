import { storage } from './../firebase/firebase-setup'
// import * as FileSystem from 'expo-file-system';

export async function downloadImg(filename) {
    
    // let base64 = ""
    let url = ""
    try {
        // console.log("Starting download of " + filename)

        url = await storage.ref(filename).getDownloadURL()
        // const uri = await FileSystem.downloadAsync(url, FileSystem.documentDirectory + filename)
        // console.log("Downloaded image: " + filename)
        // base64 = await FileSystem.readAsStringAsync(uri.uri, { encoding: 'base64' });

        return url;
    }
    catch (error) {
        console.log("Error while downloading image " + filename + ": " + error)
    }

    return url;
    // return "data:application/png;base64," + base64;
}
