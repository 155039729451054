import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraFENa() {
    const [serumNa, setSerumNa] = useState('');
    const [urineNa, setUrineNa] = useState('');
    const [serumCr, setSerumCr] = useState('');
    const [urineCr, setUrineCr] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularFENa = () => {
        const serumNaNum = parseFloat(serumNa);
        const urineNaNum = parseFloat(urineNa);
        const serumCrNum = parseFloat(serumCr);
        const urineCrNum = parseFloat(urineCr);

        if (serumNaNum > 0 && urineNaNum > 0 && serumCrNum > 0 && urineCrNum > 0) {
            const fena = (urineNaNum / serumNaNum) / (urineCrNum / serumCrNum) * 100;
            let resultadoMensagem = `Fração de Excreção de Sódio (FENa): ${fena.toFixed(2)}%`;

            if (fena > 1) {
                resultadoMensagem += ' - Um resultado maior que 1% sugere lesão tubular aguda, ou obstrução renal bilateral';
            }

            setResultado(resultadoMensagem);
        } else {
            setResultado('Preencha todos os campos com valores válidos.');
        }
    };

    useEffect(() => {
        if (serumNa && urineNa && serumCr && urineCr) {
            calcularFENa();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [serumNa, urineNa, serumCr, urineCr]);

    return (
        <div>
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Fração de Excreção de Sódio (FENa)</h1>
            <div>
                <label>Na+ sérico (mEq/L):</label>
                <input type="number" value={serumNa} onChange={e => setSerumNa(e.target.value)} />
            </div>
            <div>
                <label>Na+ urinário (mEq/L):</label>
                <input type="number" value={urineNa} onChange={e => setUrineNa(e.target.value)} />
            </div>
            <div>
                <label>Creatinina sérica (mg/dL):</label>
                <input type="number" value={serumCr} onChange={e => setSerumCr(e.target.value)} />
            </div>
            <div>
                <label>Creatinina urinária (mg/dL):</label>
                <input type="number" value={urineCr} onChange={e => setUrineCr(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraFENa;
