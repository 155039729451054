import styled from 'styled-components'
import { motion } from 'framer-motion'
import { ColumnCSS } from './BasicComponents'

const StyledCard = styled(motion.div)`
    ${ColumnCSS}

    width: ${props => props.width || '100%'};
    background: ${props => props.background || props.theme.darkMode ? 
        'rgba(28, 28, 30, 0.95)' : 
        'rgba(255, 255, 255, 0.98)'
    };
    
    border-radius: 1em;
    padding: ${props => props.padding || '2em'};
    
    // Sombra em repouso
    box-shadow: 
        0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06),
        0 0 0 1px rgba(0, 0, 0, 0.05);
        
    // Borda sutil
    border: 1px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        'rgba(0, 0, 0, 0.05)'
    };

    // Transições suaves
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
        transform: translateY(-2px);
        box-shadow: 
            0 20px 25px -5px rgba(0, 0, 0, 0.1),
            0 10px 10px -5px rgba(0, 0, 0, 0.04),
            0 0 0 1px rgba(0, 0, 0, 0.05);
    }

    // Garantir que o conteúdo não vaze das bordas arredondadas
    overflow: hidden;

    // Backdrop blur se o background for semi-transparente
    ${props => props.background?.includes('rgba') && `
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
    `}

    // Estilo específico para modo escuro
    ${props => props.theme.darkMode && `
        box-shadow: 
            0 4px 6px -1px rgba(0, 0, 0, 0.2),
            0 2px 4px -1px rgba(0, 0, 0, 0.12);

        &:hover {
            box-shadow: 
                0 20px 25px -5px rgba(0, 0, 0, 0.2),
                0 10px 10px -5px rgba(0, 0, 0, 0.08);
        }
    `}

    text-align: left;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: ${props => props.width ? 
            `calc(${props.width} + 2%)` : 
            '100%'
        };
        padding: ${props => props.padding || '1.6em'};
        border-radius: 0.9em;
        margin: 0 0.5em;
    }

    @media (max-width: 1024px) {
        width: ${props => props.width ? 
            `calc(${props.width} + 5%)` : 
            '100%'
        };
        padding: ${props => props.padding || '1.5em'};
        border-radius: 0.8em;
    }

    @media (max-width: 768px) {
        width: 100% !important;
        padding: ${props => props.forceWhiteBg ? (props.padding || '1em') : '0px'};
        border-radius: ${props => props.forceWhiteBg ? '0.75em' : '0.6em'};

        background: ${props => {
            if (props.forceWhiteBg) {
                return props.theme.darkMode ? 'rgb(51, 51, 51)' : '#fff';
            }
            return 'transparent';
        }};

        box-shadow: ${props => {
            if (props.forceWhiteBg) {
                return props.theme.darkMode ?
                    '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)' :
                    '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
            }
            return 'none';
        }};

        border: ${props => {
            if (props.forceWhiteBg) {
                return props.theme.darkMode ?
                    '1px solid rgba(255, 255, 255, 0.1)' :
                    '1px solid rgba(0, 0, 0, 0.05)';
            }
            return 'none';
        }};       

        &:hover {
            transform: none;
            box-shadow: ${props => {
                if (props.forceWhiteBg) {
                    return props.theme.darkMode ?
                        '0 4px 6px -1px rgba(0, 0, 0, 0.3), 0 2px 4px -1px rgba(0, 0, 0, 0.2)' :
                        '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)';
                }
                return 'none';
            }};
        }

        h1 {
            font-size: 1.3em;
            padding: 0;
            margin-top: 1em;
            color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'inherit'};
        }

        img {
            max-width: 100% !important;
            filter: ${props => props.theme.darkMode ? 'brightness(0.9)' : 'none'};
        }

        p {
            font-size: 0.85em;
            color: ${props => props.theme.darkMode ? 'rgb(200, 200, 200)' : 'inherit'};
        }

        backdrop-filter: ${props => props.forceWhiteBg ? 'none' : 'blur(4px)'};
        -webkit-backdrop-filter: ${props => props.forceWhiteBg ? 'none' : 'blur(4px)'};
    }

    @media (max-width: 500px) {
        padding: ${props => props.forceWhiteBg ? (props.padding || '1em') : '0px'};
        border-radius: ${props => props.forceWhiteBg ? '0.75em' : '0.5em'};
    }
`

export default function OslerCard ({ 
    children, 
    width,
    background,
    padding,
    className,
    style,
    onClick,
    forceWhiteBg = false,
    ...props 
}) {
    return (
        <StyledCard
            width={width}
            background={background}
            padding={padding}
            className={className}
            style={style}
            onClick={onClick}
            forceWhiteBg={forceWhiteBg}
            {...props}
        >
            {children}
        </StyledCard>
    )
}
