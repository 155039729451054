
import PersonalNotes from '../controllers/PersonalNotes'
import { db } from '../firebase/firebase-setup'
import { extractTagsFromFirebaseDataToArray } from './../utils/Utils'

class Test { 
    constructor(testID, data, testType) {
        this.testID = testID
        this.tags = data['tags']
        this.testType = testType


        this.statistics = undefined
        this.personalNote = undefined
    }

    async loadStatistics(testStatisticsObjct) {
        this.statistics = await testStatisticsObjct.getTestStatistics(this.testID)
    }

    async loadPersonalNotes() {
        // para 5 cards do mesmo cloze, baixará 5 vezes a mesma nota -- não vale a pena!
        this.personalNote = await PersonalNotes.getPersonalNote(this.testType, this.testID)
    }
}

export default Test