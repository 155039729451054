import { useEffect, useState } from "react"
import styled from "styled-components"
import StatisticsPie from "./StatisticsPie"
import Notebook from './../assets/tree/notebook.png'
import Dumbbell from './../assets/tree/dumbbell.png'

export const PiesContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;


    @media (max-width: 900px) {
        justify-content: space-between;
        padding: 0;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
`

export default function LastDaysPies({ dailyData, testType }) {
    const [totalNewTests, setTotalNewTests] = useState(0)
    const [totalReviews, setTotalReviews] = useState(0)
    const [newsData, setNewsData] = useState([])
    const [reviewsData, setReviewsData] = useState([])

    useEffect(() => {
        let newsDataTmp, reviewsDataTmp, totalNewTestsTmp, totalReviewsTmp

        if (testType === 'Flashcards') {
            const colorScale = ["rgb(39, 121, 196)", "rgb(35, 146, 7)", "rgb(128, 128, 128)", "#8E0011"]
            const D = prepareData(testType)

            const unit = ['card', 'cards']
            let percentageOf = ['dos novos respondidos no período']

            newsDataTmp = [
                { label: "Fácil", number: D.newEasy, color: colorScale[0], unit : unit, percentageOf : percentageOf},
                { label: "Normal", number: D.newGood, color: colorScale[1], unit : unit, percentageOf : percentageOf },
                { label: "Difícil", number: D.newHard, color: colorScale[2], unit : unit, percentageOf : percentageOf },
                { label: "Errou", number: D.newAgain, color: colorScale[3], unit : unit, percentageOf : percentageOf },
            ].reverse()


            percentageOf = ['das revisões respondidas no período']

            reviewsDataTmp = [
                { label: "Fácil", number: D.lapsedEasy + D.learningEasy, color: colorScale[0], unit : unit, percentageOf : percentageOf },
                { label: "Normal", number: D.lapsedGood + D.learningGood, color: colorScale[1], unit : unit, percentageOf : percentageOf },
                { label: "Difícil", number: D.lapsedHard + D.learningHard, color: colorScale[2], unit : unit, percentageOf : percentageOf },
                { label: "Errou", number: D.lapsedAgain + D.learningAgain, color: colorScale[3], unit : unit, percentageOf : percentageOf },
            ].reverse()

            totalNewTestsTmp = D.newAgain + D.newEasy + D.newGood + D.newHard
                                    
            totalReviewsTmp = D.lapsedAgain + D.lapsedEasy + D.lapsedGood + D.lapsedHard
                            + D.learningAgain + D.learningEasy + D.learningGood + D.learningHard

        } else if (testType === 'Residencia') {
            // As cores são copiadas de McqFeedbackButtons
            
            const colorScale = ['#1e88e5', '#43a047', '#e53935', '#ffb300']

            const D = prepareData(testType)

            const unit = ['questão', 'questões']
            let percentageOf = ['dos novas respondidas no período']


            newsDataTmp = [
                { label: 'Acertou, acaso', number: D.newRightGuess, color: colorScale[0], unit : unit, percentageOf : percentageOf},
                { label: 'Acertou, sabia', number: D.newRightConfident, color: colorScale[1], unit : unit, percentageOf : percentageOf},
                { label: 'Errou, distração', number: D.newWrongDistraction, color: colorScale[3], unit : unit, percentageOf : percentageOf},
                { label: 'Errou, desconhecia', number: D.newWrongConcept, color: colorScale[2], unit : unit, percentageOf : percentageOf},
            ]


            percentageOf = ['das revisões respondidas no período']


            reviewsDataTmp = [
                { label: 'Acertou, acaso', number: D.reviewRightGuess, color: colorScale[0], unit : unit, percentageOf : percentageOf},
                { label: 'Acertou, sabia', number: D.reviewRightConfident, color: colorScale[1], unit : unit, percentageOf : percentageOf},
                { label: 'Errou, distração', number: D.reviewWrongDistraction, color: colorScale[3], unit : unit, percentageOf : percentageOf},
                { label: 'Errou, desconhecia', number: D.reviewWrongConcept, color: colorScale[2], unit : unit, percentageOf : percentageOf},
            ].reverse()

            totalNewTestsTmp = D.newRightGuess 
                                + D.newRightConfident 
                                + D.newWrongConcept 
                                + D.newWrongDistraction

            totalReviewsTmp = D.reviewRightGuess
                                + D.reviewRightConfident
                                + D.reviewWrongConcept 
                                + D.reviewWrongDistraction
        } else {
            const resid = prepareData('Residencia')
            const cards = prepareData('Flashcards')


            let newRight = resid.newRightConfident + cards.newEasy + cards.newGood + cards.newHard
            let newWrong = resid.newRightGuess + resid.newWrongConcept + resid.newWrongDistraction 
                            + cards.newAgain

            
            newsDataTmp = [
                { label: 'Acertou', number: newRight, color: 'rgb(35, 146, 7)'},
                { label: 'Errou', number: newWrong, color: '#8E0011'},
            ].reverse()

            let reviewRight = resid.reviewRightConfident 
                                + cards.lapsedEasy + cards.lapsedGood + cards.lapsedHard
                                + cards.learningEasy + cards.learningGood + cards.learningHard

            let reviewWrong = resid.reviewRightGuess + resid.reviewWrongConcept + resid.reviewWrongDistraction
                                + cards.lapsedAgain + cards.learningAgain

            reviewsDataTmp = [
                { label: 'Acertou', number: reviewRight, color: 'rgb(35, 146, 7)'},
                { label: 'Errou', number: reviewWrong, color: '#8E0011'},
            ].reverse()

            totalNewTestsTmp = cards.newAgain + cards.newEasy 
                            + cards.newGood + cards.newHard
                            + resid.newRightGuess + resid.newRightConfident 
                            + resid.newWrongConcept + resid.newWrongDistraction 
            
            
            totalReviewsTmp = cards.lapsedAgain + cards.lapsedEasy + cards.lapsedGood + cards.lapsedHard
                                + cards.learningAgain + cards.learningEasy + cards.learningGood + cards.learningHard
                                + resid.reviewRightGuess + resid.reviewRightConfident
                                + resid.reviewWrongConcept + resid.reviewWrongDistraction

            
        }


        setTotalNewTests(totalNewTestsTmp)
        setTotalReviews(totalReviewsTmp)
        setNewsData(newsDataTmp)
        setReviewsData(reviewsDataTmp)


    }, [dailyData, testType])



    function prepareData(type) {
        const d = dailyData[type]

        if (type === 'Flashcards') {
            const D = {
                'newAgain': d.newAgain || 0,
                'newEasy': d.newEasy || 0,
                'newGood': d.newGood || 0,
                'newHard': d.newHard || 0,
                'lapsedAgain': d.lapsedAgain || 0,
                'lapsedEasy': d.lapsedEasy || 0,
                'lapsedGood': d.lapsedGood || 0,
                'lapsedHard': d.lapsedHard || 0,
                'learningAgain': d.learningAgain || 0,
                'learningEasy': d.learningEasy || 0,
                'learningGood': d.learningGood || 0,
                'learningHard': d.learningHard || 0,
            }

            return D
        } else {

            const D = {
                'newRightGuess': d.newRightGuess || 0,
                'newRightConfident': d.newRightConfident || 0,
                'newWrongConcept': d.newWrongConcept || 0,
                'newWrongDistraction': d.newWrongDistraction || 0,
                'reviewRightGuess': d.reviewRightGuess || 0,
                'reviewRightConfident': d.reviewRightConfident || 0,
                'reviewWrongConcept': d.reviewWrongConcept || 0,
                'reviewWrongDistraction': d.reviewWrongDistraction || 0,
            }

            return D
        }
    }





    return (
        <PiesContainer>
            <StatisticsPie
                title    = {totalNewTests}
                subtitle = {'testes novos'}
                icon     = {Notebook}
                data     = {newsData}
            />

            <StatisticsPie
                title    = {totalReviews}
                subtitle = {'revisões feitas'}
                icon     = {Dumbbell}
                data     = {reviewsData}
            />
        </PiesContainer>
    )
}