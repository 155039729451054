
import React, { useEffect, useState } from 'react'
import { useSelector} from 'react-redux'
import { getDailyUseTestTypes } from './DailyUseMethod'
import LastDaysChart from './LastDaysChart'
import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'



const Container = styled.div`
    ${ColumnCSS}
    width: 80%;
    margin-top: 6em;
    height: 60vh;

    @media (max-width: 500px) {
        margin-top: 3em;
        width: 100%;
    }
`

export default function TestsDoneGraph(props) {
    const user = useSelector(state => state.user.data)
    const [dailyUse, setDailyUse] = useState(false)


    useEffect(() => {
        
        async function fetchData() {
            const lastXDays = 7
            const data = await getDailyUseTestTypes(user.id, lastXDays)
            setDailyUse(data)
        }

        fetchData()
    }, [])


    if (!dailyUse) return;

    return (
        <Container>
            <LastDaysChart
                shouldRotate = {false}
                showLegend = {false}
                exhibitionType = {'Ambos'}
                dailyData = {dailyUse} />
        </Container>

    )
}