

import { db, functions } from './../firebase/firebase-setup'
import { pathToTagSequence, tagSequenceToPathS } from './../utils/Utils'
import LikedBuriedController from './LikedBuriedController'
import OslerData, { KEYS } from './OslerData'
import { SessionConfig, TEST_TYPES } from './SessionConfig'



// Essa classe é responsável por baixar e organizar inforações
// do usuário em relação aos testes disponíveis e às revisões
// pendentes.
class UserReviewsInfo {
    construct(user) {
        this.userID = user.id
        this.loadVariables()
    }



    loadVariables() {
        this.testTypes = ['Flashcards', 'Residencia']

        /*
            {
                'Flashcards' : {
                    availableTests: [ID1, ID2, ID3, ...],
                    pendingReviews: [ID4, ID5, ID6, ...],
                    futureReviews: [ID7, ID8, ID9, ...],
                    anuladas: [ID10, ID11, ID12, ...]
                }
            }
        */
        this.info = {}

        // Ainda me pergunto se this.info assim é o que precisamos.
        // Me pareceq ue só é usado na tela inicial.... a troco de nada...
        // no card de revisões pendentes...
        for (let type of this.testTypes) {
            this.info[type] = {
                nAvailableTests : 0,
                pendingReviews  : [],
                futureReviews   : []
            }
        }

        this.info['Residencia']['solved'] = []


        /* 'Flashcards': {
                tag_path: [id1, id2, id3, ...],
                tag_path2: [idA, idB, idC, ...],
                ...
        }*/
        this.tests_per_tag = {}


        /* 'Flashcards' : {
                ID_1: next_review_in_ms1,
                ID_2: next_review_in_ms2,
                ...
            }
        } */
        this.allReviews = {}


        /*
            allIDs: [ ... IDs das anuladas ],
            perTagpath: {
                tag_path1: [ID1, ID2, ID3, ... IDs das anuladas para cada tagpath LEAFT],
                ...
            },
            tag_count: {
                tag_path1: ... X: quantas anuladas para cada tagpath RECURSIVO,
                ...
            }

            Veja a diferença! Em perTagpath é só da tag específica da questão, enquanto em tag_count
            é para todas as tags (e.g., incluindo "Clínica Cirúrgica", por mais que nenhuma anulada
            tenha só essa tag).
        */
        this.anuladasInfo = {}

        /*
            'Flashcards' : {
                tagPath1 : {
                    pendingReviews: X,
                    futureReviews: Y
                }
            }


            ATENÇÃO: não tem available tests.
            
            Info per path é parecido, mas tem o availabletests.
        */
        this.reviewsPerPath = {}
        this.anuladasPerPath = {}

        this.infoPerPath = {}

        // Para cada testType, um dicionário de tag_paths : número de
        // testes disponíveis na plataforma.    
        this.tagCount = {}


        // Hierarquia das tags de cada testType
        this.tagHierarchy = {}

        // Similar a reviewsPerPath, mas diferente.
        //      1) Note que anuladas não tem sub-dict, pois só residencia
        //
        //      2) buriedPerPath só tem um número por tagPath, e não um sub-dict
        //
        // Esses dados são importantes porque esses testes também estão
        // contabilizadas em reviewsPerPath!

        // TODO Eu não acho que o que importa é "anuladas-REVIEWS-perPath",
        // mas talvez um "anuladasPerPath". Guardar isso aqui até termos certeza do que queremos.
        // this.anuladasReviewsPerPath = {}
        // this.buriedPerPath          = {}


        // Para residência, temos as "solved", porque é o único jeito de determinar o que não
        // é new, dado que excluímos algumas revisões
        this.residenciaSolved = {}


        // Para evitar que os dados sejam carregados mais de uma vez
        this.isLoading = false;
        this.loaded = false;




        this.indexation = {}
    }



    // createDictForTestType() {
    //     let dict = {}
    //     for (let type of this.testTypes) {
    //         dict[type] = {}
    //     }

    //     return dict;
    // }



    isReady() {
        return this.loaded && !this.isLoading
    }



    async start() {
        try {
            console.log('WHAT THE FLYING FUCK')
            // Evitamos que seja executado mais de uma vez, simultaneamente,
            // por re-rende do React.
            if (this.isLoading) {
                return true
            }
            else {
                this.isLoading = true;
            }

            // Incializa:
            //     - Diretamente, ao baixar do servidor: 
            //          tests_per_tag, allReviews, liked, buried 
            //
            //      - reviewsPerPath e info, mas ambos sem
            //      o número de     estes totais, só de revisões.
            //
            // Atenção: precisamos das anuladas para carregar.
            LikedBuriedController.prepare(this.userID)

            this.anuladasInfo = OslerData.data[KEYS.RESIDENCIA][KEYS.ANULADAS]

            this.comentadas = OslerData.data[KEYS.RESIDENCIA][KEYS.COMENTADAS][KEYS.ALL_IDS]
            this.extensivo = OslerData.data[KEYS.RESIDENCIA][KEYS.EXTENSIVO][KEYS.ALL_IDS]

            this.residenciaSolved = OslerData.data[KEYS.RESIDENCIA][KEYS.SOLVED]

            console.log('??????????????????????????????????????????????/')

            for (let type of this.testTypes) {
                this.info[type]['nAvailableTests'] = OslerData.data[type][KEYS.N_TOTAL_TESTS]
            }

            console.log(`!!!!!!!!!!!`)
            this.tryLocal('Flashcards')
            this.tryLocal('Residencia')

            this.isLoading = false;
            this.loaded = true;
            console.log('UserReviewsInfo: done.')
        }
        catch (error) {
            throw error
        }
    }


    tryLocal(testType) {
        console.log(`\t\t\t${testType}`)
        const typeData = OslerData.data[testType]

        let ms_per_id = typeData[KEYS.REVIEWS_INDEXED]

        let existent_IDs = typeData[KEYS.ALL_TESTS_IDS]

        // Para os documentos de liked e buried, precisamos garantir que 
        // existem, possuem dados, & estão na conformação correta
        let buriedTmp = this.validateLikedBuriedDoc( typeData[KEYS.BURIED] )
        let liked  = this.validateLikedBuriedDoc( typeData[KEYS.LIKED] )

        if (!this.buried) {
            this.buried = {}
        }
        this.buried[testType] = {}

        for (let ID of buriedTmp) {
            this.buried[testType][ID] = true
        }
        console.log(this.buried[testType])

        const tag_hierarchy = typeData[KEYS.TAG_HIERARCHY]
        const tag_count     = typeData[KEYS.TAG_COUNT]
        
        let tests_per_tag = typeData[KEYS.TESTS_PER_TAGPATH]

        // Retiramos os testes que não existem mais das revisões pendentes,
        // das liked, e das buried.
        //      Lembrando que ms_per_id é um dicionário, e os 
        //      demais são listas, então a abordagem é diferente.
        for (let ID of Object.keys(ms_per_id)) {
            if (!existent_IDs[ID]) {
                delete ms_per_id[ID]
            }
        }       

        const filtered_buried = buriedTmp.filter(ID => existent_IDs[ID])
        const filtered_liked  = liked.filter(ID => existent_IDs[ID])
        
        // Agora vamos retirar as buried das revisões pendentes, só para já facilitar.
        // Sem razão para deixar isso no cliente, aqui a execução certamente é mais rápida.
        for (let ID of filtered_buried) {
            if (ms_per_id[ID]) {
                delete ms_per_id[ID]
            }
        }

        this.tests_per_tag[testType] = tests_per_tag
        this.allReviews[testType]    = ms_per_id
        this.tagCount[testType]      = tag_count
        this.tagHierarchy[testType]  = tag_hierarchy

        LikedBuriedController.load(testType, filtered_buried, filtered_liked)

        // Criamos reviewsPerPath, que é necessário, e depois a grande
        // árvore.
        //  O gargalo é aqui, dura ~70%, mas reduzi de >500ms para <50ms!
        this.createReviewsPerPath(testType)

        // if (testType === 'Residencia') {
        //     this.createAnuladasPerPath()    
        // }
        if (testType === 'Residencia') {
            this.createResidenciaSolved()
        }

        this.createInfoPerPath(testType)

        console.log(`\t\t\t........`)

        // this.indexationInsanity(testType)
    }



    validateLikedBuriedDoc(data) {
        if ( Object.keys(data).length > 0) {
            if ( Object.keys(data).includes('data') ) {
                return data['data']
            }
        }

        return []
    }




    createReviewsPerPath(testType) {
        const allReviewsIDs = Object.keys(this.allReviews[testType])

        this.reviewsPerPath[testType] = {}   

        for (let reviewID of allReviewsIDs) {
            let key = this.getTestStatusAsKey(testType, reviewID)
            let tagPaths = this.getTestTagPathS(testType, reviewID)

            this.info[testType][key].push(reviewID)

            if (tagPaths) {                
                for (let tagPath of tagPaths) {
                    this.incrementInfoDict(this.reviewsPerPath[testType], tagPath, key)
                }
            }
            else {
                // console.log(`Nós não localizamos ${reviewID} em tests_per_tag. Estranho!`)
            }
        }
    }



    indexationInsanity(testType) {
        console.log(`\t\t\tCOMEÇANDO`)
        const start = performance.now()
    
        const typeData = OslerData.data[testType]
        const allTestsIDs = Object.keys(typeData[KEYS.ALL_TESTS_IDS])   
        const indexation = {}
        const defaultDictTemplate = this.getDefaultDict(testType)
    
        for (let testID of allTestsIDs) {
            const tagpaths = this.getTestTagPathS(testType, testID)
            if (!tagpaths) continue
            
            const status = this.getTestStatus(testType, testID)
            const tagpathKeys = this.getTagpathKeys(testType, testID)
            const counters = this.getCounterIncrements(testType, testID)
            
            for (let path of tagpaths) {
                if (!indexation[path]) {
                    indexation[path] = {}
                }
                
                for (let key of tagpathKeys) {
                    if (!indexation[path][key]) {
                        indexation[path][key] = JSON.parse(JSON.stringify(defaultDictTemplate))
                    }
    
                    for (let counter of counters) {
                        indexation[path][key][status][counter.category][counter.type][counter.status] += 1
                    }
                }
            }
        }
        
        
        const end = performance.now()
        console.log(`indexationInsinity - ${testType} - ${end - start}ms`)
        console.log(indexation)
        this.indexation[testType] = indexation
    }


    getTagpathKeys(testType, ID) {
        const keys = ['general']
        if (testType === TEST_TYPES.FLASHCARDS) {
            return keys
        }
        else {
            keys.push(this.getInstitutionYearKey(ID))
            return keys
        }
    }


    getCounterIncrements(testType, testID) {
        const isAnulada = testType === TEST_TYPES.RESIDENCIA && this.anuladasInfo.allIDs[testID]
        const isExtensivo = testType === TEST_TYPES.RESIDENCIA && this.extensivo[testID]
        const isBuried = this.buried[testType][testID]
    
        const questionType = isAnulada ? 'anulada' : 'regular'
        const buriedStatus = isBuried ? 'buried' : 'active'
    
        const counters = [{
            category: 'total',
            type: questionType,
            status: buriedStatus
        }]
    
        if (isExtensivo) {
            counters.push({
                category: 'extensivo',
                type: questionType,
                status: buriedStatus
            })
        }
    
        return counters
    }

    getDefaultDict(testType) {
        const statuses = ['pendingReviews', 'futureReviews', 'availableTests']
        const dict = {}
    
        if (testType === TEST_TYPES.FLASHCARDS) {
            for (let status of statuses) {
                dict[status] = {
                    total: {
                        regular: {
                            active: 0,
                            buried: 0
                        }
                    }
                }
            }
        } else {
            statuses.push('solved')
            for (let status of statuses) {
                dict[status] = {
                    total: {
                        regular: {
                            active: 0,
                            buried: 0
                        },
                        anulada: {
                            active: 0,
                            buried: 0
                        }
                    },
                    extensivo: {
                        regular: {
                            active: 0,
                            buried: 0
                        },
                        anulada: {
                            active: 0,
                            buried: 0
                        }
                    }
                }
            }
        }
    
        return dict
    }



    getTestTypeDict(testType) {
        const dict = this.getDefaultDict(testType)
        if (testType === TEST_TYPES.FLASHCARDS) {
            return {
                'general' : dict
            }
        } else {
            return {
                'general' : dict,
                'anuladas' : dict,
                'extensivo' : dict,
            }
        }
    }


    // getDefaultDict(testType) {
    //     if (testType === TEST_TYPES.FLASHCARDS) {
    //         return {
    //             'pendingReviews' : 0,
    //             'futureReviews'  : 0,
    //             'availableTests' : 0,                
    //         }
    //     }
    //     else {
    //         const subDict = {
    //             'total'
    //         }
    //         return {
    //             'pendingReviews' : 0,
    //             'futureReviews'  : 0,
    //             'availableTests' : 0,
    //             'solved'         : 0,
    //     }
    //     }
    // }


    getTestStatus(testType, ID) {
        const now = Date.now()
        const nextReview = this.allReviews[testType][ID]

        if (nextReview) {
            return (nextReview <= now) ? 'pendingReviews' : 'futureReviews'
        }
        else {
            if (testType === TEST_TYPES.RESIDENCIA) {
                if (this.residenciaSolved[ID]) {
                    return 'solved'
                }
            }
            return 'availableTests'
        }
    }




    getTestKeys(testType, ID) {
        const keys = ['general']

        if (testType === TEST_TYPES.RESIDENCIA) {
            if (this.anuladasInfo.allIDs[ID]) {
                keys.push('anuladas')
            }

            if (this.extensivo[ID]) {
                keys.push('extensivo')
            }

            keys.push(this.getInstitutionYearKey(ID))

            return keys
        }
        else {
            return keys
        }
    }


    getInstitutionYearKey(testID) {
        const [, institution, year] = testID.split('_')
        return `${institution}_${year}`
    }



    createResidenciaSolved() {        
        const solvedIDs = Object.keys(this.residenciaSolved)
        this.residenciaSolvedPerPath = {}

        for (let testID of solvedIDs) {
            let tagpaths = this.getTestTagPathS('Residencia', testID)
            this.info['Residencia']['solved'].push(testID)

            if (tagpaths) {
                for (let path of tagpaths) {
                    if (!this.residenciaSolvedPerPath[path]) {
                        this.residenciaSolvedPerPath[path] = 0
                    }
                    this.residenciaSolvedPerPath[path] += 1

                }
            }
        }

    }


     getTestStatusAsKey(testType, ID) {
        const now = Date.now()
        const nextReview = this.allReviews[testType][ID]
        
        if (nextReview) {
            return (nextReview <= now) ? 'pendingReviews' : 'futureReviews'
        }
        else {
            return 'availableTests'
        }
     }



    getTestTagPathS(testType, testID) {
        // Não devolvemos só uma path, mas todas as subpaths associadas
        const path = this.getGivenTestTagPath(testType, testID)

        if (path) {
            const tags = pathToTagSequence(path)
            return tagSequenceToPathS(tags)
        }
        else {
            return undefined;
        }
    }

    

    getGivenTestTagPath(testType, testID) {
        // Isso aqui foi para HashTestID
        // TODO
        let root = ''
        if (testType == 'Flashcards') {
            root  = testID.split('_')[1]   
        }
        else {
            root = testID.slice(  testID.indexOf('_') + 1  )
        }

        return OslerData.data[testType][KEYS.TAGPATH_PER_ID][root]
    }



    incrementInfoDict(dict, path, key, value = 1) {
        if (dict[path] == undefined) {
            dict[path] = {
                "pendingReviews" : 0,
                "futureReviews" : 0,
            }
        }

        dict[path][key] += value;
    }



    incrementAnuladasDict(dict, path, key, value = 1) {
        if (dict[path] == undefined) {
            dict[path] = {
                "anuladasPendingReviews" : 0,
                "anuladasFutureReviews" : 0,
                "anuladasAvailableTests" : 0,
            }
        }

        dict[path][key] += value;
    }



    createInfoPerPath(testType) {
        this.infoPerPath[testType] = {}

        for (let path of Object.keys(this.tagCount[testType])) {
            let pendingReviews = 0
            let futureReviews  = 0

            if (this.reviewsPerPath[testType][path]) {
                pendingReviews = this.reviewsPerPath[testType][path]['pendingReviews'] ?? 0
                futureReviews  = this.reviewsPerPath[testType][path]['futureReviews']  ?? 0
            }


            if (testType === 'Flashcards') {
                this.infoPerPath[testType][path] = {
                    'availableTests' : this.tagCount[testType][path] - (pendingReviews + futureReviews),
                    'pendingReviews' : pendingReviews,
                    'futureReviews'  : futureReviews,
                }
            }
            else if (testType === 'Residencia') {
                let solved = this.residenciaSolvedPerPath[path] ?? 0

                this.infoPerPath[testType][path] = {
                    'availableTests' : this.tagCount[testType][path] - (pendingReviews + futureReviews + solved),
                    'pendingReviews' : pendingReviews,
                    'futureReviews'  : futureReviews,
                    'solved'         : solved,
                }

                if (this.anuladasPerPath[path]) {
                    this.infoPerPath['Residencia'][path]['anuladasInfo'] = this.anuladasPerPath[path]
                }
            }
        }
    }


    // createAnuladasPerPath() {
    //     this.anuladasPerPath = {}

    //     this.info['Residencia']['anuladas'] = []
        
    //     for (let testID of this.anuladasIDs) {

    //         let key = this.getTestStatusAsKey('Residencia', testID)

    //         // Recebemos 'pendingReviews', queremos ajustar
    //         key = `anuladas${key.charAt(0).toUpperCase() + key.slice(1)}`

    //         let tagPaths = this.getTestTagPathS('Residencia', testID)

    //         this.info['Residencia']['anuladas'].push(testID)


    //         if (tagPaths) {                
    //             for (let tagPath of tagPaths) {
    //                 this.incrementAnuladasDict(this.anuladasPerPath, tagPath, key)
    //             }
    //         }
    //         else {
    //             console.log(`Nós não localizamos ${testID} em tests_per_tag. Estranho!`)
    //         }
    //     }
    // }

    getReviewsPerPath() {
        return this.reviewsPerPath
    }

    // getReviewsPerPathAnuladas() {
    //     return this.anuladasReviewsPerPath
    // }


    getReviewsID() {
        return this.info;
    }
 

    getTestsPerTag() {
        return this.tests_per_tag
    }


}


export default new UserReviewsInfo()