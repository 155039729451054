import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';

const PrescricaoDetalhes = () => {
  const { medicamentoId } = useParams();
  const navigate = useNavigate();
  const [detalhes, setDetalhes] = useState(null);

  useEffect(() => {
    const fetchDetalhes = async () => {
      const decodedId = decodeURIComponent(medicamentoId);
      const docRef = doc(db, "bulario", decodedId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setDetalhes(docSnap.data());
      } else {
        console.log("Nenhum documento encontrado!");
      }
    };

    fetchDetalhes();
  }, [medicamentoId]);

  const handlePrescrever = (tipo) => {
    navigate(`/prescrever/${medicamentoId}/${tipo}`);
  };
  

  return (
    <div>
      <h1>Detalhes do Medicamento</h1>
      {detalhes ? (
        <div>
          <h2>Posologia Adulto:</h2>
          <ul>
            {detalhes.posologiaAdulto && detalhes.posologiaAdulto.map((pos, index) => (
              <li key={index}>{pos}</li>
            ))}
          </ul>
          <button onClick={() => handlePrescrever("adulto")}>Prescrever para Adulto</button>
          <h2>Posologia Pediátrica:</h2>
          <ul>
            {detalhes.posologiaPediatrica && detalhes.posologiaPediatrica.map((pos, index) => (
              <li key={index}>{pos}</li>
            ))}
          </ul>
          <button onClick={() => handlePrescrever("pediatrico")}>Prescrever para Pediátrico</button>
        </div>
      ) : (
        <p>Carregando detalhes...</p>
      )}
    </div>
  );
};

export default PrescricaoDetalhes;
