import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraGAD7() {
    const [scores, setScores] = useState({
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0
    });
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setScores(prevScores => ({
            ...prevScores,
            [name]: parseInt(value, 10)
        }));
    };

    const calcularGAD7 = () => {
        const totalScore = Object.values(scores).reduce((a, b) => a + b, 0);
        let interpretation = '';

        if (totalScore >= 5 && totalScore <= 9) {
            interpretation = 'Ansiedade leve';
        } else if (totalScore >= 10 && totalScore <= 14) {
            interpretation = 'Ansiedade moderada';
        } else if (totalScore >= 15 && totalScore <= 21) {
            interpretation = 'Ansiedade grave';
        } else {
            interpretation = 'Sem ansiedade significativa';
        }

        setResultado(`Pontuação total: ${totalScore}, Interpretação: ${interpretation}`);
    };

    useEffect(() => {
        calcularGAD7();
    }, [scores]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora GAD-7</h1>
            <form className='form-gad7'>
                <div>
                    <label>Sente-se nervoso, ansioso ou no limite:</label>
                    <select name="q1" value={scores.q1} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Não consegue parar ou controlar as preocupações:</label>
                    <select name="q2" value={scores.q2} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Preocupar-se demais com diferentes coisas:</label>
                    <select name="q3" value={scores.q3} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Dificuldade em relaxar:</label>
                    <select name="q4" value={scores.q4} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Ficar tão inquieto que é difícil ficar parado:</label>
                    <select name="q5" value={scores.q5} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Ficar facilmente irritado ou irritável:</label>
                    <select name="q6" value={scores.q6} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Sentir medo como se algo terrível pudesse acontecer:</label>
                    <select name="q7" value={scores.q7} onChange={handleChange}>
                        <option value="0">De jeito nenhum</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
            </form>
            <div className="result-container">
                {resultado}
            </div>
        </div>
    );
}

export default CalculadoraGAD7;
