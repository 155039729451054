import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

import CheckIcon from './../assets/tree/check (1).png'
import CheckIconDark from './../assets/tree/check dark.png'

const CheckboxContainer = styled.div`
    width: 1.25em !important;
    height: 1.25em !important;

    border: 1.85px solid ${props => props.darkMode ? '#ffffff' : '#86868B'};
    border-radius: 0.25em;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    filter: ${props => props.theme.darkMode ? 'brightness(1.2)' : 'none'};

    ${props => props.theme.darkMode ? `
        border: 1.85px solid ${props.checked ? '#60a5fa' : 'rgba(255, 255, 255, 0.7)'};
        background-color: ${props.checked ? 'rgb(51, 51, 51)' : 'transparent'};
    ` : `
        border: 1.85px solid ${props.checked ? '#1672f6' : '#86868B'};
        background-color: white;
    `}


    &:hover {
        transform: scale(1.1);
        ${props => props.theme.darkMode && `
            border-color: ${props.checked ? '#60a5fa' : 'rgba(255, 255, 255, 0.9)'};
        `}
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 1em !important;
        height: 1em !important;
        border-width: 1.65px;
        padding-top: 0.1em;
    }

    @media (max-width: 768px) {
        width: 0.8em !important;
        height: 0.8em !important;
        border-width: 1.5px;
        padding-top: 0.15em; // Alinha com o texto e ExpandCollapse
    }
`

const CheckboxImage = styled.img`
    width: 1em;
    height: 1em;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 0.8em;
        height: 0.8em;
        display: block;
    }

    @media (max-width: 768px) {
        width: 0.65em;
        height: 0.65em;
        display: block;
    }
`

const MildlyCheckedSquare = styled.div`
    width: 0.65em;
    height: 0.65em;
    border-radius: 0.125em;
    background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#1672f6'};
    opacity: ${props => props.theme.darkMode ? 0.9 : 1};

    @media (max-width: 768px) {
        width: 0.45em;
        height: 0.45em;
    }
`

export default function Checkbox({ checked, mildlyChecked, action }) {
    const darkMode = useSelector(state => state.theme.darkModeOn)

    function getContent() {
        if (checked) {
            return <CheckboxImage src={darkMode ? CheckIconDark : CheckIcon} alt="checked" />
        } else if (mildlyChecked) {
            return <MildlyCheckedSquare />
        }
        return null
    }

    function handleAction(e) {
        if (action) {
            action(e)
        }
    }

    return (
        <CheckboxContainer darkMode={darkMode} onClick={e => handleAction(e)} checked={checked}>
            {getContent()}
        </CheckboxContainer>
    )
}