// Última revisão: 09/12/2023
// Permite inserir cupom, e ver se está ativo

import React, { useState } from 'react'
import { useDispatch } from 'react-redux' 
import { setIsLoading } from '../../redux/loadingSlice'

import './InsertCoupon.scss'

import { functions } from '../../firebase/firebase-setup'
import OslerButton from '../../components/OslerButton'


export default function InsertCoupon(props) {

    const [couponCode, setCoupon] = useState("")
    const [couponResultMsg, setCouponResultMsg] = useState('')

    const dispatch = useDispatch();

    
    async function tryCupon() {
        await getPlan()
    }


    async function getPlan() {
        console.log("getPlan(): starting...")

        dispatch(setIsLoading(true))

        if (!couponCode || couponCode == '') {
            setCouponResultMsg(<></>)
            dispatch(setIsLoading(false))
        }
        else {
            try {
                const getPlan = functions.httpsCallable('getTransactionPlanByCoupon')        
                const result = await getPlan({coupon_code: couponCode.trim(), plan_id: props.originalPlanID})

                console.log(({coupon_code: couponCode.trim(), plan_id: props.originalPlanID}))
                console.log(result)
        
                if (result && result.data) {
                    console.log(result)
    
                    const planID = result.data[0]
                    const planData = result.data[1]
    
                    props.changePlan(planID, planData, couponCode.trim())
    
                    setCouponResultMsg(<p className='couponValid'>Cupom válido: {planData.discountFormatted} off!</p>)
                }
                else {
                    setCouponResultMsg(<p className='couponInvalid'>Esse cupom não existe!</p>)
                }
        
                dispatch(setIsLoading(false))
            }
            catch (error) {
                console.log("Error calling getPlan from Functions")
                console.log(error)
            }
        }
    }


    return (
        <div className='CouponInput-Container'>
            <label className='BuySubscription-InputLabel' >Tu tens um cupom? </label>

            { couponResultMsg }

            <div className='CouponInput-Selector'>
                <input
                    className='CoouponInput-Input'
                    onChange={ (event) => { setCoupon(event.target.value)}}
                    placeholder={'Insira aqui'}
                />  

                <OslerButton
                    text = 'Aplicar'
                    color = 'blue'
                    onClick = {() => {tryCupon()}}
                    style = {{marginLeft: '1em'}} />
            </div>
        </div>

    )
}