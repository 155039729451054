import React, { useEffect, useState } from "react"
import StatisticsCard from "./StatisticsCard"
import styled from "styled-components"


export const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    align-self: center;
    width: 90%;

    @media (max-width: 900px) {
        width: 98%;
        margin-top: 2em;
    }

    
`

export default function LastDaysCards({ dailyData, nDays, testType }) {
    const [daysStudied, setDaysStudied] = useState(0)
    const [totalStudyTime, setTotalStudyTime] = useState(0)
    const [avgDailyStudyTime, setAvgDailyStudyTime] = useState(0)
    const [avgDailyStudyTimeOnStudyDays, setAvgDailyStudyTimeOnStudyDays] = useState(0)
    const [totalTests, setTotalTests] = useState(0)
    const [avgTestsSpeed, setAvgTestsSpeed] = useState(0)

    useEffect(() => {
        setDaysStudied(dailyData.daysStudied ?? 0)
        setTotalStudyTime(dailyData.timeSpent ?? 0)

        const avg = dailyData.timeSpent ? (dailyData.timeSpent / nDays) : 0
        setAvgDailyStudyTime(avg)

        const avg2 = dailyData.timeSpent ? (dailyData.timeSpent / dailyData.daysStudied) : 0
        setAvgDailyStudyTimeOnStudyDays(avg2)


        if (dailyData.nTestsAnswered) {
            setTotalTests(dailyData.nTestsAnswered)
            setAvgTestsSpeed(dailyData.timeSpent / dailyData.nTestsAnswered)

        } else {
            setTotalTests(0)
            setAvgTestsSpeed(0)
        }
    }, [dailyData])


    function roundToComma(num, numDecimals = 1) {
        // arredonda para 1 casa decimal, e põe vírgula
        return num.toLocaleString('pt-BR', {
            minimumFractionDigits: numDecimals,
            maximumFractionDigits: numDecimals
          })
    }
    


    const speedUnit = {
        'Flashcards' : 'seg/card',
        'Residencia' : 'seg/questão',
        'Ambos' : 'seg/teste'
    }

    const testsDetail = {
        'Flashcards' : 'flashcards',
        'Residencia' : 'questões',
        'Ambos' : 'testes'
    }

    return (
        <CardsContainer>
            <StatisticsCard
                detail='dias de estudo'
                text={`${daysStudied} de ${nDays}`}
                unit = {` (${(daysStudied / nDays * 100).toFixed(0)}%)`} />

            <StatisticsCard
                detail='tempo de estudo'
                text={`${roundToComma(totalStudyTime / 3600, 1)}`}
                unit = 'h' />

            <StatisticsCard
                detail='ΔT médio'
                text={`${roundToComma(avgDailyStudyTime / 60, 0)}`}
                unit = 'min/dia' />

            <StatisticsCard
                detail='ΔT médio dias estudo'
                text={`${roundToComma(avgDailyStudyTimeOnStudyDays / 60, 0)}`}
                unit = 'min/dia' />

            <StatisticsCard
                detail={`total de ${testsDetail[testType]}`}
                text={`${totalTests.toLocaleString('pt-BR')}`} />

            <StatisticsCard
                detail = 'velocidade'
                text = {`${roundToComma(avgTestsSpeed, 1)}`}
                unit = {speedUnit[testType]} />

        </CardsContainer>
    )

}