// import * as FileSystem from 'expo-file-system';
// import { Asset } from 'expo-asset';


import { downloadImg } from './ImageDownloader';
// Métodos para carregar imagens indicadas pelos nossos comandos,
// que estejam dentro de strings.
//
// São métodos separados de qualquer classe porque múltiplos tipos
// de conteúdo os utilizam.


export async function changeCommandsForHtmlImages(html, cssClass = "") {
    // Pode ser qualquer string que contenha comandos para carregar
    // imagens, incluindo: flashcards, mcqs, essentials, etc.
    const command = '[img_osler'
    
    // Encontramos todas as imagens. Extraímos título, legenda, e o 
    // filename. Então, construímos promises para o download e 
    // executamos todas.
    let images = findAllImages(html)

    
    try {
        const downloadPromises = images.map(img => {
            return downloadImg(img.filename)
        })

        const imagesURLs = await Promise.all(downloadPromises)

        // Substituímos os dados no HTML.
        let contentWithImages = html
        let imageCounter = 0;
        let index = html.indexOf(command)
    
        while (index != -1) {
            const url = imagesURLs[imageCounter]
            const title  = images[imageCounter].title
            const subtitle = images[imageCounter].subtitle
            const imageIntoHtml = createImageHtml(url, imageCounter+1, title, subtitle, cssClass)
            
            contentWithImages = insertBetweenSubstrings(contentWithImages, imageIntoHtml, command, ']', index)
    
            imageCounter += 1
            index = contentWithImages.indexOf(command, index + 1)
        }
    
        return contentWithImages
    }
    catch (error) {
        console.log('\t ERROR - changeCommandsForHtmlImages()')
        console.log(`\t ERROR - ${html}`)
        console.log(error)        
    }
}


function findAllImages(string) {
    let index = string.indexOf("[img_osler")

    const images = []

    while (index != -1) {
        const endIndex = string.indexOf("]", index)

        const filename = extractImageCommandTag("ref", string, index, endIndex)
        const title = extractImageCommandTag("title", string, index, endIndex)
        const subtitle = extractImageCommandTag("subtitle", string, index, endIndex)

        const imageData = {
            filename: filename,
            title: title,
            subtitle: subtitle,
        }

        images.push(imageData)

        index = string.indexOf("[img_osler", index + 1)
    }

    return images
}


export function insertBetweenSubstrings(string, insert, start, end, index = 0) {
    let startPos = string.indexOf(start, index)
    let endPos   = string.indexOf(end, startPos)

    let before = string.slice(0, startPos)
    let after  = string.slice(endPos+1)

    return before + insert + after
}


export function extractImageCommandTag(key, string, index, endIndex) {
    // Função generalizada para obtermos filename (dentro de 'ref),
    // título (dentro de 'title') e a legenda.
    let keyCommand = key + "=|"

    const pos = string.indexOf(keyCommand, index) 
    if (pos == -1 || pos > endIndex) {
        // console.log("Elemento não encontrado " + key)
        // console.log(pos)
        return ""
    }

    const valueStart = string.indexOf(keyCommand, index) + keyCommand.length
    const valueEnd   = string.indexOf("|", valueStart)

    const value = string.slice(valueStart, valueEnd).trim()

    return value;
}


function createImageHtml(url, imageCounter, title, subtitle, cssClass) {
    // var img = "<img ";
    // var style = "style=\"display: block; margin-left: auto; margin-right: auto; " +
    //         "margin-top: 8px; max-height: 95%; max-width:95%;\"";
    // var extra = " align = \"middle\" src='" + base64 + "'";
    // var img_end = ">";

    // o <img /> precisa do alt para o onClick funcionar.

    // img-fluid é uma classe do bootstrap que dimensiona a imagem em função
    // do componente pai. Se você quer mudar o tamanho da imagem, mude
    // o componente pai.
    //      Obs: mas isso pressupõe que a tela importou o Bootstrap antes.
    let imgTemplate = `
        <div class='image-container${cssClass}'>
            <img class='image${cssClass} img-fluid' src='${url}' alt='img' />
        
            --IMAGE DESCRIPTION BOX --
        </div>

    `

    // Só adicionamos a caixa de descrição se houver informações descritivas.
    if (title === "" || cssClass !== '-mcq-img') {
        // console.log("ImageLoader: title was empty, thus we are not adding stuff.")
        imgTemplate = imgTemplate.replace(/--IMAGE DESCRIPTION BOX --/g, "")
    }
    else {
        const imgDescriptionBox = `
            <div class='image-description-box${cssClass}'>
                <div class='image-description-text${cssClass}'>
                        <span class='image-description-number${cssClass}'> --IMAGE NUMBER-- </span>
                        <span class='image-description-title${cssClass}'> --TITLE-- </span>
                        <span> --SUBTITLE-- </span>
                </div>        
            </div>
        `

        imgTemplate = imgTemplate.replace(/--IMAGE DESCRIPTION BOX --/g, imgDescriptionBox)
        imgTemplate = imgTemplate.replace(/--TITLE--/g, title)
        imgTemplate = imgTemplate.replace(/--SUBTITLE--/g, subtitle)

        if (cssClass == '-mcq-img') {
            imgTemplate = imgTemplate.replace(/--IMAGE NUMBER--/g, "Figura " + imageCounter + ". ")
        }
        else {
            imgTemplate = imgTemplate.replace(/--IMAGE NUMBER--/g, "Figura " + imageCounter + ". ")
        }
    }

    

    // Se não colocarmos as imagens dentro de um parágrafo, elas ficaram acavaladas.

    return "<p>" + imgTemplate + "</p>";
}


// export function getImagesCSS() {
//     return `
//     /* IMAGENS */
    
//     .image-container {
//         /* 
//             Centralizamos no meio da tela.
        
//             Antigamente, utilizamos display como 'table' para forçar com que 
//             a width fosse somente necessário somente para abraçar a imagem.
            
//             Porém, precisamos *garantir* que a imagem e o box tem a mesma width.
//             Logo, troquei.
            
//             Não sei a razão, mas o width precisa vir DEPOIS do flex. 
//         */
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         width: 94%


//         /* Separa a imagem (i.e., imagem + legenda) do restante do text.
//             Ao mesmo tempo, a centraliza horizontalmente. */
//         margin: 35px auto 25px auto;
//     }
    
//     .image {
//         /* width: 94%;
//         height: auto; */

//         /* Criamos uma borda superior, mais grossa, e uma mais fina. */
//         border-top: 5px solid;
//         border-bottom: 2px solid;
//         border-color: #8a1911;


//         /* Separa a imagem das suas bordas superior e inferior. */
//         padding-top: 3px;
//         padding-bottom: 3px;
//     }
    
//     .image-description-box {
//         width: 94%;
//         border-radius: 3px;
//         background-color: #ededed;
//         text-align: left;
//     }
    
//     .image-description-text {
//         padding: 10px 15px 15px 15px;
//     }
    
//     .image-description-number {
//         font-weight: bold;
//         color: #8a1911;
//     }
    
//     .image-description-title {
//         font-weight: bold;
//     }
    
//     `
// }

// export function getImagesCSSWithoutContainer() {
//     return `
//     /* IMAGENS */
    
//     .image-container {
//         /* 
//             Centralizamos no meio da tela.
        
//             Antigamente, utilizamos display como 'table' para forçar com que 
//             a width fosse somente necessário somente para abraçar a imagem.
            
//             Porém, precisamos *garantir* que a imagem e o box tem a mesma width.
//             Logo, troquei.
            
//             Não sei a razão, mas o width precisa vir DEPOIS do flex. 
//         */
//         display: flex;
//         flex-direction: column;
//         align-items: center;

//         width: 94%


//         /* Separa a imagem (i.e., imagem + legenda) do restante do text.
//             Ao mesmo tempo, a centraliza horizontalmente. */
//         margin: 35px auto 25px auto;
//     }
    
//     .image {
//         width: 94%;
//         height: auto;
//     }
//     `
// }

