import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

const Prescrever = () => {
  const { medicamentoId, tipo } = useParams();
  const [dados, setDados] = useState({ nome: '', idade: '', endereco: '', prescricao: '' });

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{'list': 'ordered'}, {'list': 'bullet'}],
      ['link', 'image'],                                // add link and image support
      ['clean']                                         // remove formatting button
    ]
  };

  useEffect(() => {
    const fetchDados = async () => {
      const docRef = doc(db, "bulario", medicamentoId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const posologia = tipo === "adulto" ? data.posologiaAdulto : data.posologiaPediatrica;
        const posologiaDelta = {
          ops: posologia.map(item => ({ insert: item + '\n' }))
        };
        setDados({ ...dados, prescricao: posologiaDelta });
      }
    };
    fetchDados();
  }, [medicamentoId, tipo]);
  
  const generatePDF = () => {
    const pdf = new jsPDF();
  
    // Configuração do PDF
    pdf.setFontSize(18);
    pdf.text('Prescrição Médica', 105, 30, null, null, 'center');
    pdf.setFontSize(14);
    pdf.text(`Nome do Paciente: ${dados.nome}`, 20, 60);
    pdf.text(`Idade do Paciente: ${dados.idade}`, 20, 80);
    if (dados.endereco) {
      pdf.text(`Endereço do Paciente: ${dados.endereco}`, 20, 100);
    }
  
    const prescricaoTexto = document.querySelector(".ql-editor").innerText;
    pdf.setFontSize(14);
    pdf.text(`Prescrição:`, 20, 120);
    pdf.text(prescricaoTexto, 20, 140, { maxWidth: 180 });
  
    // Obter o Blob do PDF
    const pdfBlob = pdf.output('blob');
  
    // Criar uma URL para o Blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
  
    // Abre o PDF em uma nova aba
    window.open(pdfUrl, '_blank');
  
    // Opcional: revogar a URL do Blob após um tempo para liberar recursos
    setTimeout(() => URL.revokeObjectURL(pdfUrl), 100);
  };
      
  
  

  return (
    <div id="prescricao-container">
    <h1>Prescrição para Medicamento: {medicamentoId}</h1>
      <div>
        <label>Nome do Paciente:</label>
        <input
          type="text"
          value={dados.nome}
          onChange={e => setDados({...dados, nome: e.target.value})}
          placeholder="Nome do Paciente"
        />
      </div>
      <div>
        <label>Idade do Paciente:</label>
        <input
          type="text"
          value={dados.idade}
          onChange={e => setDados({...dados, idade: e.target.value})}
          placeholder="Idade do Paciente"
        />
      </div>
      <div>
        <label>Endereço do Paciente (se aplicável):</label>
        <input
          type="text"
          value={dados.endereco}
          onChange={e => setDados({...dados, endereco: e.target.value})}
          placeholder="Endereço do Paciente"
        />
      </div>
      <div>
        <label>Prescrição (edite a dose):</label>
        <ReactQuill theme="snow" value={dados.prescricao} onChange={(value) => setDados({...dados, prescricao: value})} />
      </div>
      <button onClick={generatePDF}>Gerar PDF</button>
    </div>
  );
};

export default Prescrever;
