import React from 'react';
import { styled } from 'styled-components'

const StatisticsCard = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    padding: 2em 3em 2em 3em;
    border-radius: 0.5em;

    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;

    ${props => props.clicked ? `
        cursor: normal;
        background-color: #c8d6e6;
    ` : `
        background-color: white;
        cursor: pointer;
    `}
`

const StatisticsHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`


const StatisticsIcon = styled.p`
    font-size: 2em;
    margin: 0;
`

const StatisticsNumber = styled.p`
    font-size: 1.5em;
    margin: 0 0 0 0.5em;
    font-weight: bold;
`

const StatisticsTxt = styled.p`
    font-size: 0.9em;
    color: gray;
    font-weight: bold;
    margin: 0;
`


export default function AmbassadosStatisticCard(props) {
    return (
        <StatisticsCard onClick={() => props.onClick()} clicked = {props.clicked}>
            <StatisticsHeader>
                <StatisticsIcon>{props.emoji}</StatisticsIcon>
                <StatisticsNumber>{props.number}</StatisticsNumber>
            </StatisticsHeader>
            <StatisticsTxt>{props.text}</StatisticsTxt>
        </StatisticsCard>
    )
};
