import { createSlice } from '@reduxjs/toolkit'

export const themeSlice = createSlice({
    name: 'themeSlice',
    initialState: {
        data : {
            darkModeOn: false
        }
    },
    reducers: {
        setDarkModeTo: (state, action) => {
            console.log('No reducer, mudando para: ' + action.payload)
            state.darkModeOn = action.payload
        }
    }
})

export const { setDarkModeTo } = themeSlice.actions
export default themeSlice.reducer