import React, { useState, useEffect } from 'react'

import Logo from './../assets/Logo.png'
import './MaintenanceScreen.css'
import styled from 'styled-components'
import { ColumnCSS } from '../components/BasicComponents'



const Container = styled.div`
    ${ColumnCSS}
    justify-content: center;
    height: 100vh;
    width: 100%;
`

const Content = styled.div`
    width:  50%;
    text-align: center;
    @media (max-width: 768px) {
        width: 80%;
    }
`


const Serious = styled.p`
    font-weight: bold;
    color: black;
    font-size: 1em;
    margin-top: 1em;
`


export default function MaintenanceScreen() {

    return (
        <Container>
            <Content>
                <img src = {Logo} className = 'maintenance-logo' />
                <p className = 'maintenance-title'>Manutenção</p>

                {/* <p className = 'maintenance-text'>O interno contaminou o campo cirúrgico. Precisamos de algumar horas para preparar a sala. Desculpe o transtorno!</p> */}

                {/* <Serious>Precisamos fazer alguns ajustes para a próxima atualização. E estudar de sábado à noite faz mal. Deve voltar ao ar até amanhã cedo.</Serious> */}

                <Serious>Estamos trazendo a tela de estatísticas. Deve demorar alguns minutos. E, convenhamos, sexta à noite...</Serious>

                {/* <p className = 'maintenance-text'><b>Estamos quase acabando.</b> A plataforma deve voltar ao ar ainda hoje.</p> */}
            </Content>
        </Container>
    )
}