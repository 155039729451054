import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraMagnesioCorrigido() {
    const [mg, setMg] = useState('');
    const [albumina, setAlbumina] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularMagnesioCorrigido = () => {
        const mgNum = parseFloat(mg);
        const albuminaNum = parseFloat(albumina);

        if (isNaN(mgNum) || isNaN(albuminaNum)) {
            setResultado('Preencha os campos em branco');
            return;
        }

        const magnesioCorrigido = (mgNum * 4) / albuminaNum;
        setResultado(`<strong>${magnesioCorrigido.toFixed(1)}</strong> mg/dl`);
    };

    useEffect(() => {
        if (mg && albumina) {
            calcularMagnesioCorrigido();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [mg, albumina]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Magnésio Plasmático Total Corrigido</h1>
            <div>
                <label>Mg (mg/dl):</label>
                <input type="number" value={mg} onChange={e => setMg(e.target.value)} />
            </div>
            <div>
                <label>Albumina (g/dl):</label>
                <input type="number" value={albumina} onChange={e => setAlbumina(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraMagnesioCorrigido;
