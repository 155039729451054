import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraIST() {
    const [opcao, setOpcao] = useState('');
    const [ferro, setFerro] = useState('');
    const [transferrina, setTransferrina] = useState('');
    const [tibc, setTibc] = useState('');
    const [resultado, setResultado] = useState('');
    const [referencia, setReferencia] = useState('');

    const calcularIST = () => {
        const ferroNum = parseFloat(ferro);
        let sat = 0;

        if (opcao === 'transferrina' && transferrina) {
            const transferrinaNum = parseFloat(transferrina);
            if (transferrinaNum > 0) {
                sat = (ferroNum / transferrinaNum) * 71.24;
                setReferencia('');
            }
        } else if (opcao === 'tibc' && tibc) {
            const tibcNum = parseFloat(tibc);
            if (tibcNum > 0) {
                sat = (100 * ferroNum) / tibcNum;
                setReferencia(`A faixa normal para o índice de saturação de transferrina (IST) é de 20 a 50%.
                Um IST alto (≥ 45% em homens ou ≥ 40% em mulheres) sugere sobrecarga de ferro.
                Um IST baixo (< 16% em adultos não grávidos ou < 20% durante a gravidez) é consistente com deficiência de ferro.`);
            }
        } else {
            setResultado('Preencha todos os campos obrigatórios com valores válidos.');
            setReferencia('');
            return;
        }

        setResultado(`Índice de Saturação de Transferrina (IST): ${sat.toFixed(2)}%`);
    };

    useEffect(() => {
        if (ferro && ((opcao === 'transferrina' && transferrina) || (opcao === 'tibc' && tibc))) {
            calcularIST();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setReferencia('');
        }
    }, [opcao, ferro, transferrina, tibc]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Índice de Saturação de Transferrina (IST)</h1>
            <div>
                <label>Valor a ser usado:</label>
                <select value={opcao} onChange={e => setOpcao(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="transferrina">Transferrina</option>
                    <option value="tibc">Capacidade Total de Ligação do Ferro (TIBC)</option>
                </select>
            </div>
            <div>
                <label>Ferro Sérico (µg/dL):</label>
                <input type="number" value={ferro} onChange={e => setFerro(e.target.value)} />
            </div>
            {opcao === 'transferrina' && (
                <div>
                    <label>Transferrina (mg/dL):</label>
                    <input type="number" value={transferrina} onChange={e => setTransferrina(e.target.value)} />
                </div>
            )}
            {opcao === 'tibc' && (
                <div>
                    <label>Capacidade Total de Ligação do Ferro (TIBC) (µg/dL):</label>
                    <input type="number" value={tibc} onChange={e => setTibc(e.target.value)} />
                </div>
            )}
            <div className="result-container">
                <p>{resultado}</p>
                {referencia && <p>{referencia}</p>}
            </div>
            
        </div>
    );
}

export default CalculadoraIST;
