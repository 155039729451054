import React, { useState } from 'react'
import GeneralDialog from '../tests/GeneralDialog'
import { FaSave } from 'react-icons/fa'
import { toastMsg } from '../utils/Utils'
import styled from 'styled-components'
import CustomFiltersController from '../controllers/CustomFiltersController'

import SaveIcon from '../assets/save_folder.png'

const Input = styled.input`
    font-size: 1.2em;
    color: black;
    caret-color: black;

    padding: 0.25em 0.25em 0.25em 0.5em;

    border: 1px solid gray;
    align-self: center;

    margin-top: 1em;
    margin-bottom: 1em;

    border-radius: 0.5em;
    width: 85%;
`


export default function SaveFilterDialog({ showDialog, setShowDialog, saveFilter }) {
    const [filterName, setFilterName] = useState('')

    function handleOk() {
        if (filterName === '') {
            toastMsg('Pô, precisa escolher um nome 😥')
        } 
        else if ( !CustomFiltersController.checkFilterNameAvailable(filterName) ) {
            toastMsg('Você já tem um filtro personalizado com esse nome! Tente de novo! 👀')
        } 
        else {
            saveFilter(filterName)
            setFilterName('')
            setShowDialog(false)
        }
    }

    return (
        <GeneralDialog
            icon={SaveIcon}
            title='Vamos criar um filtro?'
            open={showDialog}
            onClose={() => setShowDialog(false)}
            allowClose={true}
            actions={[
                { label: 'Cancelar', onClick: () => setShowDialog(false), style: 'neutral' },
                { label: 'Salvar', onClick: handleOk, style: 'confirm' }
            ]}
        >

            <p>Filtros são um modo de salvar seleções de <u>instituições</u> e <u>anos</u> que são do seu interesse, de modo a não ter que selecioná-los todas as vezes que desejar criar uma lista de questões.</p>
            <p>Qual o nome quer dar a esse filtro?</p>

            <Input
                value={filterName}
                onChange={e => setFilterName(e.target.value)}
                autoFocus
            />
        </GeneralDialog>
    )
}