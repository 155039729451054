import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase/firebase-setup';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';

const Prescricao = () => {
  const { usoClinico } = useParams();
  const [medicamentos, setMedicamentos] = useState([]);

  useEffect(() => {
    const fetchMedicamentos = async () => {
      const decodedUsoClinico = decodeURIComponent(usoClinico);
      const q = query(collection(db, "bulario"), where("usoClinico", "array-contains", decodedUsoClinico));
      const querySnapshot = await getDocs(q);
      const meds = [];

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        meds.push({
          id: doc.id,
          name: doc.id,
          posologiaAdulto: data.posologiaAdulto || ['Informação não disponível'],
          posologiaPediatrica: data.posologiaPediatrica || ['Informação não disponível'],
          copied: false  // Estado para gerenciar a mensagem de "Copiado!"
        });
      });

      setMedicamentos(meds);
    };

    fetchMedicamentos();
  }, [usoClinico]);

  const copyToClipboard = (text, index, tipo) => {
    const el = document.createElement('textarea');
    el.value = text.map(item => `• ${item}`).join('\n');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    // Atualizar o estado para mostrar a mensagem "Copiado!"
    const newMeds = [...medicamentos];
    newMeds[index][`copied${tipo}`] = true;
    setMedicamentos(newMeds);

    // Remover a mensagem "Copiado!" após 5 segundos
    setTimeout(() => {
      newMeds[index][`copied${tipo}`] = false;
      setMedicamentos(newMeds);
    }, 50);
  };

  return (
    <div className="Prescricao-container">
        <Link to="/clinicolist" className="button-voltar-bulario">&lt;</Link>
      <h1 className="Prescricao-header">Prescrições para: {decodeURIComponent(usoClinico).slice(0, -1)}</h1>
      <ul className="Prescricao-list">
        {medicamentos.map((med, index) => (
          <li key={med.id}>
            <strong>{med.name}</strong>
                    <div className='Prescricao-topicos'>
                        <strong>Posologia Adulto:</strong>
                        <ul>
                {med.posologiaAdulto.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
              <button className="botao-copiar" onClick={() => copyToClipboard(med.posologiaAdulto, index, 'Adulto')}>Copiar</button>
              {med.copiedAdulto && <span className="copy-success">Copiado!</span>}
                    </div>
                    <div className='Prescricao-topicos'>
                        <strong>Posologia Pediátrica:</strong>
                        <ul>
                {med.posologiaPediatrica.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
                        <button className="botao-copiar" onClick={() => copyToClipboard(med.posologiaPediatrica, index, 'Pediatrica')}>Copiar</button>
              {med.copiedPediatrica && <span className="copy-success">Copiado!</span>}
                    </div>
                </li>
            ))}
        </ul>
    </div>
);

};

export default Prescricao;
