import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

const lmsData = [
    { age: 24, L: -2.01118107, M: 16.57502768, S: 0.080592465 },
    { age: 24.5, L: -1.982373595, M: 16.54777487, S: 0.080127429 },
    { age: 25.5, L: -1.924100169, M: 16.49442763, S: 0.079233994 },
    { age: 26.5, L: -1.86549793, M: 16.44259552, S: 0.078389356 },
    { age: 27.5, L: -1.807261899, M: 16.3922434, S: 0.077593501 },
    { age: 28.5, L: -1.750118905, M: 16.34333654, S: 0.076846462 },
    { age: 29.5, L: -1.69481584, M: 16.29584097, S: 0.076148308 },
    { age: 30.5, L: -1.642106779, M: 16.24972371, S: 0.075499126 },
    { age: 31.5, L: -1.592744414, M: 16.20495268, S: 0.074898994 },
    { age: 32.5, L: -1.547442391, M: 16.16149871, S: 0.074347997 },
    { age: 33.5, L: -1.506902601, M: 16.11933258, S: 0.073846139 },
    { age: 34.5, L: -1.471770047, M: 16.07842758, S: 0.07339337 },
    { age: 35.5, L: -1.442628957, M: 16.03875896, S: 0.072989551 },
    { age: 36.5, L: -1.419991255, M: 16.00030401, S: 0.072634432 },
    { age: 37.5, L: -1.404277619, M: 15.96304277, S: 0.072327649 },
    { age: 38.5, L: -1.39586317, M: 15.92695418, S: 0.07206864 },
    { age: 39.5, L: -1.394935252, M: 15.89202582, S: 0.071856805 },
    { age: 40.5, L: -1.401671596, M: 15.85824093, S: 0.071691278 },
    { age: 41.5, L: -1.416100312, M: 15.82558822, S: 0.071571093 },
    { age: 42.5, L: -1.438164899, M: 15.79405728, S: 0.071495113 },
    { age: 43.5, L: -1.467669032, M: 15.76364255, S: 0.071462106 },
    { age: 44.5, L: -1.504376347, M: 15.73433668, S: 0.071470646 },
    { age: 45.5, L: -1.547942838, M: 15.70613566, S: 0.071519218 },
    { age: 46.5, L: -1.597896397, M: 15.67904062, S: 0.071606277 },
    { age: 47.5, L: -1.653732283, M: 15.65305192, S: 0.071730167 },
    { age: 48.5, L: -1.714869347, M: 15.62817269, S: 0.071889214 },
    { age: 49.5, L: -1.780673181, M: 15.604408, S: 0.072081737 },
    { age: 50.5, L: -1.850468473, M: 15.58176458, S: 0.072306081 },
    { age: 51.5, L: -1.923551865, M: 15.56025067, S: 0.072560637 },
    { age: 52.5, L: -1.999220429, M: 15.5398746, S: 0.07284384 },
    { age: 53.5, L: -2.076707178, M: 15.52064993, S: 0.073154324 },
    { age: 54.5, L: -2.155348017, M: 15.50258427, S: 0.073490667 },
    { age: 55.5, L: -2.234438552, M: 15.48568973, S: 0.073851672 },
    { age: 56.5, L: -2.313321723, M: 15.46997718, S: 0.074236235 },
    { age: 57.5, L: -2.391381273, M: 15.45545692, S: 0.074643374 },
    { age: 58.5, L: -2.468032491, M: 15.44213961, S: 0.075072264 },
    { age: 59.5, L: -2.542781541, M: 15.43003207, S: 0.075522104 },
    { age: 60.5, L: -2.61516595, M: 15.41914163, S: 0.07599225 },
    { age: 61.5, L: -2.684789516, M: 15.40947356, S: 0.076482128 },
    { age: 62.5, L: -2.751316949, M: 15.40103139, S: 0.076991232 },
    { age: 63.5, L: -2.81445945, M: 15.39381785, S: 0.077519149 },
    { age: 64.5, L: -2.87402476, M: 15.38783094, S: 0.07806539 },
    { age: 65.5, L: -2.92984048, M: 15.38306945, S: 0.078629592 },
    { age: 66.5, L: -2.981796828, M: 15.37952958, S: 0.079211369 },
    { age: 67.5, L: -3.029831343, M: 15.37720582, S: 0.079810334 },
    { age: 68.5, L: -3.073924224, M: 15.37609107, S: 0.080426086 },
    { age: 69.5, L: -3.114093476, M: 15.37617677, S: 0.081058206 },
    { age: 70.5, L: -3.15039, M: 15.37745304, S: 0.081706249 },
    { age: 71.5, L: -3.182893018, M: 15.37990886, S: 0.082369741 },
    { age: 72.5, L: -3.21170511, M: 15.38353217, S: 0.083048178 },
    { age: 73.5, L: -3.23694834, M: 15.38831005, S: 0.083741021 },
    { age: 74.5, L: -3.25876011, M: 15.39422883, S: 0.0844477 },
    { age: 75.5, L: -3.277281546, M: 15.40127496, S: 0.085167651 },
    { age: 76.5, L: -3.292683774, M: 15.40943252, S: 0.085900184 },
    { age: 77.5, L: -3.305124073, M: 15.41868691, S: 0.086644667 },
    { age: 78.5, L: -3.314768951, M: 15.42902273, S: 0.087400421 },
    { age: 79.5, L: -3.321785992, M: 15.44042439, S: 0.088166744 },
    { age: 80.5, L: -3.326345795, M: 15.45287581, S: 0.088942897 },
    { age: 81.5, L: -3.328602731, M: 15.46636218, S: 0.089728202 },
    { age: 82.5, L: -3.328725277, M: 15.48086704, S: 0.090521875 },
    { age: 83.5, L: -3.32687018, M: 15.49637465, S: 0.091323162 },
    { age: 84.5, L: -3.323188896, M: 15.51286936, S: 0.092131305 },
    { age: 85.5, L: -3.317827016, M: 15.53033563, S: 0.092945544 },
    { age: 86.5, L: -3.310923871, M: 15.54875807, S: 0.093765118 },
    { age: 87.5, L: -3.302612272, M: 15.56812143, S: 0.09458927 },
    { age: 88.5, L: -3.293018361, M: 15.58841065, S: 0.095417247 },
    { age: 89.5, L: -3.282260813, M: 15.60961101, S: 0.096248301 },
    { age: 90.5, L: -3.270454609, M: 15.63170735, S: 0.097081694 },
    { age: 91.5, L: -3.257703616, M: 15.65468563, S: 0.097916698 },
    { age: 92.5, L: -3.244108214, M: 15.67853139, S: 0.098752593 },
    { age: 93.5, L: -3.229761713, M: 15.70323052, S: 0.099588675 },
    { age: 94.5, L: -3.214751287, M: 15.72876911, S: 0.100424251 },
    { age: 95.5, L: -3.199158184, M: 15.75513347, S: 0.101258643 },
    { age: 96.5, L: -3.18305795, M: 15.78231007, S: 0.102091189 },
    { age: 97.5, L: -3.166520664, M: 15.8102856, S: 0.102921245 },
    { age: 98.5, L: -3.1496103, M: 15.83904708, S: 0.103748189 },
    { age: 99.5, L: -3.132389637, M: 15.86858123, S: 0.104571386 },
    { age: 100.5, L: -3.114911153, M: 15.89887562, S: 0.105390269 },
    { age: 101.5, L: -3.097226399, M: 15.92991765, S: 0.106204258 },
    { age: 102.5, L: -3.079383079, M: 15.96169481, S: 0.107012788 },
    { age: 103.5, L: -3.061423765, M: 15.99419489, S: 0.107815327 },
    { age: 104.5, L: -3.043386071, M: 16.02740607, S: 0.108611374 },
    { age: 105.5, L: -3.025310003, M: 16.0613159, S: 0.109400388 },
    { age: 106.5, L: -3.007225737, M: 16.09591292, S: 0.110181915 },
    { age: 107.5, L: -2.989164598, M: 16.13118532, S: 0.110955478 },
    { age: 108.5, L: -2.971148225, M: 16.16712234, S: 0.111720691 },
    { age: 109.5, L: -2.953208047, M: 16.20371168, S: 0.112477059 },
    { age: 110.5, L: -2.935363951, M: 16.24094239, S: 0.1132242 },
    { age: 111.5, L: -2.917635157, M: 16.27880346, S: 0.113961734 },
    { age: 112.5, L: -2.900039803, M: 16.31728385, S: 0.114689291 },
    { age: 113.5, L: -2.882593796, M: 16.35637267, S: 0.115406523 },
    { age: 114.5, L: -2.865311266, M: 16.39605916, S: 0.116113097 },
    { age: 115.5, L: -2.848204697, M: 16.43633265, S: 0.116808702 },
    { age: 116.5, L: -2.831285052, M: 16.47718256, S: 0.117493042 },
    { age: 117.5, L: -2.81456189, M: 16.51859843, S: 0.11816584 },
    { age: 118.5, L: -2.79804347, M: 16.56056987, S: 0.118826835 },
    { age: 119.5, L: -2.781736856, M: 16.60308661, S: 0.119475785 },
    { age: 120.5, L: -2.765648008, M: 16.64613844, S: 0.120112464 },
    { age: 121.5, L: -2.749782197, M: 16.68971518, S: 0.120736656 },
    { age: 122.5, L: -2.734142443, M: 16.73380695, S: 0.121348181 },
    { age: 123.5, L: -2.718732873, M: 16.77840363, S: 0.121946849 },
    { age: 124.5, L: -2.703555506, M: 16.82349538, S: 0.122532501 },
    { age: 125.5, L: -2.688611957, M: 16.86907238, S: 0.123104991 },
    { age: 126.5, L: -2.673903164, M: 16.91512487, S: 0.123664186 },
    { age: 127.5, L: -2.659429443, M: 16.96164317, S: 0.124209969 },
    { age: 128.5, L: -2.645190534, M: 17.00861766, S: 0.124742239 },
    { age: 129.5, L: -2.631185649, M: 17.05603879, S: 0.125260905 },
    { age: 130.5, L: -2.617413511, M: 17.10389705, S: 0.125765895 },
    { age: 131.5, L: -2.603872392, M: 17.15218302, S: 0.126257147 },
    { age: 132.5, L: -2.590560148, M: 17.20088732, S: 0.126734613 },
    { age: 133.5, L: -2.577474253, M: 17.25000062, S: 0.12719826 },
    { age: 134.5, L: -2.564611831, M: 17.29951367, S: 0.127648067 },
    { age: 135.5, L: -2.551969684, M: 17.34941726, S: 0.128084023 },
    { age: 136.5, L: -2.539539972, M: 17.39970308, S: 0.128506192 },
    { age: 137.5, L: -2.527325681, M: 17.45036072, S: 0.128914497 },
    { age: 138.5, L: -2.515320235, M: 17.50138161, S: 0.129309001 },
    { age: 139.5, L: -2.503519447, M: 17.55275674, S: 0.129689741 },
    { age: 140.5, L: -2.491918934, M: 17.60447714, S: 0.130056765 },
    { age: 141.5, L: -2.480514136, M: 17.6565339, S: 0.130410133 },
    { age: 142.5, L: -2.469300331, M: 17.70891811, S: 0.130749913 },
    { age: 143.5, L: -2.458272656, M: 17.76162094, S: 0.131076187 },
    { age: 144.5, L: -2.447426113, M: 17.81463359, S: 0.131389042 },
    { age: 145.5, L: -2.436755595, M: 17.86794729, S: 0.131688579 },
    { age: 146.5, L: -2.426255887, M: 17.92155332, S: 0.131974905 },
    { age: 147.5, L: -2.415921689, M: 17.97544299, S: 0.132248138 },
    { age: 148.5, L: -2.405747619, M: 18.02960765, S: 0.132508403 },
    { age: 149.5, L: -2.395728233, M: 18.08403868, S: 0.132755834 },
    { age: 150.5, L: -2.385858029, M: 18.1387275, S: 0.132990575 },
    { age: 151.5, L: -2.376131459, M: 18.19366555, S: 0.133212776 },
    { age: 152.5, L: -2.366542942, M: 18.24884431, S: 0.133422595 },
    { age: 153.5, L: -2.357086871, M: 18.3042553, S: 0.133620197 },
    { age: 154.5, L: -2.347757625, M: 18.35989003, S: 0.133805756 },
    { age: 155.5, L: -2.338549576, M: 18.41574009, S: 0.133979452 },
    { age: 156.5, L: -2.3294571, M: 18.47179706, S: 0.13414147 },
    { age: 157.5, L: -2.320474586, M: 18.52805255, S: 0.134292005 },
    { age: 158.5, L: -2.311596446, M: 18.5844982, S: 0.134431256 },
    { age: 159.5, L: -2.302817124, M: 18.64112567, S: 0.134559427 },
    { age: 160.5, L: -2.294131107, M: 18.69792663, S: 0.134676731 },
    { age: 161.5, L: -2.285532933, M: 18.75489278, S: 0.134783385 },
    { age: 162.5, L: -2.277017201, M: 18.81201584, S: 0.134879611 },
    { age: 163.5, L: -2.268578584, M: 18.86928753, S: 0.134965637 },
    { age: 164.5, L: -2.260211837, M: 18.92669959, S: 0.135041695 },
    { age: 165.5, L: -2.251911809, M: 18.98424378, S: 0.135108024 },
    { age: 166.5, L: -2.243673453, M: 19.04191185, S: 0.135164867 },
    { age: 167.5, L: -2.235491842, M: 19.09969557, S: 0.135212469 },
    { age: 168.5, L: -2.227362173, M: 19.15758672, S: 0.135251083 },
    { age: 169.5, L: -2.21927979, M: 19.21557707, S: 0.135280963 },
    { age: 170.5, L: -2.211240187, M: 19.27365839, S: 0.135302371 },
    { age: 171.5, L: -2.203239029, M: 19.33182247, S: 0.135315568 },
    { age: 172.5, L: -2.195272161, M: 19.39006106, S: 0.135320824 },
    { age: 173.5, L: -2.187335625, M: 19.44836594, S: 0.135318407 },
    { age: 174.5, L: -2.179425674, M: 19.50672885, S: 0.135308594 },
    { age: 175.5, L: -2.171538789, M: 19.56514153, S: 0.135291662 },
    { age: 176.5, L: -2.163671689, M: 19.62359571, S: 0.135267891 },
    { age: 177.5, L: -2.155821357, M: 19.6820831, S: 0.135237567 },
    { age: 178.5, L: -2.147985046, M: 19.74059538, S: 0.135200976 },
    { age: 179.5, L: -2.140160305, M: 19.7991242, S: 0.135158409 },
    { age: 180.5, L: -2.132344989, M: 19.85766121, S: 0.135110159 },
    { age: 181.5, L: -2.124537282, M: 19.916198, S: 0.135056522 },
    { age: 182.5, L: -2.116735712, M: 19.97472615, S: 0.134997797 },
    { age: 183.5, L: -2.108939167, M: 20.03323719, S: 0.134934285 },
    { age: 184.5, L: -2.10114692, M: 20.09172262, S: 0.134866291 },
    { age: 185.5, L: -2.093358637, M: 20.15017387, S: 0.134794121 },
    { age: 186.5, L: -2.085574403, M: 20.20858236, S: 0.134718085 },
    { age: 187.5, L: -2.077794735, M: 20.26693944, S: 0.134638494 },
    { age: 188.5, L: -2.070020599, M: 20.32523642, S: 0.134555663 },
    { age: 189.5, L: -2.062253431, M: 20.38346455, S: 0.13446991 },
    { age: 190.5, L: -2.054495145, M: 20.44161501, S: 0.134381553 },
    { age: 191.5, L: -2.046748156, M: 20.49967894, S: 0.134290916 },
    { age: 192.5, L: -2.039015385, M: 20.5576474, S: 0.134198323 },
    { age: 193.5, L: -2.031300282, M: 20.6155114, S: 0.134104101 },
    { age: 194.5, L: -2.023606828, M: 20.67326189, S: 0.134008581 },
    { age: 195.5, L: -2.015942013, M: 20.73088905, S: 0.133912066 },
    { age: 196.5, L: -2.008305745, M: 20.7883851, S: 0.133814954 },
    { age: 197.5, L: -2.000706389, M: 20.84574003, S: 0.133717552 },
    { age: 198.5, L: -1.993150137, M: 20.90294449, S: 0.1336202 },
    { age: 199.5, L: -1.985643741, M: 20.95998909, S: 0.133523244 },
    { age: 200.5, L: -1.97819451, M: 21.01686433, S: 0.133427032 },
    { age: 201.5, L: -1.970810308, M: 21.07356067, S: 0.133331914 },
    { age: 202.5, L: -1.96349954, M: 21.1300685, S: 0.133238245 },
    { age: 203.5, L: -1.956271141, M: 21.18637813, S: 0.133146383 },
    { age: 204.5, L: -1.949134561, M: 21.24247982, S: 0.13305669 },
    { age: 205.5, L: -1.942099744, M: 21.29836376, S: 0.132969531 },
    { age: 206.5, L: -1.935177101, M: 21.35402009, S: 0.132885274 },
    { age: 207.5, L: -1.92837748, M: 21.40943891, S: 0.132804292 },
    { age: 208.5, L: -1.921712136, M: 21.46461026, S: 0.132726962 },
    { age: 209.5, L: -1.915192685, M: 21.51952414, S: 0.132653664 },
    { age: 210.5, L: -1.908831065, M: 21.57417053, S: 0.132584784 },
    { age: 211.5, L: -1.902639482, M: 21.62853937, S: 0.132520711 },
    { age: 212.5, L: -1.896630358, M: 21.68262062, S: 0.132461838 },
    { age: 213.5, L: -1.890816268, M: 21.73640419, S: 0.132408563 },
    { age: 214.5, L: -1.885209876, M: 21.78988003, S: 0.132361289 },
    { age: 215.5, L: -1.879823505, M: 21.84303819, S: 0.132320427 },
    { age: 216.5, L: -1.874670324, M: 21.8958685, S: 0.132286382 },
    { age: 217.5, L: -1.869760299, M: 21.94836168, S: 0.1322596 },
    { age: 218.5, L: -1.865113245, M: 22.00050569, S: 0.132240418 },
    { age: 219.5, L: -1.860734944, M: 22.05229242, S: 0.13222933 },
    { age: 220.5, L: -1.85663384, M: 22.10371305, S: 0.132226801 },
    { age: 221.5, L: -1.852827186, M: 22.15475603, S: 0.132233201 },
    { age: 222.5, L: -1.849323204, M: 22.20541249, S: 0.132248993 },
    { age: 223.5, L: -1.846131607, M: 22.255673, S: 0.132274625 },
    { age: 224.5, L: -1.843261294, M: 22.30552831, S: 0.132310549 },
    { age: 225.5, L: -1.840720248, M: 22.3549693, S: 0.132357221 },
    { age: 226.5, L: -1.83851544, M: 22.40398706, S: 0.132415103 },
    { age: 227.5, L: -1.83665586, M: 22.45257182, S: 0.132484631 },
    { age: 228.5, L: -1.835138046, M: 22.50071778, S: 0.132566359 },
    { age: 229.5, L: -1.833972004, M: 22.54841437, S: 0.132660699 },
    { age: 230.5, L: -1.833157751, M: 22.59565422, S: 0.132768153 },
    { age: 231.5, L: -1.83269562, M: 22.64242956, S: 0.132889211 },
    { age: 232.5, L: -1.832584342, M: 22.68873292, S: 0.133024368 },
    { age: 233.5, L: -1.832820974, M: 22.73455713, S: 0.133174129 },
    { age: 234.5, L: -1.833400825, M: 22.7798953, S: 0.133338999 },
    { age: 235.5, L: -1.834317405, M: 22.82474087, S: 0.133519496 },
    { age: 236.5, L: -1.83555752, M: 22.86908912, S: 0.133716192 },
    { age: 237.5, L: -1.837119466, M: 22.91293151, S: 0.133929525 },
    { age: 238.5, L: -1.838987063, M: 22.95626373, S: 0.134160073 },
    { age: 239.5, L: -1.841146139, M: 22.99908062, S: 0.134408381 },
    { age: 240, L: -1.84233016, M: 23.02029424, S: 0.134539365 },
    { age: 240.5, L: -1.843580575, M: 23.04137734, S: 0.134675001 }
];


function CalculadoraIMCPedMasc() {
    const [idade, setIdade] = useState('');
    const [altura, setAltura] = useState('');
    const [peso, setPeso] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularBMI = () => {
        const alturaMetros = parseFloat(altura) / 100;
        const bmi = parseFloat(peso) / (alturaMetros * alturaMetros);

        const idadeMeses = parseFloat(idade) * 12;
        // Encontrar a idade mais próxima disponível em lmsData
        const lms = lmsData.reduce((prev, curr) => {
            return (Math.abs(curr.age - idadeMeses) < Math.abs(prev.age - idadeMeses) ? curr : prev);
        });

        if (!lms) {
            setResultado('Idade não suportada para cálculo.');
            setInterpretacao('');
            return;
        }

        const { L, M, S } = lms;
        const zScore = ((bmi / M) ** L - 1) / (L * S);
        const percentile = ZtoPercentile(zScore);
        const bmi95 = M * (((L * S * 1.645) + 1) ** (1 / L));

        let classificacao = '';

        if (percentile < 5) {
            classificacao = 'Abaixo do peso';
        } else if (percentile >= 5 && percentile < 85) {
            classificacao = 'Peso saudável';
        } else if (percentile >= 85 && percentile < 95) {
            classificacao = 'Sobrepeso';
        } else if (bmi >= bmi95 && bmi < bmi95 * 1.2 && bmi < 35) {
            classificacao = 'Obesidade classe I';
        } else if ((bmi >= bmi95 * 1.2 && bmi < bmi95 * 1.4) || bmi >= 35) {
            classificacao = 'Obesidade classe II';
        } else if (bmi >= bmi95 * 1.4 || bmi >= 40) {
            classificacao = 'Obesidade classe III';
        }

        setResultado(`BMI: ${bmi.toFixed(2)}, Z-score: ${zScore.toFixed(2)}, Percentil: ${percentile.toFixed(2)}%`);
        setInterpretacao(classificacao);
    };

    const ZtoPercentile = (z) => {
        const erf = (x) => {
            const sign = x >= 0 ? 1 : -1;
            x = Math.abs(x);
            const a1 = 0.254829592;
            const a2 = -0.284496736;
            const a3 = 1.421413741;
            const a4 = -1.453152027;
            const a5 = 1.061405429;
            const p = 0.3275911;
            const t = 1.0 / (1.0 + p * x);
            const y = 1.0 - (((((a5 * t + a4) * t) + a3) * t + a2) * t + a1) * t * Math.exp(-x * x);
            return sign * y;
        };

        const percentil = 50 * (1 + erf(z / Math.sqrt(2)));
        return percentil;
    };

    useEffect(() => {
        if (idade && altura && peso) {
            calcularBMI();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [idade, altura, peso]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de BMI percentiles and Z-scores</h1>
            <div>
                <label>Idade (anos):</label>
                <input type="number" value={idade} onChange={e => setIdade(e.target.value)} />
            </div>
            <div>
                <label>Altura (cm):</label>
                <input type="number" value={altura} onChange={e => setAltura(e.target.value)} />
            </div>
            <div>
                <label>Peso (kg):</label>
                <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraIMCPedMasc;