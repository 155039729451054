import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraFisher() {
    const [sintomas, setSintomas] = useState('');
    const [resultado, setResultado] = useState('Preencha todos os campos para ver o resultado.');

    useEffect(() => {
        if (sintomas) {
            calcularEscalaFisher();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [sintomas]);

    const calcularEscalaFisher = () => {
        let classificacao = '';
        let risco = '';

        switch (sintomas) {
            case 'Não se detecta sangue':
                classificacao = 'Fisher I';
                risco = 'Baixo risco de vasoespasmo (0-21%)';
                break;
            case 'Deposição difusa ou camada de sangue em espessura < 1 mm':
                classificacao = 'Fisher II';
                risco = 'Baixo risco de vasoespasmo (0-25%)';
                break;
            case 'Coágulos localizados e/ou camada de sangue em espessura acima de 1 mm':
                classificacao = 'Fisher III';
                risco = 'Baixo a alto risco de vasoespasmo (23-96%)';
                break;
            case 'Presença de coágulos intraparenquimatosos ou intraventriculares, com ou sem sangramento subaracnóideo difuso':
                classificacao = 'Fisher IV';
                risco = 'Baixo a moderado risco de vasoespasmo (0-35%)';
                break;
            default:
                classificacao = '';
                risco = '';
        }

        setResultado(`Classificação: ${classificacao}. ${risco}.`);
    };

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escala de Fisher (Hemorragia Subaracnoide)</h1>
            <div>
                <label>Sinais/sintomas:</label>
                <select value={sintomas} onChange={e => setSintomas(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Não se detecta sangue">Não se detecta sangue</option>
                    <option value="Deposição difusa ou camada de sangue em espessura < 1 mm">Deposição difusa ou camada de sangue em espessura &lt; 1 mm</option>
                    <option value="Coágulos localizados e/ou camada de sangue em espessura acima de 1 mm">Coágulos localizados e/ou camada de sangue em espessura acima de 1 mm</option>
                    <option value="Presença de coágulos intraparenquimatosos ou intraventriculares, com ou sem sangramento subaracnóideo difuso">Presença de coágulos intraparenquimatosos ou intraventriculares, com ou sem sangramento subaracnóideo difuso</option>
                </select>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraFisher;
