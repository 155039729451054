import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraCalcioCorrigido() {
    const [ca, setCa] = useState('');
    const [albumina, setAlbumina] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularCalcioCorrigido = () => {
        const caNum = parseFloat(ca);
        const albuminaNum = parseFloat(albumina);

        if (isNaN(caNum) || isNaN(albuminaNum)) {
            setResultado('Preencha os campos em branco');
            return;
        }

        const calcioCorrigido = (caNum * 3.2) / albuminaNum;
        setResultado(`<strong>${calcioCorrigido.toFixed(1)}</strong> mg/dl`);
    };

    useEffect(() => {
        if (ca && albumina) {
            calcularCalcioCorrigido();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [ca, albumina]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Cálcio Corrigido</h1>
            <div>
                <label>Ca (mg/dl):</label>
                <input type="number" value={ca} onChange={e => setCa(e.target.value)} />
            </div>
            <div>
                <label>Albumina (g/dl):</label>
                <input type="number" value={albumina} onChange={e => setAlbumina(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraCalcioCorrigido;
