// CollapsibleCard.js
import React from 'react'
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ToggleIcon from './../assets/tree/right-chevron2.png'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import OslerCard from '../components/OslerCard'

const Card = styled.div`
    ${ColumnCSS}
    width: 95%;
    border-radius: 1em;
    transition: all 0.3s ease;
    position: relative;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        
        &:hover {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
        }
    ` : `
        background-color: #fff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
        
        &:hover {
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
        }
    `}

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 100%;
        border-radius: 0.9em;
    }

    @media (max-width: 768px) {
        width: 100%;
        border-radius: 0.75em;
    }
`

const Header = styled.div`
    ${RowCSS}
    justify-content: space-between;
    width: 100%;
    padding: 1em 1em 1em 1em;
    cursor: pointer;

    border-radius: 1em;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        
        &:hover {
            background-color: rgba(255, 255, 255, 0.05);
        }
        
        &:active {
            background-color: rgba(255, 255, 255, 0.08);
        }
    ` : `
        background-color: white;
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.01);
        }
        
        &:active {
            background-color: rgba(0, 0, 0, 0.02);
        }
    `}

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0.9em;
        border-radius: 0.9em;
        gap: 0.2em;
    }

    @media (max-width: 768px) {
        padding: 0.7em;
        border-radius: 0.75em;

        &:hover, &:active {
            background-color: ${props => props.theme.darkMode ? 'rgb(51, 51, 51)' : 'white'};
        }
    }
`

const Left = styled.div`
    ${RowCSS}
`

const ExpandIcon = styled.div`
    ${ColumnCSS}
    height: 100%;
    width: 2em;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    transform: rotate(${props => props.isExpanded ? '90deg' : '0deg'});

    img {
        opacity: ${props => props.theme.darkMode ? 0.8 : 0.6};
        width: 1em;
        filter: ${props => props.theme.darkMode ? 'invert(0.8) brightness(1.2)' : 'none'};
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 1.8em;
        
        img {
            width: 0.9em;
        }
    }

    @media (max-width: 768px) {
        width: 1.5em;
        
        img {
            width: 0.8em;
        }
    }
`

const Title = styled.p`
    font-size: 1.1em;
    color: #1a1a1a;
    font-weight: bold;
    margin: 0;
    letter-spacing: -0.02em;

    ${props => props.theme.darkMode ? `
        color: rgb(220, 220, 220);
    ` : `
        color: #1a1a1a;
    `}

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.75em;
    }

    @media (max-width: 768px) {
        font-size: 0.85em;
    }
`

const Content = styled(motion.div)`
    width: 100%;
    padding: 0.5em 2em 1em 2em;
    overflow: hidden;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0.2em 0.8em 0.7em 0.8em;
    }

    @media (max-width: 768px) {
        padding: 0.25em 1em 0.75em 1em;
    }
`

export default function CollapsibleCard({ 
    title, 
    isExpanded, 
    onToggle, 
    rightComponent, 
    children,
    forceWhiteBg: forcedBg,
}) {

    const shouldForceWhiteBg = forcedBg ?? (
        title === 'Modo de Estudo' || 
        title === 'Ajustes'
    );

    const handleClick = (e) => {
        onToggle(e)
    }

    return (
        <OslerCard padding={'0em'} forceWhiteBg={shouldForceWhiteBg}>
                <Header onClick={handleClick}>
                <Left>
                    <ExpandIcon isExpanded={isExpanded}>
                        <img src={ToggleIcon} alt="toggle" />
                    </ExpandIcon>
                    <Title>{title}</Title>
                </Left>
                {rightComponent}
            </Header>
            
            <AnimatePresence>
                {isExpanded && (
                    <Content
                        initial={{ scaleX: 0.9, opacity: 0 }}
                        animate={{ scaleX: 1, opacity: 1 }}
                        exit={{ scaleX: 0.9, opacity: 0 }}
                        transition={{ 
                            duration: 0.2,
                            ease: [0.4, 0, 0.2, 1]
                        }}

                    >
                        {children}
                    </Content>
                )}
            </AnimatePresence>
        </OslerCard>
    )
}