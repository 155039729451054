import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux';
import BlackArrow from './../assets/left-arrow-black.png'
import GreyArrow from './../assets/left-arrow-grey.png'


import BlackArrowDarkmode from './../assets/left-arrow-darkMode.png'
import GrayArrowDarkmode from './../assets/left-arrow-darkMode-hover.png'
import { darkBackgroundSecondaryColor } from '../tests/FlashcardsStyles';



const ScreenCardStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;


    margin-top: 3em;
    margin-bottom: 3em;
    border-radius: 0.5em;

    padding-bottom: 3em;

    ${props => props.$big ? `
        width: 95%;
    ` : `width: 70%;`}

    max-width: 1500px;

    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundSecondaryColor};
        box-shadow: none;
        color: white;
        border: none;
    ` : `
        background-color: white;
        box-shadow: 0px 1.5px 4px rgba(0, 0, 0, 0.4);
        color: black;
    `}

    @media (max-width: 1200px) {
        margin: 2em 0 3em 0;
        width: 90%;
    }

    @media (max-width: 500px) {
        margin: 2em 0 3em 0;
        width: 98%;
    }
`

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1em;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: rgba(255, 71, 87, 0.8);
    `:`
         background-color: #a10d26;
    `}
`

const CardTitle = styled.p`
    text-transform: uppercase;
    font-size: 1.2em;
    color: white;
    text-align: center;
    font-weight: bold;
    margin: 0 0 0 0.8em;
    font-family: Lora;
`

const CardIcon = styled.img`
    height: 1.2em;
    margin: 0;
`

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 1em 1em 1em;

    @media (max-width: 900px) {
        padding: 0;
    }

    @media (max-width: 500px) {
        padding: 0;
    }
`

const GoBackContainer = styled.div`
    margin-right: 1em; /* adjusted margin to right for better visual balance */

    @media (max-width: 900px) {
        margin-left: 0.5em;
    }
`

const GoBackIcon = styled.img`
    width: 1.5em;
    cursor: pointer;
    @media (max-width: 500px) {
        width: 2em;
    }
`

export default function ScreenCard({ title, iconSrc, children, showGoBack = true, goBackAction, big = false}) {
    const darkMode = useSelector(state => state.theme.darkModeOn)



    return (
        <ScreenCardStyled $big = {big}>
            <CardHeader>
                <CardIcon src={iconSrc} />
                <CardTitle>{title}</CardTitle>
            </CardHeader>
            <CardContent>
                { showGoBack && goBackAction && 
                    <GoBackContainer>
                        <GoBackIcon 
                                onClick={goBackAction}
                                src = {darkMode ? BlackArrowDarkmode : GreyArrow}
                                onMouseOver = {e => e.currentTarget.src = darkMode ? GrayArrowDarkmode : BlackArrow}
                                onMouseOut = {(e) => e.currentTarget.src = darkMode ? BlackArrowDarkmode : GreyArrow}
                        />
                    </GoBackContainer>                
                }


                {children}
            </CardContent>
        </ScreenCardStyled>
    )
}
