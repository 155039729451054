import React, { useEffect, useState, useRef } from 'react'
import DeleteFilterDialog from './DeleteFilterDialog'
import { useDispatch, useSelector } from 'react-redux'
import { db } from '../firebase/firebase-setup'
import OslerData, { KEYS } from '../controllers/OslerData'
import styled from 'styled-components'
import { FaCheck, FaTrash, FaPlay, FaUniversity, FaCalendar, FaBroom } from 'react-icons/fa'
import OslerButton from '../components/OslerButton'
import GeneralDialog from '../tests/GeneralDialog'
import { setIsLoading } from '../redux/loadingSlice'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import CustomFiltersController from '../controllers/CustomFiltersController'

import TrashIcon from '../assets/trash.png'

const Container = styled.div`
    ${RowCSS}
    width: 100%;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0.5em;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1em;
    }

    @media (max-width: 768px) {
        gap: 0.75em;
        flex-direction: column;
        padding: 0.5em;
    }
`

const FilterCard = styled.div`
    background-color: ${props => props.theme.darkMode ? '#34495e' : '#fff'};
    border-radius: 0.8em;
    padding: 1.5em;
    transition: all 0.3s ease;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // Senão aperta até os botões
    min-width: 275px;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1.2em;
        max-width: 100%;
        min-width: 120px;
        border-radius: 0.7em;
        min-height: 200px;
        
        &:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 15px rgba(0, 0, 0, 0.12);
        }
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 1em;
        box-shadow:  none;
        min-width: 0;
        
        &:hover {
            transform: none;
            box-shadow: none;
        }
    }
`

const FilterTitle = styled.h3`
    font-size: 1.4em;
    color: ${props => props.theme.darkMode ? '#ecf0f1' : '#2c3e50'};
    margin-bottom: 0.5em;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 1em;
        margin-bottom: 0.4em;
    }

    @media (max-width: 768px) {
        font-size: 0.8em;
        margin-bottom: 0.3em;
    }
`

const FilterInfo = styled.div`
    font-size: 1em;
    color: ${props => props.theme.darkMode ? '#bdc3c7' : '#7f8c8d'};
    margin: 0.3em 0;
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5em;
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.6em;
        margin: 0.25em 0;
        
        svg {
            margin-right: 0.4em;
            width: 1.1em;
        }
    }

    @media (max-width: 768px) {
        font-size: 0.75em;
        margin: 0.2em 0;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 1em;

    @media (max-width: 1200px) and (min-width: 769px) {
        margin-top: 0.9em;
        // flex-direction: column;
        width: 100%;
        gap: 0.4em;
        font-size: 0.55em;
    }

    @media (max-width: 768px) {
        margin-top: 0.75em;
        width: 100%;
    }
`

const Message = styled.div`
    ${ColumnCSS}
    width: 100%;

`

const Empty = styled.p`
    width: 100%;
    text-align: center;
    color: #d1301f;
    font-weight: bold;

    font-size: 1.6em;
    margin: 2em 0 0 0;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 1.4em;
        margin: 1.5em 0 0 0;
    }

    @media (max-width: 768px) {
        font-size: 1.2em;
        margin: 1em 0 0 0;
    }
`

const Detail = styled.p`
    width: 100%;
    margin: 1em 0 0 0;
    padding: 0 30% 0 30%;

    text-align: left;
    color: black;
    font-size: 0.9em;

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 0 25%;
        font-size: 0.85em;
        margin: 0.9em 0 0 0;
    }


    @media (max-width: 768px) {
        padding: 0 1em;
        font-size: 0.85em;
        margin: 0.75em 0 0 0;
    }
`


export default function FiltersCard({ appliedFilters, updateAppliedFilters }) {
    const [filters, setFilters] = useState([])
    const dispatch = useDispatch()

    const [showDeleteFilterDialog, setShowDeleteFilterDialog] = useState(false)
    const filterToDelete = useRef()


    useEffect(() => {
        // Precisamos dessa verificação porque, se mudou, pode ser porque algum novo filtro
        // foi criado!
        console.log('FiltersCard: carregando...')
        console.log('FiltersCard: os filtros aplicados são:')
        console.log(appliedFilters)

        const filters = CustomFiltersController.getFilters()
        setFilters(filters)  
    }, [appliedFilters])


    function applyFilter(filter) {
        updateAppliedFilters(filter)
    }


    function removeFilter(filter) {
        updateAppliedFilters(filter)
    }


    function confirmDeleteFilter(filter) {
        filterToDelete.current = filter
        setShowDeleteFilterDialog(true)
    }


    function checkIfFilterIsApplied(filter_name) {
        return appliedFilters.some(applied => applied.filter_name === filter_name)
    }

    
    async function deleteFilter() {
        dispatch(setIsLoading(true))

        const newFilters = await CustomFiltersController.deleteFilter(filterToDelete.current)

        if (checkIfFilterIsApplied(filterToDelete.current.filter_name)) {
            removeFilter(filterToDelete.current)
        }

        setFilters(newFilters)
        filterToDelete.current = false
        setShowDeleteFilterDialog(false)
        
        dispatch(setIsLoading(false))
    }

    return (
        <Container>
            {/* É ridículo, mas sem isso trava porque tenta renderizar algo que não foi criado */}
            { showDeleteFilterDialog &&
                <GeneralDialog
                    icon={TrashIcon}
                    title='Deletar o filtro?'
                    titleColor='#c0392b'
                    open={showDeleteFilterDialog}
                    onClose={() => setShowDeleteFilterDialog(false)}
                    allowClose={true}
                    actions={[
                        { label: 'Voltar', onClick: () => setShowDeleteFilterDialog(false), style: 'neutral' },
                        { label: 'Excluir', onClick: deleteFilter, style: 'destructive'}
                    ]} >
                    <p>Iremos <u>excluir</u> o filtro: {filterToDelete.current.filter_name}.</p>
                    <p>Essa ação é irreversível.</p>
                </GeneralDialog>
        
            }

            {filters.length > 0 && filters.map(filter => (
                <FilterCard key={filter.filter_name}>
                    <div>
                        <FilterTitle>{filter.filter_name}</FilterTitle>
                        <FilterInfo>
                            <FaUniversity />
                            {filter.institutions && filter.institutions.length > 0
                                ? filter.institutions
                                    .map(inst => inst.match(/\(([^)]+)\)/)[1])
                                    .sort((a, b) => a.localeCompare(b, 'pt-BR'))
                                    .join(' | ')
                                : 'Nenhuma instituição selecionada'}
                        </FilterInfo>
                        <FilterInfo>
                            <FaCalendar />
                            {filter.years && filter.years.length > 0
                                ? filter.years
                                    .sort((a, b) => a.localeCompare(b, 'pt-BR'))
                                    .join(' | ')
                                : 'Nenhum ano selecionado'}
                        </FilterInfo>
                    </div>
                    <ButtonContainer>
                        <OslerButton 
                            size='small'
                            onClick={() => confirmDeleteFilter(filter)}
                            text={'Deletar'}
                            color='red'
                            icon={<FaTrash />} />

                        { checkIfFilterIsApplied(filter.filter_name) ? (
                            <OslerButton 
                                size='small'
                                onClick={() => removeFilter(filter)}
                                text={'Remover'}
                                color='grey'
                                icon={<FaBroom />} />
                        ) : (
                            <OslerButton 
                                size='small'
                                color='blue'
                                onClick={() => applyFilter(filter)}
                                text={'Aplicar'}
                                icon={<FaCheck />} />
                        )}
                    </ButtonContainer>
                </FilterCard>
            ))}

            { filters.length == 0 &&
                <Message>
                    <Empty>Você não possui nenhum filtro.</Empty>
                    <Detail><b>Filtros</b> são conjuntos de instituições e/ou anos que você pode salvar, para agilizar a criação de lista de questões das provas do seu interesse.</Detail>
                    <Detail>
                    Para criar, basta selecionar algumas instituições e/ou anos, e surgirá um botão "Salvar filtro" próximo a "Começar estudo".</Detail>
                </Message>
            }

        </Container>
    )
}