import { db } from '../firebase/firebase-setup'
import OslerData, { KEYS } from './OslerData'
import firebase from 'firebase/compat/app'


// TODO, passar a atualização das notas para cá?


class NotebookController {
    construct(user) {
        this.userID = user.id
        this.metadata = OslerData.data[KEYS.NOTES][KEYS.NOTES_METADATA]
        this.collRef = db.collection(`users/${user.id}/notes`)
        this.recommendedTagpath = undefined

        this.loaded = true
    }


    saveTagpathRecommendation(tagpath) {
        this.recommendedTagpath = tagpath  //.replaceAll('/', '|')
    }


    noteIDForTagpath(tagpath) {
        console.log(tagpath)
        console.log(this.metadata)

        if (this.metadata[tagpath]) {
            console.log(this.metadata[tagpath])
            console.log(Object.keys(this.metadata[tagpath]))

            return Object.keys(this.metadata[tagpath])[0]
        }
        else {
            return undefined
        }
    }


    async loadNote(noteID) {
        console.log(`Carregando a nota: users/${this.userID}/notes/${noteID}`)

        const docRef = await this.collRef.doc(noteID).get()        
        if (docRef.exists) {
            return {
                ...docRef.data(),
                id: noteID
            }
        }
        else {
            return undefined
        }
    }


    async createNote(testTagpath = this.recommendedTagpath ?? 'without_tagpath') {
        // Criamos a nota
        const now = new Date().toLocaleString('pt-BR')
        const defaultNoteData = {
            theme: testTagpath,
            title: '(sem título)',
            content: '',
            created: now,
            lastUpdated: now,
            previous_versions: []
        }
        const docRef = await this.collRef.add(defaultNoteData)


        // Atualizamos o metadata local e do servidor
        this.updateMetadata(testTagpath, docRef.id, '(sem título)', now)
        this.recommendedTagpath = undefined

        // Retornamos os dados, para uso
        return {
            ...defaultNoteData,
            id: docRef.id
        }        
    }


    async save(noteContent, noteTitle, theme, noteOriginalData, contentChanged, titleChanged) {
        console.log(`Notebook: estamos salvando a nota ${noteTitle} de tema ${theme}...`)
        const now = new Date().toLocaleString('pt-BR')

        const data = {
            lastUpdated: now,
        }

        if (contentChanged) {
            data['content'] = noteContent
        }

        if (titleChanged) {
            const empty = noteTitle == '' || noteTitle == '<p></p>' || noteTitle == '<p><br></p>'
            noteTitle = empty ? '(sem título)' : noteTitle
            data['title'] = noteTitle
        }
        
        data['previous_versions'] = firebase.firestore.FieldValue.arrayUnion({
            content : noteOriginalData['content'],
            title : noteOriginalData['title'],
        })
    

        this.collRef.doc(noteOriginalData['id']).set(data, { merge: true })
        this.updateMetadata(theme, noteOriginalData['id'], noteTitle, now)
    }


    async delete(noteID, tagpath) {
        console.log(`Notebook: estamos deletando a nota ${noteID}...`)
        this.collRef.doc(noteID).delete()

        if (this.metadata[tagpath]) {
            delete this.metadata[tagpath][noteID]
        }

        this.collRef.doc(`notes_metadata`).set({
            [tagpath] : {
                [noteID] : firebase.firestore.FieldValue.delete()
            }
        }, { merge: true })


        console.log(this.metadata)
    }


    async updateMetadata(tagpath, ID, title, lastUpdated) {
        console.log(`Notebook: iremos atualizar os metadados de ${title} / ${ID}...`)

        const meta = {
            title: title,
            lastUpdated: lastUpdated
        }


        if (this.metadata[tagpath]) {
            this.metadata[tagpath][ID] = meta
        }
        else {
            this.metadata[tagpath] = {}
            this.metadata[tagpath][ID] = meta
        }

        this.collRef.doc(`notes_metadata`).set({
            [tagpath] : {
                [ID] : meta   
            }
        }, { merge: true })
    }


    async changeTheme(noteID, oldTheme, newTheme) {
        console.log(this.metadata)
        console.log(noteID, oldTheme, newTheme)

        if (!this.metadata[newTheme]) {
            this.metadata[newTheme] = {}
        }

        this.metadata[newTheme][noteID] = this.metadata[oldTheme][noteID]
        delete this.metadata[oldTheme][noteID]

        this.collRef.doc(`notes_metadata`).set({
            [newTheme] : {
                [noteID] : this.metadata[newTheme][noteID]
            },
            [oldTheme] : {
                [noteID] : firebase.firestore.FieldValue.delete()
            }
        }, { merge: true })


        this.collRef.doc(noteID).set({
            ['theme']: newTheme
        }, { merge: true })
    }
}

export default new NotebookController()