

import emojis from './emoji.json'

export const transformEmojis = (emojis) => {
    const transformed = {}
    for (const [emoji, data] of Object.entries(emojis)) {
        transformed[data.slug] = emoji
    }
    return transformed
}

export const emojiDictionary = transformEmojis(emojis)