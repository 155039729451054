import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraCreatininaCockcroftGault() {
    const [sexo, setSexo] = useState('');
    const [idade, setIdade] = useState('');
    const [peso, setPeso] = useState('');
    const [creatinina, setCreatinina] = useState('');
    const [resultado, setResultado] = useState('');

    const calcularClearance = () => {
        const idadeNum = parseFloat(idade);
        const pesoNum = parseFloat(peso);
        const creatNum = parseFloat(creatinina);

        let sexFactor;
        if (sexo === 'masculino') {
            sexFactor = 1;
        } else if (sexo === 'feminino') {
            sexFactor = 0.85;
        } else {
            setResultado('Selecione o sexo.');
            return;
        }

        const estimatedClearance = sexFactor * ((140 - idadeNum) / creatNum) * (pesoNum / 72);
        setResultado(`Estimativa de Clearance de Creatinina: ${estimatedClearance.toFixed(2)} mL/min`);
    };

    useEffect(() => {
        if (sexo && idade && peso && creatinina) {
            calcularClearance();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [sexo, idade, peso, creatinina]);

    return (
        <div>
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Clearance de Creatinina (Cockcroft-Gault)</h1>
            <div>
                <label>Sexo:</label>
                <select value={sexo} onChange={e => setSexo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="masculino">Masculino</option>
                    <option value="feminino">Feminino</option>
                </select>
            </div>
            <div>
                <label>Idade (em anos):</label>
                <input type="number" value={idade} onChange={e => setIdade(e.target.value)} />
            </div>
            <div>
                <label>Peso (em kg):</label>
                <input type="number" step="0.1" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div>
                <label>Creatinina sérica (mg/dL):</label>
                <input type="number" step="0.01" value={creatinina} onChange={e => setCreatinina(e.target.value)} />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraCreatininaCockcroftGault;
