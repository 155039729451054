import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components';
import { auth  } from './../firebase/firebase-setup'
import { reloadAuth } from '../firebase/firebaseUtils';
import { toastMsg } from '../utils/Utils';
import OslerButton from '../components/OslerButton';
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles';


const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        padding: 0.5em;
    }
`


const ProfileData = styled.p`
    margin: 0 0 0.3em 1em;

    @media (max-width: 768px) {
        margin: 0 0 0.5em 0.5em;
        font-size: 0.9em;
    }
`

const VerifyEmail = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    width: 80%;
    align-self: center;
    background-color: #f2f2f2;
    padding: 1em;
    border-radius: 0.5em;

    ${backgroundWhiteBlack}

    @media (max-width: 500px) {
        width: 100%;
    }

    @media (max-width: 768px) {
        width: 95%;
        margin-top: 0.8em;
        padding: 0.8em;
        font-size: 0.9em;

        ul {
            padding-left: 1.2em;
            margin: 0.5em 0;
        }

        li {
            margin-bottom: 0.5em;
        }

        p {
            margin: 0.5em 0;
        }
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`

const Attention = styled.span`
    font-weight: bold;
    font-size: 1.05em;
    color: #A10D25;

    @media (max-width: 768px) {
        font-size: 1em;
    }
`

const EmailBttns = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-self: center;
    width: 100%;

    padding: 0.5em 2em 2em 2em;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 0.8em 0.5em;
        gap: 0.8em;
        
        > button {
            width: 100%;
            margin: 0 !important;
            padding: 0.6em;
            font-size: 0.9em;
        }
    }
`

const ChangedPassword = styled.p`
    font-size: 1em;
    font-weight: bold;
    color: #225399;
    text-align: center;
    align-self: center;
    margin-top: 1em;

    @media (max-width: 768px) {
        font-size: 0.9em;
        margin-top: 0.8em;
    }

`

export default function Profile(props) {
    const user = useSelector(state => state.user.data)    
    const [sentVerification, setSentVerification] = useState(false)
    const [didntReceived, setDidntReceived] = useState(false)
    const [changedPassword, setChangedPassword] = useState(false)



    async function resendVerificationEmail() {
        try {
            setSentVerification(true)
            await auth.currentUser.sendEmailVerification();
            toastMsg("Enviamos um novo email! 📧")
        }
        catch (error) {
            alert("Erro! enviando o email. Tire uma foto e mande para o suporte: \n\n" + error)
        }
    }


    async function checkEmailVerification() {
		try {
            toastMsg("Verificando...")
            
            const newUser = await reloadAuth()
            props.updateUserState()
            
            if (!newUser.isEmailVerified) {
                toastMsg("🤔 Email ainda não foi verificado!")
            }
            else {
                toastMsg("Sucesso!")
            }
		}
		catch (error) {
            console.log("checkEmailVerification(): error " + error)
		}
	}



    async function changePassword() {
        try {
            await auth.sendPasswordResetEmail(user.email)
            setChangedPassword(true)
        } catch (error) {
            console.log(error)
            toastMsg("👁️ Oops. Tira print e envia!" + error)
        }
    }

    console.log(user)
    
    return (
        <ProfileContainer>

            <ProfileData><strong>Nome:</strong> {user.firstName + ' ' + user.lastName}</ProfileData>
            <ProfileData><strong>E-mail:</strong> {user.email}</ProfileData>
            
            {user.phone && <ProfileData><strong>Celular:</strong> {user.phone}</ProfileData>}

            {/* { !user.isEmailVerified && */}
            { !user.isEmailVerified && !didntReceived &&  
                <VerifyEmail>
                
                    <p><Attention>Autentique sua conta.</Attention> Você recebeu um email com um <i>link</i>, clique nele para verificarmos que o endereço informado pertence a você e está correto.</p>
                
                    <EmailBttns>
                        <OslerButton
                            color = 'blue'
                            text={'Já verifiquei! 👌'}
                            onClick={checkEmailVerification}
                            style={{}} />


                        <OslerButton
                            color = 'red'
                            text={'Não recebi... 😕'}
                            onClick={() => setDidntReceived(true)}
                            style={{}} />
                    </EmailBttns>
                </VerifyEmail>
            }
            { !user.isEmailVerified && didntReceived &&
                <VerifyEmail>
                    <p><strong>Há três hipóteses:</strong></p>

                    <ul>
                    <li>Espere mais um pouco, o email pode demorar ~1min</li>
                    <li>Foi para <u>spam ou lixeira</u>, dê uma olhada lá</li>
                    <li>Você digitou seu e-mail com algum errinho (confira acima)</li>
                    </ul>

                    <p>Caso seu email tenha sido digitado errado, você pode sair e criar uma nova conta, ou pedir para corrigirmos via suporte@osler-ensino.com.</p>

                    <p>Se quer que enviemos um novo e-mail, clique abaixo.</p>

                    <EmailBttns>
                        <OslerButton
                            color = 'blue'
                            text={'Já verifiquei! 👌'}
                            onClick={checkEmailVerification}
                            style={{}} />

                        <OslerButton
                            color = 'dark-gray'
                            text={'Enviar novo email 📧'}
                            onClick={resendVerificationEmail}
                            style={{}} />
                    </EmailBttns>


                </VerifyEmail>
            }
            { user.isEmailVerified &&
                <VerifyEmail>
                <p><strong>Precisa mudar de e-mail?</strong> Se você se cadastrou com um email institucional (e.g., joão@usp.com.br) e precisa mudar para um pessoal, envie um alô para suporte@osler-ensino.com.</p>

            

                { !changedPassword &&
                <>
                    <p><strong>Precisa mudar de senha?</strong> Clique no botão abaixo, e te enviaremos um e-mail com link para isso.</p>

                    <OslerButton
                        color = 'dark-gray'
                        text={'Mudar senha 🤔'}
                        onClick={changePassword}
                        style={{marginTop: '1em', marginBottom: '1em'}} />
                </>
                }

                { changedPassword &&
                    <ChangedPassword>Enviamos um email para você mudar sua senha! 📧</ChangedPassword>
                }
                </VerifyEmail>
                
            }

        </ProfileContainer>
    )
}