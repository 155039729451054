import React, {useState, useEffect, useRef, useMemo} from 'react'
import ScreenCard from '../components/ScreenCard'

import { useNavigate } from 'react-router-dom'
import AppContainer from '../app-container/AppContainer'
import ScreenContainer from '../components/ScreenContainer'
import OslerButton from '../components/OslerButton'
import SandboxIcon from './../assets/sandbox.png'
import MistakesIcon from './../assets/trilha.png'
import ResidenciaIcon from './../assets/residencia.png'
import BalloonsIcon from './../assets/tree/balloons.png'
import DumbbellIcon from './../assets/tree/dumbbell.png'
import OslerDropdown from '../components/OslerDropdown'

import FilterIcon from './../assets/components/filter.png'
import SortIcon from './../assets/components/sorting.png'

import styled from 'styled-components';
import OslerData, { KEYS } from '../controllers/OslerData'
import { sortIDsByTagpath, toastMsg } from '../utils/Utils'
import CardList from '../components/CardList'
import TileGrid, { prepareDataForTileData } from '../user/TileGrid'
import { Column, ColumnCSS, Row, RowCSS } from '../components/BasicComponents'
import SessionBuilder from '../controllers/SessionBuilder'
import { setIsLoading } from '../redux/loadingSlice'
import { useDispatch, useSelector } from 'react-redux'
import Session from '../controllers/Session'
import QuestionIcon2 from './../assets/question.png';

import BackArrow from './../assets/left-arrow-black.png'
import GeneralDialog from '../tests/GeneralDialog'
import MistakesJournalController from '../controllers/MistakesJournalController'
import ScreenContainerHeader from '../components/ScreenContainerHeader'
import OslerCard from '../components/OslerCard'
import { PredefinedSessionConfig, SORT_MODES } from '../controllers/SessionConfig'

const TestsColumn = styled.div`
    ${ColumnCSS}

    max-width: 900px;

    margin-top: 2em;
`

const TopBttns = styled.div`
    ${RowCSS}
    width: 90%;
    justify-content: space-between;

    @media (max-width: 500px) {
        width: 100%;
        font-size: 12px;
        padding: 10px;

    }
`


const Filters = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-end;
    width: 200px;

    margin-top: 0px;
`


const Header = styled.div`
    ${RowCSS}
    
    align-items: flex-start;
    justify-content: flex-end;

    width: 100%;

    @media (max-width: 500px) {
        width: 100%;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        margin-top: 1em;
        gap: 1em;
    }
`

const Bttns = styled.div`
    ${ColumnCSS}
    margin-right: 1.5em;

    @media (max-width: 500px) {
        margin-right: 0;
    }
`

const HelpBttn = styled.img`
    margin-right: 1.5em;

    width: 1.8em;
    padding: 0.5em;
    box-sizing: border-box;

    border-radius: 0.5em;
    cursor: pointer;

    background-color: rgba(230, 230, 230, 0.8);
    &:hover {
        background-color: rgba(230, 230, 230);
    }
`

const Notice = styled.p`
    font-size: 1.2em;
    margin-top: 2em;
`

export default function Mistakes() {
    const navigate = useNavigate()

    const [filter, setFilter] = useState(false)
    const [sort, setSort] = useState(false)

    const [data, setData] = useState(false)
    const [tileData, setTileData] = useState(false)

    const [showHelp, setShowHelp] = useState(false)

    const [chosenTagpath, setChosenTagpath] = useState(false)
    const [chosenTestsIDs, setChosenTestsIDs] = useState(false)
    const dispatch = useDispatch()


    const [hasPending, setHasPending] = useState(false)
    const [hasFuture, setHasFuture] = useState(false)
    const [hasSolved, setHasSolved] = useState(false)

    const isLoading = useSelector(state => state.loading.isLoading)


    // Nota para o Vittorio do futuro
    // Ter tantos estados causa facilmente umas 7 renderizações em um carreagmento normal
    // O queq pode não ser uma boa. Mas não parece ter impacto. 


    // isso é genial. como não vai mudar nunca, deixamos como fixo e evita que fique
    // triggering novas renderizaçòes do dropdown (é shallow comparison, se muda a referência
    // ele muda tudo)
    const filterOptions = useMemo(() => {

        if (!data || (!hasSolved && !hasPending)) return []

        let defaultFilter = 'pending'
        if (!hasPending && hasSolved) {
            defaultFilter = 'solved'
        }
    
        return [
            { 
                text: 'Todas', 
                img: ResidenciaIcon, 
                id: 'all', 
                active: hasSolved && hasPending
            },
            { 
                text: 'Pendentes', 
                img: DumbbellIcon, 
                id: 'pending', 
                active: hasPending,
                default: defaultFilter === 'pending'
            },
            { 
                text: 'Resolvidas', 
                img: BalloonsIcon, 
                id: 'solved', 
                active: hasSolved,
                default: defaultFilter === 'solved'
            }
        ];
    }, [data, hasPending, hasSolved])


    const sortingOptions = useMemo(() => [
        { text: 'Alfabeticamente', img: undefined, id: 'alphabetically' },
        { text: 'Número de erros', img: undefined, id: 'number-errors', default: true },
    ], [])



    useEffect(() => {
        if (!OslerData.data[KEYS.RESIDENCIA][KEYS.MISTAKES_JOURNAL]) {
            console.log('voltando...')
            navigate('/app')
            return
        }

        const data = MistakesJournalController.getClassifiedData()

        const pendingSorted = sortIDsByTagpath('Residencia', data['pendingReviews'])
        const futureSorted = sortIDsByTagpath('Residencia', data['futureReviews'])
        const solvedSorted = sortIDsByTagpath('Residencia', data['solved'])
        const allSorted = sortIDsByTagpath('Residencia', data['all'])


        // Por ora, guardamos future, mas não estamos usando.
        const tmpData = {
            pending: pendingSorted,
            future: futureSorted,
            solved: solvedSorted,
            all: allSorted
        }

        const hasPendingTmp = Object.keys(tmpData['pending']).length > 0
        const hasFutureTmp = Object.keys(tmpData['future']).length > 0
        const hasSolvedTmp = Object.keys(tmpData['solved']).length > 0

        let defaultFilter = 'pending'
        if (!hasPendingTmp && hasSolvedTmp) {
            defaultFilter = 'solved'
        }

        setHasSolved(hasSolvedTmp)
        setHasPending(hasPendingTmp)
        setHasFuture(hasFutureTmp)

        setData(tmpData)

        update(tmpData, defaultFilter, 'number-errors')        
        // loadData(tmpData, defaultFilter)
    }, [])


    
    // function changeFilter(filter) {
    //     loadData(data, filter)
    //     setFilter(filter)
    // }






    function update(newData, newFilter, newSort) {
        console.log('Update params:')
        console.log(newData)
        console.log(newFilter)
        console.log(newSort)


        let newTileData = tileData
        
        if (newFilter && (newFilter !== filter)) {
            console.log('Mudando filtro para: ' + newFilter)
            newTileData = getTileData(newData ? newData : data, newFilter)
            setFilter(newFilter)
        }

        if (newSort && (newSort !== sort)) {
            console.log('Mudando sort!')
            newTileData = sortTileData(newSort, newTileData)
            setSort(newSort)
        }

        setTileData(newTileData)
    }


    // useEffect(() => {
    //     if (data) {
    //         const sorted = sortTileData(sort, tileData)
    //         setTileData(sorted)
    //     }
    // }, [sort])


    // useEffect(() => {
    //     if (data) {
    //         const tileData = getTileData(data, filter)
    //         const sorted = sortTileData(sort, tileData)
    //         setTileData(sorted)
    //     }
    // }, [filter])


    // useEffect(() => {
        
    //     let tileData   = getTileData(data, filter)
    //     let sortedData = sortTileData(sort, tileData)
        
    //     setSort
    //     setTileData(tileData)
    //     changeSort(sort, tileData)

    // }, [data, filter, sort])


    function getTileData(data, filter) {
        // O parâmetro é opcional, porque 
        function phrase(n, color, img) {
            return (
                <Row>
                    <img style = {{height: '1.2em'}} src={img} alt="icon" />
                    <p style = {{margin: 0, color: color, marginLeft: '0.3em', fontWeight: 'bold'}}>{n}</p>
                </Row>
            )
        }

        function subtitleCreator(idsByTagPath, tagpath, filter) {
            if (filter == 'pending') {
                return phrase(idsByTagPath[tagpath].length, '#8e0011', DumbbellIcon)
            }
            else if (filter == 'solved') {
                return phrase(idsByTagPath[tagpath].length, 'blue', BalloonsIcon)
            }
            else {
                const nPending = data['pending'][tagpath]?.length ?? 0
                const nSolved  = data['solved'][tagpath]?.length ?? 0

                return (
                    <Row>
                    {nPending != 0 && phrase(nPending, '#8e0011', DumbbellIcon)}
                    <div style = {{marginLeft: '0em', marginRight: '1em'}}>
                        {/* , */}
                    </div>
                    {nSolved != 0 && phrase(nSolved, 'blue', BalloonsIcon)}
                    </Row>
                )
            }
        }


        const tileData = prepareDataForTileData(
            data[filter],
            (idsByTagPath, tagpath) => {
                setChosenTagpath(tagpath)
                setChosenTestsIDs(idsByTagPath[tagpath])
            },
            (idsByTagPath, tagpath) => {
                return subtitleCreator(idsByTagPath, tagpath, filter)
        })


        return tileData
    }



    function sortTileData(sortOption, tileData) {
        const sortedX = [...tileData];
        if (sortOption === 'alphabetically') {
            sortedX.sort((a, b) => a.title > b.title ? 1 : -1)
        }
        else if (sortOption === 'number-errors') {
            sortedX.sort((a, b) => {
                if (a.nTests < b.nTests) {
                    return 1
                }
                else if (a.nTests > b.nTests) {
                    return -1
                }
                else {
                    return a.title > b.title ? 1 : -1
                }
            })
        }

        return sortedX
    }


    function goBack() {
        if (chosenTestsIDs) {
            setChosenTestsIDs(false)
            setChosenTagpath(false)
        }
        else {
            navigate('/app')
        }
    }


    async function startSession(testMode, tagpaths) {
        console.log(testMode)
        console.log(tagpaths)
        console.log(filter)
        console.log(data[filter])

        let testIDs = tagpaths.reduce((acc, tagpath) => acc.concat(data[filter][tagpath]), [])


        const config = PredefinedSessionConfig.create({
            ordering: SORT_MODES.SORT,
        })

        dispatch(setIsLoading(true))

        SessionBuilder.start(
            'Residencia',
            'predefined',
            testMode,
            testIDs,
            config,
            true
        )


        if (Session.sessionSize > 0) {
            Session.addTemporaryListener(moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }

    if (!data) return null


    function actionBttns(tagpaths = Object.keys(data[filter])) {

        return (
            <Bttns>
                { (hasPending || hasSolved) &&
                    <OslerButton
                        color =  'blue'
                        img = {SandboxIcon}
                        text = 'Modo Playground'
                        size = {'small'}
                        style = {{marginBottom: '1em' }}
                        onClick={() => startSession('playground-mode', tagpaths)} />    
                }


                { filter !== 'solved' && hasPending &&
                    <OslerButton
                        color =  'green'
                        img = {DumbbellIcon}
                        text = 'Fazer as revisões'
                        size = {'small'}
                        onClick={() => startSession('test-mode', tagpaths)} />             
                }
            </Bttns>
        )
    }



    function renderContent() {
        return (
            <>
                { !chosenTestsIDs && data && filter && sort && 
                    <OslerCard>
                        <Header>
                            
                            {actionBttns()}
                            
                            { (hasPending || hasSolved) && 
                                <Filters>
                                    <OslerDropdown
                                        options = {filterOptions}
                                        // signal = {(id) => changeFilter(id)}
                                        signal = {id => update(undefined, id, undefined)}
                                        icon = {FilterIcon}  />
        
                                    <OslerDropdown 
                                        style = {{marginTop: '0.3em'}}
                                        options = {sortingOptions}
                                        signal = {id => update(undefined, undefined, id)}
                                        // signal = {(id) => changeSort(id, tileData)}
                                        icon = {SortIcon}  />
                                </Filters>
                            }
                        </Header>


                        { !(hasPending || hasSolved) && !hasFuture &&
                            <Notice>Você ainda não tem questões no caderno de erros.</Notice>
                        }

                        { !(hasPending || hasSolved) && hasFuture &&
                            <Notice>Você tem revisões, mas elas são <u>futuras</u>, e vão aparecer aqui em até 7 dias.</Notice>
                        }

                        { tileData && 
                            <TileGrid
                                style = {{marginTop: '1em'}}
                                options = {tileData} />                                    
                        }

                    </OslerCard>
                }


                { chosenTestsIDs &&
                    <TestsColumn>
                        
                        { !isLoading && 
                            <TopBttns>
                                <OslerButton
                                    color =  'grey'
                                    img = {BackArrow}
                                    text = 'Voltar'
                                    onClick={goBack} />
    
    
                                {actionBttns([chosenTagpath])}
                            </TopBttns>
                        }
                        
                        <CardList
                            style = {{marginTop: '2em'}}
                            testsIDs = {chosenTestsIDs}
                            testType = {'Residencia'}
                            showLike = {true}
                            showBury = {true}
                            showFeedback = {true}
                        />
                    </TestsColumn>
                }
            </>

        )
    }


    function renderHelpContent() {
        return (
            <OslerCard style = {{alignItems: 'flex-start'}}>
                <p>Existe uma diferença entre importante entre <u>revisar o tema</u> com questões e <u>revisar questões</u>.</p>

                <p>Estima-se que um aluno que vá ser aprovado faça de 5 a 15 mil no seu ano de preparo.</p>

                <p><i>Há muita variabilidade: vários são aprovados fazendo menos, e muitos são reprovados fazendo mais. Mas vamos tomar 10 mil como média, para ilustrar o argumento.</i></p>

                <p>Se você for revisar <u>todas</u> as questões, e 2 a 5 vezes cada uma... 10 mil viram 20 a 50mil, e 1h diária viram 2 a 5 horas... e fica impossível, inútil, delirante, prejudicial, iatrogênico.</p>

                <p>A proposta do caderno de erros é que <b>você faça cada questão até acertar com segurança.</b> Enquanto você errar ou acertar por acaso, ela será lançada como uma revisão pendente para dali ~7 dias.</p>

                <p>A <u>revisão do tema</u> fica ao encargo dos flashcards (com repetição espaçada) e dos simulados (... soon).</p>

                <p>Aqui, você terá acesso fácil às questões com revisão pendente. Incentivamos que você veja uma a uma no modo consulta, entendendo o porque errou, e criando anotações. Depois, você pode clicar em "Fazer as revisões" e, se acertar com segurança, a questão ficará como resolvida.</p>

            </OslerCard>
        )
    }

    const tabs = [
        { 
            id: 'themes', 
            label: 'Correção de Erros',
            content: renderContent()
        },
        {
            id: 'help',
            icon: QuestionIcon2,
            content: renderHelpContent(),
        },
    ]


    return (
        <AppContainer>
            <ScreenContainerHeader
                icon = {MistakesIcon}
                title = {'Correção de Erros'}
                tabs = {tabs} />
        </AppContainer>
    )
}