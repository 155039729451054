import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraGlasgowPupilar() {
    const [aberturaOcular, setAberturaOcular] = useState('');
    const [respostaVerbal, setRespostaVerbal] = useState('');
    const [respostaMotora, setRespostaMotora] = useState('');
    const [respostaPupilar, setRespostaPupilar] = useState('');
    const [resultado, setResultado] = useState('Preencha todos os campos para ver o resultado.');

    useEffect(() => {
        if (aberturaOcular && respostaVerbal && respostaMotora && respostaPupilar) {
            calcularEscalaGlasgow();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [aberturaOcular, respostaVerbal, respostaMotora, respostaPupilar]);

    const calcularEscalaGlasgow = () => {
        const pontuacoes = {
            'Espontânea': 4,
            'Ao som': 3,
            'À pressão': 2,
            'Ausente': 1,
            'Não testável (trauma, edema etc)': 1,
            'Orientado': 5,
            'Confuso': 4,
            'Palavras': 3,
            'Sons': 2,
            'Ausente': 1,
            'Intubado': 1,
            'Às ordens': 6,
            'Localizadora': 5,
            'Flexão normal': 4,
            'Flexão anormal': 3,
            'Extensão': 2,
            'Ausente': 1,
            'Não testável': 1,
            'Inexistente (nenhuma pupila reage)': -2,
            'Parcial (apenas uma pupila reage)': -1,
            'Completa (ambas as pupilas reagem)': 0
        };

        const pontuacaoOcular = pontuacoes[aberturaOcular];
        const pontuacaoVerbal = pontuacoes[respostaVerbal];
        const pontuacaoMotora = pontuacoes[respostaMotora];
        const pontuacaoPupilar = pontuacoes[respostaPupilar];

        const total = pontuacaoOcular + pontuacaoVerbal + pontuacaoMotora + pontuacaoPupilar;

        let classificacao = '';
        if (total >= 13) {
            classificacao = 'Leve';
        } else if (total >= 9 && total <= 12) {
            classificacao = 'Moderado';
        } else {
            classificacao = 'Grave (coma)';
        }

        setResultado(`Escala de Glasgow: ${total} pontos. Classificação: ${classificacao}.`);
    };

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escala de Glasgow com Resposta Pupilar</h1>
            <div className='form-gad7'>
            <div>
                <label>Abertura Ocular:</label>
                <select value={aberturaOcular} onChange={e => setAberturaOcular(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Espontânea">Espontânea</option>
                    <option value="Ao som">Ao som</option>
                    <option value="À pressão">À pressão</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Não testável (trauma, edema etc)">Não testável (trauma, edema etc)</option>
                </select>
            </div>
            <div>
                <label>Resposta Verbal:</label>
                <select value={respostaVerbal} onChange={e => setRespostaVerbal(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Orientado">Orientado</option>
                    <option value="Confuso">Confuso</option>
                    <option value="Palavras">Palavras</option>
                    <option value="Sons">Sons</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Intubado">Intubado</option>
                </select>
            </div>
            <div>
                <label>Resposta Motora:</label>
                <select value={respostaMotora} onChange={e => setRespostaMotora(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Às ordens">Às ordens</option>
                    <option value="Localizadora">Localizadora</option>
                    <option value="Flexão normal">Flexão normal</option>
                    <option value="Flexão anormal">Flexão anormal</option>
                    <option value="Extensão">Extensão</option>
                    <option value="Ausente">Ausente</option>
                    <option value="Não testável">Não testável</option>
                </select>
            </div>
            <div>
                <label>Resposta Pupilar:</label>
                <select value={respostaPupilar} onChange={e => setRespostaPupilar(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Inexistente (nenhuma pupila reage)">Inexistente (nenhuma pupila reage)</option>
                    <option value="Parcial (apenas uma pupila reage)">Parcial (apenas uma pupila reage)</option>
                    <option value="Completa (ambas as pupilas reagem)">Completa (ambas as pupilas reagem)</option>
                </select>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraGlasgowPupilar;
