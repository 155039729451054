import React, { useState } from 'react'
import { getUserProfile } from '../firebase/firebaseUtils'
import { toastMsg } from '../utils/Utils'
import { auth } from './../firebase/firebase-setup'
import { useDispatch } from 'react-redux'
import { setIsLoading } from './../redux/loadingSlice'

import {
    LoginBox,
    Form,
    Input,
    Button,
    Text,
    ForgotPassword,
    WelcomeText,
    SubText,
    ButtonGroup,
    InputGroup
} from './LoginStyles'

export default function SignIn(props) {
    const dispatch = useDispatch()
    const [email, setEmail] = useState("")
    const [passwd, setPasswd] = useState("")
    const [offerPasswdReset, setOfferPasswdReset] = useState(false)
    const [sentResetEmail, setSentResetEmail] = useState(false)
    const [errorOnReset, setErrorOnReset] = useState(false)

    async function tryLogin(e) {
        // To prevent refresh, because button has type = 'submit'.
        // https://stackoverflow.com/questions/38860900/firebase-project-results-in-auth-network-request-failed-error-on-login
        e.preventDefault()

        console.log("Will try to login with " + email + " / " + passwd)
        try {
            dispatch(setIsLoading(true))

            // Sinaliza que devemos manter o usuário logado mesmo
            // que ele feche a janela.
            // await auth.setPersistence('local')

            // De fato tenta logar.
            const credential = await auth.signInWithEmailAndPassword(email.trim(), passwd.trim())

            const userData = await getUserProfile(credential.user.uid, credential.user.emailVerified)

            dispatch(setIsLoading(false))

            if (userData !== -1) {
                console.log("tryLogin(): success.")
                // dispatch( saveUser(userData) )
            }
            else {
                console.log("tryLogin(): signed in, but failed fetching profile.")
                toastMsg("☠️ Sua conta existe, mas não achamos os dados. Fale com o Suporte!")
            }
        }

        catch (error) {
            dispatch(setIsLoading(false))
            console.log(error)
            switch (error.code) {
                case 'auth/user-disabled' :
                    toastMsg("☠️ Conta desativada. Fale com o Suporte!")
                    break;

                case 'auth/user-not-found' :
                    toastMsg("👁️ Usuário não foi encontrado.")
                    break

                case 'auth/wrong-password' :
                    toastMsg("❌ Senha errada!")
                    break

                case 'auth/invalid-email' :
                    toastMsg("❌ Email mal formatado!")
                    break

                case 'auth/network-request-failed':
                    toastMsg("🐌 Verifique sua conexão com a internet.")
                    break

                default:
                    toastMsg("🙃 Erro inesperado. Considere falar com nosso Suporte. ")
            }

        }
    }


    async function sendPasswordResetEmail() {
        try {
            await auth.sendPasswordResetEmail(email)
            setSentResetEmail(true)
            setErrorOnReset(false)

        } catch (error) {

            console.log("ERROR")
            if (error.code === 'auth/user-not-found') {
                toastMsg("👁️ Este usuário não foi encontrado.")
            }
            
            setErrorOnReset(true)
        }
         
    }

    function signInJSX() {
        return (
            <LoginBox>
                <WelcomeText>Olá!</WelcomeText>
                <SubText>Entre na sua conta para continuar</SubText>
                
                <Form onSubmit={tryLogin} autoComplete="on">
                    <InputGroup>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value.trim().toLowerCase())}
                        />
                    </InputGroup>

                    <InputGroup>
                        <Input
                            type="password"
                            placeholder="Senha"
                            value={passwd}
                            onChange={(e) => setPasswd(e.target.value)}
                        />
                    </InputGroup>

                    <ForgotPassword 
                        href="#" 
                        onClick={() => setOfferPasswdReset(true)}
                    >
                        Esqueceu sua senha?
                    </ForgotPassword>

                    <Button type="submit">
                        Entrar
                    </Button>

                    <Text align="center">
                        Não tem uma conta?{' '}
                        <a href="#" onClick={props.changeMethod}>
                            Cadastre-se
                        </a>
                    </Text>
                </Form>
            </LoginBox>
        )
    }

    function changePasswordJSX() {
        return (
            <LoginBox>
                <WelcomeText>Recuperar Senha</WelcomeText>
                <SubText>
                    Insira seu email de cadastro para receber um link de recuperação
                </SubText>

                <Form>
                    {sentResetEmail && !errorOnReset ? (
                        <>
                            <Text align="center" margin="0 0 24px">
                                Email enviado para <strong>{email}</strong>!
                                Verifique sua caixa de entrada.
                            </Text>
                            
                            <Button
                                onClick={() => {
                                    setSentResetEmail(false)
                                    setOfferPasswdReset(false)
                                }}
                            >
                                Voltar
                            </Button>
                        </>
                    ) : (
                        <>
                            <InputGroup>
                                <Input
                                    type="email"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </InputGroup>

                            <ButtonGroup marginTop="24px">
                                <Button
                                    type="button"
                                    onClick={() => {
                                        setSentResetEmail(false)
                                        setOfferPasswdReset(false)
                                    }}
                                >
                                    Voltar
                                </Button>
                                <Button
                                    type="button"
                                    onClick={sendPasswordResetEmail}
                                >
                                    Recuperar Senha
                                </Button>
                            </ButtonGroup>
                        </>
                    )}
                </Form>
            </LoginBox>
        )
    }

    return offerPasswdReset ? changePasswordJSX() : signInJSX()
}