import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraNIHSS() {
    const [nivelConsciencia, setNivelConsciencia] = useState('');
    const [orientacao, setOrientacao] = useState('');
    const [comandos, setComandos] = useState('');
    const [olhar, setOlhar] = useState('');
    const [camposVisuais, setCamposVisuais] = useState('');
    const [paralisiaFacial, setParalisiaFacial] = useState('');
    const [forcaMSE, setForcaMSE] = useState('');
    const [forcaMSD, setForcaMSD] = useState('');
    const [forcaMIE, setForcaMIE] = useState('');
    const [forcaMID, setForcaMID] = useState('');
    const [ataxiaMembros, setAtaxiaMembros] = useState('');
    const [sensibilidade, setSensibilidade] = useState('');
    const [linguagem, setLinguagem] = useState('');
    const [disartria, setDisartria] = useState('');
    const [desatencao, setDesatencao] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularNIHSS = () => {
        const pontos = parseInt(nivelConsciencia) +
            parseInt(orientacao) +
            parseInt(comandos) +
            parseInt(olhar) +
            parseInt(camposVisuais) +
            parseInt(paralisiaFacial) +
            parseInt(forcaMSE) +
            parseInt(forcaMSD) +
            parseInt(forcaMIE) +
            parseInt(forcaMID) +
            parseInt(ataxiaMembros) +
            parseInt(sensibilidade) +
            parseInt(linguagem) +
            parseInt(disartria) +
            parseInt(desatencao);

        setResultado(`Pontuação NIHSS: ${pontos}`);

        if (pontos > 22) {
            setInterpretacao('Pacientes com NIHSS > 22, especialmente se > 80 anos, devem ser avaliados caso a caso quanto à necessidade de fibrinólise, devido ao maior risco de sangramento.');
        } else if (pontos > 4) {
            setInterpretacao('Pacientes com NIHSS > 4 devem ser avaliados quanto à possibilidade de terapia fibrinolítica.');
        } else {
            setInterpretacao('Pontuação NIHSS <= 4.');
        }
    };

    useEffect(() => {
        if (nivelConsciencia && orientacao && comandos && olhar && camposVisuais && paralisiaFacial && forcaMSE && forcaMSD && forcaMIE && forcaMID && ataxiaMembros && sensibilidade && linguagem && disartria && desatencao) {
            calcularNIHSS();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [nivelConsciencia, orientacao, comandos, olhar, camposVisuais, paralisiaFacial, forcaMSE, forcaMSD, forcaMIE, forcaMID, ataxiaMembros, sensibilidade, linguagem, disartria, desatencao]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Escore NIHSS (AVE)</h1>
            <div className='form-nihss'>
            <div className='div-nihss'>
                <label>1A: Nível de Consciência</label>
                <select value={nivelConsciencia} onChange={e => setNivelConsciencia(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Alerta</option>
                    <option value="1">Sonolento, desperta com pequenos estímulos</option>
                    <option value="2">Estuporoso, desperta com estímulos fortes</option>
                    <option value="3">Comatoso, apenas respostas reflexas motoras ou estereotipadas ou sem qualquer tipo de resposta</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>1B: Orientação (pergunte mês e idade)</label>
                <select value={orientacao} onChange={e => setOrientacao(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Ambas corretas</option>
                    <option value="1">Uma resposta correta</option>
                    <option value="2">Nenhuma resposta correta</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>1C: Comandos (piscar os olhos e apertar a mão)</label>
                <select value={comandos} onChange={e => setComandos(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Obedece às duas ordens</option>
                    <option value="1">Obedece a uma ordem</option>
                    <option value="2">Ambas incorretas</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>2: Olhar (apenas avalie o olhar horizontal)</label>
                <select value={olhar} onChange={e => setOlhar(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Normal</option>
                    <option value="1">Paralisia parcial do olhar conjugado</option>
                    <option value="2">Desvio forçado ou paralisia total do olhar conjugado</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>3: Campos Visuais</label>
                <select value={camposVisuais} onChange={e => setCamposVisuais(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem déficits</option>
                    <option value="1">Hemianopsia parcial</option>
                    <option value="2">Hemianopsia completa</option>
                    <option value="3">Hemianopsia bilateral (cego, incluindo cegueira cortical)</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>4: Paralisia Facial</label>
                <select value={paralisiaFacial} onChange={e => setParalisiaFacial(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Movimentos simétricos e normais</option>
                    <option value="1">Paresia mínima</option>
                    <option value="2">Paralisia facial central evidente</option>
                    <option value="3">Paralisia facial completa</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>5A: Força Muscular MSE</label>
                <select value={forcaMSE} onChange={e => setForcaMSE(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem queda; mantém o braço a 90º (ou 45º) por um período de 10 segundos</option>
                    <option value="1">Membro cai em &lt; 10 seg</option>
                    <option value="2">Vence gravidade</option>
                    <option value="3">Não vence gravidade</option>
                    <option value="4">Sem movimento</option>
                    <option value="0">Impossível avaliar</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>5B: Força Muscular MSD</label>
                <select value={forcaMSD} onChange={e => setForcaMSD(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem queda; mantém o braço a 90º (ou 45º) por um período de 10 segundos</option>
                    <option value="1">Membro cai em &lt; 10 seg</option>
                    <option value="2">Vence gravidade</option>
                    <option value="3">Não vence gravidade</option>
                    <option value="4">Sem movimento</option>
                    <option value="0">Impossível avaliar</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>6A: Força Muscular MIE</label>
                <select value={forcaMIE} onChange={e => setForcaMIE(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem queda; mantém a perna a 30º por um período de 5 segundos</option>
                    <option value="1">Membro cai em &lt; 5 seg</option>
                    <option value="2">Vence gravidade</option>
                    <option value="3">Não vence gravidade</option>
                    <option value="4">Sem movimento</option>
                    <option value="0">Impossível avaliar</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>6B: Força Muscular MID</label>
                <select value={forcaMID} onChange={e => setForcaMID(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem queda; mantém a perna a 30º por um período de 5 segundos</option>
                    <option value="1">Membro cai em &lt; 5 seg</option>
                    <option value="2">Vence gravidade</option>
                    <option value="3">Não vence gravidade</option>
                    <option value="4">Sem movimento</option>
                    <option value="0">Impossível avaliar</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>7: Ataxia dos Membros</label>
                <select value={ataxiaMembros} onChange={e => setAtaxiaMembros(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem ataxia</option>
                    <option value="1">Em um membro</option>
                    <option value="2">Em dois membros</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>8: Sensibilidade</label>
                <select value={sensibilidade} onChange={e => setSensibilidade(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Normal</option>
                    <option value="1">Déficit sutil</option>
                    <option value="2">Déficit evidente</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>9: Linguagem</label>
                <select value={linguagem} onChange={e => setLinguagem(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Sem afasia</option>
                    <option value="1">Afasia leve a moderada</option>
                    <option value="2">Afasia grave</option>
                    <option value="3">Mutismo</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>10: Disartria</label>
                <select value={disartria} onChange={e => setDisartria(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Articulação normal</option>
                    <option value="1">Disartria leve</option>
                    <option value="2">Palavras incompreensíveis</option>
                    <option value="0">Impossível avaliar</option>
                </select>
            </div>
            <div className='div-nihss'>
                <label>11: Desatenção</label>
                <select value={desatencao} onChange={e => setDesatencao(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="0">Normal</option>
                    <option value="1">Desatenção visual, tátil, auditiva, espacial ou pessoal</option>
                    <option value="2">Profunda hemidesatenção ou hemidesatenção para mais de uma modalidade</option>
                </select>
            </div>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraNIHSS;
