
import React from 'react'

import './TestScreenButton.css'
import styled from 'styled-components';



const TestScreenButtonContainer = styled.div`
    cursor: pointer;
        
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;


    height: 3em;

    width: 10em;
    border-radius: 1.5em;

    padding: 0 0.6em 0 0.6em;

    @media (max-width: 500px) {
        width: auto; 
        font-size: 12px;   
    }



    ${props => props.theme.darkMode ? `
        background-color: rgb(81, 81, 81);
        box-shadow: none;
        &:hover {
            background-color: rgb(141, 141, 141);
        }

    ` : `
        background-color: white;
        box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
        color: rgb(230, 230, 230);
        &:hover {
            background-color: rgb(244, 244, 244, 0.6);
        }
    
        &:active {
            box-shadow: none
        }
    `}

`;


const TestScreenButtonIcon = styled.img`
    height: 30%;
`;

const TestScreenButtonText = styled.div`
    font-size: 12px;
    text-transform: uppercase;
    margin-left: 0.9em;
    font-weight: 600;
    color: black;


    ${props => props.theme.darkMode ? `
        color: rgb(230, 230, 230);
    ` : `
        color: black;
    `}
`;

export default function TestScreenButton(props) {
    return (
        <TestScreenButtonContainer
            className={"TestScreenButton-Container " + (props.addClass ? props.addClass : '')}
            style={props.style}
            onClick={props.action}
        >
            <TestScreenButtonIcon src={props.icon} />
            <TestScreenButtonText>{props.text}</TestScreenButtonText>
        </TestScreenButtonContainer>
    );
}