// src/pages/CalcPage.js
import React from 'react';
import { useParams } from 'react-router-dom';
import CalculadoraIMC from '../calculators/CalculadoraIMC';
import CalculadoraAsmaGINA from '../calculators/CalculadoraAsmaGINA';
import CalculadoraCargaTabagica from '../calculators/CalculadoraCargaTabagica';
import CalculadoraNYHA from '../calculators/CalculadoraNYHA';
import CalculadoraChildPugh from '../calculators/CalculadoraChildPugh';
import CalculadoraDAS28CRP from '../calculators/CalculadoraDAS28CRP';
import CalculadoraCreatininaCKDEPI from '../calculators/CalculadoraCreatininaCKDEPI';
import CalculadoraCreatininaCockcroftGault from '../calculators/CalculadoraCreatininaCockcroftGault';
import CalculadoraFENa from '../calculators/CalculadoraFENa';
import CalculadoraProteinaCreatinina from '../calculators/CalculadoraProteinaCreatinina';
import CalculadoraCAMICU from '../calculators/CalculadoraCAMICU';
import CalculadoraCAGE from '../calculators/CalculadoraCAGE';
import CalculadoraGASA from '../calculators/CalculadoraGASA';
import CalculadoraIST from '../calculators/CalculadoraIST';
import CalculadoraGasometriaArterial from '../calculators/CalculadoraGasometriaArterial';
import CalculadoraSARAFio2 from '../calculators/CalculadoraSARAFio2';
import CalculadoraDAS28VHS from '../calculators/CalculadoraDAS28VHS';
import CalculadoraWellsTEP from '../calculators/CalculadoraWellsTEP';
import CalculadoraFramingham from '../calculators/CalculadoraFramingham';
import CalculadoraCentor from '../calculators/CalculadoraCentor';
import CalculadoraNIHSS from '../calculators/CalculadoraNIHSS';
import CalculadoraAlvarado from '../calculators/CalculadoraAlvarado';
import CalculadoraAPGAR from '../calculators/CalculadoraAPGAR';
import CalculadoraIMCPedMasc from '../calculators/CalculadoraIMCPedMasc';
import CalculadoraIMCFem from '../calculators/CalculadoraIMCFem';
import CalculadoraCapurroSomatico from '../calculators/CalculadoraCapurroSomatico';
import CalculadoraIdadeGestacionalDUM from '../calculators/CalculadoraIdadeGestacionalDUM';
import CalculadoraIdadeGestacionalUSG from '../calculators/CalculadoraIdadeGestacionalUSG';
import CalculadoraGlasgowPupilar from '../calculators/CalculadoraGlasgowPupilar';
import CalculadoraFisher from '../calculators/CalculadoraFisher';
import CalculadoraAdrenalina from '../calculators/CalculadoraAdrenalina';
import CalculadoraDobutamina from '../calculators/CalculadoraDobutamina';
import CalculadoraDopamina from '../calculators/CalculadoraDopamina';
import CalculadoraNitroglicerina from '../calculators/CalculadoraNitroglicerina';
import CalculadoraNoradrenalina from '../calculators/CalculadoraNoradrenalina';
import CalculadoraMidazolam from '../calculators/CalculadoraMidazolam';
import CalculadoraFentanil from '../calculators/CalculadoraFentanil';
import CalculadoraCetamina from '../calculators/CalculadoraCetamina';
import CalculadoraPropofol from '../calculators/CalculadoraPropofol';
import CalculadoraSodioCorrigido from '../calculators/CalculadoraSodioCorrigido';
import CalculadoraMagnesioCorrigido from '../calculators/CalculadoraMagnesioCorrigido';
import CalculadoraCalcioCorrigido from '../calculators/CalculadoraCalcioCorrigido';
import CalculadoraDeficitAguaLivre from '../calculators/CalculadoraDeficitAguaLivre';
import CalculadoraHOMAIR from '../calculators/CalculadoraHOMAIR';
import CalculadoraGAD7 from '../calculators/CalculadoraGAD7';
import CalculadoraConversaoEsteroides from '../calculators/CalculadoraConversaoEsteroides';
import CalculadoraPHQ9 from '../calculators/CalculadoraPHQ9';
import CalculadoraHEART from '../calculators/CalculadoraHEART';
import CalculadoraPlantao from '../calculators/CalculadoraPlantao';
import { Link } from 'react-router-dom';

import './CalcPage.css'
function CalcPage() {
  let { tipo } = useParams();

  const renderizarCalculadora = () => {
    switch (tipo) {
      case 'imc':
        return <div className="calc-container"><CalculadoraIMC /></div>;

      case 'GINA':
        return <div className="calc-container"><CalculadoraAsmaGINA /></div>;

        case 'CargaTabagica':
          return <div className="calc-container"><CalculadoraCargaTabagica /></div>;

          case 'NYHA':
          return <div className="calc-container"><CalculadoraNYHA /></div>;

          case 'childpugh':
          return <div className="calc-container"><CalculadoraChildPugh /></div>;

          case 'das28crp':
          return <div className="calc-container"><CalculadoraDAS28CRP /></div>;

          case 'ckdepi':
          return <div className="calc-container"><CalculadoraCreatininaCKDEPI /></div>;

          case 'alvarado':
          return <div className="calc-container"><CalculadoraAlvarado /></div>;

          case 'cockcroftGault':
          return <div className="calc-container"><CalculadoraCreatininaCockcroftGault /></div>;

          case 'excrecaoSodio':
          return <div className="calc-container"><CalculadoraFENa /></div>;

          case 'calcProtCreat':
          return <div className="calc-container"><CalculadoraProteinaCreatinina /></div>;

          case 'camicu':
          return <div className="calc-container"><CalculadoraCAMICU /></div>;

          case 'cage':
          return <div className="calc-container"><CalculadoraCAGE /></div>;
      
          case 'gasa':
          return <div className="calc-container"><CalculadoraGASA /></div>;

          case 'ist':
          return <div className="calc-container"><CalculadoraIST /></div>;

          case 'gasometria':
          return <div className="calc-container"><CalculadoraGasometriaArterial /></div>;

          case 'sarafio2':
          return <div className="calc-container"><CalculadoraSARAFio2 /></div>;

          case 'das28vhs':
          return <div className="calc-container"><CalculadoraDAS28VHS /></div>;

          case 'WellsTEP':
          return <div className="calc-container"><CalculadoraWellsTEP /></div>;

          case 'framingham':
          return <div className="calc-container"><CalculadoraFramingham /></div>;

          case 'centor':
          return <div className="calc-container"><CalculadoraCentor /></div>;

          case 'nihss':
          return <div className="calc-container-nihss"><CalculadoraNIHSS /></div>;

          case 'apgar':
          return <div className="calc-container"><CalculadoraAPGAR /></div>;

          case 'imcpedmasc':
          return <div className="calc-container"><CalculadoraIMCPedMasc /></div>;

          case 'imcpedfem':
          return <div className="calc-container"><CalculadoraIMCFem /></div>;

          case 'capurro':
          return <div className="calc-container"><CalculadoraCapurroSomatico /></div>;

          case 'igdum':
          return <div className="calc-container"><CalculadoraIdadeGestacionalDUM /></div>;

          case 'igusg':
          return <div className="calc-container"><CalculadoraIdadeGestacionalUSG /></div>;

          case 'glasgowpupilar':
          return <div className="calc-container"><CalculadoraGlasgowPupilar /></div>;

          case 'fisher':
          return <div className="calc-container"><CalculadoraFisher /></div>;

          case 'adrenalina':
          return <div className="calc-container"><CalculadoraAdrenalina /></div>;

          case 'dobutamina':
          return <div className="calc-container"><CalculadoraDobutamina /></div>;

          case 'dopamina':
          return <div className="calc-container"><CalculadoraDopamina /></div>;

          case 'nitroglicerina':
          return <div className="calc-container"><CalculadoraNitroglicerina /></div>;

          case 'noradrenalina':
          return <div className="calc-container"><CalculadoraNoradrenalina /></div>;

          case 'midazolam':
          return <div className="calc-container"><CalculadoraMidazolam /></div>;

          case 'fentanil':
          return <div className="calc-container"><CalculadoraFentanil /></div>;

          case 'cetamina':
          return <div className="calc-container"><CalculadoraCetamina /></div>;

          case 'propofol':
          return <div className="calc-container"><CalculadoraPropofol /></div>;

          case 'sodiocorrigido':
          return <div className="calc-container"><CalculadoraSodioCorrigido /></div>;

          case 'magnesiocorrigido':
          return <div className="calc-container"><CalculadoraMagnesioCorrigido /></div>;

          case 'calciocorrigido':
            return <div className="calc-container"><CalculadoraCalcioCorrigido /></div>;

            case 'deficitagualivre':
            return <div className="calc-container"><CalculadoraDeficitAguaLivre /></div>;

            case 'homair':
              return <div className="calc-container"><CalculadoraHOMAIR /></div>;

              case 'gad7':
              return <div className="calc-container"><CalculadoraGAD7 /></div>;

              case 'conversaoesteroides':
              return <div className="calc-container"><CalculadoraConversaoEsteroides /></div>;
              case 'phq9':
              return <div className="calc-container"><CalculadoraPHQ9 /></div>;

              case 'heart':
              return <div className="calc-container"><CalculadoraHEART /></div>;

              case 'plantoes':
              return <div className="calc-container"><CalculadoraPlantao /></div>;
  

      default:
        return <div className="calc-container">Calculadora não encontrada</div>;
    }
  };

  return (
    <div className='calc-rendering'>
      {renderizarCalculadora()}
    </div>
  );
}

export default CalcPage;
