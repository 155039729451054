import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import ResidenciaListsController from '../controllers/ResidenciaListsController'
import EditIcon from '../assets/edit.png'
import DeleteIcon from '../assets/trash.png'
import { useDispatch, useSelector } from 'react-redux'
import CancelIcon from '../assets/x-icon.png'
import SaveIcon from '../assets/save_folder.png'
import { useHotkeys } from 'react-hotkeys-hook'
import GeneralDialog from '../tests/GeneralDialog'
import OslerButton from '../components/OslerButton'
import { setIsLoading } from '../redux/loadingSlice'
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import OslerHorizontalBar from '../statistics/OslerHorizontalBar'
import OslerProgressBar from '../statistics/OslerProgressBar'
import { FaArrowRight } from 'react-icons/fa'
import ResidenciaListsDialog from './ResidenciaListsDialog'


const TagContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 1em;

    @media (max-width: 768px) {
        margin-top: 0.5em;
    }
`

const Tag = styled.span`
    ${props => props.theme.darkMode ? `
        background-color: rgb(61, 61, 61);
        color: rgb(180, 180, 180);
    ` : `
        background-color: #ecf0f1;
        color: #7f8c8d;
    `}
    font-size: 0.8em;
    padding: 0.2em 0.5em;
    border-radius: 0.3em;
    margin-right: 0.3em;
    margin-bottom: 0.3em;

    @media (max-width: 768px) {
        font-size: 0.7em;
        padding: 0.15em 0.4em;
    }
`


const NoListsMessage = styled.p`
    text-align: center;
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#7f8c8d'};
    font-size: 1.1em;
    margin-top: 2em;

    @media (max-width: 768px) {
        font-size: 1em;
        margin-top: 1em;
        padding: 0 1em;
    }
`


const ListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1.5em;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        padding: 1em;
    }
`

const ListCard = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 48%;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.2);
        
        &:hover {
            transform: translateY(-0.25em);
            box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
        }
    ` : `
        background-color: #ffffff;
        box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
        
        &:hover {
            transform: translateY(-0.25em);
            box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.15);
        }
    `}

    border-radius: 0.8em;
    padding: 1.5em;
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    @media (max-width: 768px) {
        width: 100%;
        padding: 0.7em;
        margin-bottom: 1em;
    }
`


const TitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        gap: 1em;
    }
`

const Title = styled.h3`
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'black'};
    font-size: 1.2em;
    border-bottom: 2px solid ${props => props.theme.darkMode ? '#60a5fa' : '#3498db'};

        // border-bottom: 2px solid #a01d26;


    margin: 0;
    padding: 0 0 0.2em 0;
    width: 85%;

    @media (max-width: 768px) {
        font-size: 1.1em;
        width: 80%;
    }
`

const EditTitle = styled.input`
    font-size: 1.2em;
    ${props => props.theme.darkMode ? `
        background-color: rgb(61, 61, 61);
        color: rgb(220, 220, 220);
        caret-color: rgb(220, 220, 220);
        border: 1px solid rgba(255, 255, 255, 0.1);
        
        &:focus {
            border-color: #60a5fa;
        }
    ` : `
        background-color: white;
        color: gray;
        caret-color: gray;
        border: 1px solid #ebebeb;
        
        &:focus {
            border-color: #3498db;
        }
    `}

    padding: 0.25em 0.25em 0.25em 0.5em;

    border-radius: 0.5em;
    width: 85%;

    @media (max-width: 768px) {
        font-size: 1.1em;
        width: 80%;
        padding: 0.2em 0.2em 0.2em 0.4em;
    }
`


const EditBttns = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-right: -1em;

    @media (max-width: 768px) {
        margin-right: -0.5em;
    }
`

const Bttn = styled.div`
    background: none;
    border: none;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.3s ease;
    filter: ${props => props.theme.darkMode ? 'invert(0.8)' : 'none'};

    &:hover {
        opacity: 1;
    }

    margin-right: 1em;

    img {
        height: 1.4em;
        display: block;
    }

    @media (max-width: 768px) {
        margin-right: 0.5em;
        
        img {
            height: 1.2em;
        }
    }
`

const DeleteBttns = styled.div`
    ${RowCSS}
    width: 100%;
    justify-content: flex-end;
    margin-top: 1em;
    gap: 1em;

    @media (max-width: 768px) {
        gap: 0.5em;
    }
`


const DateInfo = styled.p`
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : 'gray'};
    font-size: 0.8em;
    margin: 0.5em 0;
    font-weight: bold;

    @media (max-width: 768px) {
        font-size: 0.75em;
        margin: 0.3em 0;
    }
`

//a 


const ProgressContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1em 0;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
        margin: 0.8em 0;
    }
`

const BarContainer = styled.div`
    ${ColumnCSS}
    justify-content: center;
    width: 75%;
    margin-right: 1em;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.5em;
    }
`

const ProgressText = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25%;

    @media (max-width: 768px) {
        width: 100%;
    }
`

const ProgressMain = styled.span`
    font-size: 1em;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#2c3e50'};
    font-weight: bold;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 0.9em;
    }
`

const ProgressSub = styled.span`
    font-size: 0.8em;
    color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#7f8c8d'};
    white-space: normal;
    word-wrap: break-word;
    padding: 0 2em 0 2em;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 0.75em;
        padding: 0 1em;
    }
`


const StartButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    align-self: flex-end;
    
    @media (max-width: 768px) {
        margin-top: 1em;
        width: 100%;
    }
`

const StartButton = styled.button`
    background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#3498db'};
    color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41)' : 'white'};
    // background-color: #a01d26;

    border: none;
    border-radius: 0.5em;
    padding: 0.8em 1.5em;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;

    align-self: flex-end;

    &:hover {
        background-color: ${props => props.theme.darkMode ? '#3b82f6' : '#2980b9'};
    }

    svg {
        margin-left: 8px;
        font-size: 0.8em;
        ${props => props.theme.darkMode && `
            filter: brightness(0.1);
        `}
    }

    @media (max-width: 768px) {
        width: 100%;
        padding: 0.7em 1.2em;
        font-size: 0.95em;
    }
`

function ProgressBarComponent({ segments, mainText, subText }) {
    return (
        <ProgressContainer>
            <BarContainer>
                <OslerProgressBar segments={segments} />
            </BarContainer>
            <ProgressText>
                <ProgressMain>{mainText}</ProgressMain>
                <ProgressSub>{subText}</ProgressSub>
            </ProgressText>
        </ProgressContainer>
    )
}


export default function ResidenciaLists() {
    const [lists, setLists] = useState([])
    const [editingId, setEditingId] = useState(null)
    const [editName, setEditName] = useState('')
    const [listToDeleteID, setListToDeleteID] = useState(null)
    const [listToDeleName, setListToDeleteName] = useState(null)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)
    const [showStartListDialog, setShowStartListDialog] = useState(false)
    const [selectedList, setSelectedList] = useState(null)


    const user = useSelector(state => state.user.data)
    const dispatch = useDispatch()


    useEffect(() => {
        const fetchLists = async () => {
            await ResidenciaListsController.start(user)
            setLists(ResidenciaListsController.getLists())
        }
        fetchLists()
    }, [user])


    const handleEdit = (list) => {
        setEditingId(list.id)
        setEditName(list.name)
    }


    const handleSave = async () => {
        if (editingId && editName.trim()) {
            await ResidenciaListsController.updateListName(editingId, editName)
            setLists(ResidenciaListsController.getLists())
            setEditingId(null)
        }
    }


    useHotkeys('enter', (event) => {
        event.preventDefault()
        if (editingId) {
            handleSave()
        }
        // Por padrão, desativa em input; revertemos
    }, { enableOnFormTags: ['INPUT'] })


    const handleCancel = () => {
        setEditingId(null)
        setEditName('')
    }


    function handleStartTest(list) {
        setSelectedList(list)
        setShowStartListDialog(true)
    }


    function offerToDeleteList(listId, listName) {
        setListToDeleteID(listId)
        setListToDeleteName(listName)
        setShowDeleteDialog(true)
    }


    function cancelDeleteList() {
        setListToDeleteID(null)
        setListToDeleteName(null)
        setShowDeleteDialog(false)
    }


    async function deleteList() {
        dispatch(setIsLoading(true))
        await ResidenciaListsController.deleteList(listToDeleteID)
        setLists(ResidenciaListsController.getLists())

        cancelDeleteList()
        dispatch(setIsLoading(false))
    }


    if (lists.length === 0) {
        return <NoListsMessage>Você ainda não tem listas. Elas podem ser criadas na área de questões.</NoListsMessage>
    }

    return (
        <ListContainer>
            
            <ResidenciaListsDialog
                showDialog = {showStartListDialog}
                setShowDialog = {setShowStartListDialog}
                selectedList = {selectedList} />

            <GeneralDialog
                open = {showDeleteDialog}
                icon = {DeleteIcon}
                title = {'Excluir lista?'}
                onClose={cancelDeleteList}>

                    <p>Você irá <u>excluir</u> a lista de questões: <span style = {{fontSize: '1.05em', fontWeight: 'bold'}}>{listToDeleName}</span>.</p>

                    <p>Isso é <u>irreversível</u>.</p>

                    <DeleteBttns>
                        <OslerButton
                            color='gray'
                            text='Voltar'
                            onClick={cancelDeleteList}
                        />

                        <OslerButton
                            color='red'
                            text='Excluir'
                            onClick={deleteList}
                        />
                    </DeleteBttns>

            </GeneralDialog>

            {lists.map(list => (

                <ListCard key={list.id}>
                                    { console.log(list) }
                    <TitleContainer>
                        {editingId === list.id ? (
                            <>
                                <EditTitle
                                    value={editName}
                                    onChange={(e) => setEditName(e.target.value)}
                                    autoFocus
                                />
                                <EditBttns>
                                    <Bttn onClick={handleCancel}>
                                        <img src={CancelIcon} alt="Cancelar" />
                                    </Bttn>
                                    <Bttn onClick={handleSave}>
                                        <img src={SaveIcon} alt="Salvar" />
                                    </Bttn>
                                </EditBttns>
                            </>
                        ) : (
                            <>
                                <Title>{list.name}</Title>
                                <EditBttns>
                                    <Bttn onClick={() => handleEdit(list)}>
                                        <img src={EditIcon} alt="Editar" />
                                    </Bttn>
                                    <Bttn onClick={() => offerToDeleteList(list.id, list.name)}>
                                        <img src={DeleteIcon} alt="Excluir" />
                                    </Bttn>
                                </EditBttns>
                            </>
                        )}
                    </TitleContainer>
                    <DateInfo>Criada em: {list.createdAtFormatted}</DateInfo>
                    <DateInfo>Última resposta: {list.lastAnsweredFormatted || 'Nenhuma resposta ainda'}</DateInfo>
                                    
                    <ProgressBarComponent
                        segments={[
                            {
                                percent: list.completionPercentage,
                                color: 'rgba(52, 152, 219, 0.7)',
                                colorHover: 'rgba(41, 128, 185, 0.8)',
                                label: 'Feitas',
                                secondaryLabel: `${list.totalAnswered} de ${list.testIDs.length} (${Math.round(list.completionPercentage)}%)`,
                                number: list.totalAnswered
                            },
                            {
                                percent: 100 - list.completionPercentage,
                                color: 'rgba(189, 195, 199, 0.7)',
                                colorHover: 'rgba(149, 165, 166, 0.8)',
                                label: 'Não feitas',
                                secondaryLabel: `${list.testIDs.length - list.totalAnswered} de ${list.testIDs.length} (${Math.round(100 - list.completionPercentage)}%)`,
                                number: list.testIDs.length - list.totalAnswered
                            }
                        ]}
                        mainText={`${list.totalAnswered} de ${list.testIDs.length} (${Math.round(list.completionPercentage)}%)`}
                        subText="das questões feitas"
                    />
                    <ProgressBarComponent
                        segments={[
                            {
                                percent: (list.firstCorrect / list.testIDs.length) * 100,
                                color: 'rgba(46, 204, 113, 0.7)',
                                colorHover: 'rgba(39, 174, 96, 0.8)',
                                label: 'Acertos',
                                secondaryLabel: `${list.firstCorrect} de ${list.testIDs.length} (${Math.round((list.firstCorrect / list.testIDs.length) * 100)}%)`,
                                number: list.firstCorrect
                            },
                            {
                                percent: (list.firstIncorrect / list.testIDs.length) * 100,
                                color: 'rgba(231, 76, 60, 0.7)',
                                colorHover: 'rgba(192, 57, 43, 0.8)',
                                label: 'Erros',
                                secondaryLabel: `${list.firstIncorrect} de ${list.testIDs.length} (${Math.round((list.firstIncorrect / list.testIDs.length) * 100)}%)`,
                                number: list.firstIncorrect
                            },
                            {
                                percent: ((list.testIDs.length - list.totalAnswered) / list.testIDs.length) * 100,
                                color: 'rgba(189, 195, 199, 0.7)',
                                colorHover: 'rgba(149, 165, 166, 0.8)',
                                label: 'Não feitas',
                                secondaryLabel: `${list.testIDs.length - list.totalAnswered} de ${list.testIDs.length} (${Math.round(((list.testIDs.length - list.totalAnswered) / list.testIDs.length) * 100)}%)`,
                                number: list.testIDs.length - list.totalAnswered
                            }
                        ]}
                        mainText={`${list.firstCorrect} de ${list.testIDs.length} (${Math.round((list.firstCorrect / list.testIDs.length) * 100)}%)`}
                        subText="de acerto, entre as feitas, na 1ª vez"
                    />
                    <ProgressBarComponent
                        segments={[
                            {
                                percent: (list.lastCorrect / list.testIDs.length) * 100,
                                color: 'rgba(46, 204, 113, 0.7)',
                                colorHover: 'rgba(39, 174, 96, 0.8)',
                                label: 'Acertos',
                                secondaryLabel: `${list.lastCorrect} de ${list.testIDs.length} (${Math.round((list.lastCorrect / list.testIDs.length) * 100)}%)`,
                                number: list.lastCorrect
                            },
                            {
                                percent: (list.lastIncorrect / list.testIDs.length) * 100,
                                color: 'rgba(231, 76, 60, 0.7)',
                                colorHover: 'rgba(192, 57, 43, 0.8)',
                                label: 'Erros',
                                secondaryLabel: `${list.lastIncorrect} de ${list.testIDs.length} (${Math.round((list.lastIncorrect / list.testIDs.length) * 100)}%)`,
                                number: list.lastIncorrect
                            },
                            {
                                percent: ((list.testIDs.length - list.totalAnswered) / list.testIDs.length) * 100,
                                color: 'rgba(189, 195, 199, 0.7)',
                                colorHover: 'rgba(149, 165, 166, 0.8)',
                                label: 'Não feitas',
                                secondaryLabel: `${list.testIDs.length - list.totalAnswered} de ${list.testIDs.length} (${Math.round(((list.testIDs.length - list.totalAnswered) / list.testIDs.length) * 100)}%)`,
                                number: list.testIDs.length - list.totalAnswered
                            }
                        ]}
                        mainText={`${list.lastCorrect} de ${list.testIDs.length} (${Math.round((list.lastCorrect / list.testIDs.length) * 100)}%)`}
                        subText="de acerto, entre as feitas, da última vez"
                    />

                    <TagContainer>
                        {Object.keys(list.nTestsPerPath).slice(0, 6).map((path, index) => (
                            <Tag key={index}>{path.split('/').pop()}</Tag>
                        ))}
                        {Object.keys(list.nTestsPerPath).length > 6 && <Tag>E outras tags...</Tag>}
                    </TagContainer>

                    <StartButtonContainer>
                        <StartButton onClick={() => handleStartTest(list)}>
                            Começar
                            <FaArrowRight />
                        </StartButton>
                    </StartButtonContainer>

                </ListCard>
            ))}
        </ListContainer>
    )
}