import { shuffle } from 'lodash'
import SessionBuilder from '../controllers/SessionBuilder';


export function nTestsPerPathIntoPredefined(nTestsPerPath, sessionConfig) {
    // Recebemos uma lista de tagpaths e uma quantidade de questões pra cada um; transformamos
    // em uma lista de testIDs para carregar como predefined
    let allTests = []

    for (const [path, nTests] of Object.entries(nTestsPerPath)) {
        const testsForPath = SessionBuilder.simulate(
            'Residencia',
            'custom',
            [path],
            sessionConfig
        )

        const selectedTests = shuffle(testsForPath).slice(0, nTests)
        allTests = allTests.concat(selectedTests)
    }

    return allTests
}

export function getLastSessionPath(userID) {
    return `/users/${userID}/personal/last-sessions`;
}

