import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraHEART() {
    const [scores, setScores] = useState({
        history: 0,
        ekg: 0,
        age: 0,
        riskFactors: 0,
        troponin: 0
    });
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setScores(prevScores => ({
            ...prevScores,
            [name]: parseInt(value, 10)
        }));
    };

    const calcularHEART = () => {
        const totalScore = Object.values(scores).reduce((a, b) => a + b, 0);
        let interpretation = '';

        if (totalScore >= 0 && totalScore <= 3) {
            interpretation = 'Baixo risco de eventos cardiovasculares maiores';
        } else if (totalScore >= 4 && totalScore <= 6) {
            interpretation = 'Risco intermediário de eventos cardiovasculares maiores';
        } else if (totalScore >= 7) {
            interpretation = 'Alto risco de eventos cardiovasculares maiores';
        }

        setResultado(`Pontuação total: ${totalScore}, Interpretação: ${interpretation}`);
    };

    useEffect(() => {
        calcularHEART();
    }, [scores]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora HEART</h1>
            <form className='form-gad7'>
                <div>
                    <label>História</label>
                    <select name="history" value={scores.history} onChange={handleChange}>
                        <option value="0">Levemente suspeito</option>
                        <option value="1">Moderadamente suspeito</option>
                        <option value="2">Altamente suspeito</option>
                    </select>
                </div>
                <div>
                    <label>EKG</label>
                    <select name="ekg" value={scores.ekg} onChange={handleChange}>
                        <option value="0">Normal</option>
                        <option value="1">Distúrbio inespecífico de repolarização</option>
                        <option value="2">Desvio significativo do ST</option>
                    </select>
                </div>
                <div>
                    <label>Idade</label>
                    <select name="age" value={scores.age} onChange={handleChange}>
                        <option value="0">&lt;45</option>
                        <option value="1">45-64</option>
                        <option value="2">&ge;65</option>
                    </select>
                </div>
                <div>
                    <label>Fatores de risco</label>
                    <select name="riskFactors" value={scores.riskFactors} onChange={handleChange}>
                        <option value="0">Nenhum fator de risco conhecido</option>
                        <option value="1">1-2 fatores de risco</option>
                        <option value="2">&ge;3 fatores de risco ou história de doença aterosclerótica</option>
                    </select>
                </div>
                <div>
                    <label>Troponina inicial</label>
                    <select name="troponin" value={scores.troponin} onChange={handleChange}>
                        <option value="0">&le; limite normal</option>
                        <option value="1">1-3× limite normal</option>
                        <option value="2">&gt;3× limite normal</option>
                    </select>
                </div>
            </form>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraHEART;
