import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraFramingham() {
    const [sexo, setSexo] = useState('');
    const [idade, setIdade] = useState('');
    const [paSistolica, setPaSistolica] = useState('');
    const [tratamentoHipertensao, setTratamentoHipertensao] = useState('');
    const [tabagismo, setTabagismo] = useState('');
    const [diabetes, setDiabetes] = useState('');
    const [opcaoCalculo, setOpcaoCalculo] = useState('');
    const [colesterolTotal, setColesterolTotal] = useState('');
    const [hdl, setHdl] = useState('');
    const [peso, setPeso] = useState('');
    const [altura, setAltura] = useState('');
    const [resultado, setResultado] = useState('');
    const [interpretacao, setInterpretacao] = useState('');

    const calcularRiscoFramingham = () => {
        const idadeNum = parseInt(idade);
        const paSistolicaNum = parseFloat(paSistolica);
        const colesterolTotalNum = parseFloat(colesterolTotal);
        const hdlNum = parseFloat(hdl);
        const pesoNum = parseFloat(peso);
        const alturaNum = parseFloat(altura) / 100; // converter cm para metros
        const imc = pesoNum / (alturaNum * alturaNum);

        const ln = Math.log;

        const hipertensaoFator = tratamentoHipertensao === 'Sim' ? 1 : 0;
        const cig = tabagismo === 'Sim' ? 0.52873 : 0;
        const dm = diabetes === 'Sim' ? 0.69154 : 0;

        let riscoFatores, risco;

        if (sexo === 'Masculino' && opcaoCalculo === 'Por CT-HDL') {
            riscoFatores = (ln(idadeNum) * 3.06117) + (ln(colesterolTotalNum) * 1.12370) - (ln(hdlNum) * 0.93263) + (ln(paSistolicaNum) * (hipertensaoFator ? 2.82263 : 1.93303)) + cig + dm - 23.9802;
            risco = 100 * (1 - Math.pow(0.88936, Math.exp(riscoFatores)));
        } else if (sexo === 'Feminino' && opcaoCalculo === 'Por CT-HDL') {
            riscoFatores = (ln(idadeNum) * 2.32888) + (ln(colesterolTotalNum) * 1.20904) - (ln(hdlNum) * 0.70833) + (ln(paSistolicaNum) * (hipertensaoFator ? 2.82263 : 2.76157)) + cig + dm - 26.1931;
            risco = 100 * (1 - Math.pow(0.95012, Math.exp(riscoFatores)));
        } else if (opcaoCalculo === 'Por IMC') {
            if (sexo === 'Masculino') {
                riscoFatores = (ln(idadeNum) * 3.06117) + (ln(imc) * 1.12370) + (ln(paSistolicaNum) * (hipertensaoFator ? 2.019 : 1.79735)) + cig + dm - 23.9802;
                risco = 100 * (1 - Math.pow(0.88936, Math.exp(riscoFatores)));
            } else if (sexo === 'Feminino') {
                riscoFatores = (ln(idadeNum) * 2.32888) + (ln(imc) * 1.20904) + (ln(paSistolicaNum) * (hipertensaoFator ? 2.82263 : 2.76157)) + cig + dm - 26.1931;
                risco = 100 * (1 - Math.pow(0.95012, Math.exp(riscoFatores)));
            }
        }

        setResultado(`Fatores de risco: ${riscoFatores.toFixed(2)}, Risco de eventos cardiovasculares em 10 anos: ${risco.toFixed(2)}%`);

        if (risco < 10) {
            setInterpretacao('Baixo risco');
        } else if (risco <= 20) {
            setInterpretacao('Risco moderado');
        } else {
            setInterpretacao('Alto risco');
        }
    };

    useEffect(() => {
        if (sexo && idade && paSistolica && opcaoCalculo && ((opcaoCalculo === 'Por CT-HDL' && colesterolTotal && hdl) || (opcaoCalculo === 'Por IMC' && peso && altura))) {
            calcularRiscoFramingham();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
            setInterpretacao('');
        }
    }, [sexo, idade, paSistolica, tratamentoHipertensao, tabagismo, diabetes, opcaoCalculo, colesterolTotal, hdl, peso, altura]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora Escore de Framingham (Risco de Doença Cardiovascular)</h1>
            <div>
                <label>Sexo:</label>
                <select value={sexo} onChange={e => setSexo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Feminino">Feminino</option>
                </select>
            </div>
            <div>
                <label>Idade (anos):</label>
                <input type="number" value={idade} onChange={e => setIdade(e.target.value)} />
            </div>
            <div>
                <label>Pressão arterial sistólica (mmHg):</label>
                <input type="number" value={paSistolica} onChange={e => setPaSistolica(e.target.value)} />
            </div>
            <div>
                <label>Tratamento para hipertensão:</label>
                <select value={tratamentoHipertensao} onChange={e => setTratamentoHipertensao(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Não">Não</option>
                    <option value="Sim">Sim</option>
                </select>
            </div>
            <div>
                <label>Tabagismo atual:</label>
                <select value={tabagismo} onChange={e => setTabagismo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Não">Não</option>
                    <option value="Sim">Sim</option>
                </select>
            </div>
            <div>
                <label>Diabetes:</label>
                <select value={diabetes} onChange={e => setDiabetes(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Não">Não</option>
                    <option value="Sim">Sim</option>
                </select>
            </div>
            <div>
                <label>Opções de Cálculo:</label>
                <select value={opcaoCalculo} onChange={e => setOpcaoCalculo(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="Por CT-HDL">Por CT-HDL</option>
                    <option value="Por IMC">Por IMC</option>
                </select>
            </div>
            {opcaoCalculo === 'Por CT-HDL' && (
                <>
                    <div>
                        <label>Colesterol total (mg/dL):</label>
                        <input type="number" value={colesterolTotal} onChange={e => setColesterolTotal(e.target.value)} />
                    </div>
                    <div>
                        <label>HDL (mg/dL):</label>
                        <input type="number" value={hdl} onChange={e => setHdl(e.target.value)} />
                    </div>
                </>
            )}
            {opcaoCalculo === 'Por IMC' && (
                <>
                    <div>
                        <label>Peso (kg):</label>
                        <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
                    </div>
                    <div>
                        <label>Altura (cm):</label>
                        <input type="number" value={altura} onChange={e => setAltura(e.target.value)} />
                    </div>
                </>
            )}
            <div className="result-container">
                <p>{resultado}</p>
                <p>{interpretacao}</p>
            </div>
        </div>
    );
}

export default CalculadoraFramingham;
