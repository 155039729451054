import React from 'react';
import { isMobile } from '../utils/BootstrapUtils';
import { statisticsDateToReadable } from '../utils/Utils';
import { GRAPHS_COLORS } from './LastDaysChart';
import { GraphicEqSharp } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import CardsIcon from './../assets/flashcard.png'
import ResidIcon from './../assets/residencia.png'
import { RowCSS } from '../components/BasicComponents';
import { index } from 'd3';


const Tooltip = styled.div`
    background-color: white;
    border: 0.8px solid gray;
    border-radius: 0.5em;
         
    pointer-events: none;

    display: flex;
    flex-direction: column;

    padding: 0.6em;
`

const Title = styled.p`
    font-weight: bold;
    text-align: center;
    font-size: 0.5em;
    margin: 0;
`

const Subtitle = styled.p`
    font-weight: bold;
    text-align: center;
    font-size: 0.5em;
    color: gray;
    margin: -0.25em 0 0.5em 0;
`

const Row = styled.div`
    ${RowCSS}
    margin-top: 0.15em;
`

const Icon = styled.img`
    height: 0.75em;
    width: 0.75em;
`

const Text = styled.p`
    font-size: 0.5em;
    text-align: left;
    margin: 0 0 0 0.25em;
`

export default function CustomTooltip(props) {
    const { x, y } = props;
    
    // https://github.com/FormidableLabs/victory/issues/445
    return (
        <g style={{pointerEvents: 'none'}}>
            <foreignObject x = { 60 } y = { 10 } width="125" height="120">
            <Tooltip>
                <Title> {props.title.content} </Title>

                { props.subtitle &&
                    <Subtitle> { props.subtitle.content} </Subtitle>                
                }


                { props.rows.map((row, index) => {
                    return (
                        <Row key={index}>
                            { row.icon && 
                                <Icon src = {row.icon} />                               
                            }

                            <Text>{row.content}</Text>                 
                        </Row>
                    )
                })}
                
            
            </Tooltip>
            </foreignObject>
        </g>
    )
}