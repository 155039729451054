import React from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from './../assets/Logo.png'
import LogoDark from './../assets/LogoDark.png'
import { useSelector } from 'react-redux';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const AppLogo = styled.img`
  width: 8em;
  align-self: center;
  margin: 2em;

  @media (prefers-reduced-motion: no-preference) {
    animation: ${rotate} infinite 5s linear;
  }
`;

export default function IsLoading() {

  const darkMode = useSelector(state => state.theme.darkModeOn)

    return <AppLogo src={darkMode ? LogoDark : Logo} />
}
