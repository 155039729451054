import React, { useState } from 'react'
import styled from 'styled-components'
import { darkTextPrimaryColor, darkTextSecondaryColor, darkTextTertiaryColor } from '../tests/FlashcardsStyles';


const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2em;
    width: 90%;
    border-radius: 0.75em;
    margin-top: 4em;
    align-self: center;
    
    
    ${props => props.theme.darkMode ? `
     
    `:`
        border: 1px solid #57a9fa;
    `}


    ${props => props.theme.darkMode ? `
        background-color: rgba(81, 81, 81, 1);
    `:`
        background-color: #e6f2ff;
    `}

    @media (max-width: 900px) {
        margin-bottom: -0.5em;
        padding: 1.5em;
    }
`

const Text = styled.p`
    margin: 0;
    padding: 0;
    font-size: 1.2em;

    ${props => props.theme.darkMode ? `
        color: ${darkTextPrimaryColor};
    `:`
        color: black;
    `}

    @media (max-width: 900px) {
        font-size: 1.1em;        
    }

    @media (max-width: 500px) {
        font-size: 1em;
    }
`

const Highlight = styled.span`
    font-weight: bold;
    margin-left: 0.3em;
`

const Emoji = styled.span`
    font-size: 1.3em;

    @media (max-width: 500px) {
        font-size: 1em;
    }
`

export default function StatisticsExplanationCard(props) {

    return (
        <Container>
            <Text><Emoji>{props.emoji}</Emoji><Highlight> {props.highlight}.</Highlight> {props.text}</Text>
        </Container>
    )
}