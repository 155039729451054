import React from 'react'
import styled from 'styled-components'
import { backgroundWhiteBlack } from '../tests/FlashcardsStyles'



const ScreenContainerStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    ${backgroundWhiteBlack}
    
    width: 100%;
    min-height: 100vh;


    @media (max-width: 900px) {
        width: 100%;
        padding-bottom: 0em;
    }

    @media (max-width: 500px) {
        padding-bottom: 0em;
        width: 100%;
        margin-bottom: 0px;
    }
`

export default function ScreenContainer(props) {
    return (
        <ScreenContainerStyled>
            {props.children}
        </ScreenContainerStyled>
    )
}
