// Jul, 2024
// Abstração para do modelo de gráfico usado para testes feitos nos últimos dias, e para
// revisões pendentes nos próximos.
import React, { useState, useRef, useEffect } from 'react'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryGroup, VictoryLegend, VictoryLine, VictoryScatter, VictoryStack, VictoryTooltip, VictoryVoronoiContainer } from 'victory'
import { isMobile, isTablet } from '../utils/BootstrapUtils'
import { useSelector } from 'react-redux'
import { darkTextSecondaryColor, darkTextTertiaryColor } from '../tests/FlashcardsStyles'
import styled from "styled-components"



const ChartContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    max-height: 100%;
    width: 100%;

    @media (max-width: 900px) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 2em 1em 0 1em;
        margin-top: 4em;
    }

    @media (max-width: 500px) {
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 1em;
        height: 350px;
    }
`



export function dateDashStrToDate(dateStr) {
    // dateStr é uma string da forma 2024-8-22
    // No Chrome, podemos fazer new Date(t). No Safari, não.
    // Para ser mais robusto...
    const [year, month, day] = dateStr.split('-').map(Number)
    var date = new Date(year, month - 1, day)

    return date
}


export default function OslerDateChart({ data, CustomTooltipWrapper, metadata, showLine = true, showLegend = true}) 
    {

    const chartRef = useRef(false)
    const darkMode = useSelector(state => state.theme.darkModeOn);

    const [barMaximum, setBarMaximum] = useState()
    const [lineMaximum, setLineMaximum] = useState()

    const heightExpanded = useRef(false)

    // Variáveis para o VictoryLegend (legenda sob o gráfico)
    // O número de linhas depende do número de barras; se tivermos duas barras (cards e
    // questões, fazemos três linhas)
    //
    // Se for só uma linha, a width é muito maior, e reduzimos o X. Ainda, se o bar1 só tiver
    // um label, a widt é AINDA menor, e precisamos de ainda mais X
    const itemsPerRow = (metadata.bar1_labels && metadata.bar2_labels) ? 2 : 3
    const legendFontSize = isTablet() ? 16 : 6

    let legendX;
    
    

    if (metadata.bar1_labels.length > 1) {
        if (itemsPerRow === 3) {
            legendX = isMobile() ? 100 : isTablet() ? 80 : 135
        } else {
            legendX = isMobile() ? 100 : isTablet() ? 80 : 160
        }
    } else {
        if (metadata.bar2_labels) {
            legendX = isMobile() ? 100 : isTablet() ? 80 : 170
        } else {
            legendX = isMobile() ? 100 : isTablet() ? 80 : 200
        }
    }


 

    useEffect(() => {
        let maxTests = 0
        let line1_maximum_tmp = 0
    
        data.forEach((datum) => {
            const totalTests = (datum.bar1 ? datum.bar1.reduce((sum, value) => sum + value, 0) : 0) + 
                               (datum.bar2 ? datum.bar2.reduce((sum, value) => sum + value, 0) : 0)
            
            if (totalTests > maxTests) {
                maxTests = totalTests
            }
    
            if (datum.line1 > line1_maximum_tmp) {
                line1_maximum_tmp = datum.line1
            }
        })
    
        setBarMaximum(maxTests)
        setLineMaximum(line1_maximum_tmp)
    }, [data])




    function getDateAxisTick(t, index, ticks) {
        // Se o datum tiver um customTick, use-o
        // Único caso: emoji nas acumuladas
        const datum = data[index]

        if (datum && datum.customTick) {
            return datum.customTick;
        }

        const date = dateDashStrToDate(t)
        const day = date.getDate()

        const monthNames = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];  // Abreviações dos meses

        const isFirstDayOfMonth = day === 1;

        if (index == 0 || isFirstDayOfMonth){
            return `${day}\nde ${monthNames[date.getMonth()]}`; 
        }
        else { 
            if (isTablet()) {
                // Se é último e o segundo dia do mês, significa que já teremos a data no meio, não
                // precisa
                const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
                const isLastDayOfMonth = nextDate.getMonth() !== date.getMonth();

                if (isLastDayOfMonth || day == 2) {
                    return '';  // Não exibir se for o primeiro ou último dia do mês
                } else {
                    // Exibimos um dia a cada dois, para não poluir
                    return index % 2 === 0 ? `${day}` : '';
                }
            }
            else {
                return `${day}`
            }
        }
    }



    const [chartHeight, setChartHeight] = useState(false)

    useEffect(() => {
        // A mais absoluta gambiarra. Por alguma razão, o svg/div não tem altura suficiente
        // para renderizar a legenda, especialmente quando a fonte é maior ou ela tem três linhas
        // (que é o que ocorre quando estamos exibindo Cards + Residência).
        //
        // Então, aumentamos, uma única vez, para evitar um loop infinito.
        if (chartRef.current) {
            if (!chartHeight) {
                setChartHeight(chartRef.current.clientHeight)
            }

            if (!heightExpanded.current) {
                if (metadata.bar1_labels && metadata.bar2_labels) {
                    setChartHeight(chartRef.current.clientHeight + 160)
                    heightExpanded.current = true
                }
            } else {
                if (!(metadata.bar1_labels && metadata.bar2_labels)) {
                    setChartHeight(chartRef.current.clientHeight - 160)
                    heightExpanded.current = false
                }
            }
        }
    }, [chartRef, metadata])
    

    // senão arrisca dividir por zero e dar BO.
    if (!barMaximum || !lineMaximum) return

    return (
        <ChartContainer ref = {chartRef} clientHeight = {chartHeight}>

        <VictoryChart

            domainPadding={{ x: 5, y: 20 }}
            padding={ isMobile() ? 
                { top: 0, bottom: 50, left: 35, right: 35 }
                : 
                { top: 0, bottom: 50, left: 50, right: 50 }
            }
            containerComponent={
                <VictoryVoronoiContainer
                    style={{
                        touchAction: "auto"
                    }}
                    voronoiDimension="x"
                    radius={(chartRef.current && chartRef.current.clientHeight) ?? 500}
                    labels={() => " "}
                    labelComponent={
                        <VictoryTooltip 
                            flyoutComponent={ <CustomTooltipWrapper  />}
                         />
                    }
                />
            }
        >

            <VictoryGroup offset={4}>

                { metadata.bar1_labels && (
                    <VictoryStack>
                        {metadata.bar1_labels.map((_, index) => (
                            <VictoryBar
                                key={`bar1-${index}`}
                                data={data}
                                x="date"
                                y={(datum) => {

                                    return (datum.bar1 ? datum.bar1[index] / barMaximum : 0)
                                }}
                                style={{ data: { fill: metadata.bar1_colors[index], width: isTablet() ? 10 : 3 } }}
                            />
                        ))}
                    </VictoryStack>

                )}

                { metadata.bar2_labels && (
                    <VictoryStack>
                        {metadata.bar2_labels.map((_, index) => (
                            <VictoryBar
                                key={`bar2-${index}`}
                                data={data}
                                x="date"
                                y={(datum) => (datum.bar2 ? datum.bar2[index] / barMaximum : 0)}
                                style={{ data: { fill: metadata.bar2_colors[index], width: isTablet() ? 10 : 3 } }}
                            />
                        ))}
                    </VictoryStack>
                )}
            </VictoryGroup>

            {data.line1 && data.length > 1 && showLine &&
                <VictoryLine
                    data={data}
                    x="date"
                    y={(datum) => datum.line1 / lineMaximum}
                    style={{
                    data: {
                        stroke: metadata.line1_color,
                        strokeWidth: isTablet() ? 3 : 1,
                    },
                    }}
                />
            }
            {data.line1 && data.length == 1 && showLine &&
                <VictoryScatter
                    data={data}
                    x="date"
                    y={(datum) => datum.line1 / lineMaximum}
                    size={3}
                    style={{
                    data: {
                        fill: metadata.line1_color,
                    },
                    }}
                />
                    
            }
         
         <VictoryAxis dependentAxis
                label={isMobile() ? "" : "Testes"}
                key={1}
                tickValues={[0.25, 0.5, 0.75, 1.0]}
                tickFormat={(t) => Math.round(t * barMaximum)}
                style={{
                    axis: { stroke: "#cccccc" },
                    ticks: { stroke: "grey", size: 5 },
                    tickLabels: {
                        fill: darkMode ? darkTextTertiaryColor : undefined,
                        fontSize: isTablet() ? 14 : 10,
                        padding: isTablet() ? 1 : 5
                    },
                    axisLabel: { 
                        fontSize: isTablet() ? 16 : 10, 
                        padding: 35, 
                        fill: darkMode ? darkTextTertiaryColor : '#8E0011'
                    }
                }}
            />

            <VictoryAxis dependentAxis
                label = 'Tempo (min)'
                key = {2}
                orientation="right"
                tickValues = {[0.25, 0.5, 0.75, 1.0]}
                tickFormat = {t => {
                    const rounded = Math.floor(t * lineMaximum)
                    return rounded >= 1 ? rounded : ''
                }}
                style={{
                    axis: { stroke: "#cccccc" },
                    ticks: {
                        stroke: "grey",
                        size: 5,
                        strokeWidth: (tick) => tick.text !== '' ? 1 : 0,
                    },
                    tickLabels: {
                        fill: darkMode ? darkTextTertiaryColor : undefined,
                        fontSize: isTablet() ? 14 : 10,
                        padding: isTablet() ? 1 : 5
                    },
                    axisLabel: { 
                        fontSize: isTablet() ? 14 : 10, 
                        padding: 35, 
                        fill: darkMode ? darkTextTertiaryColor : '#8E0011'
                    }
                }}
            />

            <VictoryAxis
                tickFormat={(t, index, ticks) => getDateAxisTick(t, index, ticks)}
                style={{
                    axis: { stroke: "#cccccc" },
                    ticks: { stroke: "grey", size: 4 },
                    tickLabels : {
                        fontSize: isMobile() ? 14 : isTablet() ? 12 : 6, 
                        padding: 3,
                        angle: 360,
                        fill: darkMode ? darkTextTertiaryColor : undefined
                    }, 
                }} />

            
            { showLegend &&
                <VictoryLegend
                    x={legendX}
                    y={isMobile() ? 300 : isTablet() ? 310 : 285}
                    orientation="horizontal"
                    gutter={20}
                    itemsPerRow={itemsPerRow}
                    data={[
                        ...(metadata.bar1_labels ? metadata.bar1_labels.map((label, index) => ({
                            name: label,
                            symbol: { fill: metadata.bar1_colors[index] || metadata.bar1_colors[0] },
                            labels: { fontSize: legendFontSize }
                        })) : []),
                        ...(metadata.bar2_labels ? metadata.bar2_labels.map((label, index) => ({
                            name: label,
                            symbol: { fill: metadata.bar2_colors[index] || metadata.bar2_colors[0] },
                            labels: { fontSize: legendFontSize }
                        })) : []),
                        ...(metadata.line1_label ? [{
                            name: metadata.line1_label,
                            symbol: { fill: metadata.line1_colors },
                            labels: { fontSize: legendFontSize }
                        }] : []),
                    ]}
                    style={{ 
                        border: { stroke: darkMode ? darkTextTertiaryColor : 'black' },
                        labels: { fill: darkMode ? darkTextSecondaryColor : "black" },
                        title: {fontSize: 14}
                    }}
                />            
            }

        </VictoryChart>

        </ChartContainer>
    );
}