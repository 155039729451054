// Novembro, 2024
import React, { useEffect, useRef, useState } from 'react'
import IconColor from '../assets/highlighter_color.png'
import IconBW from '../assets/highlighter_bw.png'
import IconEraser from '../assets/eraser.png'
import { htmlToReact } from '../utils/HtmlToReact'
import session from '../controllers/Session'
import Separator from './Separator'
import { getAlphabetLetters, toastMsg } from '../utils/Utils'
import DeleteColored from '../assets/delete_alternative_color.png'
import DeleteBW from '../assets/delete_alternative_bw.png'
import DeleteColoredDarkMode from '../assets/delete_alternative_color_darkMode.png'
import DeleteBWDarkMode from '../assets/delete_alternative_bw_darkMode.png'
import { useSelector } from 'react-redux'
import TestScreenButton from './TestScreenButton'
import { useHotkeys } from 'react-hotkeys-hook'
import MoveForwardBttn from './MoveForwardBttn'
import styled from 'styled-components'
import { McqMetadata, TestArea, contentStyle } from './FlashcardsStyles'
import TestContainer from './TestContainer'
import RadioButton from '../custom/RadioButton'
import QuestionScreenHelperBttns from './QuestionScreenHelperBttns'
import HighlightableHTML from './HighlightableHTML'
import { ColumnCSS, RowCSS, SelectableText } from '../components/BasicComponents'



const McqQuestionText = styled.div`
    ${contentStyle}
    text-align: justify;
    ${SelectableText}
`


const McqAlternativeText = styled.p`
    ${contentStyle}
    margin: 0;
    padding: 0;
    ${SelectableText}
`

const Alternatives = styled.div`
    ${ColumnCSS}
    align-items: flex-start;
    width: 100%;
`

const Alternative = styled.div`
    ${RowCSS}
    margin-bottom: 0.2em;
    padding: 0.5em;
`

const CrossAlternative = styled.div`
    ${RowCSS}

    img {
        width: 1.25em;
        cursor: pointer;
    }

    margin-right: 0.5em;
`


export default function McqQuestionJSX({ test, moveEndQueue, goToPreviousQuestion, moveForward }) {
    const darkMode = useSelector(state => state.theme.darkModeOn)
    const [questionHTML, setQuestionHTML] = useState("")
    const [metadata, setMetadata] = useState("")
    const [isAlternativeCrossed, setIsDel] = useState(new Array(test.alternatives.length).fill(false))
    const [chosenAlternative, setChosenAlternative] = useState(-1)

    const highlightableRef = useRef()
    const [hasHighlights, setHasHighlights] = useState(false)
    const [highlightIsActive, setHighlightIsActive] = useState(false)


    useEffect(() => {
        if (!test) {
            console.log(session.session)
            setQuestionHTML(
                `Erro grave ao obter a pergunta.
                Por favor, tire um print e envie na DM!
                (${session.session[session.currentIndex].testID} / ${session.currentIndex})
            `)
        }

        setQuestionHTML(
            test.getQuestion()
        )

        setMetadata(`${test.institution} (${test.year})`)
        setChosenAlternative(-1)
    }, [test])



    function changeCrossedStatusForAlternative(index) {
        // Se a alternativa estava disponível, marca ela como 
        // uma das eliminadas. Do contrário, refaz ela como disponível.
        const newIsDel = Array.from(isAlternativeCrossed)
        
        if (newIsDel[index]) {
            newIsDel[index] = false;
        }
        else {
            newIsDel[index] = true;
            if (chosenAlternative === index) {
                // console.log("Changing to -1")
                setChosenAlternative(-1)
            }
        }

        setIsDel(newIsDel)
    }

    
    function changeChosenAlternative(newAlternative) {
        if (newAlternative < test.alternatives.length) {
            // console.log(isAlternativeCrossed)
            if ( isAlternativeCrossed[newAlternative]) {
                toastMsg("Você já excluiu essa alternativa! 🤔")
            }
            else {
                if (chosenAlternative === newAlternative) {
                    // console.log(`Changing from ${chosenAlternative} to -1`)
                    setChosenAlternative(-1)
                }
                else {
                    // console.log(`Changing from ${chosenAlternative} to ${newAlternative}`)
                    setChosenAlternative(newAlternative)
                }    
            }
        }
    }


    function tryToMoveForward() {

        if (chosenAlternative === -1) {
            toastMsg("Mas não vai escolher uma alternativa? 🙃")
        }
        else {
            const y = chosenAlternative+1
            session.mcqChosenAnswer = y
            moveForward()
        }
    }


    // Igual aos cards.
    useHotkeys('ctrl+z', goToPreviousQuestion)
    useHotkeys('shift+p', moveEndQueue)
    useHotkeys('enter', tryToMoveForward, [chosenAlternative])
    useHotkeys('space', tryToMoveForward, [chosenAlternative])


    // Específico
    useHotkeys('shift+g', () => setHighlightIsActive(!highlightIsActive), [highlightIsActive])


    // https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/setting-callback-dependencies
    useHotkeys('1', () => changeChosenAlternative(0), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('2', () => changeChosenAlternative(1), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('3', () => changeChosenAlternative(2), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('4', () => changeChosenAlternative(3), [chosenAlternative, isAlternativeCrossed])
    useHotkeys('5', () => changeChosenAlternative(4), [chosenAlternative, isAlternativeCrossed])


    function clearHighlights() {
        if (highlightableRef.current) {
            highlightableRef.current.removeAllHighlights();
        }
    }

    function handleHighlightChange(newHasHighlights) {
        setHasHighlights(newHasHighlights)
    }


    return (
        <>

        <TestArea>
            <TestContainer>

                    <McqMetadata>
                        <strong>{ metadata }</strong>
                    </McqMetadata>

                    { questionHTML && highlightIsActive &&
                        <HighlightableHTML
                            ref = {highlightableRef}

                            // Atenção, aqui nào pode ser React, mas HTML puro!
                            html = {questionHTML}
                            onHighlightChange={handleHighlightChange} />
                    }
                    {
                        questionHTML && !highlightIsActive &&
                        <McqQuestionText>
                            {/* Diferente de cima, aqui precisa ser React, e não html. */}
                            { htmlToReact( questionHTML ) }
                        </McqQuestionText>
                    }
                        
                    <Separator />

                    <Alternatives>
                        {
                            test.alternatives.map((alternative, index) => {
                                const alternativeJSX = (<>{getAlphabetLetters(index+1)[index]}) {htmlToReact(alternative)}</>)
                                return (
                                    <Alternative>
                                        <CrossAlternative onClick={() => changeCrossedStatusForAlternative(index)}>
                                            <img src={isAlternativeCrossed[index] ? (darkMode ? DeleteColoredDarkMode : DeleteColored) : (darkMode ? DeleteBWDarkMode : DeleteBW)} />
                                        </CrossAlternative>
                                        
                                        <RadioButton
                                            text = {
                                                <McqAlternativeText>
                                                    { isAlternativeCrossed[index] ? <del>{alternativeJSX}</del> : <>{alternativeJSX}</> }
                                                </McqAlternativeText>
                                            }

                                            action = {() => changeChosenAlternative(index)}
                                        
                                            clicked = {!isAlternativeCrossed[index] && chosenAlternative === index}

                                            disabled = {isAlternativeCrossed[index]}
                                            />

                                    </Alternative>
                                )
                            })
                        }
                    </Alternatives>

                </TestContainer>

                <QuestionScreenHelperBttns
                    goToPreviousQuestion = {goToPreviousQuestion}
                    moveEndQueue = {moveEndQueue}
                    otherBttns = {
                        <>
                        { !highlightIsActive &&
                            <TestScreenButton
                                text = {'Grifar'}
                                action = {() => setHighlightIsActive(true)}
                                icon = {IconColor} />
                        }
                        { highlightIsActive && !hasHighlights &&
                            <TestScreenButton
                                text = {'Desativar grifos'}
                                action = {() => setHighlightIsActive(false)}
                                icon = {IconBW} />                        
                        }
                        { highlightIsActive && hasHighlights &&
                            <TestScreenButton
                                text = {'Limpar grifos'}
                                action = {clearHighlights}
                                icon = {IconEraser} />                
                        }
                        </>
                    }
                    />

                
                <MoveForwardBttn
                    moveForward = {tryToMoveForward} />

        </TestArea>
        </>
    )
}