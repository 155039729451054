import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import styled from 'styled-components'
import Session, { KEYS } from '../controllers/Session'
import SessionBuilder from '../controllers/SessionBuilder'
import { sleep } from '../utils/Utils'
import GoToTopButton from './GoToTopButton'
import SeeMoreButton from './SeeMoreButton'

import CardListElement from './CardListElement'
import OslerButton from './OslerButton'
import { RowCSS } from './BasicComponents'
import { PredefinedSessionConfig, SORT_MODES } from '../controllers/SessionConfig'



const CardListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    @media (max-width: 500px) {
        // padding: 0.5em;
    }

    padding: 2em;
`


const CardListBttns = styled.div`
    ${RowCSS}
    width: 50%;
    justify-content: space-around;
    padding-bottom: 4em;
`

export default function CardList({
        showLike = true, showBury = true, showFeedback = true, 
        showPersonalNote = true, showJournal = true,
        chunkSize = 25,
        sessionBuilt = false,
        testType, testsIDs, style}) {
    

    const dispatch = useDispatch()
    const [loadedTests, setLoadedTests] = useState([])
    const [loading, setLoading] = useState(false)


    const sessionPrepared = useRef(false)


    useEffect(() => {
        async function prepareSession() {
            console.log('CardList: construindo a sessão...')
            setLoadingStatus(true)
            

            const config = PredefinedSessionConfig.create({
                ordering: SORT_MODES.SORT,
                detachCousins: true
            })
    

            SessionBuilder.start(
                testType,
                'predefined',
                'consult',
                testsIDs,
                config,
                false
            )

            tryToLoad()
        }
        

        // Evita que entremos em um loop infinito, onde essa tela altera isLoading, que é
        // variável de estado do pai dela, logo é renderizado aqui de novo, etc.
        // if (!sessionPrepared.current) {
            // sessionPrepared.current = true
            if (!sessionBuilt) {
                prepareSession()
            }
            else {
                // presume-se que já existe, i.e., que session builder já foi executado
                tryToLoad()
            }
        // }
    }, [])



    function setLoadingStatus(bool) {
        dispatch(setIsLoading(bool))
        setLoading(bool)
    }



    async function tryToLoad() {
        // Precisamos esperar a Session terminar de baixar todas as questões esperadas
        // até agora -- que são todas que já temos, e o próximo chunk.
        const until = loadedTests.length + chunkSize

        console.log('CardList: verificando se já baixou até o índice ' + until)

        if ( Session.isDownloading( until ) ) {
            console.log('CardList: ainda está baixando, vamos colocar essa mesma função como listener')
            setLoadingStatus(true)
            Session.addTemporaryListener(tryToLoad)
        }
        else {
            console.log('CardList: acabou de baixar!')
            loadScreen(until)
        }
    }



    async function loadScreen(until) {
         // Sabendo que os testes já foram baixados, colocamos
        // eles na lista do que será exibido na nossa tela.
        let previousQuantity = loadedTests.length
        let questions = [...loadedTests]

        for(let i = previousQuantity; i < Math.min(until, Session.session.length); i++) {
            const test = Session.session[i]
            
            console.log(test)

            if (test.status == KEYS.downloaded) {
                questions.push(test.data)
            }
            else {
                console.log('CardList: erro, tentamos carregar um teste que não estava pronto.')
                console.log(test.testID)
                console.log(test.status)
            }
        }

        if (loadedTests.length > chunkSize) {
            // A ideia é facilitar a visualização, mostraando que mais
            // coiza carregou. O if é para garantir que só após o usuário
            // clicar no botão, e o sleep para garnatir que já atualizou o state.
            //
            // Tentar colocar em um useEffect(), mas não deu certo.
            await sleep(200)
            window.scrollBy(0, 300)
        }

        console.log('CardList: carregando a tela...')
        setLoadedTests(questions)
        setLoadingStatus(false)
    }



    async function loadMoreTests() {
        // Quando Session() é criada, ela espontameante baixa 10 primeiros testes.
        //
        // Quando o usuário clica em 'Ver mais', precisamos baixar e mostrar mais N = 25. Mas, para
        // agilizar os próximos cliques ao botão, já baixamos 2N neste primeiro clique.
        //
        // Nos subsequentes, baixamos N.
        
        //

        // dos quais nós exibimos os primeiros, usualmente, 25.
        //
        // Quando o usuário clica em 'Ver mais', cairemos aqui. Exibimos as próximas,
        // mas já adiantamos o download por trás, para ser mais rápido.
        const additionalTests = loadedTests.length < chunkSize ? chunkSize*2 : chunkSize
        const howManyToDowlonad = loadedTests.length + additionalTests

        console.log(`CardList: baixando mais ${additionalTests} testes`)

        Session.ensureNextTests(loadedTests.length, additionalTests)
        tryToLoad()
    }


    const anchor = useRef()


    console.log(loadedTests)
    console.log(Session.session)

    return (
        <CardListContainer style = {style}>
            <div ref = {anchor} />

            { loadedTests.length > 0 && (
                <>
                    { loadedTests.map((element, index) => {
                        return (
                            <CardListElement
                                // Fundamental ter a key para evitar novas renderizações ao
                                // mostrar mais
                                key={element.testID} 
                                index = {index}
                                test = {element}
                                like = {showLike}
                                bury = {showBury}
                                feedback = {showFeedback}
                                postIt = {showPersonalNote}
                                journal = {showJournal}
                                testType = {testType}
                                />
                        )
                    })}

                    <CardListBttns>
                        <OslerButton
                            color = 'grey'
                            text = 'Voltar ao topo'
                            onClick = {() => {
                                // antigamente era um window.scrollTo
                                // mas de algum modo o height e overflow-y de <ScrollableContent>
                                // cagaram com isso
                                anchor.current.scrollIntoView({ behavior: 'smooth' });
                            }} />

                        {
                            // Não podemos comparar com session.sessionSize, devido à clozeFusion()
                            (loadedTests.length < Session.session.length) &&
                                <OslerButton
                                    color={'grey'}
                                    text = 'Carregar mais'
                                    onClick = {loadMoreTests} />
                        }
                    </CardListBttns>

                </>
            )}

        </CardListContainer>
    );
}