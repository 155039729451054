import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

import Checkbox from './Checkbox'
import ExpandCollapse from './ExpandCollapse'
import { TreeFilters } from './Tree.js'
import { ColumnCSS, RowCSS } from '../components/BasicComponents.js'
import Dumbbell from './../assets/tree/dumbbell.png'
import Notebook from './../assets/tree/notebook.png'
import Books from './../assets/books.png'
import Balloons from './../assets/tree/balloons.png'
import SandboxIcon from './../assets/sandbox.png'
import Rocket from './../assets/rocket.png'
import { motion, AnimatePresence } from 'framer-motion'
import EmojiSadIcon from './../assets/sad.png'

const Container = styled(motion.div)`
    display: flex;    
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: calc(100% - ${({ depth }) => depth * 2}em);
    margin-left: ${({ depth }) => depth * 2}em;
    margin-bottom: 0em;

    ${({ depth, userIsSearching }) => {
        if (userIsSearching) {
            return `
                height: ${depth === 0 ? '2em' : '1em'};
                padding: ${depth === 0 ? '0.5em 1em' : '1.5em 1em'};
                margin-top: ${depth === 0 ? '1em' : '0'};
            `
        } else {
            return `
                height: ${depth === 0 ? '2em' : '1em'};
                padding: ${depth === 0 ? '2em 1em' : '1.5em 1em'};
            `
        }
    }}

    border-radius: 0.5em;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${props => props.theme.darkMode ? 'rgb(200, 200, 200)' : 'black'};
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

    ${props => {
        if (props.searched) {
            return `
                background-color: ${props.theme.darkMode ? 
                    'rgb(41, 41, 41, 1)' : 
                    'rgba(39, 120, 196, 0.3)'};
                &:hover {
                    background-color: ${props.theme.darkMode ? 
                        'rgba(39, 120, 196, 0.25)' : 
                        'rgba(39, 120, 196, 0.3)'};
                }
            `
        } else if (props.userIsSearching) {
            return `
                background-color: transparent;
                &:hover {
                    background-color: transparent;
                }
            `
        } else {
            return `
                background-color: transparent;
                &:hover {
                    background-color: ${props.theme.darkMode ? 
                        'rgb(41, 41, 41, 1)' : 
                        'rgba(0, 0, 0, 0.05)'};
                }
            `
        }
    }}

    @media (max-width: 1200px) and (min-width: 769px) {
        width: calc(100% - ${({ depth }) => depth * 1.5}em);
        margin-left: ${({ depth }) => depth * 1}em;
        padding: ${props => props.depth === 0 ? '1.5em 0.8em' : '1.2em 0.8em'};
    }


    @media (max-width: 768px) {
        flex-direction: ${props => props.depth === 0 ? 'column' : 'row'};
        align-items: ${props => props.depth === 0 ? 'flex-start' : 'center'};
        height: auto;
        padding: ${props => props.depth === 0 ? '0.4em' : '0.5em 0.2em'};
        width: calc(96% - ${({ depth }) => depth * 0.75}em);
        margin-left: ${({ depth }) => depth * 0.55}em;
        margin-bottom: 0.5em;
        gap: ${props => props.depth === 0 ? '0.2em' : '0'};
        border-radius: 0.5em;
    }
`

const Left = styled.div`
    ${RowCSS}
    width: 80%;
    height: 100%;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 70%;
        align-items: center;
    }

    @media (max-width: 768px) {
        width: ${props => props.depth === 0 ? '80%' : '65%'};
        align-items: center;
    }
`

const Title = styled.p`
    font-size: 1em;
    margin: 0 0 0 0.5em;
    padding: 0;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'inherit'};


    ${props => props.depth === 0 && `
        font-weight: bold;
    `}

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.7em;
        width: 70%;
    }

    @media (max-width: 768px) {
        font-size: 0.65em !important;
        width: 70%;
    }
`

const Right = styled.div`
    ${RowCSS}
    justify-content: flex-end;

    width: 40%;
    height: 100%;
    gap: 2em;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 30%;
        justify-content: flex-end;
        position: relative;
    }

    @media (max-width: 768px) {
        width: ${props => props.depth === 0 ? '100%' : '35%'};
        flex-direction: column;
        gap: 0.5em;
        margin-left: 0;
        padding: 0;
        
        ${props => props.depth === 0 && `
            align-items: flex-start;
            padding-left: 2.5em;
        `}
    }
`

const Status = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: ${props => props.depth === 0 ? '14em' : 'auto'};

    @media (max-width: 1200px) and (min-width: 769px) {
        width: ${props => props.depth === 0 ? '12em' : 'auto'};
        justify-content: flex-end;
        align-items: flex-end;
    }

    @media (max-width: 768px) {
        flex-direction: row;
        align-items: center;
        width: 100%;
        gap: 0em;
        justify-content: flex-end;
        
        ${props => props.depth === 0 && `
            justify-content: flex-start;

            
            img {
                order: 1;
                margin-right: 0.4em;
            }
            
            div {
                order: 2;
                margin: 0;
            }
            
            p {
                order: 3;
                margin: 0;
                padding: 0;
            }
        `}
    }
`

const StatusContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    width: 100%;
    gap: 2em;

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: ${props => props.depth === 0 ? '0.9em' : '3.7em'};
    }

    @media (max-width: 768px) {
        flex-direction: ${props => props.depth === 0 ? 'column' : 'row'};
        gap: ${props => props.depth === 0 ? '1em' : '0.2em'};
        width: 100%;
        
        ${props => props.depth === 0 && `
            > div:first-child {
                order: 1;
            }
            > div:last-child {
                order: 2;
            }
        `}
    }
`

const StatusColumn = styled.div`
    width: 6em;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 4em;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    @media (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        
        ${props => props.depth === 0 && `
            display: flex;
            flex-direction: column;
            gap: 0.5em;
        `}
    }
`

const Info = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 6em;

    @media (max-width: 1200px) and (min-width: 769px) {
        width: auto;
        justify-content: flex-end;
        padding: 0;
    }

    @media (max-width: 768px) {
        width: auto;
        gap: 0;
        
        ${props => props.depth === 0 && `
            justify-content: flex-start;
            flex: 1;
        `}
    }
`

const Icon = styled.img`
    width: 1.25em;
    margin-right: 0.25em;
    filter: ${props => props.theme.darkMode ? 'brightness(0.9)' : 'none'};

    @media (max-width: 1200px) and (min-width: 769px) {
        width: 0.8em;
    }

    @media (max-width: 768px) {
        width: 1em;
    }
`

const Number = styled.p`
    font-size: 0.8em;
    margin: 0;
    color: ${props => {
        if (props.theme.darkMode) {
            // Adjust colors for dark mode
            if (props.color === 'gray') return 'rgb(180, 180, 180)';
            if (props.color === '#8E0011') return '#ff9999';
            if (props.color === '#006400') return '#90EE90';
            if (props.color === '#2778C4') return '#87CEEB';
            return props.color;
        }
        return props.color;
    }} !important;
    width: fit-content;
    text-align: right;
    padding-right: 0;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: ${props => props.depth === 0 ? '0.6em' : '0.6em'};
        text-align: right;
        width: auto;
        white-space: nowrap;
    }

    @media (max-width: 768px) {
        font-size: 0.5em !important;
        width: fit-content;
        
        ${props => props.depth === 0 && `
            margin: 0;
            text-align: right;
            margin-right: 0.2em;
        `}
    }
`

const Label = styled.p`
    font-size: 0.75em;
    margin: 0;
    margin-left: 0.5em;
    color: ${props => {
        if (props.theme.darkMode) {
            // Adjust colors for dark mode
            if (props.color === 'gray') return 'rgb(180, 180, 180)';
            if (props.color === '#8E0011') return '#ff9999';
            if (props.color === '#006400') return '#90EE90';
            if (props.color === '#2778C4') return '#87CEEB';
            return props.color;
        }
        return props.color;
    }} !important;
    white-space: nowrap;
    min-width: 8em;
    text-align: left;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.5em;
        width: auto;
        margin-left: 0em;
    }

    @media (max-width: 768px) {
        font-size: ${props => props.depth === 0 ? '0.65em' : '0.65em'} !important;
        margin: 0;
        min-width: unset;
        white-space: normal;
        flex: 1;
    }
`


export default function TreeNodeJSX({ node, mode, testType, userIsSearching, triggerTreeRender, visible, onNodeChecked, config}) {

    const [clickable, setClickable] = useState(false)
    const [status, setStatus] = useState([])

    useEffect(() => {
        if (!node || !node.info) return
        
        // Verifica se o nó é clicável
        const hasAvailableTests = node.info && !config.removeNewTests && node.info.availableTests > 0
        const hasPendingReviews = node.info && !config.removePendingReviews && node.info.pendingReviews > 0
        const hasRelevantTests = hasAvailableTests || hasPendingReviews
        const notTestMode = ['playground-mode', 'consult-mode', 'clear-history'].includes(mode)
        
        setClickable(hasRelevantTests || notTestMode)
        
        const newStatus = getStatus(mode, testType, node.info, config)
        setStatus(newStatus)
        
    }, [node, mode, testType, config])






    function getStatus(mode, testType, info, config) {
        const gray = 'gray'
        const red = '#8E0011'
        const green = '#006400' 
        const blue = '#2778C4'

        // if (!info) {
        
        // }
        // if (!info) {
        //     console.log('\n\n\n')
        //     console.log(node.title)
        //     console.log(node)
        //     console.log(info)
        //     console.log('getStatus called:', { mode, testType, info, config })

        //     return [{
        //         icon: EmojiSadIcon,
        //         label: 'não há testes para esses filtros',
        //         hideNumber: true,
        //         color: gray
        //     }]
        // }


        function getFilteredSum(...counts) {
            return counts.reduce((sum, curr) => sum + (curr || 0), 0)
        }
        

        // For consult or playground modes
        if (mode === 'consult-mode' || mode === 'playground-mode') {
            const total = getFilteredSum(
                config.removeNewTests ? 0 : info.availableTests,
                config.removePendingReviews ? 0 : info.pendingReviews,
                config.removeFutureReviews ? 0 : info.futureReviews,
                config.removeSolved ? 0 : info.solved
            )
            
            return [{
                icon: mode === 'consult-mode' ? Books : SandboxIcon,
                label: `${testType === 'Residencia' ? 'questões' : 'flashcards'} do tema`,
                number: total,
                color: gray
            }]
        }
        
        // For clear-history mode
        if (mode === 'clear-history') {
            const seenTotal = getFilteredSum(
                config.removePendingReviews ? 0 : info.pendingReviews,
                config.removeFutureReviews ? 0 : info.futureReviews,
                config.removeSolved ? 0 : info.solved
            )
            
            return [{
                icon: Dumbbell,
                label: `${testType === 'Residencia' ? 'questões' : 'flashcards'} já vistos`,
                number: seenTotal,
                color: red
            }]
        }
        
        if (mode === 'test-mode') {
            const availableCount = config.removeNewTests ? 0 : info.availableTests
            const reviewsCount = getFilteredSum(
                config.removePendingReviews ? 0 : info.pendingReviews,
                config.removeFutureReviews ? 0 : info.futureReviews
            )
            const solvedCount = config.removeSolved ? 0 : info.solved
            
            if (availableCount === 0 && reviewsCount === 0 && solvedCount === 0) {
                return [{
                    icon: Balloons,
                    label: 'você já fez tudo!',
                    hideNumber: true,  // New property to hide the number
                    color: green
                }]
            }
            
            let status = []
            
            // Add reviews if they exist and are not filtered
            if (reviewsCount > 0) {
                const pendingOnly = !config.removePendingReviews && config.removeFutureReviews
                const futureOnly = !config.removeFutureReviews && config.removePendingReviews

                const label = pendingOnly ? 'revisões pendentes' : 
                             futureOnly ? 'revisões futuras' : 
                             'revisões pendentes e futuras';
                
                status.push({
                    icon: Dumbbell,
                    label: label,
                    number: reviewsCount,
                    color: red
                })
            }
            
            // Add available tests if they exist and are not filtered
            if (availableCount > 0) {
                status.push({
                    icon: Notebook,
                    label: `${testType === 'Residencia' ? 'questões novas' : 'flashcards novos'}`,
                    number: availableCount,
                    color: gray
                })
            }
            
            // Add solved tests if they exist and are not filtered
            if (solvedCount > 0) {
                status.push({
                    icon: Rocket, 
                    label: 'questões resolvidas',
                    number: solvedCount,
                    color: blue
                })
            }
            
            return status
        }
        
        return []
    }
    

    function checkboxClicked() {
        node.changeIsChecked()
        if (onNodeChecked) {
            onNodeChecked()
        }
        if (triggerTreeRender) {
            triggerTreeRender()
        }

    }


    function collapseExpandClicked() {

        node.changeIsExpanded()
        if (triggerTreeRender) {
            console.log('chamando props')
            triggerTreeRender()
        }
    }


    function textAreaClicked() {
        // Se o usuário clica sobre o texto, nós expandimos/colapsamos -- desde que não haja 
        // pesquisa ativa e existam filhos.
        //
        // Do contrário, se não há filhos (i.e., é último nó) ou existe pesquisa ativa,
        // clicar sobre o título ativa o checkbox.
        // if (!userIsSearching && node.children) {

        if (node.children) {

            collapseExpandClicked()
        }
        else {

            if (clickable) {

                checkboxClicked()
            }
        }
    }


    function renderStatus() {
        const formatNumber = num => new Intl.NumberFormat('pt-BR').format(num)
        
        const getStatusType = (item) => {
            if (item.label?.includes('revisões')) return 'reviews';
            if (item.label?.includes('flashcards') || item.label?.includes('questões novas')) return 'newTests';
            return item.color === 'gray' ? 'newTests' : 'reviews';  // Fallback based on color
        }
    
        // Separe os status em dois grupos
        const reviews = status.filter(item => getStatusType(item) === 'reviews');
        const newTests = status.filter(item => getStatusType(item) === 'newTests');
    
        return (
            <StatusContainer depth={node.depth}>
                {/* Coluna de revisões */}
                <StatusColumn type="reviews">
                    {reviews.map((item, index) => (
                        <Status key={index} type="reviews">
                            {!item.empty && (
                                <>
                                    <Info type="reviews">
                                        {node.depth === 0 && <Icon src={item.icon} />}
                                        {!item.hideNumber && (
                                            <Number 
                                                depth={node.depth} 
                                                color={item.color}
                                                type="reviews"
                                            >
                                                {formatNumber(item.number)}
                                            </Number>
                                        )}
                                    </Info>
                                    {node.depth === 0 && 
                                        <Label 
                                            color={item.color}
                                            type="reviews"
                                        >
                                            {item.label}
                                        </Label>
                                    }
                                </>
                            )}
                        </Status>
                    ))}
                </StatusColumn>
    
                    <StatusColumn type="newTests">
                    {newTests.map((item, index) => (
                        <Status key={index} type="newTests">
                            {!item.empty && (
                                <>
                                    <Info type="newTests">
                                        {node.depth === 0 && <Icon src={item.icon} />}
                                        {!item.hideNumber && (
                                            <Number 
                                                depth={node.depth} 
                                                color={item.color}
                                                type="newTests"
                                            >
                                                {formatNumber(item.number)}
                                            </Number>
                                        )}
                                    </Info>
                                    {node.depth === 0 && 
                                        <Label 
                                            color={item.color}
                                            type="newTests"
                                        >
                                            {item.label}
                                        </Label>
                                    }
                                </>
                            )}
                        </Status>
                    ))}
                </StatusColumn>
                
                
            </StatusContainer>
        )
    }


    if (!node) return
    return (
        <AnimatePresence>
            {visible && (

                <Container
                    key={node.id}
                    depth={node.depth}
                    onClick={textAreaClicked}
                    searched={node.searched}
                    userIsSearching={userIsSearching}
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    transition={{
                        opacity: { duration: 0.3 },
                        scale: { duration: 0.2 },
                        enter: { duration: 0.2, ease: 'easeOut' },
                        exit: { duration: 0.1, ease: 'easeIn' },
                        x: { duration: 0.1, ease: 'easeOut' } 
                    }}
                    whileHover={{ x: '0.8em' }}
                >
                    <Left hasChildren={node.children}>
                        {node.children && !userIsSearching && (
                            <ExpandCollapse
                                expanded={node.isExpanded}
                                action={(e) => {
                                    e.stopPropagation()
                                    collapseExpandClicked()
                                }}
                            />
                        )}
                        { !node.children && !userIsSearching && 
                            <ExpandCollapse empty = {true} />
                        }
                        {clickable && (
                            <Checkbox
                                checked={node.isChecked}
                                mildlyChecked={node.isMildlyChecked}
                                action={(e) => {
                                    e.stopPropagation()
                                    checkboxClicked()
                                }}
                            />
                        )}
                        <Title depth={node.depth}>{node.title}</Title>
                    </Left>
                    <Right depth={node.depth}>
                        {renderStatus()}
                    </Right>
                </Container>
            )}
        </AnimatePresence>
    )
}