import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { ResponsiveContainer, Tooltip, XAxis, YAxis, ScatterChart, Scatter, Rectangle } from "recharts";
import * as d3 from 'd3';
import { statisticsDateToReadable } from '../utils/Utils';
import StatisticsExplanationCard from "./StatisticsExplanationCard";
import { isMobile, isTablet } from '../utils/BootstrapUtils';
import { useSelector } from 'react-redux';
import { darkBackgroundPrimaryColor, darkBackgroundSecondaryColor, darkTextPrimaryColor, darkTextSecondaryColor, darkTextTertiaryColor, textWhiteBlack } from '../tests/FlashcardsStyles';
import { dateDashStrToDate } from './OslerDateChart';
import { getDailyUseTestTypes } from "./DailyUseMethod";
import IsLoading from "../main/IsLoading";

const HeatMapScreen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
`;


const HeatMapContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 1em 0;

    // @media (max-width: 900px) {
    //     padding: 2em 0 2em 0;
    //     overflow-y: scroll;
    // }
`;


const RotateWrapper = styled.div`
    width: 100%;

    @media (max-width: 500px) {
        height: 95vw;
        width: 105vh;
        transform: rotate(-90deg);
        margin-top: 35vh;
        margin-bottom: 25vh;

    }
`


const TooltipContainer = styled.div`
    padding: 1em 2em 1em 2em;
    min-width: 200px;
    border-radius: 0.5em;


    ${props => props.theme.darkMode ? `
        background-color: ${darkBackgroundPrimaryColor};
    `:`
        background-color: white;    
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    `}
`


const TooltipTitle = styled.div`
    font-weight: bold;
    text-align: center;
    margin: 0;

    ${textWhiteBlack};
`

const HeatMapTooltipP = styled.p`
    ${textWhiteBlack};
    margin-top: 0.5em;
`



export default function OslerHeatmap({ exhibitionType, selector }) {
    const [data, setData] = useState(false);
    const [startDate, setStartDate] = useState(false);
    const today = new Date();
    const darkMode = useSelector(state => state.theme.darkModeOn);
    const [necessaryData, setNecessaryData] = useState(false);
    const user = useSelector(state => state.user.data);

    const darkColorRange = {
        'Flashcards' : [darkBackgroundSecondaryColor, 'rgb(120, 80, 100)', 'rgb(180, 100, 120)', 'rgb(220, 120, 140)', '#e33b50'],
        'Residencia' : [darkBackgroundSecondaryColor, 'rgb(60, 80, 120)', 'rgb(60, 100, 180)', 'rgb(60, 130, 220)', 'rgb(92, 163, 255)'],
        'Ambos'      : [darkBackgroundSecondaryColor, 'rgb(100, 60, 90)', 'rgb(140, 60, 120)', 'rgb(180, 60, 150)', 'rgb(255, 108, 216)']
    }

    const colorRange = {
        'Flashcards' : ['white', '#e4a6ad', '#cb6d79', '#ac3f4d', '#8f2936', '#781f2a'],
        'Residencia' : ['white', '#7ea0ce', '#5985c0', '#386094', '#264063'],
        'Ambos'      : ['white', '#b1a3bd', '#92768c', '#724f70', '#5b354a', '#9e5f8d']
    }

    const whichRange = darkMode ? darkColorRange : colorRange

    function mapColor(value, range) {

        const colorScale = d3.scaleLinear()
            .domain([0, 100, 250, 500, 1000, 2000])
            .range(range)

        return colorScale(value)
    }


    useEffect(() => {
        function getWeekAndDayNumber (dateStr) {
            // Me retorne o número da semana do ano ao qual pertence, de 0 a 52.
            // E me retorne o número de dia, sendo 0 = sábado, 1 = sexta, 2 = quinta, ..., 7 = domingo
            
            const dt = dateDashStrToDate(dateStr)
            
            // Obter o número da semana do ano. A subtração dá a diferença em milissegundos,
            // e aí corrigimos para que seja em dias. Ou seja, a data corresponde ao, digamos
            // 223º dia do ano.
            // 
            // Então, adicionamos o número de dias a um objeto 
            const primeiroDiaAno = new Date(dt.getFullYear(), 0, 1)
            const diaAno = ((dt - primeiroDiaAno) / (24 * 60 * 60 * 1000)) + 1

            // Se a semana começa em uma quarta, hoje é o 6º dia do ano (estamos na seg seguinte),
            // o usuário está estudando na primeira ou na segunda semana do ano?
            // Filosofias à parte, queremos ele na próxima coluna do heatmap, então precisamos
            // corrigir para o dia da semana iniciado.
            //
            // Logo, teremos 6 + 2 = 8, e 8 / 7 = 1.14, então ele está na segunda semana do ano.
            const numeroSemana = Math.ceil((diaAno + primeiroDiaAno.getDay()) / 7)
            

            // Para o Date,
            //      domingo = 0,
            //      segunda = 1, ...,
            //      sábado = 6.
            //
            // E os weeksDays são: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', ...]
            //
            // Então, parece lógico que o número do dia da semana seja o dt.getDay().
            // Porém, nós queremos domingo no TOPO do heatmap, e não na base.
            // Então, precisamos que domingo = 7, segunda = 6, ..., sábado = 1.            
            // Logo...

            let numeroDiaSemana = 6 - dt.getDay()

            // if (isTablet()) {
            //     return [ dt.getDay() + 1, 52 - numeroSemana]
            // }
            // else {
                return [ numeroSemana, numeroDiaSemana ]
            // }
        }



        function adjustData(thisYearData, newsKeys, reviewsKeys) {
            return thisYearData.map(item => {
                let novos = 0
                let revisoes = 0
            
                let useData = item['data']['Total'] || false;
                if (useData) {
                    for (let key of newsKeys) {
                        novos += useData[key] || 0
                    }

                    for (let key of reviewsKeys) {
                        revisoes += useData[key] || 0
                    }
                }
                
                const [X, Y] = getWeekAndDayNumber(item.date_str)

                const count = novos + revisoes

                return { 
                    count : count,
                    date: item.date_str,
                    dayY: Y,
                    weekX: X,
                } 
            })
        }


        async function fetchData(numberDays) {
            return await getDailyUseTestTypes(user.id, numberDays)
        }


        async function start() {
            const firstDayOfYear = new Date(today.getFullYear(), 0, 1)
            const numberDays = Math.ceil((today - firstDayOfYear) / (1000 * 60 * 60 * 24))

            const dailyData = await fetchData(numberDays)

            if (dailyData) {
                // newsKeys e reviewsKeys, como de LastDaysChart, poderia ser generalizado, não é?

                const adjusted = {
                    'Flashcards' : adjustData(
                                    dailyData['Flashcards'], 
                                    ['newAgain', 'newEasy', 'newGood', 'newHard'],
                                    ['learningAgain', 'learningEasy', 'learningGood', 'learningHard', 'lapsedAgain', 'lapsedEasy', 'lapsedGood', 'lapsedHard']
                                ),
    
                    'Residencia' : adjustData(
                                    dailyData['Residencia'],
                                    ['newRightConfident', 'newRightGuess', 'newWrongConcept', 'newWrongDistraction'],
                                    ['reviewRightConfident', 'reviewRightGuess', 'reviewWrongConcept', 'reviewWrongDistraction']
                                )
                }
            
                // Calcular o timestamp `n` dias atrás
                const nDaysAgoTimestamp = today.getTime() - numberDays * 24 * 60 * 60 * 1000; // `n` dias em milissegundos

    
                // Criar uma nova data `n` dias atrás
                setStartDate(new Date(nDaysAgoTimestamp))
                setNecessaryData(adjusted)
            } else {
                setData(false)
            }
        }

        start()
    }, [])


    useEffect(() => {
        if (necessaryData && exhibitionType) {
            if (exhibitionType === 'Flashcards' || exhibitionType === 'Residencia') {
                const colored = necessaryData[exhibitionType].map(item => ({
                    ...item,
                    color: mapColor(item.count, whichRange[exhibitionType])
                }))

                setData(colored)
            } else {
                const colored = necessaryData['Flashcards'].map((item, index) => {
                    const residItem = necessaryData['Residencia'][index]

                    return {
                        count : item.count + residItem.count,
                        date: item.date,
                        dayY: item.dayY,
                        weekX: item.weekX,
                        color: mapColor(item.count + residItem.count, whichRange['Ambos'])
                    }
                })

                setData(colored)
            }
        }
    }, [necessaryData, exhibitionType])


    const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb']
    const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
    const reversedMonths = [...months].reverse()


    function getTooltipLabel(datum) {
        let label = ''

        if (exhibitionType === 'Flashcards') {
            label = `cards`
        } else if (exhibitionType === 'Residencia') {
            label = `questões`
        } else {
            label = `testes`
        }

        return label
    }

    // Aproximação das semanas centrais de cada mês
    function getFirstWeekOfMonths() {
        const currentYear = new Date().getFullYear();
        const firstWeekOfMonths = [];
        
        for (let month = 0; month < 12; month++) {
            const date = new Date(currentYear, month, 1);
            const firstDayOfYear = new Date(currentYear, 0, 1);
            const daysSinceFirstDay = Math.floor((date - firstDayOfYear) / (24 * 60 * 60 * 1000));
            const weekNumber = Math.ceil((daysSinceFirstDay + firstDayOfYear.getDay() + 1) / 7);
            firstWeekOfMonths.push(weekNumber);
        }
        
        return firstWeekOfMonths;
    }

    const firstWeekOfMonths = useMemo(() => getFirstWeekOfMonths(), []);

    const graphLabelsColor = darkMode ? darkTextSecondaryColor : 'black'


    if (!necessaryData || !data || !exhibitionType) {
        <IsLoading />
    }
    return (
            <HeatMapScreen>
                <StatisticsExplanationCard
                    emoji='🎨'
                    highlight='Nerds, uni-vos'
                    text='Heatmaps são uma técnica de visualização de dados onde a intensidade da cor é proporcional ao valor. Isso permite explorar rapidamente grandes conjuntos de dados — como o número de cards que você fez diariamente ao longo do ano!'
                />
                {selector}

                <RotateWrapper>
                    <HeatMapContainer>
                        <ResponsiveContainer width="100%" height = {isTablet() ? 350 : 400}>
                            <ScatterChart
                                margin={{ top: 20, right: 20, bottom: 10, left: 40 }}
                            >
                                <XAxis
                                    dataKey="weekX"
                                    type="number"
                                    stroke  = {graphLabelsColor}
                                    domain={[1, 52]}
                                    ticks={firstWeekOfMonths}
                                    tickFormatter={(weekNumber) => {
                                        const months = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
                                        const monthIndex = firstWeekOfMonths.indexOf(weekNumber);
                                        return monthIndex !== -1 ? months[monthIndex] : '';
                                    }}
                                    padding={{ left: 14, right: 0 }}
                                    
                                />

                                <YAxis
                                    dataKey="dayY"
                                    type="number"

                                    domain = {[-1, 6]}
                                    ticks={[0, 1, 2, 3, 4, 5, 6]}
                                    tickFormatter={value => {
                                        return weekDays[isTablet() ? value : 6 - value]
                                    }}
                                    tick={{ fill: graphLabelsColor }}
                                    tickLine={false}  // Isso remove as "barrinhas"
                                    axisLine={false}  // Isso remove a linha principal do eixo, se desejar
                                />

                                <Tooltip
                                    content={({ payload }) => {
                                        if (payload && payload.length > 0) {
                                            const data = payload[0].payload;
                                            return (
                                                <TooltipContainer>
                                                    <TooltipTitle>{statisticsDateToReadable(data.date)}</TooltipTitle>
                                                    <HeatMapTooltipP>{`${data.count} ${getTooltipLabel()}`}</HeatMapTooltipP>
                                                </TooltipContainer>
                                            );
                                        }
                                        return null;
                                    }}
                                />
                                <Scatter
                                    data={data}
                                    shape={(props) => {
                                        
                                        const { x, y, payload } = props;
                                

                                        return (
                                            <Rectangle
                                                x={x - 2} // uma nuance para alinhar com o tick
                                                y={y - 2} // uma nuance para tentar alinhar com a letra
                                                width={14}
                                                height={14}
                                                fill={payload.color}
                                                stroke= {darkMode ? darkTextSecondaryColor : `gray`}
                                                strokeWidth={0.5}
                                            />
                                        );
                                    }}
                                />
                            </ScatterChart>
                        </ResponsiveContainer>
                    </HeatMapContainer>
                </RotateWrapper>
            </HeatMapScreen>
    )
}
