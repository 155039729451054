import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraWellsTEP() {
    const [criterios, setCriterios] = useState({
        sinaisTvp: false,
        diagnosticoAlternativo: false,
        fcAlta: false,
        cirurgiaRecente: false,
        tvpPrevio: false,
        hemoptise: false,
        cancer: false
    });

    const [pontuacao, setPontuacao] = useState(0);
    const [interpretacao, setInterpretacao] = useState('');

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setCriterios((prevCriterios) => ({
            ...prevCriterios,
            [name]: checked
        }));
    };

    useEffect(() => {
        let total = 0;

        if (criterios.sinaisTvp) total += 3;
        if (criterios.diagnosticoAlternativo) total += 3;
        if (criterios.fcAlta) total += 1.5;
        if (criterios.cirurgiaRecente) total += 1.5;
        if (criterios.tvpPrevio) total += 1.5;
        if (criterios.hemoptise) total += 1;
        if (criterios.cancer) total += 1;

        setPontuacao(total);

        if (total < 2) {
            setInterpretacao('Baixa probabilidade');
        } else if (total <= 6) {
            setInterpretacao('Probabilidade intermediária');
        } else {
            setInterpretacao('Alta probabilidade');
        }
    }, [criterios]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora Escore de Wells para TEP</h1>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="sinaisTvp"
                        checked={criterios.sinaisTvp}
                        onChange={handleCheckboxChange}
                    />
                    Sinais clínicos de TVP
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="diagnosticoAlternativo"
                        checked={criterios.diagnosticoAlternativo}
                        onChange={handleCheckboxChange}
                    />
                    Diagnóstico alternativo menos provável que TEP
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="fcAlta"
                        checked={criterios.fcAlta}
                        onChange={handleCheckboxChange}
                    />
                    FC maior que 100 bpm
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="cirurgiaRecente"
                        checked={criterios.cirurgiaRecente}
                        onChange={handleCheckboxChange}
                    />
                    Cirurgia recente ou imobilização
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="tvpPrevio"
                        checked={criterios.tvpPrevio}
                        onChange={handleCheckboxChange}
                    />
                    TVP ou TEP prévios
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="hemoptise"
                        checked={criterios.hemoptise}
                        onChange={handleCheckboxChange}
                    />
                    Hemoptise
                </label>
            </div>
            <div>
                <label>
                    <input
                        type="checkbox"
                        name="cancer"
                        checked={criterios.cancer}
                        onChange={handleCheckboxChange}
                    />
                    Câncer
                </label>
            </div>
            <div className="result-container">
                <p>Pontuação: {pontuacao}</p>
                <p>Interpretação: {interpretacao}</p>
            </div>
            
        </div>
    );
}

export default CalculadoraWellsTEP;
