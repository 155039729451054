

export function mapMethodology(methodology) {
    const mapping = {
        'methodology': 'Metodologia',
        'spelling': 'Typo',
        'content': 'Conteúdo',
        'improvement': 'Sugestão'
    };
    return mapping[methodology] || methodology;
}

export function mapIcon(methodology) {
    const mapping = {
        'methodology': '🧠',
        'spelling': '✍🏼',
        'content': '📚',
        'improvement': '🎨',
        'Total' : '⚠️',
    };
    return mapping[methodology] || '✍🏻';
}
