import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import Exam from './Exam'
import ExamDialog from './ExamDialog'
import FullHeart from './../assets/like_full.png'
import EmptyHeart from './../assets/like_empty.png'
import ExamsController from '../controllers/ExamsController'
import OslerDropdown from '../components/OslerDropdown'
import SessionBuilder from '../controllers/SessionBuilder'
import Session from '../controllers/Session'
import { toastMsg } from '../utils/Utils'
import { setIsLoading } from '../redux/loadingSlice'
import { useNavigate } from 'react-router-dom'
import { SessionConfig } from '../controllers/SessionConfig'

const Container = styled.div`
    width: 100%;
    padding: 2em;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: 768px) {
        padding: 1em 0.5em;
    }
`

const FiltersContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-end;
        gap: 8px;
    }
`

const ExamsContent = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (max-width: 768px) {
        padding: 0em;
    }
`


const InstitutionHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        gap: 0.8em;
    }
`

const InstitutionName = styled.h2`
    font-size: 1.4em;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#333'};
    font-weight: 600;

    @media (max-width: 768px) {
        font-size: 1em;
        width: 90%;
    }
`

const LikeButton = styled.div`
    cursor: pointer;
    filter: ${props => props.theme.darkMode ? 'brightness(0.9)' : 'none'};

    img {
        width: 1.2em;
        height: 1.2em;
    }
`

const ExamsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    align-self: center;

    flex-wrap: wrap;
    width: 100%;
    padding: 0 1em 0 1em;
    gap: 2em;

    @media (max-width: 768px) {
        padding: 0;
        justify-content: center;
        gap: 0.8em;
    }
`


const NoExamsMessage = styled.div`
    width: 60%;
    text-align: center;
    padding: 2em;
    border-radius: 12px;
    margin: 2em 0;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    ` : `
        background-color: #ffffff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    `}

    h3 {
        font-size: 1.6em;
        font-weight: bold;
        color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#333'};
        margin-bottom: 0.5em;
    }

    p {
        font-size: 1.2em;
        color: ${props => props.theme.darkMode ? 'rgb(180, 180, 180)' : '#666'};
    }

    @media (max-width: 768px) {
        width: 95%;
        padding: 1em;

        h3 {
            font-size: 1.2em;
        }

        p {
            font-size: 1em;
        }
    }
`


const StateGroup = styled.div`
    display: flex;
    flex-direction: column;

    align-items: flex-start;;

    width: 100%;
    margin-bottom: 2em;

    @media (max-width: 768px) {
        margin-bottom: 1.5em;
    }
`

const StateHeader = styled.h2`
    font-size: 1.6em;
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : '#333'};
    margin-bottom: 1em;
    padding-bottom: 0.5em;

    border-bottom: 2px solid ${props => props.theme.darkMode ? 
        'rgba(255, 255, 255, 0.1)' : 
        '#e0e0e0'};
        
    width: 100%;

    @media (max-width: 768px) {
        font-size: 1.3em;
        margin-bottom: 0.8em;
        padding-bottom: 0.3em;
    }
`

const InstitutionsRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: wrap;
    gap: 20px;
    width: 100%;

    @media (max-width: 768px) {
        gap: 12px;
    }
`

const Institution = styled.div`
    // width: calc(33.33% - 20px);

    width: 49%;

    min-width: 300px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 12px;
    transition: all 0.3s ease;

    ${props => props.theme.darkMode ? `
        background-color: rgb(51, 51, 51);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
        
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
        }
    ` : `
        background-color: #ffffff;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
        
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
        }
    `}

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 1200px) {
        width: calc(50% - 20px);
    }

    @media (max-width: 768px) {
        width: 100%;
        min-width: unset;
        margin-bottom: 12px;
        padding: 0.8em;

        &:hover {
            transform: none;
        }
    }
`

export default function Exams() {
    const [examsMetadata, setExamsMetadata] = useState(null)
    const [selectedExam, setSelectedExam] = useState(null)
    const [likedInstitutions, setLikedInstitutions] = useState([])
    const [showDialog, setShowDialog] = useState(false)
    const [selectedState, setSelectedState] = useState(null)
    const [showOnlyLiked, setShowOnlyLiked] = useState(false)
    const user = useSelector(state => state.user.data)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const stateOptions = useMemo(() => {
        const options = Object.entries(ExamsController.stateAbbreviationToName).map(([id, text]) => ({ id, text }))
        return [{ id: 'all', text: 'Todos os estados' }, ...options]
    }, [])

    const likedOptions = useMemo(() => [
        { text: 'Todas as instituições', id: 'all' },
        { text: 'Somente curtidas', id: 'liked' }
    ], [])

    useEffect(() => {
        async function load() {
            setExamsMetadata(ExamsController.examsMetadata)
            setLikedInstitutions(ExamsController.focusedInstitutions)
        }

        load()
    }, [user])

    function handleStateFilter(stateId) {
        setSelectedState(stateId === 'all' ? null : stateId)
    }

    function handleLikedFilter(option) {
        setShowOnlyLiked(option === 'liked')
    }

    function filterAndGroupInstitutions(institutions) {
        const filtered = Object.entries(institutions).filter(([institution, institutionData]) => {
            const matchesState = !selectedState || institutionData.stateAbbrev === selectedState
            const matchesLiked = !showOnlyLiked || likedInstitutions.includes(institution)
            return matchesState && matchesLiked
        })

        const grouped = filtered.reduce((acc, [institution, institutionData]) => {
            const state = institutionData.stateFull
            if (!acc[state]) {
                acc[state] = []
            }
            acc[state].push([institution, institutionData])
            return acc
        }, {})

        // Ordenar estados alfabeticamente
        return Object.entries(grouped).sort((a, b) => a[0].localeCompare(b[0]))
    }

    function prepareDialog(institution_id, year, institution) {
        setSelectedExam({
            institution: institution,
            year: year,
            id: institution_id
        })
        setShowDialog(true)
    }

    


    function toggleLike(institution) {        
        setLikedInstitutions(ExamsController.toggleFocused(institution))
    }

    return (
        <Container>
            <FiltersContainer>
                <OslerDropdown
                    options={stateOptions}
                    signal={handleStateFilter}
                    placeholder='Filtrar por estado'
                />
                <OslerDropdown
                    options={likedOptions}
                    signal={handleLikedFilter}
                    placeholder='Filtrar por curtidas'
                />
            </FiltersContainer>
            <ExamsContent>
                {examsMetadata && (
                    (() => {
                        const groupedInstitutions = filterAndGroupInstitutions(examsMetadata)
                        if (groupedInstitutions.length === 0) {
                            return (
                                <NoExamsMessage>
                                    <h3>Nenhuma prova encontrada</h3>
                                    <p>Não foram encontradas provas que satisfaçam os filtros selecionados. Por favor, tente ajustar seus critérios de busca.</p>
                                </NoExamsMessage>
                            )
                        }
                        return groupedInstitutions.map(([state, institutions]) => (
                            <StateGroup key={state}>
                                <StateHeader>{state}</StateHeader>
                                <InstitutionsRow>
                                    {institutions.sort((a, b) => a[0].localeCompare(b[0])).map(([institution, institutionData]) => (
                                        <Institution key={institution}>
                                            <InstitutionHeader>
                                                <InstitutionName>{institution}</InstitutionName>
                                                <LikeButton onClick={() => toggleLike(institution)}>
                                                    <img src={likedInstitutions.includes(institution) ? FullHeart : EmptyHeart} alt='Like' />
                                                </LikeButton>
                                            </InstitutionHeader>
                                            <ExamsContainer>
                                                {Object.entries(institutionData.R1.years).map(([year, yearData]) => (
                                                    <Exam
                                                        key={`${institution}-R1-${year}`}
                                                        institution={institution}
                                                        institution_id={institutionData.institution_id}
                                                        year={year}
                                                        examType='R1'
                                                        examData={{
                                                            firstAnswerPercentage: yearData.firstAnswerPercentage || 0,
                                                            lastAnswerPercentage: yearData.lastAnswerPercentage || 0,
                                                            testsAnswered: yearData.testsAnswered || 0,
                                                            nTests: yearData.n_tests
                                                        }}
                                                        prepareDialog={prepareDialog}
                                                    />
                                                ))}
                                            </ExamsContainer>
                                        </Institution>
                                    ))}
                                </InstitutionsRow>
                            </StateGroup>
                        ))
                    })()
                )}
            </ExamsContent>
            <ExamDialog
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                selectedExam={selectedExam}
                // loadSession={loadSession}
            />
        </Container>
    )
}