import React, { useState, useEffect, useRef, useCallback } from 'react';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';
import Fuse from 'fuse.js';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { debounce } from 'lodash';

const ITEMS_PER_PAGE = 20;

const SearchBar = React.memo(({ searchTerm, setSearchTerm }) => {
  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <input
      type="text"
      placeholder="Pesquisar pelo nome da doença"
      value={searchTerm}
      onChange={handleSearchTermChange}
    />
  );
});

const CidsPage = () => {
  const [cids, setCids] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSystem, setSelectedSystem] = useState(''); // Adicionado para filtrar por sistema acometido
  const [filteredCids, setFilteredCids] = useState([]);
  const [expandedCid, setExpandedCid] = useState(null);
  const [loading, setLoading] = useState(true); // Estado de carregamento
  const [currentPage, setCurrentPage] = useState(0);
  const searchTimeoutRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const cidsCollection = collection(db, 'cid');
        const cidSnapshot = await getDocs(cidsCollection);
        const cidList = cidSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setCids(cidList);
        setFilteredCids(cidList);
      } catch (error) {
        console.error("Erro ao buscar os dados:", error);
      } finally {
        setLoading(false); // Definir carregamento como falso após buscar os dados
      }
    };

    fetchData();
  }, []);

  const debouncedRegisterSearchTerm = useCallback(
    debounce(async (term) => {
      if (term.length > 5) {
        try {
          await addDoc(collection(db, 'searchLogs'), {
            searchTerm: term,
            timestamp: new Date()
          });
        } catch (error) {
          console.error("Erro ao registrar o termo de busca:", error);
        }
      }
    }, 500),
    [] // A callback não deve ser recriada em cada renderização
  );

  useEffect(() => {
    const performSearch = (term) => {
      let filteredResults = cids;

      if (term) {
        debouncedRegisterSearchTerm(term);

        const options = {
          keys: [
            'descricao',
            'codigo',
            'id',
            'subtipos.descricao',
            'subtipos.codigo'
          ],
          includeMatches: true,
          threshold: 0.3 // Ajuste para controlar a sensibilidade da pesquisa
        };

        const fuse = new Fuse(cids, options);
        const results = fuse.search(term);
        filteredResults = results.map(result => result.item);
      }

      if (selectedSystem) {
        filteredResults = filteredResults.filter(cid => cid.sistemaAcometido === selectedSystem);
      }

      setFilteredCids(filteredResults);
      setCurrentPage(0); // Resetar para a primeira página ao fazer uma nova busca

      // Expandir automaticamente os CIDs que têm subtipos correspondentes
      const expandedIds = filteredResults
        .filter(cid => cid.subtipos && cid.subtipos.some(subtipo =>
          subtipo.descricao.toLowerCase().includes(term.toLowerCase()) ||
          subtipo.codigo.toLowerCase().includes(term.toLowerCase())
        ))
        .map(cid => cid.id);

      setExpandedCid(expandedIds.length > 0 ? expandedIds[0] : null);
    };

    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      performSearch(searchTerm);
    }, 300);

    return () => {
      if (searchTimeoutRef.current) {
        clearTimeout(searchTimeoutRef.current);
      }
    };
  }, [searchTerm, selectedSystem, cids, debouncedRegisterSearchTerm]);

  const toggleExpand = (id) => {
    setExpandedCid(expandedCid === id ? null : id);
  };

  const startIndex = currentPage * ITEMS_PER_PAGE;
  const currentItems = filteredCids.slice(startIndex, startIndex + ITEMS_PER_PAGE);
  const totalPages = Math.ceil(filteredCids.length / ITEMS_PER_PAGE);

  return (
    <div>
      <Link to="/bedside" className="button-voltar-bulario">&lt;</Link>
      <h1 className='titulo-cid'>Lista CID10</h1>
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      {loading ? (
        <div className="loading">
          <FontAwesomeIcon icon={faSpinner} spin size="3x" />
        </div>
      ) : (
        <>
          <div className="cards-container-cid">
            {currentItems.map(cid => (
              <div className="card-cid" key={cid.id}>
                <div className="card-header-cid">
                  <div className="document-id-cid">
                    <strong>{cid.codigo}</strong> {cid.descricao}
                    <span className='numero-cid'><b>{cid.id}</b></span>
                  </div>
                  <button className='cid-button' onClick={() => toggleExpand(cid.id)}>
                    <i className={expandedCid === cid.id ? "fi fi-rr-minus" : "fi fi-rr-plus"}></i>
                  </button>
                </div>
                {expandedCid === cid.id && (
                  <div className="card-body-cid">
                    <ul>
                      {cid.subtipos && cid.subtipos.map(subtipo => (
                        <li className='lista-cid' key={subtipo.codigo}>
                          <strong>{subtipo.codigo}:</strong> {subtipo.descricao}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="pagination-controls">
            {currentPage > 0 && (
              <button
                className="pagination-button"
                onClick={() => setCurrentPage((prevPage) => Math.max(prevPage - 1, 0))}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
              </button>
            )}
            <span>{currentPage + 1} de {totalPages}</span>
            {currentPage < totalPages - 1 && (
              <button
                className="pagination-button"
                onClick={() => setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default CidsPage;
