import React, { useState, useRef, useEffect } from 'react';


import styled from 'styled-components';
import data from './AmbassadorsController';

// Componentes estilizados
const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;

  width: 30%;
`;

const InputSearch = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
`;

const DropdownContent = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  position: absolute;
  background-color: #f9f9f9;
  min-width: 400px;
  max-height: 200px; /* Altura máxima antes de começar a rolar */
  overflow-y: auto; /* Habilita a rolagem vertical se o conteúdo exceder o max-height */
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  border-radius: 4px;
  width: calc(100% - 32px);
`;

const Item = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f1f1f1;
  }
`;


export default function AmbassadorSearchBox({ selectAmbassador }) {
    const [searchTerm, setSearchTerm] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [isVisible, setIsVisible] = useState(false)
    const wrapperRef = useRef(null)


    const handleSearchChange = (event) => {
        const searchedTerm = event.target.value
        setSearchTerm(searchedTerm);

        const value = searchTerm.toLowerCase();

        if (value.length > 0) {
            const filteredSuggestions = data.consolidatedProfiles.filter(profile =>
                profile.current_coupon.toLowerCase().includes(value) ||
                profile.email.toLowerCase().includes(value) ||
                profile.name.toLowerCase().includes(value) ||
                profile.phone.includes(value) ||
                profile.school.toLowerCase().includes(value)
            )
            setSuggestions(filteredSuggestions)

        } else {
            // Se nenhum texto for inserido, mostre todas as opções
            setSuggestions(data.consolidatedProfiles);
        }

        setIsVisible(true);
    }


    useEffect(() => {
        // A referência wrapperRef.current deixa de ser nula assim que o componente ao 
        // qual ela está atribuída é montado no DOM. No React, isso acontece depois que 
        // o componente é renderizado pela primeira vez. Ao usar o atributo ref={wrapperRef}
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setIsVisible(false);
            }
        }

        // Adiciona o ouvinte de eventos quando o dropdown está visível
        if (isVisible) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        // Limpeza do ouvinte de eventos
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isVisible]);


    const handleFocus = () => {
        setIsVisible(true);
        if (searchTerm.length === 0) {
            setSuggestions(data.consolidatedProfiles);
        }
    };


    function signalChosenAmbassador(chosen) {
        selectAmbassador(chosen)
        // selectAmbassador( couponToUid.current[selectedCoupon]['embaixador_uid'] )  
    }


    return (
        <DropdownWrapper ref={wrapperRef}>
            <InputSearch
                type = "text"
                value = {searchTerm}
                onChange = {handleSearchChange}
                onFocus = {handleFocus}
                placeholder = "Buscar embaixador..."
            />
            <DropdownContent isVisible={isVisible}>
                {suggestions.map((suggestion, index) => (
                    <Item key={index} onClick={() => signalChosenAmbassador(suggestion.uid)}>
                        {suggestion.name} - {suggestion.current_coupon}
                    </Item>
                ))}
            </DropdownContent>
        </DropdownWrapper>
    )
}