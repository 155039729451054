import { createSlice } from '@reduxjs/toolkit'

/*
    Durante os primeiros milissegundos do app, quando estamos
    esperando o FirebaseAuth carregar, salvamos a página na qual
    o usuário estava tentando entrar, para redirecioná-lo a ela,
    se for o caso.
*/


export const pathSlice = createSlice({
    name: 'path',
    initialState: {
        path: '/home'
    },
    reducers: {
        savePath: (state, action) => {
            state.path = action.payload
        },

        removePath: (state) => {
            state.data = '/home'
        }
    }
})

export const { savePath, removePath } = pathSlice.actions
export default pathSlice.reducer