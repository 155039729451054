import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraPHQ9() {
    const [scores, setScores] = useState({
        q1: 0,
        q2: 0,
        q3: 0,
        q4: 0,
        q5: 0,
        q6: 0,
        q7: 0,
        q8: 0,
        q9: 0
    });
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setScores(prevScores => ({
            ...prevScores,
            [name]: parseInt(value, 10)
        }));
    };

    const calcularPHQ9 = () => {
        const totalScore = Object.values(scores).reduce((a, b) => a + b, 0);
        let interpretation = '';

        if (totalScore >= 0 && totalScore <= 4) {
            interpretation = 'Sem sintomas depressivos notáveis';
        } else if (totalScore >= 5 && totalScore <= 9) {
            interpretation = 'Sintomas leves de depressão';
        } else if (totalScore >= 10 && totalScore <= 14) {
            interpretation = 'Sintomas moderados de depressão';
        } else if (totalScore >= 15 && totalScore <= 19) {
            interpretation = 'Sintomas moderadamente graves de depressão';
        } else if (totalScore >= 20) {
            interpretation = 'Sintomas graves de depressão';
        }

        setResultado(`Pontuação total: ${totalScore}, Interpretação: ${interpretation}`);
    };

    useEffect(() => {
        calcularPHQ9();
    }, [scores]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora PHQ-9</h1>
            <form className='form-gad7'>
                <div>
                    <label>Pouco interesse ou prazer em fazer coisas?</label>
                    <select name="q1" value={scores.q1} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Sentir-se para baixo, deprimido ou sem esperanças?</label>
                    <select name="q2" value={scores.q2} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Dificuldade para dormir ou dormir demais?</label>
                    <select name="q3" value={scores.q3} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Sentir-se cansado ou com pouca energia?</label>
                    <select name="q4" value={scores.q4} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Pouco apetite ou comer demais?</label>
                    <select name="q5" value={scores.q5} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Sentir-se mal consigo mesmo - ou que você é um fracasso ou deixou sua família para baixo?</label>
                    <select name="q6" value={scores.q6} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Dificuldade para se concentrar nas coisas, como ler o jornal ou ver TV?</label>
                    <select name="q7" value={scores.q7} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Movimentar-se ou falar tão devagar que outras pessoas poderiam ter notado? Ou tão agitado ou inquieto que você tem se movido muito mais do que o habitual?</label>
                    <select name="q8" value={scores.q8} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
                <div>
                    <label>Pensamentos de que você estaria melhor morto ou de se machucar de alguma forma?</label>
                    <select name="q9" value={scores.q9} onChange={handleChange}>
                        <option value="0">Nunca</option>
                        <option value="1">Vários dias</option>
                        <option value="2">Mais da metade dos dias</option>
                        <option value="3">Quase todos os dias</option>
                    </select>
                </div>
            </form>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraPHQ9;
