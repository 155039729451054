import React, { useState } from 'react'
import ReactSwitch from 'react-switch'
import { useDispatch, useSelector } from 'react-redux'
import { setDarkModeTo } from '../redux/themeSlice'
import Moon from './../assets/lua.png'
import Sun from './../assets/sol.png'
import styled from 'styled-components'


const ToggleIcon = styled.img`
    width: 1.2em;
    position: relative;
    left: 0.5em;
`



export default function DarkModeToggle({style}) {

    const dispatch = useDispatch();  

    const [darkMode, setDarkMode] = useState(useSelector(state => state.theme.darkModeOn))
    
    return (
            <ReactSwitch
                checked = {!darkMode}

                checkedIcon = {<ToggleIcon src = {Sun} />}
                uncheckedIcon = {<ToggleIcon src = {Moon} />}

                onChange = {() => {
                    dispatch(setDarkModeTo(!darkMode))
                    setDarkMode(!darkMode)
                    console.log('Now dark mode is ' + !darkMode)
                }}

                // uncheckedHandleIcon={ <ToggleIcon src = {Sun} />}
                offHandleColor = {'#c4c4c4'}

                width = {60}
                // style = {style}
                // style = {{ marginBottom: '2em', appearance: 'none'}}
                    />
    );
}
