import { db } from './../firebase/firebase-setup'

class ResidenciaListsController {
    constructor() {
        this.userID = null
        this.root = null
        this.lists = []
    }

    async start(user) {
        this.userID = user.id
        this.root = `/users/${this.userID}/Residencia`
        await this.fetchLists()
    }

    async fetchLists() {
        try {
            const listsSnapshot = await db.collection(`${this.root}/lists/custom_lists`).get()
            this.lists = listsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                ...this.calculateStatistics(doc.data())
            }))
        } catch (error) {
            console.error('Erro ao baixar listas do Firebase:', error)
            throw error
        }
    }

    calculateStatistics(listData) {
        let firstCorrect = 0
        let firstIncorrect = 0
        let lastCorrect = 0
        let lastIncorrect = 0
        let totalAnswered = 0
        let lastAnsweredDate = 0
        const totalQuestions = listData.testIDs.length
        const lastWrongAnswers = []
        const neverAnswered = []
    
        for (const testId of listData.testIDs) {
            const answerData = listData[testId]
            if (answerData && answerData.first_answer) {
                totalAnswered++
                if (answerData.first_answer.gaveRightAnswer) firstCorrect++
                else firstIncorrect++
                
                if (answerData.first_answer.dateAnswered > lastAnsweredDate) {
                    lastAnsweredDate = answerData.first_answer.dateAnswered
                }

                if (answerData.last_answer) {
                    if (answerData.last_answer.gaveRightAnswer) lastCorrect++
                    else {
                        lastIncorrect++
                        lastWrongAnswers.push(testId)
                    }
                    
                    if (answerData.last_answer.dateAnswered > lastAnsweredDate) {
                        lastAnsweredDate = answerData.last_answer.dateAnswered
                    }
                }
            } else {
                neverAnswered.push(testId)
            }
        }
    
        // Corrigindo o formato da data de criação
        const createdAtFormatted = listData.createdAt ? listData.createdAt.split(',')[0] : ''
        const lastAnsweredFormatted = lastAnsweredDate ? this.formatDate(lastAnsweredDate) : ''
    
        return {
            createdAtFormatted,
            lastAnsweredFormatted,
            firstCorrect,
            firstIncorrect,
            lastCorrect,
            lastIncorrect,
            totalAnswered,
            firstAttemptPercentage: totalAnswered > 0 ? (firstCorrect / totalAnswered) * 100 : 0,
            lastAttemptPercentage: totalAnswered > 0 ? (lastCorrect / totalAnswered) * 100 : 0,
            completionPercentage: (totalAnswered / totalQuestions) * 100,
            lastWrongAnswers,
            neverAnswered
        }
    }

    formatDate(timestamp) {
        const date = new Date(timestamp)
        const day = String(date.getDate()).padStart(2, '0')
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const year = String(date.getFullYear()).slice(-2)
        return `${day}/${month}/${year}`
    }

    async updateListName(listId, newName) {
        try {
            await db.doc(`${this.root}/lists/custom_lists/${listId}`).update({ name: newName })
            const listIndex = this.lists.findIndex(list => list.id === listId)
            if (listIndex !== -1) {
                this.lists[listIndex].name = newName
            }
            return true
        } catch (error) {
            console.error('Erro ao atualizar o nome da lista:', error)
            return false
        }
    }

    async deleteList(listId) {
        try {
            await db.doc(`${this.root}/lists/custom_lists/${listId}`).delete()
            this.lists = this.lists.filter(list => list.id !== listId)
            return true
        } catch (error) {
            console.error('Erro ao deletar a lista:', error)
            return false
        }
    }

    getLists() {
        return this.lists
    }
}

export default new ResidenciaListsController()