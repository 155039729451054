import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebase-setup';
import { Link } from 'react-router-dom';

const BularioPage = () => {
    const [medicamentos, setMedicamentos] = useState([]);
    const [filtro, setFiltro] = useState('');  // Estado para guardar o valor do filtro de busca

    useEffect(() => {
        const fetchMedicamentos = async () => {
            const querySnapshot = await getDocs(collection(db, 'bulario'));
            let medicamentosData = [];
            
            querySnapshot.docs.forEach((doc) => {
                const data = doc.data();
                medicamentosData.push({
                    ...data,
                    id: doc.id,
                });
            });

            medicamentosData.sort((a, b) => {
                const nomeA = a.id.toUpperCase();
                const nomeB = b.id.toUpperCase();
                return nomeA.localeCompare(nomeB);
            });

            setMedicamentos(medicamentosData);
        };

        fetchMedicamentos();
    }, []);

    // Função para filtrar os medicamentos de acordo com o texto inserido
    const medicamentosFiltrados = medicamentos.filter(med => {
        const busca = filtro.toLowerCase();
        return med.id.toLowerCase().includes(busca) || 
               (med.nomeMedicamento && med.nomeMedicamento.toLowerCase().includes(busca));
    });

    return (
        <div className="bulario-container">
            <Link to="/bedside" className="button-voltar-bulario">&lt;</Link>
            <h1 className='tituloMedicamentos'>Medicamentos</h1>
            <input
                type="text"
                placeholder="Buscar medicamentos..."
                value={filtro}
                onChange={(e) => setFiltro(e.target.value)}
                className="search-bar"
            />
            <ul className="medicamento-lista">
                {medicamentosFiltrados.map((medicamento, index) => (
                    <li key={index} className="medicamento-item">
                        <Link to={`/medicamentos/${medicamento.id}`}>
                            <span className="nome-comercial">{medicamento.id}</span>
                            <br />
                            {/* <span className="nome-medicamento">{medicamento.nomeMedicamento || "Nome Genérico Não Disponível"}</span> */}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BularioPage;