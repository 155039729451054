import { db } from "../firebase/firebase-setup";


export async function loadNumberCards(uid) {
    const overallStatistics = await db.doc(`users/${uid}/Flashcards/statistics/assorted/nEachTypeTest`).get()
    let totalCards = 0

    if (overallStatistics.exists) {
        const data = overallStatistics.data()
        const lapsed = data.Total && data.Total.lapsed ? data.Total.lapsed : 0;
        const learning = data.Total && data.Total.learning ? data.Total.learning : 0;    

        totalCards = (lapsed + learning)
    }
    else {
        totalCards = 0
    }

    return totalCards
}