import React, { useState } from 'react'
import styled from 'styled-components'
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from "recharts"
import { ColumnCSS, RowCSS } from '../components/BasicComponents'
import { backgroundWhiteBlack, darkBackgroundSextenaryColor, darkBackgroundTertiaryColor, textWhiteBlack } from '../tests/FlashcardsStyles'
import { useSelector } from 'react-redux'

const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const PieContainer = styled.div`
    aspect-ratio: 1 / 1;
    width: 60%;
    margin-top: 1em;
    margin-bottom: 1em;
`

const LegendContainer = styled.div`
    ${ColumnCSS}
    
    width: 50%;
    border: 0.8px solid gray;
    border-radius: 0.5em;

    padding: 1em 5% 1em 5%;

    ${backgroundWhiteBlack}
`


const LegendItem = styled.div`
    ${RowCSS}

    margin-bottom: 0.5em;   
    width: 100%;
`

const LegendColor = styled.div`
    width: 1em;
    height: 1em;
    border-radius: 0.25em;


    background-color: ${props => props.color};
    opacity: ${props => props.isActive ? 1 : 0.3};
    transition: opacity 0.3s ease;
`

const LegendInfo = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 0.25em;
    margin-left: 0.5em;

    ${textWhiteBlack}
`

const LegendLabel = styled.p`
    margin: 0;
    font-weight: bold;
    width: 100%;
`

const LegendData = styled.p`
    margin: -0.2em 0 0 0.5em;
    width: 100%;
`


const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    padding: 1em;

    ${backgroundWhiteBlack}

    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 0.5em;
`

const TooltipTitle = styled.p`
    margin: 0 0 0.5em 0;
    font-weight: bold;
    text-align: center;
    color: ${props => props.color};
`

const TooltipInfo = styled.p`
    margin: 0;
`


const TooltipDescription = styled.p`
    font-size: 0.8em;
    margin: 0;
    margin-top: 0.5em;
    color: gray;
`


function CustomTooltip({ active, payload, graphData }) {
    if (active && payload && payload.length) {
        const data = payload[0].payload
        const total = graphData.reduce((sum, item) => sum + item.number, 0)


        let number = `${data.number.toLocaleString('pt-BR')}`
        if (data.unit) {
            number += ` ${data.unit[data.number > 1 ? 1 : 0]}`
        }    


        let percentage = `${((data.number / total) * 100).toFixed(0)}%`
        if (data.percentageOf) {
            percentage += ` ${data.percentageOf}`
        }



        return (
            <TooltipContainer>
                <TooltipTitle>{data.label}</TooltipTitle>
                <TooltipInfo>{number}</TooltipInfo>
                <TooltipInfo>{percentage}</TooltipInfo>

                {data.tooltipDescription && <TooltipDescription>{`${data.tooltipDescription}`}</TooltipDescription>}
            </TooltipContainer>
        )
    }
    return null
}

function CustomLegend({payload, activeIndex, setActiveIndex}) {
    const total = payload.reduce((sum, item) => sum + item.number, 0)
    return (
        <LegendContainer>
            {payload.map((entry, index) => {
                const percentage = ((entry.number / total) * 100).toFixed(0)
                return (
                    <LegendItem 
                        key={`item-${index}`}
                        onMouseEnter={() => setActiveIndex(index)}
                        onMouseLeave={() => setActiveIndex(null)}
                    >
                        <LegendColor color={entry.color} isActive={activeIndex === null || activeIndex === index} />
                        <LegendInfo>
                            <LegendLabel>{`${entry.label}`}</LegendLabel>
                            <LegendData>{`${entry.number.toLocaleString('pt-BR')} (${percentage}%)`}</LegendData>                          
                        </LegendInfo>
                    </LegendItem>
                )
            })}
        </LegendContainer>
    )
}

export default function OslerPieChart({data}) {
    const [activeIndex, setActiveIndex] = useState(null)


    const darkMode = useSelector(state => state.theme.darkModeOn)
    
    if (!data) return null


    
    return (
        <ChartContainer>
            <PieContainer>
                <ResponsiveContainer width="100%" height="100%" debounce={200}>
                    <PieChart>
                        <Pie 
                            stroke = {darkMode ? darkBackgroundSextenaryColor : 'white'}
                            data={data} 
                            dataKey="number"
                            nameKey="label"
                            outerRadius="100%"
                        >
                            {data.map((entry, index) => (
                                <Cell 
                                    key={`cell-${index}`} 
                                    fill={entry.color}
                                    opacity={activeIndex === null || activeIndex === index ? 1 : 0.3}
                                />
                            ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip graphData={data} />} />
                    </PieChart>
                </ResponsiveContainer>
            </PieContainer>

            <CustomLegend 
                payload={data} 
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex} />

        </ChartContainer>
    )
}