
import sha256 from 'crypto-js/sha256';

const NUM_DOCS = 4
const DOC_LETTERS = {
    0: 'a',
    1: 'b',
    2: 'c',
    3: 'd',
    4: 'e',
    5: 'f',
    6: 'g',
    7: 'h',
}


export function hashTestID(testID) {
    const hash = sha256(testID).toString()
    const docIndex = parseInt(hash.substring(0, 8), 16) % NUM_DOCS
    // const docLetter = DOC_LETTERS[docIndex]

    // console.log(testID)
    // console.log(`ms_per_id_${docLetter}`)

    return `ms_per_id_${docIndex}`
}



export function hashTestIDToSolved(testID) {
    const hash = sha256(testID).toString()
    const docIndex = parseInt(hash.substring(0, 8), 16) % NUM_DOCS

    return `solved_per_id_${docIndex}`
}



// export function hashTestID(testID, testType) {
//     let doc_id_selector = 0;

//     if (testType === 'Flashcards') {
//         const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']

//         const parts = testID.split('_')

//         var unidecode = require('unidecode')
//         const root = unidecode(parts[1])

//         const letter = root[0].toLowerCase()

//         doc_id_selector = letters.indexOf(letter) % 2
//     }
//     else if (testType === 'Residencia') {
//         // No geral, é da forma: residencia_UFF_2021_R1_Q33
//         // Mas algumas instituições tiveram mais de uma prova no ano, aí oé do formato: 2020-2
//         let year = testID.split("_")[2]

//         if ( year.includes("-") ) {
//             year = year.slice(0, 4)
//         }

//         year = parseInt(year)

//         doc_id_selector = year % 2
//     }

//     if (doc_id_selector == 0) {
//         return 'ms_per_id_1'
//     }
//     else {
//         return 'ms_per_id_2'
//     }

// }


export function getTestRoot(testType, testID) {
    console.log(testType)
    console.log(testID)
    if (testType == 'Flashcards') {
        return testID.split('_')[1]   
    }
    else {
        return testID.slice(  testID.indexOf('_') + 1  )
    }
}
