import React, { useState, useRef, useEffect } from 'react'
import { Editor, EditorState, RichUtils, convertToRaw, SelectionState, Modifier, convertFromHTML, ContentState } from 'draft-js'
import 'draft-js/dist/Draft.css'
import styled, { createGlobalStyle } from 'styled-components'


import H1Icon from '../assets/text_editor/h1.png';
import H1IconDark from '../assets/text_editor/h1 copy.png';
import H2Icon from '../assets/text_editor/h2.png';
import H2IconDark from '../assets/text_editor/h2 copy.png';
import H3Icon from '../assets/text_editor/h3.png';
import H3IconDark from '../assets/text_editor/h3 copy.png';
import MarkerIcon from '../assets/text_editor/marker.png';
import MarkerIconDark from '../assets/text_editor/marker copy.png';
import UnderlineIcon from '../assets/text_editor/underline.png';
import UnderlineIconDark from '../assets/text_editor/underline copy.png';
import StrikethroughIcon from '../assets/text_editor/strikethrough.png';
import StrikethroughIconDark from '../assets/text_editor/strikethrough copy.png';
import ItalicIcon from '../assets/text_editor/italic.png';
import ItalicIconDark from '../assets/text_editor/italic copy.png';
import BoldIcon from '../assets/text_editor/bold.png';
import BoldIconDark from '../assets/text_editor/bold copy.png';
import OutdentIcon from '../assets/text_editor/outdent.png';
import OutdentIconDark from '../assets/text_editor/outdent copy.png';
import IndentIcon from '../assets/text_editor/indent.png';
import IndentIconDark from '../assets/text_editor/indent copy.png';
import ListOrderedIcon from '../assets/text_editor/list_ordered.png';
import ListOrderedIconDark from '../assets/text_editor/list_ordered copy.png';
import ListUnorderedIcon from '../assets/text_editor/list_unordered.png';
import ListUnorderedIconDark from '../assets/text_editor/list_unordered copy.png';
import MeltingIcon from '../assets/text_editor/melting.png';
import MeltingIconDark from '../assets/text_editor/melting copy.png';

import { set } from 'remirror'
import EmojiSuggestions from './EmojiSuggestions'
import { emojiDictionary } from './Emojis'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import OnClickOutside from './OnClickOutside'
import { backgroundWhiteBlack, darkBackgroundSecondaryColor, darkBackgroundTertiaryColor, textWhiteBlack } from './FlashcardsStyles'
import { useSelector } from 'react-redux'


const TextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    cursor: auto;

    // deixamos um cinza claro, para mostrar onde é editável
    background-color: ${props => props.theme.darkMode ? darkBackgroundSecondaryColor : `rgba(250, 250, 250);`};

    border-radius: 0.5em;

`

const EditorContainer = styled.div`
    padding: 10px;

    border: ${({ isFocused }) => isFocused ? '1px solid #ccc' : 'none'};


    ${textWhiteBlack}

    min-height: ${({ singleLine }) => singleLine ? '2em' : '200px'};
    position: relative;
    caret-color: auto;
    overflow-y: scroll;

    background-color: transparent;
`


const ToolbarContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 400px;
    overflow-x: scroll;

    background-color: transparent;
     
    ${textWhiteBlack}
`

const ToolbarButtonWrapper = styled.div`
    background: none;
    border: none;
    cursor: pointer;

    img {
        height: 1em;
    }

    ${textWhiteBlack}

    padding: 0.3em;
    margin-bottom: 0.5em;
    border-radius: 0.5em;

    &:hover {
        background-color: ${props => props.theme.darkMode ? darkBackgroundTertiaryColor : `#eee`}
    }
`

const GlobalStyles = createGlobalStyle`
  .customHeaderOne {
    font-size: 36px;
    font-weight: bold;
  }
`;



const getStyleMap = (darkMode) => ({
    HIGHLIGHT: {
        backgroundColor: darkMode ? '#160671' : 'yellow',
    },
});


export default function OslerTextEditor({ 
        updateParent, previousContent = false, 
        showToolbar, toolbarWithPlaceholder = true,
        singleLine, style = {}, placeholder = ''}) {

    const editorRef = useRef(null)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [emojiSuggestions, setEmojiSuggestions] = useState(false)
    const [replacementIndices, setReplacementIndices] = useState(null)
    const [editorIsFocused, setEditorIsFocused] = useState(false)
    const [currentContent, setCurrentContent] = useState(null)

    const containerRef = useRef(null)

    const darkMode = useSelector(state => state.theme.darkModeOn)


    useEffect(() => {
        // console.log('O novo conteúdo sendo passado é:')
        // console.log(previousContent)

        if (previousContent) {
            if (previousContent != contentIntoHtml(editorState)) {
                // console.log('Iremos mudar, pois é diferente. O anterior era é:')
                // console.log(contentIntoHtml(editorState))

                const contentState = stateFromHTML(previousContent,
                    {
                        // deus é poderoso
                        // https://github.com/sstur/draft-js-utils/issues/120 
                        customInlineFn: (element, { Style }) => {
                            // Verifica se é um span com background-color amarelo
                            if (element.style.backgroundColor === 'yellow') {
                                return Style('HIGHLIGHT');
                            }
                        }
                    }
                )
                setEditorState(EditorState.createWithContent(contentState))
            }
        }
        else {
            console.log('Sem previous content, iremos deixar nulo')
            setEditorState(EditorState.createEmpty())
        }
    }, [previousContent])

    

    function handleInlineAction(type) {
        setEditorState(RichUtils.toggleInlineStyle(editorState, type))
    }


    function handleBlockType(type) {
        setEditorState(RichUtils.toggleBlockType(editorState, type))
    }



    function handleIndent(event) {
        // Esta função auxiliar do Draft.js já lida com a lógica necessária para ajustar a
        //  profundidade das listas. O event é passado junto com o estado atual do editor e um 
        // maxDepth que limita quão profundo uma lista pode ser indentada.
        // 4 é o maxDepth
        //
        // o event é usado para saber se é para identar ou desindentar
        const newEditorState = RichUtils.onTab(event, editorState, 4)
        setEditorState(newEditorState)
    }
    


    function handleEmojiMenuClick() {
        const {block, selectionState} = getBlock()
        const cursorPosition = selectionState.getStartOffset()
        let start = cursorPosition

        setEmojiSuggestions(Object.keys(emojiDictionary))
        setReplacementIndices({ block, start, cursorPosition })
    }



    function handleSelectAll() {
        const contentState = editorState.getCurrentContent();
        const firstBlock = contentState.getFirstBlock();
        const lastBlock = contentState.getLastBlock();
        const firstBlockKey = firstBlock.getKey();
        const lastBlockKey = lastBlock.getKey();
        const lengthOfLastBlock = lastBlock.getLength();

        const selection = new SelectionState({
            anchorKey: firstBlockKey,
            anchorOffset: 0,
            focusKey: lastBlockKey,
            focusOffset: lengthOfLastBlock,
            hasFocus: true
        });

        setEditorState(EditorState.forceSelection(editorState, selection));
    }



    const onChange = (newEditorState) => {
        // Há uma razão para ser a 1ª linha: (tentar) garantir o editor está atualizado antes
        // de passarmos para o pai, vide a discussão em NotebookSlide.

        setEditorState(newEditorState)
        checkForColon(newEditorState)

        const HTML = contentIntoHtml(newEditorState)
        setCurrentContent(HTML)
        updateParent(HTML)
    }


    function contentIntoHtml(newEditorState) {
        return stateToHTML(
            newEditorState.getCurrentContent(),
            {
                inlineStyles: {
                  HIGHLIGHT: {style: {backgroundColor: 'yellow'}},
                },
            },
        ).replace(/&nbsp;/g, ' ')
    }

    

    function getBlock(state = editorState) {
        const contentState = state.getCurrentContent()
        const selectionState = state.getSelection()
        const blockKey = selectionState.getStartKey()
        const block = contentState.getBlockForKey(blockKey)

        return {block, selectionState}
    }



    const checkForColon = (newEditorState) => {
        const {block, selectionState} = getBlock(newEditorState)
        const text = block.getText()
        const cursorPosition = selectionState.getStartOffset()

        // Encontra a palavra atual com base na posição do cursor
        let start = cursorPosition - 1
        while (start >= 0 && text[start] !== ' ' && text[start] !== '\n') {
            start--
        }
        start++

        // console.log(text)
        // console.log(start)
        // console.log(cursorPosition)
        const word = text.slice(start, cursorPosition)

        // Verifica se a palavra começa com ":"
        if (word.startsWith(':')) {
            const query = word.slice(1) // Remove o ":" do início

            // console.log('String após o ":" até o espaço:', query)

            const matches = []
            for (let slug in emojiDictionary) {
                if (slug.startsWith(query)) {
                    matches.push(slug)
                }
            }

            // console.log('Número de matches', matches.length)
            // console.log('start', start)
            // console.log('cursorPosition', cursorPosition)

            if (matches.length > 0) {
                setEmojiSuggestions(matches)
                setReplacementIndices({ block, start, cursorPosition })
                return
            }
        }
        setEmojiSuggestions(false)
        setReplacementIndices(null)
     }



    function setChosenEmoji(emoji) {
        const { start, cursorPosition, block  } = replacementIndices

        // console.log('\n\n\nemoji')
        // console.log(emoji)
        // console.log(start)
        // console.log(cursorPosition)
        // console.log(block)

        const currentContent = editorState.getCurrentContent()
        const selectionState = editorState.getSelection().merge({
            anchorOffset: start,
            focusOffset: cursorPosition
        })


    
        const newContent = Modifier.replaceText(
            currentContent,
            selectionState,
            emoji,  // o emoji escolhido para substituir
            editorState.getCurrentInlineStyle()  // mantém o estilo de texto atual se necessário
        );
    
        // Atualiza o estado do editor com o novo contentState
        const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
        setEditorState(newEditorState);
    
        // Limpa os índices após a substituição
        setReplacementIndices(null)
        setEmojiSuggestions(false)
    }


   
    function handleKeyDown(event) {
        if (!editorIsFocused) {
            return
        }

        if (!event.ctrlKey && !event.metaKey && event.key !== 'Tab') {
            return; // Sair se Ctrl não está pressionado e não é Tab
        }
    
        if (event.ctrlKey || event.metaKey) {
            switch (event.key.toLowerCase()) {
                case 'b':
                    event.preventDefault()
                    handleInlineAction('BOLD')
                    break
                case 'i':
                    event.preventDefault()
                    handleInlineAction('ITALIC')
                    break
                case 'u':
                    event.preventDefault()
                    handleInlineAction('UNDERLINE')
                    break
                case 's':
                    event.preventDefault()
                    handleInlineAction('STRIKETHROUGH')
                    break
                case 'h':
                    event.preventDefault()
                    handleInlineAction('HIGHLIGHT')
                    break
                case '1':
                    event.preventDefault()
                    handleBlockType('header-one')
                    break
                case '2':
                    event.preventDefault()
                    handleBlockType('header-two')
                    break
                case '3':
                    event.preventDefault()
                    handleBlockType('header-three')
                    break
                case 'a':
                    event.preventDefault()
                    handleSelectAll()
                    break
            }
        } else if (event.key === 'Tab') {
            event.preventDefault()
            const newEditorState = handleIndent(event)
        }
    }



    useEffect(() => {
        const eventHandler = (e) => handleKeyDown(e, editorState, setEditorState);
        document.addEventListener('keydown', eventHandler);

        return () => {
            document.removeEventListener('keydown', eventHandler);
        };
    }, [editorState]); // A função será recriada quando editorState mudar



    function handleReturn(e, editorState) {
        if (singleLine) {
            return 'handled'
        }
    }


    OnClickOutside(containerRef, () => {
        setEditorIsFocused(false)
    })




    const ToolbarButton = ({ icon, onClick, alt }) => (
        <ToolbarButtonWrapper onClick={onClick}>
          <img src={iconMap[icon]} alt={alt} />
        </ToolbarButtonWrapper>
    )

    

    const iconMap = {
        H1: darkMode ? H1IconDark : H1Icon,
        H2: darkMode ? H2IconDark : H2Icon,
        H3: darkMode ? H3IconDark : H3Icon,
        MARKER: darkMode ? MarkerIconDark : MarkerIcon,
        UNDERLINE: darkMode ? UnderlineIconDark : UnderlineIcon,
        STRIKETHROUGH: darkMode ? StrikethroughIconDark : StrikethroughIcon,
        ITALIC: darkMode ? ItalicIconDark : ItalicIcon,
        BOLD: darkMode ? BoldIconDark : BoldIcon,
        OUTDENT: darkMode ? OutdentIconDark : OutdentIcon,
        INDENT: darkMode ? IndentIconDark : IndentIcon,
        LIST_ORDERED: darkMode ? ListOrderedIconDark : ListOrderedIcon,
        LIST_UNORDERED: darkMode ? ListUnorderedIconDark : ListUnorderedIcon,
        MELTING: darkMode ? MeltingIconDark : MeltingIcon,
      }

    return (
        <TextContainer ref = {containerRef} style = {style} onClick = {() => setEditorIsFocused(true)}>  

            { showToolbar && ((toolbarWithPlaceholder && currentContent == '') || editorIsFocused) && 
                <ToolbarContainer>
                    <ToolbarButton icon="BOLD" onClick={() => handleInlineAction('BOLD')} alt="Bold" />
                    <ToolbarButton icon="ITALIC" onClick={() => handleInlineAction('ITALIC')} alt="Italic" />
                    <ToolbarButton icon="UNDERLINE" onClick={() => handleInlineAction('UNDERLINE')} alt="Underline" />
                    <ToolbarButton icon="STRIKETHROUGH" onClick={() => handleInlineAction('STRIKETHROUGH')} alt="Strikethrough" />
                    <ToolbarButton icon="MARKER" onClick={() => handleInlineAction('HIGHLIGHT')} alt="Highlight" />
                    <ToolbarButton icon="LIST_ORDERED" onClick={() => handleBlockType('ordered-list-item')} alt="Ordered List" />
                    <ToolbarButton icon="LIST_UNORDERED" onClick={() => handleBlockType('unordered-list-item')} alt="Unordered List" />
                    <ToolbarButton icon="INDENT" onClick={() => handleIndent({ preventDefault: () => {}, stopPropagation: () => {}, shiftKey: false })} alt="Indent List" />
                    <ToolbarButton icon="OUTDENT" onClick={() => handleIndent({ preventDefault: () => {}, stopPropagation: () => {}, shiftKey: true })} alt="Outdent List" />
                    <ToolbarButton icon="H1" onClick={() => handleBlockType('header-one')} alt="Header 1" />
                    <ToolbarButton icon="H2" onClick={() => handleBlockType('header-two')} alt="Header 2" />
                    <ToolbarButton icon="H3" onClick={() => handleBlockType('header-three')} alt="Header 3" />
                    <ToolbarButton icon="MELTING" onClick={() => handleEmojiMenuClick()} alt="Emoji" />
              </ToolbarContainer>
            }

            <EmojiSuggestions
                matches = {emojiSuggestions}
                setChosenEmoji = {setChosenEmoji} />

            <EditorContainer 
                ref={editorRef} 
                onClick = {() => {
                    // Garante que, se clicar fora das linhas, foca na última.
                    const selection = editorState.getSelection();
                    if (!selection.getHasFocus()) {
                        setEditorState(editorState => EditorState.moveFocusToEnd(editorState))
                    }
                    setEditorIsFocused(true)}}

                isFocused={editorIsFocused}

                singleLine = {singleLine}
                > 
                    
                    <GlobalStyles />




                    <Editor
                        editorState={editorState}
                        onChange={onChange}
                        ref={editorRef}
                        customStyleMap={getStyleMap(darkMode)}

                        placeholder = {placeholder}

                        // onBlur={() => setEditorIsFocused(false)}
                        handleReturn={handleReturn}
                        blockStyleFn={(contentBlock) => {
                            const type = contentBlock.getType();
                            if (type === 'unstyled' && singleLine) {
                                return 'customHeaderOne';
                              }
                              return null;

                        }}
                        // readOnly={!editorIsFocused}
                    />

            </EditorContainer>
        </TextContainer>
    )
}
