import React, { useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import CheckoutInfo from './CheckoutInfo'
import CheckoutPayment from './CheckoutPayment'
import { theme } from './CheckoutStyles'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getSubscriptionType, reloadUser, shouldAllowPayment } from '../../firebase/firebaseUtils'
import { setIsLoading } from '../../redux/loadingSlice'

const Container = styled.div`
    display: flex;
    min-height: 100vh;
    background: ${theme.colors.background};
    font-family: ${theme.fonts.primary};
    
    @media (max-width: 64em) {
        flex-direction: column;
    }
`


export default function Checkout() {

    const navigate = useNavigate()
    const dispatch = useDispatch()


    useEffect(() => {
        async function load() {
            dispatch( setIsLoading(true) )

            const newUser = await reloadUser()
            const buyingAllowed = shouldAllowPayment(getSubscriptionType(newUser))
    
            console.log(newUser)
            console.log(buyingAllowed)            
    
            if (!buyingAllowed) {
                // Não deveria poder comprar
                navigate('/user')
            }

            dispatch( setIsLoading(false) )
        }

        load()
    }, [])

    function onBuy() {
        navigate('/app')
    }

    return (
        <Container>
            <CheckoutInfo />
            <CheckoutPayment onBuy={onBuy} />
        </Container>
    );
}

