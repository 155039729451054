import React, { useState } from 'react'
import { styled } from 'styled-components'
import AmbassadosStatisticCard from './AmbassadosStatisticCard'

import { ComposedChart, Bar, Line, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import data from './AmbassadorsController'

const AmbassadorsCountContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    // background-color: yellow;
    background-color: #efefef;
    align-self: center;
    margin-top: 2em;
    padding: 2em;
`


const GraphContainer = styled.div`
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`


const UsersCountContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    // background-color: blue;
    width: 50%;
    height: 100%;
`


const AmbassadorsCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    width: 100%;
    padding: 2em;
`

export default function AmbassadorsCount(props) {
    const [coupons, setCoupons] = useState(false)

    return (
        <AmbassadorsCountContainer>

            <GraphContainer>
                <ComposedChart width={700} height={500} data={data.monthlyAmbassadorCount}>
                    <XAxis dataKey="month" />
                    <YAxis yAxisId="left" orientation="left" stroke="#8884d8"/>
                    <YAxis yAxisId="right" orientation="right" stroke="#82ca9d"/>
                    <Tooltip />
                    <Legend />
                    <Bar yAxisId="left" stackId = 'a' dataKey="newAmbassadors" fill="#8884d8" name="Novos" />
                    <Bar yAxisId="left" stackId = 'a' dataKey="terminatedAmbassadors" fill="red" name="Removidos" />
                    <Line yAxisId="right" type="monotone" dataKey="cumulativeAmbassadors" stroke="#82ca9d" name="Total Cumulativo" />
                </ComposedChart>   
            </GraphContainer>


            <UsersCountContainer>
                <AmbassadorsCardsContainer>
                    <AmbassadosStatisticCard 
                        emoji = '👥'
                        number = {data.activeAmbassadors.length}
                        text = 'Ativos'
                        onClick = {() => setCoupons(data.activeAmbassadors)}
                        clicked = { coupons == data.activeAmbassadors } />
                    
                    <AmbassadosStatisticCard 
                        emoji = '📁'
                        number = {data.pendingOnboardingAmbassadors.length}
                        text = 'Onboarding'
                        onClick = {() => setCoupons(data.pendingOnboardingAmbassadors) }
                        clicked = { coupons == data.pendingOnboardingAmbassadors } />
                    
                    <AmbassadosStatisticCard 
                        emoji = '💀'
                        number = {data.terminatedAmbassadors.length}
                        text = 'Desligados'
                        onClick = {() => setCoupons(data.terminatedAmbassadors) }
                        clicked = { coupons == data.terminatedAmbassadors } />
                </AmbassadorsCardsContainer>

                { coupons && 
                    <p style = {{textAlign: 'center'}}>
                        {coupons.map((e, index) => (
                            <span key={index}>
                                <span style={{ fontWeight: 'bold', color: 'gray', fontSize: '0.9em' }}>{e}</span>
                                {index < coupons.length - 1 && <span style={{ color: 'gray', fontSize: 'small' }}> | </span>}
                            </span>
                        ))}
                    </p>
                }
                { !coupons &&
                    <p>Em busca da meta.</p>
                }

            </UsersCountContainer>

        </AmbassadorsCountContainer>
    );
};
