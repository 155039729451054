import { db } from './../firebase/firebase-setup'
import OslerData from './OslerData';
import userReviews from './UserReviewsInfo'
import firebase from 'firebase/compat/app';

/*
    TODAS AS MODIFICAÇÕES RELACIONADAS A TESTS LIKED OU BURIED
    DEVEM OCORRER ATRAVÉS DESSA CLASSE, E NUNCA MANIPULANDO
    DIRETAMENTE O QUE ESTÁ EM USER-REVIEWS-INFO OU O FIREBASE.

    Atente-se que após a 1ª modificação, o que está em UserReviewsInfo
    poderá estar desatualizado, e não deve ser utilizado.
*/


class LikedBuriedController {
    prepare(userID) {
        this.userID = userID

        /*Lista de IDs das buried e liked, respectivamente.
            {
                'Flashcards' : [...],
                'Residencia' : [...]
            }
        } */
        this.buried = {}
        this.liked  = {}
    }


    load(testType, buried, liked) {
        this.buried[testType] = buried
        this.liked[testType]  = liked
    }

    isLiked(testType, testID) {
        return this.liked && this.liked[testType] && this.liked[testType].includes(testID)
    }


    isBuried(testType, testID) {
        return this.buried && this.buried[testType] && this.buried[testType].includes(testID)
    }

    
    async likeOrDislike(testType, testID) {
        await this.likeOrDislikeAll(testType, [testID]);
    }
    
    async buryOrUnbury(testType, testID) {
        await this.buryOrUnburyAll(testType, [testID]);
    }


    async likeOrDislikeAll(testType, testIDs) {
        await this.changeStatus('liked', testType, testIDs);
    }
    
    async buryOrUnburyAll(testType, testIDs) {
        await this.changeStatus('buried', testType, testIDs);
    }


    async changeStatus(type, testType, testIDs) {
        const array = (type === 'liked') ? this.liked : this.buried;
        const changesToAdd = [];
        const changesToRemove = [];

        testIDs.forEach(testID => {
            const index = array[testType].indexOf(testID);
        
            if (index >= 0) {
                changesToRemove.push(testID);
            } else {
                array[testType].push(testID);
                changesToAdd.push(testID);
            }
        });
    
        // Aplicar a remoção após a iteração para evitar modificar o array durante a iteração
        array[testType] = array[testType].filter(id => !changesToRemove.includes(id));

        if (type === 'liked') {
            this.liked = array
        }
        else {
            this.buried = array
        }



    
        const path = `/users/${this.userID}/personal/${testType.toLowerCase()}-${type}`;
        if (changesToAdd.length > 0) {
            await db.doc(path).set({
                'data': firebase.firestore.FieldValue.arrayUnion(...changesToAdd)
            }, {merge: true});
        }
        if (changesToRemove.length > 0) {
            await db.doc(path).set({
                'data': firebase.firestore.FieldValue.arrayRemove(...changesToRemove)
            }, {merge: true});
        }
    }


//     changeStatus(type, testType, testID) {
//         // Qual array estamos usando?
//         const array = (type === 'liked') ? this.liked : this.buried

//         // O teste já é liked/buried?
//         const index = array[testType].indexOf(testID)


//         // Iremos ou inserir ou remover algo do array. Na sequência,
//         // precisamos gravar esse array atualizado no Firebase.
//         //
//         // Utilizamos arrayUnion ao invés de manipular o array inteiro para evitar
//         // que, por algum bug, se o array local estiver corrompido, o usuário perca
//         // TODOS os dados.
//         let change;

//         if (index >= 0) {
//             array[testType].splice( index, 1 )
//             change = firebase.firestore.FieldValue.arrayRemove(testID)
//         }
//         else {
//             array[testType].push(testID)
//             change = firebase.firestore.FieldValue.arrayUnion(testID)
//         }

//         // Atualizamos o Firebase.
//         const path = `/users/${this.userID}/personal/${testType.toLowerCase()}-${type}`
        
//         console.log(path)

//         db.doc(path).set({
//             'data' : change
//         }, {merge: true})
//     }
}

export default new LikedBuriedController()