import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { marked } from 'marked'
import StickyIcon from './../assets/sticky-note.png'
import PersonalNotes from '../controllers/PersonalNotes'
import Session from '../controllers/Session'
import OslerTextEditor from './OslerTextEditor'
import OslerButton from '../components/OslerButton'
import { useSelector } from 'react-redux'
import { textWhiteBlack } from './FlashcardsStyles'


const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 100%;
    border-radius: 0.5em;
    margin-top: 2em;
    padding: 1em;

    ${props => props.theme.darkMode ? `
        background-color: #7F6E00;
        box-shadow: none;
    `:`
        background-color: #fff170;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    `}

    @media (max-width: 500px){
        width: 95%;
    }
`



const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    
`


const Left = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Right = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0.3em;
`

const Title = styled.div`
    font-size: 1.1em;
    font-weight: bold;
    ${textWhiteBlack}
    margin: 0;
`

const ImgBttn = styled.img`
    height: 2em;
    margin-right: 0.5em;
`



export default function PersonalNoteJSX({ test, close, style }) {

    // const [noteContent, setNoteContent] = useState(false)

    const noteContent = useRef()
    const testID = useRef()

    const darkMode = useSelector(state => state.theme.darkModeOn)


    // Só para forçar atualização do JSX. Infelizmente, sim, precisa manter os dois.
    const [noteState, setNoteState] = useState()


    useEffect(() => {
        console.log(test)
        if (test && test.personalNote) {
            noteContent.current = test.personalNote
            setNoteState(test.personalNote)

            console.log('Encontarmos o conteúdo')
            console.log(noteContent.current)
        }
        testID.current = test.testID
    }, [test])


    function save() {
        if (test.personalNote !== noteContent.current) {
            console.log('PersonalNoteJSX: iremos de fato salvar, houve mudança')
            PersonalNotes.savePersonalNote(
                Session.testType,
                testID.current,
                noteContent.current
            )

            for (let test of Session.session) {
                if (test.testID === testID.current) {
                    console.log('PersonalNoteJSX: modificamos o teste em Session')
                    test.data.personalNote = noteContent.current

                    console.log(Session.session)

                    break
                }
            } 
        } else {
            console.log('PersonalNoteJSX: NÃO houve mudança')
        }
    }


    async function deleteNote() {
        for (let test of Session.session) {
            if (test.testID === testID.current) {
                test.data.personalNote = undefined
                break
            }
        }
    
        noteContent.current = ''
        setNoteState('')

        PersonalNotes.deletePersonalNote(
            Session.testType,
            testID.current
        )

        if (close) {
            close()
        }
    }


    useEffect(() => {
        // Grante que iremos salvar ao desmontar o componente
        return () => {
            console.log('PersonalNoteJSX: componente está sendo desmontado')
            if (noteContent.current) {
                console.log('PersonalNoteJSX: consideramos salvar...')
                save()
            }
        }
    }, [])


    function closeNote() {
        if (close) {
            save()
            close()
        }
    }

    return (
        <Card style = {style}>
            <Header>
                <Left>
                    <ImgBttn src = {StickyIcon} />
                    <Title>Sua Nota</Title>
                </Left>     

                <Right>
                    { noteState && noteState != '' && 
                        <OslerButton
                            size = 'small'
                            color = 'red'
                            text = 'Deletar'
                            onClick = {() => deleteNote()}
                            style = {{marginRight: '1em'}} />
                    }

                    <OslerButton
                        size = 'small'
                        color = 'gray'
                        text = 'Fechar'
                        onClick = {() => closeNote()} />

                </Right>
            </Header>

            <OslerTextEditor
                showToolbar = {true}
                updateParent = {content => {
                    noteContent.current = content
                    setNoteState(content)
                }}
                previousContent = {noteState}
                placeholder = 'Qual dúvida você teve, ou insight desenvolveu?'
                singleLine = {false}
                style = {{padding: '1em', backgroundColor: darkMode ? '#7F6E00' : '#fff170'}} />
        </Card>
    )
}
