import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import GeneralDialog from '../tests/GeneralDialog'
import DialogAction from './ExamsDialogAction'
import ReactSwitch from 'react-switch'

import examIcon from './../assets/exam.png'
import fullExamIcon from './../assets/exam.png'
import reviewIcon from './../assets/tree/dumbbell.png'
import newQuestionsIcon from './../assets/tree/notebook.png'
import consultModeIcon from './../assets/books.png'
import playgroundModeIcon from './../assets/sandbox.png'
import SessionBuilder from '../controllers/SessionBuilder'
import { PredefinedSessionConfig, SessionConfig } from '../controllers/SessionConfig'
import { useDispatch } from 'react-redux'
import { setIsLoading } from '../redux/loadingSlice'
import Session from '../controllers/Session'
import { toastMsg } from '../utils/Utils'
import { useNavigate } from 'react-router-dom'

const DialogContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 15px;
        padding: 10px;
    }
`

const SimulatedModeSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.darkMode ? '#2c3e50' : '#f0f4f8'};
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 15px;
        padding: 15px;
    }
`

const SimulatedModeInfo = styled.div`
    flex: 1;

    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
    }
`

const SimulatedModeTitle = styled.h3`
    font-size: 1.2em;
    margin-bottom: 8px;
    color: ${props => props.theme.darkMode ? '#ecf0f1' : '#2c3e50'};
    
    @media (max-width: 768px) {
        font-size: 1.1em;
        margin-bottom: 6px;
    }
`

const SimulatedModeDescription = styled.p`
    font-size: 0.9em;
    color: ${props => props.theme.darkMode ? '#bdc3c7' : '#34495e'};
    max-width: 70%;

    @media (max-width: 768px) {
        font-size: 0.85em;
        max-width: 100%;
        margin: 0 auto;
    }
`

const ActionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;

    @media (max-width: 768px) {
        max-width: 100%;
        padding: 0 10px;
    }

    @media (max-width: 480px) {
        padding: 0 5px;
    }
`

export default function ExamDialog({ selectedExam, showDialog, setShowDialog }) {
    const [simulatedMode, setSimulatedMode] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [predefinedIDs, setPredefinedIDs] = useState({
        'new': [],
        'review': []
    })

    const [configVar, setConfigVar] = useState(false)


    useEffect(() => {
        if (selectedExam) {
            setConfigVar({
                'institutions': [selectedExam.institution],
                'years': [selectedExam.year],
            })
        }
    }, [selectedExam])


    useEffect(() => {
        function fetchTests(config) {
            return SessionBuilder.simulate('Residencia', 'custom', false, config)
        }

        const newConfig = SessionConfig.createTestMode('Residencia', {
            removePendingReviews: true,
            ...configVar
        })


        const reviewConfig = SessionConfig.createTestMode('Residencia', {
            removeNewTests: true,
            ...configVar
        })


        setPredefinedIDs({
            'new':    fetchTests(newConfig),
            'review': fetchTests(reviewConfig)
        })

    }, [configVar])


    async function loadSession(option) {
        dispatch(setIsLoading(true))

 
        let config = null

        switch (option) {
            case 'all':
                config = SessionConfig.createConfig('Residencia', {
                    removeFutureReviews: false,
                    removeSolved: false,
                    ...configVar
                })

                customSession('test-mode', config)
                break
    
            case 'only_reviews':
                predefinedSession(predefinedIDs.review, 'test-mode')
                break

            case 'only_news':
                predefinedSession(predefinedIDs.new, 'test-mode')
                break

            case 'playground':
                config = SessionConfig.createPlaygroundOrConsultMode('Residencia', configVar)
                customSession('playground-mode', config)
                break

            case 'consult':
                config = SessionConfig.createPlaygroundOrConsultMode('Residencia', configVar)
                customSession('consult-mode', config)
                break
        }

        // Isso tá repetido aqui, em CustomStudy, em AcademicTrackMain...
        if (Session.sessionSize > 0) {
            Session.addTemporaryListener(option === 'consult' ? moveToConsult : moveToTests)
        }
        else {
            dispatch(setIsLoading(false))
            toastMsg("Não esperávamos por esse erro... Manda um print por DM. Desculpa. 🫠")
        }
    }


    function customSession(mode, config) {
        SessionBuilder.start(
            'Residencia',
            'custom',
            mode,
            [],
            config,
            mode === 'test-mode',
        )
    }

    function predefinedSession(testIDs, mode) {
        SessionBuilder.start(
            'Residencia',
            'predefined',
            mode,
            testIDs,
            PredefinedSessionConfig.create('Residencia'), 
            mode === 'test-mode',
        )
    }


    function moveToTests() {
        navigate("/test")
        dispatch(setIsLoading(false))
    }


    function moveToConsult() {
        navigate("/consult")
        dispatch(setIsLoading(false))
    }


    return (
        <GeneralDialog
            
            open={showDialog}
            icon={examIcon}
            title={selectedExam ? `${selectedExam.institution} - ${selectedExam.year}` : ''}
            onClose={() => setShowDialog(false)}
        >
            <DialogContent>
                <SimulatedModeSection>
                    <SimulatedModeInfo>
                        <SimulatedModeTitle>Modo Simulado</SimulatedModeTitle>
                        <SimulatedModeDescription>
                            Neste modo, a plataforma funcionará como uma prova real: você só verá as respostas após encerrar todas as questões.
                        </SimulatedModeDescription>
                    </SimulatedModeInfo>
                    <ReactSwitch
                        checked={simulatedMode}
                        onChange={() => setSimulatedMode(!simulatedMode)}
                        onColor="#86d3ff"
                        offColor="#767577"
                        offHandleColor="#c4c4c4"
                        onHandleColor="#2693e6"
                        handleDiameter={24}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                    />
                </SimulatedModeSection>

                <ActionsContainer>
                    <DialogAction
                        main={<><img src={fullExamIcon} alt='Full Exam' /><p>Prova</p></>}
                        explanation='Todas as questões da prova, independente de já tê-las visto, para entender sua performance.'
                        onClick={() => loadSession('all')}
                    />

                    { predefinedIDs.new.length > 0 &&
                        <DialogAction
                            main={<><img src={newQuestionsIcon} alt='New Questions' /><p>Novas</p></>}
                            explanation='Somente as questões que não viu até agora, ideal para completar todas as questões da prova.'
                            onClick={() => loadSession('only_news')}
                        />
                    }

                    { predefinedIDs.review.length > 0 &&
                        <DialogAction
                            main={<><img src={reviewIcon} alt='Review' /><p>Revisar</p></>}
                            explanation='Somente as revisões pendentes, conforme Caderno de Erros, para rever o que não sabe e aumentar sua % de acerto.'
                            onClick={() => loadSession('only_reviews')}
                        />                    
                    }

                    <DialogAction
                        main={<><img src={consultModeIcon} alt='Consult Mode' /><p>Consulta</p></>}
                        explanation='Você visualizará as questões, incluindo gabarito e explicação, sem precisar responder.'
                        onClick={() => loadSession('consult')}
                    />
                    <DialogAction
                        main={<><img src={playgroundModeIcon} alt='Playground Mode' /><p>Playground</p></>}
                        explanation='Responda todas as questões da prova, mas sem afetar suas estatísticas.'
                        onClick={() => loadSession( 'playground')}
                    />
                </ActionsContainer>
            </DialogContent>
        </GeneralDialog>
    )
}