import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db, functions} from '../firebase/firebase-setup'
import UserReviewsInfo from '../controllers/UserReviewsInfo';



export async function measureTime(task, taskName) {
    const start = performance.now()

    const result = await task()

    const end = performance.now()

    // Arredonda para o segundo mais próximo
    const timeInSeconds = ((end-start) / 1000).toFixed(1);
    console.log(`${taskName} in ${timeInSeconds} seconds`)

    return result
}

export function sortMonths(A, B) {
    const [yearA, monthA] = A.split('_').map(Number);
    const [yearB, monthB] = B.split('_').map(Number);

    if (yearA !== yearB) {
        return yearA > yearB ? 1 : -1;
    }
    return monthA > monthB ? 1 : -1;
}


export function getFlashcardDeckRoot(testID) {
    return testID.split('_')[1];

}

export function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}


export function dateSlashIntoDateUnderline(date_slash) {
    const [day, month, year] = date_slash.split('/');
    const yearMonth = `${year}_${parseInt(month, 10)}`; // Isso removerá o zero à esquerda

    return yearMonth
}


export function dateObjIntoStr(date) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()

    return `${year}-${month}-${day}`;
}


export function sortIDsByTagpath(testType, IDs) {
    // Agrupamos os IDs por tagpath, para facilitar a visualização.
    const idsByTagpath = {}

    for (let ID of IDs) {
        const tagpath = UserReviewsInfo.getGivenTestTagPath(testType, ID)

        if ( idsByTagpath[tagpath] ) {
            idsByTagpath[tagpath].push(ID)
        }
        else {
            idsByTagpath[tagpath] = [ID]
        }
    }

    return idsByTagpath;
}




export function arraysHaveSameUniqueElements(arr1, arr2) {
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);

    if (set1.size !== set2.size) {
        return false;
    }

    for (let item of set1) {
        if (!set2.has(item)) {
            return false;
        }
    }

    return true;
}


export function removeHTMLTags(str) {
    return str.replace(/<[^>]*>/g, '');
  }


export async function checkCouponForError(coupon) {
    let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    if (coupon == '') {
        return(<p className = 'UserAmbassador-SignUpError'><strong>Você precisa digitar um cupom.</strong> Por favor, escolha outro.</p>)
    }
    else if (coupon.length <= 4) {
        return(<p className = 'UserAmbassador-SignUpError'><strong>Cupom muito pequeno.</strong> Por favor, escolha outro.</p>)
    }
    else if (coupon.length > 12) {
        return(<p className = 'UserAmbassador-SignUpError'><strong>Cupom muito grande.</strong> Por favor, escolha outro.</p>)
    }
    else if (format.test(coupon) ) {
        return(<p className = 'UserAmbassador-SignUpError'><strong>Cupom não pode ter símbolos.</strong> Por favor, limite-se a letras e números.</p>)
    }
    else {
        const check = functions.httpsCallable('checkIfUsedCoupon')
        const result = await check({
            desired_coupon : coupon
        })
        
        if (result.data.alreadyUsed) {
            return(<p className = 'UserAmbassador-SignUpError'><strong>Esse cupom não é válido!</strong> Por favor, escolha outro.</p>)
        }   
        else {
            return false;
        }
    }
}

export function createCheckboxesOptions(strings) {
    // Nós recebemos um array de strings, cada uma dos quais corresponde
    // a uma opção desejada para os checkboxes.
    //     Premissa: strings diferentes entre si.
    //
    // Os checkboxes requerem uma (para diferenciar) e
    // um texto para ser exibido. Faremos ambos, basicamente, iguais.
    // console.log(strings)
    
    let options = []
    for (let string of strings) {
        options.push({
            text: string,
            id: string.replaceAll(' ', '')
        })
    }

    return options
}

export function extractCheckboxesOptions(options) {
    // Inverso da função acima.
    let strings = []
    for (let option of options) {
        strings.push(option.text)
    }

    return strings
}

export function errorIntoDict(error) {
        // Construímos algo útil a partir do erro. A princío,
        // essas propriedades existem em todo Error e FirebaseError,
        // embora sejam opcionais e nem sempre úteis.
        //
        //      https://firebase.google.com/docs/reference/js/v8/firebase.FirebaseError
        //      https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#instance_properties

        return {
            'name' : error.name,
            'code' : error.code,
            'message' : error.message,
            'stack' : error.stack,
            'other' : `${error.fileName} - line: ${error.lineNumber} - column: ${error.columnNumber}`
        }
}


// You can set the theme on App.css
export function toastMsg(msg) {
    toast.dark(msg, {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        style: {width: '600px', fontSize: '1.2em'}
    })
}


export function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}


export function pathToTagSequence(path) {
    return path.split("/")
}


export function tagSequenceToPathS(tagSequence) {
    // Recebemos um array de tags: [T1, T2, T3, ...]
    // Todo teste pode ser encaixado em um nível mais
    // ou menos específico de tags.
    //
    // Por exemplo, o teste pertence tanto a 'T1' quanto
    // a 'T1/T2' e 'T1/T2/T3', etc.
    //
    // Nós devolvemos essas combinações de tags em um array.
    let paths = []
    for (let i = 1; i <= tagSequence.length; i++) {
        let tagSubSequence = [...tagSequence].splice(0, i)
        paths.push( tagSequenceToPath(tagSubSequence) )
    }

    return paths;
}


/**
 * Método que gera os PATHS referente a cada um dos nós da árvore em que a questão está contida
 * Ex: considerando o conjunto de tags (T1, T2, T3 e T4), a função devolverá um array com os seguintes PATHS:
 * ['T1', 'T1/T2', 'T1/T2/T3', 'T1/T2/T3/T4']
 * @param {*} tags conjunto de tags das questões
 * @returns Array com os PATHS de todos os níveis da árvore em que a questão está contida
 */
// export function allTagSequencesToPaths(tags) {
//     const tagSequences = []
//     let aux = ''
//     for(let i=0; i < tags.length; i++) {
//         if(tags[i] !== null && tags[i] !== 'null') {
//             if(i === 0) {
//                 aux = tags[i]
//             } else {
//                 aux = aux.concat(`/${tags[i]}`)
//             }

//             tagSequences.push(aux)
//         }
//     }

//     return tagSequences
// }


export function allTagSequencesToPaths(tags) {
    const index = tags.indexOf('null');
    const validTags = index === -1 ? tags : tags.slice(0, index);

    return validTags.map((tag, i) => validTags.slice(0, i + 1).join('/'));
}


export function tagSequenceToPath(tagSequence, delimiter = '/') {
    const index = tagSequence.indexOf('null');
    const validTags = index === -1 ? tagSequence : tagSequence.slice(0, index);

    return validTags.join(delimiter);
}


export function deckRootFromFlashcardID(ID) {
    // obsceno, está redundante com getFlashcardDeckRoot
    
    const parts = ID.split('_');
    return parts[1];
}


// export function tagSequenceToPath(tagSequence, delimiter = '/') {
//     let path = ""

//     for (let i = 0; i < tagSequence.length - 1; i++) {
//         path += (tagSequence[i] + delimiter)
//     }
//     path += tagSequence[tagSequence.length -1]

//     return path
// }


export function subtractArrFromArr(objectsToSubtract, arrayFromWhichSubtract) {
    // O nome é intuitivo. Para cada elemento do array objectsToSubtract,
    // nós iremos remover a primeira ocorrência dele em arrayFromWhichSubtract.
    for (let index = 0; index < objectsToSubtract.length; index++) {
        let object = objectsToSubtract[index]
        let pos = arrayFromWhichSubtract.indexOf(object)

        if (pos !== -1) {
            arrayFromWhichSubtract.splice(pos, 1);
        }
    }
}

export function addToArrIfUnique(array, arrayOfElements) {
    for (let index = 0; index < arrayOfElements.length; index++) {
        let element = arrayOfElements[index]

        if (array.indexOf(element) == -1) array.push(element)
    }
}


export function getDateFormattedForFirebase(date) {
    // No firebase, quando vamos criar o TÍTULO de um documento
    // ou coleção, não podemos usar '/'.
    //
    // Logo, se queremos associar ao título uma data (dia-mês-ano),
    // devemos usar hífen.
    //
    // É o que fazemos aqui.
    //
    // Para facilitar a inspeção no Firebase, construímos a string
    // ao contrário: ano -> mês -> dia
    let str = ""
    str += date.getFullYear() + "-"
    str += ( parseInt(date.getMonth(), 10) + 1 ) + '-'
    str += date.getDate()

    return str;
}


export function dateIntoString(date) {
    // Algumas observações.
    //  getMonth() retorna janeiro como mês 0, então incrementamos.
    //  getHour() e getMinutes() retornam inteiros. Para formatar,
    //      por ex, meia noite como 00:00, precisamos sempre adicionar
    //      um 0 antes se menor que 10.

    let hour = date.getHours();
    if (hour < 10) {
        hour = "0" + hour;
    }

    let minutes = date.getMinutes();
    if (minutes < 10) {
        minutes = "0" + minutes;
    }

    const time = hour + ":" + minutes

    let month = parseInt(date.getMonth(), 10)
    month += 1;

    const day  = date.getDate() + "/" + month + "/" + date.getFullYear();

    return time + ", " + day
}


export function statisticsDateToReadable(date_str) {
    // YYYY-M-D -> D/M/YYYY
    const [year, month, day] = date_str.split('-');
    
    // Formata o resultado para o formato D/M/YYYY, incluindo zero à esquerda para dia/mês menores que 10
    const formattedDay = day.padStart(2, '0');
    const formattedMonth = month.padStart(2, '0');
    
    // Retorna a string formatada no padrão desejado
    return `${formattedDay}/${formattedMonth}/${year}`;
}


export function getDateFormattedForExtensivo(date) {
    // e.g., 2023-01-16

    let year = date.getFullYear()

    let month = parseInt(date.getMonth(), 10) + 1
    // if (month < 10) {
    //     month = "0" + month
    // }

    let day = parseInt(date.getDate())
    if (day < 10) {
        day = "0" + day
    }


    return year + '-' + month + '-' + day
    // Isso mudou
    // return day + '/' + month + '/' + year
}


export function compareFirebaseDates(date1, date2) {
    // return -1 if date 1 comes before date 2
    const [y1, m1, d1] = date1.split('-').map(Number)
    const [y2, m2, d2] = date2.split('-').map(Number)

    if (y1 < y2) {
        return -1
    }
    else if (y1 > y2) {
        return 1
    }
    else {
        if (m1 < m2) {
            return -1
        }
        else if (m1 > m2) {
            return 1
        }
        else {
            if (d1 < d2) {
                return -1
            }
            else if (d1 > d2) {
                return 1
            }
            else {
                return 0
            }
        }
    }
}


export function sanitizeStringDay(str) {
    // Recebe 2021-6-9 e devolve 09/06/2021
    const parts = str.split('-')

    const day = Number(parts[2]) <= 9 ? `0${parts[2]}` : parts[2]
    const mo  = Number(parts[1]) <= 9 ? `0${parts[1]}` : parts[1]
    const yr  = parts[0].slice(2)

    return (day + '/' + mo + '/' + yr)
}



export function tagsFromArrayToFirebase(tagsArr) {
    let tags = {}
    for (let i = 1; i <= 4; i++) {
        let tag_ref = "T" + i;
        if (tagsArr[i-1] !== undefined) {
            tags[tag_ref] = tagsArr[i-1]
        }
        else {
            tags[tag_ref] = "null"
        }
    }

    return tags;
}

export function extractTagsFromFirebaseDataToArray(data) {
    let tags = []

    for (let i = 1; i <= 4; i++) {
        let tag_ref = "T" + i;

        if (data[tag_ref] == undefined || data[tag_ref] === "null") {
            break;
        }
        else {
            tags.push(data[tag_ref])
        }
    }

    return tags;
}


export function shuffleArray(array) {
    // De nota, Math.random() retorna número 
    // no intervalo [0, 1[
    // Fisher-Yates Algorithm

    // Percorremos o array, trocando cada elemento
    // com outro de posição aleatória ANTES dele.
    //
    // Logo, somente corremos até o penúltimo elemento.
    for (let i = array.length - 1; i > 0; i--) {
        const random = Math.floor( Math.random() * i)

        const tempElement = array[i]
        array[i] = array[random]
        array[random] = tempElement
    }

    return array;
}


export function secondsToReadableTime(amountSeconds) {
    // Transformamos segundos em '1h20min', por exemplo.

    const hours   = Math.floor(amountSeconds / 3600)

    const minutes = Math.ceil((amountSeconds % 3600) / 60)

    return `${hours}h${minutes}min`
}

/**
 * Sleep millis
 * @param {int} milliseconds quantidade de milisegundos para aguardar
 */
export function takeABreak(milliseconds) {
    setTimeout(() => {console.log(`Taking a break for ${milliseconds} ms`)}, milliseconds)
}

/**
 * Verifica se um card é do tipo cloze card, isto é,
 * se o ID dele termina com um padrão "_clz[número(s)]"
 */
// export function isClozeCard(cardID) {
//     return /^\w+_clz\d+$/.test(cardID)
// }

export function isClozeCard(cardID) {
    return cardID.includes('_clz')
}


export function getAlphabetLetters(quantity) {
    let alpha_codes = Array.from(Array(quantity)).map((_, i) => i + 65)
    let alfabeto = alpha_codes.map(x => String.fromCharCode(x))
    return alfabeto
}


