import React from 'react';
import styled from 'styled-components';



function DialogAction({main, explanation, onClick}) {
    return (
        <DialogActionContainer onClick={onClick}>
            <Content>
                <MainContent>{main}</MainContent>
                <Explanation>{explanation}</Explanation>
            </Content>
            <Arrow>›</Arrow>
        </DialogActionContainer>
    )
}

const DialogActionContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.2em 1.5em;
    margin-bottom: 1em;
    background-color: ${props => props.theme.darkMode ? 'rgba(50, 50, 50, 0.8)' : 'rgba(255, 255, 255, 0.8)'};
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
        background-color: ${props => props.theme.darkMode ? 'rgba(60, 60, 60, 0.9)' : 'rgba(255, 255, 255, 0.9)'};
        cursor: pointer;
    }

    @media (max-width: 768px) {
        padding: 1em;
        margin-bottom: 0.8em;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-right: 1em;

    @media (max-width: 768px) {
        padding-right: 0.5em;
    }
`

const MainContent = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;

    p {
        font-size: 1.2em;
        font-weight: 600;
        margin: 0;
        color: ${props => props.theme.darkMode ? '#ffffff' : '#333333'};
    }

    img { 
        margin-right: 0.8em;
        height: 1.8em;
        width: 1.8em;
        object-fit: contain;
    }
`

const Explanation = styled.p`
    color: ${props => props.theme.darkMode ? '#b0b0b0' : '#666666'};
    font-size: 0.9em;
    margin: 0;

    @media (max-width: 768px) {
        font-size: 0.7em !important;
    }
`

const Arrow = styled.span`
    font-size: 1.8em;
    color: ${props => props.theme.darkMode ? '#b0b0b0' : '#666666'};
    transition: transform 0.3s ease;

    ${DialogActionContainer}:hover & {
        transform: translateX(5px);
        color: ${props => props.theme.darkMode ? '#ffffff' : '#333333'};
    }

    @media (max-width: 768px) {
        font-size: 1.5em;
    }
`

export default DialogAction;