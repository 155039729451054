
import React, { useState, useRef, useEffect } from 'react'
import { db, storage } from '../firebase/firebase-setup'
import { useSelector } from 'react-redux' 
import './AmbassadorScreen.css'
import { json, useNavigate } from "react-router-dom";
import Logo from './../assets/Logo.png'
import Signature from './../assets/signature.png'
import AmbassadorMonthly from './AmbassadorMonthly';
import UserAmbassador from '../user/UserAmbassador';
import AppContainer from '../app-container/AppContainer';
import { Link } from 'react-router-dom'

export default function AmbassadorScreen() {

    const user = useSelector(state => state.user.data)
    const [isAmbassador, setIsAmbassador] = useState(false)
    const [loadedData, setLoadedData] = useState(false)

    const ambassador_registry = useRef(false)
    const ambassador_profile = useRef(false)


    const last_update = useRef(false)

    useEffect(() => {
        console.log('oiiiiii')
        update()
    }, [])

    async function update() {
        // Descobrimos se já é embaixador
        const doc = await db.doc(`users/${user.id}/ambassador/personal_registry`).get()

        if (doc.exists) {
            ambassador_registry.current = doc.data()
        }
        else {
            console.log('oops, doc não existe')
            setIsAmbassador(false)
            setLoadedData(true)
            ambassador_registry.current = false
            return
        }

        console.log('doc1 existe')


        const doc2 = await db.doc(`users/${user.id}/`).get()
        if (doc2.exists) {
            const profile = doc2.data()

            ambassador_profile.current = {
                name : profile['firstName'] + profile['lastName'],
                email : profile['email'],
                phone: profile['phone'],
                school: profile['school'],
                school_stage: profile['school_stage'],
                uid: user.id,
                current_coupon: ambassador_registry.current['current_coupon'],
                previous_coupons: ambassador_registry.current['previous_coupons'],
                signup_date: ambassador_registry.current['signup_date'],
                isActive: profile['subscription']['isActive'] && profile['subscription']['status'] === 'paid'
            }
        }
        else {
            ambassador_profile.current = false
        }

    

        setIsAmbassador(doc.exists)
        setLoadedData(true)
    }

    return (
        <>
        { loadedData && isAmbassador && 
            <AppContainer>
                <div className = 'EmbaixadoresScreen'>
                    <div className='Embaixadores-Header'>
                        <div className = 'EmbaixadoresScreen-LogoContainer'>
                            <img className='Embaixadores-Logo' src={Logo} />
                            <img className='Embaixadores-Signature' src={Signature} />    
                        </div>
                        <div>
                            <p className='Embaixadores-Title'>Programa de Embaixadores</p>
                        </div> 
                    </div>
                    
                    { ambassador_registry.current['onboarding_complete'] && ambassador_registry.current['status'] === 'active' &&
                        <div className = 'EmbaixadoresScreen-Container'>
                            <AmbassadorMonthly
                                // uid = { selectedAmbassadorData.current['uid'] }
                                // personal_registry = {selectedAmbassadorData.current['personal_registry']}
                                ambassador = {{
                                    ...ambassador_profile.current,
                                    ...ambassador_registry.current
                                }}
                                />
                        </div>
                    }
                    { ambassador_registry.current['onboarding_complete'] && ambassador_registry.current['status'] === 'ambassador_terminated' &&
                        <div className = 'EmbaixadoresScreen-Container'>
                            <p><b>Você foi desativado do programa.</b> Ou você pediu para ser removido, ou foi uma decisão nossa e provavelmente já falamos com você. Se você acredita que isso é um erro, basta entrar em contato via e-mail (embaixadores@osler-ensino.com).</p>
                        </div>
                    }
                    { !ambassador_registry.current['onboarding_complete'] && 
                        <div className = 'EmbaixadoresScreen-Container'>
                            <p className = 'EmbaixadoresScreen-TitleText'>Bem-vindo(a) ao programa de embaixadores!</p>
                            
                            <p>Antes da liberação do seu cupom e de sua inserção de fato no programa, você precisa passar pela reunião de <em>onboarding</em>, onde traremos algumas explicações adicionais e conheceremos você um pouco melhor.</p>

                            <p><strong>Para isso, <Link to="https://wa.link/a8h84u" target="_blank" rel="noopener noreferrer">clique aqui</Link> para entrar em contato com a Eduarda (Dudinha), coordenadora do programa. Apresente-se, fale sua instituição, e agende sua reunião.</strong></p>

                            <p>Por favor, entre em contato agora. Após 24 horas, nós cancelaremos sua inscrição no programa, e a vaga ficará liberada para outros alunos da sua faculdade.</p> 
                        </div>
                    
                    }

                </div>
            </AppContainer>
        }
        { loadedData && !isAmbassador && 
                <UserAmbassador
                    update = {update} />
        }
        </>
    )
}