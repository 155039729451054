// Agrupa os feedbacks por tagpath na tela FeedbackReviewScreen
import React, { useState, useEffect } from 'react';

import './FeedbackGroup.css'
import MinusIcon from './../assets/tree/minus.png'
import PlusIcon from './../assets/tree/plus.png'
import FeedbackReviewCard from './FeedbackReviewCard';
import { mapIcon } from './FeedbackUtils';


export default function FeedbackGroup({ handleResolve, feedbacks, selectedType}) {
    const [title, setTitle] = useState('Loading...')


    const [tagpath, setTagpath] = useState('')
    const [expanded, setExpanded] = useState(false)


    useEffect(() => {
        setTitle( feedbacks['tagpath'].split('/').pop() )
        setTagpath( feedbacks['tagpath'] )
    }, [feedbacks])


    return (
        <div className = 'FeedbackGroup-Container'>
            <div className = 'FeedbackGroup-Header' onClick = {() => setExpanded(!expanded)}>
                <div className = 'FeedbackGroup-ExpandCollapse' >
                    <img className = 'FeedbackGroup-ExpandCollapseIcon' src = {expanded ? MinusIcon : PlusIcon} />
                </div>
                
                <p className = 'FeedbackGroup-Title'>
                    <span className = 'FeedbackGroup-TitleDetail'>[{mapIcon(selectedType)} {feedbacks.feedbacks.length}]</span> 
                    {title}
                </p>
                
            </div>

            { expanded && 
                <p className = 'FeedbackGroup-Tagpath'>{tagpath}</p>
            }


            { expanded && 
                feedbacks['feedbacks'].sort((a, b) => a.test_id.localeCompare(b.test_id)).map((feedback) => (
                    <FeedbackReviewCard
                        handleResolve={handleResolve}
                        feedback = {feedback} />
                ))            
            }
        </div>
    );
}


