import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: undefined
    },
    reducers: {
        saveUser: (state, action) => {
            // action has a .payload (data passed)
            // and a .type (automatically generated,
            // i.e., user/saveUser)
            state.data = action.payload
        },

        logOut: (state) => {
            state.data = undefined
        }
    }
})

export const { saveUser, logOut } = userSlice.actions
export default userSlice.reducer