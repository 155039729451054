import React, { useState, useEffect, useRef } from 'react';
import { db } from '../firebase/firebase-setup'

import './FeedbackReviewScreen.css'
import FeedbackGroup from './FeedbackGroup';
import { mapMethodology, mapIcon } from './FeedbackUtils';

import FlashcardsIcon from '../assets/flashcard.png'
import ResidenciaIcon from '../assets/residencia.png'
import ScreenContainerHeader from '../components/ScreenContainerHeader';


export default function FeedbackReviewScreen() {
    const [orderedFeedbacks, setOrderedFeedbacks] = useState([]);
    const [feedbackCount, setFeedbackCount] = useState({})
    const [selectedType, setSelectedType] = useState('Total')
    
    const [loaded, setLoaded] = useState()

    const testType = useRef()
    const feedbackData = useRef()
    

    useEffect(() => {
        const fetchFeedback = async (currentType) => {
            // Baixamos todos os feedbacks pendentes do Firebase.
            const querySnapshot = await db.collection(`/feedback/${currentType}/disliked`)
            .where('solved', '==', false)
            .get();

            feedbackData.current[currentType] = []

            querySnapshot.forEach((doc) => {
                const docData = doc.data();
                
                feedbackData.current[currentType].push({ docId: doc.id, ...docData })
            });

        };


        async function load() {
            feedbackData.current = {}

            await fetchFeedback('Flashcards');
            await fetchFeedback('Residencia');

            console.log(' * Carregando todos os typos')


            changeType('Flashcards')
        }

        load();
    }, []);




    function sortData() {
        if (dataLoaded()) {
            groupFeedback()
            countFeedbacks() 
        }
    }


    function countFeedbacks() {
        const types = {}

        console.log(Object.keys(feedbackData.current))

        for (let feedback of feedbackData.current[testType.current]) {
            if (!types[feedback.type]) {
                types[feedback.type] = 1
            }
            else {
                types[feedback.type] += 1
            } 
        }
        types['Total'] = feedbackData.current[testType.current].length


        setFeedbackCount(types)
    }


    function groupFeedback(selectedTypes = ['methodology', 'spelling', 'content', 'improvement']) {
        let data = {}

        for (let feedback of feedbackData.current[testType.current]) {
            const tagPath = feedback.tag_path;

            if (selectedTypes.includes(feedback.type)) {
                if (!data[tagPath]) {
                    data[tagPath] = {
                        'feedbacks': [],
                        'meta' : {
                            'methodology' : 0,
                            'spelling' : 0,
                            'content' : 0,
                            'improvement' : 0,
                            'total' : 0
                        }
                    };
                }
                
                data[tagPath]['feedbacks'].push(feedback);
                data[tagPath]['meta'][feedback['type']] += 1
                data[tagPath]['meta']['total'] += 1
            }
        }

        // Agora que organizamos por tagpath, nós precisamos ordenar
        // do que tem maiores pendências para o que tem menos. 
        //
        // E, dentro desses agrupamentos, os feedbacks precisam ser organizados
        // alfabeticamente pelo test_id
        const groupedFeedbacks = Object.entries(data).map(x => {
            return {
                'tagpath' : x[0],
                'feedbacks' : x[1]['feedbacks'],
                'meta' : x[1]['meta']
            }
        })


        const orderedFeedbacksTemp = groupedFeedbacks.sort( (a, b) => {
            let filterOption = selectedType.length > 1 ? 'total' : selectedType[0]

            return a['meta'][filterOption] > b['meta'][filterOption] ? -1 : 1
        });

        setOrderedFeedbacks(orderedFeedbacksTemp)
    }


    function filterFeedbackType(type) {
        console.log(' * Mudando a opção de filtro para... ' + type)
        setSelectedType(type)
        type == 'Total' ? groupFeedback() : groupFeedback([type])
    }


    function handleResolve (id) {
        // O documento já foi modificado, só precisamos mudar os dados localmente.
        feedbackData.current[testType.current] = feedbackData.current[testType.current].map(feedback => {
            if (feedback.docId === id) {
                return { ...feedback, solved: true }; // cria uma cópia do objeto com solved atualizado para true
            }
            return feedback;
        });
        
        if (selectedType != 'Total') {
            groupFeedback([selectedType])
        }
        else {
            groupFeedback()
        }
    };


    function dataLoaded() {
        console.log()
        return feedbackData.current &&  feedbackData.current[testType.current] && feedbackData.current[testType.current].length > 0
    }


    function changeType(paramTestType) {
        console.log(' * Mudando o tipo para ' + paramTestType)
        testType.current = paramTestType
        setSelectedType('Total')
        setLoaded(paramTestType)
        sortData()
    }


    return (
        <>
        { loaded != ''  && dataLoaded() &&


            <div className = 'FeedbackReviewScreen-Container'>
                <div className = 'FeedbackReviewScreen-ChooseTestType'>
                    <div 
                        className = {'TestType-Selector ' + (testType.current == 'Flashcards' ? ' TestType-Selector-Selected' : 'TestType-Selector-Unselected')}
                        onClick = {() => changeType('Flashcards')}>
                        <img src = {FlashcardsIcon} />
                        <p>Flashcards</p>
                    </div>

                    <div className = {'TestType-Selector ' + (testType.current == 'Residencia' ? ' TestType-Selector-Selected' : 'TestType-Selector-Unselected')}
                    onClick = {() => changeType('Residencia')}>
                        <img src = {ResidenciaIcon} />
                        <p>Residência</p>
                    </div>
                </div>


                <div className = 'FeedbackReviewScreen-Header'>
                    {
                        Object.entries(feedbackCount).map( ([type, count]) => (
                            <div
                                className = {'FeedbackCounter' + (selectedType == type ? ' SelectedCounter' : '')}
                                onClick = {() => filterFeedbackType(type)}>
                                    <p className = 'FeedbackCounter-Count'>{count}</p>
                                    <p className = 'FeedbackCounter-Icon'>{mapIcon(type)}</p>
                                    <p className = 'FeedbackCounter-Type'>{mapMethodology(type)}</p>

                            </div>
                        ))
                    }
                </div>

                { orderedFeedbacks.map(feedbacks => (                
                        <FeedbackGroup
                            handleResolve = {handleResolve}
                            feedbacks = {feedbacks}
                            selectedType = {selectedType} />
                    ))
                }

            </div>
        }
        { !dataLoaded() &&
            <p className = 'FeedbackReviewScreen-Error'>Ou não há feedbacks, ou você não tem permissão, ou sua conexão falhou.</p>
        }
        </>
    );
}


