import React, { useState } from 'react'
import SignIn from './SignIn'
import SignUp from './SignUp'
import logo from './../assets/Logo.png'
import signature from './../assets/signature.png'

import {
    LoginScreenContainer,
    BrandSection,
    LoginSection,
    LogoContainer,
    Tagline
} from './LoginStyles'

export default function LoginScreen() {
    const [method, setMethod] = useState('signIn')
    
    function changeMethod() {
        setMethod(method === 'signIn' ? 'signUp' : 'signIn')
    }
    
    return (
        <LoginScreenContainer>
            <BrandSection>
                <LogoContainer>
                    <img src={logo} alt="Logo" className="logo" />
                    <img src={signature} alt="Assinatura" className="signature" />
                </LogoContainer>
                <Tagline>
                    Educação médica centrada em curiosidade
                </Tagline>
            </BrandSection>

            <LoginSection>
                {method === 'signIn' && <SignIn changeMethod={changeMethod} />}
                {method === 'signUp' && <SignUp changeMethod={changeMethod} />}
            </LoginSection>
        </LoginScreenContainer>
    )
}