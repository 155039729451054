import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BedsideLogo from './bedsidelogo.png';

// Estilos gerais
const Body = styled.body`
  font-family: 'Arial';
  margin: 0;
  padding: 0;
  background-color: #f2f2f2 !important;
`;

const BedsideMainContainer = styled.div`
  margin: 0px;
  border-radius: 40px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

// Cabeçalho
const BedsideHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: none;
  box-shadow: none;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const BedsideLogoImg = styled.img`
  width: 20%;
  margin-right: 0px;

  @media (max-width: 768px) {
    width: 40% !important;
  }
`;

const BedsideTitle = styled.h1`
  padding: 0px;
  color: #A70008;
`;

const ModuleTitle = styled.span`
color: #A70008;
font-size: 20px;
font-weight: bold;
`;

// Módulos
const BedsideModulesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
  height: fit-content;
  background: none;
  border-radius: 20px;
  width: fit-content;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const BedsideModule = styled(Link)`
  text-decoration: none;
  color: #333;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 150px;
  width: 220px;
  transition: background-color 0.3s ease;
  border-bottom: 2px solid #A70008;

  &:hover {
    background-color: #f5f5f5;
  }

  @media (min-width: 769px) {
    background-color: #f2f2f2;
    border-bottom: solid 3px #A70008;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    justify-content: center;
    color: #A70008;
  }

  a.sc-NjHHu iKuKFw {
    color: #A70008;
  }
`;

const BedsideModuleContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

const BedsideModuleIcon = styled.i`
  font-size: 50px;
  margin-bottom: 0px !important;
  color: #A70008;
  text-align: center;


  &.fi.fi-br-user-gear {
    margin: 0px !important;
    width: 60% !important;
  }
`;

// Componente de cabeçalho para a página BedsideMain
const Header = () => (
  <BedsideHeader>
    <BedsideLogoImg src={BedsideLogo} alt="Bedside Logo" className="bedside-logo" />
    <BedsideTitle>Osler Bedside</BedsideTitle>
  </BedsideHeader>
);

// Componente de módulo modificado
const Module = ({ name, icon, path, isIconClass }) => (
  <BedsideModule to={path}>
    <BedsideModuleContent>
      {isIconClass ? (
        <BedsideModuleIcon className={`${icon} bedside-module-icon`} />
      ) : (
        <img src={icon} alt={name} className="bedside-module-icon" />
      )}
      <ModuleTitle>
      {name}
      </ModuleTitle>
    </BedsideModuleContent>
  </BedsideModule>
);

// Componente BedsideMain
const BedsideMain = () => (
  <BedsideMainContainer>
    <Header />
    <BedsideModulesContainer>
      <Module name="Prescrições" icon="fi-rr-note-medical" path="/clinicolist" isIconClass={true} />
      <Module name="Medicamentos" icon="fi-rr-medicine" path="/medicamentos" isIconClass={true} />
      <Module name="Calculadoras" icon="fi fi-rr-calculator-simple" path="/calculadoras" isIconClass={true} />
      <Module name="Dicionário CID10" icon="fi fi-rr-overview" path="/cidslist" isIconClass={true} />
    </BedsideModulesContainer>
  </BedsideMainContainer>
);

export default BedsideMain;
