import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraCAGE() {
    const [pergunta1, setPergunta1] = useState(false);
    const [pergunta2, setPergunta2] = useState(false);
    const [pergunta3, setPergunta3] = useState(false);
    const [pergunta4, setPergunta4] = useState(false);
    const [resultado, setResultado] = useState('');

    const calcularCAGE = () => {
        const respostas = [pergunta1, pergunta2, pergunta3, pergunta4];
        const pontuacao = respostas.filter(resposta => resposta).length;
        
        let interpretacao;

        if (pergunta4) {
            interpretacao = 'Comportamento altamente preocupante para o consumo não saudável de álcool.';
        } else if (pontuacao >= 2) {
            interpretacao = 'Triagem positiva. Suspeitar de presença de um problema de uso de álcool e encaminhar para avaliação adicional e tratamento.';
        } else {
            interpretacao = 'Triagem negativa, baixa probabilidade de um problema de uso de álcool.';
        }

        setResultado(`Pontuação: ${pontuacao} - ${interpretacao}`);
    };

    useEffect(() => {
        calcularCAGE();
    }, [pergunta1, pergunta2, pergunta3, pergunta4]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora Escala CAGE</h1>
            <div>
                <label>
                    <input type="checkbox" checked={pergunta1} onChange={e => setPergunta1(e.target.checked)} />
                    Você já pensou em largar a bebida?
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={pergunta2} onChange={e => setPergunta2(e.target.checked)} />
                    Ficou aborrecido quando outras pessoas criticaram o seu hábito de beber?
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={pergunta3} onChange={e => setPergunta3(e.target.checked)} />
                    Se sentiu mal ou culpado pelo fato de beber?
                </label>
            </div>
            <div>
                <label>
                    <input type="checkbox" checked={pergunta4} onChange={e => setPergunta4(e.target.checked)} />
                    Bebeu pela manhã para ficar mais calmo ou se livrar de uma ressaca (abrir os olhos)?
                </label>
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
            
        </div>
    );
}

export default CalculadoraCAGE;
