import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../bedside/CalcPage.css';

function CalculadoraIdadeGestacionalDUM() {
    const [dataAtual, setDataAtual] = useState('');
    const [dataDUM, setDataDUM] = useState('');
    const [resultado, setResultado] = useState('Preencha todos os campos para ver o resultado.');

    useEffect(() => {
        const today = new Date();
        const dia = String(today.getDate()).padStart(2, '0');
        const mes = String(today.getMonth() + 1).padStart(2, '0');
        const ano = today.getFullYear();
        setDataAtual(`${dia}/${mes}/${ano}`);
    }, []);

    useEffect(() => {
        if (dataAtual && dataDUM.length === 10) {
            calcularIdadeGestacional();
        } else {
            setResultado('Preencha todos os campos para ver o resultado.');
        }
    }, [dataAtual, dataDUM]);

    const calcularIdadeGestacional = () => {
        const dataAtualObj = new Date(dataAtual.split('/').reverse().join('-'));
        const dataDUMObj = new Date(dataDUM.split('/').reverse().join('-'));
        const diffTime = Math.abs(dataAtualObj - dataDUMObj);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        const semanas = Math.floor(diffDays / 7);
        const dias = diffDays % 7;

        const dataParto = new Date(dataDUMObj);
        dataParto.setDate(dataParto.getDate() + 280);

        const diaParto = String(dataParto.getDate()).padStart(2, '0');
        const mesParto = String(dataParto.getMonth() + 1).padStart(2, '0');
        const anoParto = dataParto.getFullYear();

        setResultado(`Idade Gestacional: ${semanas} semanas e ${dias} dias. Data Provável do Parto: ${diaParto}/${mesParto}/${anoParto}`);
    };

    const handleDUMChange = (e) => {
        let value = e.target.value.replace(/\D/g, '');
        if (value.length > 2) value = value.replace(/^(\d{2})(\d)/, '$1/$2');
        if (value.length > 5) value = value.replace(/^(\d{2})\/(\d{2})(\d)/, '$1/$2/$3');
        setDataDUM(value);
    };

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Idade Gestacional pela DUM</h1>
            <div>
                <label>Data atual (DD/MM/AAAA):</label>
                <input 
                    type="text" 
                    value={dataAtual} 
                    disabled
                />
            </div>
            <div>
                <label>Data da última menstruação (DUM) (DD/MM/AAAA):</label>
                <input 
                    type="text" 
                    value={dataDUM} 
                    onChange={handleDUMChange} 
                    placeholder="DD/MM/AAAA" 
                />
            </div>
            <div className="result-container">
                <p>{resultado}</p>
            </div>
        </div>
    );
}

export default CalculadoraIdadeGestacionalDUM;
