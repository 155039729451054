import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraChildPugh() {
    const [bilirrubina, setBilirrubina] = useState('');
    const [albumina, setAlbumina] = useState('');
    const [inr, setInr] = useState('');
    const [ascite, setAscite] = useState('');
    const [encefalopatia, setEncefalopatia] = useState('');
    const [resultado, setResultado] = useState('');
    const [exibirResultado, setExibirResultado] = useState(false);

    const calcularChildPugh = () => {
        let pontos = 0;
        exibirResultado || setExibirResultado(true); // Garante que o resultado será exibido após o clique

        // Bilirrubina
        pontos += bilirrubina > 3 ? 3 : bilirrubina > 2 ? 2 : 1;

        // Albumina
        pontos += albumina < 2.8 ? 3 : albumina <= 3.5 ? 2 : 1;

        // INR
        pontos += inr > 2.3 ? 3 : inr >= 1.7 ? 2 : 1;

        // Ascite
        pontos += ascite === 'grave' ? 3 : ascite === 'moderada' ? 2 : 1;

        // Encefalopatia
        pontos += encefalopatia === 'graus3e4' ? 3 : encefalopatia === 'graus1e2' ? 2 : 1;

        const classificacao = pontos <= 6 ? 'A' : pontos <= 9 ? 'B' : 'C';

        setResultado(`Pontuação total: ${pontos}, Classificação Child-Pugh: ${classificacao}`);
    };

    return (
        <div>
                                                      <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora Child-Pugh</h1>
            <div>
                <label>Bilirrubina sérica (mg/dL):</label>
                <input type="number" value={bilirrubina} onChange={e => setBilirrubina(parseFloat(e.target.value))} />
            </div>
            <div>
                <label>Albumina sérica (g/dL):</label>
                <input type="number" value={albumina} onChange={e => setAlbumina(parseFloat(e.target.value))} />
            </div>
            <div>
                <label>INR:</label>
                <input type="number" value={inr} onChange={e => setInr(parseFloat(e.target.value))} />
            </div>
            <div>
                <label>Ascite:</label>
                <select value={ascite} onChange={e => setAscite(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="nenhuma">Nenhuma</option>
                    <option value="moderada">Leve/Moderada</option>
                    <option value="grave">Tensa</option>
                </select>
            </div>
            <div>
                <label>Encefalopatia hepática:</label>
                <select value={encefalopatia} onChange={e => setEncefalopatia(e.target.value)}>
                    <option value="">Selecione</option>
                    <option value="ausente">Nenhuma</option>
                    <option value="graus1e2">Graus I e II</option>
                    <option value="graus3e4">Graus III e IV</option>
                </select>
            </div>
            <button onClick={calcularChildPugh}>Calcular</button>
            {exibirResultado ? (
                <div className="result-container">
                    {resultado}
                </div>
            ) : (
                <div className="result-container">
                    Preencha todos os campos e clique em calcular para ver o resultado.
                </div>
            )}
        </div>
    );
}

export default CalculadoraChildPugh;
