import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ColumnCSS, RowCSS } from './BasicComponents'

const Screen = styled.div`
    ${ColumnCSS}
    display: flex;
    flex-direction: column;

    min-height: 100dvh;
    width: 100%;

    // CRUCIAL para o conteúdo nunca encostar no fim da tela, o que é muuuuuito feio.
    padding-bottom: 5em;


    
    @media (max-width: 1200px) and (min-width: 769px) {
        padding-bottom: 4em;
    }

    @media (max-width: 768px) {
        padding-bottom: 3em;
    }
`

const Header = styled.header`
    ${RowCSS}
    width: 100%;

    // Atenção a esse padding, ele é fundamental para que o 
    // conteúdo sob a tela esteja alinhado
    // com o ícone do header e com os elementos da tablist
    padding: 2em 0 1.25em 2em;

    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'white'};
    border-bottom: ${props => props.theme.darkMode ? '1px solid rgba(255, 255, 255, 0.1)' : 'none'};

    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1.8em 1.8em 1.1em 1.8em;
    }

    @media (max-width: 768px) {
        padding: 1.5em 1.5em 1em 1.5em;
    }

    @media (max-width: 480px) {
        padding: 1.25em 1em 0.75em 1em;
        
        gap: 0.5em;
    }
`

const Icon = styled.img`
    // Nem todas as imagens são quadradas, então determinamos só a altura.
    height: 3em;
    filter: ${props => props.theme.darkMode ? 'brightness(0.8)' : 'none'};

    @media (max-width: 1200px) and (min-width: 769px) {
        height: 2.8em;
    }

    @media (max-width: 768px) {
        height: 2.5em;
    }

    @media (max-width: 480px) {
        height: 2em;
    }
`

const Title = styled.h1`
    color: ${props => props.theme.darkMode ? 'rgb(220, 220, 220)' : 'black'};
    font-size: 2.4em;
    margin: 0 0 0 0.5em;
    letter-spacing: -0.03em;

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 2.2em;
        margin: 0 0 0 0.45em;
    }

    @media (max-width: 768px) {
        font-size: 2em;
        margin: 0 0 0 0.4em;
    }

    @media (max-width: 480px) {
        font-size: 1.2em;
        margin: 0;
        max-width: 100%;
        padding-left: 0em;
    }
`


const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;

    // O padding à esquerda garante que o conteúdo da página está alinhado com o ícone do header
    // e, se houver, com o primeiro elemento da tab. O padding à direita mantém simetria. O padding
    // do topo impedeq que o conteúdo encoste no header..
    padding: 1.5em 2em 0 2em;

    // Cor cinza claro, para facilitar contrast com cards brancos
    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'rgb(245, 245, 245)'};


    @media (max-width: 1200px) and (min-width: 769px) {
        padding: 1em 1em 0 1em;
    }

    @media (max-width: 768px) {
        padding: 1.25em 1.5em 0 1.5em;
    }

    @media (max-width: 480px) {
        padding: 0.3em;
    }
`


// a


const TabList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    background-color: ${props => props.theme.darkMode ? 'rgb(41, 41, 41, 1)' : 'white'};
    margin: 0;
    gap: 2em;
    padding: 1em 2em 0 2em;
    border-bottom: 1px solid ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.1)' : '#d2d2d7'};

    @media (max-width: 1200px) and (min-width: 769px) {
        gap: 1.5em;
        padding: 0.9em 1.8em 0 1.8em;
    }

    @media (max-width: 768px) {
        gap: 1em;
        padding: 0.75em 1.5em 0 1.5em;
        overflow-x: auto;
        overflow-y: hidden;
        justify-content: space-between;
    }

    @media (max-width: 480px) {
        gap: 0.6em;
        padding: 0.5em 1em 0 1em;
    }
`

const TabItem = styled.div`
    ${RowCSS}
    cursor: pointer;
    font-size: 1.1em;
    color: ${props => {
        if (props.theme.darkMode) {
            return props.active ? '#60a5fa' : 'rgb(220, 220, 220)';
        }
        return props.active ? '#06c' : '#1d1d1f';
    }};
    font-weight: bold;
    transition: all 0.3s ease;
    padding-bottom: 0.8em;
    position: relative;
    gap: 0.5em;
    align-items: center;

    // Se for icon-only, ajusta o estilo
    ${props => !props.label && `
        padding: 0.5em;
        margin-left: ${props.isFirstIcon ? 'auto' : '0'}; // Primeiro ícone empurra para direita
        margin-right: ${props.isLastIcon ? '0' : '-1em'}; // Aproxima os ícones

        @media (max-width: 480px) {
            margin-left: ${props.isFirstIcon ? '0' : '0'};
            margin-right: ${props.isLastIcon ? '0' : '-0.5em'};
            padding: 0.4em;
        }
    `}

    img {
        height: 1.4em;
        width: auto;
        opacity: ${props => props.active ? 1 : 0.7};
        transition: opacity 0.3s ease;
        margin: ${props => props.label ? '0' : '0'};
        filter: ${props => props.theme.darkMode ? 'none' : 'none'};

        @media (max-width: 1200px) and (min-width: 769px) {
            height: 1.35em;
        }

        @media (max-width: 768px) {
            height: 1.3em;
        }

        @media (max-width: 480px) {
            height: 1.2em;
        }
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
        transform: scaleX(${props => props.active ? 1 : 0});
        transition: transform 0.3s ease;
        z-index: 1;
    }

    &:hover {
        color: ${props => props.theme.darkMode ? '#60a5fa' : '#06c'};
        img {
            opacity: 1;
        }
    }

    @media (max-width: 1200px) and (min-width: 769px) {
        font-size: 0.95em;
        padding-bottom: 0.75em;
        gap: 0.4em;
    }

    @media (max-width: 768px) {
        font-size: 0.85em;
        padding-bottom: 0.7em;
        padding-top: 0;
    }

    @media (max-width: 480px) {
        font-size: 0.7em;
        padding-bottom: 0.6em;
        padding-top: 0;
        gap: 0.3em;
        min-width: fit-content;
    }

    @media (max-width: 368px) {
        font-size: 0.55em;
        padding-bottom: 0.6em;
        padding-top: 0;
        gap: 0.3em;
        min-width: fit-content;
    }
`



const ScreenContainerHeader = ({icon, title, 
    tabs = [],
    onTabChange,
    children
}) => {


    const [selectedTab, setSelectedTab] = useState()

    useEffect(() => {
        console.log('ScreenContainerHeader >> setting tab')
        setSelectedTab(tabs[0]?.id)
    }, [tabs])

    
    const handleTabChange = (tabId) => {
        setSelectedTab(tabId)
        onTabChange?.(tabId)
    }


    const normalTabs = tabs.filter(tab => tab.label)
    const iconOnlyTabs = tabs.filter(tab => !tab.label)
                        
    return (
        <Screen>
            <Header>
                <Icon src={icon} alt={`${title} icon`} />
                <Title>{title}</Title>
            </Header>

            {tabs.length > 0 && (
                <TabList>
                    {normalTabs.map((tab) => (
                        <TabItem
                            key={tab.id}
                            active={selectedTab === tab.id}
                            onClick={() => handleTabChange(tab.id)}
                            label={tab.label}
                        >
                            {tab.icon && <img src={tab.icon} alt={tab.label} />}
                            {tab.label}
                        </TabItem>
                    ))}

                    {iconOnlyTabs.map((tab, index) => (
                        <TabItem
                            key={tab.id}
                            active={selectedTab === tab.id}
                            onClick={() => handleTabChange(tab.id)}
                            isFirstIcon={index === 0}
                            isLastIcon={index === iconOnlyTabs.length - 1}
                        >
                            <img src={tab.icon} alt={tab.id} />
                        </TabItem>
                    ))}
                </TabList>
            )}
            

            
            <Content>
                {tabs.find(tab => tab.id === selectedTab)?.content}

                { children }
            </Content>
        </Screen>
    )
}

export default ScreenContainerHeader