import React, { useState,useImperativeHandle, forwardRef, useRef, useEffect, useCallback } from 'react';
import styled from 'styled-components'
import { contentStyle, HighlightColor } from './FlashcardsStyles';
import { SelectableText } from '../components/BasicComponents';







const TextContainer = styled.div`

    // ???
    -webkit-appearance: none;


    ${SelectableText}

    // No Safari talvez não funcione.
    // ATENÇÃO: É BACKGROUND E NÃO BACKGROUND-COLOR
    ::-moz-selection {
        background: ${HighlightColor} !important;
    }
    ::-webkit-selection {
        background: ${HighlightColor} !important;
    }
    ::selection {
        background: ${HighlightColor} !important;
    }
    
    text-align: justify;
    
    p {
        ${contentStyle}
    }

    mark {
        /* Reset de estilos padrão */
        all: unset;

        /* Seus estilos personalizados */
        background: ${HighlightColor} !important;
        padding: 0;
        margin: 0;
        display: inline;
    }

    img {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    mark img {
        background-color: transparent;
    }
`



function HighlightableHTML({ html, onHighlightChange }, ref) {
    const [highlightedHtml, setHighlightedHtml] = useState(html)
    const [hasHighlights, setHasHighlights] = useState(false);
    const [touchStart, setTouchStart] = useState(null);

    const containerRef = useRef(null)


    useEffect(() => {
        setHighlightedHtml(html)
    }, [html])


    function handleSelection(e) {
        // Obtemos o objeto Selection do navegador.
        const selection = window.getSelection()

        // migué master para, em quase todos os casos, não exibir o context menu do mobile,
        // que acompanha o texto selecionado
        window.scrollBy(0, 1)
        window.scrollBy(0, -1)
        
        // Teoricamente, isso não existe, são casos limítrofes, de navegador antigo.
        if (!selection.rangeCount) return

        // Obtemos o primeiro dos trechos selecionados pelo usuário. Mais uma vez,
        // tratamento excessivo, nunca ou muito raramente haverá mais de um selecionado.
        // Na sequência, obtemos a string desse trecho.
        const range = selection.getRangeAt(0)

        // Isso aqui ajuda a, no mobile, na maioria dos casos (não entendo porque não todos),
        // não exibir o contextmenu.
        // selection.removeAllRanges()

        // Ignora seleções vazias
        if (range.collapsed) return

        handleMultiNodeSelection(range)   
        
    }


    function handleMultiNodeSelection(range) {

        if (range.startContainer === range.endContainer) {
            // startContainer é o nó DOM que contém o início da seleção, e endContainer é o nó que
            // contém o fim.
            //
            // Se a seleção através nós, haverá "middleContainers", aos quais não temos acesso
            // diretamente, e precisamos usar o TreeWalker. Se a seleção é em um mesmo nó,
            // startContainer e endContainer são iguais.
            if (range.startContainer.nodeType === Node.TEXT_NODE) {
                highlightNode(range.startContainer, range.startOffset, range.endOffset);
            }
        } else {
            // O Walker é criado no ancestral comum. O parâmetro NodeFilter.SHOW_TEXT filtra para
            // considerarmos somente nós de elemento textual (logo, já ignora imagens, por exemplo).
            //
            // A função passada garante que somente nós que estão contidos no range/seleção serão
            // considerados (considere que commonAncestor contém todos os outros parágrafos).
            const walker = document.createTreeWalker(
                range.commonAncestorContainer,
                NodeFilter.SHOW_TEXT,
                {
                    acceptNode: function(node) {
                        return range.intersectsNode(node) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_REJECT;
                    }
                }
            )
        
            // No primeiro nó, grifamos algo do meio ao fim. Nos nós intermediários, grifamos tudo.
            // No último nó, grifamos algo do começo ao meio.
            //
            // Mas precisamos criar uma cópia dos nós, senão entra em um loop infinito porque estamos
            // inserindo elementos no DOM enquanto iteramos sobre os nós.
            let node
            const nodes = []
            while (node = walker.nextNode()) {
                nodes.push(node)
            }
        
            nodes.forEach((node, index) => {
                if (index === 0) {
                    highlightNode(node, range.startOffset)
                } else if (index === nodes.length - 1) {
                    highlightNode(node, 0, range.endOffset)
                } else {
                    highlightNode(node)
                }
            })
        }

        window.getSelection().removeAllRanges()
        setHasHighlights(true)
    }


    function highlightNode(node, start = 0, end = null) {
        const range = document.createRange()
        range.setStart(node, start)
        range.setEnd(node, end === null ? node.length : end)
    
        const selectedContent = range.extractContents()
        const mark = document.createElement('mark')
        mark.appendChild(selectedContent)
        range.insertNode(mark)
    }


    useImperativeHandle(ref, () => ({
        // useImperativeHandle é um hook do React que permite customizar o que da instância é
        // exposto ao componente pai, via ref
        removeAllHighlights,
        hasHighlights
    }))


    useEffect(() => {
        onHighlightChange(hasHighlights)
    }, [hasHighlights, onHighlightChange])


    function removeAllHighlights() {
        const highlights = document.querySelectorAll('mark')
        highlights.forEach(highlight => {
            const parent = highlight.parentNode
            parent.insertBefore(document.createTextNode(highlight.textContent), highlight)
            parent.removeChild(highlight)
        })
        setHasHighlights(false)
    }



    return (
        <TextContainer
            ref={containerRef}
            onTouchEnd={handleSelection}
            onMouseUp={handleSelection}
            dangerouslySetInnerHTML={{ __html: highlightedHtml }}
    />
    )
}



export default forwardRef(HighlightableHTML)