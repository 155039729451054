import React, { useState, useEffect } from 'react';
import { Clock } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import Session from '../controllers/Session';

const TimerContainer = styled(motion.div)`
    display: flex;
    align-items: center;
    gap: 8px;

`;

const IconWrapper = styled(motion.button)`
    background: none;
    border: none;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.7)'};
    cursor: pointer;
    border-radius: 6px;
`;

const TimeDisplay = styled(motion.span)`
    font-family: -apple-system, BlinkMacSystemFont, "SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.darkMode ? 'rgba(255, 255, 255, 0.9)' : 'rgba(0, 0, 0, 0.9)'};
    min-width: 50px;
`;

export default function Stopwatch() {
    const [time, setTime] = useState(Session.startTime || 0);
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTime(prevTime => prevTime + 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    useEffect(() => {
        Session.startTime = time;
    }, [time]);

    function formatTime() {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }

    const buttonVariants = {
        hover: { 
            scale: 1.05,
            transition: {
                type: "spring",
                stiffness: 400,
                damping: 17
            }
        },
        tap: { scale: 0.95 }
    };

    const timeVariants = {
        hidden: { opacity: 0, x: 20 },
        visible: { 
            opacity: 1, 
            x: 0,
            transition: {
                type: "spring",
                stiffness: 500,
                damping: 25
            }
        },
        exit: { 
            opacity: 0,
            x: 20,
            transition: {
                duration: 0.2
            }
        }
    };

    return (
        <TimerContainer>
            <IconWrapper
                onClick={() => setVisible(!visible)}
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
            >
                <Clock size={18} />
            </IconWrapper>

            <AnimatePresence mode="wait">
                {visible && (
                    <TimeDisplay
                        key="time"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={timeVariants}
                    >
                        {formatTime()}
                    </TimeDisplay>
                )}
            </AnimatePresence>
        </TimerContainer>
    );
}