import styled, {css} from 'styled-components';


// Outros


export const backgroundWhiteBlack = css`
    background-color: ${props => props.theme.darkMode ? darkBackgroundPrimaryColor : 'rgb(245, 245, 245)'};
`


export const textWhiteBlack = css`
    color: ${props => props.theme.darkMode ? darkTextSecondaryColor : 'black'};

    caret-color: ${props => props.theme.darkMode ? darkTextSecondaryColor : 'black'};
`



// Vinho que é usado para títulos e etc.
export const primaryColor = css`
    ${props => props.theme.darkMode ? `
    color: rgba(255, 71, 87, 0.8);
    ` : `
    color: rgb(142, 0, 17);
    `}
`



export const darkBackgroundPrimaryColor = css`
    rgba(41, 41, 41, 1)
`

export const darkBackgroundSecondaryColor = css`
    rgba(61, 61, 61, 1)
`

export const darkBackgroundTertiaryColor = css`
    rgba(81, 81, 81, 1)
`

export const darkBackgroundQuarternaryColor = css`
    rgba(101, 101, 101, 1)
`


export const darkBackgroundQuintenaryColor = css`
    rgba(121, 121, 121, 1)
`

export const darkBackgroundSextenaryColor = css`
    rgba(141, 141, 141, 1)
`



// Eu preciso padronizar isso na porra toda
export const darkTextPrimaryColor = css`
    rgb(240, 240, 240)
`

export const darkTextSecondaryColor = css`
    rgb(200, 200, 200)
`

export const darkTextTertiaryColor = css`
    rgb(150, 150, 150)
`

// rgb(39, 121, 196)
export const blue = css`
    #2779c4
`

export const blueHover = css`
    #1f5c94
`

export const darkModeBlue = css`
    #1f5c94;
`

export const darkModeGreen = css`
    #4F9F00
`

export const OslerInput = styled.input`
    flex: 1;
    padding: 0.6em;
    caret-color: black;
`


// Todos
export const TestArea = styled.div`
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    // No desktop, fundamental, para evitar que os botões encostem no fim da tela.
    padding-bottom: 4em;

    @media (max-width: 500px) {
        // Fudnamental para ocupar tudo.
        justify-content: space-between;
        flex-grow: 1;
        padding-bottom: 0;
    }


`



// Flashcards
export const clozeStyle = css`
  font-weight: bold;

  ${props => props.theme.darkMode ? `
        color: rgb(255, 196, 0);
    ` : `
        color: rgb(39, 121, 196);
    `}
`;



export const HighlightColor = css`
    ${props => props.theme.darkMode ? `#160671` : `rgba(255, 238, 0, 0.8)`}
`

// Para todos os componentes tipo card que abrigam conteúdo.
export const contentStyle = css`
    width: 100%;

    ${props => props.theme.darkMode ? `
        color: rgb(230, 230, 230);
    ` : `
        color: black;
    `}
`


export const seenContentStyle = css`
    width: 100%;

    ${props => props.theme.darkMode ? `
        color: rgb(200, 200, 200);
    ` : `
        color: #3d3d3d;
    `}
`


export const flashcardStyle = css`
    ${contentStyle}
    span.cloze {
        ${clozeStyle}
    }

    span.Flashcard-Reference {
        ${seenContentStyle}
    }
`
    


// Para as questões
export const McqMetadata = styled.div`
    font-size: 1.1em;
    text-align: left;
    width: 100%;

    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;

    ${primaryColor}
`


