import React, { useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ComposedChart, Legend } from 'recharts';
import styled from "styled-components";
import { isMobile, isTablet } from '../utils/BootstrapUtils';
import { ColumnCSS, RowCSS } from '../components/BasicComponents';
import { useSelector } from 'react-redux';
import { backgroundWhiteBlack, darkTextPrimaryColor, darkTextSecondaryColor, darkTextQuintenaryColor, darkBackgroundTertiaryColor, darkBackgroundQuarternaryColor, textWhiteBlack } from '../tests/FlashcardsStyles';


const Container = styled.div`
    ${ColumnCSS}
    width: 95%;
    align-self: center;


    @media (max-width: 500px) {
        flex-direction: column-reverse;
        width: 100%;
    }
`


const RotateWrapper = styled.div`
    width: 100%;

    ${props => props.mobileRotate && `
        @media (max-width: 500px) {
        width: 80vh;

        transform: rotate(-90deg);
        margin-top: 20vh;
        margin-bottom: 20vh;
    }        
    `}

`

const ChartContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 400px;

    @media (max-width: 500px) {
    }
`


const LegendContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;

    max-width: 75%;
    border: 0.8px solid gray;
    border-radius: 0.5em;
    padding: 1em;
    margin-top: 1em;

    font-size: 0.7em;



    ${backgroundWhiteBlack}


    @media (max-width: 500px) {
        transform: rotate(-90deg);
        margin-top: 10vh;
        margin-bottom: 15vh;
    }
`


const LegendItem = styled.div`
    ${RowCSS}
    
    margin: 0 1.5em 0 1.5em;

`;

const LegendColor = styled.div`
    width: 1em;
    height: 1em;
    border-radius: 0.25em;
    background-color: ${props => props.color};
    opacity: ${props => props.isActive ? 1 : 0.3};
    transition: opacity 0.3s ease;
`;

const LegendLabel = styled.p`
    ${textWhiteBlack}
    margin: 0 0 0 0.5em;
    font-weight: bold;
`;

const Rotate = styled.div`
    ${ColumnCSS}
    text-align: center;
    width: 100%;
    margin-bottom: 3em;
`


function CustomLegend({ items, activeIndex, setActiveIndex, showLine = true }) {


    let legendItems = []


    if (showLine) {
        legendItems = [
            {
                id: 'line1', 
                name: items.line1_label, 
                color: items.line1_color 
            },
        ]
    }


    if (items.bar1_labels) {
        legendItems = [
            ...legendItems,
            ...items.bar1_labels.map((label, index) => ({
                id: `bar1-${index}`,
                name: label,
                color: items.bar1_colors[index]
            })),
        ]
    }

    if (items.bar2_labels) {
        legendItems = [
            ...legendItems,
            ...items.bar2_labels.map((label, index) => ({
                id: `bar2-${index}`,
                name: label,
                color: items.bar2_colors[index]
            })),
        ]
    }


    return (
        <>

        <LegendContainer>
            {/* {isMobile() && 
                        <p>Vira a cabeça. Ou o celular.</p>
            } */}

            {legendItems.map((item, index) => (
                
                <LegendItem 
                    key={`item-${index}`}
                    onMouseEnter={() => setActiveIndex(item.id)}
                    onMouseLeave={() => setActiveIndex(null)}
                >
                                    {console.log(item.id)}
                    <LegendColor color={item.color} isActive={activeIndex === null || activeIndex === item.id} />
                    <LegendLabel>{item.name}</LegendLabel>
                </LegendItem>
            ))}
            </LegendContainer>
        </>
    
    );
}



export function dateDashStrToDate(dateStr) {
    const [year, month, day] = dateStr.split('-').map(Number)
    return new Date(year, month - 1, day)
}


export default function OslerNewDateChart({ data, metadata, tooltip, showLine = true, style, shouldRotate = true }) {

    const [activeIndex, setActiveIndex] = useState(null)
    const darkMode = useSelector(state => state.theme.darkModeOn)
  
    const graphLabelsColor = darkMode ? darkTextSecondaryColor : 'black'
    const labelStyle = {
        // É só o texto ao redor 
        // A fonte já está herdada como Lato, mas isso é só para (re)forçar
        fontFamily: 'Lato, sans-serif',
        fontSize: '1.1em',
        fill: graphLabelsColor
    }


    function getTick(props) {
        const { x, y, payload } = props;


        let content = ''
        let fontSize = '10px'
        let yOffset = 10
        let fontWeight = 'normal'


        if (data[payload.index].customTick) {
            console.log(data[payload.index])
            content = data[payload.index].customTick
        }
        else {
            const tickItem = payload.value;
            const index = payload.index;


            const date = dateDashStrToDate(tickItem)
            const day = date.getDate()

            const monthNames = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

            const isFirstDayOfMonth = (day === 1)


        
            if (index === 0 || isFirstDayOfMonth) {
                content = `${day}\nde ${monthNames[date.getMonth()]}`
                fontSize = '12px'
                yOffset = 18
                fontWeight = 'bold'
            }
            else { 
                if (isTablet()) {
                    const nextDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
                    const isLastDayOfMonth = nextDate.getMonth() !== date.getMonth()

                    if (isLastDayOfMonth || day == 2) {
                        return null
                    } else {
                        content = index % 2 === 0 ? `${day}` : ''
                    }
                }
                else {
                    content = `${day}`
                }
            }


        }

        
    
        if (!content) return null;

        const lines = content.split('\n')

        return (
            <g transform={`translate(${x},${y})`}>
                <text 
                    x={0} 
                    y={yOffset} 
                    dy={0} 
                    textAnchor="middle" 
                    fill= {graphLabelsColor}
                    style={{ fontSize: fontSize, fontWeight: fontWeight }}
                >
                    {lines.map((line, i) => (
                        <tspan x={0} dy={i === 0 ? 0 : 14} key={i}>
                            {line}
                        </tspan>
                    ))}
                </text>
            </g>
        )
    }



    
    if (!data || !metadata) return null;
    return (
        <Container style = {style}>

            <RotateWrapper mobileRotate = {shouldRotate}>
                <ChartContainer>
                    <ResponsiveContainer width="100%" height="100%" debounce={200}>
                        <ComposedChart
                            barGap={0}
                            data={data}

                            // Necessário para o tick com nome do mês, e para os labels dos eixos Y
                            margin={{
                                top: 0,
                                right: 20,
                                left: 20, 
                                bottom: 20,
                            }}
                        >
                            

                            <CartesianGrid 
                                stroke = {darkMode ? darkBackgroundQuarternaryColor : '#f5f5f5'}
                                strokeDasharray="3 3" />

                            <XAxis 
                                stroke  = {graphLabelsColor}
                                dataKey = 'date'
                                style   = {labelStyle}

                                // Necessário para exibir todos
                                interval={0} 
                                tick={getTick}
                                />

                            <YAxis 
                                yAxisId="left"
                                orientation="left" 
                                stroke = {graphLabelsColor}
                                label={{ 
                                    value: 'Testes', 
                                    angle: -90, 
                                    position: 'outside',
                                    dx: -40,
                                    style: labelStyle}}
                                tickFormatter={(value) => value.toLocaleString('pt-BR')}
                                />

                            <YAxis 
                                yAxisId="right" 
                                orientation="right" 
                                stroke = {graphLabelsColor}
                                label={{ 
                                    value: 'Tempo (min)', 
                                    angle: 90, 
                                    position: 'outside',
                                    dx: 40,
                                    style: labelStyle}}
                                tickFormatter={(value) => value.toLocaleString('pt-BR')}

                            />

                            <Tooltip content={tooltip} />


                            {metadata.bar1_labels && metadata.bar1_labels.map((label, index) => (
                                <Bar
                                    key={`bar1-${index}`}
                                    dataKey={(datum) => datum.bar1 ? datum.bar1[index] : 0}
                                    stackId="a"
                                    fill={metadata.bar1_colors[index]}
                                    yAxisId="left"
                                    barGap={0}
                                    opacity={activeIndex === null || activeIndex === `bar1-${index}` ? 1 : 0.3}
                                />
                            ))}

                            {metadata.bar2_labels && metadata.bar2_labels.map((label, index) => (
                                <Bar
                                    key={`bar2-${index}`}
                                    dataKey={(datum) => datum.bar2 ? datum.bar2[index] : 0}
                                    stackId="b"
                                    fill={metadata.bar2_colors[index]}
                                    yAxisId="left"
                                    barGap={0}
                                    opacity={activeIndex === null || activeIndex === `bar2-${index}` ? 1 : 0.3}

                                />
                            ))}

                                {showLine && metadata.line1_label ? (
                                    <Line
                                        type="monotone"
                                        dataKey="line1"
                                        stroke={metadata.line1_color}
                                        dot={false}
                                        yAxisId="right"
                                        opacity={activeIndex === null || activeIndex === `line1` ? 1 : 0.3}
                                    />
                                    ) : (
                                    // Linha invisível para forçar a renderização do eixo Y direito
                                    <Line
                                        type="monotone"
                                        dataKey="line1"
                                        stroke="transparent"
                                        dot={false}
                                        yAxisId="right"
                                        opacity={0}
                                    />
                                )}

                        </ComposedChart>
                    </ResponsiveContainer>
                </ChartContainer>
            </RotateWrapper>

            { !isMobile() && 
                <CustomLegend 
                    items={metadata}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                    showLine={showLine} />
            }
        
        </Container>
    );
}