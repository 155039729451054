import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'

import Icon from './../assets/Logo.png'
import './ErrorScreen.css'

export default function LoggedOut(props) {

    let navigate = useNavigate();


    return (
        <div className = 'ErrorScreen'>

            <div className = 'ErrorScreen-Content' >
                <img className = 'ErrorScreen-Logo' src = {Icon} />

 
                <p className = 'ErrorScreen-Title'>Interno é osso, né?</p>

                <p>Você foi deslogado. Isso ocorre quando você entra em dispositivos demais, muda sua senha, ou passar muito tempo sem logar.</p>

                <p>Mas não tem pepino, é só entrar de novo.</p>

                <div className = 'ErrorScreen-Bttn' onClick = {() => navigate('/login')}>
                   <p className = 'ErrorScreen-Msg'>Fazer login</p> 
                </div>
            </div>
        </div>
    )
}