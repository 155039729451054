import { db } from '../firebase/firebase-setup'
import OslerData, { KEYS } from '../controllers/OslerData'
import { toastMsg } from '../utils/Utils'

class CustomFiltersController {
    constructor() {
        this.userID = null
        this.filters = []
    }

    start(user) {
        this.userID = user.id
        this.loadFilters()
        this.loaded = true
    }

    async loadFilters() {
        const data = OslerData.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS]
        this.filters = data && data['filters'] ? data['filters'] : []
        return this.filters
    }


    checkFilterNameAvailable(filterName) {
        return !(this.filters.map(f => f.filter_name.toLowerCase())).includes(filterName.toLowerCase())
    }


    updateOslerData() {
        OslerData.data[KEYS.RESIDENCIA][KEYS.USER_FILTERS] = {
            filters: this.filters
        }
    }

    async saveFilter(newFilter) {
        if (!(this.filters.map(f => f.filter_name.toLowerCase())).includes(newFilter.filter_name.toLowerCase())) {
            try {
                this.filters.push(newFilter)
                
                await db.doc(`/users/${this.userID}/personal/residencia_filters`).set({
                    'filters': this.filters
                })

                this.updateOslerData()
                return this.filters
            } catch (e) {
                console.log(e)
                return false
            }
        } else {
            toastMsg('Você já tem um filtro personalizado com esse nome! Tente de novo! 👀')
            return false
        }
    }

    async deleteFilter(filterToDelete) {
        this.filters = this.filters.filter(filter => filter.filter_name !== filterToDelete.filter_name)
        
        try {
            await db.doc(`/users/${this.userID}/personal/residencia_filters`).set({
                'filters': this.filters
            })

            this.updateOslerData()
            return this.filters
        } catch (e) {
            console.log(e)
            return false
        }
    }

    getFilters() {
        return this.filters
    }

    isSelectionNewFilter(chosenInstitutions, chosenYears) {
        if (chosenInstitutions.length === 0 && chosenYears.length === 0) {
            return false
        }

        for (let filter of this.filters) {
            const institutionsMatch = chosenInstitutions.every(inst => filter.institutions.includes(inst))
            const yearsMatch = chosenYears.every(year => filter.years.includes(year))

            if (institutionsMatch && yearsMatch) {
                return false
            }
        }

        return true
    }
}

export default new CustomFiltersController()