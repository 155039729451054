import { useState } from "react";
import { useEffect } from "react";
import TestScreenButton from "../tests/TestScreenButton";

import LikedIcon from '../assets/save-color.png'
import LikedIconDark from '../assets/save-color-dark.png'


import NotLikedIcon from '../assets/save.png'
import LikedBuriedController from "../controllers/LikedBuriedController";
import { useHotkeys } from 'react-hotkeys-hook'
import { useSelector } from "react-redux";

export default function LikeButton(props) {
    
    const [isLiked, setIsLiked] = useState()

    const darkMode = useSelector(state => state.theme.darkModeOn)

    useEffect(() => {
        setIsLiked( LikedBuriedController.isLiked( props.testType, props.ID ) )
    }, [])
 

    // https://react-hotkeys-hook.vercel.app/docs/documentation/useHotkeys/setting-callback-dependencies

    
    useHotkeys('shift+s', shortcut, [isLiked])

    
    function shortcut() {
        // Uso condicional de useHotkeys baseado em testMode, para que no modo consulte não faça para
        // todos
        if (props.testMode) {
            save()
        }
    }


    function save() {
        LikedBuriedController.likeOrDislike(props.testType, props.ID)
        setIsLiked( !isLiked )
    }

    return (
        <>
            <TestScreenButton
                text = { isLiked ? 'Salvo' : 'Salvar' }
                action = {save}
                icon = { isLiked ? (darkMode ? LikedIconDark : LikedIcon) : NotLikedIcon } />
        </>
    );
}
