import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraFentanil() {
    const [peso, setPeso] = useState('');
    const [volumeInfusao, setVolumeInfusao] = useState('');
    const [diluicao, setDiluicao] = useState(250); // Diluição padrão
    const [diluicaoPersonalizada, setDiluicaoPersonalizada] = useState(''); // Diluição personalizada
    const [ampolas, setAmpolas] = useState(3); // Ampola padrão (3 amp)
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularFentanil = () => {
        const pesoNum = parseFloat(peso);
        const volumeInfusaoNum = parseFloat(volumeInfusao);
        const diluicaoNum = diluicao === 'custom' ? parseFloat(diluicaoPersonalizada) : parseFloat(diluicao);
        const ampolasNum = parseFloat(ampolas);

        if (isNaN(pesoNum) || isNaN(volumeInfusaoNum) || isNaN(diluicaoNum) || isNaN(ampolasNum)) {
            setResultado('Preencha todos os campos necessários para ver o resultado');
            return;
        }

        const mcgSoro = ampolasNum * 500; // 1 ampola de Fentanil = 500mcg
        const dose = (mcgSoro * volumeInfusaoNum) / (diluicaoNum * pesoNum * 60); // Dose em mcg/kg/min
        setResultado(`Dose: <strong>${dose.toFixed(2)}</strong> mcg/kg/min`);
    };

    useEffect(() => {
        if (peso && volumeInfusao && (diluicao || diluicaoPersonalizada) && ampolas) {
            calcularFentanil();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [peso, volumeInfusao, diluicao, diluicaoPersonalizada, ampolas]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de Infusão de Fentanil</h1>
            <div>
                <label>Peso do paciente (kg):</label>
                <input type="number" value={peso} onChange={e => setPeso(e.target.value)} />
            </div>
            <div>
                <label>Volume de infusão (mL/h):</label>
                <input type="number" value={volumeInfusao} onChange={e => setVolumeInfusao(e.target.value)} />
            </div>
            <div>
                <label>Diluição em soro (mL):</label>
                <select value={diluicao} onChange={e => setDiluicao(e.target.value)}>
                    <option value={250}>250 mL</option>
                    <option value={500}>500 mL</option>
                    <option value="custom">Personalizado</option>
                </select>
                {diluicao === 'custom' && (
                    <input
                        type="number"
                        placeholder="Digite a diluição personalizada"
                        value={diluicaoPersonalizada}
                        onChange={e => setDiluicaoPersonalizada(e.target.value)}
                    />
                )}
            </div>
            <div>
                <label>Ampolas usadas:</label>
                <input type="number" value={ampolas} onChange={e => setAmpolas(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
            
        </div>
    );
}

export default CalculadoraFentanil;
