import React from 'react'
import styled, { keyframes } from 'styled-components'

const pulseAnimation = keyframes`
    0% { transform: scale(1) }
    50% { transform: scale(1.02) }
    100% { transform: scale(1) }
`

const Container = styled.div`
    padding: 2rem;
    background: linear-gradient(135deg, #000000 0%, #1a1a1a 100%);
    border-radius: 1rem;
    color: white;
    position: relative;
    overflow: hidden;
`

const TimerBanner = styled.div`
    background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%);
    padding: 0.8rem;
    border-radius: 8px;
    margin-bottom: 2rem;
    text-align: center;

    h2 {
        color: black;
        font-size: 1.8rem;
        margin: 0;
        text-transform: uppercase;
        font-weight: 900;
        letter-spacing: 1px;
    }
`

const InfoSection = styled.div`
    margin-bottom: 2rem;
`

const Title = styled.h4`
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: #FFD700;
`

const Detail = styled.p`
    font-size: 1.1rem;
    margin-bottom: 1rem;
    line-height: 1.6;
    color: #ffffff;

    strong {
        color: #FFD700;
    }

    u {
        color: #FFA500;
        text-decoration-color: #FFA500;
    }
`

const PriceTag = styled.div`
    text-align: center;
    margin: 2rem 0;
    animation: ${pulseAnimation} 2s infinite;
`

const OriginalPrice = styled.div`
    text-decoration: line-through;
    color: #888;
    font-size: 1.3rem;
`

const CurrentPrice = styled.div`
    font-size: 3.5rem;
    font-weight: 900;
    color: #FFD700;
    margin: 0.3rem 0;
`

const Discount = styled.div`
    background: #009127;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    font-weight: bold;
    font-size: 1.2rem;
    display: inline-block;
    margin: 0.5rem 0;
`

const InstallmentInfo = styled.div`
    color: #FFA500;
    font-size: 1.2rem;
    margin-top: 1rem;
    font-weight: bold;
`

const BuyButton = styled.button`
    background: linear-gradient(90deg, #FFD700 0%, #FFA500 100%);
    border: none;
    padding: 1.2rem 3rem;
    border-radius: 50px;
    font-size: 1.6rem;
    font-weight: 900;
    color: black;
    cursor: pointer;
    width: 100%;
    margin: 1rem 0;
    transition: all 0.3s ease;
    text-transform: uppercase;

    &:hover {
        transform: scale(1.02);
        background: linear-gradient(90deg, #FFA500 0%, #FFD700 100%);
    }
`

export default function AvailablePlans({ signalChosenPlan }) {
    function choosePlan(plan) {
        if (signalChosenPlan) {
            signalChosenPlan(plan)
        }
    }

    return (
        <Container>
            <TimerBanner>
                <h2>🔥 Black Friday termina em breve! 🔥</h2>
            </TimerBanner>

            <InfoSection>
                <Title>O que você está comprando?</Title>

                <Detail>
                    <strong>✓ Plataforma de flashcards.</strong> Abordando todo o material 
                    do ciclo clínico e prova de residência. Adicionamos novos decks 
                    <u> toda semana</u>, e iremos atualizar todos em 2024.
                </Detail>

                <Detail>
                    <strong>✓ +47.000 flashcards</strong> baseados no UpToDate e em 
                    livros clássicos.
                </Detail>

                <Detail>
                    <strong>✓ +43.000 questões comentadas</strong> de todo o Brasil, com 
                    qBank completo: provas na íntegra, listas personalizadas e exportação em PDF.
                </Detail>

                <Detail>
                    <strong>✓ Extensivo & Intensivão 2024.</strong> Cronogramas próprios
                    da Osler com <u>flashcards e questões diárias</u>.
                </Detail>

                <Detail>
                    <strong>✓ 7 dias de garantia.</strong> Estorno integral sem 
                    perguntas. Menos de 5% dos alunos cancelam! 😊
                </Detail>
            </InfoSection>

            <PriceTag>
                <OriginalPrice>De R$ 3.600,00 por apenas</OriginalPrice>
                <CurrentPrice>R$ 2.697,00</CurrentPrice>
                <Discount>30% OFF</Discount>
                <InstallmentInfo>Até Jan de 2026 (12x R$ 199)</InstallmentInfo>
            </PriceTag>

            <BuyButton onClick={() => choosePlan('plan-1')}>
                Garantir minha vaga
            </BuyButton>
        </Container>
    )
}