import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function CalculadoraHOMAIR() {
    const [insulina, setInsulina] = useState('');
    const [glicose, setGlicose] = useState('');
    const [resultado, setResultado] = useState('Preencha os campos para ver o resultado');

    const calcularHOMAIR = () => {
        const insulinaNum = parseFloat(insulina);
        const glicoseNum = parseFloat(glicose);

        if (isNaN(insulinaNum) || isNaN(glicoseNum)) {
            setResultado('Preencha os campos em branco');
            return;
        }

        const homaIR = (insulinaNum * glicoseNum) / 22.5;
        setResultado(`<strong>${homaIR.toFixed(2)}</strong>`);
    };

    useEffect(() => {
        if (insulina && glicose) {
            calcularHOMAIR();
        } else {
            setResultado('Preencha os campos para ver o resultado');
        }
    }, [insulina, glicose]);

    return (
        <div className="calc-page">
            <Link to="/calculadoras" className="button-voltar-calc">&lt;</Link>
            <h1>Calculadora de HOMA-IR</h1>
            <div>
                <label>Insulina em jejum (µUI/mL):</label>
                <input type="number" value={insulina} onChange={e => setInsulina(e.target.value)} />
            </div>
            <div>
                <label>Glicose em jejum (mmol/L):</label>
                <input type="number" value={glicose} onChange={e => setGlicose(e.target.value)} />
            </div>
            <div className="result-container" dangerouslySetInnerHTML={{ __html: resultado }} />
        </div>
    );
}

export default CalculadoraHOMAIR;
